import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { UpdateDrLegalSummaryActionTypes } from "./ActionTypes";
import { UpdateDrLegalSummaryBody, UpdateLegalSummaryRes } from "./Model";
import {
  updateDrLegalSummaryAPIResClearAction,
  updateDrLegalSummaryErrorAction,
  updateDrLegalSummaryLoadingAction,
  updateDrLegalSummarySuccessAction,
  updateDrLegalSummaryAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as UpdateLegalSummaryRes;
export const updateDrLegalSummary = (payload: UpdateDrLegalSummaryBody) => {
  return function (dispatch: Dispatch<UpdateDrLegalSummaryActionTypes>) {
    dispatch(updateDrLegalSummaryLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/edit_legal_summary`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updateDrLegalSummaryLoadingAction(false));
        dispatch(
          updateDrLegalSummarySuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal summary has been updated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(updateDrLegalSummaryLoadingAction(false));
        dispatch(
          updateDrLegalSummaryErrorAction(
            {} as UpdateLegalSummaryRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateUpdateDrLegalSummaryAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdateDrLegalSummaryActionTypes>) {
    dispatch(
      updateDrLegalSummaryAPIMsgAction(
        apiRes as UpdateLegalSummaryRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearUpdateDrLegalSummaryAPIRes = () => {
  return function (dispatch: Dispatch<UpdateDrLegalSummaryActionTypes>) {
    dispatch(
      updateDrLegalSummaryAPIResClearAction(
        {} as UpdateLegalSummaryRes,
        "",
        "",
        0
      )
    );
  };
};
