import { UpdateDemographicsDetailsByPatientIdRes } from "./Model";

export const UPDATE_PATIENT_DEMOGRAPHICS_LOADING =
  "UPDATE_PATIENT_DEMOGRAPHICS_LOADING";
export const UPDATE_PATIENT_DEMOGRAPHICS_SUCCESS =
  "UPDATE_PATIENT_DEMOGRAPHICS_SUCCESS";
export const UPDATE_PATIENT_DEMOGRAPHICS_FAIL =
  "UPDATE_PATIENT_DEMOGRAPHICS_FAIL";
export const UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG =
  "UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG";
export const UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_RES =
  "UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_RES";

export interface UpdatePatientDemographicsDetailsState {
  updatePatientDemographicsRes: UpdateDemographicsDetailsByPatientIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdatePatientDemographicsDetailsLoading {
  type: typeof UPDATE_PATIENT_DEMOGRAPHICS_LOADING;
  loading: boolean;
}
export interface UpdatePatientDemographicsDetailsSuccess {
  type: typeof UPDATE_PATIENT_DEMOGRAPHICS_SUCCESS;
  payload: UpdateDemographicsDetailsByPatientIdRes;
  successMsg: string;
}
export interface UpdatePatientDemographicsDetailsFail {
  type: typeof UPDATE_PATIENT_DEMOGRAPHICS_FAIL;
  payload: UpdateDemographicsDetailsByPatientIdRes;
  errorMsg: string;
  status: number;
}
export interface UpdatePatientDemographicsDetailsUpdateAPIMsg {
  type: typeof UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG;
  payload: UpdateDemographicsDetailsByPatientIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdatePatientDemographicsDetailsUpdateAPIRes {
  type: typeof UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_RES;
  payload: UpdateDemographicsDetailsByPatientIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type UpdateDemographicsDetailsActionTypes =
  | UpdatePatientDemographicsDetailsLoading
  | UpdatePatientDemographicsDetailsSuccess
  | UpdatePatientDemographicsDetailsFail
  | UpdatePatientDemographicsDetailsUpdateAPIMsg
  | UpdatePatientDemographicsDetailsUpdateAPIRes;
