import {
  SAVE_AS_DRAFT_PRESCRIPTION_FAIL,
  SAVE_AS_DRAFT_PRESCRIPTION_LOADING,
  SAVE_AS_DRAFT_PRESCRIPTION_SUCCESS,
  SAVE_AS_DRAFT_PRESCRIPTION_API_MSG,
  SAVE_AS_DRAFT_PRESCRIPTION_API_RES,
  SaveAsDraftPrescriptionActionTypes,
} from "./ActionTypes";
import { SaveAsDraftPrescriptionRes } from "./Model";

export const saveAsDraftPrescriptionLoadingAction = (
  loading: boolean
): SaveAsDraftPrescriptionActionTypes => {
  return {
    type: SAVE_AS_DRAFT_PRESCRIPTION_LOADING,
    loading: loading,
  };
};

export const saveAsDraftPrescriptionSuccessAction = (
  saveAsDraftPrescriptionResponse: SaveAsDraftPrescriptionRes,
  successMsg: string
): SaveAsDraftPrescriptionActionTypes => {
  return {
    type: SAVE_AS_DRAFT_PRESCRIPTION_SUCCESS,
    payload: saveAsDraftPrescriptionResponse,
    successMsg: successMsg,
  };
};

export const saveAsDraftPrescriptionErrorAction = (
  saveAsDraftPrescriptionResponse: SaveAsDraftPrescriptionRes,
  errMsg: string,
  status: number
): SaveAsDraftPrescriptionActionTypes => {
  return {
    type: SAVE_AS_DRAFT_PRESCRIPTION_FAIL,
    payload: saveAsDraftPrescriptionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const saveAsDraftPrescriptionAPIMsgAction = (
  saveAsDraftPrescriptionResponse: SaveAsDraftPrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): SaveAsDraftPrescriptionActionTypes => {
  return {
    type: SAVE_AS_DRAFT_PRESCRIPTION_API_MSG,
    payload: saveAsDraftPrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const saveAsDraftPrescriptionAPIResClearAction = (
  saveAsDraftPrescriptionResponse: SaveAsDraftPrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): SaveAsDraftPrescriptionActionTypes => {
  return {
    type: SAVE_AS_DRAFT_PRESCRIPTION_API_RES,
    payload: saveAsDraftPrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
