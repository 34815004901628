import {
  GET_FINAL_OPINION_LIST_FAIL,
  GET_FINAL_OPINION_LIST_LOADING,
  GET_FINAL_OPINION_LIST_SUCCESS,
  GET_FINAL_OPINION_LIST_API_MSG,
  GET_FINAL_OPINION_LIST_API_RES,
  GetFinalOpinionListActionTypes,
} from "./ActionTypes";
import { GetFinalOpinionListRes } from "./Model";

export const getFinalOpinionListLoadingAction = (
  loading: boolean
): GetFinalOpinionListActionTypes => {
  return {
    type: GET_FINAL_OPINION_LIST_LOADING,
    loading: loading,
  };
};

export const getFinalOpinionListSuccessAction = (
  getFinalOpinionListResponse: GetFinalOpinionListRes,
  successMsg: string
): GetFinalOpinionListActionTypes => {
  return {
    type: GET_FINAL_OPINION_LIST_SUCCESS,
    payload: getFinalOpinionListResponse,
    successMsg: successMsg,
  };
};

export const getFinalOpinionListErrorAction = (
  getFinalOpinionListResponse: GetFinalOpinionListRes,
  errMsg: string,
  status: number
): GetFinalOpinionListActionTypes => {
  return {
    type: GET_FINAL_OPINION_LIST_FAIL,
    payload: getFinalOpinionListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getFinalOpinionListAPIMsgAction = (
  getFinalOpinionListResponse: GetFinalOpinionListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetFinalOpinionListActionTypes => {
  return {
    type: GET_FINAL_OPINION_LIST_API_MSG,
    payload: getFinalOpinionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getFinalOpinionListAPIResClearAction = (
  getFinalOpinionListResponse: GetFinalOpinionListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetFinalOpinionListActionTypes => {
  return {
    type: GET_FINAL_OPINION_LIST_API_RES,
    payload: getFinalOpinionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
