import { SubmitQuestionResponse } from "./Model";
export const SUBMIT_QUESTION_LOADING = "SUBMIT_QUESTION_LOADING";
export const SUBMIT_QUESTION_SUCCESS = "SUBMIT_QUESTION_SUCCESS";
export const SUBMIT_QUESTION_FAIL = "SUBMIT_QUESTION_FAIL";
export const SUBMIT_QUESTION_API_MSG = "SUBMIT_QUESTION_API_MSG";
export const SUBMIT_QUESTION_API_RES = "SUBMIT_QUESTION_API_RES";

export interface SubmitQuestionState {
  submitQuestionResponse: SubmitQuestionResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SubmitQuestionLoading {
  type: typeof SUBMIT_QUESTION_LOADING;
  loading: boolean;
}
export interface SubmitQuestionSuccess {
  type: typeof SUBMIT_QUESTION_SUCCESS;
  payload: SubmitQuestionResponse;
  successMsg: string;
}
export interface SubmitQuestionFail {
  type: typeof SUBMIT_QUESTION_FAIL;
  payload: SubmitQuestionResponse;
  errorMsg: string;
  status: number;
}
export interface SubmitQuestionAPIMsg {
  type: typeof SUBMIT_QUESTION_API_MSG;
  payload: SubmitQuestionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SubmitQuestionAPIRes {
  type: typeof SUBMIT_QUESTION_API_RES;
  payload: SubmitQuestionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SubmitQuestionActionTypes =
  | SubmitQuestionLoading
  | SubmitQuestionSuccess
  | SubmitQuestionFail
  | SubmitQuestionAPIMsg
  | SubmitQuestionAPIRes;
