import {
  EDIT_DR_AWARD_FAIL,
  EDIT_DR_AWARD_LOADING,
  EDIT_DR_AWARD_SUCCESS,
  EDIT_DR_AWARD_API_MSG,
  EDIT_DR_AWARD_API_RES,
  EditDrAwardActionTypes,
} from "./ActionTypes";
import { EditAwardRes } from "./Model";

export const editDrAwardLoadingAction = (
  loading: boolean
): EditDrAwardActionTypes => {
  return {
    type: EDIT_DR_AWARD_LOADING,
    loading: loading,
  };
};

export const editDrAwardSuccessAction = (
  editDrAwardResponse: EditAwardRes,
  successMsg: string
): EditDrAwardActionTypes => {
  return {
    type: EDIT_DR_AWARD_SUCCESS,
    payload: editDrAwardResponse,
    successMsg: successMsg,
  };
};

export const editDrAwardErrorAction = (
  editDrAwardResponse: EditAwardRes,
  errMsg: string,
  status: number
): EditDrAwardActionTypes => {
  return {
    type: EDIT_DR_AWARD_FAIL,
    payload: editDrAwardResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDrAwardAPIMsgAction = (
  editDrAwardResponse: EditAwardRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrAwardActionTypes => {
  return {
    type: EDIT_DR_AWARD_API_MSG,
    payload: editDrAwardResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDrAwardAPIResClearAction = (
  editDrAwardResponse: EditAwardRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrAwardActionTypes => {
  return {
    type: EDIT_DR_AWARD_API_RES,
    payload: editDrAwardResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
