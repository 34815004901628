import {
  GET_DOCTOR_LEGAL_SUMMARY_FAIL,
  GET_DOCTOR_LEGAL_SUMMARY_LOADING,
  GET_DOCTOR_LEGAL_SUMMARY_SUCCESS,
  GET_DOCTOR_LEGAL_SUMMARY_API_MSG,
  GET_DOCTOR_LEGAL_SUMMARY_API_RES,
  GetDoctorLegalSummaryActionTypes,
} from "./ActionTypes";
import { GetLegalSummaryRes } from "./Model";

export const getDoctorLegalSummaryLoadingAction = (
  loading: boolean
): GetDoctorLegalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_SUMMARY_LOADING,
    loading: loading,
  };
};

export const getDoctorLegalSummarySuccessAction = (
  getDoctorLegalSummaryResponse: GetLegalSummaryRes,
  successMsg: string
): GetDoctorLegalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_SUMMARY_SUCCESS,
    payload: getDoctorLegalSummaryResponse,
    successMsg: successMsg,
  };
};

export const getDoctorLegalSummaryErrorAction = (
  getDoctorLegalSummaryResponse: GetLegalSummaryRes,
  errMsg: string,
  status: number
): GetDoctorLegalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_SUMMARY_FAIL,
    payload: getDoctorLegalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorLegalSummaryAPIMsgAction = (
  getDoctorLegalSummaryResponse: GetLegalSummaryRes,
  errMsg: string,
  status: number
): GetDoctorLegalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_SUMMARY_API_MSG,
    payload: getDoctorLegalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorLegalSummaryAPIResClearAction = (
  getDoctorLegalSummaryResponse: GetLegalSummaryRes,
  errMsg: string,
  status: number
): GetDoctorLegalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_SUMMARY_API_RES,
    payload: getDoctorLegalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
