import {
    GetSectionTemplateDetails,
    GetSectionTemplateListActionTypes,
    GET_SECTION_TEMPLATE_LIST_FAIL,
    GET_SECTION_TEMPLATE_LIST_LOADING,
    GET_SECTION_TEMPLATE_LIST_SUCCESS,
  } from "./ActionTypes";
  import { GetSectionTemplateListRes } from "./Model";
  
  const initialStateGetPosts: GetSectionTemplateDetails = {
    loading: false,
    GetSectionTemplateListRes: {} as GetSectionTemplateListRes,
    errRes: "",
  };
  export const GetSectionTemplateListReducer = (
    state = initialStateGetPosts,
    action: GetSectionTemplateListActionTypes
  ): GetSectionTemplateDetails => {
    switch (action.type) {
      case GET_SECTION_TEMPLATE_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          GetSectionTemplateListRes: action.payload,
          errRes: "",
        };
      case GET_SECTION_TEMPLATE_LIST_LOADING:
        return {
          ...state,
          loading: true,
          GetSectionTemplateListRes: {} as GetSectionTemplateListRes,
        };
      case GET_SECTION_TEMPLATE_LIST_FAIL:
        return {
          ...state,
          GetSectionTemplateListRes: {} as GetSectionTemplateListRes,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  