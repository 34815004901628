import { PaymentStatusRes } from "./Model";

export const PAYMENT_STATUS_LOADING =
  "PAYMENT_STATUS_LOADING";
export const PAYMENT_STATUS_SUCCESS =
  "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_FAIL =
  "PAYMENT_STATUS_FAIL";
export const PAYMENT_STATUS_DATA =
  "PAYMENT_STATUS_DATA";

export interface PaymentStatusDetails {
  paymentStatusRes: PaymentStatusRes;
  loading: boolean;
  errRes: string;
}
export interface PaymentStatusDetailsLoading {
  type: typeof PAYMENT_STATUS_LOADING;
}
export interface PaymentStatusDetailsSuccess {
  type: typeof PAYMENT_STATUS_SUCCESS;
  payload: PaymentStatusRes;
  errRes: string;
}
export interface PaymentStatusDetailsFail {
  type: typeof PAYMENT_STATUS_FAIL;
  payload: PaymentStatusRes;
  errRes: string;
}
interface PaymentStatusDetailsAction {
  type: typeof PAYMENT_STATUS_DATA;
  payload: PaymentStatusRes;
  errRes: string;
}

export type PaymentStatusActionTypes =
  | PaymentStatusDetailsAction
  | PaymentStatusDetailsLoading
  | PaymentStatusDetailsSuccess
  | PaymentStatusDetailsFail;
