import { GetDrIdProofListRes } from "./Model";
export const GET_DOCTOR_ID_PROOF_LIST_LOADING =
  "GET_DOCTOR_ID_PROOF_LIST_LOADING";
export const GET_DOCTOR_ID_PROOF_LIST_SUCCESS =
  "GET_DOCTOR_ID_PROOF_LIST_SUCCESS";
export const GET_DOCTOR_ID_PROOF_LIST_FAIL = "GET_DOCTOR_ID_PROOF_LIST_FAIL";
export const GET_DOCTOR_ID_PROOF_LIST_API_MSG =
  "GET_DOCTOR_ID_PROOF_LIST_API_MSG";
export const GET_DOCTOR_ID_PROOF_LIST_API_RES =
  "GET_DOCTOR_ID_PROOF_LIST_API_RES";

export interface GetDrIdProofListState {
  getDrIdProofListRes: GetDrIdProofListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDrIdProofListLoading {
  type: typeof GET_DOCTOR_ID_PROOF_LIST_LOADING;
  loading: boolean;
}
export interface GetDrIdProofListSuccess {
  type: typeof GET_DOCTOR_ID_PROOF_LIST_SUCCESS;
  payload: GetDrIdProofListRes;
  successMsg: string;
}
export interface GetDrIdProofListFail {
  type: typeof GET_DOCTOR_ID_PROOF_LIST_FAIL;
  payload: GetDrIdProofListRes;
  errorMsg: string;
  status: number;
}
export interface GetDrIdProofListAPIMsg {
  type: typeof GET_DOCTOR_ID_PROOF_LIST_API_MSG;
  payload: GetDrIdProofListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDrIdProofListAPIRes {
  type: typeof GET_DOCTOR_ID_PROOF_LIST_API_RES;
  payload: GetDrIdProofListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDrIdProofListActionTypes =
  | GetDrIdProofListLoading
  | GetDrIdProofListSuccess
  | GetDrIdProofListFail
  | GetDrIdProofListAPIMsg
  | GetDrIdProofListAPIRes;
