import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ComponentRouter from "./routes";
import AppFrame from "components/AppFrame";
import { Box, Toolbar } from "@mui/material";

export default function App() {
  const Bearer = localStorage.getItem("access");
  return (
    <Box sx={{ display: "flex" }}>
      <Router>
        <AppFrame />
        <Box component="main" sx={{ flexGrow: 1 }}>
          {Bearer ? <Toolbar /> : null}
          <ComponentRouter />
        </Box>
      </Router>
    </Box>
  );
}
