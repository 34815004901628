import { AllActiveMeetingListRes } from "./Model";
export const ALL_ACTIVE_MEETING_LIST_LOADING =
  "ALL_ACTIVE_MEETING_LIST_LOADING";
export const ALL_ACTIVE_MEETING_LIST_SUCCESS =
  "ALL_ACTIVE_MEETING_LIST_SUCCESS";
export const ALL_ACTIVE_MEETING_LIST_FAIL = "ALL_ACTIVE_MEETING_LIST_FAIL";
export const ALL_ACTIVE_MEETING_LIST_LIST = "ALL_ACTIVE_MEETING_LIST_LIST";

export interface allActiveMeetingListDetails {
  allActiveMeetingListRes: AllActiveMeetingListRes;
  loading: boolean;
  errRes: string;
}
export interface allActiveMeetingListLoading {
  type: typeof ALL_ACTIVE_MEETING_LIST_LOADING;
  // loading: boolean;
}
export interface allActiveMeetingListSuccess {
  type: typeof ALL_ACTIVE_MEETING_LIST_SUCCESS;
  payload: AllActiveMeetingListRes;
  errRes: string;
}
export interface allActiveMeetingListFail {
  type: typeof ALL_ACTIVE_MEETING_LIST_FAIL;
  errRes: string;
}

interface allActiveMeetingListAction {
  type: typeof ALL_ACTIVE_MEETING_LIST_LIST;
  payload: AllActiveMeetingListRes;
  errRes: string;
}

export type allActiveMeetingListActionTypes =
  | allActiveMeetingListAction
  | allActiveMeetingListLoading
  | allActiveMeetingListSuccess
  | allActiveMeetingListFail;
