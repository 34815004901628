import {
  AddDrAwardActionTypes,
  ADD_DR_AWARD_FAIL,
  ADD_DR_AWARD_LOADING,
  ADD_DR_AWARD_SUCCESS,
  AddDrAwardState,
  ADD_DR_AWARD_API_MSG,
  ADD_DR_AWARD_API_RES,
} from "./ActionTypes";
import { AddAwardRes } from "./Model";

const initialStateEditPosts: AddDrAwardState = {
  loading: false,
  addDrAwardRes: {} as AddAwardRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDrAwardReducer = (
  state = initialStateEditPosts,
  action: AddDrAwardActionTypes
): AddDrAwardState => {
  switch (action.type) {
    case ADD_DR_AWARD_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DR_AWARD_SUCCESS:
      return {
        ...state,
        addDrAwardRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DR_AWARD_FAIL:
      return {
        ...state,
        addDrAwardRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_AWARD_API_MSG:
      return {
        ...state,
        addDrAwardRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_AWARD_API_RES:
      return {
        ...state,
        addDrAwardRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
