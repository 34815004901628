import { EditProfessionalMembershipRes } from "./Model";
export const EDIT_DR_PROFESSIONAL_MEMBERSHIP_LOADING =
  "EDIT_DR_PROFESSIONAL_MEMBERSHIP_LOADING";
export const EDIT_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS =
  "EDIT_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS";
export const EDIT_DR_PROFESSIONAL_MEMBERSHIP_FAIL =
  "EDIT_DR_PROFESSIONAL_MEMBERSHIP_FAIL";
export const EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_MSG =
  "EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_MSG";
export const EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_RES =
  "EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_RES";

export interface EditDrProfessionalMembershipState {
  editDrProfessionalMembershipRes: EditProfessionalMembershipRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrProfessionalMembershipLoading {
  type: typeof EDIT_DR_PROFESSIONAL_MEMBERSHIP_LOADING;
  loading: boolean;
}
export interface EditDrProfessionalMembershipSuccess {
  type: typeof EDIT_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS;
  payload: EditProfessionalMembershipRes;
  successMsg: string;
}
export interface EditDrProfessionalMembershipFail {
  type: typeof EDIT_DR_PROFESSIONAL_MEMBERSHIP_FAIL;
  payload: EditProfessionalMembershipRes;
  errorMsg: string;
  status: number;
}
export interface EditDrProfessionalMembershipAPIMsg {
  type: typeof EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_MSG;
  payload: EditProfessionalMembershipRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrProfessionalMembershipAPIRes {
  type: typeof EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_RES;
  payload: EditProfessionalMembershipRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDrProfessionalMembershipActionTypes =
  | EditDrProfessionalMembershipLoading
  | EditDrProfessionalMembershipSuccess
  | EditDrProfessionalMembershipFail
  | EditDrProfessionalMembershipAPIMsg
  | EditDrProfessionalMembershipAPIRes;
