import { GetAgreementRes } from "./Model";
export const GET_DOCTOR_AGREEMENT_LIST_LOADING =
  "GET_DOCTOR_AGREEMENT_LIST_LOADING";
export const GET_DOCTOR_AGREEMENT_LIST_SUCCESS =
  "GET_DOCTOR_AGREEMENT_LIST_SUCCESS";
export const GET_DOCTOR_AGREEMENT_LIST_FAIL = "GET_DOCTOR_AGREEMENT_LIST_FAIL";
export const GET_DOCTOR_AGREEMENT_LIST_API_MSG =
  "GET_DOCTOR_AGREEMENT_LIST_API_MSG";
export const GET_DOCTOR_AGREEMENT_LIST_API_RES =
  "GET_DOCTOR_AGREEMENT_LIST_API_RES";

export interface GetDoctorAgreementState {
  getDoctorAgreementRes: GetAgreementRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAgreementLoading {
  type: typeof GET_DOCTOR_AGREEMENT_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorAgreementSuccess {
  type: typeof GET_DOCTOR_AGREEMENT_LIST_SUCCESS;
  payload: GetAgreementRes;
  successMsg: string;
}
export interface GetDoctorAgreementFail {
  type: typeof GET_DOCTOR_AGREEMENT_LIST_FAIL;
  payload: GetAgreementRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAgreementAPIMsg {
  type: typeof GET_DOCTOR_AGREEMENT_LIST_API_MSG;
  payload: GetAgreementRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAgreementAPIRes {
  type: typeof GET_DOCTOR_AGREEMENT_LIST_API_RES;
  payload: GetAgreementRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorAgreementActionTypes =
  | GetDoctorAgreementLoading
  | GetDoctorAgreementSuccess
  | GetDoctorAgreementFail
  | GetDoctorAgreementAPIMsg
  | GetDoctorAgreementAPIRes;
