import { GetProfessionalSummaryRes } from "./Model";
export const GET_DOCTOR_PROFESSIONAL_SUMMARY_LOADING =
  "GET_DOCTOR_PROFESSIONAL_SUMMARY_LOADING";
export const GET_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS =
  "GET_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS";
export const GET_DOCTOR_PROFESSIONAL_SUMMARY_FAIL =
  "GET_DOCTOR_PROFESSIONAL_SUMMARY_FAIL";
export const GET_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG =
  "GET_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG";
export const GET_DOCTOR_PROFESSIONAL_SUMMARY_API_RES =
  "GET_DOCTOR_PROFESSIONAL_SUMMARY_API_RES";

export interface GetDoctorProfessionalSummaryState {
  getDoctorProfessionalSummaryRes: GetProfessionalSummaryRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetDoctorProfessionalSummaryLoading {
  type: typeof GET_DOCTOR_PROFESSIONAL_SUMMARY_LOADING;
  loading: boolean;
}
export interface GetDoctorProfessionalSummarySuccess {
  type: typeof GET_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS;
  payload: GetProfessionalSummaryRes;
  successMsg: string;
}
export interface GetDoctorProfessionalSummaryFail {
  type: typeof GET_DOCTOR_PROFESSIONAL_SUMMARY_FAIL;
  payload: GetProfessionalSummaryRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorProfessionalSummaryAPIMsg {
  type: typeof GET_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG;
  payload: GetProfessionalSummaryRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorProfessionalSummaryAPIRes {
  type: typeof GET_DOCTOR_PROFESSIONAL_SUMMARY_API_RES;
  payload: GetProfessionalSummaryRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorProfessionalSummaryActionTypes =
  | GetDoctorProfessionalSummaryLoading
  | GetDoctorProfessionalSummarySuccess
  | GetDoctorProfessionalSummaryFail
  | GetDoctorProfessionalSummaryAPIMsg
  | GetDoctorProfessionalSummaryAPIRes;
