import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader,
  Grid,
} from "@mui/material";
import { t } from "i18next";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import { getMembershipList } from "redux/UserProfile/ProfessionalDetails/Membership/GetProfessionalMembershipList/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import dayjs from "dayjs";
import { MembershipList } from "redux/UserProfile/ProfessionalDetails/Membership/GetProfessionalMembershipList/Model";
import { ModifiedMembershipList } from "./Model";
import DocumentViewer from "components/DocumentViewer";
import UpdateMembershipDetails from "./UpdateMembershipDetails";
import MWExceptionList from "components/MWExceptionList";
export interface Props {
  professionalMembershipLoading: boolean | any;
}
export default function ProfessionalMembership({
  professionalMembershipLoading,
}: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMembershipList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [membershipList, setMembershipList] = useState(
    [] as ModifiedMembershipList[]
  );
  const membershipTableColumns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName:`${t("appointmentpage.doc", { ns: ["home"] })!}`,
      minWidth: 130,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === null ? true : false}
          onClick={() => {
            setViewMembershipDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "membershipName",
      headerName: `${t("appointmentpage.membership", { ns: ["home"] })!}`,
      width: 160,
      flex: 1,
    },
    { field: "membershipNumber", headerName: `${t("appointmentpage.membershipnum", { ns: ["home"] })!}`, width: 200 },
    {
      field: "validityDate",
      headerName: `${t("appointmentpage.validtydate", { ns: ["home"] })!}`,
      width: 130,
    },
    {
      field: "description",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })!}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setUpdateDrProfessionalMembershipDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("userprofilepage.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  const getMembershipListRes = useSelector(
    (state: AppState) => state.getMembershipListRes
  );

  useEffect(() => {
    professionalMembershipLoading(getMembershipListRes?.loading);
    if (
      getMembershipListRes?.getDoctorMembershipListRes?.membershipList !==
      undefined
    ) {
      let membershipListItem =
        getMembershipListRes?.getDoctorMembershipListRes?.membershipList.map(
          (item: MembershipList) =>
            ({
              id: item?.id,
              supportingDocument:
                item?.supportingDocuments !== undefined
                  ? item?.supportingDocuments?.userFile?.document !== undefined
                    ? item?.supportingDocuments?.userFile?.document
                    : null
                  : null,
              membershipName:
                item?.name !== undefined
                  ? item?.name !== ""
                    ? item?.name
                    : ""
                  : "",
              membershipNumber:
                item?.idNumber !== undefined
                  ? item?.idNumber !== ""
                    ? item?.idNumber
                    : ""
                  : "",
              createdDate:
                item?.createdDate !== null
                  ? dayjs(item?.createdDate).format("DD-MM-YYYY")
                  : "Not Set",
              validityDate:
                item?.validityDate !== null
                  ? dayjs(item?.validityDate).format("DD-MM-YYYY")
                  : "Not Set",
              description: item?.description,
            } as ModifiedMembershipList)
        );
      setMembershipList(membershipListItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMembershipListRes]);

  const [editItem, setEditItem] = useState({} as ModifiedMembershipList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };
  const [
    updateDrProfessionalMembershipDialog,
    setUpdateDrProfessionalMembershipDialog,
  ] = useState(false);
  const updateDrProfessionalMembershipDialogValFromChild = (
    passedVal: boolean
  ) => {
    setUpdateDrProfessionalMembershipDialog(passedVal);
  };
  const [viewMembershipDocumentDialog, setViewMembershipDocumentDialog] =
    useState(false);
  const updateViewMembershipDocumentDialogValFromChild = (
    passedVal: boolean
  ) => {
    setViewMembershipDocumentDialog(passedVal);
  };
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.profmem", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setUpdateDrProfessionalMembershipDialog(true)}
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                >
                  {t("userprofilepage.addprofmem", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {membershipList && membershipList.length ? (
            <DataGrid
              rows={membershipList}
              columns={membershipTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {updateDrProfessionalMembershipDialog ? (
        <UpdateMembershipDetails
          dialogEnable={updateDrProfessionalMembershipDialog}
          dialogUpdateState={updateDrProfessionalMembershipDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewMembershipDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewMembershipDocumentDialog}
          dialogUpdateState={updateViewMembershipDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
