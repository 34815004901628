import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { AddScheduleActionTypes } from "./ActionTypes";
import { EmailOTPGenBody, emailOtpGenRes } from "./Model";
import {
  emailOTPGenAPIResClearAction,
  emailOTPGenErrorAction,
  emailOTPGenLoadingAction,
  emailOTPGenSuccessAction,
  emailOTPGenUpdateAPIMsgAction,
} from "./Actions";
import { ApiHeader } from "../../components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as emailOtpGenRes;
export const emailOtpGenerationApi = (payload: EmailOTPGenBody) => {
  return function (dispatch: Dispatch<AddScheduleActionTypes>) {
    dispatch(emailOTPGenLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/email-otp-generation/`, payload, {
        headers: ApiHeader,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(emailOTPGenLoadingAction(false));
        dispatch(
          emailOTPGenSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "OTP generated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(emailOTPGenLoadingAction(false));
        dispatch(
          emailOTPGenErrorAction(
            {} as emailOtpGenRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEmailOtpGenAPIResMsg = () => {
  return function (dispatch: Dispatch<AddScheduleActionTypes>) {
    dispatch(
      emailOTPGenUpdateAPIMsgAction(apiRes as emailOtpGenRes, "", "", 0)
    );
  };
};

export const clearEmailOtpGenAPIRes = () => {
  return function (dispatch: Dispatch<AddScheduleActionTypes>) {
    dispatch(emailOTPGenAPIResClearAction({} as emailOtpGenRes, "", "", 0));
  };
};
