import { DoctorAppointmentDecisionRes } from "./Model";
export const ADD_DR_APPOINTMENT_DECISION_LOADING =
  "ADD_DR_APPOINTMENT_DECISION_LOADING";
export const ADD_DR_APPOINTMENT_DECISION_SUCCESS =
  "ADD_DR_APPOINTMENT_DECISION_SUCCESS";
export const ADD_DR_APPOINTMENT_DECISION_FAIL =
  "ADD_DR_APPOINTMENT_DECISION_FAIL";
export const ADD_DR_APPOINTMENT_DECISION_API_MSG =
  "ADD_DR_APPOINTMENT_DECISION_API_MSG";
export const ADD_DR_APPOINTMENT_DECISION_API_RES =
  "ADD_DR_APPOINTMENT_DECISION_API_RES";

export interface DrAppointmentDecisionState {
  drAppointmentDecisionRes: DoctorAppointmentDecisionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DrAppointmentDecisionLoading {
  type: typeof ADD_DR_APPOINTMENT_DECISION_LOADING;
  loading: boolean;
}
export interface DrAppointmentDecisionSuccess {
  type: typeof ADD_DR_APPOINTMENT_DECISION_SUCCESS;
  payload: DoctorAppointmentDecisionRes;
  successMsg: string;
}
export interface DrAppointmentDecisionFail {
  type: typeof ADD_DR_APPOINTMENT_DECISION_FAIL;
  payload: DoctorAppointmentDecisionRes;
  errorMsg: string;
  status: number;
}
export interface DrAppointmentDecisionAPIMsg {
  type: typeof ADD_DR_APPOINTMENT_DECISION_API_MSG;
  payload: DoctorAppointmentDecisionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DrAppointmentDecisionAPIRes {
  type: typeof ADD_DR_APPOINTMENT_DECISION_API_RES;
  payload: DoctorAppointmentDecisionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DrAppointmentDecisionActionTypes =
  | DrAppointmentDecisionLoading
  | DrAppointmentDecisionSuccess
  | DrAppointmentDecisionFail
  | DrAppointmentDecisionAPIMsg
  | DrAppointmentDecisionAPIRes;
