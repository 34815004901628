import React from "react";
import { Route, Redirect } from "react-router-dom";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";

export const PublicRoute = ({ component: Component, token, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem("access");
      const userDetails = JSON.parse(
        localStorage.getItem("loginDetails")!
      ) as GetDoctorDetailsRes;
      if (token) {
        if (userDetails?.onboardingstatus === "approved") {
          return (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          );
        } else {
          return (
            <Redirect
              to={{ pathname: "/userprofile", state: { from: props.location } }}
            />
          );
        }
      }

      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

// forgot password lal email er parseFloat,
// onaction,
// api success hole
