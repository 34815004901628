import { AskQuestionResponse } from "./Model";
export const ASK_QUESTION_LOADING = "ASK_QUESTION_LOADING";
export const ASK_QUESTION_SUCCESS = "ASK_QUESTION_SUCCESS";
export const ASK_QUESTION_FAIL = "ASK_QUESTION_FAIL";
export const ASK_QUESTION_API_MSG = "ASK_QUESTION_API_MSG";
export const ASK_QUESTION_API_RES = "ASK_QUESTION_API_RES";

export interface AskQuestionState {
  askQuestionResponse: AskQuestionResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AskQuestionLoading {
  type: typeof ASK_QUESTION_LOADING;
  loading: boolean;
}
export interface AskQuestionSuccess {
  type: typeof ASK_QUESTION_SUCCESS;
  payload: AskQuestionResponse;
  successMsg: string;
}
export interface AskQuestionFail {
  type: typeof ASK_QUESTION_FAIL;
  payload: AskQuestionResponse;
  errorMsg: string;
  status: number;
}
export interface AskQuestionAPIMsg {
  type: typeof ASK_QUESTION_API_MSG;
  payload: AskQuestionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AskQuestionAPIRes {
  type: typeof ASK_QUESTION_API_RES;
  payload: AskQuestionResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AskQuestionActionTypes =
  | AskQuestionLoading
  | AskQuestionSuccess
  | AskQuestionFail
  | AskQuestionAPIMsg
  | AskQuestionAPIRes;
