import React, { useState } from "react";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
interface Props {
  isMenu?: boolean;
}
const MWSelectLang: React.FC<Props> = ({ isMenu = false }) => {
  const { t, i18n } = useTranslation(["home"]);
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("lng") || "en"
  );
  const onClickLanguageChange = (e: any) => {
    const language = e.target.value;
    localStorage.setItem("lng", language);
    setSelectedLang(language);
    i18n.changeLanguage(language); //change the language
    window.location.reload();
  };
  return (
    <FormControl sx={{ m: 1, minHeight: 1 }} size="small">
      <Select value={selectedLang} onChange={onClickLanguageChange}>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="de">Deutsch</MenuItem>
      </Select>
      {!isMenu && (
        <FormHelperText variant="standard" sx={{ fontSize: 12 }}>
          {t("home.chooseLng", { ns: ["home"] })}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default MWSelectLang;
