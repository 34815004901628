import {
  ADD_DR_INSURANCE_FAIL,
  ADD_DR_INSURANCE_LOADING,
  ADD_DR_INSURANCE_SUCCESS,
  ADD_DR_INSURANCE_API_MSG,
  ADD_DR_INSURANCE_API_RES,
  AddDrInsuranceActionTypes,
} from "./ActionTypes";
import { AddDrInsuranceRes } from "./Model";

export const addDrInsuranceLoadingAction = (
  loading: boolean
): AddDrInsuranceActionTypes => {
  return {
    type: ADD_DR_INSURANCE_LOADING,
    loading: loading,
  };
};

export const addDrInsuranceSuccessAction = (
  addDrInsuranceResponse: AddDrInsuranceRes,
  successMsg: string
): AddDrInsuranceActionTypes => {
  return {
    type: ADD_DR_INSURANCE_SUCCESS,
    payload: addDrInsuranceResponse,
    successMsg: successMsg,
  };
};

export const addDrInsuranceErrorAction = (
  addDrInsuranceResponse: AddDrInsuranceRes,
  errMsg: string,
  status: number
): AddDrInsuranceActionTypes => {
  return {
    type: ADD_DR_INSURANCE_FAIL,
    payload: addDrInsuranceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDrInsuranceAPIMsgAction = (
  addDrInsuranceResponse: AddDrInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrInsuranceActionTypes => {
  return {
    type: ADD_DR_INSURANCE_API_MSG,
    payload: addDrInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDrInsuranceAPIResClearAction = (
  addDrInsuranceResponse: AddDrInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrInsuranceActionTypes => {
  return {
    type: ADD_DR_INSURANCE_API_RES,
    payload: addDrInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
