import { AddWorkExperienceRes } from "./Model";
export const ADD_DR_WORK_EXPERIENCE_LOADING = "ADD_DR_WORK_EXPERIENCE_LOADING";
export const ADD_DR_WORK_EXPERIENCE_SUCCESS = "ADD_DR_WORK_EXPERIENCE_SUCCESS";
export const ADD_DR_WORK_EXPERIENCE_FAIL = "ADD_DR_WORK_EXPERIENCE_FAIL";
export const ADD_DR_WORK_EXPERIENCE_API_MSG = "ADD_DR_WORK_EXPERIENCE_API_MSG";
export const ADD_DR_WORK_EXPERIENCE_API_RES = "ADD_DR_WORK_EXPERIENCE_API_RES";

export interface AddDrWorkExperienceState {
  addDrWorkExperienceRes: AddWorkExperienceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrWorkExperienceLoading {
  type: typeof ADD_DR_WORK_EXPERIENCE_LOADING;
  loading: boolean;
}
export interface AddDrWorkExperienceSuccess {
  type: typeof ADD_DR_WORK_EXPERIENCE_SUCCESS;
  payload: AddWorkExperienceRes;
  successMsg: string;
}
export interface AddDrWorkExperienceFail {
  type: typeof ADD_DR_WORK_EXPERIENCE_FAIL;
  payload: AddWorkExperienceRes;
  errorMsg: string;
  status: number;
}
export interface AddDrWorkExperienceAPIMsg {
  type: typeof ADD_DR_WORK_EXPERIENCE_API_MSG;
  payload: AddWorkExperienceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrWorkExperienceAPIRes {
  type: typeof ADD_DR_WORK_EXPERIENCE_API_RES;
  payload: AddWorkExperienceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrWorkExperienceActionTypes =
  | AddDrWorkExperienceLoading
  | AddDrWorkExperienceSuccess
  | AddDrWorkExperienceFail
  | AddDrWorkExperienceAPIMsg
  | AddDrWorkExperienceAPIRes;
