import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { EditPatientActionTypes } from "./ActionTypes";
import { EditPersonalDetailsModel, EditPersonalDetailsRes } from "./Model";
import {
  editPatientAPIResClearAction,
  editPatientErrorAction,
  editPatientLoadingAction,
  editPatientSuccessAction,
  editPatientUpdateAPIMsgAction,
} from "./Action";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import { headers } from "components/Utility";

let apiRes = {} as EditPersonalDetailsRes;
export const editPersonalDetailsApi = (payload: EditPersonalDetailsModel) => {
  return function (dispatch: Dispatch<EditPatientActionTypes>) {
    dispatch(editPatientLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL + `/doctorapp/api/onboarding/edit_personal_details`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editPatientLoadingAction(false));
        dispatch(
          editPatientSuccessAction(
            res.data !== undefined ? res.data : ({} as EditPersonalDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "Edit patient successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editPatientLoadingAction(false));
        dispatch(
          editPatientErrorAction(
            {} as EditPersonalDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditPersonalAPIResMsg = () => {
  return function (dispatch: Dispatch<EditPatientActionTypes>) {
    dispatch(editPatientUpdateAPIMsgAction(apiRes as EditPersonalDetailsRes, "", "", 0));
  };
};

export const clearEditPersonalAPIRes = () => {
  return function (dispatch: Dispatch<EditPatientActionTypes>) {
    dispatch(editPatientAPIResClearAction({} as EditPersonalDetailsRes, "", "", 0));
  };
};
