import { GetWeekdayListRes } from "./Model";

export const GET_WEEKDAY_LIST_LOADING =
  "GET_WEEKDAY_LIST_LOADING";
export const GET_WEEKDAY_LIST_SUCCESS =
  "GET_WEEKDAY_LIST_SUCCESS";
export const GET_WEEKDAY_LIST_FAIL =
  "GET_WEEKDAY_LIST_FAIL";
export const GET_WEEKDAY_LIST_DATA =
  "GET_WEEKDAY_LIST_DATA";

export interface WeekdayListDetails {
  weekDays(weekDays: any): unknown;
  getWeekdayListRes: GetWeekdayListRes;
  loading: boolean;
  errRes: string;
}
export interface GetWeekdayListActionLoading {
  type: typeof GET_WEEKDAY_LIST_LOADING;
}
export interface GetWeekdayListActionSuccess {
  type: typeof GET_WEEKDAY_LIST_SUCCESS;
  payload: GetWeekdayListRes;
  errRes: string;
}
export interface GetWeekdayListActionFail {
  type: typeof GET_WEEKDAY_LIST_FAIL;
  payload: GetWeekdayListRes;
  errRes: string;
}
interface GetWeekdayListActionAction {
  type: typeof GET_WEEKDAY_LIST_DATA;
  payload: GetWeekdayListRes;
  errRes: string;
}

export type GetWeekdayListActionTypes =
  | GetWeekdayListActionAction
  | GetWeekdayListActionLoading
  | GetWeekdayListActionSuccess
  | GetWeekdayListActionFail;
