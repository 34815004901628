import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedDegreeList } from "../Model";
import MWUploader from "components/MWUploader";
import { AppState } from "redux/store/Store";
import { DegreeList } from "redux/DegreeListDropdown/Model";
import dayjs, { Dayjs } from "dayjs";
import { AddDoctorEducationDetailsBody } from "redux/AddEducationDetails/Model";
import {
  addDoctorEducationDetails,
  clearAddDoctorEducationDetailsAPIRes,
} from "redux/AddEducationDetails/API";
import { EditEducationDetailsBody } from "redux/EditEducationDetails/Model";
import {
  clearEditDoctorEducationDetailsAPIRes,
  editDoctorEducationDetails,
} from "redux/EditEducationDetails/API";
import { getDoctorEducationDetails } from "redux/GetEducationList/API";
import { getEducationList } from "redux/UserProfile/EducationDetails/EducationList/API";
import MWLoader from "components/MWLoader";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedDegreeList | any;
  selectedItemUpdateState: any;
}
export default function AddDoctorEducationDetails({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setInstitute("");
    setInstituteError("");
    setSelectedDegree(null);
    setSelectedDegreeError("");
    setImageId("");
    dispatch(clearImageUploadAPIRes());
    setSelectedDate(dayjs(new Date()));
    setSelectedToDate(dayjs(new Date()));
    setDescription("");
    selectedItemUpdateState({} as any);
    dispatch(clearAddDoctorEducationDetailsAPIRes());
    setAddEducationDetailsMsg("");
    dispatch(clearEditDoctorEducationDetailsAPIRes());
    setEditEducationDetailsMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const getDoctorDegreeListRes = useSelector(
    (state: AppState) => state.getDoctorDegreeListRes
  );
  const [degreeList, setDegreeList] = useState([] as DegreeList[]);
  useEffect(() => {
    if (
      getDoctorDegreeListRes?.getDoctorDegreeListRes?.degreeList !== undefined
    ) {
      if (
        getDoctorDegreeListRes?.getDoctorDegreeListRes?.degreeList.length > 0
      ) {
        setDegreeList(
          getDoctorDegreeListRes?.getDoctorDegreeListRes?.degreeList
        );
      } else setDegreeList([] as DegreeList[]);
    } else setDegreeList([] as DegreeList[]);
  }, [getDoctorDegreeListRes]);

  const [institute, setInstitute] = useState("");
  const [instituteError, setInstituteError] = useState("");

  const [selectedDegree, setSelectedDegree] = useState<DegreeList | null>(null);
  const selectedDegreeChange = (_event: any, passedVal: DegreeList | any) => {
    setSelectedDegree(passedVal);
  };
  const [selectedDegreeError, setSelectedDegreeError] = useState("");
  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // to Date
  const [toDate, setSelectedToDate] = React.useState<Dayjs | null>(null);
  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
  };
  const [description, setDescription] = useState("");
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  useEffect(() => {
    selectedItem?.name !== undefined
      ? setInstitute(selectedItem?.name)
      : setInstitute("");
    selectedItem?.degreeVal !== undefined && degreeList.length > 0
      ? selectedItem?.degreeVal !== "" && degreeList.length > 0
        ? setSelectedDegree(
            degreeList!.find((o) => o.value === selectedItem?.degreeVal)!
          )
        : setSelectedDegree(null)
      : setSelectedDegree(null);

    if (selectedItem?.fromDate !== undefined) {
      if (selectedItem?.endDate !== "Not Set") {
        let year = selectedItem?.fromDate.split("-")[2];
        let month = selectedItem?.fromDate.split("-")[1];
        let day = selectedItem?.fromDate.split("-")[0];
        let date = year + "-" + month + "-" + day;
        setSelectedDate(dayjs(date));
      }
    }
    if (selectedItem?.fromDate === undefined) {
      setSelectedDate(dayjs(new Date()));
    } else if (selectedItem?.fromDate === "Not Set") {
      setSelectedDate(dayjs(new Date()));
    }

    if (selectedItem?.toDate !== undefined) {
      let year = selectedItem?.toDate.split("-")[2];
      let month = selectedItem?.toDate.split("-")[1];
      let day = selectedItem?.toDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedToDate(dayjs(date));
    }
    if (selectedItem?.toDate === undefined) {
      setSelectedToDate(null);
    } else if (selectedItem?.toDate === "Not Set") {
      setSelectedToDate(null);
    }

    selectedItem?.description !== undefined
      ? selectedItem?.description !== ""
        ? setDescription(selectedItem?.description)
        : setDescription("")
      : setDescription("");
    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== ""
        ? setImageInfo(selectedItem?.supportingDocument)
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, degreeList]);

  const addEducationalDetailsBody = {
    institutename: institute,
    degreeId: String(selectedDegree?.value),
    fromDate: dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
    toDate:
      toDate !== null
        ? dayjs(toDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    documentId: imageId !== "" ? imageId : null,
    description: description,
  } as AddDoctorEducationDetailsBody;

  const editEducationalDetailsBody = {
    educationId: String(selectedItem?.id),
    institutename: institute,
    degreeId: String(selectedDegree?.value),
    fromDate: dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ"),
    toDate:
      toDate !== null
        ? dayjs(toDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    description: description,
  } as EditEducationDetailsBody;

  const submitEducationalDetails = () => {
    if (institute === "" && selectedDegree === null) {
      setInstituteError(
        `${t("userprofilepage.instNameEror", { ns: ["home"] })!}`
      );
      setSelectedDegreeError(
        `${t("userprofilepage.degNotEmp", { ns: ["home"] })!}`
      );
    } else if (institute === "" && selectedDegree !== null) {
      setInstituteError(
        `${t("userprofilepage.instNameEror", { ns: ["home"] })!}`
      );
      setSelectedDegreeError("");
    } else if (institute !== "" && selectedDegree === null) {
      setInstituteError("");
      setSelectedDegreeError(
        `${t("userprofilepage.degNotEmp", { ns: ["home"] })!}`
      );
    } else {
      setInstituteError("");
      setSelectedDegreeError("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Education Details
        dispatch(addDoctorEducationDetails(addEducationalDetailsBody));
      } else {
        // Edit Dr. Education Details
        if (imageId === undefined) {
          // When user does not upload image on patient creation
          dispatch(editDoctorEducationDetails(editEducationalDetailsBody));
        } else {
          dispatch(
            editDoctorEducationDetails(
              Object.assign(editEducationalDetailsBody, {
                documentId: imageId !== "" ? imageId : null,
              })
            )
          );
        }
      }
    }
  };

  const addDoctorEducationDetailsRes = useSelector(
    (state: AppState) => state.addDoctorEducationDetailsRes
  );
  const [addEducationDetailsMsg, setEditEducationDetailsMsg] = useState("");
  const [addEducationDetailsMsgType, setEditEducationDetailsMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    setLoading(addDoctorEducationDetailsRes?.loading);
    if (addDoctorEducationDetailsRes?.successMsg !== "") {
      setEditEducationDetailsMsgType("success");
      setEditEducationDetailsMsg(addDoctorEducationDetailsRes?.successMsg);
      dispatch(getDoctorEducationDetails());
      dispatch(getEducationList());
      closeDialog();
    }
    if (addDoctorEducationDetailsRes?.errorMsg !== "") {
      setEditEducationDetailsMsgType("error");
      setEditEducationDetailsMsg(addDoctorEducationDetailsRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDoctorEducationDetailsRes]);

  const editEducationDetailsRes = useSelector(
    (state: AppState) => state.editEducationDetails
  );
  const [editEducationDetailsMsg, setAddEducationDetailsMsg] = useState("");
  const [editEducationDetailsMsgType, setAddEducationDetailsMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    setLoading(editEducationDetailsRes?.loading);
    if (editEducationDetailsRes?.successMsg !== "") {
      setAddEducationDetailsMsgType("success");
      setAddEducationDetailsMsg(editEducationDetailsRes?.successMsg);
      dispatch(getDoctorEducationDetails());
      dispatch(getEducationList());
      closeDialog();
    }
    if (editEducationDetailsRes?.errorMsg !== "") {
      setAddEducationDetailsMsgType("error");
      setAddEducationDetailsMsg(editEducationDetailsRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEducationDetailsRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {
                  t("userprofilepage.editedudetail", {
                    ns: ["home"],
                  })!
                }
              </Typography>
            ) : (
              <Typography variant="h5">
                {
                  t("userprofilepage.addedudetail", {
                    ns: ["home"],
                  })!
                }
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography style={{ marginBottom: "5px" }}>
                {t("appointmentpage.desc", { ns: ["home"] })!}
              </Typography>
              <TextField
                value={institute}
                onChange={(e) => setInstitute(e.target.value)}
                label={t("userprofilepage.instname", { ns: ["home"] })}
                type="text"
                placeholder={
                  t("userprofilepage.enterinstname", { ns: ["home"] })!
                }
                id="Institutename"
                fullWidth
                size="small"
              />
              {instituteError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {instituteError}
                </Typography>
              ) : null}
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("userprofilepage.degree", { ns: ["home"] })!}
              </Typography>
              <Autocomplete
                value={selectedDegree}
                id="highest-states"
                options={degreeList}
                onChange={(event: any, newValue: any) => {
                  selectedDegreeChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      t("userprofilepage.selDegName", { ns: ["home"] })!
                    }
                  />
                )}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value) =>
                  option.value === value.value
                }
              />
              {selectedDegreeError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {selectedDegreeError}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="doctor-educational-supporting-doc"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("userprofilepage.startdate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("appointmentpage.enddate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={handleToDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                  // maxDate={isEdit ? dayjs(new Date()) : undefined}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                label={t("appointmentpage.desc", { ns: ["home"] })}
                fullWidth
                value={description}
                onChange={(e) => handleDescriptionChange(e.target.value)}
                placeholder={t("appointmentpage.enterdesc", { ns: ["home"] })!}
                multiline
                maxRows={4}
                id="summaryID"
                inputProps={{
                  style: { fontSize: 15 },
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        {addEducationDetailsMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addEducationDetailsMsgType}
              onClose={() => {
                dispatch(clearAddDoctorEducationDetailsAPIRes());
                setAddEducationDetailsMsg("");
              }}
            >
              {addEducationDetailsMsg}
            </Alert>
          </Box>
        ) : null}
        {editEducationDetailsMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editEducationDetailsMsgType}
              onClose={() => {
                dispatch(clearEditDoctorEducationDetailsAPIRes());
                setEditEducationDetailsMsg("");
              }}
            >
              {editEducationDetailsMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              // disabled={checkImgUpload}
              onClick={() => submitEducationalDetails()}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
