import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  Chip,
  AlertColor,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import { getFinalOpinionDetails } from "redux/FinalOpinion/FinalOpinionTable/GetFinalOpinionList/API";
import { GetDetailsForCreatePrescriptionBody } from "redux/FinalOpinion/PrescriptionCreation/GetCreatingPrescriptionDetails/Model";
import { getDetailsForCreatePrescription } from "redux/FinalOpinion/PrescriptionCreation/GetCreatingPrescriptionDetails/API";
import { GeneratePrescriptionBody } from "redux/FinalOpinion/PrescriptionCreation/GeneratePrescriptionApi/Model";
import {
  clearGeneratePrescriptionAPIRes,
  generatePrescription,
} from "redux/FinalOpinion/PrescriptionCreation/GeneratePrescriptionApi/API";
import { GetPrescriptionListBody } from "redux/FinalOpinion/PrescriptionTable/GetPrescriptionList/Model";
import { prescriptionListApi } from "redux/PrescriptionListApi/API";
import {
  clearSaveAsDraftPrescriptionAPIRes,
  saveAsDraftPrescription,
} from "redux/FinalOpinion/PrescriptionCreation/SavePrescriptionApi/API";
import PatientDetailsDialog from "./PatientDetailsDialog";
import { getDetailsForEditPrescription } from "redux/FinalOpinion/PrescriptionCreation/GetEditingPrescriptionDetails/API";
import { GetDetailsForEditPrescriptionBody } from "redux/FinalOpinion/PrescriptionCreation/GetEditingPrescriptionDetails/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import MWLoader from "components/MWLoader";

type Props = {
  itemId?: string | any;
  prescriptionId?: string | any;
  addPrescriptionDialogEnable?: boolean | any;
  addPrescriptionDialogUpdateState?: boolean | any;
};

export default function AddPrescription({
  itemId,
  addPrescriptionDialogEnable,
  addPrescriptionDialogUpdateState,
  prescriptionId,
}: Props) {
  const dispatch = useDispatch();
  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;

  const [dialogState, setDialogState] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [doctorEmail, setDoctorEmail] = useState("");
  const [qualification, setQualification] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [patientName, setPatientName] = useState("");
  const [address, setAddress] = useState("");
  const [age, setAge] = useState("");
  const [signatureImageInfo, setSignatureImageInfo] = useState("");
  const [gender, setGender] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [lmp, setLMP] = useState("");
  const [spo2, setSPO2] = useState("");
  const [bpm, setBPM] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [chiefComplaint, setChiefComplaint] = useState("");
  const [relevantHistory, setRelevantHistory] = useState("");
  const [examination, setExamination] = useState("");
  const [investigation, setInvestigation] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [nextVisitSchedule, setNextVisitSchedule] = useState("");
  const [medVal, setMedVal] = useState<string>("");
  const [medicines, setMedicines] = useState<string[]>([]);
  const addMedicine = () => {
    const newMedicines: string[] = [...medicines, medVal];
    setMedicines(newMedicines);
  };
  const removeMedicine = (index: number): void => {
    let medRemMsg =
      medicines[index] +
      " " +
      `${t("prescriptionPage.medicineRemove", { ns: ["home"] })}`;
    setMedVal(medRemMsg);
    const newMedicines: string[] = medicines;
    newMedicines.splice(index, 1);
    setMedicines(newMedicines);
  };
  let getPrescriptionDetailsBody = {
    caseId: itemId,
    doctorId: String(DoctorID),
  } as GetDetailsForCreatePrescriptionBody;

  useEffect(() => {
    dispatch(getDetailsForCreatePrescription(getPrescriptionDetailsBody));
    if (prescriptionId !== undefined) {
      if (prescriptionId !== "") {
        dispatch(
          getDetailsForEditPrescription({
            caseId: itemId,
            doctorId: String(DoctorID),
            prescriptionId: prescriptionId,
          } as GetDetailsForEditPrescriptionBody)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, prescriptionId]);
  useEffect(() => {
    setDialogState(addPrescriptionDialogEnable);
  }, [addPrescriptionDialogEnable, dialogState]);

  const prescriptionListBody = { caseId: itemId };
  const handleDialogClose = () => {
    setDialogState(false);
    addPrescriptionDialogUpdateState(false);
    dispatch(prescriptionListApi(prescriptionListBody));
    setDoctorName("");
    setDoctorEmail("");
    setQualification("");
    setDoctorAddress("");
    setPhoneNumber("");
    setRegistrationNumber("");
    setSignatureImageInfo("");
    setPatientName("");
    setAddress("");
    setAddress("");
    setAge("");
    setGender("");
    setHeight("");
    setWeight("");
    setLMP("");
    setSPO2("");
    setBPM("");
    setBloodPressure("");
    setChiefComplaint("");
    setDiagnosis("");
    setExamination("");
    setInvestigation("");
    setMedVal("");
    setNextVisitSchedule("");
    setMedicines([] as string[]);
    dispatch(clearGeneratePrescriptionAPIRes());
    setGeneratePrescriptionMsg("");
    dispatch(clearSaveAsDraftPrescriptionAPIRes());
    setGeneratePrescriptionMsg("");
  };

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  // Call GetDetailsForCreatePrescription store
  const getCreatePrescriptionValueRes = useSelector(
    (state: AppState) => state.getDetailsForCreatePrescriptionRes
  );

  // Update the following fields from GetDetailsForCreatePrescription store
  useEffect(() => {
    dispatch(toggleAppBarLoading(getCreatePrescriptionValueRes?.loading));
    if (
      getCreatePrescriptionValueRes?.getDetailsForCreatePrescriptionRes
        ?.phoneNumber !== undefined
    ) {
      let data =
        getCreatePrescriptionValueRes?.getDetailsForCreatePrescriptionRes;
      setDoctorName(data.doctorname);
      setDoctorEmail(data.doctoremail);
      setPhoneNumber(data.phoneNumber);
      setQualification(data.qualification);
      setDoctorAddress(data.doctorAddress);
      setRegistrationNumber(data.registrationNumber);
      setPatientName(data.patientName);
      setAddress(data.patientAdress);
      setAge(data.patientAgeInyears);
      setGender(data.patientGender);
      if (data?.doctorSignature?.doctorSignature?.document !== undefined) {
        setSignatureImageInfo(data.doctorSignature.doctorSignature.document);
      } else setSignatureImageInfo("");
    } else {
      setDoctorName("");
      setDoctorEmail("");
      setPhoneNumber("");
      setQualification("");
      setDoctorAddress("");
      setRegistrationNumber("");
      setPatientName("");
      setAddress("");
      setAge("");
      setGender("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCreatePrescriptionValueRes]);

  const generatePrescriptionBody = {
    doctorId: String(DoctorID),
    caseId: itemId,
    templateId: "1",
    diagnosis: diagnosis,
    height: height,
    weight: weight,
    LMP: lmp,
    spO2: spo2,
    BPM: bpm,
    bloodPressure: bloodPressure,
    chiefCompliants: chiefComplaint,
    relevantPointsFromHistory: relevantHistory,
    examinationsLabFindings: examination,
    suggestedInvestigations: investigation,
    medicines: medicines,
    specialInstructions: specialInstructions,
    nextVisitSchedule: nextVisitSchedule,
    signatureData: signatureImageInfo,
  } as GeneratePrescriptionBody;
  const savePrescriptionAsDraft = () => {
    dispatch(saveAsDraftPrescription(generatePrescriptionBody));
  };

  const signPrescription = () => {
    dispatch(generatePrescription(generatePrescriptionBody));
  };

  const [patientDetailsSecType, setPatientDetailsSectionType] = useState("");
  const [patientDetailsSecTypeDialog, setPatientDetailsSectionTypeDialog] =
    useState(false);
  const updatePatientDetailsSectionTypeDialogValFromChild = (
    passedVal: boolean
  ) => {
    setPatientDetailsSectionTypeDialog(passedVal);
  };
  const selectedItemUpdateState = (passedVal: string) => {
    setPatientDetailsSectionType(passedVal);
  };

  const selectedChiefComplaintUpdatedValueFromChild = (passedVal: string) => {
    if (passedVal !== undefined) {
      if (passedVal !== null) {
        if (passedVal !== "") {
          if (chiefComplaint === "") {
            setChiefComplaint(String(chiefComplaint.concat(passedVal)));
          } else {
            setChiefComplaint(String(chiefComplaint.concat(" \n" + passedVal)));
          }
        }
      }
    }
  };
  const selectedPastHistoryUpdatedValueFromChild = (passedVal: string) => {
    if (passedVal !== undefined) {
      if (passedVal !== null) {
        if (passedVal !== "") {
          if (relevantHistory === "") {
            setRelevantHistory(String(relevantHistory.concat(passedVal)));
          } else {
            setRelevantHistory(
              String(relevantHistory.concat(" \n" + passedVal))
            );
          }
        }
      }
    }
  };
  const selectedDiagnosisUpdatedValueFromChild = (passedVal: string) => {
    if (passedVal !== undefined) {
      if (passedVal !== null) {
        if (passedVal !== "") {
          if (diagnosis === "") {
            setDiagnosis(String(diagnosis.concat(passedVal)));
          } else {
            setDiagnosis(String(diagnosis.concat(" \n" + passedVal)));
          }
        }
      }
    }
  };
  const selectedTestProcedureUpdatedValueFromChild = (passedVal: string) => {
    if (passedVal !== undefined) {
      if (passedVal !== null) {
        if (passedVal !== "") {
          if (examination === "") {
            setExamination(String(examination.concat(passedVal)));
          } else {
            setExamination(String(examination.concat(" \n" + passedVal)));
          }
        }
      }
    }
  };
  const getDetailsForEditPrescriptionRes = useSelector(
    (state: AppState) => state.getDetailsForEditPrescriptionRes
  );

  useEffect(() => {
    dispatch(toggleAppBarLoading(getDetailsForEditPrescriptionRes?.loading));
    if (prescriptionId !== undefined) {
      if (prescriptionId !== "") {
        if (
          getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes !==
          undefined
        ) {
          setHeight(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.height
          );
          setSPO2(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.spO2
          );
          setWeight(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.weight
          );
          setBPM(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.BPM
          );
          setLMP(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.LMP
          );
          setBloodPressure(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.bloodPressure
          );
          setChiefComplaint(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.chiefCompliants
          );
          setRelevantHistory(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.relevantPointsFromHistory
          );
          setDiagnosis(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.diagnosis
          );
          setExamination(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.examinationsLabFindings
          );
          setInvestigation(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.suggestedInvestigations
          );
          setNextVisitSchedule(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.nextVisitSchedule
          );
          setSpecialInstructions(
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.specialInstructions
          );
          if (
            getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
              ?.prescription?.medicineList !== undefined
          ) {
            if (
              getDetailsForEditPrescriptionRes?.getDetailsForEditPrescriptionRes
                ?.prescription?.medicineList !== null
            ) {
              if (
                getDetailsForEditPrescriptionRes
                  ?.getDetailsForEditPrescriptionRes?.prescription?.medicineList
                  .length > 0
              ) {
                let medicineArray: any[] = [];
                for (
                  var i = 0;
                  i <
                  getDetailsForEditPrescriptionRes
                    ?.getDetailsForEditPrescriptionRes?.prescription
                    ?.medicineList.length;
                  i++
                ) {
                  medicineArray.push([
                    getDetailsForEditPrescriptionRes
                      ?.getDetailsForEditPrescriptionRes?.prescription
                      ?.medicineList[i].filling_pharmacy_fullname,
                  ]);
                }
                setMedicines(medicineArray);
              } else setMedicines([] as string[]);
            } else setMedicines([] as string[]);
          } else setMedicines([] as string[]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetailsForEditPrescriptionRes, prescriptionId]);

  const generatePrescriptionRes = useSelector(
    (state: AppState) => state.generatePrescriptionValue
  );
  const [generatePrescriptionMsg, setGeneratePrescriptionMsg] = useState("");
  const [generatePrescriptionMsgType, setGeneratePrescriptionMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (generatePrescriptionRes?.successMsg !== "") {
      setGeneratePrescriptionMsgType("success");
      setGeneratePrescriptionMsg(generatePrescriptionRes?.successMsg);
      dispatch(
        getFinalOpinionDetails({
          caseId: itemId,
          secondOpinionUiSection: "finalOpinion",
        })
      );
      dispatch(
        prescriptionListApi({ caseId: itemId } as GetPrescriptionListBody)
      );
      handleDialogClose();
    }
    if (generatePrescriptionRes?.errorMsg !== "") {
      setGeneratePrescriptionMsgType("error");
      setGeneratePrescriptionMsg(generatePrescriptionRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatePrescriptionRes]);

  const savePrescriptionAsDraftRes = useSelector(
    (state: AppState) => state.savePrescriptionValue
  );
  const [savePrescriptionAsDraftMsg, setSavePrescriptionAsDraftMsg] =
    useState("");
  const [savePrescriptionAsDraftMsgType, setSavePrescriptionAsDraftMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (savePrescriptionAsDraftRes?.successMsg !== "") {
      setSavePrescriptionAsDraftMsgType("success");
      setSavePrescriptionAsDraftMsg(savePrescriptionAsDraftRes?.successMsg);
      dispatch(
        getFinalOpinionDetails({
          caseId: itemId,
          secondOpinionUiSection: "finalOpinion",
        })
      );
      dispatch(
        prescriptionListApi({ caseId: itemId } as GetPrescriptionListBody)
      );
      handleDialogClose();
    }
    if (savePrescriptionAsDraftRes?.errorMsg !== "") {
      setSavePrescriptionAsDraftMsgType("error");
      setSavePrescriptionAsDraftMsg(savePrescriptionAsDraftRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePrescriptionAsDraftRes]);
  return (
    <Box>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={dialogState}
        disableEscapeKeyDown
        PaperProps={{
          style: {
            minHeight: "90vh",
            minWidth: "50vw",
            overflow: "hidden",
          },
        }}
      >
        {generatePrescriptionRes?.loading ||
          (savePrescriptionAsDraftRes?.loading && <MWLoader />)}
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {prescriptionId === undefined ? (
                <Typography variant="h5">
                  {t("appointmentpage.addpres", { ns: ["home"] })!}
                </Typography>
              ) : (
                <Typography variant="h5">
                  {t("appointmentpage.editPres", { ns: ["home"] })!}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleDialogClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item>
              <Alert severity="error">
                <AlertTitle>
                  <strong>{doctorName}</strong>
                </AlertTitle>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                >
                  <Grid item>
                    <strong>
                      {t("prescriptionPage.docQualify", { ns: ["home"] })!}{" "}
                      {qualification}
                    </strong>
                  </Grid>
                  <Grid item>
                    <strong>
                      {t("prescriptionPage.docRegNo", { ns: ["home"] })!}{" "}
                      {registrationNumber}
                    </strong>
                  </Grid>
                  <Grid item>
                    <strong>
                      {t("prescriptionPage.docAddress", { ns: ["home"] })!}{" "}
                      {doctorAddress}
                    </strong>
                  </Grid>
                  <Grid item>
                    <strong>
                      {t("prescriptionPage.docPhno", { ns: ["home"] })!}{" "}
                      {phoneNumber}
                    </strong>
                  </Grid>
                  <Grid item>
                    <strong>
                      {t("prescriptionPage.docEmail", { ns: ["home"] })!}{" "}
                      {doctorEmail}
                    </strong>
                  </Grid>
                </Grid>
              </Alert>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={12} sm={4}>
                  <Grid container>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        views={["year", "month", "day"]}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            inputProps={{ ...params.inputProps }}
                            size="small"
                          />
                        )}
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t("prescriptionPage.docAge", { ns: ["home"] })!}
                    fullWidth
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    inputProps={{
                      readOnly: true,
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    label={t("prescriptionPage.docGen", { ns: ["home"] })!}
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("common.patientname", { ns: ["home"] })}
                    value={patientName}
                    fullWidth
                    onChange={(e) => setPatientName(e.target.value)}
                    inputProps={{
                      readOnly: true,
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t("prescriptionPage.docHeigh", { ns: ["home"] })}
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t("prescriptionPage.presAdrs", { ns: ["home"] })}
                    fullWidth
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    multiline
                    rows={2}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t("prescriptionPage.presSPo2", { ns: ["home"] })}
                    value={spo2}
                    onChange={(e) => setSPO2(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t("prescriptionPage.presBP", { ns: ["home"] })}
                    value={bloodPressure}
                    onChange={(e) => setBloodPressure(e.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t("prescriptionPage.presWgt", { ns: ["home"] })}
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    size="small"
                  />
                </Grid>
                {gender === "Female" ? (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label={t("prescriptionPage.presLMP", { ns: ["home"] })}
                      value={lmp}
                      onChange={(e) => setLMP(e.target.value)}
                      size="small"
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label={t("prescriptionPage.presBPM", { ns: ["home"] })}
                    value={bpm}
                    onChange={(e) => setBPM(e.target.value)}
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item mt={2}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography>
                            {t("prescriptionPage.presCC", { ns: ["home"] })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setPatientDetailsSectionTypeDialog(true);
                              setPatientDetailsSectionType("chiefCompliants");
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {t("prescriptionPage.presGetSummary", {
                              ns: ["home"],
                            })}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item mt={1}>
                        <TextField
                          fullWidth
                          label=""
                          value={chiefComplaint}
                          onChange={(e) => setChiefComplaint(e.target.value)}
                          multiline
                          rows={2}
                          id="finalOpinionDescriptionID"
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography>
                            {t("prescriptionPage.presRelevantHistory", {
                              ns: ["home"],
                            })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setPatientDetailsSectionTypeDialog(true);
                              setPatientDetailsSectionType("pastHistory");
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {t("prescriptionPage.presGetSummary", {
                              ns: ["home"],
                            })}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item mt={1}>
                        <TextField
                          fullWidth
                          label=""
                          value={relevantHistory}
                          onChange={(e) => setRelevantHistory(e.target.value)}
                          multiline
                          rows={2}
                          id="finalOpinionDescriptionID"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography>
                            {t("prescriptionPage.presDagnosis", {
                              ns: ["home"],
                            })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setPatientDetailsSectionTypeDialog(true);
                              setPatientDetailsSectionType("diagnosis");
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {t("prescriptionPage.presGetSummary", {
                              ns: ["home"],
                            })}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item mt={1}>
                        <TextField
                          fullWidth
                          label="Dia"
                          value={diagnosis}
                          onChange={(e) => setDiagnosis(e.target.value)}
                          multiline
                          rows={4}
                          id="finalOpinionDescriptionID"
                        />
                      </Grid>
                    </Grid>
                    <Grid item mt={1}>
                      <Grid item>
                        <Grid item>
                          <Typography>
                            {t("prescriptionPage.presMed", { ns: ["home"] })}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid item xs={12} sm={8}>
                            <TextField
                              fullWidth
                              label=""
                              value={medVal}
                              onChange={(e) => setMedVal(e.target.value)}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Button
                              variant="outlined"
                              onClick={() => addMedicine()}
                            >
                              {t("prescriptionPage.presMedAdd", {
                                ns: ["home"],
                              })}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography>
                            {t("prescriptionPage.presLabFind", {
                              ns: ["home"],
                            })}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setPatientDetailsSectionTypeDialog(true);
                              setPatientDetailsSectionType(
                                "testsProceduresandInvestigations"
                              );
                            }}
                            sx={{ textTransform: "none" }}
                          >
                            {t("prescriptionPage.presGetSummary", {
                              ns: ["home"],
                            })}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item mt={1}>
                        <TextField
                          fullWidth
                          label=""
                          value={examination}
                          onChange={(e) => setExamination(e.target.value)}
                          multiline
                          rows={2}
                          id="finalOpinionDescriptionID"
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid item>
                        <Grid item>
                          <Typography>
                            {t("prescriptionPage.presInvestigation", {
                              ns: ["home"],
                            })}
                          </Typography>
                        </Grid>
                        <Grid item mt={1}>
                          <TextField
                            fullWidth
                            label=""
                            value={investigation}
                            onChange={(e) => setInvestigation(e.target.value)}
                            multiline
                            rows={2}
                            id="finalOpinionDescriptionID"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Grid item>
                            <Typography>
                              {t("prescriptionPage.presVisit", {
                                ns: ["home"],
                              })}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item mt={1}>
                          <TextField
                            fullWidth
                            label=""
                            value={nextVisitSchedule}
                            onChange={(e) =>
                              setNextVisitSchedule(e.target.value)
                            }
                            id="finalOpinionDescriptionID"
                            size="small"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    {medicines.map((medicine: string, index: number) => (
                      <Chip
                        key={index}
                        label={medicine}
                        onDelete={() => removeMedicine(index)}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} mb={4}>
              <TextField
                fullWidth
                label={t("prescriptionPage.presInstruction", { ns: ["home"] })}
                value={specialInstructions}
                onChange={(e) => setSpecialInstructions(e.target.value)}
                multiline
                rows={2}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>{t("prescriptionPage.docSig", { ns: ["home"] })}</Grid>
              <Grid item>
                <Box>
                  <img
                    src={signatureImageInfo}
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {generatePrescriptionMsg !== "" ? (
            <Box my={2}>
              <Alert
                severity={generatePrescriptionMsgType}
                onClose={() => {
                  dispatch(clearGeneratePrescriptionAPIRes());
                  setGeneratePrescriptionMsg("");
                }}
              >
                {generatePrescriptionMsg}
              </Alert>
            </Box>
          ) : null}
          {savePrescriptionAsDraftMsg !== "" ? (
            <Box my={2}>
              <Alert
                severity={savePrescriptionAsDraftMsgType}
                onClose={() => {
                  dispatch(clearSaveAsDraftPrescriptionAPIRes());
                  setSavePrescriptionAsDraftMsg("");
                }}
              >
                {savePrescriptionAsDraftMsg}
              </Alert>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            color="primary"
            style={{ fontSize: "12px", margin: "5px" }}
            onClick={savePrescriptionAsDraft}
          >
            {t("appointmentpage.savedraft", { ns: ["home"] })!}
          </Button>
          <Button
            autoFocus
            variant="contained"
            color="primary"
            onClick={signPrescription}
          >
            {t("appointmentpage.signPres", { ns: ["home"] })!}
          </Button>
        </DialogActions>
      </Dialog>
      {patientDetailsSecTypeDialog ? (
        <PatientDetailsDialog
          dialogEnable={patientDetailsSecTypeDialog}
          dialogUpdateState={updatePatientDetailsSectionTypeDialogValFromChild}
          selectedItem={patientDetailsSecType}
          selectedItemUpdateState={selectedItemUpdateState}
          selectedChiefComplaintUpdatedValue={
            selectedChiefComplaintUpdatedValueFromChild
          }
          selectedPastHistoryUpdatedValue={
            selectedPastHistoryUpdatedValueFromChild
          }
          selectedDiagnosisUpdatedValue={selectedDiagnosisUpdatedValueFromChild}
          selectedTestProcedureUpdatedValue={
            selectedTestProcedureUpdatedValueFromChild
          }
        />
      ) : null}
    </Box>
  );
}
