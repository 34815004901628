import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { getProfessionalSummary } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/GetProfessionalSummary/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { EditProfessionalSummaryBody } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/EditProfessionalSummary/Model";
import {
  clearEditDoctorProfessionalSummaryAPIRes,
  editProfessionalSummary,
} from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/EditProfessionalSummary/API";
import { getSpecialtyList } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/GetSpecialtyList/API";
import { DoctorSpecialtyList } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/GetSpecialtyList/Model";
export interface Props {
  professionalSummaryLoading: boolean | any;
}
export default function ProfessionSummary({
  professionalSummaryLoading,
}: Props) {
  const dispatch = useDispatch();
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [isEdit, setIsEdit] = useState(true);

  const [highestQualification, setHighestQualification] = useState("0");

  const [privatePractice, setPrivatePractice] = useState("0");

  const [specialtyList, setSpecialtyList] = useState(
    [] as DoctorSpecialtyList[]
  );
  const [selectedSpecialty, setSelectedSpecialty] =
    useState<DoctorSpecialtyList | null>(null);
  const selectedSpecialtyChange = (
    _event: any,
    passedVal: DoctorSpecialtyList | any
  ) => {
    setSelectedSpecialty(passedVal);
  };

  const editDoctorProfessionalSummaryBody = {
    totalYearsOfPracticeAfterHighestQualification: highestQualification,
    totalYearsOfPrivatePracticeAfterHighestQualification: privatePractice,
    doctorSpecialty:
      selectedSpecialty !== null ? String(selectedSpecialty?.value) : null,
  } as EditProfessionalSummaryBody;

  const professionSummarySubmit = () => {
    dispatch(editProfessionalSummary(editDoctorProfessionalSummaryBody));
  };

  useEffect(() => {
    dispatch(getSpecialtyList());
    dispatch(getProfessionalSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call Specialty List store
  const getSpecialtyListRes = useSelector(
    (state: AppState) => state.specialtyListRes
  );
  useEffect(() => {
    professionalSummaryLoading(getSpecialtyListRes?.loading);
    getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList !== undefined
      ? setSpecialtyList(
          getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList
        )
      : setSpecialtyList([] as DoctorSpecialtyList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSpecialtyListRes]);

  // Call Professional summary store
  const getProfessionalSummaryRes = useSelector(
    (state: AppState) => state.getProfessionalSummaryValue
  );
  // Get Professional summary
  useEffect(() => {
    professionalSummaryLoading(getProfessionalSummaryRes?.loading);
    getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
      ?.workSummaryDetails?.totalYearsOfPracticeAfterHighestQualification !==
    undefined
      ? setHighestQualification(
          String(
            getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
              ?.workSummaryDetails.totalYearsOfPracticeAfterHighestQualification
          )
        )
      : setHighestQualification("0");
    getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
      ?.workSummaryDetails
      ?.totalYearsOfPrivatePracticeAfterHighestQualification !== undefined
      ? setPrivatePractice(
          String(
            getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
              ?.workSummaryDetails
              .totalYearsOfPrivatePracticeAfterHighestQualification
          )
        )
      : setPrivatePractice("0");
    getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
      ?.workSummaryDetails?.doctorSpecialty !== null &&
    getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList !== undefined
      ? getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
          ?.workSummaryDetails?.doctorSpecialty !== undefined &&
        getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList !== undefined
        ? getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
            ?.workSummaryDetails?.doctorSpecialty?.value !== undefined &&
          getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList !==
            undefined
          ? setSelectedSpecialty(
              getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList.find(
                (element: DoctorSpecialtyList) =>
                  element.value ===
                  getProfessionalSummaryRes?.getDoctorProfessionalSummaryRes
                    ?.workSummaryDetails?.doctorSpecialty?.value
              )!
            )
          : setSelectedSpecialty(null)
        : setSelectedSpecialty(null)
      : setSelectedSpecialty(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfessionalSummaryRes, getSpecialtyListRes]);

  const [
    updateProfessionalSummaryMessage,
    setUpdateProfessionalSummaryMessage,
  ] = useState("");
  const [updateProfessionalSummaryType, setUpdateProfessionalSummaryType] =
    useState<AlertColor>("success");

  // Call edit Professional summary store
  const editProfessionalSummaryRes = useSelector(
    (state: AppState) => state.editProfessionalSummaryRes
  );
  // edit professional summary
  useEffect(() => {
    professionalSummaryLoading(editProfessionalSummaryRes?.loading);
    if (editProfessionalSummaryRes?.successMsg !== "") {
      setUpdateProfessionalSummaryType("success");
      setUpdateProfessionalSummaryMessage(
        editProfessionalSummaryRes?.successMsg
      );
      dispatch(getProfessionalSummary());
      setIsEdit((isEdit) => !isEdit);
      setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
    }
    if (editProfessionalSummaryRes?.errorMsg !== "") {
      setUpdateProfessionalSummaryType("error");
      setUpdateProfessionalSummaryMessage(editProfessionalSummaryRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editProfessionalSummaryRes]);

  return (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("userprofilepage.profsum", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                {isEdit
                  ? `${t("userprofilepage.edit", { ns: ["home"] })}`
                  : `${t("accountdetail.cancel", { ns: ["home"] })}`}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              label={t("userprofilepage.yearofpractice", { ns: ["home"] })}
              value={highestQualification}
              type="number"
              onChange={(event) => setHighestQualification(event?.target.value)}
              placeholder={
                t("userprofilepage.yearofpractice", { ns: ["home"] })!
              }
              disabled={isEdit}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              fullWidth
              label={t("userprofilepage.yearofprivateprac", { ns: ["home"] })}
              value={privatePractice}
              type="number"
              onChange={(event) => setPrivatePractice(event.target.value)}
              placeholder={
                t("userprofilepage.yearofprivateprac", { ns: ["home"] })!
              }
              disabled={isEdit}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>
              {t("userprofilepage.docspeciality", { ns: ["home"] })}
            </Typography>
            <Autocomplete
              value={selectedSpecialty}
              id="doctor-specialty"
              options={specialtyList}
              onChange={(event: any, newValue: any) => {
                selectedSpecialtyChange(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("appointmentpage.selSpec", { ns: ["home"] })!}
                />
              )}
              disabled={isEdit}
              size="small"
              getOptionLabel={(option: any) => option?.label! || ""}
              isOptionEqualToValue={(option: any, value) =>
                option.value === value.value
              }
            />
          </Grid>
          {updateProfessionalSummaryMessage !== "" ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                severity={updateProfessionalSummaryType}
                onClose={() => {
                  dispatch(clearEditDoctorProfessionalSummaryAPIRes());
                  setUpdateProfessionalSummaryMessage("");
                }}
              >
                {updateProfessionalSummaryMessage}
              </Alert>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="contained"
              disabled={isEnableSubmit || getProfessionalSummaryRes?.loading}
              onClick={() => {
                professionSummarySubmit();
              }}
              sx={{ textTransform: "none" }}
            >
              {`${t("userprofilepage.subdetail", { ns: ["home"] })}`}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
