import {
    doctorPersonalDetailsByIdDetails,
    doctorPersonalDetailsByIdActionTypes,
   PERSONAL_DETAILS_BY_ID_FAIL,
   PERSONAL_DETAILS_BY_ID_LOADING,
   PERSONAL_DETAILS_BY_ID_SUCCESS,
  } from "./ActionTypes";
  import { doctorPersonalDetailsByIdValue } from "./Model";
  
  const initialStateGetPosts: doctorPersonalDetailsByIdDetails = {
    loading: false,
    doctorPersonalDetailsByIdRes: {} as doctorPersonalDetailsByIdValue,
    errRes: "",
  };
  export const doctorPersonalDetailsByIdReducer = (
    state = initialStateGetPosts,
    action: doctorPersonalDetailsByIdActionTypes
  ): doctorPersonalDetailsByIdDetails => {
    switch (action.type) {
      case PERSONAL_DETAILS_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          doctorPersonalDetailsByIdRes: action.payload,
          errRes: "",
        };
      case PERSONAL_DETAILS_BY_ID_LOADING:
        return {
          ...state,
          loading: true,
          doctorPersonalDetailsByIdRes: {} as doctorPersonalDetailsByIdValue,
        };
      case PERSONAL_DETAILS_BY_ID_FAIL:
        return {
          ...state,
          doctorPersonalDetailsByIdRes: {} as doctorPersonalDetailsByIdValue,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  