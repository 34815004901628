import {
  GENERATE_DOCTOR_AGREEMENT_FAIL,
  GENERATE_DOCTOR_AGREEMENT_LOADING,
  GENERATE_DOCTOR_AGREEMENT_SUCCESS,
  GENERATE_DOCTOR_AGREEMENT_API_MSG,
  GENERATE_DOCTOR_AGREEMENT_API_RES,
  GenerateDoctorAgreementActionTypes,
} from "./ActionTypes";
import { GenerateAgreementRes } from "./Model";

export const generateDoctorAgreementLoadingAction = (
  loading: boolean
): GenerateDoctorAgreementActionTypes => {
  return {
    type: GENERATE_DOCTOR_AGREEMENT_LOADING,
    loading: loading,
  };
};

export const generateDoctorAgreementSuccessAction = (
  generateDoctorAgreementResponse: GenerateAgreementRes,
  successMsg: string
): GenerateDoctorAgreementActionTypes => {
  return {
    type: GENERATE_DOCTOR_AGREEMENT_SUCCESS,
    payload: generateDoctorAgreementResponse,
    successMsg: successMsg,
  };
};

export const generateDoctorAgreementErrorAction = (
  generateDoctorAgreementResponse: GenerateAgreementRes,
  errMsg: string,
  status: number
): GenerateDoctorAgreementActionTypes => {
  return {
    type: GENERATE_DOCTOR_AGREEMENT_FAIL,
    payload: generateDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const generateDoctorAgreementAPIMsgAction = (
  generateDoctorAgreementResponse: GenerateAgreementRes,
  errMsg: string,
  status: number
): GenerateDoctorAgreementActionTypes => {
  return {
    type: GENERATE_DOCTOR_AGREEMENT_API_MSG,
    payload: generateDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const generateDoctorAgreementAPIResClearAction = (
  generateDoctorAgreementResponse: GenerateAgreementRes,
  errMsg: string,
  status: number
): GenerateDoctorAgreementActionTypes => {
  return {
    type: GENERATE_DOCTOR_AGREEMENT_API_RES,
    payload: generateDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};
