import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { AddEmailPhoneTypes } from "./ActionTypes";
import { AddEmailPhoneBody, AddEmailPhoneRes } from "./Model";
import {
  addEmailPhoneAPIResClearAction,
  addEmailPhoneErrorAction,
  addEmailPhoneLoadingAction,
  addEmailPhoneSuccessAction,
  addEmailPhoneUpdateAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddEmailPhoneRes;
export const addEmailPhoneAPI = (payload: AddEmailPhoneBody) => {
  return function (dispatch: Dispatch<AddEmailPhoneTypes>) {
    dispatch(addEmailPhoneLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/profileapp/api/userDetails/set_email_or_phone`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addEmailPhoneLoadingAction(false));
        dispatch(
          addEmailPhoneSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Phone number added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addEmailPhoneLoadingAction(false));
        dispatch(
          addEmailPhoneErrorAction(
            {} as AddEmailPhoneRes,
            error.response !== undefined
              ? error.response.data !== undefined
                ? error.response.data.message !== undefined
                  ? error.response.data.message
                  : error.response.data.detail !== undefined
                  ? error.response.data.detail
                  : error.response.data.non_field_errors[0] !== undefined
                  ? error.response.data.non_field_errors[0]
                  : "Something went wrong"
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddEmailPhoneAPIResMsg = () => {
  return function (dispatch: Dispatch<AddEmailPhoneTypes>) {
    dispatch(addEmailPhoneUpdateAPIMsgAction(apiRes as AddEmailPhoneRes, "", "", 0));
  };
};

export const clearAddEmailPhoneAPIRes = () => {
  return function (dispatch: Dispatch<AddEmailPhoneTypes>) {
    dispatch(addEmailPhoneAPIResClearAction({} as AddEmailPhoneRes, "", "", 0));
  };
};
