import { AddDrProfessionalLicenseRes } from "./Model";
export const ADD_DR_WORK_LICENSE_LOADING = "ADD_DR_WORK_LICENSE_LOADING";
export const ADD_DR_WORK_LICENSE_SUCCESS = "ADD_DR_WORK_LICENSE_SUCCESS";
export const ADD_DR_WORK_LICENSE_FAIL = "ADD_DR_WORK_LICENSE_FAIL";
export const ADD_DR_WORK_LICENSE_API_MSG = "ADD_DR_WORK_LICENSE_API_MSG";
export const ADD_DR_WORK_LICENSE_API_RES = "ADD_DR_WORK_LICENSE_API_RES";

export interface AddDrLicenseState {
  addDrLicenseRes: AddDrProfessionalLicenseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrLicenseLoading {
  type: typeof ADD_DR_WORK_LICENSE_LOADING;
  loading: boolean;
}
export interface AddDrLicenseSuccess {
  type: typeof ADD_DR_WORK_LICENSE_SUCCESS;
  payload: AddDrProfessionalLicenseRes;
  successMsg: string;
}
export interface AddDrLicenseFail {
  type: typeof ADD_DR_WORK_LICENSE_FAIL;
  payload: AddDrProfessionalLicenseRes;
  errorMsg: string;
  status: number;
}
export interface AddDrLicenseAPIMsg {
  type: typeof ADD_DR_WORK_LICENSE_API_MSG;
  payload: AddDrProfessionalLicenseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrLicenseAPIRes {
  type: typeof ADD_DR_WORK_LICENSE_API_RES;
  payload: AddDrProfessionalLicenseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrLicenseActionTypes =
  | AddDrLicenseLoading
  | AddDrLicenseSuccess
  | AddDrLicenseFail
  | AddDrLicenseAPIMsg
  | AddDrLicenseAPIRes;
