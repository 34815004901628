import { ResetPasswordRes } from "./Model";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_UPDATE_API_MSG = "RESET_PASSWORD_UPDATE_API_MSG";
export const RESET_PASSWORD_UPDATE_API_RES = "RESET_PASSWORD_UPDATE_API_RES";

export interface ResetPasswordState {
  resetPasswordRes: ResetPasswordRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ResetPasswordLoading {
  type: typeof RESET_PASSWORD_LOADING;
  loading: boolean;
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: ResetPasswordRes;
  successMsg: string;
}
export interface ResetPasswordFail {
  type: typeof RESET_PASSWORD_FAIL;
  payload: ResetPasswordRes;
  errorMsg: string;
  status: number;
}
export interface ResetPasswordUpdateAPIMsg {
  type: typeof RESET_PASSWORD_UPDATE_API_MSG;
  payload: ResetPasswordRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ResetPasswordUpdateAPIRes {
  type: typeof RESET_PASSWORD_UPDATE_API_RES;
  payload: ResetPasswordRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type JwtTokenActionTypes =
  | ResetPasswordLoading
  | ResetPasswordSuccess
  | ResetPasswordFail
  | ResetPasswordUpdateAPIMsg
  | ResetPasswordUpdateAPIRes;
