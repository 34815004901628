import {
  GetDoctorDetailsActionTypes,
  GET_DOCTOR_EDUCATION_DETAILS_FAIL,
  GET_DOCTOR_EDUCATION_DETAILS_LOADING,
  GET_DOCTOR_EDUCATION_DETAILS_SUCCESS,
  GetDoctorEducationDetailsState,
  GET_DOCTOR_EDUCATION_DETAILS_API_MSG,
  GET_DOCTOR_EDUCATION_DETAILS_API_RES,
} from "./ActionTypes";
import { GetEducationDetailsRes } from "./Model";

const initialStateUpdatePosts: GetDoctorEducationDetailsState = {
  loading: false,
  getDoctorEducationDetailsRes: {} as GetEducationDetailsRes,
  errorMsg: "",
  status: 0,
};
export const getDoctorEducationDetailsReducer = (
  state = initialStateUpdatePosts,
  action: GetDoctorDetailsActionTypes
): GetDoctorEducationDetailsState => {
  switch (action.type) {
    case GET_DOCTOR_EDUCATION_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_EDUCATION_DETAILS_SUCCESS:
      return {
        ...state,
        getDoctorEducationDetailsRes: action.payload,
      };
    case GET_DOCTOR_EDUCATION_DETAILS_FAIL:
      return {
        ...state,
        getDoctorEducationDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_EDUCATION_DETAILS_API_MSG:
      return {
        ...state,
        getDoctorEducationDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_EDUCATION_DETAILS_API_RES:
      return {
        ...state,
        getDoctorEducationDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
