import { EmailPhoneOTPGenRes } from "./Model";
export const EMAIL_PHONE_OTP_GEN_LOADING = "EMAIL_PHONE_OTP_GEN_LOADING";
export const EMAIL_PHONE_OTP_GEN_SUCCESS = "EMAIL_PHONE_OTP_GEN_SUCCESS";
export const EMAIL_PHONE_OTP_GEN_FAIL = "EMAIL_PHONE_OTP_GEN_FAIL";
export const EMAIL_PHONE_OTP_GEN_UPDATE_API_MSG =
  "EMAIL_PHONE_OTP_GEN_UPDATE_API_MSG";
export const EMAIL_PHONE_OTP_GEN_UPDATE_API_RES =
  "EMAIL_PHONE_OTP_GEN_UPDATE_API_RES";

export interface EmailPhoneOTPGenState {
  emailPhoneOTPGenRes: EmailPhoneOTPGenRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EmailPhoneOTPGenLoading {
  type: typeof EMAIL_PHONE_OTP_GEN_LOADING;
  loading: boolean;
}
export interface EmailPhoneOTPGenSuccess {
  type: typeof EMAIL_PHONE_OTP_GEN_SUCCESS;
  payload: EmailPhoneOTPGenRes;
  successMsg: string;
}
export interface EmailPhoneOTPGenFail {
  type: typeof EMAIL_PHONE_OTP_GEN_FAIL;
  payload: EmailPhoneOTPGenRes;
  errorMsg: string;
  status: number;
}
export interface EmailPhoneOTPGenUpdateAPIMsg {
  type: typeof EMAIL_PHONE_OTP_GEN_UPDATE_API_MSG;
  payload: EmailPhoneOTPGenRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EmailPhoneOTPGenUpdateAPIRes {
  type: typeof EMAIL_PHONE_OTP_GEN_UPDATE_API_RES;
  payload: EmailPhoneOTPGenRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EmailPhoneOTPGenActionTypes =
  | EmailPhoneOTPGenLoading
  | EmailPhoneOTPGenSuccess
  | EmailPhoneOTPGenFail
  | EmailPhoneOTPGenUpdateAPIMsg
  | EmailPhoneOTPGenUpdateAPIRes;
