import {
  GetDetailsForEditPrescriptionActionTypes,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_FAIL,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_LOADING,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_SUCCESS,
  GetDetailsForEditPrescriptionState,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_MSG,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_RES,
} from "./ActionTypes";
import { GetDetailsForEditPrescriptionRes } from "./Model";

const initialStateEditPosts: GetDetailsForEditPrescriptionState = {
  loading: false,
  getDetailsForEditPrescriptionRes: {} as GetDetailsForEditPrescriptionRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDetailsForEditPrescriptionReducer = (
  state = initialStateEditPosts,
  action: GetDetailsForEditPrescriptionActionTypes
): GetDetailsForEditPrescriptionState => {
  switch (action.type) {
    case GET_DETAILS_FOR_EDIT_PRESCRIPTION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DETAILS_FOR_EDIT_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        getDetailsForEditPrescriptionRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DETAILS_FOR_EDIT_PRESCRIPTION_FAIL:
      return {
        ...state,
        getDetailsForEditPrescriptionRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_MSG:
      return {
        ...state,
        getDetailsForEditPrescriptionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_RES:
      return {
        ...state,
        getDetailsForEditPrescriptionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
