import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../URL";
import { setupInterceptorsTo } from "../../authentication/Interceptors";
import {
  deleteSelectedDoctorActionTypes,
  DELETE_SELECTED_DOCTOR_FAIL,
  DELETE_SELECTED_DOCTOR_LOADING,
  DELETE_SELECTED_DOCTOR_SUCCESS,
} from "./ActionTypes";
import {
  DeleteSelectedDoctorDetails,
  DeleteSelectedDoctorValue,
} from "./Model";
import { headers } from "components/Utility";

export const deleteSelectedDoctorApi = (body: DeleteSelectedDoctorDetails) => {
  return function (dispatch: Dispatch<deleteSelectedDoctorActionTypes>) {
    dispatch({
      type: DELETE_SELECTED_DOCTOR_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          `/patientapp/api/patientMedicalData/delete_selected_doctor`,
        body,
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch({
          type: DELETE_SELECTED_DOCTOR_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SELECTED_DOCTOR_FAIL,
          payload: {} as DeleteSelectedDoctorValue,
          errRes: error.response.data.message,
        });
      });
  };
};
