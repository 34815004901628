import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../../URL";
import { GetDetailsForCreatePrescriptionActionTypes } from "./ActionTypes";
import {
  GetDetailsForCreatePrescriptionBody,
  GetDetailsForCreatePrescriptionRes,
} from "./Model";
import {
  getDetailsForCreatePrescriptionUpdateAPIResClearAction,
  getDetailsForCreatePrescriptionErrorAction,
  getDetailsForCreatePrescriptionLoadingAction,
  getDetailsForCreatePrescriptionSuccessAction,
  getDetailsForCreatePrescriptionUpdateAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetDetailsForCreatePrescriptionRes;
export const getDetailsForCreatePrescription = (
  payload: GetDetailsForCreatePrescriptionBody
) => {
  return function (
    dispatch: Dispatch<GetDetailsForCreatePrescriptionActionTypes>
  ) {
    dispatch(getDetailsForCreatePrescriptionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          `/patientapp/api/prescriptiongenerate/get_details_for_creating_prescription`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDetailsForCreatePrescriptionLoadingAction(false));
        dispatch(
          getDetailsForCreatePrescriptionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Data has been added successfully for create prescription"
          )
        );
      })
      .catch((error) => {
        dispatch(getDetailsForCreatePrescriptionLoadingAction(false));
        dispatch(
          getDetailsForCreatePrescriptionErrorAction(
            {} as GetDetailsForCreatePrescriptionRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetDetailsForCreatePrescriptionAPIResMsg = () => {
  return function (
    dispatch: Dispatch<GetDetailsForCreatePrescriptionActionTypes>
  ) {
    dispatch(
      getDetailsForCreatePrescriptionUpdateAPIMsgAction(
        apiRes as GetDetailsForCreatePrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDetailsForCreatePrescriptionAPIRes = () => {
  return function (
    dispatch: Dispatch<GetDetailsForCreatePrescriptionActionTypes>
  ) {
    dispatch(
      getDetailsForCreatePrescriptionUpdateAPIResClearAction(
        {} as GetDetailsForCreatePrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};
