import {
  GET_GENDER_LIST_API_MSG,
  GET_GENDER_LIST_API_RES,
  GET_GENDER_LIST_FAIL,
  GET_GENDER_LIST_LOADING,
  GET_GENDER_LIST_SUCCESS,
  GetGenderListActionTypes,
} from "./ActionTypes";
import { GetGenderListRes } from "./Model";

export const getGenderListLoadingAction = (
  loading: boolean
): GetGenderListActionTypes => {
  return {
    type: GET_GENDER_LIST_LOADING,
    loading: loading,
  };
};

export const getGenderListSuccessAction = (
  getGenderListRes: GetGenderListRes,
  successMsg: string
): GetGenderListActionTypes => {
  return {
    type: GET_GENDER_LIST_SUCCESS,
    payload: getGenderListRes,
    successMsg: successMsg,
  };
};

export const getGenderListErrorAction = (
  getGenderListRes: GetGenderListRes,
  errMsg: string,
  status: number
): GetGenderListActionTypes => {
  return {
    type: GET_GENDER_LIST_FAIL,
    payload: getGenderListRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const getGenderListUpdateAPIMsgAction = (
  getGenderListRes: GetGenderListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetGenderListActionTypes => {
  return {
    type: GET_GENDER_LIST_API_MSG,
    payload: getGenderListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getGenderListAPIResClearAction = (
  getGenderListRes: GetGenderListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetGenderListActionTypes => {
  return {
    type: GET_GENDER_LIST_API_RES,
    payload: getGenderListRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
