import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { AuthorizeDoctorForZoomTeleHealthActionTypes } from "./ActionTypes";
import { AuthorizeDoctorForZoomTeleHealthBody, AuthorizeDoctorForZoomTeleHealthRes } from "./Model";
import {
  authorizeDoctorForZoomTeleHealthAPIResClearAction,
  authorizeDoctorForZoomTeleHealthErrorAction,
  authorizeDoctorForZoomTeleHealthLoadingAction,
  authorizeDoctorForZoomTeleHealthSuccessAction,
  authorizeDoctorForZoomTeleHealthAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AuthorizeDoctorForZoomTeleHealthRes;
export const authorizeDoctorForZoomTeleHealth = (payload: AuthorizeDoctorForZoomTeleHealthBody) => {
  return function (dispatch: Dispatch<AuthorizeDoctorForZoomTeleHealthActionTypes>) {
    dispatch(authorizeDoctorForZoomTeleHealthLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/telehealthapp/api/telehealth/authorizeTelehealth`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(authorizeDoctorForZoomTeleHealthLoadingAction(false));
        dispatch(
          authorizeDoctorForZoomTeleHealthSuccessAction(
            res.data,
            res.data.message !== undefined ? res.data.message : "",
          ),
        );
      })
      .catch((error) => {
        dispatch(authorizeDoctorForZoomTeleHealthLoadingAction(false));
        dispatch(
          authorizeDoctorForZoomTeleHealthErrorAction(
            {} as AuthorizeDoctorForZoomTeleHealthRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0,
          ),
        );
      });
  };
};

export const updateAuthorizeDoctorForZoomTeleHealthAPIResMsg = () => {
  return function (dispatch: Dispatch<AuthorizeDoctorForZoomTeleHealthActionTypes>) {
    dispatch(
      authorizeDoctorForZoomTeleHealthAPIMsgAction(
        apiRes as AuthorizeDoctorForZoomTeleHealthRes,
        "",
        "",
        0,
      ),
    );
  };
};

export const clearAuthorizeDoctorForZoomTeleHealthAPIRes = () => {
  return function (dispatch: Dispatch<AuthorizeDoctorForZoomTeleHealthActionTypes>) {
    dispatch(
      authorizeDoctorForZoomTeleHealthAPIResClearAction(
        {} as AuthorizeDoctorForZoomTeleHealthRes,
        "",
        "",
        0,
      ),
    );
  };
};
