import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { DrawerList } from "../DrawerList";
import { logOut } from "../../redux/logOut/API";
import { LogOutBody } from "redux/logOut/Model";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import { t } from "i18next";

type Props = {
  drawerState: boolean;
  updateDrawerState: (value: boolean) => void;
};

const drawerWidth = 200;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AppDrawer({ drawerState, updateDrawerState }: Props) {
  const dispatch = useDispatch();
  const Bearer = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");
  const logOutBody = {
    refresh_token: refresh!,
  } as LogOutBody;
  async function handleLogout() {
    dispatch(logOut(logOutBody));
  }

  const logOutRes = useSelector((state: AppState) => state.logOutRes);
  useEffect(() => {
    dispatch(toggleAppBarLoading(logOutRes?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOutRes]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  useEffect(() => {
    setIsDrawerOpen(drawerState);
  }, [drawerState]);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    if (!isDrawerOpen) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isDrawerOpen) {
      setIsHovered(false);
    }
  };
  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
    setIsHovered(false);
  };

  const DrawerBody = (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Toolbar />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <List
                  style={{
                    minWidth: "100%",
                    height: "calc(100vh - 22vh)",
                  }}
                  dense
                >
                  {DrawerList.slice(0, 1).map((item) => {
                    return (
                      <ListItem
                        activeClassName="Mui-selected"
                        component={NavLink}
                        to={item.path}
                        key={item.name}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 32,
                            justifyContent:
                              isDrawerOpen || isHovered ? "initial" : "center",
                            px: 2.5,
                          }}
                          onClick={() => {
                            if (fullScreen) {
                              updateDrawerState(false);
                              setIsDrawerOpen(false);
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: isDrawerOpen || isHovered ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              t(`common.${item.name}`, {
                                ns: ["home"],
                              })!
                            }
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                            sx={{
                              opacity: isDrawerOpen || isHovered ? 1 : 0,
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  {!fullScreen && <Divider />}
                  {DrawerList.slice(1, 4).map((item) => {
                    return (
                      <ListItem
                        component={NavLink}
                        activeClassName="Mui-selected"
                        to={item.path}
                        key={item.name}
                        disablePadding
                        sx={{ display: "block" }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 32,
                            justifyContent:
                              isDrawerOpen || isHovered ? "initial" : "center",
                            px: 2.5,
                          }}
                          onClick={() => {
                            if (fullScreen) {
                              updateDrawerState(false);
                              setIsDrawerOpen(false);
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: isDrawerOpen || isHovered ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              t(`common.${item.name}`, {
                                ns: ["home"],
                              })!
                            }
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                            sx={{
                              opacity: isDrawerOpen || isHovered ? 1 : 0,
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Divider />
            </Box>
            <List
              dense
              style={{
                width: "100%",
              }}
            >
              {DrawerList.slice(-3, -1).map((item) => {
                return (
                  <ListItem
                    component={NavLink}
                    activeClassName="Mui-selected"
                    to={item.path}
                    key={item.name}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 32,
                        justifyContent:
                          isDrawerOpen || isHovered ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        if (fullScreen) {
                          updateDrawerState(false);
                          setIsDrawerOpen(false);
                        }
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isDrawerOpen || isHovered ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          t(`common.${item.name}`, {
                            ns: ["home"],
                          })!
                        }
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                        sx={{
                          opacity: isDrawerOpen || isHovered ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
              {DrawerList.slice(-1).map((item) => {
                return (
                  <ListItem
                    key={item.name}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={() => {
                        handleLogout();
                        if (fullScreen) {
                          updateDrawerState(false);
                          setIsDrawerOpen(false);
                        }
                      }}
                      sx={{
                        minHeight: 32,
                        justifyContent:
                          isDrawerOpen || isHovered ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: isDrawerOpen || isHovered ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          t(`common.${item.name}`, {
                            ns: ["home"],
                          })!
                        }
                        primaryTypographyProps={{
                          fontSize: 14,
                          fontWeight: "medium",
                        }}
                        sx={{
                          opacity: isDrawerOpen || isHovered ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {Bearer ? (
        fullScreen ? (
          <Drawer
            sx={{
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="temporary"
            anchor="left"
            open={isDrawerOpen}
            onClose={() => {
              setIsDrawerOpen(false);
              setIsHovered(false);
              updateDrawerState(false);
            }}
          >
            {DrawerBody}
          </Drawer>
        ) : (
          <CustomDrawer
            variant="permanent"
            open={isDrawerOpen || isHovered}
            onClose={toggleDrawer}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {DrawerBody}
          </CustomDrawer>
        )
      ) : null}
    </Box>
  );
}
