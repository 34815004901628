import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../URL";
import { setupInterceptorsTo } from "../../authentication/Interceptors";
import {
  setDrTeleHealthCredentialsLoadingAction,
  setDrTeleHealthCredentialsSuccessAction,
  setDrTeleHealthCredentialsErrorAction,
  setDrTeleHealthCredentialsAPIMsgAction,
  setDrTeleHealthCredentialsAPIResClearAction,
} from "./Action";
import { CreateMeetingActionTypes } from "./ActionTypes";
import { CreateInstantMeetingValue, CreateInstantMeetingBody } from "./Model";
import { headers } from "components/Utility";

let apiRes = {} as CreateInstantMeetingValue;
export const CreateInstantMeetingApi = (payload: CreateInstantMeetingBody) => {
  return function (dispatch: Dispatch<CreateMeetingActionTypes>) {
    dispatch(setDrTeleHealthCredentialsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL + `/telehealthapp/api/telehealth/createInstantMeeting`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(setDrTeleHealthCredentialsLoadingAction(false));
        dispatch(
          setDrTeleHealthCredentialsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Meeting created successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(setDrTeleHealthCredentialsLoadingAction(false));
        dispatch(
          setDrTeleHealthCredentialsErrorAction(
            {} as CreateInstantMeetingValue,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateCreateMeetingAPIResMsg = () => {
  return function (dispatch: Dispatch<CreateMeetingActionTypes>) {
    dispatch(
      setDrTeleHealthCredentialsAPIMsgAction(
        apiRes as CreateInstantMeetingValue,
        "",
        "",
        0
      )
    );
  };
};

export const clearCreateMeetingAPIRes = () => {
  return function (dispatch: Dispatch<CreateMeetingActionTypes>) {
    dispatch(
      setDrTeleHealthCredentialsAPIResClearAction(
        {} as CreateInstantMeetingValue,
        "",
        "",
        0
      )
    );
  };
};
