import {
  GET_DOCTOR_LOGIN_DETAILS_FAIL,
  GET_DOCTOR_LOGIN_DETAILS_LOADING,
  GET_DOCTOR_LOGIN_DETAILS_SUCCESS,
  GET_DOCTOR_LOGIN_DETAILS_API_MSG,
  GET_DOCTOR_LOGIN_DETAILS_API_RES,
  GetDoctorDetailsActionTypes,
} from "./ActionTypes";
import { GetDoctorDetailsRes } from "./Model";

export const getDoctorDetailsLoadingAction = (
  loading: boolean
): GetDoctorDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LOGIN_DETAILS_LOADING,
    loading: loading,
  };
};

export const getDoctorDetailsSuccessAction = (
  getDoctorLoginDetailsResponse: GetDoctorDetailsRes,
  successMsg: string
): GetDoctorDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LOGIN_DETAILS_SUCCESS,
    payload: getDoctorLoginDetailsResponse,
    successMsg: successMsg,
  };
};

export const getDoctorDetailsErrorAction = (
  getDoctorLoginDetailsResponse: GetDoctorDetailsRes,
  errMsg: string,
  status: number
): GetDoctorDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LOGIN_DETAILS_FAIL,
    payload: getDoctorLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorDetailsAPIMsgAction = (
  getDoctorLoginDetailsResponse: GetDoctorDetailsRes,
  errMsg: string,
  status: number
): GetDoctorDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LOGIN_DETAILS_API_MSG,
    payload: getDoctorLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorDetailsAPIResClearAction = (
  getDoctorLoginDetailsResponse: GetDoctorDetailsRes,
  errMsg: string,
  status: number
): GetDoctorDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LOGIN_DETAILS_API_RES,
    payload: getDoctorLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
