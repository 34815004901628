import {
    PaymentStatusDetails,
    PaymentStatusActionTypes,
    PAYMENT_STATUS_FAIL,
    PAYMENT_STATUS_LOADING,
    PAYMENT_STATUS_SUCCESS,
  } from "./ActionTypes";
  import { PaymentStatusRes } from "./Model";
  
  const initialStateGetPosts: PaymentStatusDetails = {
    loading: false,
    paymentStatusRes: {} as PaymentStatusRes,
    errRes: "",
  };
  export const paymentStatusReducer = (
    state = initialStateGetPosts,
    action: PaymentStatusActionTypes
  ): PaymentStatusDetails => {
    switch (action.type) {
      case PAYMENT_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          paymentStatusRes: action.payload,
          errRes: "",
        };
      case PAYMENT_STATUS_LOADING:
        return {
          ...state,
          loading: true,
          paymentStatusRes: {} as PaymentStatusRes,
        };
      case PAYMENT_STATUS_FAIL:
        return {
          ...state,
          paymentStatusRes: {} as PaymentStatusRes,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  