import {
  SubmitQuestionActionTypes,
    SUBMIT_QUESTION_FAIL,
    SUBMIT_QUESTION_LOADING,
    SUBMIT_QUESTION_SUCCESS,
    SubmitQuestionState,
    SUBMIT_QUESTION_API_MSG,
    SUBMIT_QUESTION_API_RES,
  } from "./ActionTypes";
  import { SubmitQuestionResponse } from "./Model";
  
  const initialStateEditPosts: SubmitQuestionState = {
    loading: false,
    submitQuestionResponse: {} as SubmitQuestionResponse,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const submitQuestionReducer = (
    state = initialStateEditPosts,
    action: SubmitQuestionActionTypes
  ): SubmitQuestionState => {
    switch (action.type) {
      case SUBMIT_QUESTION_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case SUBMIT_QUESTION_SUCCESS:
        return {
          ...state,
          submitQuestionResponse: action.payload,
          successMsg: action.successMsg,
        };
      case SUBMIT_QUESTION_FAIL:
        return {
          ...state,
          submitQuestionResponse: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case SUBMIT_QUESTION_API_MSG:
        return {
          ...state,
          submitQuestionResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case SUBMIT_QUESTION_API_RES:
        return {
          ...state,
          submitQuestionResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };