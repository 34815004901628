import {
    QUESTION_LIST_FAIL,
    QUESTION_LIST_LOADING,
    QUESTION_LIST_SUCCESS,
    QUESTION_LIST_API_MSG,
    QUESTION_LIST_API_RES,
    AddDrLegalDetailsActionTypes,
  } from "./ActionTypes";
  import { QuestionListResponse } from "./Model";
  
  export const questionListLoadingAction = (
    loading: boolean
  ): AddDrLegalDetailsActionTypes => {
    return {
      type: QUESTION_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const questionListSuccessAction = (
    questionListResponse: QuestionListResponse,
    successMsg: string
  ): AddDrLegalDetailsActionTypes => {
    return {
      type: QUESTION_LIST_SUCCESS,
      payload: questionListResponse,
      successMsg: successMsg,
    };
  };
  
  export const questionListErrorAction = (
    questionListResponse: QuestionListResponse,
    errMsg: string,
    status: number
  ): AddDrLegalDetailsActionTypes => {
    return {
      type: QUESTION_LIST_FAIL,
      payload: questionListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const questionListAPIMsgAction = (
    questionListResponse: QuestionListResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): AddDrLegalDetailsActionTypes => {
    return {
      type: QUESTION_LIST_API_MSG,
      payload: questionListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const questionListAPIResClearAction = (
    questionListResponse: QuestionListResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): AddDrLegalDetailsActionTypes => {
    return {
      type: QUESTION_LIST_API_RES,
      payload: questionListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };