import { GetAgreementTypeListRes } from "./Model";
export const GET_DOCTOR_AGREEMENT_TYPE_LIST_LOADING =
  "GET_DOCTOR_AGREEMENT_TYPE_LIST_LOADING";
export const GET_DOCTOR_AGREEMENT_TYPE_LIST_SUCCESS =
  "GET_DOCTOR_AGREEMENT_TYPE_LIST_SUCCESS";
export const GET_DOCTOR_AGREEMENT_TYPE_LIST_FAIL =
  "GET_DOCTOR_AGREEMENT_TYPE_LIST_FAIL";
export const GET_DOCTOR_AGREEMENT_TYPE_LIST_API_MSG =
  "GET_DOCTOR_AGREEMENT_TYPE_LIST_API_MSG";
export const GET_DOCTOR_AGREEMENT_TYPE_LIST_API_RES =
  "GET_DOCTOR_AGREEMENT_TYPE_LIST_API_RES";

export interface GetDoctorAgreementTypeListState {
  getDoctorAgreementTypeListRes: GetAgreementTypeListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAgreementTypeListLoading {
  type: typeof GET_DOCTOR_AGREEMENT_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorAgreementTypeListSuccess {
  type: typeof GET_DOCTOR_AGREEMENT_TYPE_LIST_SUCCESS;
  payload: GetAgreementTypeListRes;
  successMsg: string;
}
export interface GetDoctorAgreementTypeListFail {
  type: typeof GET_DOCTOR_AGREEMENT_TYPE_LIST_FAIL;
  payload: GetAgreementTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAgreementTypeListAPIMsg {
  type: typeof GET_DOCTOR_AGREEMENT_TYPE_LIST_API_MSG;
  payload: GetAgreementTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAgreementTypeListAPIRes {
  type: typeof GET_DOCTOR_AGREEMENT_TYPE_LIST_API_RES;
  payload: GetAgreementTypeListRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorAgreementTypeListActionTypes =
  | GetDoctorAgreementTypeListLoading
  | GetDoctorAgreementTypeListSuccess
  | GetDoctorAgreementTypeListFail
  | GetDoctorAgreementTypeListAPIMsg
  | GetDoctorAgreementTypeListAPIRes;
