import {  AddSummaryListRes } from "./Model";
export const  ADD_SUMMARYLIST_SUBMIT_LIST_LOADING = " ADD_SUMMARYLIST_SUBMIT_LIST_LOADING";
export const  ADD_SUMMARYLIST_SUBMIT_LIST_SUCCESS = " ADD_SUMMARYLIST_SUBMIT_LIST_SUCCESS";
export const  ADD_SUMMARYLIST_SUBMIT_LIST_FAIL = " ADD_SUMMARYLIST_SUBMIT_LIST_FAIL";
export const  ADD_SUMMARYLIST_SUBMIT_LIST_DATA = " ADD_SUMMARYLIST_SUBMIT_LIST_DATA";
export const  ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG =
  " ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG";
export const  ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES =
  " ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES";

export interface  AddSummaryListState {
addSummaryListResponse:  AddSummaryListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  AddSummaryListLoading {
  type: typeof  ADD_SUMMARYLIST_SUBMIT_LIST_LOADING;
  loading: boolean;
}
export interface  AddSummaryListSuccess {
  type: typeof  ADD_SUMMARYLIST_SUBMIT_LIST_SUCCESS;
  payload:  AddSummaryListRes;
  successMsg: string;
}
export interface  AddSummaryListFail {
  type: typeof  ADD_SUMMARYLIST_SUBMIT_LIST_FAIL;
  payload:  AddSummaryListRes;
  errorMsg: string;
  status: number;
}
export interface  AddSummaryListUpdateAPIMsg {
  type: typeof  ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG;
  payload:  AddSummaryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  AddSummaryListUpdateAPIRes {
  type: typeof  ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES;
  payload:  AddSummaryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type  AddSummaryListActionTypes =
  |  AddSummaryListLoading
  |  AddSummaryListSuccess
  |  AddSummaryListFail
  |  AddSummaryListUpdateAPIMsg
  |  AddSummaryListUpdateAPIRes;
