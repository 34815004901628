import { PrescriptionListRes } from "./Model";

export const PRESCRIPTION_LIST_LOADING =
  "PRESCRIPTION_LIST_LOADING";
export const PRESCRIPTION_LIST_SUCCESS =
  "PRESCRIPTION_LIST_SUCCESS";
export const PRESCRIPTION_LIST_FAIL =
  "PRESCRIPTION_LIST_FAIL";
export const PRESCRIPTION_LIST_DATA =
  "PRESCRIPTION_LIST_DATA";

export interface PrescriptionListDetails {
  prescriptionListRes: PrescriptionListRes;
  loading: boolean;
  errRes: string;
}
export interface PrescriptionListDetailsLoading {
  type: typeof PRESCRIPTION_LIST_LOADING;
}
export interface PrescriptionListDetailsSuccess {
  type: typeof PRESCRIPTION_LIST_SUCCESS;
  payload: PrescriptionListRes;
  errRes: string;
}
export interface PrescriptionListDetailsFail {
  type: typeof PRESCRIPTION_LIST_FAIL;
  payload: PrescriptionListRes;
  errRes: string;
}
interface PrescriptionListDetailsAction {
  type: typeof PRESCRIPTION_LIST_DATA;
  payload: PrescriptionListRes;
  errRes: string;
}

export type PrescriptionListActionTypes =
  | PrescriptionListDetailsAction
  | PrescriptionListDetailsLoading
  | PrescriptionListDetailsSuccess
  | PrescriptionListDetailsFail;
