import { DeletePatientDataByIdRes } from "./Model";
export const DELETE_SCHEDULE_LOADING = "DELETE_SCHEDULE_LOADING";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAIL = "DELETE_SCHEDULE_FAIL";
export const DELETE_SCHEDULE_UPDATE_API_MSG = "DELETE_SCHEDULE_UPDATE_API_MSG";
export const DELETE_SCHEDULE_UPDATE_API_RES = "DELETE_SCHEDULE_UPDATE_API_RES";

export interface DeletePatientDataByIdState {
  deletePatientDataByIdRes: DeletePatientDataByIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeletePatientDataByIdLoading {
  type: typeof DELETE_SCHEDULE_LOADING;
  loading: boolean;
}
export interface DeletePatientDataByIdSuccess {
  type: typeof DELETE_SCHEDULE_SUCCESS;
  payload: DeletePatientDataByIdRes;
  successMsg: string;
}
export interface DeletePatientDataByIdFail {
  type: typeof DELETE_SCHEDULE_FAIL;
  payload: DeletePatientDataByIdRes;
  errorMsg: string;
  status: number;
}
export interface DeletePatientDataByIdUpdateAPIMsg {
  type: typeof DELETE_SCHEDULE_UPDATE_API_MSG;
  payload: DeletePatientDataByIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DeletePatientDataByIdUpdateAPIRes {
  type: typeof DELETE_SCHEDULE_UPDATE_API_RES;
  payload: DeletePatientDataByIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DeletePatientDataByIdActionTypes =
  | DeletePatientDataByIdLoading
  | DeletePatientDataByIdSuccess
  | DeletePatientDataByIdFail
  | DeletePatientDataByIdUpdateAPIMsg
  | DeletePatientDataByIdUpdateAPIRes;
