import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardHeader,
  Grid,
  CardContent,
} from "@mui/material";
import { getLicenseList } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/GetLicenseList/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { getLicenseTypeList } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/DoctorLicenseTypeDropdown/API";
import { t } from "i18next";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";
import { LicenseList } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/GetLicenseList/Model";
import { ModifiedLicenseList } from "./Model";
import UpdateLicense from "./UpdateLicense";
import DocumentViewer from "components/DocumentViewer";
import MWExceptionList from "components/MWExceptionList";
export interface Props {
  professionalLicenseLoading: boolean | any;
}
export default function ProfessionalLicenseList({
  professionalLicenseLoading,
}: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLicenseList());
    dispatch(getLicenseTypeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [licenseList, setLicenseList] = useState([] as ModifiedLicenseList[]);

  const licenseTableColumns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })!}`,
      minWidth: 110,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === null ? true : false}
          onClick={() => {
            setViewLicenseDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "idType",
      headerName: `${t("userprofilepage.medlicensename", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    { field: "idNumber", headerName: "License Number", width: 130 },
    {
      field: "startDate",
      headerName: `${t("userprofilepage.startdate", { ns: ["home"] })!}`,
      width: 100,
    },
    {
      field: "endDate",
      headerName: `${t("appointmentpage.enddate", { ns: ["home"] })!}`,
      width: 100,
    },
    {
      field: "description",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })!}`,
      width: 200,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })!}`,
      minWidth: 130,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setAddLicenseDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("userprofilepage.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  // call license list store
  const getLicenseListRes = useSelector(
    (state: AppState) => state.getLicenseListRes
  );

  useEffect(() => {
    professionalLicenseLoading(getLicenseListRes?.loading);
    if (getLicenseListRes?.getDoctorLicenseListRes?.licenseList !== undefined) {
      let licenseListItem =
        getLicenseListRes?.getDoctorLicenseListRes?.licenseList.map(
          (item: LicenseList) =>
            ({
              id: item?.id,
              supportingDocument:
                item?.supportingDocuments[0]?.userFile?.document !== undefined
                  ? item?.supportingDocuments[0]?.userFile?.document
                  : null,
              idType:
                item?.idType?.label !== undefined ? item?.idType?.label : "",
              idValue:
                item?.idType?.value !== undefined ? item?.idType?.value : 0,
              idNumber: item?.idNumber,
              startDate:
                item?.fromDate !== null
                  ? dayjs(item?.fromDate).format("DD-MM-YYYY")
                  : "Not Set",
              endDate:
                item?.toDate !== null
                  ? dayjs(item?.toDate).format("DD-MM-YYYY")
                  : "Not Set",
              description: item?.description,
            } as ModifiedLicenseList)
        );
      setLicenseList(licenseListItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLicenseListRes]);

  const [editItem, setEditItem] = useState({} as ModifiedLicenseList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const [addLicenseDialog, setAddLicenseDialog] = useState(false);
  const updateAddLicenseDialogValFromChild = (passedVal: boolean) => {
    setAddLicenseDialog(passedVal);
  };
  const [viewLicenseDocumentDialog, setViewLicenseDocumentDialog] =
    useState(false);
  const updateViewLicenseDocumentDialogDialogValFromChild = (
    passedVal: boolean
  ) => {
    setViewLicenseDocumentDialog(passedVal);
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.proflicense", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setAddLicenseDialog(true)}
                  variant="outlined"
                  sx={{ textTransform: "none", color: "#000" }}
                >
                  {t("userprofilepage.addproflicense", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {licenseList.length > 0 ? (
            <DataGrid
              rows={licenseList}
              columns={licenseTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {addLicenseDialog ? (
        <UpdateLicense
          dialogEnable={addLicenseDialog}
          dialogUpdateState={updateAddLicenseDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewLicenseDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewLicenseDocumentDialog}
          dialogUpdateState={updateViewLicenseDocumentDialogDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
