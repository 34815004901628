import React, { useState } from "react";
import Telehealthcredentials from "./Telehealthcredentials";
import TelehealthMeeting from "./TelehealthMeeting";
import { t } from "i18next";
import { Box, Container, Tab, Tabs } from "@mui/material";
import PageLayout from "components/PageLayout";
import { useDispatch } from "react-redux";
import { clearDrAppointmentDecisionAPIRes } from "redux/TelehealthCredentialSet/API";
import MWPageTitle from "components/MWPageTitle";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 1 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DoctorTeleHealth() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    dispatch(clearDrAppointmentDecisionAPIRes());
  };
  const actualPageMarkup = (
    <Box>
      <Tabs value={selected} onChange={handleTabChange}>
        <Tab
          label={t("telehealthpage.telemeet", { ns: ["home"] })}
          {...a11yProps(0)}
        />
        <Tab
          label={t("telehealthpage.telecred", { ns: ["home"] })}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={selected} index={0}>
        {<TelehealthMeeting />}
      </TabPanel>
      <TabPanel value={selected} index={1}>
        {<Telehealthcredentials />}
      </TabPanel>
    </Box>
  );

  return (
    <Container maxWidth="xl">
      <PageLayout>
        <MWPageTitle title={t("common.telehealth", { ns: ["home"] })} />
        {actualPageMarkup}
      </PageLayout>
    </Container>
  );
}
