import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { GetDoctorDegreeListActionTypes } from "./ActionTypes";
import { GetDegreeListRes } from "./Model";
import {
  getDoctorDegreeListAPIResClearAction,
  getDoctorDegreeListErrorAction,
  getDoctorDegreeListLoadingAction,
  getDoctorDegreeListSuccessAction,
  getDoctorDegreeListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetDegreeListRes;
export const getDoctorDegreeList = () => {
  return function (dispatch: Dispatch<GetDoctorDegreeListActionTypes>) {
    dispatch(getDoctorDegreeListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/degree_list_dropdown`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorDegreeListLoadingAction(false));
        dispatch(
          getDoctorDegreeListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Degree list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorDegreeListLoadingAction(false));
        dispatch(
          getDoctorDegreeListErrorAction(
            {} as GetDegreeListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorDegreeListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorDegreeListActionTypes>) {
    dispatch(
      getDoctorDegreeListAPIMsgAction(apiRes as GetDegreeListRes, "", 0)
    );
  };
};

export const clearGetDoctorDegreeListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorDegreeListActionTypes>) {
    dispatch(
      getDoctorDegreeListAPIResClearAction({} as GetDegreeListRes, "", 0)
    );
  };
};
