import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { EditDrInsuranceActionTypes } from "./ActionTypes";
import { EditDrInsuranceBody, EditDrInsuranceRes } from "./Model";
import {
  editDrInsuranceAPIResClearAction,
  editDrInsuranceErrorAction,
  editDrInsuranceLoadingAction,
  editDrInsuranceSuccessAction,
  editDrInsuranceAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EditDrInsuranceRes;
export const editDrInsurance = (payload: EditDrInsuranceBody) => {
  return function (dispatch: Dispatch<EditDrInsuranceActionTypes>) {
    dispatch(editDrInsuranceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/doctorinsurance/edit_doctor_insurance`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editDrInsuranceLoadingAction(false));
        dispatch(
          editDrInsuranceSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal details has been edited successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editDrInsuranceLoadingAction(false));
        dispatch(
          editDrInsuranceErrorAction(
            {} as EditDrInsuranceRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDrInsuranceAPIResMsg = () => {
  return function (dispatch: Dispatch<EditDrInsuranceActionTypes>) {
    dispatch(
      editDrInsuranceAPIMsgAction(apiRes as EditDrInsuranceRes, "", "", 0)
    );
  };
};

export const clearEditDrInsuranceAPIRes = () => {
  return function (dispatch: Dispatch<EditDrInsuranceActionTypes>) {
    dispatch(
      editDrInsuranceAPIResClearAction({} as EditDrInsuranceRes, "", "", 0)
    );
  };
};
