import {
  AddPatientDataActionTypes,
  ADD_PATIENT_DATA_FAIL,
  ADD_PATIENT_DATA_LOADING,
  ADD_PATIENT_DATA_SUCCESS,
  AddPatientDataState,
  ADD_PATIENT_DATA_API_MSG,
  ADD_PATIENT_DATA_API_RES,
} from "./ActionTypes";
import { AddPatientDataRes } from "./Model";

const initialStateGetPosts: AddPatientDataState = {
  loading: false,
  addPatientDataRes: {} as AddPatientDataRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addPatientDataReducer = (
  state = initialStateGetPosts,
  action: AddPatientDataActionTypes
): AddPatientDataState => {
  switch (action.type) {
    case ADD_PATIENT_DATA_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PATIENT_DATA_SUCCESS:
      return {
        ...state,
        addPatientDataRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_DATA_FAIL:
      return {
        ...state,
        addPatientDataRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DATA_API_MSG:
      return {
        ...state,
        addPatientDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_DATA_API_RES:
      return {
        ...state,
        addPatientDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
