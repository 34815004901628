import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorLicenseTypeListActionTypes } from "./ActionTypes";
import { GetDoctorLicenseTypeListRes } from "./Model";
import {
  getDoctorLicenseTypeListAPIResClearAction,
  getDoctorLicenseTypeListErrorAction,
  getDoctorLicenseTypeListLoadingAction,
  getDoctorLicenseTypeListSuccessAction,
  getDoctorLicenseTypeListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetDoctorLicenseTypeListRes;
export const getLicenseTypeList = () => {
  return function (dispatch: Dispatch<GetDoctorLicenseTypeListActionTypes>) {
    dispatch(getDoctorLicenseTypeListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL +
          `/doctorapp/api/doctorlicense/doctor_licensetype_dropdown`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorLicenseTypeListLoadingAction(false));
        dispatch(
          getDoctorLicenseTypeListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. license type list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorLicenseTypeListLoadingAction(false));
        dispatch(
          getDoctorLicenseTypeListErrorAction(
            {} as GetDoctorLicenseTypeListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetDoctorLicenseTypeListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorLicenseTypeListActionTypes>) {
    dispatch(
      getDoctorLicenseTypeListAPIMsgAction(
        apiRes as GetDoctorLicenseTypeListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDoctorProfessionalListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorLicenseTypeListActionTypes>) {
    dispatch(
      getDoctorLicenseTypeListAPIResClearAction(
        {} as GetDoctorLicenseTypeListRes,
        "",
        "",
        0
      )
    );
  };
};
