import {
  ImageUploadDetails,
  ImageUploadActionTypes,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPLOAD_LOADING,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_PROGRESS,
  IMAGE_UPLOAD_API_MSG,
  IMAGE_UPLOAD_API_RES,
} from "./ActionTypes";
import { ImageUploadResponse } from "./Model";

const initialStateGetPosts: ImageUploadDetails = {
  loading: false,
  imageUploadResponse: {} as ImageUploadResponse,
  progress: 0,
  successMsg: "",
  errMsg: "",
  status: "",
};
export const imageUploadReducer = (
  state = initialStateGetPosts,
  action: ImageUploadActionTypes
): ImageUploadDetails => {
  switch (action.type) {
    case IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        imageUploadResponse: action.payload,
        successMsg: action.successMsg,
        status: action.status,
      };
    case IMAGE_UPLOAD_LOADING:
      return {
        ...state,
        loading: true,
        imageUploadResponse: {} as ImageUploadResponse,
      };
    case IMAGE_UPLOAD_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    case IMAGE_UPLOAD_FAIL:
      return {
        ...state,
        imageUploadResponse: {} as ImageUploadResponse,
        loading: false,
        errMsg: action.errMsg,
        status: action.status,
      };
    case IMAGE_UPLOAD_API_MSG:
      return {
        ...state,
        loading: false,
        imageUploadResponse: action.payload,
        successMsg: action.successMsg,
        status: action.status,
      };
    case IMAGE_UPLOAD_API_RES:
      return {
        ...state,
        loading: false,
        imageUploadResponse: {} as ImageUploadResponse,
        successMsg: action.successMsg,
        errMsg: action.errMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
