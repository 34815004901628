import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { doctorAccountDetailsAPI } from "redux/AccountDetails/DoctorAccDetails/ApiCall";
import PageLayout from "components/MWPageLayout";
import MWPageTitle from "components/MWPageTitle";
import { t } from "i18next";
import AccountDetailsLayout from "./AccountDetailsLayout";
import Signature from "./AccountDetailsLayout/Signature";

export default function AccountDetails() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(doctorAccountDetailsAPI());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageLayout>
      <MWPageTitle
        title={t("common.accdetails", { ns: ["home"] })!}
        enableCount={false}
        reload={false}
      />
      <AccountDetailsLayout />
      <Signature />
    </PageLayout>
  );
}
