import { GetBloodGroupListRes } from "./Model";

export const GET_BLOOD_GROUP_LIST_LOADING =
  "GET_BLOOD_GROUP_LIST_LOADING";
export const GET_BLOOD_GROUP_LIST_SUCCESS =
  "GET_BLOOD_GROUP_LIST_SUCCESS";
export const GET_BLOOD_GROUP_LIST_FAIL =
  "GET_BLOOD_GROUP_LIST_FAIL";
export const GET_BLOOD_GROUP_LIST_DATA =
  "GET_BLOOD_GROUP_LIST_DATA";

export interface BloodGroupListDetails {
  getBloodGroupListRes: GetBloodGroupListRes;
  loading: boolean;
  errRes: string;
}
export interface GetBloodGroupListActionLoading {
  type: typeof GET_BLOOD_GROUP_LIST_LOADING;
}
export interface GetBloodGroupListActionSuccess {
  type: typeof GET_BLOOD_GROUP_LIST_SUCCESS;
  payload: GetBloodGroupListRes;
  errRes: string;
}
export interface GetBloodGroupListActionFail {
  type: typeof GET_BLOOD_GROUP_LIST_FAIL;
  payload: GetBloodGroupListRes;
  errRes: string;
}
interface GetBloodGroupListActionAction {
  type: typeof GET_BLOOD_GROUP_LIST_DATA;
  payload: GetBloodGroupListRes;
  errRes: string;
}

export type GetBloodGroupListActionTypes =
  | GetBloodGroupListActionAction
  | GetBloodGroupListActionLoading
  | GetBloodGroupListActionSuccess
  | GetBloodGroupListActionFail;
