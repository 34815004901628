import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import {
  setDrTeleHealthCredentialsLoadingAction,
  setDrTeleHealthCredentialsSuccessAction,
  setDrTeleHealthCredentialsErrorAction,
  setDrTeleHealthCredentialsAPIMsgAction,
  setDrTeleHealthCredentialsAPIResClearAction,
} from "./Action";
import { DrAppointmentDecisionActionTypes } from "./ActionTypes";
import {
  SetTeleHealthCredentialRes,
  SetTeleHealthCredentialsBody,
} from "./Model";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as SetTeleHealthCredentialRes;
export const setDrTeleHealthCredentials = (
  payload: SetTeleHealthCredentialsBody
) => {
  return function (dispatch: Dispatch<DrAppointmentDecisionActionTypes>) {
    dispatch(setDrTeleHealthCredentialsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          `/telehealthapp/api/telehealth/setTelehealth_credential`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(setDrTeleHealthCredentialsLoadingAction(false));
        dispatch(
          setDrTeleHealthCredentialsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. Telehealth credentials has been saved successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(setDrTeleHealthCredentialsLoadingAction(false));
        dispatch(
          setDrTeleHealthCredentialsErrorAction(
            {} as SetTeleHealthCredentialRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDrAppointmentDecisionAPIResMsg = () => {
  return function (dispatch: Dispatch<DrAppointmentDecisionActionTypes>) {
    dispatch(
      setDrTeleHealthCredentialsAPIMsgAction(
        apiRes as SetTeleHealthCredentialRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDrAppointmentDecisionAPIRes = () => {
  return function (dispatch: Dispatch<DrAppointmentDecisionActionTypes>) {
    dispatch(
      setDrTeleHealthCredentialsAPIResClearAction(
        {} as SetTeleHealthCredentialRes,
        "",
        "",
        0
      )
    );
  };
};
