import { GeneratePrescriptionRes } from "./Model";
export const GENERATE_PRESCRIPTION_LOADING = "GENERATE_PRESCRIPTION_LOADING";
export const GENERATE_PRESCRIPTION_SUCCESS = "GENERATE_PRESCRIPTION_SUCCESS";
export const GENERATE_PRESCRIPTION_FAIL = "GENERATE_PRESCRIPTION_FAIL";
export const GENERATE_PRESCRIPTION_API_MSG = "GENERATE_PRESCRIPTION_API_MSG";
export const GENERATE_PRESCRIPTION_API_RES = "GENERATE_PRESCRIPTION_API_RES";

export interface GeneratePrescriptionState {
  generatePrescriptionRes: GeneratePrescriptionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GeneratePrescriptionLoading {
  type: typeof GENERATE_PRESCRIPTION_LOADING;
  loading: boolean;
}
export interface GeneratePrescriptionSuccess {
  type: typeof GENERATE_PRESCRIPTION_SUCCESS;
  payload: GeneratePrescriptionRes;
  successMsg: string;
}
export interface GeneratePrescriptionFail {
  type: typeof GENERATE_PRESCRIPTION_FAIL;
  payload: GeneratePrescriptionRes;
  errorMsg: string;
  status: number;
}
export interface GeneratePrescriptionAPIMsg {
  type: typeof GENERATE_PRESCRIPTION_API_MSG;
  payload: GeneratePrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GeneratePrescriptionAPIRes {
  type: typeof GENERATE_PRESCRIPTION_API_RES;
  payload: GeneratePrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GeneratePrescriptionActionTypes =
  | GeneratePrescriptionLoading
  | GeneratePrescriptionSuccess
  | GeneratePrescriptionFail
  | GeneratePrescriptionAPIMsg
  | GeneratePrescriptionAPIRes;
