import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorEducationSummaryActionTypes } from "./ActionTypes";
import { GetEducationSummaryRes } from "./Model";
import {
  getDoctorEducationSummaryAPIResClearAction,
  getDoctorEducationSummaryErrorAction,
  getDoctorEducationSummaryLoadingAction,
  getDoctorEducationSummarySuccessAction,
  getDoctorEducationSummaryAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetEducationSummaryRes;
export const getEducationSummary = () => {
  return function (dispatch: Dispatch<GetDoctorEducationSummaryActionTypes>) {
    dispatch(getDoctorEducationSummaryLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/get_education_summary`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorEducationSummaryLoadingAction(false));
        dispatch(
          getDoctorEducationSummarySuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Education list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorEducationSummaryLoadingAction(false));
        dispatch(
          getDoctorEducationSummaryErrorAction(
            {} as GetEducationSummaryRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorEducationSummaryAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorEducationSummaryActionTypes>) {
    dispatch(
      getDoctorEducationSummaryAPIMsgAction(
        apiRes as GetEducationSummaryRes,
        "",
        0
      )
    );
  };
};

export const clearGetDoctorEducationListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorEducationSummaryActionTypes>) {
    dispatch(
      getDoctorEducationSummaryAPIResClearAction(
        {} as GetEducationSummaryRes,
        "",
        0
      )
    );
  };
};
