import {
  AddDrLegalDetailsActionTypes,
  ADD_DR_LEGAL_DETAILS_FAIL,
  ADD_DR_LEGAL_DETAILS_LOADING,
  ADD_DR_LEGAL_DETAILS_SUCCESS,
  AddDrLegalDetailsState,
  ADD_DR_LEGAL_DETAILS_API_MSG,
  ADD_DR_LEGAL_DETAILS_API_RES,
} from "./ActionTypes";
import { AddLegalDetailsRes } from "./Model";

const initialStateEditPosts: AddDrLegalDetailsState = {
  loading: false,
  addDrLegalDetailsRes: {} as AddLegalDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDrLegalDetailsReducer = (
  state = initialStateEditPosts,
  action: AddDrLegalDetailsActionTypes
): AddDrLegalDetailsState => {
  switch (action.type) {
    case ADD_DR_LEGAL_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DR_LEGAL_DETAILS_SUCCESS:
      return {
        ...state,
        addDrLegalDetailsRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DR_LEGAL_DETAILS_FAIL:
      return {
        ...state,
        addDrLegalDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_LEGAL_DETAILS_API_MSG:
      return {
        ...state,
        addDrLegalDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_LEGAL_DETAILS_API_RES:
      return {
        ...state,
        addDrLegalDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
