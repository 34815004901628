import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorProfessionalSummaryActionTypes } from "./ActionTypes";
import { GetProfessionalSummaryRes } from "./Model";
import {
  getDoctorProfessionalSummaryAPIResClearAction,
  getDoctorProfessionalSummaryErrorAction,
  getDoctorProfessionalSummaryLoadingAction,
  getDoctorProfessionalSummarySuccessAction,
  getDoctorProfessionalSummaryAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetProfessionalSummaryRes;
export const getProfessionalSummary = () => {
  return function (
    dispatch: Dispatch<GetDoctorProfessionalSummaryActionTypes>
  ) {
    dispatch(getDoctorProfessionalSummaryLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/get_work_summary`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorProfessionalSummaryLoadingAction(false));
        dispatch(
          getDoctorProfessionalSummarySuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Professional summary has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorProfessionalSummaryLoadingAction(false));
        dispatch(
          getDoctorProfessionalSummaryErrorAction(
            {} as GetProfessionalSummaryRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorProfessionalSummaryAPIResMsg = () => {
  return function (
    dispatch: Dispatch<GetDoctorProfessionalSummaryActionTypes>
  ) {
    dispatch(
      getDoctorProfessionalSummaryAPIMsgAction(
        apiRes as GetProfessionalSummaryRes,
        "",
        0
      )
    );
  };
};

export const clearGetDoctorProfessionalListAPIRes = () => {
  return function (
    dispatch: Dispatch<GetDoctorProfessionalSummaryActionTypes>
  ) {
    dispatch(
      getDoctorProfessionalSummaryAPIResClearAction(
        {} as GetProfessionalSummaryRes,
        "",
        0
      )
    );
  };
};
