import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import MWLoader from "components/MWLoader";
import OnboardingLayout from "./OnboardingLayout";
export default function Experience() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  const closeerror = () => {
    setError("");
  };

  const [error, setError] = useState("");
  const errorDiv = error ? (
    <Alert onClose={closeerror} severity="error">
      <Typography>{error}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [selected, setSelected] = useState([]);

  const handleChange = useCallback((value:any) => setSelected(value), []);

  const handleproceed = () => {
    setIsLoading(true);
    if (selected.length === 0) {
      setIsLoading(false);
      setError(`${t("experiencePage.satcondition", { ns: ["home"] })}`);
    } else {
      setIsLoading(true);
      history.push("/signup");
      setIsLoading(false);
    }
  };

  const handleSigninProcess = () => {
    history.push("/signin");
  };
  const AvailableExp = [
    `${t("experiencePage.label1", {
      ns: ["home"],
    })}`,
    `${t("experiencePage.label2", {
      ns: ["home"],
    })}`,
    `${t("experiencePage.label3", {
      ns: ["home"],
    })}`,
  ];
  const actualPageMarkup = (
    <OnboardingLayout>
      <Grid
        container
        spacing={1}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Box>
          <Box my={1}>
            <Typography variant="h4" gutterBottom>
              {t("experiencePage.selExp", { ns: ["home"] })}
            </Typography>
          </Box>
          <br />
          <Box>
            <form onSubmit={handleproceed}>
              <FormControl fullWidth component="fieldset" margin="normal">
                <FormGroup aria-label="Temas" row={true}>
                  {AvailableExp.map((theme, i) => (
                    <FormControlLabel
                      key={theme}
                      value={theme}
                      control={
                        <Checkbox
                          name={`muiThemes[${i}]`}
                          value={theme}
                          onChange={handleChange}
                        />
                      }
                      label={theme}
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <Button fullWidth variant="contained" onClick={handleproceed}>
                {t("experiencePage.proceed", { ns: ["home"] })!}
              </Button>
              <Box>{errorDiv}</Box>

              <Box>
                <Typography fontWeight="bold">
                  {t("experiencePage.haveAcc", { ns: ["home"] })!}{" "}
                </Typography>
                <Button variant="contained" onClick={handleSigninProcess}>
                  {t("experiencePage.signin", { ns: ["home"] })!}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
    </OnboardingLayout>
  );

  return (
    <Box>
      <Container maxWidth="xl">
        {loadingMarkup}
        {actualPageMarkup}
      </Container>
    </Box>
  );
}
