import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Toolbar,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NavigationList } from "../DrawerList";
import medicalWISDOM from "../../components/image/medicalWisdom-with-caption.png";
import MWLoader from "../MWLoader";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import { styled, useTheme } from "@mui/material/styles";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
import { useTranslation } from "react-i18next";
import MWSelectLang from "components/MWSelectLang";
import { MobileSelectLang } from "components/MobileSelectLang";

type Props = {
  drawerState: boolean;
  updateDrawerStateFromAppBar: (value: boolean) => void;
  updateHoverState: (value: boolean) => void;
};

const drawerWidth = 200;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBars({
  drawerState,
  updateDrawerStateFromAppBar,
  updateHoverState,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Bearer = localStorage.getItem("access");
  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetDoctorDetailsRes;

  const logOutRes = useSelector((state: AppState) => state.logOutRes);
  useEffect(() => {
    dispatch(toggleAppBarLoading(logOutRes?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOutRes]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const appLoaderRes = useSelector((state: AppState) => state.appLoaderRes);
  useEffect(() => {
    setIsLoading(appLoaderRes?.loading);
  }, [appLoaderRes]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  useEffect(() => {
    drawerState !== isDrawerOpen
      ? setIsDrawerOpen(drawerState)
      : setIsDrawerOpen(isDrawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerState]);

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
    updateHoverState(false);
  };

  useEffect(() => {
    updateDrawerStateFromAppBar(isDrawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {Bearer ? (
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme: any) => theme.zIndex.drawer + 1,
            backgroundColor: "#ffffff",
          }}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              px={1}
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <IconButton
                      aria-label="open drawer"
                      onClick={toggleDrawer}
                      edge="start"
                      sx={{ color: "#757575" }}
                    >
                      {drawerState ? <MenuOpenIcon /> : <MenuIcon />}
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Link
                      to={`/`}
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        alt=""
                        src={medicalWISDOM}
                        height="50px"
                        onClick={() => {
                          fullScreen && setIsDrawerOpen(false);
                          updateHoverState(false);
                        }}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "flex",
                          md: "flex",
                          lg: "flex",
                          xl: "flex",
                        },
                      }}
                    >
                      <MWSelectLang isMenu />
                    </Box>
                    <Box
                      sx={{
                        display: {
                          xs: "flex",
                          sm: "flex",
                          md: "flex",
                          lg: "none",
                          xl: "none",
                        },
                      }}
                    >
                      <MobileSelectLang />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                      <Button
                        sx={{ marginTop: 0.5 }}
                        size="small"
                        aria-describedby={id}
                        onClick={handleClick}
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={0.5}
                        >
                          <Grid item>
                            <Avatar />
                          </Grid>
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              <Grid item>
                                <Typography
                                  sx={{ textTransform: "capitalize" }}
                                  variant="subtitle2"
                                  noWrap
                                >
                                  {userDetails?.user?.first_name !==
                                    undefined &&
                                  userDetails?.user?.last_name !== undefined
                                    ? userDetails?.user?.first_name +
                                      " " +
                                      userDetails?.user?.last_name
                                    : null}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="caption"
                                  noWrap
                                  style={{
                                    textTransform: "lowercase",
                                  }}
                                >
                                  {userDetails?.user?.email !== undefined
                                    ? userDetails?.user?.email
                                    : null}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                      <IconButton
                        size="large"
                        aria-label="show more"
                        aria-haspopup="true"
                        onClick={handleClick}
                        sx={{ color: "#757575" }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <List dense>
                    {NavigationList.map((item: any, index: number) => {
                      return (
                        <ListItem disablePadding>
                          <ListItemButton
                            component={Link}
                            to={item.path}
                            key={item.name}
                            style={{
                              textDecoration: "none",
                            }}
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            <ListItemText>
                              {t(`common.${item.name}`, { ns: ["home"] })}
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </Popover>
              </Grid>
            </Grid>
          </Toolbar>
          {loadingMarkup}
        </AppBar>
      ) : null}
    </Box>
  );
}
