import { GetAwardTypeListRes } from "./Model";
export const GET_DOCTOR_AWARD_TYPE_LIST_LOADING =
  "GET_DOCTOR_AWARD_TYPE_LIST_LOADING";
export const GET_DOCTOR_AWARD_TYPE_LIST_SUCCESS =
  "GET_DOCTOR_AWARD_TYPE_LIST_SUCCESS";
export const GET_DOCTOR_AWARD_TYPE_LIST_FAIL =
  "GET_DOCTOR_AWARD_TYPE_LIST_FAIL";
export const GET_DOCTOR_AWARD_TYPE_LIST_API_MSG =
  "GET_DOCTOR_AWARD_TYPE_LIST_API_MSG";
export const GET_DOCTOR_AWARD_TYPE_LIST_API_RES =
  "GET_DOCTOR_AWARD_TYPE_LIST_API_RES";

export interface GetDoctorAwardTypeListState {
  getDoctorAwardTypeListRes: GetAwardTypeListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAwardTypeListLoading {
  type: typeof GET_DOCTOR_AWARD_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorAwardTypeListSuccess {
  type: typeof GET_DOCTOR_AWARD_TYPE_LIST_SUCCESS;
  payload: GetAwardTypeListRes;
  successMsg: string;
}
export interface GetDoctorAwardTypeListFail {
  type: typeof GET_DOCTOR_AWARD_TYPE_LIST_FAIL;
  payload: GetAwardTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAwardTypeListAPIMsg {
  type: typeof GET_DOCTOR_AWARD_TYPE_LIST_API_MSG;
  payload: GetAwardTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAwardTypeListAPIRes {
  type: typeof GET_DOCTOR_AWARD_TYPE_LIST_API_RES;
  payload: GetAwardTypeListRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorAwardTypeListActionTypes =
  | GetDoctorAwardTypeListLoading
  | GetDoctorAwardTypeListSuccess
  | GetDoctorAwardTypeListFail
  | GetDoctorAwardTypeListAPIMsg
  | GetDoctorAwardTypeListAPIRes;
