import {
  ADD_DOCTOR_SIGNATURE_DETAILS_FAIL,
  ADD_DOCTOR_SIGNATURE_DETAILS_LOADING,
  ADD_DOCTOR_SIGNATURE_DETAILS_SUCCESS,
  ADD_DOCTOR_SIGNATURE_DETAILS_API_MSG,
  ADD_DOCTOR_SIGNATURE_DETAILS_API_RES,
  AddDoctorSignatureDetailsActionTypes,
} from "./ActionTypes";
import { AddSignatureRes } from "./Model";

export const addDoctorSignatureDetailsLoadingAction = (
  loading: boolean
): AddDoctorSignatureDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_SIGNATURE_DETAILS_LOADING,
    loading: loading,
  };
};

export const addDoctorSignatureDetailsSuccessAction = (
  addDoctorSignatureDetailsResponse: AddSignatureRes,
  successMsg: string
): AddDoctorSignatureDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_SIGNATURE_DETAILS_SUCCESS,
    payload: addDoctorSignatureDetailsResponse,
    successMsg: successMsg,
  };
};

export const addDoctorSignatureDetailsErrorAction = (
  addDoctorSignatureDetailsResponse: AddSignatureRes,
  errMsg: string,
  status: number
): AddDoctorSignatureDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_SIGNATURE_DETAILS_FAIL,
    payload: addDoctorSignatureDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDoctorSignatureDetailsAPIMsgAction = (
  addDoctorSignatureDetailsResponse: AddSignatureRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDoctorSignatureDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_SIGNATURE_DETAILS_API_MSG,
    payload: addDoctorSignatureDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDoctorSignatureDetailsAPIResClearAction = (
  addDoctorSignatureDetailsResponse: AddSignatureRes,
  errMsg: string,
  successMsg: string,
  status: number
): AddDoctorSignatureDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_SIGNATURE_DETAILS_API_RES,
    payload: addDoctorSignatureDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
