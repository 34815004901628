import { EditPersonalDetailsRes } from "./Model";
export const EDIT_PATIENT_LOADING = "EDIT_PATIENT_LOADING";
export const EDIT_PATIENT_SUCCESS = "EDIT_PATIENT_SUCCESS";
export const EDIT_PATIENT_FAIL = "EDIT_PATIENT_FAIL";
export const EDIT_PATIENT_DATA = "EDIT_PATIENT_DATA";
export const EDIT_PATIENT_UPDATE_API_MSG =
  "EDIT_PATIENT_UPDATE_API_MSG";
export const EDIT_PATIENT_UPDATE_API_RES =
  "EDIT_PATIENT_UPDATE_API_RES";

export interface EditPatientState {
  editPersonalDetailsRes: EditPersonalDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientLoading {
  type: typeof EDIT_PATIENT_LOADING;
  loading: boolean;
}
export interface EditPatientSuccess {
  type: typeof EDIT_PATIENT_SUCCESS;
  payload: EditPersonalDetailsRes;
  successMsg: string;
}
export interface EditPatientFail {
  type: typeof EDIT_PATIENT_FAIL;
  payload: EditPersonalDetailsRes;
  errorMsg: string;
  status: number;
}
export interface EditPatientUpdateAPIMsg {
  type: typeof EDIT_PATIENT_UPDATE_API_MSG;
  payload: EditPersonalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientUpdateAPIRes {
  type: typeof EDIT_PATIENT_UPDATE_API_RES;
  payload: EditPersonalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditPatientActionTypes =
  | EditPatientLoading
  | EditPatientSuccess
  | EditPatientFail
  | EditPatientUpdateAPIMsg
  | EditPatientUpdateAPIRes;

