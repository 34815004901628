import {
    StepPreviewListActionTypes,
    STEP_PREVIEW_LIST_FAIL,
    STEP_PREVIEW_LIST_LOADING,
    STEP_PREVIEW_LIST_SUCCESS,
    StepPreviewListState,
    STEP_PREVIEW_LIST_API_MSG,
    STEP_PREVIEW_LIST_API_RES,
  } from "./ActionTypes";
  import { StepPreviewResponse } from "./Model";
  
  const initialStateEditPosts: StepPreviewListState = {
    loading: false,
    stepPreviewListResponse: {} as StepPreviewResponse,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const stepPreviewListReducer = (
    state = initialStateEditPosts,
    action: StepPreviewListActionTypes
  ): StepPreviewListState => {
    switch (action.type) {
      case STEP_PREVIEW_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case STEP_PREVIEW_LIST_SUCCESS:
        return {
          ...state,
          stepPreviewListResponse: action.payload,
          successMsg: action.successMsg,
        };
      case STEP_PREVIEW_LIST_FAIL:
        return {
          ...state,
          stepPreviewListResponse: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case STEP_PREVIEW_LIST_API_MSG:
        return {
          ...state,
          stepPreviewListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case STEP_PREVIEW_LIST_API_RES:
        return {
          ...state,
          stepPreviewListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };