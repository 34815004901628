import {
  EDIT_DR_WORK_EXPERIENCE_FAIL,
  EDIT_DR_WORK_EXPERIENCE_LOADING,
  EDIT_DR_WORK_EXPERIENCE_SUCCESS,
  EDIT_DR_WORK_EXPERIENCE_API_MSG,
  EDIT_DR_WORK_EXPERIENCE_API_RES,
  EditDrWorkExperienceActionTypes,
} from "./ActionTypes";
import { EditDrWorkExperienceRes } from "./Model";

export const editDrWorkExperienceLoadingAction = (
  loading: boolean
): EditDrWorkExperienceActionTypes => {
  return {
    type: EDIT_DR_WORK_EXPERIENCE_LOADING,
    loading: loading,
  };
};

export const editDrWorkExperienceSuccessAction = (
  editDrWorkExperienceResponse: EditDrWorkExperienceRes,
  successMsg: string
): EditDrWorkExperienceActionTypes => {
  return {
    type: EDIT_DR_WORK_EXPERIENCE_SUCCESS,
    payload: editDrWorkExperienceResponse,
    successMsg: successMsg,
  };
};

export const editDrWorkExperienceErrorAction = (
  editDrWorkExperienceResponse: EditDrWorkExperienceRes,
  errMsg: string,
  status: number
): EditDrWorkExperienceActionTypes => {
  return {
    type: EDIT_DR_WORK_EXPERIENCE_FAIL,
    payload: editDrWorkExperienceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDrWorkExperienceAPIMsgAction = (
  editDrWorkExperienceResponse: EditDrWorkExperienceRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrWorkExperienceActionTypes => {
  return {
    type: EDIT_DR_WORK_EXPERIENCE_API_MSG,
    payload: editDrWorkExperienceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDrWorkExperienceAPIResClearAction = (
  editDrWorkExperienceResponse: EditDrWorkExperienceRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrWorkExperienceActionTypes => {
  return {
    type: EDIT_DR_WORK_EXPERIENCE_API_RES,
    payload: editDrWorkExperienceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
