import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GenerateDoctorAgreementActionTypes } from "./ActionTypes";
import { GenerateAgreementBody, GenerateAgreementRes } from "./Model";
import {
  generateDoctorAgreementAPIResClearAction,
  generateDoctorAgreementErrorAction,
  generateDoctorAgreementLoadingAction,
  generateDoctorAgreementSuccessAction,
  generateDoctorAgreementAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GenerateAgreementRes;
export const getAgreementTypeList = (body: GenerateAgreementBody) => {
  return function (dispatch: Dispatch<GenerateDoctorAgreementActionTypes>) {
    dispatch(generateDoctorAgreementLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/api/generate-agreement/`, body, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(generateDoctorAgreementLoadingAction(false));
        dispatch(
          generateDoctorAgreementSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. agreement has been generated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(generateDoctorAgreementLoadingAction(false));
        dispatch(
          generateDoctorAgreementErrorAction(
            {} as GenerateAgreementRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGenerateDoctorAgreementAPIResMsg = () => {
  return function (dispatch: Dispatch<GenerateDoctorAgreementActionTypes>) {
    dispatch(
      generateDoctorAgreementAPIMsgAction(apiRes as GenerateAgreementRes, "", 0)
    );
  };
};

export const clearGenerateDoctorAgreementListAPIRes = () => {
  return function (dispatch: Dispatch<GenerateDoctorAgreementActionTypes>) {
    dispatch(
      generateDoctorAgreementAPIResClearAction(
        {} as GenerateAgreementRes,
        "",
        0
      )
    );
  };
};
