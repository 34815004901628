import {
  GET_DOCTOR_AWARD_TYPE_LIST_FAIL,
  GET_DOCTOR_AWARD_TYPE_LIST_LOADING,
  GET_DOCTOR_AWARD_TYPE_LIST_SUCCESS,
  GET_DOCTOR_AWARD_TYPE_LIST_API_MSG,
  GET_DOCTOR_AWARD_TYPE_LIST_API_RES,
  GetDoctorAwardTypeListActionTypes,
} from "./ActionTypes";
import { GetAwardTypeListRes } from "./Model";

export const getDoctorAwardTypeListLoadingAction = (
  loading: boolean
): GetDoctorAwardTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_TYPE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorAwardTypeListSuccessAction = (
  getDoctorAwardTypeListResponse: GetAwardTypeListRes,
  successMsg: string
): GetDoctorAwardTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_TYPE_LIST_SUCCESS,
    payload: getDoctorAwardTypeListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorAwardTypeListErrorAction = (
  getDoctorAwardTypeListResponse: GetAwardTypeListRes,
  errMsg: string,
  status: number
): GetDoctorAwardTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_TYPE_LIST_FAIL,
    payload: getDoctorAwardTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorAwardTypeListAPIMsgAction = (
  getDoctorAwardTypeListResponse: GetAwardTypeListRes,
  errMsg: string,
  status: number
): GetDoctorAwardTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_TYPE_LIST_API_MSG,
    payload: getDoctorAwardTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorAwardTypeListAPIResClearAction = (
  getDoctorAwardTypeListResponse: GetAwardTypeListRes,
  errMsg: string,
  status: number
): GetDoctorAwardTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_TYPE_LIST_API_RES,
    payload: getDoctorAwardTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
