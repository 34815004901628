import {
    AddDrLegalDetailsActionTypes,
    QUESTION_LIST_FAIL,
    QUESTION_LIST_LOADING,
    QUESTION_LIST_SUCCESS,
    QuestionListState,
    QUESTION_LIST_API_MSG,
    QUESTION_LIST_API_RES,
  } from "./ActionTypes";
  import { QuestionListResponse } from "./Model";
  
  const initialStateEditPosts: QuestionListState = {
    loading: false,
    questionListResponse: {} as QuestionListResponse,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const questionListReducer = (
    state = initialStateEditPosts,
    action: AddDrLegalDetailsActionTypes
  ): QuestionListState => {
    switch (action.type) {
      case QUESTION_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case QUESTION_LIST_SUCCESS:
        return {
          ...state,
          questionListResponse: action.payload,
          successMsg: action.successMsg,
        };
      case QUESTION_LIST_FAIL:
        return {
          ...state,
          questionListResponse: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case QUESTION_LIST_API_MSG:
        return {
          ...state,
          questionListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case QUESTION_LIST_API_RES:
        return {
          ...state,
          questionListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };