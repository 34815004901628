import { GetPersonalDetailsRes } from "./Model";
export const GET_PATIENT_LOADING = "GET_PATIENT_LOADING";
export const GET_PATIENT_SUCCESS = "GET_PATIENT_SUCCESS";
export const GET_PATIENT_FAIL = "GET_PATIENT_FAIL";
export const GET_PATIENT_DATA = "GET_PATIENT_DATA";
export const GET_PATIENT_UPDATE_API_MSG =
  "GET_PATIENT_UPDATE_API_MSG";
export const GET_PATIENT_UPDATE_API_RES =
  "GET_PATIENT_UPDATE_API_RES";

export interface GetPatientState {
  getPersonalDetailsRes: GetPersonalDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientLoading {
  type: typeof GET_PATIENT_LOADING;
  loading: boolean;
}
export interface GetPatientSuccess {
  type: typeof GET_PATIENT_SUCCESS;
  payload: GetPersonalDetailsRes;
  successMsg: string;
}
export interface GetPatientFail {
  type: typeof GET_PATIENT_FAIL;
  payload: GetPersonalDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetPatientUpdateAPIMsg {
  type: typeof GET_PATIENT_UPDATE_API_MSG;
  payload: GetPersonalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPatientUpdateAPIRes {
  type: typeof GET_PATIENT_UPDATE_API_RES;
  payload: GetPersonalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPatientActionTypes =
  | GetPatientLoading
  | GetPatientSuccess
  | GetPatientFail
  | GetPatientUpdateAPIMsg
  | GetPatientUpdateAPIRes;

