import {
  CaseGetActionTypes,
  GET_CASE_LIST,
  AppointmentGetActionTypes,
  GET_APPOINTMENT_LIST,
} from "../types/ActionTypes";
import { AppointmentList, CaseList } from "../interfaces/Model";

export const getCaseAction = (cases: CaseList[]): CaseGetActionTypes => {
  return {
    type: GET_CASE_LIST,
    payload: cases,
  };
};

export const getAppointmentAction = (
  appointments: AppointmentList[]
): AppointmentGetActionTypes => {
  return {
    type: GET_APPOINTMENT_LIST,
    payload: appointments,
  };
};
