import {
  GetPrescriptionListActionTypes,
  GET_PRESCRIPTION_LIST_FAIL,
  GET_PRESCRIPTION_LIST_LOADING,
  GET_PRESCRIPTION_LIST_SUCCESS,
  GetPrescriptionListState,
  GET_PRESCRIPTION_LIST_API_MSG,
  GET_PRESCRIPTION_LIST_API_RES,
} from "./ActionTypes";
import { GetPrescriptionListRes } from "./Model";

const initialStateEditPosts: GetPrescriptionListState = {
  loading: false,
  getPrescriptionListRes: {} as GetPrescriptionListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const prescriptionListReducer = (
  state = initialStateEditPosts,
  action: GetPrescriptionListActionTypes
): GetPrescriptionListState => {
  switch (action.type) {
    case GET_PRESCRIPTION_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PRESCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        getPrescriptionListRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_PRESCRIPTION_LIST_FAIL:
      return {
        ...state,
        getPrescriptionListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PRESCRIPTION_LIST_API_MSG:
      return {
        ...state,
        getPrescriptionListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PRESCRIPTION_LIST_API_RES:
      return {
        ...state,
        getPrescriptionListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
