import {
  GET_DOCTOR_ID_PROOF_TYPE_LIST_FAIL,
  GET_DOCTOR_ID_PROOF_TYPE_LIST_LOADING,
  GET_DOCTOR_ID_PROOF_TYPE_LIST_SUCCESS,
  GET_DOCTOR_ID_PROOF_TYPE_LIST_API_MSG,
  GET_DOCTOR_ID_PROOF_TYPE_LIST_API_RES,
  GetDrIdProofTypeListActionTypes,
} from "./ActionTypes";
import { GetDoctorIdTypeListRes } from "./Model";

export const getDrIdProofTypeListLoadingAction = (
  loading: boolean
): GetDrIdProofTypeListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_TYPE_LIST_LOADING,
    loading: loading,
  };
};

export const getDrIdProofTypeListSuccessAction = (
  getDrIdProofTypeListResponse: GetDoctorIdTypeListRes,
  successMsg: string
): GetDrIdProofTypeListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_TYPE_LIST_SUCCESS,
    payload: getDrIdProofTypeListResponse,
    successMsg: successMsg,
  };
};

export const getDrIdProofTypeListErrorAction = (
  getDrIdProofTypeListResponse: GetDoctorIdTypeListRes,
  errMsg: string,
  status: number
): GetDrIdProofTypeListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_TYPE_LIST_FAIL,
    payload: getDrIdProofTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDrIdProofTypeListAPIMsgAction = (
  getDrIdProofTypeListResponse: GetDoctorIdTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDrIdProofTypeListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_TYPE_LIST_API_MSG,
    payload: getDrIdProofTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDrIdProofTypeListAPIResClearAction = (
  getDrIdProofTypeListResponse: GetDoctorIdTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDrIdProofTypeListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_TYPE_LIST_API_RES,
    payload: getDrIdProofTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
