import {
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_UPDATE_API_MSG,
  FORGOT_PASSWORD_UPDATE_API_RES,
  ForgetPasswordActionTypes,
} from "./ActionTypes";
import { ForgotPasswordRes } from "./Model";

export const forgotPasswordLoadingAction = (
  loading: boolean
): ForgetPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_LOADING,
    loading: loading,
  };
};

export const forgotPasswordSuccessAction = (
  forgotPasswordResponse: ForgotPasswordRes,
  successMsg: string
): ForgetPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotPasswordResponse,
    successMsg: successMsg,
  };
};

export const forgotPasswordErrorAction = (
  forgotPasswordResponse: ForgotPasswordRes,
  errMsg: string,
  status: number
): ForgetPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_FAIL,
    payload: forgotPasswordResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const forgotPasswordUpdateAPIMsgAction = (
  forgotPasswordResponse: ForgotPasswordRes,
  successMsg: string,
  errMsg: string,
  status: number
): ForgetPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_UPDATE_API_MSG,
    payload: forgotPasswordResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const forgotPasswordAPIResClearAction = (
  forgotPasswordResponse: ForgotPasswordRes,
  successMsg: string,
  errMsg: string,
  status: number
): ForgetPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_UPDATE_API_RES,
    payload: forgotPasswordResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
