import {
  GetPatientDataListActionTypes,
  GET_PATIENT_DATA_LIST_FAIL,
  GET_PATIENT_DATA_LIST_LOADING,
  GET_PATIENT_DATA_LIST_SUCCESS,
  GetPatientDataListState,
  GET_PATIENT_DATA_LIST_UPDATE_API_MSG,
  GET_PATIENT_DATA_LIST_UPDATE_API_RES,
} from "./ActionTypes";
import { GetPatientDataListRes } from "./Model";

const initialState: GetPatientDataListState = {
  loading: false,
  getPatientDataListRes: {} as GetPatientDataListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getPatientDataListReducer = (
  state = initialState,
  action: GetPatientDataListActionTypes
): GetPatientDataListState => {
  switch (action.type) {
    case GET_PATIENT_DATA_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PATIENT_DATA_LIST_SUCCESS:
      return {
        ...state,
        getPatientDataListRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_PATIENT_DATA_LIST_FAIL:
      return {
        ...state,
        getPatientDataListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_DATA_LIST_UPDATE_API_MSG:
      return {
        ...state,
        getPatientDataListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_DATA_LIST_UPDATE_API_RES:
      return {
        ...state,
        getPatientDataListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
