import { GetDegreeListRes } from "./Model";
export const GET_DOCTOR_DEGREE_LIST_LOADING =
  "GET_DOCTOR_DEGREE_LIST_LOADING";
export const GET_DOCTOR_DEGREE_LIST_SUCCESS =
  "GET_DOCTOR_DEGREE_LIST_SUCCESS";
export const GET_DOCTOR_DEGREE_LIST_FAIL =
  "GET_DOCTOR_DEGREE_LIST_FAIL";
export const GET_DOCTOR_DEGREE_LIST_API_MSG =
  "GET_DOCTOR_DEGREE_LIST_API_MSG";
export const GET_DOCTOR_DEGREE_LIST_API_RES =
  "GET_DOCTOR_DEGREE_LIST_API_RES";

export interface GetDoctorDegreeListState {
  getDoctorDegreeListRes: GetDegreeListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetDoctorDegreeListLoading {
  type: typeof GET_DOCTOR_DEGREE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorDegreeListSuccess {
  type: typeof GET_DOCTOR_DEGREE_LIST_SUCCESS;
  payload: GetDegreeListRes;
  successMsg: string;
}
export interface GetDoctorDegreeListFail {
  type: typeof GET_DOCTOR_DEGREE_LIST_FAIL;
  payload: GetDegreeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorDegreeListAPIMsg {
  type: typeof GET_DOCTOR_DEGREE_LIST_API_MSG;
  payload: GetDegreeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorDegreeListAPIRes {
  type: typeof GET_DOCTOR_DEGREE_LIST_API_RES;
  payload: GetDegreeListRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorDegreeListActionTypes =
  | GetDoctorDegreeListLoading
  | GetDoctorDegreeListSuccess
  | GetDoctorDegreeListFail
  | GetDoctorDegreeListAPIMsg
  | GetDoctorDegreeListAPIRes;
