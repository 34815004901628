import React, { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getDoctorAppointmentList } from "redux/effects/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import MWExceptionList from "components/MWExceptionList";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { AppointmentList } from "redux/interfaces/Model";
export default function DoctorAppointment() {
  const history = useHistory();
  const gotoPatientCaseDetails = (
    patientId: any,
    caseid: any,
    rowIndex: any
  ) => {
    history.push(
      `/appointmentdetails/${patientId}/${caseid}/${rowIndex}?&tab=doctorpatientinteraction`
    );
  };
  const appointmenttableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "View",
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            gotoPatientCaseDetails(
              params.row.patientId,
              params.row.caseid,
              params.row.id
            );
          }}
        >
          View
        </Button>
      ),
    },
    { field: "name", headerName: "Appoinment Name", width: 250 },
    { field: "patientName", headerName: "Patient Name", width: 200 },
    {
      field: "createDate",
      headerName: "Create Date",
      width: 200,
    },
    {
      field: "end",
      headerName: "End Date",
      width: 200,
    },
    {
      field: "modifiedDate",
      headerName: "Modified Date",
      width: 200,
    },
    {
      field: "appoinmentStatus",
      headerName: "Status",
      width: 300,
    },
  ];
  const dispatch = useDispatch();

  const selectionTypeBody = {
    appointmentStatus: "",
  };

  // call Store
  const allAppointmentValues = useSelector(
    (state: AppState) => state.appointmentValue
  );
  const truncate = (str: string) => {
    return str.length > 0 ? str.substring(0, 30) + "..." : str;
  };

  const [appointmenttableValue, setAppointmenttableValue] = React.useState(
    [] as any[]
  );

  const reloadAppointList = () => {
    dispatch(getDoctorAppointmentList(selectionTypeBody));
  };

  useEffect(() => {
    dispatch(getDoctorAppointmentList(selectionTypeBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (allAppointmentValues?.appointmentValue !== undefined) {
      let allAppointmentListTableValues =
        allAppointmentValues?.appointmentValue.map((element: any) => ({
          id: element.id,
          caseid: element.caseId,
          patientId: element.patientId,
          name: truncate(element.name),
          patientName: element.patientName,
          createDate: dayjs(element.createDate).format("ddd, MMMM D, YYYY"),
          endDate: dayjs(element.end).format("ddd, MMMM D, YYYY"),
          modifiedDate: dayjs(element.modifiedDate).format("ddd, MMMM D, YYYY"),
          appoinmentStatus: element.hasOwnProperty("status")
            ? element.status === null || element.status === ""
              ? ""
              : element.status
            : "",
        }));
      setAppointmenttableValue(allAppointmentListTableValues);
    } else setAppointmenttableValue([] as AppointmentList[]);
  }, [allAppointmentValues]);

  const appointmentTableBody = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">
                {t("appointmentpage.existingdetail", { ns: ["home"] })!}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={reloadAppointList}
                    sx={{ textTransform: "none" }}
                  >
                    Reload
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        <Box style={{ maxHeight: 600, width: "100%", overflow: "auto" }}>
          {appointmenttableValue && appointmenttableValue.length ? (
            <DataGrid
              rows={appointmenttableValue}
              columns={appointmenttableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return <Box>{appointmentTableBody}</Box>;
}
