import {
  EMAIL_PHONE_OTP_GEN_FAIL,
  EMAIL_PHONE_OTP_GEN_LOADING,
  EMAIL_PHONE_OTP_GEN_SUCCESS,
  EMAIL_PHONE_OTP_GEN_UPDATE_API_MSG,
  EMAIL_PHONE_OTP_GEN_UPDATE_API_RES,
  EmailPhoneOTPGenActionTypes,
} from "./ActionTypes";
import { EmailPhoneOTPGenRes } from "./Model";

export const emailPhoneOTPGenLoadingAction = (
  loading: boolean
): EmailPhoneOTPGenActionTypes => {
  return {
    type: EMAIL_PHONE_OTP_GEN_LOADING,
    loading: loading,
  };
};

export const emailPhoneOTPGenSuccessAction = (
  emailPhoneOTPGenResponse: EmailPhoneOTPGenRes,
  successMsg: string
): EmailPhoneOTPGenActionTypes => {
  return {
    type: EMAIL_PHONE_OTP_GEN_SUCCESS,
    payload: emailPhoneOTPGenResponse,
    successMsg: successMsg,
  };
};

export const emailPhoneOTPGenErrorAction = (
  emailPhoneOTPGenResponse: EmailPhoneOTPGenRes,
  errMsg: string,
  status: number
): EmailPhoneOTPGenActionTypes => {
  return {
    type: EMAIL_PHONE_OTP_GEN_FAIL,
    payload: emailPhoneOTPGenResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const emailPhoneOTPGenUpdateAPIMsgAction = (
  emailPhoneOTPGenResponse: EmailPhoneOTPGenRes,
  successMsg: string,
  errMsg: string,
  status: number
): EmailPhoneOTPGenActionTypes => {
  return {
    type: EMAIL_PHONE_OTP_GEN_UPDATE_API_MSG,
    payload: emailPhoneOTPGenResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const emailPhoneOTPGenAPIResClearAction = (
  emailPhoneOTPGenResponse: EmailPhoneOTPGenRes,
  successMsg: string,
  errMsg: string,
  status: number
): EmailPhoneOTPGenActionTypes => {
  return {
    type: EMAIL_PHONE_OTP_GEN_UPDATE_API_RES,
    payload: emailPhoneOTPGenResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
