import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import {
  PaymentStatusActionTypes,
  PAYMENT_STATUS_FAIL,
  PAYMENT_STATUS_LOADING,
  PAYMENT_STATUS_SUCCESS,
} from "./ActionTypes";
import { PaymentStatusModel, PaymentStatusRes } from "./Model";
import { headers } from "components/Utility";

export const paymentStatusApi = (body: PaymentStatusModel) => {
  return function (dispatch: Dispatch<PaymentStatusActionTypes>) {
    dispatch({
      type: PAYMENT_STATUS_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL + `/paymentapp/api/payment/get_payment_status`,
        body,
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch({
          type: PAYMENT_STATUS_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_STATUS_FAIL,
          payload: {} as PaymentStatusRes,
          errRes: error.response.data.message,
        });
      });
  };
};
