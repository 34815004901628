import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedLicenseList } from "../Model";
import MWUploader from "components/MWUploader";
import { AppState } from "redux/store/Store";
import { LicenseTypeList } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/DoctorLicenseTypeDropdown/Model";
import dayjs, { Dayjs } from "dayjs";
import { AddDrProfessionalLicenseBody } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/AddProfessionalLicenseDetails/Model";
import {
  addDrLicense,
  clearAddDrLicenseAPIRes,
} from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/AddProfessionalLicenseDetails/API";
import { getLicenseList } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/GetLicenseList/API";
import { EditProfessionalLicenseBody } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/EditProfessionalLicenseDetails/Model";
import {
  editDrLicense,
  clearEditDrLicenseAPIRes,
} from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/EditProfessionalLicenseDetails/API";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import MWLoader from "components/MWLoader";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedLicenseList | any;
  selectedItemUpdateState: any;
}
export default function UpdateLicense({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setLicenseNumber("");
    setLicenseNumberError("");
    setSelectedLicense(null);
    setSelectedLicenseError("");
    setImageId("");
    setSelectedDate(dayjs(new Date()));
    setSelectedToDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    dispatch(clearAddDrLicenseAPIRes());
    setAddDrLicenseMsg("");
    dispatch(clearEditDrLicenseAPIRes());
    dispatch(clearImageUploadAPIRes());
    setEditDrLicenseMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const getDoctorDegreeListRes = useSelector(
    (state: AppState) => state.getDrLicenseTypeListRes
  );
  const [degreeList, setDegreeList] = useState([] as LicenseTypeList[]);
  useEffect(() => {
    if (
      getDoctorDegreeListRes?.getDoctorLicenseTypeListRes?.licenseTypeList !==
      undefined
    ) {
      if (
        getDoctorDegreeListRes?.getDoctorLicenseTypeListRes?.licenseTypeList
          .length > 0
      ) {
        setDegreeList(
          getDoctorDegreeListRes?.getDoctorLicenseTypeListRes?.licenseTypeList
        );
      } else setDegreeList([] as LicenseTypeList[]);
    } else setDegreeList([] as LicenseTypeList[]);
  }, [getDoctorDegreeListRes]);

  const [licenseNumber, setLicenseNumber] = useState("");
  const licenseNumberChange = (event: string) => {
    setLicenseNumber(event);
  };
  const [licenseNumberError, setLicenseNumberError] = useState("");

  const [selectedLicense, setSelectedLicense] =
    useState<LicenseTypeList | null>(null);
  const selectedLicenseChange = (
    _event: any,
    passedVal: LicenseTypeList | any
  ) => {
    setSelectedLicense(passedVal);
  };
  const [selectedLicenseError, setSelectedLicenseError] = useState("");
  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // to Date
  const [toDate, setSelectedToDate] = React.useState<Dayjs | null>(null);
  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
  };
  const [description, setDescription] = useState("");
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  // Call Specialty List store
  const getDrLicenseTypeListRes = useSelector(
    (state: AppState) => state.getDrLicenseTypeListRes
  );

  useEffect(() => {
    selectedItem?.idNumber !== undefined
      ? setLicenseNumber(selectedItem?.idNumber)
      : setLicenseNumber("");

    selectedItem?.idValue !== undefined &&
    getDrLicenseTypeListRes?.getDoctorLicenseTypeListRes?.licenseTypeList !==
      undefined
      ? selectedItem?.idValue !== 0 &&
        getDrLicenseTypeListRes?.getDoctorLicenseTypeListRes
          ?.licenseTypeList !== undefined
        ? setSelectedLicense(
            getDrLicenseTypeListRes?.getDoctorLicenseTypeListRes?.licenseTypeList!.find(
              (o) => o.value === selectedItem?.idValue
            )!
          )
        : setSelectedLicense(null)
      : setSelectedLicense(null);

    if (selectedItem?.startDate !== undefined) {
      let year = selectedItem?.startDate.split("-")[2];
      let month = selectedItem?.startDate.split("-")[1];
      let day = selectedItem?.startDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedDate(dayjs(date));
    }
    if (selectedItem?.startDate === undefined) {
      setSelectedDate(dayjs(new Date()));
    } else if (selectedItem?.startDate === "Not Set") {
      setSelectedDate(dayjs(new Date()));
    }

    if (selectedItem?.endDate !== undefined) {
      if (selectedItem?.endDate !== "Not Set") {
        let year = selectedItem?.endDate.split("-")[2];
        let month = selectedItem?.endDate.split("-")[1];
        let day = selectedItem?.endDate.split("-")[0];
        let date = year + "-" + month + "-" + day;
        setSelectedToDate(dayjs(date));
      }
    }
    if (selectedItem?.endDate === undefined) {
      setSelectedToDate(null);
    } else if (selectedItem?.endDate === "Not Set") {
      setSelectedToDate(null);
    }
    selectedItem?.description !== undefined
      ? selectedItem?.description !== ""
        ? setDescription(selectedItem?.description)
        : setDescription("")
      : setDescription("");

    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== ""
        ? setImageInfo(selectedItem?.supportingDocument)
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, getDrLicenseTypeListRes]);

  const addDrLicenseBody = {
    licenseTypeId: String(selectedLicense?.value),
    idNumber: licenseNumber,
    fromDate:
      selectedDate !== null
        ? dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    toDate:
      toDate !== null
        ? dayjs(toDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    description: description,
  } as AddDrProfessionalLicenseBody;

  const submitEducationalDetails = () => {
    if (selectedLicense === null && licenseNumber === "") {
      setSelectedLicenseError(
        `${t("userprofilepage.licTypeNotEmp", { ns: ["home"] })!}`
      );
      setLicenseNumberError(
        `${t("userprofilepage.licenseNumberError", { ns: ["home"] })!}`
      );
    } else if (selectedLicense === null && licenseNumber !== "") {
      setSelectedLicenseError(
        `${t("userprofilepage.licTypeNotEmp", { ns: ["home"] })!}`
      );
      setLicenseNumberError("");
    } else if (selectedLicense !== null && licenseNumber === "") {
      setSelectedLicenseError("");
      setLicenseNumberError(
        `${t("userprofilepage.licenseNumberError", { ns: ["home"] })!}`
      );
    } else {
      setSelectedLicenseError("");
      setLicenseNumberError("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Education Details
        if (imageId === undefined) {
          dispatch(
            addDrLicense(
              Object.assign(addDrLicenseBody, {
                supportdocId: imageId !== "" ? imageId : null,
              } as AddDrProfessionalLicenseBody)
            )
          );
        } else {
          dispatch(
            addDrLicense(
              Object.assign(addDrLicenseBody, {
                supportdocId: imageId !== "" ? imageId : null,
              } as AddDrProfessionalLicenseBody)
            )
          );
        }
      } else {
        // Edit Dr. Education Details
        if (imageId === undefined) {
          // When user does not upload image on add work experience
          dispatch(
            editDrLicense(
              Object.assign(addDrLicenseBody, {
                licenseId: String(selectedItem?.id!),
                supportdocId: null,
              } as EditProfessionalLicenseBody)
            )
          );
        } else {
          dispatch(
            editDrLicense(
              Object.assign(addDrLicenseBody, {
                licenseId: String(selectedItem?.id!),
                supportdocId: imageId !== "" ? String(imageId) : null,
              } as EditProfessionalLicenseBody)
            )
          );
        }
      }
    }
  };

  const addDrLicenseRes = useSelector(
    (state: AppState) => state.addDrLicenseRes
  );
  const [addDrLicenseMsg, setAddDrLicenseMsg] = useState("");
  const [addDrLicenseMsgType, setAddDrLicenseMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addDrLicenseRes?.successMsg !== "") {
      setAddDrLicenseMsgType("success");
      setAddDrLicenseMsg(addDrLicenseRes?.successMsg);
      dispatch(getLicenseList());
      closeDialog();
    }
    if (addDrLicenseRes?.errorMsg !== "") {
      setAddDrLicenseMsgType("error");
      setAddDrLicenseMsg(addDrLicenseRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDrLicenseRes]);

  const editDrLicenseRes = useSelector(
    (state: AppState) => state.editDrLicenseRes
  );
  const [editDrLicenseMsg, setEditDrLicenseMsg] = useState("");
  const [editDrLicenseMsgType, setEditDrLicenseMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (editDrLicenseRes?.successMsg !== "") {
      setEditDrLicenseMsgType("success");
      setEditDrLicenseMsg(editDrLicenseRes?.successMsg);
      dispatch(getLicenseList());
      closeDialog();
    }
    if (editDrLicenseRes?.errorMsg !== "") {
      setEditDrLicenseMsgType("error");
      setEditDrLicenseMsg(editDrLicenseRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDrLicenseRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addDrLicenseRes?.loading || editDrLicenseRes?.loading ? (
        <MWLoader />
      ) : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {t("userprofilepage.editprofdetail", { ns: ["home"] })!}
              </Typography>
            ) : (
              <Typography variant="h5">
                {t("userprofilepage.addproflicense", { ns: ["home"] })!}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("userprofilepage.sellicense", { ns: ["home"] })!}
              </Typography>
              <Autocomplete
                value={selectedLicense}
                id="highest-states"
                options={degreeList}
                onChange={(event: any, newValue: any) => {
                  selectedLicenseChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      t("userprofilepage.sellicense", { ns: ["home"] })!
                    }
                  />
                )}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value) =>
                  option.value === value.value
                }
              />
              {selectedLicenseError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {selectedLicenseError}
                </Typography>
              ) : null}
              <Typography style={{ marginBottom: "5px" }}>
                {t("userprofilepage.licensenum", { ns: ["home"] })!}
              </Typography>
              <TextField
                value={licenseNumber}
                onChange={(e) => licenseNumberChange(e.target.value)}
                label={t("userprofilepage.licensenum", { ns: ["home"] })}
                type="text"
                placeholder={
                  t("userprofilepage.sellicensenum", { ns: ["home"] })!
                }
                id="licenseNumbername"
                fullWidth
                size="small"
              />
              {licenseNumberError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {licenseNumberError}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="work-experience-supporting-docs"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("userprofilepage.startdate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("appointmentpage.enddate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={handleToDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                  maxDate={dayjs(new Date())}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                label={t("appointmentpage.desc", { ns: ["home"] })}
                fullWidth
                value={description}
                onChange={(e) => handleDescriptionChange(e.target.value)}
                placeholder={t("appointmentpage.enterdesc", { ns: ["home"] })!}
                multiline
                maxRows={4}
                id="summaryID"
                inputProps={{
                  style: { fontSize: 15 },
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        {addDrLicenseMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrLicenseMsgType}
              onClose={() => {
                dispatch(clearAddDrLicenseAPIRes());
                setAddDrLicenseMsg("");
              }}
            >
              {addDrLicenseMsg}
            </Alert>
          </Box>
        ) : null}
        {editDrLicenseMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editDrLicenseMsgType}
              onClose={() => {
                dispatch(clearEditDrLicenseAPIRes());
                setEditDrLicenseMsg("");
              }}
            >
              {editDrLicenseMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={addDrLicenseRes?.loading || editDrLicenseRes?.loading}
              onClick={() => submitEducationalDetails()}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
