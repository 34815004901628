import { createTheme } from "@mui/material/styles";
const mwTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#DE3618",
    },
    secondary: {
      main: "#330101",
    },
    background: {
      default: "#fdf5f2",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: [
      "Fira Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"San Francisco"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "8px",
          minHeight: "40px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "black",
          "&.Mui-selected": {
            backgroundColor: "#F5D9D9",
            color: "#DE3618",
            borderLeft: "solid 2px",
            "&.Mui-focusVisible": {
              background: "#DE3618",
            },
            "& .MuiListItemIcon-root": {
              color: "#DE3618",
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "start",
          justifyContent: "start",
          textAlign: "right",
          textTransform: "none",
          minHeight: 0,
          padding: "10px 20px",
        },
      },
    },
  },
});

export default mwTheme;
