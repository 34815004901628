import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../URL";
import { SubmitQuestionActionTypes } from "./ActionTypes";
import { SubmitQuestionBody, SubmitQuestionResponse } from "./Model";
import {
  submitQuestionAPIResClearAction,
  submitQuestionErrorAction,
  submitQuestionLoadingAction,
  submitQuestionSuccessAction,
  submitQuestionAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as SubmitQuestionResponse;
export const submitQuestionApi = (payload: SubmitQuestionBody) => {
  return function (dispatch: Dispatch<SubmitQuestionActionTypes>) {
    dispatch(submitQuestionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/questionandanswerapp/api/question/answer_Question`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(submitQuestionLoadingAction(false));
        dispatch(
          submitQuestionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Question list fetch successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(submitQuestionLoadingAction(false));
        dispatch(
          submitQuestionErrorAction(
            {} as SubmitQuestionResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const submitQuestionAPIResMsg = () => {
  return function (dispatch: Dispatch<SubmitQuestionActionTypes>) {
    dispatch(
      submitQuestionAPIMsgAction(apiRes as SubmitQuestionResponse, "", "", 0)
    );
  };
};

export const clearQuestionAPIRes = () => {
  return function (dispatch: Dispatch<SubmitQuestionActionTypes>) {
    dispatch(
      submitQuestionAPIResClearAction({} as SubmitQuestionResponse, "", "", 0)
    );
  };
};