import {
  GET_PATIENT_FAIL,
  GET_PATIENT_LOADING,
  GET_PATIENT_SUCCESS,
  GET_PATIENT_UPDATE_API_MSG,
  GET_PATIENT_UPDATE_API_RES,
  GetPatientActionTypes,
} from "./ActionTypes";
import { GetPersonalDetailsRes } from "./Model";

export const getPatientLoadingAction = (
  loading: boolean
): GetPatientActionTypes => {
  return {
    type: GET_PATIENT_LOADING,
    loading: loading,
  };
};

export const getPatientSuccessAction = (
  getPersonalDetailsRes: GetPersonalDetailsRes,
  successMsg: string
): GetPatientActionTypes => {
  return {
    type: GET_PATIENT_SUCCESS,
    payload: getPersonalDetailsRes,
    successMsg: successMsg,
  };
};

export const getPatientErrorAction = (
  getPersonalDetailsRes: GetPersonalDetailsRes,
  errMsg: string,
  status: number
): GetPatientActionTypes => {
  return {
    type: GET_PATIENT_FAIL,
    payload: getPersonalDetailsRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientUpdateAPIMsgAction = (
  getPersonalDetailsRes: GetPersonalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientActionTypes => {
  return {
    type: GET_PATIENT_UPDATE_API_MSG,
    payload: getPersonalDetailsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientAPIResClearAction = (
  getPersonalDetailsRes: GetPersonalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientActionTypes => {
  return {
    type: GET_PATIENT_UPDATE_API_RES,
    payload: getPersonalDetailsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

