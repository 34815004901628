import {
  EditDrInsuranceActionTypes,
  EDIT_DR_INSURANCE_FAIL,
  EDIT_DR_INSURANCE_LOADING,
  EDIT_DR_INSURANCE_SUCCESS,
  EditDrInsuranceState,
  EDIT_DR_INSURANCE_API_MSG,
  EDIT_DR_INSURANCE_API_RES,
} from "./ActionTypes";
import { EditDrInsuranceRes } from "./Model";

const initialStateEditPosts: EditDrInsuranceState = {
  loading: false,
  editDrInsuranceRes: {} as EditDrInsuranceRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editDrInsuranceReducer = (
  state = initialStateEditPosts,
  action: EditDrInsuranceActionTypes
): EditDrInsuranceState => {
  switch (action.type) {
    case EDIT_DR_INSURANCE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_DR_INSURANCE_SUCCESS:
      return {
        ...state,
        editDrInsuranceRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_DR_INSURANCE_FAIL:
      return {
        ...state,
        editDrInsuranceRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_INSURANCE_API_MSG:
      return {
        ...state,
        editDrInsuranceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_INSURANCE_API_RES:
      return {
        ...state,
        editDrInsuranceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
