import {
  GET_DOCTOR_LEGAL_DETAILS_FAIL,
  GET_DOCTOR_LEGAL_DETAILS_LOADING,
  GET_DOCTOR_LEGAL_DETAILS_SUCCESS,
  GET_DOCTOR_LEGAL_DETAILS_API_MSG,
  GET_DOCTOR_LEGAL_DETAILS_API_RES,
  GetDoctorLegalDetailsActionTypes,
} from "./ActionTypes";
import { GetLegalDetailsByDoctorRes } from "./Model";

export const getDoctorLegalDetailsLoadingAction = (
  loading: boolean
): GetDoctorLegalDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_DETAILS_LOADING,
    loading: loading,
  };
};

export const getDoctorLegalDetailsSuccessAction = (
  getDoctorLegalDetailsResponse: GetLegalDetailsByDoctorRes,
  successMsg: string
): GetDoctorLegalDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_DETAILS_SUCCESS,
    payload: getDoctorLegalDetailsResponse,
    successMsg: successMsg,
  };
};

export const getDoctorLegalDetailsErrorAction = (
  getDoctorLegalDetailsResponse: GetLegalDetailsByDoctorRes,
  errMsg: string,
  status: number
): GetDoctorLegalDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_DETAILS_FAIL,
    payload: getDoctorLegalDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorLegalDetailsAPIMsgAction = (
  getDoctorLegalDetailsResponse: GetLegalDetailsByDoctorRes,
  errMsg: string,
  status: number
): GetDoctorLegalDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_DETAILS_API_MSG,
    payload: getDoctorLegalDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorLegalDetailsAPIResClearAction = (
  getDoctorLegalDetailsResponse: GetLegalDetailsByDoctorRes,
  errMsg: string,
  status: number
): GetDoctorLegalDetailsActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_DETAILS_API_RES,
    payload: getDoctorLegalDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
