import {
  EMAIL_OTP_GEN_FAIL,
  EMAIL_OTP_GEN_LOADING,
  EMAIL_OTP_GEN_SUCCESS,
  EMAIL_OTP_GEN_UPDATE_API_MSG,
  EMAIL_OTP_GEN_UPDATE_API_RES,
  AddScheduleActionTypes,
} from "./ActionTypes";
import { emailOtpGenRes } from "./Model";

export const emailOTPGenLoadingAction = (
  loading: boolean
): AddScheduleActionTypes => {
  return {
    type: EMAIL_OTP_GEN_LOADING,
    loading: loading,
  };
};

export const emailOTPGenSuccessAction = (
  emailOTPGenResponse: emailOtpGenRes,
  successMsg: string
): AddScheduleActionTypes => {
  return {
    type: EMAIL_OTP_GEN_SUCCESS,
    payload: emailOTPGenResponse,
    successMsg: successMsg,
  };
};

export const emailOTPGenErrorAction = (
  emailOTPGenResponse: emailOtpGenRes,
  errMsg: string,
  status: number
): AddScheduleActionTypes => {
  return {
    type: EMAIL_OTP_GEN_FAIL,
    payload: emailOTPGenResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const emailOTPGenUpdateAPIMsgAction = (
  emailOTPGenResponse: emailOtpGenRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddScheduleActionTypes => {
  return {
    type: EMAIL_OTP_GEN_UPDATE_API_MSG,
    payload: emailOTPGenResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const emailOTPGenAPIResClearAction = (
  emailOTPGenResponse: emailOtpGenRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddScheduleActionTypes => {
  return {
    type: EMAIL_OTP_GEN_UPDATE_API_RES,
    payload: emailOTPGenResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
