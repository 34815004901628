import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import PageLayout from "components/MWPageLayout";
import MWPageTitle from "components/MWPageTitle";
import MWSnackbar from "components/MWSnackbar";
import dayjs from "dayjs";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  clearDrAppointmentDecisionAPIRes,
  drAppointmentDecision,
} from "redux/AddDoctorTeam/doctorAcceptAppointment/API";
import { DoctorAppointmentDecisionBody } from "redux/AddDoctorTeam/doctorAcceptAppointment/Model";
import { getTaskListList } from "redux/Task/API";
import { TaskDataList } from "redux/Task/Model";
import { AppState } from "redux/store/Store";

export default function TaskDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { patientId, caseId, index } = useParams() as {
    patientId: string;
    caseId: string;
    index: string;
  };
  // Call Store
  const getTaskListRes = useSelector((state: AppState) => state.getTaskListRes);

  const [selectedTask, setSelectedTask] = useState({} as TaskDataList);
  useEffect(() => {
    if (getTaskListRes?.getDoctorTaskListRes?.taskDataList !== undefined) {
      if (getTaskListRes?.getDoctorTaskListRes?.taskDataList !== null) {
        if (getTaskListRes?.getDoctorTaskListRes?.taskDataList.length > 0) {
          setSelectedTask(
            getTaskListRes?.getDoctorTaskListRes?.taskDataList[Number(index)]
          );
        } else setSelectedTask({} as TaskDataList);
      } else setSelectedTask({} as TaskDataList);
    } else setSelectedTask({} as TaskDataList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTaskListRes]);

  useEffect(() => {
    dispatch(getTaskListList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drAppointmentDecisionRes = useSelector(
    (state: AppState) => state.drAppointmentDecisionRes
  );
  const [drAppointmentDecisionMsg, setDrAppointmentDecisionMsg] = useState("");
  const [drAppointmentDecisionMsgType, setDrAppointmentDecisionMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (drAppointmentDecisionRes?.successMsg !== "") {
      setDrAppointmentDecisionMsgType("success");
      setDrAppointmentDecisionMsg(drAppointmentDecisionRes?.successMsg);
    }
    if (drAppointmentDecisionRes?.errorMsg !== "") {
      setDrAppointmentDecisionMsgType("error");
      setDrAppointmentDecisionMsg(drAppointmentDecisionRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drAppointmentDecisionRes]);

  const closeModifyDrAppointmentDecision = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(clearDrAppointmentDecisionAPIRes());
    }
  };

  return (
    <PageLayout>
      <MWPageTitle
        title={t("docdashboardpage.docdashboard", { ns: ["home"] })}
        backButton={true}
      />
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography variant="h5">
                  {t("userprofilepage.addagreement", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() =>
                        history.push(
                          `/appointmentdetails/${patientId}/${selectedTask.caseId}/${index}?&tab=doctorpatientinteraction`
                        )
                      }
                      sx={{ textTransform: "none" }}
                    >
                      {t("docdashboardpage.viewCaseDetails", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        dispatch(
                          drAppointmentDecision({
                            caseId: caseId,
                            requestStatus: 1,
                          } as DoctorAppointmentDecisionBody)
                        )
                      }
                      disabled={drAppointmentDecisionRes?.loading}
                      sx={{ textTransform: "none" }}
                    >
                      {
                        t("docdashboardpage.acceptAppointment", {
                          ns: ["home"],
                        })!
                      }
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        dispatch(
                          drAppointmentDecision({
                            caseId: caseId,
                            requestStatus: 1,
                          } as DoctorAppointmentDecisionBody)
                        )
                      }
                      disabled={drAppointmentDecisionRes?.loading}
                      sx={{ textTransform: "none" }}
                    >
                      {
                        t("docdashboardpage.rejectAppointment", {
                          ns: ["home"],
                        })!
                      }
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {selectedTask?.patientName !== undefined
                    ? selectedTask?.patientName !== null
                      ? selectedTask?.patientName !== ""
                        ? selectedTask?.patientName
                        : null
                      : null
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="body1">
                  {selectedTask?.title !== undefined
                    ? selectedTask?.title !== null
                      ? selectedTask?.title !== ""
                        ? selectedTask?.title
                        : null
                      : null
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {t("docdashboardpage.secondopinion", { ns: ["home"] })!}:
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="body1">
                  {selectedTask?.whyWant2ndOpinion !== undefined
                    ? selectedTask?.whyWant2ndOpinion !== null
                      ? selectedTask?.whyWant2ndOpinion !== ""
                        ? selectedTask?.whyWant2ndOpinion
                        : null
                      : null
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {
                    t("docdashboardpage.secondOpinionReasons", {
                      ns: ["home"],
                    })!
                  }
                  :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="body1">
                  {selectedTask?.secondOpinionReasons !== undefined
                    ? selectedTask?.secondOpinionReasons !== null
                      ? selectedTask?.secondOpinionReasons !== ""
                        ? selectedTask?.secondOpinionReasons
                        : null
                      : null
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {
                    t("docdashboardpage.constype", {
                      ns: ["home"],
                    })!
                  }
                  :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="body1">
                  {selectedTask?.consultationType !== undefined
                    ? selectedTask?.consultationType !== null
                      ? selectedTask?.consultationType !== ""
                        ? selectedTask?.consultationType
                        : null
                      : null
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {
                    t("docdashboardpage.note", {
                      ns: ["home"],
                    })!
                  }
                  :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="body1">
                  {selectedTask?.note !== undefined
                    ? selectedTask?.note !== null
                      ? selectedTask?.note !== ""
                        ? selectedTask?.note
                        : null
                      : null
                    : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {
                    t("appointmentpage.createdate", {
                      ns: ["home"],
                    })!
                  }
                  :
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography variant="body1">
                  {selectedTask?.created_date !== undefined
                    ? selectedTask?.created_date !== null
                      ? selectedTask?.created_date !== ""
                        ? dayjs(selectedTask?.created_date).format("YYYY-MM-DD")
                        : null
                      : null
                    : null}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
      <MWSnackbar
        msg={drAppointmentDecisionMsg}
        type={drAppointmentDecisionMsgType}
        alertClose={closeModifyDrAppointmentDecision}
      />
    </PageLayout>
  );
}
