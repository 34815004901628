import {
  ADD_DR_AWARD_FAIL,
  ADD_DR_AWARD_LOADING,
  ADD_DR_AWARD_SUCCESS,
  ADD_DR_AWARD_API_MSG,
  ADD_DR_AWARD_API_RES,
  AddDrAwardActionTypes,
} from "./ActionTypes";
import { AddAwardRes } from "./Model";

export const addDrAwardLoadingAction = (
  loading: boolean
): AddDrAwardActionTypes => {
  return {
    type: ADD_DR_AWARD_LOADING,
    loading: loading,
  };
};

export const addDrAwardSuccessAction = (
  addDrAwardResponse: AddAwardRes,
  successMsg: string
): AddDrAwardActionTypes => {
  return {
    type: ADD_DR_AWARD_SUCCESS,
    payload: addDrAwardResponse,
    successMsg: successMsg,
  };
};

export const addDrAwardErrorAction = (
  addDrAwardResponse: AddAwardRes,
  errMsg: string,
  status: number
): AddDrAwardActionTypes => {
  return {
    type: ADD_DR_AWARD_FAIL,
    payload: addDrAwardResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDrAwardAPIMsgAction = (
  addDrAwardResponse: AddAwardRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrAwardActionTypes => {
  return {
    type: ADD_DR_AWARD_API_MSG,
    payload: addDrAwardResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDrAwardAPIResClearAction = (
  addDrAwardResponse: AddAwardRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrAwardActionTypes => {
  return {
    type: ADD_DR_AWARD_API_RES,
    payload: addDrAwardResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
