import {
  ADD_DR_PROFESSIONAL_MEMBERSHIP_FAIL,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_LOADING,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_API_MSG,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_API_RES,
  AddDrProfessionalMembershipActionTypes,
} from "./ActionTypes";
import { AddProfessionalMembershipRes } from "./Model";

export const addDrProfessionalMembershipLoadingAction = (
  loading: boolean
): AddDrProfessionalMembershipActionTypes => {
  return {
    type: ADD_DR_PROFESSIONAL_MEMBERSHIP_LOADING,
    loading: loading,
  };
};

export const addDrProfessionalMembershipSuccessAction = (
  addDrProfessionalMembershipResponse: AddProfessionalMembershipRes,
  successMsg: string
): AddDrProfessionalMembershipActionTypes => {
  return {
    type: ADD_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS,
    payload: addDrProfessionalMembershipResponse,
    successMsg: successMsg,
  };
};

export const addDrProfessionalMembershipErrorAction = (
  addDrProfessionalMembershipResponse: AddProfessionalMembershipRes,
  errMsg: string,
  status: number
): AddDrProfessionalMembershipActionTypes => {
  return {
    type: ADD_DR_PROFESSIONAL_MEMBERSHIP_FAIL,
    payload: addDrProfessionalMembershipResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDrProfessionalMembershipAPIMsgAction = (
  addDrProfessionalMembershipResponse: AddProfessionalMembershipRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrProfessionalMembershipActionTypes => {
  return {
    type: ADD_DR_PROFESSIONAL_MEMBERSHIP_API_MSG,
    payload: addDrProfessionalMembershipResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDrProfessionalMembershipAPIResClearAction = (
  addDrProfessionalMembershipResponse: AddProfessionalMembershipRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrProfessionalMembershipActionTypes => {
  return {
    type: ADD_DR_PROFESSIONAL_MEMBERSHIP_API_RES,
    payload: addDrProfessionalMembershipResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
