import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { t } from "i18next";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { ModifiedAwardList } from "./Model";
import DocumentViewer from "components/DocumentViewer";
import { AwardList } from "redux/UserProfile/Award/GetAwardList/Model";
import { getAwardList } from "redux/UserProfile/Award/GetAwardList/API";
import UpdateDoctorAwardDetails from "./UpdateDoctorAwardDetails";
import { getAwardTypeList } from "redux/UserProfile/Award/AwardTypeList/API";
import MWExceptionList from "components/MWExceptionList";
export interface Props {
  drAwardLoading: boolean | any;
}
export default function Awards({ drAwardLoading }: Props) {
  const [editItem, setEditItem] = useState({} as ModifiedAwardList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const awardTableColumns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: "Document",
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setViewEducationDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    { field: "awardType", headerName: "Award Type", minWidth: 200 },
    { field: "name", headerName: "Award Name", minWidth: 200, flex: 1 },
    {
      field: "presentDate",
      headerName: "Present Date",
      minWidth: 200,
    },
    {
      field: "id",
      headerName: "Edit",
      minWidth: 200,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setAddEducationDetailsDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("userprofilepage.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  const [awardList, setAwardList] = useState([] as ModifiedAwardList[]);

  // Call award list store
  const getAwardListRes = useSelector(
    (state: AppState) => state.getAwardListRes
  );

  useEffect(() => {
    drAwardLoading(getAwardListRes?.loading);
    if (getAwardListRes?.getDoctorAwardListRes?.awardList !== undefined) {
      let response = getAwardListRes?.getDoctorAwardListRes?.awardList.map(
        (item: AwardList) =>
          ({
            id: item?.id,
            supportingDocument:
              item?.supportingDocuments[0]?.userFile?.document,
            name: item?.description,
            awardType:
              item?.type !== undefined
                ? item?.type !== null
                  ? item?.type?.label
                  : ""
                : "",
            awardTypeVal:
              item?.type !== undefined
                ? item?.type !== null
                  ? item?.type?.value
                  : null
                : null,
            presentDate:
              item?.fromDate !== null
                ? dayjs(item?.fromDate).format("DD-MM-YYYY")
                : "Not Set",
          } as ModifiedAwardList)
      );

      setAwardList(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAwardListRes]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAwardTypeList());
    dispatch(getAwardList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addEducationDetailsDialog, setAddEducationDetailsDialog] =
    useState(false);
  const updateAddEducationDetailsDialogValFromChild = (passedVal: boolean) => {
    setAddEducationDetailsDialog(passedVal);
  };

  const [viewEducationDocumentDialog, setViewEducationDocumentDialog] =
    useState(false);
  const updateViewEducationDocumentDialogValFromChild = (
    passedVal: boolean
  ) => {
    setViewEducationDocumentDialog(passedVal);
  };

  const actualPageMarkup = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.awarddetail", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setAddEducationDetailsDialog(true)}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  {t("userprofilepage.addawarddetail", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {awardList && awardList.length ? (
            <DataGrid
              rows={awardList}
              columns={awardTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );
  return (
    <Box>
      {actualPageMarkup}
      {addEducationDetailsDialog ? (
        <UpdateDoctorAwardDetails
          dialogEnable={addEducationDetailsDialog}
          dialogUpdateState={updateAddEducationDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewEducationDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewEducationDocumentDialog}
          dialogUpdateState={updateViewEducationDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
