import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { EditDrLicenseActionTypes } from "./ActionTypes";
import {
  EditProfessionalLicenseBody,
  EditProfessionalLicenseRes,
} from "./Model";
import {
  editDrLicenseAPIResClearAction,
  editDrLicenseErrorAction,
  editDrLicenseLoadingAction,
  editDrLicenseSuccessAction,
  editDrLicenseAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EditProfessionalLicenseRes;
export const editDrLicense = (payload: EditProfessionalLicenseBody) => {
  return function (dispatch: Dispatch<EditDrLicenseActionTypes>) {
    dispatch(editDrLicenseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/doctorlicense/edit_doctor_license`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editDrLicenseLoadingAction(false));
        dispatch(
          editDrLicenseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. license has been edited successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editDrLicenseLoadingAction(false));
        dispatch(
          editDrLicenseErrorAction(
            {} as EditProfessionalLicenseRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDrLicenseAPIResMsg = () => {
  return function (dispatch: Dispatch<EditDrLicenseActionTypes>) {
    dispatch(
      editDrLicenseAPIMsgAction(apiRes as EditProfessionalLicenseRes, "", "", 0)
    );
  };
};

export const clearEditDrLicenseAPIRes = () => {
  return function (dispatch: Dispatch<EditDrLicenseActionTypes>) {
    dispatch(
      editDrLicenseAPIResClearAction(
        {} as EditProfessionalLicenseRes,
        "",
        "",
        0
      )
    );
  };
};
