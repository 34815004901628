import {
  DELETE_SCHEDULE_FAIL,
  DELETE_SCHEDULE_LOADING,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_UPDATE_API_MSG,
  DELETE_SCHEDULE_UPDATE_API_RES,
  DeletePatientDataByIdActionTypes,
} from "./ActionTypes";
import { DeletePatientDataByIdRes } from "./Model";

export const deletePatientDataByIdLoadingAction = (
  loading: boolean
): DeletePatientDataByIdActionTypes => {
  return {
    type: DELETE_SCHEDULE_LOADING,
    loading: loading,
  };
};

export const deletePatientDataByIdSuccessAction = (
  deletePatientDataByIdResponse: DeletePatientDataByIdRes,
  successMsg: string
): DeletePatientDataByIdActionTypes => {
  return {
    type: DELETE_SCHEDULE_SUCCESS,
    payload: deletePatientDataByIdResponse,
    successMsg: successMsg,
  };
};

export const deletePatientDataByIdErrorAction = (
  deletePatientDataByIdResponse: DeletePatientDataByIdRes,
  errMsg: string,
  status: number
): DeletePatientDataByIdActionTypes => {
  return {
    type: DELETE_SCHEDULE_FAIL,
    payload: deletePatientDataByIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const deletePatientDataByIdUpdateAPIMsgAction = (
  deletePatientDataByIdResponse: DeletePatientDataByIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): DeletePatientDataByIdActionTypes => {
  return {
    type: DELETE_SCHEDULE_UPDATE_API_MSG,
    payload: deletePatientDataByIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const deletePatientDataByIdAPIResClearAction = (
  deletePatientDataByIdResponse: DeletePatientDataByIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): DeletePatientDataByIdActionTypes => {
  return {
    type: DELETE_SCHEDULE_UPDATE_API_RES,
    payload: deletePatientDataByIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
