import {
  DrAppointmentDecisionActionTypes,
  SET_TELE_HEALTH_CREDENTIALS_FAIL,
  SET_TELE_HEALTH_CREDENTIALS_LOADING,
  SET_TELE_HEALTH_CREDENTIALS_SUCCESS,
  DrAppointmentDecisionState,
  SET_TELE_HEALTH_CREDENTIALS_API_MSG,
  SET_TELE_HEALTH_CREDENTIALS_API_RES,
} from "./ActionTypes";
import { SetTeleHealthCredentialRes } from "./Model";

const initialStateEditPosts: DrAppointmentDecisionState = {
  loading: false,
  setDrTeleHealthCredentialsRes: {} as SetTeleHealthCredentialRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const setDrTeleHealthCredentialsReducer = (
  state = initialStateEditPosts,
  action: DrAppointmentDecisionActionTypes
): DrAppointmentDecisionState => {
  switch (action.type) {
    case SET_TELE_HEALTH_CREDENTIALS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_TELE_HEALTH_CREDENTIALS_SUCCESS:
      return {
        ...state,
        setDrTeleHealthCredentialsRes: action.payload,
        successMsg: action.successMsg,
      };
    case SET_TELE_HEALTH_CREDENTIALS_FAIL:
      return {
        ...state,
        setDrTeleHealthCredentialsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SET_TELE_HEALTH_CREDENTIALS_API_MSG:
      return {
        ...state,
        setDrTeleHealthCredentialsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SET_TELE_HEALTH_CREDENTIALS_API_RES:
      return {
        ...state,
        setDrTeleHealthCredentialsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
