import { GetDoctorCaseListRes } from "./Model";

export const GET_DOCTOR_CASE_LIST_LOADING =
  "GET_DOCTOR_CASE_LIST_LOADING";
export const GET_DOCTOR_CASE_LIST_SUCCESS =
  "GET_DOCTOR_CASE_LIST_SUCCESS";
export const GET_DOCTOR_CASE_LIST_FAIL =
  "GET_DOCTOR_CASE_LIST_FAIL";
export const GET_DOCTOR_CASE_LIST_DATA =
  "GET_DOCTOR_CASE_LIST_DATA";

export interface DoctorCaseList {
  getDoctorCaseListRes: GetDoctorCaseListRes;
  loading: boolean;
  errRes: string;
}
export interface GetDoctorCaseListLoading {
  type: typeof GET_DOCTOR_CASE_LIST_LOADING;
}
export interface GetDoctorCaseListSuccess {
  type: typeof GET_DOCTOR_CASE_LIST_SUCCESS;
  payload: GetDoctorCaseListRes;
  errRes: string;
}
export interface GetDoctorCaseListFail {
  type: typeof GET_DOCTOR_CASE_LIST_FAIL;
  payload: GetDoctorCaseListRes;
  errRes: string;
}
interface GetDoctorCaseListAction {
  type: typeof GET_DOCTOR_CASE_LIST_DATA;
  payload: GetDoctorCaseListRes;
  errRes: string;
}

export type GetDoctorCaseListActionTypes =
  | GetDoctorCaseListAction
  | GetDoctorCaseListLoading
  | GetDoctorCaseListSuccess
  | GetDoctorCaseListFail;
