import {
  GetDoctorCoverLetterActionTypes,
  GET_DOCTOR_COVER_LETTER_FAIL,
  GET_DOCTOR_COVER_LETTER_LOADING,
  GET_DOCTOR_COVER_LETTER_SUCCESS,
  GetDoctorCoverLetterState,
  GET_DOCTOR_COVER_LETTER_API_MSG,
  GET_DOCTOR_COVER_LETTER_API_RES,
} from "./ActionTypes";
import { GetCoverLetterRes } from "./Model";

const initialStateUpdatePosts: GetDoctorCoverLetterState = {
  loading: false,
  getDoctorCoverLetterRes: {} as GetCoverLetterRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getCoverLetterReducer = (
  state = initialStateUpdatePosts,
  action: GetDoctorCoverLetterActionTypes
): GetDoctorCoverLetterState => {
  switch (action.type) {
    case GET_DOCTOR_COVER_LETTER_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_COVER_LETTER_SUCCESS:
      return {
        ...state,
        getDoctorCoverLetterRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DOCTOR_COVER_LETTER_FAIL:
      return {
        ...state,
        getDoctorCoverLetterRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_COVER_LETTER_API_MSG:
      return {
        ...state,
        getDoctorCoverLetterRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_COVER_LETTER_API_RES:
      return {
        ...state,
        getDoctorCoverLetterRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
