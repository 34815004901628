import {
    DeleteSummaryListActionTypes,
    DELETE_SUMMARYLIST_SUBMIT_LIST_FAIL,
    DELETE_SUMMARYLIST_SUBMIT_LIST_LOADING,
    DELETE_SUMMARYLIST_SUBMIT_LIST_SUCCESS,
    DeleteSummaryListState,
    DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG,
    DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES,
  } from "./ActionTypes";
  import { DeleteSummaryListRes } from "./Model";
  
  const initialStateGetPosts: DeleteSummaryListState = {
    loading: false,
    deleteSummaryListResponse: {} as DeleteSummaryListRes,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const deleteDoctorSummaryListReducer = (
    state = initialStateGetPosts,
    action: DeleteSummaryListActionTypes
  ): DeleteSummaryListState => {
    switch (action.type) {
      case DELETE_SUMMARYLIST_SUBMIT_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case DELETE_SUMMARYLIST_SUBMIT_LIST_SUCCESS:
        return {
          ...state,
          deleteSummaryListResponse: action.payload,
          successMsg: action.successMsg,
        };
      case DELETE_SUMMARYLIST_SUBMIT_LIST_FAIL:
        return {
          ...state,
          deleteSummaryListResponse: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG:
        return {
          ...state,
          deleteSummaryListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES:
        return {
          ...state,
          deleteSummaryListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };
  