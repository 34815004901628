import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedWorkExperienceList } from "../Model";
import MWUploader from "components/MWUploader";
import { AppState } from "redux/store/Store";
import { DoctorSpecialtyList } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/GetSpecialtyList/Model";
import dayjs, { Dayjs } from "dayjs";
import { AddWorkExperienceBody } from "redux/UserProfile/ProfessionalDetails/WorkExperience/AddWorkExperience/Model";
import {
  addDrWorkExperience,
  clearAddDrWorkExperienceAPIRes,
} from "redux/UserProfile/ProfessionalDetails/WorkExperience/AddWorkExperience/API";
import { getWorkExperienceList } from "redux/UserProfile/ProfessionalDetails/WorkExperience/GetWorkExperienceList/API";
import { EditDrWorkExperienceBody } from "redux/UserProfile/ProfessionalDetails/WorkExperience/EditWorkExperience/Model";
import {
  editDrWorkExperience,
  clearEditDrWorkExperienceAPIRes,
} from "redux/UserProfile/ProfessionalDetails/WorkExperience/EditWorkExperience/API";
import MWLoader from "components/MWLoader";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedWorkExperienceList | any;
  selectedItemUpdateState: any;
}
export default function UpdateWorkExperience({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setInstitute("");
    setSelectedSpecialty(null);
    setImageId("");
    dispatch(clearImageUploadAPIRes());
    setSelectedDate(dayjs(new Date()));
    setSelectedToDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    dispatch(clearAddDrWorkExperienceAPIRes());
    setAddDrWorkExperienceMsg("");
    dispatch(clearEditDrWorkExperienceAPIRes());
    setEditWorkExperienceMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const specialtyListRes = useSelector(
    (state: AppState) => state.specialtyListRes
  );

  const [degreeList, setSpecialtyList] = useState([] as DoctorSpecialtyList[]);
  useEffect(() => {
    if (specialtyListRes?.specialtyListRes?.doctorSpecialtyList !== undefined) {
      if (specialtyListRes?.specialtyListRes?.doctorSpecialtyList.length > 0) {
        setSpecialtyList(
          specialtyListRes?.specialtyListRes?.doctorSpecialtyList
        );
      } else setSpecialtyList([] as DoctorSpecialtyList[]);
    } else setSpecialtyList([] as DoctorSpecialtyList[]);
  }, [specialtyListRes]);

  const [institute, setInstitute] = useState("");
  const [instituteError, setSpecialtyNumberError] = useState("");
  const instituteChange = (event: string) => {
    setInstitute(event);
  };

  const [selectedSpecialty, setSelectedSpecialty] =
    useState<DoctorSpecialtyList | null>(null);
  const selectedSpecialtyChange = (
    _event: any,
    passedVal: DoctorSpecialtyList | any
  ) => {
    setSelectedSpecialty(passedVal);
  };
  const [selectedSpecialtyError, setSelectedSpecialtyError] = useState("");

  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // to Date
  const [toDate, setSelectedToDate] = React.useState<Dayjs | null>(null);
  const handleToDateChange = (date: Dayjs | null) => {
    setSelectedToDate(date);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  // Call Specialty List store
  const getSpecialtyListRes = useSelector(
    (state: AppState) => state.specialtyListRes
  );

  useEffect(() => {
    selectedItem?.name !== undefined
      ? setInstitute(selectedItem?.name)
      : setInstitute("");

    selectedItem?.specialistVal !== undefined &&
    getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList !== undefined
      ? selectedItem?.specialistVal !== "" &&
        getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList !== undefined
        ? setSelectedSpecialty(
            getSpecialtyListRes?.specialtyListRes?.doctorSpecialtyList!.find(
              (o) => o.value === selectedItem?.specialistVal
            )!
          )
        : setSelectedSpecialty(null)
      : setSelectedSpecialty(null);

    if (selectedItem?.startDate !== undefined) {
      let year = selectedItem?.startDate.split("-")[2];
      let month = selectedItem?.startDate.split("-")[1];
      let day = selectedItem?.startDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedDate(dayjs(date));
    }
    if (selectedItem?.startDate === undefined) {
      setSelectedDate(dayjs(new Date()));
    }

    if (selectedItem?.endDate !== undefined) {
      let year = selectedItem?.endDate.split("-")[2];
      let month = selectedItem?.endDate.split("-")[1];
      let day = selectedItem?.endDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedToDate(dayjs(date));
    }
    if (selectedItem?.endDate === undefined) {
      setSelectedToDate(null);
    }

    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== ""
        ? setImageInfo(selectedItem?.supportingDocument)
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, getSpecialtyListRes]);

  const addEducationalDetailsBody = {
    institutename: institute,
    specialityId: String(selectedSpecialty?.value),
    fromDate:
      selectedDate !== null
        ? dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    toDate:
      toDate !== null ? dayjs(toDate).utc().format("YYYY-MM-DDTHH:mm:ssZ") : null,
  } as AddWorkExperienceBody;

  const submitEducationalDetails = () => {
    if (selectedSpecialty === null && institute === "") {
      setSelectedSpecialtyError(
        `${t("appointmentpage.specNotEmp", { ns: ["home"] })!}`
      );
      setSpecialtyNumberError(
        `${t("userprofilepage.instNameEror", { ns: ["home"] })!}`
      );
    } else if (selectedSpecialty === null && institute !== "") {
      setSelectedSpecialtyError(
        `${t("appointmentpage.specNotEmp", { ns: ["home"] })!}`
      );
      setSpecialtyNumberError("");
    } else if (selectedSpecialty !== null && institute === "") {
      setSelectedSpecialtyError("");
      setSpecialtyNumberError(
        `${t("userprofilepage.instNameEror", { ns: ["home"] })!}`
      );
    } else {
      setSelectedSpecialtyError("");
      setSpecialtyNumberError("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Education Details
        if (imageId === undefined) {
          dispatch(addDrWorkExperience(addEducationalDetailsBody));
        } else {
          dispatch(
            addDrWorkExperience(
              Object.assign(addEducationalDetailsBody, {
                documentId: imageId !== "" ? imageId : null,
              })
            )
          );
        }
      } else {
        // Edit Dr. Education Details
        if (imageId === undefined) {
          // When user does not upload image on add work experience
          dispatch(
            editDrWorkExperience(
              Object.assign(addEducationalDetailsBody, {
                workexperienceId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? Number(imageId) : null,
              } as EditDrWorkExperienceBody)
            )
          );
        } else {
          dispatch(
            editDrWorkExperience(
              Object.assign(addEducationalDetailsBody, {
                workexperienceId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? Number(imageId) : null,
              } as EditDrWorkExperienceBody)
            )
          );
        }
      }
    }
  };

  const addDrWorkExperienceRes = useSelector(
    (state: AppState) => state.addDrWorkExperienceRes
  );
  const [addDrWorkExperienceMsg, setAddDrWorkExperienceMsg] = useState("");
  const [addDrWorkExperienceMsgType, setAddDrWorkExperienceMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addDrWorkExperienceRes?.successMsg !== "") {
      setAddDrWorkExperienceMsgType("success");
      setAddDrWorkExperienceMsg(addDrWorkExperienceRes?.successMsg);
      dispatch(getWorkExperienceList());
      closeDialog();
    }
    if (addDrWorkExperienceRes?.errorMsg !== "") {
      setAddDrWorkExperienceMsgType("error");
      setAddDrWorkExperienceMsg(addDrWorkExperienceRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDrWorkExperienceRes]);

  const editEducationDetailsRes = useSelector(
    (state: AppState) => state.editWorkExperienceRes
  );
  const [editEducationDetailsMsg, setEditWorkExperienceMsg] = useState("");
  const [editEducationDetailsMsgType, setEditWorkExperienceMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (editEducationDetailsRes?.successMsg !== "") {
      setEditWorkExperienceMsgType("success");
      setEditWorkExperienceMsg(editEducationDetailsRes?.successMsg);
      dispatch(getWorkExperienceList());
      closeDialog();
    }
    if (editEducationDetailsRes?.errorMsg !== "") {
      setEditWorkExperienceMsgType("error");
      setEditWorkExperienceMsg(editEducationDetailsRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEducationDetailsRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addDrWorkExperienceRes?.loading || editEducationDetailsRes?.loading ? (
        <MWLoader />
      ) : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {
                  t("userprofilepage.editworkexp", {
                    ns: ["home"],
                  })!
                }
              </Typography>
            ) : (
              <Typography variant="h5">
                {
                  t("userprofilepage.addworkexp", {
                    ns: ["home"],
                  })!
                }
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography style={{ marginBottom: "5px" }}>
                {t("appointmentpage.desc", { ns: ["home"] })!}
              </Typography>
              <TextField
                value={institute}
                onChange={(e) => instituteChange(e.target.value)}
                label={t("userprofilepage.instname", { ns: ["home"] })}
                type="text"
                placeholder={
                  t("userprofilepage.enterinstname", { ns: ["home"] })!
                }
                id="Institutename"
                fullWidth
                size="small"
              />
              {instituteError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {instituteError}
                </Typography>
              ) : null}
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("appointmentpage.speciality", { ns: ["home"] })!}
              </Typography>
              <Autocomplete
                value={selectedSpecialty}
                id="highest-states"
                options={degreeList}
                onChange={(event: any, newValue: any) => {
                  selectedSpecialtyChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      t("appointmentpage.selSpec", { ns: ["home"] })!
                    }
                  />
                )}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value) =>
                  option.value === value.value
                }
              />
              {selectedSpecialtyError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {selectedSpecialtyError}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="work-experience-supporting-docs"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("userprofilepage.startdate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography>
                {t("appointmentpage.enddate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={toDate}
                  onChange={handleToDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                  // maxDate={isEdit ? dayjs(new Date()) : undefined}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Stack>
        {addDrWorkExperienceMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrWorkExperienceMsgType}
              onClose={() => {
                dispatch(clearAddDrWorkExperienceAPIRes());
                setAddDrWorkExperienceMsg("");
              }}
            >
              {addDrWorkExperienceMsg}
            </Alert>
          </Box>
        ) : null}
        {editEducationDetailsMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editEducationDetailsMsgType}
              onClose={() => {
                dispatch(clearEditDrWorkExperienceAPIRes());
                setEditWorkExperienceMsg("");
              }}
            >
              {editEducationDetailsMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={
                addDrWorkExperienceRes?.loading ||
                editEducationDetailsRes?.loading
              }
              onClick={() => submitEducationalDetails()}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
