import { GetSpecialtyListRes } from "./Model";
export const GET_DOCTOR_EDUCATION_LIST_LOADING =
  "GET_DOCTOR_EDUCATION_LIST_LOADING";
export const GET_DOCTOR_EDUCATION_LIST_SUCCESS =
  "GET_DOCTOR_EDUCATION_LIST_SUCCESS";
export const GET_DOCTOR_EDUCATION_LIST_FAIL = "GET_DOCTOR_EDUCATION_LIST_FAIL";
export const GET_DOCTOR_EDUCATION_LIST_API_MSG =
  "GET_DOCTOR_EDUCATION_LIST_API_MSG";
export const GET_DOCTOR_EDUCATION_LIST_API_RES =
  "GET_DOCTOR_EDUCATION_LIST_API_RES";

export interface GetSpecialtyListState {
  specialtyListRes: GetSpecialtyListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetSpecialtyListLoading {
  type: typeof GET_DOCTOR_EDUCATION_LIST_LOADING;
  loading: boolean;
}
export interface GetSpecialtyListSuccess {
  type: typeof GET_DOCTOR_EDUCATION_LIST_SUCCESS;
  payload: GetSpecialtyListRes;
  successMsg: string;
}
export interface GetSpecialtyListFail {
  type: typeof GET_DOCTOR_EDUCATION_LIST_FAIL;
  payload: GetSpecialtyListRes;
  errorMsg: string;
  status: number;
}
export interface GetSpecialtyListAPIMsg {
  type: typeof GET_DOCTOR_EDUCATION_LIST_API_MSG;
  payload: GetSpecialtyListRes;
  errorMsg: string;
  status: number;
}
export interface GetSpecialtyListAPIRes {
  type: typeof GET_DOCTOR_EDUCATION_LIST_API_RES;
  payload: GetSpecialtyListRes;
  errorMsg: string;
  status: number;
}

export type GetSpecialtyListActionTypes =
  | GetSpecialtyListLoading
  | GetSpecialtyListSuccess
  | GetSpecialtyListFail
  | GetSpecialtyListAPIMsg
  | GetSpecialtyListAPIRes;
