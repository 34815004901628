import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDrIdProofListActionTypes } from "./ActionTypes";
import { GetDrIdProofListRes } from "./Model";
import {
  getDrIdProofListAPIResClearAction,
  getDrIdProofListErrorAction,
  getDrIdProofListLoadingAction,
  getDrIdProofListSuccessAction,
  getDrIdProofListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetDrIdProofListRes;
export const getDrIdProofList = () => {
  return function (dispatch: Dispatch<GetDrIdProofListActionTypes>) {
    dispatch(getDrIdProofListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL + `/doctorapp/api/doctoridproof/get_id_details_by_doctor`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDrIdProofListLoadingAction(false));
        dispatch(
          getDrIdProofListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. identity list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDrIdProofListLoadingAction(false));
        dispatch(
          getDrIdProofListErrorAction(
            {} as GetDrIdProofListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDrIdProofListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDrIdProofListActionTypes>) {
    dispatch(
      getDrIdProofListAPIMsgAction(apiRes as GetDrIdProofListRes, "", "", 0)
    );
  };
};

export const clearGetDrIdProofListAPIRes = () => {
  return function (dispatch: Dispatch<GetDrIdProofListActionTypes>) {
    dispatch(
      getDrIdProofListAPIResClearAction({} as GetDrIdProofListRes, "", "", 0)
    );
  };
};
