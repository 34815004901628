import React from "react";
import { t } from "i18next";
import { Alert, Typography } from "@mui/material";
import PageLayout from "components/PageLayout";
import ExistingMeetingList from "./FilePreparation/DoctorPatientInteraction/ExistingMeetingList";
import QuestionAnswer from "./FilePreparation/DoctorPatientInteraction/QuestionAnswer";

export default function DoctorPatientInteraction() {
  return (
    <PageLayout>
      <Alert severity="info" variant="outlined">
        <Typography>
          {t("docdashboardpage.banner", { ns: ["home"] })!}
        </Typography>
      </Alert>
      <ExistingMeetingList />
      <QuestionAnswer />
    </PageLayout>
  );
}
