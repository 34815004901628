import { AlertColor, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GetPatientDataDetailsBody } from "../../../redux/FilePreparation/Questionnaire/Model";
import { getQuestionnaireList } from "../../../redux/FilePreparation/Questionnaire/API";
import { useDispatch, useSelector } from "react-redux";
import SummaryList from "./SummaryList";
import Questionnaire from "./Questionnaire";
import DataList from "./DataList";
import { getPatientDataList } from "../../../redux/FilePreparation/GetPatientDataList/API";
import PersonalHistory from "./PersonalHistory";
import { AppState } from "../../../redux/store/Store";
import MWSnackbar from "../../../components/MWSnackbar";
import {
  clearAddPatientDataAPIRes,
  updateAddPatientDataAPIResMsg,
} from "../../../redux/FilePreparation/AddPatientDataList/API";
import {
  updateDeletePatientDataByIdAPIResMsg,
  clearDeletePatientDataByIdAPIRes,
} from "../../../redux/FilePreparation/DeletePatientDataById/API";
import { getDoctorSummaryList } from "redux/FilePreparation/doctorSummary/getSummaryList/ApiCall";
import { GetSummeryListSubmitBody } from "redux/FilePreparation/doctorSummary/getSummaryList/Model";
import {
  clearAddSummaryListAPIRes,
  updateAddSummaryListAPIResMsg,
} from "redux/FilePreparation/doctorSummary/addSummaryList/ApiCall";
import {
  clearDeleteSummaryListAPIRes,
  updateDeleteSummaryListAPIResMsg,
} from "redux/FilePreparation/doctorSummary/deleteSummaryList/ApiCall";
import { t } from "i18next";
interface Props {
  type?: string;
  label?: string;
}
export default function FileData({ type, label }: Props) {
  const location = useLocation();
  useEffect(() => {
    // setCurrentPath(location.pathname);
  }, [location]);
  const { caseid } = useParams() as {
    caseid: string;
  };

  const getPatientDataDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetPatientDataDetailsBody;
  const getDoctorSummaryDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetSummeryListSubmitBody;

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(getQuestionnaireList(getPatientDataDetails));
    }, 2000);
    setTimeout(() => {
      dispatch(getPatientDataList(getPatientDataDetails));
    }, 2000);
    setTimeout(() => {
      dispatch(getDoctorSummaryList(getDoctorSummaryDetails));
    }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  const [addPatientDataSuccessMsg, setAddPatientDataSuccessMsg] = useState("");
  const [addPatientDataMsgType, setAddPatientDataMsgType] =
    useState<AlertColor>("success");
  const addPatientDataListValRes = useSelector(
    (state: AppState) => state.addPatientDataListRes
  );
  useEffect(() => {
    if (addPatientDataListValRes?.successMsg !== "") {
      setAddPatientDataSuccessMsg(addPatientDataListValRes?.successMsg);
      setAddPatientDataMsgType("success");
    }
  }, [addPatientDataListValRes]);

  // for add summary
  const [addPatientSummarySuccessMsg, setAddPatientSummarySuccessMsg] =
    useState("");
  const [addPatientSummaryMsgType, setAddPatientSummaryMsgType] =
    useState<AlertColor>("success");
  const addPatientSummaryListValRes = useSelector(
    (state: AppState) => state.addDoctorSummaryList
  );
  useEffect(() => {
    if (addPatientSummaryListValRes?.successMsg !== "") {
      setAddPatientSummarySuccessMsg(addPatientSummaryListValRes?.successMsg);
      setAddPatientSummaryMsgType("success");
    }
  }, [addPatientSummaryListValRes]);

  const closeAddPatientDataMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateAddPatientDataAPIResMsg());
      dispatch(clearAddPatientDataAPIRes());
    }
  };

  const closeAddPatientSummaryMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateAddSummaryListAPIResMsg());
      dispatch(clearAddSummaryListAPIRes());
    }
  };
  const deletePatientDataListRes = useSelector(
    (state: AppState) => state.deletePatientDataByIdRes
  );
  const [deletePatientDataSuccessMsg, setDeletePatientDataSuccessMsg] =
    useState("");
  const [deletePatientDataMsgType, setDeletePatientDataMsgType] =
    useState<AlertColor>("success");
  useEffect(() => {
    if (deletePatientDataListRes?.successMsg !== "") {
      setDeletePatientDataSuccessMsg(deletePatientDataListRes?.successMsg);
      setDeletePatientDataMsgType("success");
    }
  }, [deletePatientDataListRes]);

  // For summary
  const deletePatientSummaryListRes = useSelector(
    (state: AppState) => state.deleteDoctorSummaryList
  );
  const [deletePatientSummarySuccessMsg, setDeletePatientSummarySuccessMsg] =
    useState("");
  const [deletePatientSummaryMsgType, setDeletePatientSummaryMsgType] =
    useState<AlertColor>("success");
  useEffect(() => {
    if (deletePatientSummaryListRes?.successMsg !== "") {
      setDeletePatientSummarySuccessMsg(
        deletePatientSummaryListRes?.successMsg
      );
      setDeletePatientSummaryMsgType("success");
    }
  }, [deletePatientSummaryListRes]);

  const closeDeletePatientDataMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateDeletePatientDataByIdAPIResMsg());
      dispatch(clearDeletePatientDataByIdAPIRes());
    }
  };

  const closeDeletePatientSummaryMsg = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(updateDeleteSummaryListAPIResMsg());
      dispatch(clearDeleteSummaryListAPIRes());
    }
  };
  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Typography variant="h5" fontWeight="bold">
          {location.pathname.includes("/preview")
            ? label
            : `${t(`filePreparationTab.${label}`, { ns: ["home"] })}`}
        </Typography>
        <SummaryList label={label} type={type} />
        <Questionnaire type={type} />
        {type === "personalandSocialHistory" ? <PersonalHistory /> : null}
        <DataList label={label} type={type} />
      </Stack>
      {addPatientDataSuccessMsg !== "" ? (
        <MWSnackbar
          msg={addPatientDataSuccessMsg}
          type={addPatientDataMsgType}
          alertClose={closeAddPatientDataMsg}
        />
      ) : null}
      {deletePatientDataSuccessMsg !== "" ? (
        <MWSnackbar
          msg={deletePatientDataSuccessMsg}
          type={deletePatientDataMsgType}
          alertClose={closeDeletePatientDataMsg}
        />
      ) : null}

      {addPatientSummarySuccessMsg !== "" ? (
        <MWSnackbar
          msg={addPatientSummarySuccessMsg}
          type={addPatientSummaryMsgType}
          alertClose={closeAddPatientSummaryMsg}
        />
      ) : null}

      {deletePatientSummarySuccessMsg !== "" ? (
        <MWSnackbar
          msg={deletePatientSummarySuccessMsg}
          type={deletePatientSummaryMsgType}
          alertClose={closeDeletePatientSummaryMsg}
        />
      ) : null}
    </Box>
  );
}
