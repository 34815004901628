import { EditPatientImportConfigValueRes } from "./Model";
export const EDIT_PATIENT_IMPORT_CONFIG_DATA_LOADING =
  "EDIT_PATIENT_IMPORT_CONFIG_DATA_LOADING";
export const EDIT_PATIENT_IMPORT_CONFIG_DATA_SUCCESS =
  "EDIT_PATIENT_IMPORT_CONFIG_DATA_SUCCESS";
export const EDIT_PATIENT_IMPORT_CONFIG_DATA_FAIL =
  "EDIT_PATIENT_IMPORT_CONFIG_DATA_FAIL";
export const EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_MSG =
  "EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_MSG";
export const EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_RES =
  "EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_RES";

export interface EditPatientImportConfigDataState {
  editPatientImportConfigDataRes: EditPatientImportConfigValueRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientImportConfigDataLoading {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_DATA_LOADING;
  loading: boolean;
}
export interface EditPatientImportConfigDataSuccess {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_DATA_SUCCESS;
  payload: EditPatientImportConfigValueRes;
  successMsg: string;
}
export interface EditPatientImportConfigDataFail {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_DATA_FAIL;
  payload: EditPatientImportConfigValueRes;
  errorMsg: string;
  status: number;
}
export interface EditPatientImportConfigDataUpdateAPIMsg {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_MSG;
  payload: EditPatientImportConfigValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientImportConfigDataUpdateAPIRes {
  type: typeof EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_RES;
  payload: EditPatientImportConfigValueRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditPatientImportConfigDataActionTypes =
  | EditPatientImportConfigDataLoading
  | EditPatientImportConfigDataSuccess
  | EditPatientImportConfigDataFail
  | EditPatientImportConfigDataUpdateAPIMsg
  | EditPatientImportConfigDataUpdateAPIRes;
