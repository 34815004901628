import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { AddDrWorkExperienceActionTypes } from "./ActionTypes";
import { AddWorkExperienceBody, AddWorkExperienceRes } from "./Model";
import {
  addDrWorkExperienceAPIResClearAction,
  addDrWorkExperienceErrorAction,
  addDrWorkExperienceLoadingAction,
  addDrWorkExperienceSuccessAction,
  addDrWorkExperienceAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddWorkExperienceRes;
export const addDrWorkExperience = (payload: AddWorkExperienceBody) => {
  return function (dispatch: Dispatch<AddDrWorkExperienceActionTypes>) {
    dispatch(addDrWorkExperienceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/add_work_experience`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDrWorkExperienceLoadingAction(false));
        dispatch(
          addDrWorkExperienceSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. Work experience has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDrWorkExperienceLoadingAction(false));
        dispatch(
          addDrWorkExperienceErrorAction(
            {} as AddWorkExperienceRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDrWorkExperienceAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDrWorkExperienceActionTypes>) {
    dispatch(
      addDrWorkExperienceAPIMsgAction(apiRes as AddWorkExperienceRes, "", "", 0)
    );
  };
};

export const clearAddDrWorkExperienceAPIRes = () => {
  return function (dispatch: Dispatch<AddDrWorkExperienceActionTypes>) {
    dispatch(
      addDrWorkExperienceAPIResClearAction(
        {} as AddWorkExperienceRes,
        "",
        "",
        0
      )
    );
  };
};
