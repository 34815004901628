import { GetLegalDetailsByDoctorRes } from "./Model";
export const GET_DOCTOR_LEGAL_DETAILS_LOADING =
  "GET_DOCTOR_LEGAL_DETAILS_LOADING";
export const GET_DOCTOR_LEGAL_DETAILS_SUCCESS =
  "GET_DOCTOR_LEGAL_DETAILS_SUCCESS";
export const GET_DOCTOR_LEGAL_DETAILS_FAIL = "GET_DOCTOR_LEGAL_DETAILS_FAIL";
export const GET_DOCTOR_LEGAL_DETAILS_API_MSG =
  "GET_DOCTOR_LEGAL_DETAILS_API_MSG";
export const GET_DOCTOR_LEGAL_DETAILS_API_RES =
  "GET_DOCTOR_LEGAL_DETAILS_API_RES";

export interface GetDoctorLegalDetailsState {
  getDoctorLegalDetailsRes: GetLegalDetailsByDoctorRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalDetailsLoading {
  type: typeof GET_DOCTOR_LEGAL_DETAILS_LOADING;
  loading: boolean;
}
export interface GetDoctorLegalDetailsSuccess {
  type: typeof GET_DOCTOR_LEGAL_DETAILS_SUCCESS;
  payload: GetLegalDetailsByDoctorRes;
  successMsg: string;
}
export interface GetDoctorLegalDetailsFail {
  type: typeof GET_DOCTOR_LEGAL_DETAILS_FAIL;
  payload: GetLegalDetailsByDoctorRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalDetailsAPIMsg {
  type: typeof GET_DOCTOR_LEGAL_DETAILS_API_MSG;
  payload: GetLegalDetailsByDoctorRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalDetailsAPIRes {
  type: typeof GET_DOCTOR_LEGAL_DETAILS_API_RES;
  payload: GetLegalDetailsByDoctorRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorLegalDetailsActionTypes =
  | GetDoctorLegalDetailsLoading
  | GetDoctorLegalDetailsSuccess
  | GetDoctorLegalDetailsFail
  | GetDoctorLegalDetailsAPIMsg
  | GetDoctorLegalDetailsAPIRes;
