import { SignAgreementRes } from "./Model";
export const SIGN_DOCTOR_AGREEMENT_LOADING = "SIGN_DOCTOR_AGREEMENT_LOADING";
export const SIGN_DOCTOR_AGREEMENT_SUCCESS = "SIGN_DOCTOR_AGREEMENT_SUCCESS";
export const SIGN_DOCTOR_AGREEMENT_FAIL = "SIGN_DOCTOR_AGREEMENT_FAIL";
export const SIGN_DOCTOR_AGREEMENT_API_MSG = "SIGN_DOCTOR_AGREEMENT_API_MSG";
export const SIGN_DOCTOR_AGREEMENT_API_RES = "SIGN_DOCTOR_AGREEMENT_API_RES";

export interface SignDoctorAgreementState {
  signDoctorAgreementRes: SignAgreementRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SignDoctorAgreementLoading {
  type: typeof SIGN_DOCTOR_AGREEMENT_LOADING;
  loading: boolean;
}
export interface SignDoctorAgreementSuccess {
  type: typeof SIGN_DOCTOR_AGREEMENT_SUCCESS;
  payload: SignAgreementRes;
  successMsg: string;
}
export interface SignDoctorAgreementFail {
  type: typeof SIGN_DOCTOR_AGREEMENT_FAIL;
  payload: SignAgreementRes;
  errorMsg: string;
  status: number;
}
export interface SignDoctorAgreementAPIMsg {
  type: typeof SIGN_DOCTOR_AGREEMENT_API_MSG;
  payload: SignAgreementRes;
  errorMsg: string;
  status: number;
}
export interface SignDoctorAgreementAPIRes {
  type: typeof SIGN_DOCTOR_AGREEMENT_API_RES;
  payload: SignAgreementRes;
  errorMsg: string;
  status: number;
}

export type SignDoctorAgreementActionTypes =
  | SignDoctorAgreementLoading
  | SignDoctorAgreementSuccess
  | SignDoctorAgreementFail
  | SignDoctorAgreementAPIMsg
  | SignDoctorAgreementAPIRes;
