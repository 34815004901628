import {
  UPDATE_DOCTOR_EDUCATION_SUMMARY_FAIL,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_LOADING,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_SUCCESS,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_API_MSG,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_API_RES,
  UpdateDoctorEducationSummaryActionTypes,
} from "./ActionTypes";
import { UpdateEducationSummaryRes } from "./Model";

export const updateDoctorEducationSummaryLoadingAction = (
  loading: boolean
): UpdateDoctorEducationSummaryActionTypes => {
  return {
    type: UPDATE_DOCTOR_EDUCATION_SUMMARY_LOADING,
    loading: loading,
  };
};

export const updateDoctorEducationSummarySuccessAction = (
  updateDoctorEducationSummaryResponse: UpdateEducationSummaryRes,
  successMsg: string
): UpdateDoctorEducationSummaryActionTypes => {
  return {
    type: UPDATE_DOCTOR_EDUCATION_SUMMARY_SUCCESS,
    payload: updateDoctorEducationSummaryResponse,
    successMsg: successMsg,
  };
};

export const updateDoctorEducationSummaryErrorAction = (
  updateDoctorEducationSummaryResponse: UpdateEducationSummaryRes,
  errMsg: string,
  status: number
): UpdateDoctorEducationSummaryActionTypes => {
  return {
    type: UPDATE_DOCTOR_EDUCATION_SUMMARY_FAIL,
    payload: updateDoctorEducationSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updateDoctorEducationSummaryAPIMsgAction = (
  updateDoctorEducationSummaryResponse: UpdateEducationSummaryRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDoctorEducationSummaryActionTypes => {
  return {
    type: UPDATE_DOCTOR_EDUCATION_SUMMARY_API_MSG,
    payload: updateDoctorEducationSummaryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const updateDoctorEducationSummaryAPIResClearAction = (
  updateDoctorEducationSummaryResponse: UpdateEducationSummaryRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDoctorEducationSummaryActionTypes => {
  return {
    type: UPDATE_DOCTOR_EDUCATION_SUMMARY_API_RES,
    payload: updateDoctorEducationSummaryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
