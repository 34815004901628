import { GetSummaryListRes } from "./Model";
export const GET_SUMMARYLIST_SUBMIT_LIST_LOADING = "GET_SUMMARYLIST_SUBMIT_LIST_LOADING";
export const GET_SUMMARYLIST_SUBMIT_LIST_SUCCESS = "GET_SUMMARYLIST_SUBMIT_LIST_SUCCESS";
export const GET_SUMMARYLIST_SUBMIT_LIST_FAIL = "GET_SUMMARYLIST_SUBMIT_LIST_FAIL";
export const GET_SUMMARYLIST_SUBMIT_LIST_DATA = "GET_SUMMARYLIST_SUBMIT_LIST_DATA";
export const GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG =
  "GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG";
export const GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES =
  "GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES";

export interface GetSummaryListState {
getSummaryListResponse: GetSummaryListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSummaryListLoading {
  type: typeof GET_SUMMARYLIST_SUBMIT_LIST_LOADING;
  loading: boolean;
}
export interface GetSummaryListSuccess {
  type: typeof GET_SUMMARYLIST_SUBMIT_LIST_SUCCESS;
  payload: GetSummaryListRes;
  successMsg: string;
}
export interface GetSummaryListFail {
  type: typeof GET_SUMMARYLIST_SUBMIT_LIST_FAIL;
  payload: GetSummaryListRes;
  errorMsg: string;
  status: number;
}
export interface GetSummaryListUpdateAPIMsg {
  type: typeof GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG;
  payload: GetSummaryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetSummaryListUpdateAPIRes {
  type: typeof GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES;
  payload: GetSummaryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetSummaryListActionTypes =
  | GetSummaryListLoading
  | GetSummaryListSuccess
  | GetSummaryListFail
  | GetSummaryListUpdateAPIMsg
  | GetSummaryListUpdateAPIRes;
