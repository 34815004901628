import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { EditDoctorProfessionalSummaryActionTypes } from "./ActionTypes";
import {
  EditProfessionalSummaryBody,
  EditProfessionalSummaryRes,
} from "./Model";
import {
  editDoctorProfessionalSummaryAPIResClearAction,
  editDoctorProfessionalSummaryErrorAction,
  editDoctorProfessionalSummaryLoadingAction,
  editDoctorProfessionalSummarySuccessAction,
  editDoctorProfessionalSummaryAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EditProfessionalSummaryRes;
export const editProfessionalSummary = (
  payload: EditProfessionalSummaryBody
) => {
  return function (
    dispatch: Dispatch<EditDoctorProfessionalSummaryActionTypes>
  ) {
    dispatch(editDoctorProfessionalSummaryLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/edit_work_summary`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editDoctorProfessionalSummaryLoadingAction(false));
        dispatch(
          editDoctorProfessionalSummarySuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Professional summary has been editd successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editDoctorProfessionalSummaryLoadingAction(false));
        dispatch(
          editDoctorProfessionalSummaryErrorAction(
            {} as EditProfessionalSummaryRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDoctorProfessionalSummaryAPIResMsg = () => {
  return function (
    dispatch: Dispatch<EditDoctorProfessionalSummaryActionTypes>
  ) {
    dispatch(
      editDoctorProfessionalSummaryAPIMsgAction(
        apiRes as EditProfessionalSummaryRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearEditDoctorProfessionalSummaryAPIRes = () => {
  return function (
    dispatch: Dispatch<EditDoctorProfessionalSummaryActionTypes>
  ) {
    dispatch(
      editDoctorProfessionalSummaryAPIResClearAction(
        {} as EditProfessionalSummaryRes,
        "",
        "",
        0
      )
    );
  };
};
