import { EmailOTPSignUpRes } from "./Model";
export const EMAIL_OTP_SIGNUP_LOADING = "EMAIL_OTP_SIGNUP_LOADING";
export const EMAIL_OTP_SIGNUP_SUCCESS = "EMAIL_OTP_SIGNUP_SUCCESS";
export const EMAIL_OTP_SIGNUP_FAIL = "EMAIL_OTP_SIGNUP_FAIL";
export const EMAIL_OTP_SIGNUP_UPDATE_API_MSG =
  "EMAIL_OTP_SIGNUP_UPDATE_API_MSG";
export const EMAIL_OTP_SIGNUP_UPDATE_API_RES =
  "EMAIL_OTP_SIGNUP_UPDATE_API_RES";

export interface EmailOTPSignupState {
  emailOTPSignUpRes: EmailOTPSignUpRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EmailOTPSignupLoading {
  type: typeof EMAIL_OTP_SIGNUP_LOADING;
  loading: boolean;
}
export interface EmailOTPSignupSuccess {
  type: typeof EMAIL_OTP_SIGNUP_SUCCESS;
  payload: EmailOTPSignUpRes;
  successMsg: string;
}
export interface EmailOTPSignupFail {
  type: typeof EMAIL_OTP_SIGNUP_FAIL;
  payload: EmailOTPSignUpRes;
  errorMsg: string;
  status: number;
}
export interface EmailOTPSignupUpdateAPIMsg {
  type: typeof EMAIL_OTP_SIGNUP_UPDATE_API_MSG;
  payload: EmailOTPSignUpRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EmailOTPSignupUpdateAPIRes {
  type: typeof EMAIL_OTP_SIGNUP_UPDATE_API_RES;
  payload: EmailOTPSignUpRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EmailOTPSignupActionTypes =
  | EmailOTPSignupLoading
  | EmailOTPSignupSuccess
  | EmailOTPSignupFail
  | EmailOTPSignupUpdateAPIMsg
  | EmailOTPSignupUpdateAPIRes;
