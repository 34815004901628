import {
    DeleteSelectedDoctorDetails,
    deleteSelectedDoctorActionTypes,
    DELETE_SELECTED_DOCTOR_FAIL,
    DELETE_SELECTED_DOCTOR_LOADING,
    DELETE_SELECTED_DOCTOR_SUCCESS,
  } from "./ActionTypes";
  import { DeleteSelectedDoctorValue } from "./Model";
  
  const initialStateGetPosts: DeleteSelectedDoctorDetails = {
    loading: false,
    deleteSelectedDoctorRes: {} as DeleteSelectedDoctorValue,
    errRes: "",
  };
  export const deleteSelectedDoctorReducer = (
    state = initialStateGetPosts,
    action: deleteSelectedDoctorActionTypes
  ): DeleteSelectedDoctorDetails => {
    switch (action.type) {
      case DELETE_SELECTED_DOCTOR_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteSelectedDoctorRes: action.payload,
          errRes: "",
        };
      case DELETE_SELECTED_DOCTOR_LOADING:
        return {
          ...state,
          loading: true,
          deleteSelectedDoctorRes: {} as DeleteSelectedDoctorValue,
        };
      case DELETE_SELECTED_DOCTOR_FAIL:
        return {
          ...state,
          deleteSelectedDoctorRes: {} as DeleteSelectedDoctorValue,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  