import { UpdateEducationSummaryRes } from "./Model";
export const UPDATE_DOCTOR_EDUCATION_SUMMARY_LOADING =
  "UPDATE_DOCTOR_EDUCATION_SUMMARY_LOADING";
export const UPDATE_DOCTOR_EDUCATION_SUMMARY_SUCCESS =
  "UPDATE_DOCTOR_EDUCATION_SUMMARY_SUCCESS";
export const UPDATE_DOCTOR_EDUCATION_SUMMARY_FAIL =
  "UPDATE_DOCTOR_EDUCATION_SUMMARY_FAIL";
export const UPDATE_DOCTOR_EDUCATION_SUMMARY_API_MSG =
  "UPDATE_DOCTOR_EDUCATION_SUMMARY_API_MSG";
export const UPDATE_DOCTOR_EDUCATION_SUMMARY_API_RES =
  "UPDATE_DOCTOR_EDUCATION_SUMMARY_API_RES";

export interface UpdateDoctorEducationSummaryState {
  updateDoctorEducationSummaryRes: UpdateEducationSummaryRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorEducationSummaryLoading {
  type: typeof UPDATE_DOCTOR_EDUCATION_SUMMARY_LOADING;
  loading: boolean;
}
export interface UpdateDoctorEducationSummarySuccess {
  type: typeof UPDATE_DOCTOR_EDUCATION_SUMMARY_SUCCESS;
  payload: UpdateEducationSummaryRes;
  successMsg: string;
}
export interface UpdateDoctorEducationSummaryFail {
  type: typeof UPDATE_DOCTOR_EDUCATION_SUMMARY_FAIL;
  payload: UpdateEducationSummaryRes;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorEducationSummaryAPIMsg {
  type: typeof UPDATE_DOCTOR_EDUCATION_SUMMARY_API_MSG;
  payload: UpdateEducationSummaryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorEducationSummaryAPIRes {
  type: typeof UPDATE_DOCTOR_EDUCATION_SUMMARY_API_RES;
  payload: UpdateEducationSummaryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type UpdateDoctorEducationSummaryActionTypes =
  | UpdateDoctorEducationSummaryLoading
  | UpdateDoctorEducationSummarySuccess
  | UpdateDoctorEducationSummaryFail
  | UpdateDoctorEducationSummaryAPIMsg
  | UpdateDoctorEducationSummaryAPIRes;
