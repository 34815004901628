import {
  EditPatientImportConfigDataActionTypes,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_FAIL,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_LOADING,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_SUCCESS,
  EditPatientImportConfigDataState,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_MSG,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_RES,
} from "./ActionTypes";
import { EditPatientImportConfigValueRes } from "./Model";

const initialStateGetPosts: EditPatientImportConfigDataState = {
  loading: false,
  editPatientImportConfigDataRes: {} as EditPatientImportConfigValueRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editPatientImportConfigDataReducer = (
  state = initialStateGetPosts,
  action: EditPatientImportConfigDataActionTypes
): EditPatientImportConfigDataState => {
  switch (action.type) {
    case EDIT_PATIENT_IMPORT_CONFIG_DATA_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_PATIENT_IMPORT_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        editPatientImportConfigDataRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_PATIENT_IMPORT_CONFIG_DATA_FAIL:
      return {
        ...state,
        editPatientImportConfigDataRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_MSG:
      return {
        ...state,
        editPatientImportConfigDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_RES:
      return {
        ...state,
        editPatientImportConfigDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
