import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedDegreeList } from "./Model";
import MWUploader from "components/MWUploader";
import { AppState } from "redux/store/Store";
import { DegreeList } from "redux/DegreeListDropdown/Model";
import dayjs, { Dayjs } from "dayjs";
import { AwardTypeList } from "redux/UserProfile/Award/AwardTypeList/Model";
import { AddAwardBody } from "redux/UserProfile/Award/AddAward/Model";
import {
  addDrAward,
  clearAddDrAwardAPIRes,
} from "redux/UserProfile/Award/AddAward/API";
import {
  clearEditDrAwardAPIRes,
  editDrAward,
} from "redux/UserProfile/Award/EditAward/API";
import { EditAwardBody } from "redux/UserProfile/Award/EditAward/Model";
import { getAwardList } from "redux/UserProfile/Award/GetAwardList/API";
import MWLoader from "components/MWLoader";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedDegreeList | any;
  selectedItemUpdateState: any;
}
export default function UpdateDoctorAwardDetails({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setAwardName("");
    setSelectedAward(null);
    seSelectedAwardError("");
    setImageId("");
    dispatch(clearImageUploadAPIRes());
    setPresentDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    dispatch(clearAddDrAwardAPIRes());
    setAddDrAwardMsg("");
    dispatch(clearEditDrAwardAPIRes());
    setEditDrAwardMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  // Call Specialty List store
  const getAwardTypeListRes = useSelector(
    (state: AppState) => state.getAwardTypeListRes
  );
  const [awardTypeList, setAwardTypeList] = useState([] as DegreeList[]);
  useEffect(() => {
    if (
      getAwardTypeListRes?.getDoctorAwardTypeListRes?.awardTypeList !==
      undefined
    ) {
      if (
        getAwardTypeListRes?.getDoctorAwardTypeListRes?.awardTypeList.length > 0
      ) {
        setAwardTypeList(
          getAwardTypeListRes?.getDoctorAwardTypeListRes?.awardTypeList
        );
      } else setAwardTypeList([] as DegreeList[]);
    } else setAwardTypeList([] as DegreeList[]);
  }, [getAwardTypeListRes]);

  const [awardName, setAwardName] = useState("");
  const awardNameChange = (event: string) => {
    setAwardName(event);
  };

  const [selectedAward, setSelectedAward] = useState<DegreeList | null>(null);
  const selectedAwardChange = (_event: any, passedVal: DegreeList | any) => {
    setSelectedAward(passedVal);
  };
  const [selectedAwardError, seSelectedAwardError] = useState("");
  const [selectedAwardErrorMsg, setSelectedAwardErrorMsg] = useState("");

  // from Date
  const [presentDate, setPresentDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setPresentDate(date);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  useEffect(() => {
    selectedItem?.name !== undefined
      ? setAwardName(selectedItem?.name)
      : setAwardName("");

    selectedItem?.awardTypeVal !== undefined &&
    getAwardTypeListRes?.getDoctorAwardTypeListRes?.awardTypeList !== undefined
      ? selectedItem?.awardTypeVal !== "" &&
        getAwardTypeListRes?.getDoctorAwardTypeListRes?.awardTypeList !== null
        ? setSelectedAward(
            getAwardTypeListRes?.getDoctorAwardTypeListRes?.awardTypeList!.find(
              (o: AwardTypeList) => o.value === selectedItem?.awardTypeVal
            )!
          )
        : setSelectedAward(null)
      : setSelectedAward(null);

    if (selectedItem?.presentDate !== undefined) {
      let year = selectedItem?.presentDate.split("-")[2];
      let month = selectedItem?.presentDate.split("-")[1];
      let day = selectedItem?.presentDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setPresentDate(dayjs(date));
    }
    if (selectedItem?.presentDate === undefined) {
      setPresentDate(dayjs(new Date()));
    } else if (selectedItem?.presentDate === "Not Set") {
      setPresentDate(dayjs(new Date()));
    }

    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== ""
        ? setImageInfo(selectedItem?.supportingDocument)
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, getAwardTypeListRes]);

  const addDrAwardBody = {
    description: awardName,
    fromDate:
      presentDate !== null
        ? dayjs.utc(presentDate).format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
  } as AddAwardBody;

  const submitAwardDetails = () => {
    if (selectedAward === null) {
      seSelectedAwardError(
        `${t("userprofilepage.selAward", { ns: ["home"] })!}`
      );
    } else {
      seSelectedAwardError("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Award Details
        if (selectedAward !== null) {
          setSelectedAwardErrorMsg("");
          if (imageId === undefined) {
            dispatch(
              addDrAward(
                Object.assign(addDrAwardBody, {
                  awardtypeId:
                    selectedAward?.value !== null ? selectedAward?.value : null,
                })
              )
            );
          } else {
            dispatch(
              addDrAward(
                Object.assign(addDrAwardBody, {
                  awardtypeId:
                    selectedAward?.value !== null ? selectedAward?.value : null,
                  supportdocId: imageId !== "" ? imageId : null,
                })
              )
            );
          }
        } else {
          setSelectedAwardErrorMsg(
            `${t("userprofilepage.selAward", { ns: ["home"] })!}`
          );
        }
      } else {
        // Edit Dr. Award Details
        if (selectedAward !== null) {
          setSelectedAwardErrorMsg("");
          if (imageId === undefined) {
            // When user does not upload image on add work experience
            dispatch(
              editDrAward(
                Object.assign(addDrAwardBody, {
                  awardId: Number(selectedItem?.id!),
                  awardtypeId:
                    selectedAward?.value !== null ? selectedAward?.value : null,
                  supportdocId: imageId !== "" ? Number(imageId) : null,
                } as EditAwardBody)
              )
            );
          } else {
            dispatch(
              editDrAward(
                Object.assign(addDrAwardBody, {
                  awardId: Number(selectedItem?.id!),
                  awardtypeId:
                    selectedAward?.value !== null ? selectedAward?.value : null,
                  supportdocId: imageId !== "" ? Number(imageId) : null,
                } as EditAwardBody)
              )
            );
          }
        } else {
          setSelectedAwardErrorMsg(
            `${t("userprofilepage.selAward", { ns: ["home"] })!}`
          );
        }
      }
    }
  };

  const addDrAwardRes = useSelector((state: AppState) => state.addDrAwardRes);
  const [addDrAwardMsg, setAddDrAwardMsg] = useState("");
  const [addDrAwardMsgType, setAddDrAwardMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addDrAwardRes?.successMsg !== "") {
      setAddDrAwardMsgType("success");
      setAddDrAwardMsg(addDrAwardRes?.successMsg);
      dispatch(getAwardList());
      closeDialog();
    }
    if (addDrAwardRes?.errorMsg !== "") {
      setAddDrAwardMsgType("error");
      setAddDrAwardMsg(addDrAwardRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDrAwardRes]);

  const editDrAwardRes = useSelector((state: AppState) => state.editDrAwardRes);
  const [editDrAwardMsg, setEditDrAwardMsg] = useState("");
  const [editDrAwardMsgType, setEditDrAwardMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (editDrAwardRes?.successMsg !== "") {
      setEditDrAwardMsgType("success");
      setEditDrAwardMsg(editDrAwardRes?.successMsg);
      dispatch(getAwardList());
      closeDialog();
    }
    if (editDrAwardRes?.errorMsg !== "") {
      setEditDrAwardMsgType("error");
      setEditDrAwardMsg(editDrAwardRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDrAwardRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addDrAwardRes?.loading || editDrAwardRes?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {t("userprofilepage.editawarddetail", { ns: ["home"] })!}
              </Typography>
            ) : (
              <Typography variant="h5">
                {t("userprofilepage.addawarddetail", { ns: ["home"] })!}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("userprofilepage.awardtype", { ns: ["home"] })}
              </Typography>
              <Autocomplete
                value={selectedAward}
                id="highest-states"
                options={awardTypeList}
                onChange={(event: any, newValue: any) => {
                  selectedAwardChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      t("userprofilepage.selAwardType", { ns: ["home"] })!
                    }
                  />
                )}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value) =>
                  option.value === value.value
                }
              />
              {selectedAwardError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {selectedAwardError}
                </Typography>
              ) : null}
              {selectedAwardErrorMsg !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {selectedAwardErrorMsg}
                </Typography>
              ) : null}
              <Typography style={{ marginBottom: "5px" }}>
                {t("userprofilepage.awardname", { ns: ["home"] })}
              </Typography>
              <TextField
                value={awardName}
                onChange={(e) => awardNameChange(e.target.value)}
                label={t("userprofilepage.awardname", { ns: ["home"] })}
                type="text"
                placeholder={
                  t("userprofilepage.enterawardname", { ns: ["home"] })!
                }
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="award-supporting-docs"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                {t("userprofilepage.pdate", { ns: ["home"] })!}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={presentDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Stack>
        {addDrAwardMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrAwardMsgType}
              onClose={() => {
                dispatch(clearAddDrAwardAPIRes());
                setAddDrAwardMsg("");
              }}
            >
              {addDrAwardMsg}
            </Alert>
          </Box>
        ) : null}
        {editDrAwardMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editDrAwardMsgType}
              onClose={() => {
                dispatch(clearEditDrAwardAPIRes());
                setEditDrAwardMsg("");
              }}
            >
              {editDrAwardMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={addDrAwardRes?.loading || editDrAwardRes?.loading}
              onClick={() => submitAwardDetails()}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
