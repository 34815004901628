import { AddDrIdProofRes } from "./Model";
export const ADD_DR_ID_PROOF_LOADING = "ADD_DR_ID_PROOF_LOADING";
export const ADD_DR_ID_PROOF_SUCCESS = "ADD_DR_ID_PROOF_SUCCESS";
export const ADD_DR_ID_PROOF_FAIL = "ADD_DR_ID_PROOF_FAIL";
export const ADD_DR_ID_PROOF_API_MSG = "ADD_DR_ID_PROOF_API_MSG";
export const ADD_DR_ID_PROOF_API_RES = "ADD_DR_ID_PROOF_API_RES";

export interface AddDrIdProofState {
  addDrIdProofRes: AddDrIdProofRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrIdProofLoading {
  type: typeof ADD_DR_ID_PROOF_LOADING;
  loading: boolean;
}
export interface AddDrIdProofSuccess {
  type: typeof ADD_DR_ID_PROOF_SUCCESS;
  payload: AddDrIdProofRes;
  successMsg: string;
}
export interface AddDrIdProofFail {
  type: typeof ADD_DR_ID_PROOF_FAIL;
  payload: AddDrIdProofRes;
  errorMsg: string;
  status: number;
}
export interface AddDrIdProofAPIMsg {
  type: typeof ADD_DR_ID_PROOF_API_MSG;
  payload: AddDrIdProofRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrIdProofAPIRes {
  type: typeof ADD_DR_ID_PROOF_API_RES;
  payload: AddDrIdProofRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrIdProofActionTypes =
  | AddDrIdProofLoading
  | AddDrIdProofSuccess
  | AddDrIdProofFail
  | AddDrIdProofAPIMsg
  | AddDrIdProofAPIRes;
