import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import {
  PrescriptionListActionTypes,
  PRESCRIPTION_LIST_FAIL,
  PRESCRIPTION_LIST_LOADING,
  PRESCRIPTION_LIST_SUCCESS,
} from "./ActionTypes";
import { PrescriptionListModel, PrescriptionListRes } from "./Model";
import { headers } from "components/Utility";

export const prescriptionListApi = (editBody: PrescriptionListModel) => {
  return function (dispatch: Dispatch<PrescriptionListActionTypes>) {
    dispatch({
      type: PRESCRIPTION_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          `/patientapp/api/prescriptionTemplate/list_prescription`,
        editBody,
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch({
          type: PRESCRIPTION_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: PRESCRIPTION_LIST_FAIL,
          payload: {} as PrescriptionListRes,
          errRes: error.response.data.message,
        });
      });
  };
};
