import {
  GET_DOCTOR_WORK_EXPERIENCE_LIST_FAIL,
  GET_DOCTOR_WORK_EXPERIENCE_LIST_LOADING,
  GET_DOCTOR_WORK_EXPERIENCE_LIST_SUCCESS,
  GET_DOCTOR_WORK_EXPERIENCE_LIST_API_MSG,
  GET_DOCTOR_WORK_EXPERIENCE_LIST_API_RES,
  GetDoctorWorkExperienceListActionTypes,
} from "./ActionTypes";
import { GetWorkExperienceListRes } from "./Model";

export const getDoctorWorkExperienceListLoadingAction = (
  loading: boolean
): GetDoctorWorkExperienceListActionTypes => {
  return {
    type: GET_DOCTOR_WORK_EXPERIENCE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorWorkExperienceListSuccessAction = (
  getDoctorWorkExperienceListResponse: GetWorkExperienceListRes,
  successMsg: string
): GetDoctorWorkExperienceListActionTypes => {
  return {
    type: GET_DOCTOR_WORK_EXPERIENCE_LIST_SUCCESS,
    payload: getDoctorWorkExperienceListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorWorkExperienceListErrorAction = (
  getDoctorWorkExperienceListResponse: GetWorkExperienceListRes,
  errMsg: string,
  status: number
): GetDoctorWorkExperienceListActionTypes => {
  return {
    type: GET_DOCTOR_WORK_EXPERIENCE_LIST_FAIL,
    payload: getDoctorWorkExperienceListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorWorkExperienceListAPIMsgAction = (
  getDoctorWorkExperienceListResponse: GetWorkExperienceListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorWorkExperienceListActionTypes => {
  return {
    type: GET_DOCTOR_WORK_EXPERIENCE_LIST_API_MSG,
    payload: getDoctorWorkExperienceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorWorkExperienceListAPIResClearAction = (
  getDoctorWorkExperienceListResponse: GetWorkExperienceListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorWorkExperienceListActionTypes => {
  return {
    type: GET_DOCTOR_WORK_EXPERIENCE_LIST_API_RES,
    payload: getDoctorWorkExperienceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
