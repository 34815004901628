import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "components/MWDialogTransition";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedMembershipList } from "../Model";
import MWUploader from "components/MWUploader";
import { AppState } from "redux/store/Store";
import dayjs, { Dayjs } from "dayjs";
import { AddProfessionalMembershipBody } from "redux/UserProfile/ProfessionalDetails/Membership/AddProfessionalMembership/Model";
import {
  addDrProfessionalMembership,
  clearAddDrProfessionalMembershipAPIRes,
} from "redux/UserProfile/ProfessionalDetails/Membership/AddProfessionalMembership/API";
import { getMembershipList } from "redux/UserProfile/ProfessionalDetails/Membership/GetProfessionalMembershipList/API";
import {
  clearEditDrProfessionalMembershipAPIRes,
  editDrProfessionalMembership,
} from "redux/UserProfile/ProfessionalDetails/Membership/EditProfessionalMembership/API";
import { EditProfessionalMembershipBody } from "redux/UserProfile/ProfessionalDetails/Membership/EditProfessionalMembership/Model";
import MWLoader from "components/MWLoader";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedMembershipList | any;
  selectedItemUpdateState: any;
}
export default function UpdateMembershipDetails({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setDescription("");
    setMembershipName("");
    setMembershipNameError("");
    setMembershipNumber("");
    setImageId("");
    dispatch(clearImageUploadAPIRes());
    setSelectedDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    dispatch(clearAddDrProfessionalMembershipAPIRes());
    setAddDrProfessionalMembershipMsg("");
    dispatch(clearEditDrProfessionalMembershipAPIRes());
    setEditDrProfessionalMembershipMsg("");
  };
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const [membershipName, setMembershipName] = useState("");
  const [membershipNameError, setMembershipNameError] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [description, setDescription] = useState("");

  // from Date
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  useEffect(() => {
    selectedItem?.membershipName !== undefined
      ? selectedItem?.membershipName !== null
        ? setMembershipName(selectedItem?.membershipName)
        : setMembershipName("")
      : setMembershipName("");
    selectedItem?.membershipNumber !== undefined
      ? selectedItem?.membershipNumber !== null
        ? setMembershipNumber(selectedItem?.membershipNumber)
        : setMembershipNumber("")
      : setMembershipNumber("");
    selectedItem?.description !== undefined
      ? selectedItem?.description !== null
        ? setDescription(selectedItem?.description)
        : setDescription("")
      : setDescription("");
    if (selectedItem?.startDate !== undefined) {
      let year = selectedItem?.startDate.split("-")[2];
      let month = selectedItem?.startDate.split("-")[1];
      let day = selectedItem?.startDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setSelectedDate(dayjs(date));
    }
    if (selectedItem?.startDate === undefined) {
      setSelectedDate(dayjs(new Date()));
    }

    selectedItem?.supportingDocument !== undefined
      ? selectedItem?.supportingDocument !== null
        ? selectedItem?.supportingDocument !== ""
          ? setImageInfo(selectedItem?.supportingDocument)
          : setImageInfo("")
        : setImageInfo("")
      : setImageInfo("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const addDrProfessionalMembershipBody = {
    name: membershipName,
    idNumber: membershipNumber,
    validityDate:
      selectedDate !== null
        ? dayjs(selectedDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    description: description,
  } as AddProfessionalMembershipBody;

  const submitDrProfessionalMembership = () => {
    if (membershipName === "") {
      setMembershipNameError(
        `${t("userprofilepage.profmemnameerror", { ns: ["home"] })!}`
      );
    } else {
      setMembershipNameError("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Education Details
        if (imageId === undefined) {
          dispatch(
            addDrProfessionalMembership(
              Object.assign(addDrProfessionalMembershipBody, {
                supportdocId: imageId !== "" ? imageId : null,
              })
            )
          );
        } else {
          dispatch(
            addDrProfessionalMembership(
              Object.assign(addDrProfessionalMembershipBody, {
                supportdocId: imageId !== "" ? imageId! : null,
              })
            )
          );
        }
      } else {
        // Edit Dr. Education Details
        if (imageId === undefined) {
          // When user does not upload image on add work experience
          dispatch(
            editDrProfessionalMembership(
              Object.assign(addDrProfessionalMembershipBody, {
                membershipId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? imageId : null,
              } as EditProfessionalMembershipBody)
            )
          );
        } else {
          dispatch(
            editDrProfessionalMembership(
              Object.assign(addDrProfessionalMembershipBody, {
                membershipId: Number(selectedItem?.id!),
                supportdocId: imageId !== "" ? imageId : null,
              } as EditProfessionalMembershipBody)
            )
          );
        }
      }
    }
  };

  const addDrProfessionalMembershipReducer = useSelector(
    (state: AppState) => state.addDrProfessionalMembershipReducer
  );
  const [addDrProfessionalMembershipMsg, setAddDrProfessionalMembershipMsg] =
    useState("");
  const [
    addDrProfessionalMembershipMsgType,
    setAddDrProfessionalMembershipMsgType,
  ] = useState<AlertColor>("success");

  useEffect(() => {
    if (addDrProfessionalMembershipReducer?.successMsg !== "") {
      setAddDrProfessionalMembershipMsgType("success");
      setAddDrProfessionalMembershipMsg(
        addDrProfessionalMembershipReducer?.successMsg
      );
      dispatch(getMembershipList());
      closeDialog();
    }
    if (addDrProfessionalMembershipReducer?.errorMsg !== "") {
      setAddDrProfessionalMembershipMsgType("error");
      setAddDrProfessionalMembershipMsg(
        addDrProfessionalMembershipReducer?.errorMsg
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDrProfessionalMembershipReducer]);

  const editDrProfessionalMembershipRes = useSelector(
    (state: AppState) => state.editDrProfessionalMembershipRes
  );
  const [editDrProfessionalMembershipMsg, setEditDrProfessionalMembershipMsg] =
    useState("");
  const [
    editDrProfessionalMembershipMsgType,
    setEditDrProfessionalMembershipMsgType,
  ] = useState<AlertColor>("success");

  useEffect(() => {
    if (editDrProfessionalMembershipRes?.successMsg !== "") {
      setEditDrProfessionalMembershipMsgType("success");
      setEditDrProfessionalMembershipMsg(
        editDrProfessionalMembershipRes?.successMsg
      );
      dispatch(getMembershipList());
      closeDialog();
    }
    if (editDrProfessionalMembershipRes?.errorMsg !== "") {
      setEditDrProfessionalMembershipMsgType("error");
      setEditDrProfessionalMembershipMsg(
        editDrProfessionalMembershipRes?.errorMsg
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDrProfessionalMembershipRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      fullScreen={fullScreen}
      fullWidth
    >
      {addDrProfessionalMembershipReducer?.loading ||
      editDrProfessionalMembershipRes?.loading ? (
        <MWLoader />
      ) : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {selectedItem?.id ? (
              <Typography variant="h5">
                {t("userprofilepage.editprofmem", { ns: ["home"] })}
              </Typography>
            ) : (
              <Typography variant="h5">
                {t("userprofilepage.addprofmem", { ns: ["home"] })}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-between"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="body1" style={{ marginBottom: "5px" }}>
                {t("userprofilepage.profmemname", { ns: ["home"] })}
              </Typography>
              <TextField
                value={membershipName}
                onChange={(e) => setMembershipName(e.target.value)}
                label={t("userprofilepage.profmemname", { ns: ["home"] })}
                placeholder={
                  t("userprofilepage.enterprofmemname", { ns: ["home"] })!
                }
                type="text"
                fullWidth
                size="small"
              />
              {membershipNameError !== "" ? (
                <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                  {membershipNameError}
                </Typography>
              ) : null}
              <Typography style={{ marginBottom: "5px" }}>
                {t("userprofilepage.enterprofmemnum", {
                  ns: ["home"],
                })}
              </Typography>
              <TextField
                value={membershipNumber}
                onChange={(e) => setMembershipNumber(e.target.value)}
                label={t("userprofilepage.enterprofmemnum", {
                  ns: ["home"],
                })}
                placeholder={t("userprofilepage.profmemnum", { ns: ["home"] })!}
                type="text"
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="doctor-license"
                criteria={t("userprofilepage.awarddocsize", { ns: ["home"] })!}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.uploadsuppdoc", { ns: ["home"] })!}
                visibility={false}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography>
                {t("userprofilepage.vdate", { ns: ["home"] })}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography style={{ marginBottom: "5px" }}>
                {t("userprofilepage.memdesc", { ns: ["home"] })}
              </Typography>
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label={t("userprofilepage.memdesc", { ns: ["home"] })}
                placeholder={
                  t("userprofilepage.entermemdesc", { ns: ["home"] })!
                }
                type="text"
                fullWidth
                size="small"
                multiline
                maxRows={4}
              />
            </Grid>
          </Grid>
        </Stack>
        {addDrProfessionalMembershipMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addDrProfessionalMembershipMsgType}
              onClose={() => {
                dispatch(clearAddDrProfessionalMembershipAPIRes());
                setAddDrProfessionalMembershipMsg("");
              }}
            >
              {addDrProfessionalMembershipMsg}
            </Alert>
          </Box>
        ) : null}
        {editDrProfessionalMembershipMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editDrProfessionalMembershipMsgType}
              onClose={() => {
                dispatch(clearEditDrProfessionalMembershipAPIRes());
                setEditDrProfessionalMembershipMsg("");
              }}
            >
              {editDrProfessionalMembershipMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={
                addDrProfessionalMembershipReducer?.loading ||
                editDrProfessionalMembershipRes?.loading
              }
              onClick={() => submitDrProfessionalMembership()}
              sx={{ textTransform: "none" }}
            >
              {t("Workschedulepage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
