import { MeetingListResponse } from "./Model";
export const MEETING_LIST_LOADING = "MEETING_LIST_LOADING";
export const MEETING_LIST_SUCCESS = "MEETING_LIST_SUCCESS";
export const MEETING_LIST_FAIL = "MEETING_LIST_FAIL";
export const MEETING_LIST_API_MSG = "MEETING_LIST_API_MSG";
export const MEETING_LIST_API_RES = "MEETING_LIST_API_RES";

export interface MeetingListState {
  meetingListResponse: MeetingListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface MeetingListLoading {
  type: typeof MEETING_LIST_LOADING;
  loading: boolean;
}
export interface MeetingListSuccess {
  type: typeof MEETING_LIST_SUCCESS;
  payload: MeetingListResponse;
  successMsg: string;
}
export interface MeetingListFail {
  type: typeof MEETING_LIST_FAIL;
  payload: MeetingListResponse;
  errorMsg: string;
  status: number;
}
export interface MeetingListAPIMsg {
  type: typeof MEETING_LIST_API_MSG;
  payload: MeetingListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface MeetingListAPIRes {
  type: typeof MEETING_LIST_API_RES;
  payload: MeetingListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type MeetingListActionTypes =
  | MeetingListLoading
  | MeetingListSuccess
  | MeetingListFail
  | MeetingListAPIMsg
  | MeetingListAPIRes;
