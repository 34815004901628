import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { GetDoctorTaskListActionTypes } from "./ActionTypes";
import { GetTaskListRes } from "./Model";
import {
  getDoctorTaskListAPIResClearAction,
  getDoctorTaskListErrorAction,
  getDoctorTaskListLoadingAction,
  getDoctorTaskListSuccessAction,
  getDoctorTaskListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetTaskListRes;
export const getTaskListList = () => {
  return function (dispatch: Dispatch<GetDoctorTaskListActionTypes>) {
    dispatch(getDoctorTaskListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/doctorcase/doctor_task_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorTaskListLoadingAction(false));
        dispatch(
          getDoctorTaskListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. task list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorTaskListLoadingAction(false));
        dispatch(
          getDoctorTaskListErrorAction(
            {} as GetTaskListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorTaskListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorTaskListActionTypes>) {
    dispatch(getDoctorTaskListAPIMsgAction(apiRes as GetTaskListRes, "", 0));
  };
};

export const clearGetDoctorEducationListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorTaskListActionTypes>) {
    dispatch(getDoctorTaskListAPIResClearAction({} as GetTaskListRes, "", 0));
  };
};
