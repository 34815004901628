import { GetLicenseListRes } from "./Model";
export const GET_DOCTOR_LICENSE_LIST_LOADING =
  "GET_DOCTOR_LICENSE_LIST_LOADING";
export const GET_DOCTOR_LICENSE_LIST_SUCCESS =
  "GET_DOCTOR_LICENSE_LIST_SUCCESS";
export const GET_DOCTOR_LICENSE_LIST_FAIL = "GET_DOCTOR_LICENSE_LIST_FAIL";
export const GET_DOCTOR_LICENSE_LIST_API_MSG =
  "GET_DOCTOR_LICENSE_LIST_API_MSG";
export const GET_DOCTOR_LICENSE_LIST_API_RES =
  "GET_DOCTOR_LICENSE_LIST_API_RES";

export interface GetDoctorLicenseListState {
  getDoctorLicenseListRes: GetLicenseListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLicenseListLoading {
  type: typeof GET_DOCTOR_LICENSE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorLicenseListSuccess {
  type: typeof GET_DOCTOR_LICENSE_LIST_SUCCESS;
  payload: GetLicenseListRes;
  successMsg: string;
}
export interface GetDoctorLicenseListFail {
  type: typeof GET_DOCTOR_LICENSE_LIST_FAIL;
  payload: GetLicenseListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLicenseListAPIMsg {
  type: typeof GET_DOCTOR_LICENSE_LIST_API_MSG;
  payload: GetLicenseListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLicenseListAPIRes {
  type: typeof GET_DOCTOR_LICENSE_LIST_API_RES;
  payload: GetLicenseListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorLicenseListActionTypes =
  | GetDoctorLicenseListLoading
  | GetDoctorLicenseListSuccess
  | GetDoctorLicenseListFail
  | GetDoctorLicenseListAPIMsg
  | GetDoctorLicenseListAPIRes;
