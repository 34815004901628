import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { AddDrIdProofActionTypes } from "./ActionTypes";
import { AddDrIdProofBody, AddDrIdProofRes } from "./Model";
import {
  addDrIdProofAPIResClearAction,
  addDrIdProofErrorAction,
  addDrIdProofLoadingAction,
  addDrIdProofSuccessAction,
  addDrIdProofAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddDrIdProofRes;
export const addDrIdProof = (payload: AddDrIdProofBody) => {
  return function (dispatch: Dispatch<AddDrIdProofActionTypes>) {
    dispatch(addDrIdProofLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/doctoridproof/add_doctor_idproof`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDrIdProofLoadingAction(false));
        dispatch(
          addDrIdProofSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. id proof details has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDrIdProofLoadingAction(false));
        dispatch(
          addDrIdProofErrorAction(
            {} as AddDrIdProofRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDrIdProofAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDrIdProofActionTypes>) {
    dispatch(
      addDrIdProofAPIMsgAction(apiRes as AddDrIdProofRes, "", "", 0)
    );
  };
};

export const clearAddDrIdProofAPIRes = () => {
  return function (dispatch: Dispatch<AddDrIdProofActionTypes>) {
    dispatch(
      addDrIdProofAPIResClearAction({} as AddDrIdProofRes, "", "", 0)
    );
  };
};
