import {
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_FAIL,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_LOADING,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_SUCCESS,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_MSG,
  GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_RES,
  GetDetailsForEditPrescriptionActionTypes,
} from "./ActionTypes";
import { GetDetailsForEditPrescriptionRes } from "./Model";

export const getDetailsForEditPrescriptionLoadingAction = (
  loading: boolean
): GetDetailsForEditPrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_EDIT_PRESCRIPTION_LOADING,
    loading: loading,
  };
};

export const getDetailsForEditPrescriptionSuccessAction = (
  getDetailsForEditPrescriptionResponse: GetDetailsForEditPrescriptionRes,
  successMsg: string
): GetDetailsForEditPrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_EDIT_PRESCRIPTION_SUCCESS,
    payload: getDetailsForEditPrescriptionResponse,
    successMsg: successMsg,
  };
};

export const getDetailsForEditPrescriptionErrorAction = (
  getDetailsForEditPrescriptionResponse: GetDetailsForEditPrescriptionRes,
  errMsg: string,
  status: number
): GetDetailsForEditPrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_EDIT_PRESCRIPTION_FAIL,
    payload: getDetailsForEditPrescriptionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDetailsForEditPrescriptionAPIMsgAction = (
  getDetailsForEditPrescriptionResponse: GetDetailsForEditPrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDetailsForEditPrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_MSG,
    payload: getDetailsForEditPrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDetailsForEditPrescriptionAPIResClearAction = (
  getDetailsForEditPrescriptionResponse: GetDetailsForEditPrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDetailsForEditPrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_RES,
    payload: getDetailsForEditPrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
