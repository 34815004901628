import {
    ADD_SUMMARYLIST_SUBMIT_LIST_FAIL,
    ADD_SUMMARYLIST_SUBMIT_LIST_LOADING,
    ADD_SUMMARYLIST_SUBMIT_LIST_SUCCESS,
    ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG,
    ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES,
    AddSummaryListActionTypes,
  } from "./ActionTypes";
  import { AddSummaryListRes } from "./Model";
  
  export const addSummaryListLoadingAction = (
    loading: boolean
  ): AddSummaryListActionTypes => {
    return {
      type: ADD_SUMMARYLIST_SUBMIT_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const addSummaryListSuccessAction = (
    addSummaryListResponse: AddSummaryListRes,
    successMsg: string
  ): AddSummaryListActionTypes => {
    return {
      type: ADD_SUMMARYLIST_SUBMIT_LIST_SUCCESS,
      payload: addSummaryListResponse,
      successMsg: successMsg,
    };
  };
  
  export const addSummaryListErrorAction = (
    addSummaryListResponse: AddSummaryListRes,
    errMsg: string,
    status: number
  ): AddSummaryListActionTypes => {
    return {
      type: ADD_SUMMARYLIST_SUBMIT_LIST_FAIL,
      payload: addSummaryListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const addSummaryListUpdateAPIMsgAction = (
    addSummaryListResponse: AddSummaryListRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): AddSummaryListActionTypes => {
    return {
      type: ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG,
      payload: addSummaryListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const addSummaryListAPIResClearAction = (
    addSummaryListResponse: AddSummaryListRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): AddSummaryListActionTypes => {
    return {
      type: ADD_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES,
      payload: addSummaryListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  