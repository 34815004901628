import {
  ADD_DR_APPOINTMENT_DECISION_FAIL,
  ADD_DR_APPOINTMENT_DECISION_LOADING,
  ADD_DR_APPOINTMENT_DECISION_SUCCESS,
  ADD_DR_APPOINTMENT_DECISION_API_MSG,
  ADD_DR_APPOINTMENT_DECISION_API_RES,
  DrAppointmentDecisionActionTypes,
} from "./ActionTypes";
import { DoctorAppointmentDecisionRes } from "./Model";

export const drAppointmentDecisionLoadingAction = (
  loading: boolean
): DrAppointmentDecisionActionTypes => {
  return {
    type: ADD_DR_APPOINTMENT_DECISION_LOADING,
    loading: loading,
  };
};

export const drAppointmentDecisionSuccessAction = (
  drAppointmentDecisionRes: DoctorAppointmentDecisionRes,
  successMsg: string
): DrAppointmentDecisionActionTypes => {
  return {
    type: ADD_DR_APPOINTMENT_DECISION_SUCCESS,
    payload: drAppointmentDecisionRes,
    successMsg: successMsg,
  };
};

export const drAppointmentDecisionErrorAction = (
  drAppointmentDecisionRes: DoctorAppointmentDecisionRes,
  errMsg: string,
  status: number
): DrAppointmentDecisionActionTypes => {
  return {
    type: ADD_DR_APPOINTMENT_DECISION_FAIL,
    payload: drAppointmentDecisionRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const drAppointmentDecisionAPIMsgAction = (
  drAppointmentDecisionRes: DoctorAppointmentDecisionRes,
  successMsg: string,
  errMsg: string,
  status: number
): DrAppointmentDecisionActionTypes => {
  return {
    type: ADD_DR_APPOINTMENT_DECISION_API_MSG,
    payload: drAppointmentDecisionRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const drAppointmentDecisionAPIResClearAction = (
  drAppointmentDecisionRes: DoctorAppointmentDecisionRes,
  successMsg: string,
  errMsg: string,
  status: number
): DrAppointmentDecisionActionTypes => {
  return {
    type: ADD_DR_APPOINTMENT_DECISION_API_RES,
    payload: drAppointmentDecisionRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
