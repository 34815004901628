import {
  UPDATE_DR_LEGAL_SUMMARY_FAIL,
  UPDATE_DR_LEGAL_SUMMARY_LOADING,
  UPDATE_DR_LEGAL_SUMMARY_SUCCESS,
  UPDATE_DR_LEGAL_SUMMARY_API_MSG,
  UPDATE_DR_LEGAL_SUMMARY_API_RES,
  UpdateDrLegalSummaryActionTypes,
} from "./ActionTypes";
import { UpdateLegalSummaryRes } from "./Model";

export const updateDrLegalSummaryLoadingAction = (
  loading: boolean
): UpdateDrLegalSummaryActionTypes => {
  return {
    type: UPDATE_DR_LEGAL_SUMMARY_LOADING,
    loading: loading,
  };
};

export const updateDrLegalSummarySuccessAction = (
  updateDrLegalSummaryResponse: UpdateLegalSummaryRes,
  successMsg: string
): UpdateDrLegalSummaryActionTypes => {
  return {
    type: UPDATE_DR_LEGAL_SUMMARY_SUCCESS,
    payload: updateDrLegalSummaryResponse,
    successMsg: successMsg,
  };
};

export const updateDrLegalSummaryErrorAction = (
  updateDrLegalSummaryResponse: UpdateLegalSummaryRes,
  errMsg: string,
  status: number
): UpdateDrLegalSummaryActionTypes => {
  return {
    type: UPDATE_DR_LEGAL_SUMMARY_FAIL,
    payload: updateDrLegalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updateDrLegalSummaryAPIMsgAction = (
  updateDrLegalSummaryResponse: UpdateLegalSummaryRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDrLegalSummaryActionTypes => {
  return {
    type: UPDATE_DR_LEGAL_SUMMARY_API_MSG,
    payload: updateDrLegalSummaryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const updateDrLegalSummaryAPIResClearAction = (
  updateDrLegalSummaryResponse: UpdateLegalSummaryRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDrLegalSummaryActionTypes => {
  return {
    type: UPDATE_DR_LEGAL_SUMMARY_API_RES,
    payload: updateDrLegalSummaryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
