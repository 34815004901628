import { UpdateLegalSummaryRes } from "./Model";
export const UPDATE_DR_LEGAL_SUMMARY_LOADING =
  "UPDATE_DR_LEGAL_SUMMARY_LOADING";
export const UPDATE_DR_LEGAL_SUMMARY_SUCCESS =
  "UPDATE_DR_LEGAL_SUMMARY_SUCCESS";
export const UPDATE_DR_LEGAL_SUMMARY_FAIL = "UPDATE_DR_LEGAL_SUMMARY_FAIL";
export const UPDATE_DR_LEGAL_SUMMARY_API_MSG =
  "UPDATE_DR_LEGAL_SUMMARY_API_MSG";
export const UPDATE_DR_LEGAL_SUMMARY_API_RES =
  "UPDATE_DR_LEGAL_SUMMARY_API_RES";

export interface UpdateDrLegalSummaryState {
  updateDrLegalSummaryRes: UpdateLegalSummaryRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDrLegalSummaryLoading {
  type: typeof UPDATE_DR_LEGAL_SUMMARY_LOADING;
  loading: boolean;
}
export interface UpdateDrLegalSummarySuccess {
  type: typeof UPDATE_DR_LEGAL_SUMMARY_SUCCESS;
  payload: UpdateLegalSummaryRes;
  successMsg: string;
}
export interface UpdateDrLegalSummaryFail {
  type: typeof UPDATE_DR_LEGAL_SUMMARY_FAIL;
  payload: UpdateLegalSummaryRes;
  errorMsg: string;
  status: number;
}
export interface UpdateDrLegalSummaryAPIMsg {
  type: typeof UPDATE_DR_LEGAL_SUMMARY_API_MSG;
  payload: UpdateLegalSummaryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDrLegalSummaryAPIRes {
  type: typeof UPDATE_DR_LEGAL_SUMMARY_API_RES;
  payload: UpdateLegalSummaryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type UpdateDrLegalSummaryActionTypes =
  | UpdateDrLegalSummaryLoading
  | UpdateDrLegalSummarySuccess
  | UpdateDrLegalSummaryFail
  | UpdateDrLegalSummaryAPIMsg
  | UpdateDrLegalSummaryAPIRes;
