import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { ForgetPasswordActionTypes } from "./ActionTypes";
import { ForgotPasswordBody, ForgotPasswordRes } from "./Model";
import {
  forgotPasswordAPIResClearAction,
  forgotPasswordErrorAction,
  forgotPasswordLoadingAction,
  forgotPasswordSuccessAction,
  forgotPasswordUpdateAPIMsgAction,
} from "./Actions";
import { ApiHeader } from "../../components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as ForgotPasswordRes;
export const forgotPasswordAPI = (payload: ForgotPasswordBody) => {
  return function (dispatch: Dispatch<ForgetPasswordActionTypes>) {
    dispatch(forgotPasswordLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/profileapp/forgot-password/${payload.email}`, {
        headers: ApiHeader,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(forgotPasswordLoadingAction(false));
        dispatch(
          forgotPasswordSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "OTP sent successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(forgotPasswordLoadingAction(false));
        dispatch(
          forgotPasswordErrorAction(
            {} as ForgotPasswordRes,
            error.response !== undefined
              ? error?.response?.data?.message !== undefined
                ? error?.response?.data?.message
                : error?.response?.data?.detail !== undefined
                ? error?.response?.data?.detail
                : error?.response?.data?.messages[0]?.message !== undefined
                ? error?.response?.data?.messages[0]?.message
                : "Something went wrong"
              : "Something went wrong",
            error?.response?.data?.status !== undefined
              ? error.response.data.status || 0
              : 0
          )
        );
      });
  };
};

export const updateForgotPasswordLoginAPIResMsg = () => {
  return function (dispatch: Dispatch<ForgetPasswordActionTypes>) {
    dispatch(
      forgotPasswordUpdateAPIMsgAction(apiRes as ForgotPasswordRes, "", "", 0)
    );
  };
};

export const clearForgotPasswordLoginAPIRes = () => {
  return function (dispatch: Dispatch<ForgetPasswordActionTypes>) {
    dispatch(
      forgotPasswordAPIResClearAction({} as ForgotPasswordRes, "", "", 0)
    );
  };
};
