import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorLegalSummaryActionTypes } from "./ActionTypes";
import { GetLegalSummaryRes } from "./Model";
import {
  getDoctorLegalSummaryAPIResClearAction,
  getDoctorLegalSummaryErrorAction,
  getDoctorLegalSummaryLoadingAction,
  getDoctorLegalSummarySuccessAction,
  getDoctorLegalSummaryAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetLegalSummaryRes;
export const getLegalSummary = () => {
  return function (dispatch: Dispatch<GetDoctorLegalSummaryActionTypes>) {
    dispatch(getDoctorLegalSummaryLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/get_legal_summary`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorLegalSummaryLoadingAction(false));
        dispatch(
          getDoctorLegalSummarySuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal summary has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorLegalSummaryLoadingAction(false));
        dispatch(
          getDoctorLegalSummaryErrorAction(
            {} as GetLegalSummaryRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorLegalSummaryAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorLegalSummaryActionTypes>) {
    dispatch(
      getDoctorLegalSummaryAPIMsgAction(apiRes as GetLegalSummaryRes, "", 0)
    );
  };
};

export const clearGetDoctorLegalSummaryAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorLegalSummaryActionTypes>) {
    dispatch(
      getDoctorLegalSummaryAPIResClearAction({} as GetLegalSummaryRes, "", 0)
    );
  };
};
