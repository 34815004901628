import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetFinalOpinionListActionTypes } from "./ActionTypes";
import { GetFinalOpinionListBody, GetFinalOpinionListRes } from "./Model";
import {
  getFinalOpinionListAPIResClearAction,
  getFinalOpinionListErrorAction,
  getFinalOpinionListLoadingAction,
  getFinalOpinionListSuccessAction,
  getFinalOpinionListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetFinalOpinionListRes;
export const getFinalOpinionDetails = (payload: GetFinalOpinionListBody) => {
  return function (dispatch: Dispatch<GetFinalOpinionListActionTypes>) {
    dispatch(getFinalOpinionListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/patientapp/api/patientMedicalData/get_patientDetailsList`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getFinalOpinionListLoadingAction(false));
        dispatch(
          getFinalOpinionListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : ""
          )
        );
      })
      .catch((error) => {
        dispatch(getFinalOpinionListLoadingAction(false));
        dispatch(
          getFinalOpinionListErrorAction(
            {} as GetFinalOpinionListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateFinalOpinionDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetFinalOpinionListActionTypes>) {
    dispatch(
      getFinalOpinionListAPIMsgAction(apiRes as GetFinalOpinionListRes, "", "", 0)
    );
  };
};

export const clearEFinalOpinionDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetFinalOpinionListActionTypes>) {
    dispatch(
      getFinalOpinionListAPIResClearAction({} as GetFinalOpinionListRes, "", "", 0)
    );
  };
};
