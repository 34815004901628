import { GetGenderListRes } from "./Model";

export const GET_GENDER_LIST_LOADING = "GET_GENDER_LIST_LOADING";
export const GET_GENDER_LIST_SUCCESS = "GET_GENDER_LIST_SUCCESS";
export const GET_GENDER_LIST_FAIL = "GET_GENDER_LIST_FAIL";
export const GET_GENDER_LIST_DATA = "GET_GENDER_LIST_DATA";
export const GET_GENDER_LIST_API_MSG = "GET_GENDER_LIST_API_MSG";
export const GET_GENDER_LIST_API_RES = "GET_GENDER_LIST_API_RES";

export interface GenderListDetails {
  getGenderListRes: GetGenderListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetGenderListActionLoading {
  type: typeof GET_GENDER_LIST_LOADING;
  loading: boolean;
}
export interface GetGenderListActionSuccess {
  type: typeof GET_GENDER_LIST_SUCCESS;
  payload: GetGenderListRes;
  successMsg: string;
}
export interface GetGenderListActionFail {
  type: typeof GET_GENDER_LIST_FAIL;
  payload: GetGenderListRes;
  errorMsg: string;
  status: number;
}
export interface GetGenderListUpdateAPIMsg {
  type: typeof GET_GENDER_LIST_API_MSG;
  payload: GetGenderListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetGenderListUpdateAPIRes {
  type: typeof GET_GENDER_LIST_API_RES;
  payload: GetGenderListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetGenderListActionTypes =
  | GetGenderListActionLoading
  | GetGenderListActionSuccess
  | GetGenderListActionFail
  | GetGenderListUpdateAPIMsg
  | GetGenderListUpdateAPIRes;
