import { CreateScheduleMeetingValue } from "./Model";
export const SET_SCHEDULE_MEETING_LOADING = "SET_SCHEDULE_MEETING_LOADING";
export const SET_SCHEDULE_MEETING_SUCCESS = "SET_SCHEDULE_MEETING_SUCCESS";
export const SET_SCHEDULE_MEETING_FAIL = "SET_SCHEDULE_MEETING_FAIL";
export const SET_SCHEDULE_MEETING_API_MSG = "SET_SCHEDULE_MEETING_API_MSG";
export const SET_SCHEDULE_MEETING_API_RES = "SET_SCHEDULE_MEETING_API_RES";

export interface ScheduleMeetingState {
  setScheduleMeetingRes: CreateScheduleMeetingValue;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ScheduleMeetingLoading {
  type: typeof SET_SCHEDULE_MEETING_LOADING;
  loading: boolean;
}
export interface ScheduleMeetingSuccess {
  type: typeof SET_SCHEDULE_MEETING_SUCCESS;
  payload: CreateScheduleMeetingValue;
  successMsg: string;
}
export interface ScheduleMeetingFail {
  type: typeof SET_SCHEDULE_MEETING_FAIL;
  payload: CreateScheduleMeetingValue;
  errorMsg: string;
  status: number;
}
export interface ScheduleMeetingAPIMsg {
  type: typeof SET_SCHEDULE_MEETING_API_MSG;
  payload: CreateScheduleMeetingValue;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ScheduleMeetingAPIRes {
  type: typeof SET_SCHEDULE_MEETING_API_RES;
  payload: CreateScheduleMeetingValue;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ScheduleMeetingActionTypes =
  | ScheduleMeetingLoading
  | ScheduleMeetingSuccess
  | ScheduleMeetingFail
  | ScheduleMeetingAPIMsg
  | ScheduleMeetingAPIRes;
