import { getAppointmentAction, getCaseAction } from "../actions/Actions";
import { Dispatch } from "redux";
import {
  GET_APPOINTMENT_LOADING,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_FAIL,
  AppointmentGetActionTypes,
  CaseGetActionTypes,
  CASE_LIST_FAIL,
  CASE_LIST_LOADING,
  CASE_LIST_SUCCESS,
} from "../types/ActionTypes";
import axios from "axios";
import apiURL from "../../URL";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import { headers } from "components/Utility";

export const getPatientCaseList = () => {
  return function (dispatch: Dispatch<CaseGetActionTypes>) {
    dispatch({
      type: CASE_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(apiURL.apiURL + "/doctorapp/api/doctorcase/doctor_case_list", {
        headers: headers,
      })
      .then((res) => {
        let resData = res.data.patientCaseList;
        dispatch(getCaseAction(resData));
        dispatch({
          type: CASE_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: CASE_LIST_FAIL,
        });
      });
  };
};

// Get Doctor task List
export const getDoctorAppointmentList = (selectionTypeBody: any) => {
  return function (dispatch: Dispatch<AppointmentGetActionTypes>) {
    dispatch({
      type: GET_APPOINTMENT_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          "/appointmentapp/api/appointment/get_appointment_list_for_doctor",
        selectionTypeBody,
        { headers }
      )
      .then((res) => {
        let resData = res.data.appointmentRequestList;
        dispatch(getAppointmentAction(resData));
        dispatch({
          type: GET_APPOINTMENT_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_APPOINTMENT_FAIL,
        });
      });
  };
};
