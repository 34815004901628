import {
  AddDrProfessionalMembershipActionTypes,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_FAIL,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_LOADING,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS,
  AddDrProfessionalMembershipState,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_API_MSG,
  ADD_DR_PROFESSIONAL_MEMBERSHIP_API_RES,
} from "./ActionTypes";
import { AddProfessionalMembershipRes } from "./Model";

const initialStateEditPosts: AddDrProfessionalMembershipState = {
  loading: false,
  addDrProfessionalMembershipRes: {} as AddProfessionalMembershipRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDrProfessionalMembershipReducer = (
  state = initialStateEditPosts,
  action: AddDrProfessionalMembershipActionTypes
): AddDrProfessionalMembershipState => {
  switch (action.type) {
    case ADD_DR_PROFESSIONAL_MEMBERSHIP_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        addDrProfessionalMembershipRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DR_PROFESSIONAL_MEMBERSHIP_FAIL:
      return {
        ...state,
        addDrProfessionalMembershipRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_PROFESSIONAL_MEMBERSHIP_API_MSG:
      return {
        ...state,
        addDrProfessionalMembershipRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_PROFESSIONAL_MEMBERSHIP_API_RES:
      return {
        ...state,
        addDrProfessionalMembershipRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
