import {
  ADD_DR_WORK_EXPERIENCE_FAIL,
  ADD_DR_WORK_EXPERIENCE_LOADING,
  ADD_DR_WORK_EXPERIENCE_SUCCESS,
  ADD_DR_WORK_EXPERIENCE_API_MSG,
  ADD_DR_WORK_EXPERIENCE_API_RES,
  AddDrWorkExperienceActionTypes,
} from "./ActionTypes";
import { AddWorkExperienceRes } from "./Model";

export const addDrWorkExperienceLoadingAction = (
  loading: boolean
): AddDrWorkExperienceActionTypes => {
  return {
    type: ADD_DR_WORK_EXPERIENCE_LOADING,
    loading: loading,
  };
};

export const addDrWorkExperienceSuccessAction = (
  addDrWorkExperienceResponse: AddWorkExperienceRes,
  successMsg: string
): AddDrWorkExperienceActionTypes => {
  return {
    type: ADD_DR_WORK_EXPERIENCE_SUCCESS,
    payload: addDrWorkExperienceResponse,
    successMsg: successMsg,
  };
};

export const addDrWorkExperienceErrorAction = (
  addDrWorkExperienceResponse: AddWorkExperienceRes,
  errMsg: string,
  status: number
): AddDrWorkExperienceActionTypes => {
  return {
    type: ADD_DR_WORK_EXPERIENCE_FAIL,
    payload: addDrWorkExperienceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDrWorkExperienceAPIMsgAction = (
  addDrWorkExperienceResponse: AddWorkExperienceRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrWorkExperienceActionTypes => {
  return {
    type: ADD_DR_WORK_EXPERIENCE_API_MSG,
    payload: addDrWorkExperienceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDrWorkExperienceAPIResClearAction = (
  addDrWorkExperienceResponse: AddWorkExperienceRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrWorkExperienceActionTypes => {
  return {
    type: ADD_DR_WORK_EXPERIENCE_API_RES,
    payload: addDrWorkExperienceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
