import {
  EDIT_PATIENT_FAIL,
  EDIT_PATIENT_LOADING,
  EDIT_PATIENT_SUCCESS,
  EDIT_PATIENT_UPDATE_API_MSG,
  EDIT_PATIENT_UPDATE_API_RES,
  EditPatientActionTypes,
} from "./ActionTypes";
import { EditPersonalDetailsRes } from "./Model";

export const editPatientLoadingAction = (
  loading: boolean
): EditPatientActionTypes => {
  return {
    type: EDIT_PATIENT_LOADING,
    loading: loading,
  };
};

export const editPatientSuccessAction = (
  editPersonalDetailsRes: EditPersonalDetailsRes,
  successMsg: string
): EditPatientActionTypes => {
  return {
    type: EDIT_PATIENT_SUCCESS,
    payload: editPersonalDetailsRes,
    successMsg: successMsg,
  };
};

export const editPatientErrorAction = (
  editPersonalDetailsRes: EditPersonalDetailsRes,
  errMsg: string,
  status: number
): EditPatientActionTypes => {
  return {
    type: EDIT_PATIENT_FAIL,
    payload: editPersonalDetailsRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const editPatientUpdateAPIMsgAction = (
  editPersonalDetailsRes: EditPersonalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditPatientActionTypes => {
  return {
    type: EDIT_PATIENT_UPDATE_API_MSG,
    payload: editPersonalDetailsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editPatientAPIResClearAction = (
  editPersonalDetailsRes: EditPersonalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditPatientActionTypes => {
  return {
    type: EDIT_PATIENT_UPDATE_API_RES,
    payload: editPersonalDetailsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

