import { AddAwardRes } from "./Model";
export const ADD_DR_AWARD_LOADING = "ADD_DR_AWARD_LOADING";
export const ADD_DR_AWARD_SUCCESS = "ADD_DR_AWARD_SUCCESS";
export const ADD_DR_AWARD_FAIL = "ADD_DR_AWARD_FAIL";
export const ADD_DR_AWARD_API_MSG = "ADD_DR_AWARD_API_MSG";
export const ADD_DR_AWARD_API_RES = "ADD_DR_AWARD_API_RES";

export interface AddDrAwardState {
  addDrAwardRes: AddAwardRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrAwardLoading {
  type: typeof ADD_DR_AWARD_LOADING;
  loading: boolean;
}
export interface AddDrAwardSuccess {
  type: typeof ADD_DR_AWARD_SUCCESS;
  payload: AddAwardRes;
  successMsg: string;
}
export interface AddDrAwardFail {
  type: typeof ADD_DR_AWARD_FAIL;
  payload: AddAwardRes;
  errorMsg: string;
  status: number;
}
export interface AddDrAwardAPIMsg {
  type: typeof ADD_DR_AWARD_API_MSG;
  payload: AddAwardRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrAwardAPIRes {
  type: typeof ADD_DR_AWARD_API_RES;
  payload: AddAwardRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrAwardActionTypes =
  | AddDrAwardLoading
  | AddDrAwardSuccess
  | AddDrAwardFail
  | AddDrAwardAPIMsg
  | AddDrAwardAPIRes;
