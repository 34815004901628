import {
    DELETE_SUMMARYLIST_SUBMIT_LIST_FAIL,
    DELETE_SUMMARYLIST_SUBMIT_LIST_LOADING,
    DELETE_SUMMARYLIST_SUBMIT_LIST_SUCCESS,
    DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG,
    DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES,
    DeleteSummaryListActionTypes,
  } from "./ActionTypes";
  import { DeleteSummaryListRes } from "./Model";
  
  export const deleteSummaryListLoadingAction = (
    loading: boolean
  ): DeleteSummaryListActionTypes => {
    return {
      type: DELETE_SUMMARYLIST_SUBMIT_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const deleteSummaryListSuccessAction = (
    deleteSummaryListResponse: DeleteSummaryListRes,
    successMsg: string
  ): DeleteSummaryListActionTypes => {
    return {
      type: DELETE_SUMMARYLIST_SUBMIT_LIST_SUCCESS,
      payload: deleteSummaryListResponse,
      successMsg: successMsg,
    };
  };
  
  export const deleteSummaryListErrorAction = (
    deleteSummaryListResponse: DeleteSummaryListRes,
    errMsg: string,
    status: number
  ): DeleteSummaryListActionTypes => {
    return {
      type: DELETE_SUMMARYLIST_SUBMIT_LIST_FAIL,
      payload: deleteSummaryListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const deleteSummaryListUpdateAPIMsgAction = (
    deleteSummaryListResponse: DeleteSummaryListRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): DeleteSummaryListActionTypes => {
    return {
      type: DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG,
      payload: deleteSummaryListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const deleteSummaryListAPIResClearAction = (
    deleteSummaryListResponse: DeleteSummaryListRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): DeleteSummaryListActionTypes => {
    return {
      type: DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES,
      payload: deleteSummaryListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  