import {
  JOINING_FEE_FAIL,
  JOINING_FEE_LOADING,
  JOINING_FEE_SUCCESS,
  JOINING_FEE_UPDATE_API_MSG,
  JOINING_FEE_UPDATE_API_RES,
  JoiningFeeActionTypes,
} from "./ActionTypes";
import { JoiningFeeRes } from "./Model";

export const joiningFeeLoadingAction = (
  loading: boolean
): JoiningFeeActionTypes => {
  return {
    type: JOINING_FEE_LOADING,
    loading: loading,
  };
};

export const joiningFeeSuccessAction = (
  joiningFeeResponse: JoiningFeeRes,
  successMsg: string
): JoiningFeeActionTypes => {
  return {
    type: JOINING_FEE_SUCCESS,
    payload: joiningFeeResponse,
    successMsg: successMsg,
  };
};

export const joiningFeeErrorAction = (
  joiningFeeResponse: JoiningFeeRes,
  errMsg: string,
  status: number
): JoiningFeeActionTypes => {
  return {
    type: JOINING_FEE_FAIL,
    payload: joiningFeeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const joiningFeeUpdateAPIMsgAction = (
  joiningFeeResponse: JoiningFeeRes,
  successMsg: string,
  errMsg: string,
  status: number
): JoiningFeeActionTypes => {
  return {
    type: JOINING_FEE_UPDATE_API_MSG,
    payload: joiningFeeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const joiningFeeAPIResClearAction = (
  joiningFeeResponse: JoiningFeeRes,
  successMsg: string,
  errMsg: string,
  status: number
): JoiningFeeActionTypes => {
  return {
    type: JOINING_FEE_UPDATE_API_RES,
    payload: joiningFeeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

