import { GetInsuranceListRes } from "./Model";
export const GET_DOCTOR_INSURANCE_LIST_LOADING =
  "GET_DOCTOR_INSURANCE_LIST_LOADING";
export const GET_DOCTOR_INSURANCE_LIST_SUCCESS =
  "GET_DOCTOR_INSURANCE_LIST_SUCCESS";
export const GET_DOCTOR_INSURANCE_LIST_FAIL = "GET_DOCTOR_INSURANCE_LIST_FAIL";
export const GET_DOCTOR_INSURANCE_LIST_API_MSG =
  "GET_DOCTOR_INSURANCE_LIST_API_MSG";
export const GET_DOCTOR_INSURANCE_LIST_API_RES =
  "GET_DOCTOR_INSURANCE_LIST_API_RES";

export interface GetDoctorInsuranceListState {
  getDoctorInsuranceListRes: GetInsuranceListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorInsuranceListLoading {
  type: typeof GET_DOCTOR_INSURANCE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorInsuranceListSuccess {
  type: typeof GET_DOCTOR_INSURANCE_LIST_SUCCESS;
  payload: GetInsuranceListRes;
  successMsg: string;
}
export interface GetDoctorInsuranceListFail {
  type: typeof GET_DOCTOR_INSURANCE_LIST_FAIL;
  payload: GetInsuranceListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorInsuranceListAPIMsg {
  type: typeof GET_DOCTOR_INSURANCE_LIST_API_MSG;
  payload: GetInsuranceListRes;
  successMsg: string
  errorMsg: string;
  status: number;
}
export interface GetDoctorInsuranceListAPIRes {
  type: typeof GET_DOCTOR_INSURANCE_LIST_API_RES;
  payload: GetInsuranceListRes;
  successMsg: string
  errorMsg: string;
  status: number;
}

export type GetDoctorInsuranceListActionTypes =
  | GetDoctorInsuranceListLoading
  | GetDoctorInsuranceListSuccess
  | GetDoctorInsuranceListFail
  | GetDoctorInsuranceListAPIMsg
  | GetDoctorInsuranceListAPIRes;
