import {
  GET_DOCTOR_AGREEMENT_TYPE_LIST_FAIL,
  GET_DOCTOR_AGREEMENT_TYPE_LIST_LOADING,
  GET_DOCTOR_AGREEMENT_TYPE_LIST_SUCCESS,
  GET_DOCTOR_AGREEMENT_TYPE_LIST_API_MSG,
  GET_DOCTOR_AGREEMENT_TYPE_LIST_API_RES,
  GetDoctorAgreementTypeListActionTypes,
} from "./ActionTypes";
import { GetAgreementTypeListRes } from "./Model";

export const getDoctorAgreementTypeListLoadingAction = (
  loading: boolean
): GetDoctorAgreementTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_TYPE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorAgreementTypeListSuccessAction = (
  getDoctorAgreementTypeListResponse: GetAgreementTypeListRes,
  successMsg: string
): GetDoctorAgreementTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_TYPE_LIST_SUCCESS,
    payload: getDoctorAgreementTypeListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorAgreementTypeListErrorAction = (
  getDoctorAgreementTypeListResponse: GetAgreementTypeListRes,
  errMsg: string,
  status: number
): GetDoctorAgreementTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_TYPE_LIST_FAIL,
    payload: getDoctorAgreementTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorAgreementTypeListAPIMsgAction = (
  getDoctorAgreementTypeListResponse: GetAgreementTypeListRes,
  errMsg: string,
  status: number
): GetDoctorAgreementTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_TYPE_LIST_API_MSG,
    payload: getDoctorAgreementTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorAgreementTypeListAPIResClearAction = (
  getDoctorAgreementTypeListResponse: GetAgreementTypeListRes,
  errMsg: string,
  status: number
): GetDoctorAgreementTypeListActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_TYPE_LIST_API_RES,
    payload: getDoctorAgreementTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
