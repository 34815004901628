import { EditEducationDetailsRes } from "./Model";
export const EDIT_DOCTOR_EDUCATION_DETAILS_LOADING =
  "EDIT_DOCTOR_EDUCATION_DETAILS_LOADING";
export const EDIT_DOCTOR_EDUCATION_DETAILS_SUCCESS =
  "EDIT_DOCTOR_EDUCATION_DETAILS_SUCCESS";
export const EDIT_DOCTOR_EDUCATION_DETAILS_FAIL =
  "EDIT_DOCTOR_EDUCATION_DETAILS_FAIL";
export const EDIT_DOCTOR_EDUCATION_DETAILS_API_MSG =
  "EDIT_DOCTOR_EDUCATION_DETAILS_API_MSG";
export const EDIT_DOCTOR_EDUCATION_DETAILS_API_RES =
  "EDIT_DOCTOR_EDUCATION_DETAILS_API_RES";

export interface AddDoctorEducationDetailsState {
  editDoctorEducationDetailsRes: EditEducationDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorEducationDetailsLoading {
  type: typeof EDIT_DOCTOR_EDUCATION_DETAILS_LOADING;
  loading: boolean;
}
export interface AddDoctorEducationDetailsSuccess {
  type: typeof EDIT_DOCTOR_EDUCATION_DETAILS_SUCCESS;
  payload: EditEducationDetailsRes;
  successMsg: string;
}
export interface AddDoctorEducationDetailsFail {
  type: typeof EDIT_DOCTOR_EDUCATION_DETAILS_FAIL;
  payload: EditEducationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddDoctorEducationDetailsAPIMsg {
  type: typeof EDIT_DOCTOR_EDUCATION_DETAILS_API_MSG;
  payload: EditEducationDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorEducationDetailsAPIRes {
  type: typeof EDIT_DOCTOR_EDUCATION_DETAILS_API_RES;
  payload: EditEducationDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDoctorEducationDetailsActionTypes =
  | AddDoctorEducationDetailsLoading
  | AddDoctorEducationDetailsSuccess
  | AddDoctorEducationDetailsFail
  | AddDoctorEducationDetailsAPIMsg
  | AddDoctorEducationDetailsAPIRes;
