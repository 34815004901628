import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorCoverLetterActionTypes } from "./ActionTypes";
import { GetCoverLetterRes } from "./Model";
import {
  getDoctorCoverLetterAPIResClearAction,
  getDoctorCoverLetterErrorAction,
  getDoctorCoverLetterLoadingAction,
  getDoctorCoverLetterSuccessAction,
  getDoctorCoverLetterAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetCoverLetterRes;
export const getCoverLetter = () => {
  return function (dispatch: Dispatch<GetDoctorCoverLetterActionTypes>) {
    dispatch(getDoctorCoverLetterLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/get_cover_letter`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorCoverLetterLoadingAction(false));
        dispatch(
          getDoctorCoverLetterSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. cover letter has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorCoverLetterLoadingAction(false));
        dispatch(
          getDoctorCoverLetterErrorAction(
            {} as GetCoverLetterRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorCoverLetterAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorCoverLetterActionTypes>) {
    dispatch(
      getDoctorCoverLetterAPIMsgAction(apiRes as GetCoverLetterRes, "", 0)
    );
  };
};

export const clearGetDoctorEducationListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorCoverLetterActionTypes>) {
    dispatch(
      getDoctorCoverLetterAPIResClearAction({} as GetCoverLetterRes, "", 0)
    );
  };
};
