import { getPaymentLinkRes } from "./Model";

export const PAYMENT_LIST_LOADING =
  "PAYMENT_LIST_LOADING";
export const PAYMENT_LIST_SUCCESS =
  "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL =
  "PAYMENT_LIST_FAIL";
export const PAYMENT_LIST_TYPE =
  "PAYMENT_LIST_TYPE";

export interface PaymentListStateType {
  getPaymentLinkRes: getPaymentLinkRes;
  loading: boolean;
  errRes: string;
}
export interface PaymentListLoading {
  type: typeof PAYMENT_LIST_LOADING;
}
export interface PaymentListSuccess {
  type: typeof PAYMENT_LIST_SUCCESS;
  payload: getPaymentLinkRes;
  errRes: string;
}
export interface PaymentListFail {
  type: typeof PAYMENT_LIST_FAIL;
  payload: getPaymentLinkRes;
  errRes: string;
}
interface PaymentListAction {
  type: typeof PAYMENT_LIST_TYPE;
  payload: getPaymentLinkRes;
  errRes: string;
}

export type PaymentListActionTypes =
  | PaymentListAction
  | PaymentListLoading
  | PaymentListSuccess
  | PaymentListFail;

