import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { setupInterceptorsTo } from "../../redux/authentication/Interceptors";
import {
  LogOutActionTypes,
  LOGOUT_FAIL,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
} from "./ActionTypes";
import { LogOutBody, LogOutResponse } from "./Model";
import { headers } from "components/Utility";

export const logOut = (body: LogOutBody) => {
  return function (dispatch: Dispatch<LogOutActionTypes>) {
    dispatch({
      type: LOGOUT_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL + `/api/logout/`,body,
        { headers: headers }
      )
      .then((res) => {
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: res.data,
          errRes: "",
        });
        localStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        dispatch({
          type: LOGOUT_FAIL,
          payload: {} as LogOutResponse,
          errRes: error.response.data.message,
        });
        localStorage.clear();
        window.location.reload();
      });
  };
};
