import {
  ADD_PATIENT_DATA_FAIL,
  ADD_PATIENT_DATA_LOADING,
  ADD_PATIENT_DATA_SUCCESS,
  ADD_PATIENT_DATA_API_MSG,
  ADD_PATIENT_DATA_API_RES,
  AddPatientDataActionTypes,
} from "./ActionTypes";
import { AddPatientDataRes } from "./Model";

export const addPatientDataLoadingAction = (
  loading: boolean
): AddPatientDataActionTypes => {
  return {
    type: ADD_PATIENT_DATA_LOADING,
    loading: loading,
  };
};

export const addPatientDataSuccessAction = (
  addPatientDataResponse: AddPatientDataRes,
  successMsg: string
): AddPatientDataActionTypes => {
  return {
    type: ADD_PATIENT_DATA_SUCCESS,
    payload: addPatientDataResponse,
    successMsg: successMsg,
  };
};

export const addPatientDataErrorAction = (
  addPatientDataResponse: AddPatientDataRes,
  errMsg: string,
  status: number
): AddPatientDataActionTypes => {
  return {
    type: ADD_PATIENT_DATA_FAIL,
    payload: addPatientDataResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addPatientDataUpdateAPIMsgAction = (
  addPatientDataResponse: AddPatientDataRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientDataActionTypes => {
  return {
    type: ADD_PATIENT_DATA_API_MSG,
    payload: addPatientDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addPatientDataUpdateAPIResClearAction = (
  addPatientDataResponse: AddPatientDataRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientDataActionTypes => {
  return {
    type: ADD_PATIENT_DATA_API_RES,
    payload: addPatientDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
