import { GetAwardListRes } from "./Model";
export const GET_DOCTOR_AWARD_LIST_LOADING = "GET_DOCTOR_AWARD_LIST_LOADING";
export const GET_DOCTOR_AWARD_LIST_SUCCESS = "GET_DOCTOR_AWARD_LIST_SUCCESS";
export const GET_DOCTOR_AWARD_LIST_FAIL = "GET_DOCTOR_AWARD_LIST_FAIL";
export const GET_DOCTOR_AWARD_LIST_API_MSG = "GET_DOCTOR_AWARD_LIST_API_MSG";
export const GET_DOCTOR_AWARD_LIST_API_RES = "GET_DOCTOR_AWARD_LIST_API_RES";

export interface GetDoctorAwardListState {
  getDoctorAwardListRes: GetAwardListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAwardListLoading {
  type: typeof GET_DOCTOR_AWARD_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorAwardListSuccess {
  type: typeof GET_DOCTOR_AWARD_LIST_SUCCESS;
  payload: GetAwardListRes;
  successMsg: string;
}
export interface GetDoctorAwardListFail {
  type: typeof GET_DOCTOR_AWARD_LIST_FAIL;
  payload: GetAwardListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAwardListAPIMsg {
  type: typeof GET_DOCTOR_AWARD_LIST_API_MSG;
  payload: GetAwardListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorAwardListAPIRes {
  type: typeof GET_DOCTOR_AWARD_LIST_API_RES;
  payload: GetAwardListRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorAwardListActionTypes =
  | GetDoctorAwardListLoading
  | GetDoctorAwardListSuccess
  | GetDoctorAwardListFail
  | GetDoctorAwardListAPIMsg
  | GetDoctorAwardListAPIRes;
