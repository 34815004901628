import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import {
  getTelehealthListActionTypes,
  TELEHEALTH_DETAIL_LIST_FAIL,
  TELEHEALTH_DETAIL_LIST_LOADING,
  TELEHEALTH_DETAIL_LIST_SUCCESS,
} from "./ActionTypes";
import { getTelehealthListDetails, GetTelehealthgListValue } from "./Model";
import { headers } from "components/Utility";

export const getTelehealthListApi = (body: getTelehealthListDetails) => {
  return function (dispatch: Dispatch<getTelehealthListActionTypes>) {
    dispatch({
      type: TELEHEALTH_DETAIL_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          `/telehealthapp/api/telehealth/getTelehealth_credential`,
        body,
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch({
          type: TELEHEALTH_DETAIL_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: TELEHEALTH_DETAIL_LIST_FAIL,
          payload: {} as GetTelehealthgListValue,
          errRes: error.response.data.message,
        });
      });
  };
};
