import {
  GET_DOCTOR_PROFESSIONAL_SUMMARY_FAIL,
  GET_DOCTOR_PROFESSIONAL_SUMMARY_LOADING,
  GET_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS,
  GET_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG,
  GET_DOCTOR_PROFESSIONAL_SUMMARY_API_RES,
  GetDoctorProfessionalSummaryActionTypes,
} from "./ActionTypes";
import { GetProfessionalSummaryRes } from "./Model";

export const getDoctorProfessionalSummaryLoadingAction = (
  loading: boolean
): GetDoctorProfessionalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_PROFESSIONAL_SUMMARY_LOADING,
    loading: loading,
  };
};

export const getDoctorProfessionalSummarySuccessAction = (
  getDoctorProfessionalSummaryResponse: GetProfessionalSummaryRes,
  successMsg: string
): GetDoctorProfessionalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS,
    payload: getDoctorProfessionalSummaryResponse,
    successMsg: successMsg,
  };
};

export const getDoctorProfessionalSummaryErrorAction = (
  getDoctorProfessionalSummaryResponse: GetProfessionalSummaryRes,
  errMsg: string,
  status: number
): GetDoctorProfessionalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_PROFESSIONAL_SUMMARY_FAIL,
    payload: getDoctorProfessionalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorProfessionalSummaryAPIMsgAction = (
  getDoctorProfessionalSummaryResponse: GetProfessionalSummaryRes,
  errMsg: string,
  status: number
): GetDoctorProfessionalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG,
    payload: getDoctorProfessionalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorProfessionalSummaryAPIResClearAction = (
  getDoctorProfessionalSummaryResponse: GetProfessionalSummaryRes,
  errMsg: string,
  status: number
): GetDoctorProfessionalSummaryActionTypes => {
  return {
    type: GET_DOCTOR_PROFESSIONAL_SUMMARY_API_RES,
    payload: getDoctorProfessionalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
