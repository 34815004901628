import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorAwardTypeListActionTypes } from "./ActionTypes";
import { GetAwardTypeListRes } from "./Model";
import {
  getDoctorAwardTypeListAPIResClearAction,
  getDoctorAwardTypeListErrorAction,
  getDoctorAwardTypeListLoadingAction,
  getDoctorAwardTypeListSuccessAction,
  getDoctorAwardTypeListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetAwardTypeListRes;
export const getAwardTypeList = () => {
  return function (dispatch: Dispatch<GetDoctorAwardTypeListActionTypes>) {
    dispatch(getDoctorAwardTypeListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL + `/doctorapp/api/onboarding/award_type_list`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorAwardTypeListLoadingAction(false));
        dispatch(
          getDoctorAwardTypeListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. award type list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorAwardTypeListLoadingAction(false));
        dispatch(
          getDoctorAwardTypeListErrorAction(
            {} as GetAwardTypeListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorAwardTypeListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorAwardTypeListActionTypes>) {
    dispatch(getDoctorAwardTypeListAPIMsgAction(apiRes as GetAwardTypeListRes, "", 0));
  };
};

export const clearGetDoctorAwardTypeListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorAwardTypeListActionTypes>) {
    dispatch(getDoctorAwardTypeListAPIResClearAction({} as GetAwardTypeListRes, "", 0));
  };
};
