import {
  AddDrIdProofActionTypes,
  ADD_DR_ID_PROOF_FAIL,
  ADD_DR_ID_PROOF_LOADING,
  ADD_DR_ID_PROOF_SUCCESS,
  AddDrIdProofState,
  ADD_DR_ID_PROOF_API_MSG,
  ADD_DR_ID_PROOF_API_RES,
} from "./ActionTypes";
import { AddDrIdProofRes } from "./Model";

const initialStateEditPosts: AddDrIdProofState = {
  loading: false,
  addDrIdProofRes: {} as AddDrIdProofRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDrIdProofReducer = (
  state = initialStateEditPosts,
  action: AddDrIdProofActionTypes
): AddDrIdProofState => {
  switch (action.type) {
    case ADD_DR_ID_PROOF_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DR_ID_PROOF_SUCCESS:
      return {
        ...state,
        addDrIdProofRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DR_ID_PROOF_FAIL:
      return {
        ...state,
        addDrIdProofRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_ID_PROOF_API_MSG:
      return {
        ...state,
        addDrIdProofRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_ID_PROOF_API_RES:
      return {
        ...state,
        addDrIdProofRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
