import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { ResetPasswordBody } from "redux/resetPassword/Model";
import {
  clearResetPasswordAPIRes,
  resetPasswordAPI,
} from "redux/resetPassword/API";
import { Transition } from "components/MWDialogTransition";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface Props {
  isEnable: boolean | any;
  dialogUpdateState: boolean | any;
}
export default function ResetPassword({ isEnable, dialogUpdateState }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);
  interface oldPasswordState {
    oldPassword: string;
    showPassword: boolean;
  }
  const [errorsupportold, setErrorOldpassword] = useState("");
  const [errorsupportnew, setErrorNewpassword] = useState("");
  const [oldPassword, setOldPassword] = React.useState<oldPasswordState>({
    oldPassword: "",
    showPassword: false,
  });

  const handleOldPasswordChange =
    (prop: keyof oldPasswordState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOldPassword({ ...oldPassword, [prop]: event.target.value });
    };

  const handleOldPasswordClickShowPassword = () => {
    setOldPassword({ ...oldPassword, showPassword: !oldPassword.showPassword });
  };
  const handleOldMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // New Password Field
  interface NewPasswordState {
    newPassword: string;
    showNewPassword: boolean;
  }

  const [newPassword, setNewPassword] = React.useState<NewPasswordState>({
    newPassword: "",
    showNewPassword: false,
  });

  const handleNewPasswordChange =
    (prop: keyof NewPasswordState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword({ ...newPassword, [prop]: event.target.value });
    };

  const handleNewPasswordClickShowPassword = () => {
    setNewPassword({
      ...newPassword,
      showNewPassword: !newPassword.showNewPassword,
    });
  };

  const handleNewPasswordMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
  };
  useEffect(() => {
    setActiveResetPasswordDialog(isEnable);
  }, [isEnable]);

  const errorSupportold = errorsupportold ? (
    <Typography>{errorsupportold}</Typography>
  ) : (
    ""
  );

  const errorSupportnew = errorsupportnew ? (
    <Typography>{errorsupportnew}</Typography>
  ) : (
    ""
  );

  const resetbody = {
    old_password: oldPassword.oldPassword,
    new_password: newPassword.newPassword,
  } as ResetPasswordBody;

  const resetPasswordAPICall = () => {
    if (oldPassword.oldPassword === "") {
      setErrorOldpassword("Please enter old password");
    } else if (newPassword.newPassword === "") {
      setErrorNewpassword("Please enter new password");
    } else {
      setErrorOldpassword("");
      setErrorNewpassword("");
      dispatch(resetPasswordAPI(resetbody));
    }
  };

  const [resetPassword, setResetPasswordMessage] = useState("");
  const [resetPasswordType, setResetPasswordMessageType] =
    useState<AlertColor>("success");

  const resetPasswordResponse = useSelector(
    (state: AppState) => state.resetPassword
  );
  useEffect(() => {
    // setOTPGenLoading(resetPasswordResponse?.loading);
    if (resetPasswordResponse?.successMsg !== "") {
      setResetPasswordMessage(resetPasswordResponse?.successMsg);
      setResetPasswordMessageType("success");
    }
    if (resetPasswordResponse?.errorMsg !== "") {
      setResetPasswordMessage(resetPasswordResponse.errorMsg);
      setResetPasswordMessageType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordResponse]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>{t("accountdetail.resetpassword", { ns: ["home"] })}</Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
          pt={2}
        >
          <Typography>
            {t("accountdetail.oldpassword", { ns: ["home"] })!}
          </Typography>
          <Box pt={1}>
            <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
            <OutlinedInput
              id="oldpassword"
              placeholder={t("accountdetail.enteroldpass", { ns: ["home"] })!}
              type={
                oldPassword.showPassword
                  ? `${t("home.text", { ns: ["home"] })!}`
                  : `${t("password", { ns: ["home"] })!}`
              }
              value={oldPassword.oldPassword}
              onChange={handleOldPasswordChange("oldPassword")}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleOldPasswordClickShowPassword}
                    onMouseDown={handleOldMouseDownPassword}
                    edge="end"
                  >
                    {oldPassword.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Typography style={{ color: "#d50000" }}>
            {errorSupportold}
          </Typography>
          <Typography>
            {t("accountdetail.newpassword", { ns: ["home"] })!}
          </Typography>
          <Box pt={1}>
            {/* <ThemeProvider theme={theme}> */}
            <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
            <OutlinedInput
              id="newpassword"
              placeholder="Enter your password"
              type={
                newPassword.showNewPassword
                  ? `${t("home.text", { ns: ["home"] })!}`
                  : `${t("password", { ns: ["home"] })!}`
              }
              value={newPassword.newPassword}
              onChange={handleNewPasswordChange("newPassword")}
              fullWidth
              // classes={outlinedInputClasses}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleNewPasswordClickShowPassword}
                    onMouseDown={handleNewPasswordMouseDownPassword}
                    edge="end"
                  >
                    {newPassword.showNewPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Typography style={{ color: "#d50000" }}>
            {errorSupportnew}
          </Typography>
        </Stack>
        {resetPassword !== "" ? (
          <Box my={2}>
            <Alert
              severity={resetPasswordType}
              onClose={() => {
                dispatch(clearResetPasswordAPIRes());
                setResetPasswordMessage("");
              }}
            >
              {resetPassword}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("accountdetail.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={resetPasswordResponse?.loading}
              onClick={() => resetPasswordAPICall()}
              sx={{ textTransform: "none" }}
            >
              {t("accountdetail.send", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
