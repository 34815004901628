import { GetFinalOpinionListRes } from "./Model";
export const GET_FINAL_OPINION_LIST_LOADING = "GET_FINAL_OPINION_LIST_LOADING";
export const GET_FINAL_OPINION_LIST_SUCCESS = "GET_FINAL_OPINION_LIST_SUCCESS";
export const GET_FINAL_OPINION_LIST_FAIL = "GET_FINAL_OPINION_LIST_FAIL";
export const GET_FINAL_OPINION_LIST_API_MSG = "GET_FINAL_OPINION_LIST_API_MSG";
export const GET_FINAL_OPINION_LIST_API_RES = "GET_FINAL_OPINION_LIST_API_RES";

export interface GetFinalOpinionListState {
  getFinalOpinionListRes: GetFinalOpinionListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetFinalOpinionListLoading {
  type: typeof GET_FINAL_OPINION_LIST_LOADING;
  loading: boolean;
}
export interface GetFinalOpinionListSuccess {
  type: typeof GET_FINAL_OPINION_LIST_SUCCESS;
  payload: GetFinalOpinionListRes;
  successMsg: string;
}
export interface GetFinalOpinionListFail {
  type: typeof GET_FINAL_OPINION_LIST_FAIL;
  payload: GetFinalOpinionListRes;
  errorMsg: string;
  status: number;
}
export interface GetFinalOpinionListAPIMsg {
  type: typeof GET_FINAL_OPINION_LIST_API_MSG;
  payload: GetFinalOpinionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetFinalOpinionListAPIRes {
  type: typeof GET_FINAL_OPINION_LIST_API_RES;
  payload: GetFinalOpinionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetFinalOpinionListActionTypes =
  | GetFinalOpinionListLoading
  | GetFinalOpinionListSuccess
  | GetFinalOpinionListFail
  | GetFinalOpinionListAPIMsg
  | GetFinalOpinionListAPIRes;
