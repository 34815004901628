import { LoginRes } from "./Model";
export const JWT_LOGIN_LOADING = "JWT_LOGIN_LOADING";
export const JWT_LOGIN_SUCCESS = "JWT_LOGIN_SUCCESS";
export const JWT_LOGIN_FAIL = "JWT_LOGIN_FAIL";
export const JWT_LOGIN_UPDATE_API_MSG = "JWT_LOGIN_UPDATE_API_MSG";
export const JWT_LOGIN_UPDATE_API_RES = "JWT_LOGIN_UPDATE_API_RES";

export interface LoginState {
  loginRes: LoginRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface LoginLoading {
  type: typeof JWT_LOGIN_LOADING;
  loading: boolean;
}
export interface LoginSuccess {
  type: typeof JWT_LOGIN_SUCCESS;
  payload: LoginRes;
  successMsg: string;
}
export interface LoginFail {
  type: typeof JWT_LOGIN_FAIL;
  payload: LoginRes;
  errorMsg: string;
  status: number;
}
export interface LoginUpdateAPIMsg {
  type: typeof JWT_LOGIN_UPDATE_API_MSG;
  payload: LoginRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface LoginUpdateAPIRes {
  type: typeof JWT_LOGIN_UPDATE_API_RES;
  payload: LoginRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type JwtTokenActionTypes =
  | LoginLoading
  | LoginSuccess
  | LoginFail
  | LoginUpdateAPIMsg
  | LoginUpdateAPIRes;
