import {
  DOCTOR_LIST_FOR_CASE_FAIL,
  DOCTOR_LIST_FOR_CASE_LOADING,
  DOCTOR_LIST_FOR_CASE_SUCCESS,
  DOCTOR_LIST_FOR_CASE_API_MSG,
  DOCTOR_LIST_FOR_CASE_API_RES,
  DoctorListForCaseActionTypes,
} from "./ActionTypes";
import { DoctorListForCaseRes } from "./Model";

export const DoctorListForCaseLoadingAction = (
  loading: boolean
): DoctorListForCaseActionTypes => {
  return {
    type: DOCTOR_LIST_FOR_CASE_LOADING,
    loading: loading,
  };
};

export const DoctorListForCaseSuccessAction = (
  doctorListForPatientCaseResponse: DoctorListForCaseRes,
  successMsg: string
): DoctorListForCaseActionTypes => {
  return {
    type: DOCTOR_LIST_FOR_CASE_SUCCESS,
    payload: doctorListForPatientCaseResponse,
    successMsg: successMsg,
  };
};

export const DoctorListForCaseErrorAction = (
  doctorListForPatientCaseResponse: DoctorListForCaseRes,
  errMsg: string,
  status: number
): DoctorListForCaseActionTypes => {
  return {
    type: DOCTOR_LIST_FOR_CASE_FAIL,
    payload: doctorListForPatientCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const DoctorListForCaseAPIMsgAction = (
  doctorListForPatientCaseResponse: DoctorListForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorListForCaseActionTypes => {
  return {
    type: DOCTOR_LIST_FOR_CASE_API_MSG,
    payload: doctorListForPatientCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const DoctorListForCaseAPIResClearAction = (
  doctorListForPatientCaseResponse: DoctorListForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorListForCaseActionTypes => {
  return {
    type: DOCTOR_LIST_FOR_CASE_API_RES,
    payload: doctorListForPatientCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
