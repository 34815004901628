import { AppointmentList, CaseList } from "../interfaces/Model";

// API call state
export const GET_CASE_LIST = "GET_CASE_LIST";
export const CASE_LIST_LOADING = "CASE_LIST_LOADING";
export const CASE_LIST_SUCCESS = "CASE_LIST_SUCCESS";
export const CASE_LIST_FAIL = "CASE_LIST_FAIL";

// Appointment List types
export const GET_APPOINTMENT_LIST = "GET_APPOINTMENT_LIST";
export const GET_APPOINTMENT_LOADING = "GET_APPOINTMENT_LOADING";
export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_FAIL = "GET_APPOINTMENT_FAIL";

export interface CaseListLoading {
  type: typeof CASE_LIST_LOADING;
}
export interface CaseListSuccess {
  type: typeof CASE_LIST_SUCCESS;
  payload: CaseList[];
}
export interface CaseListFail {
  type: typeof CASE_LIST_FAIL;
}

export interface GetCaseStateType {
  caseValue: CaseList[];
  loading: boolean;
}
// Case Loading State
interface GetCaseActionType {
  type: typeof GET_CASE_LIST;
  payload: CaseList[];
}

// Appointment List Interfaces
export interface AppointmentLoading {
  type: typeof GET_APPOINTMENT_LOADING;
}
export interface AppointmentSuccess {
  type: typeof GET_APPOINTMENT_SUCCESS;
  payload: AppointmentList[];
}
export interface AppointmentFail {
  type: typeof GET_APPOINTMENT_FAIL;
}

export interface GetAppointmentStateType {
  appointmentValue: AppointmentList[];
  loading: boolean;
}

interface GetAppointmentActionType {
  type: typeof GET_APPOINTMENT_LIST;
  payload: AppointmentList[];
}

export type CaseGetActionTypes =
  | GetCaseActionType
  | CaseListLoading
  | CaseListSuccess
  | CaseListFail;
export type AppointmentGetActionTypes =
  | GetAppointmentActionType
  | AppointmentLoading
  | AppointmentSuccess
  | AppointmentFail;
