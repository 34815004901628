import {
  EDIT_PATIENT_IMPORT_CONFIG_DATA_FAIL,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_LOADING,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_SUCCESS,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_MSG,
  EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_RES,
  EditPatientImportConfigDataActionTypes,
} from "./ActionTypes";
import { EditPatientImportConfigValueRes } from "./Model";

export const editPatientImportConfigDataLoadingAction = (
  loading: boolean
): EditPatientImportConfigDataActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_DATA_LOADING,
    loading: loading,
  };
};

export const editPatientImportConfigDataSuccessAction = (
  editPatientImportConfigDataResponse: EditPatientImportConfigValueRes,
  successMsg: string
): EditPatientImportConfigDataActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_DATA_SUCCESS,
    payload: editPatientImportConfigDataResponse,
    successMsg: successMsg,
  };
};

export const editPatientImportConfigDataErrorAction = (
  editPatientImportConfigDataResponse: EditPatientImportConfigValueRes,
  errMsg: string,
  status: number
): EditPatientImportConfigDataActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_DATA_FAIL,
    payload: editPatientImportConfigDataResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editPatientImportConfigDataUpdateAPIMsgAction = (
  editPatientImportConfigDataResponse: EditPatientImportConfigValueRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditPatientImportConfigDataActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_MSG,
    payload: editPatientImportConfigDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const clearEditPatientImportConfigDataAPIRes = (
  editPatientImportConfigDataResponse: EditPatientImportConfigValueRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditPatientImportConfigDataActionTypes => {
  return {
    type: EDIT_PATIENT_IMPORT_CONFIG_DATA_UPDATE_API_RES,
    payload: editPatientImportConfigDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
