import { EditLegalDetailsRes } from "./Model";
export const EDIT_DR_LEGAL_DETAILS_LOADING = "EDIT_DR_LEGAL_DETAILS_LOADING";
export const EDIT_DR_LEGAL_DETAILS_SUCCESS = "EDIT_DR_LEGAL_DETAILS_SUCCESS";
export const EDIT_DR_LEGAL_DETAILS_FAIL = "EDIT_DR_LEGAL_DETAILS_FAIL";
export const EDIT_DR_LEGAL_DETAILS_API_MSG = "EDIT_DR_LEGAL_DETAILS_API_MSG";
export const EDIT_DR_LEGAL_DETAILS_API_RES = "EDIT_DR_LEGAL_DETAILS_API_RES";

export interface EditDrLegalDetailsState {
  editDrLegalDetailsRes: EditLegalDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrLegalDetailsLoading {
  type: typeof EDIT_DR_LEGAL_DETAILS_LOADING;
  loading: boolean;
}
export interface EditDrLegalDetailsSuccess {
  type: typeof EDIT_DR_LEGAL_DETAILS_SUCCESS;
  payload: EditLegalDetailsRes;
  successMsg: string;
}
export interface EditDrLegalDetailsFail {
  type: typeof EDIT_DR_LEGAL_DETAILS_FAIL;
  payload: EditLegalDetailsRes;
  errorMsg: string;
  status: number;
}
export interface EditDrLegalDetailsAPIMsg {
  type: typeof EDIT_DR_LEGAL_DETAILS_API_MSG;
  payload: EditLegalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrLegalDetailsAPIRes {
  type: typeof EDIT_DR_LEGAL_DETAILS_API_RES;
  payload: EditLegalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDrLegalDetailsActionTypes =
  | EditDrLegalDetailsLoading
  | EditDrLegalDetailsSuccess
  | EditDrLegalDetailsFail
  | EditDrLegalDetailsAPIMsg
  | EditDrLegalDetailsAPIRes;
