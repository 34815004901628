import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { SubmitQuestionBody } from "redux/PatientDoctorInteraction/SubmitAnswer/Model";
import {
  clearQuestionAPIRes,
  submitQuestionApi,
} from "redux/PatientDoctorInteraction/SubmitAnswer/API";
import { useParams } from "react-router-dom";
import { questionListApi } from "redux/PatientDoctorInteraction/QuestionList/API";
import { QuestionLisyBody } from "redux/PatientDoctorInteraction/QuestionList/Model";
import { AskQuestionBody } from "redux/PatientDoctorInteraction/AskQuestion/Model";
import {
  askQuestionApi,
  clearAskQuestionAPIRes,
} from "redux/PatientDoctorInteraction/AskQuestion/API";
import { AppState } from "redux/store/Store";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import MWLoader from "components/MWLoader";

interface Props {
  dialogStateValue: any;
  closeDialogStateValue: any;
  questionObj: any;
  questionObjStateValue: any;
}

export default function QuestionDialog({
  dialogStateValue,
  closeDialogStateValue,
  questionObj,
  questionObjStateValue
}: Props) {
  const dispatch = useDispatch();
  const [questionDescription, setQuestionDescription] = useState("");

  const { caseid } = useParams() as {
    caseid: string;
  };

  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;

  const [askMsg, setAskMsg] = useState("");
  const [msgType, setMsgType] = useState<AlertColor>("success");

  const [submitMsg, setSubmitMsg] = useState("");
  const [submitMsgType, setSubmitMsgType] = useState<AlertColor>("success");

  const askQuestionValue = useSelector(
    (state: AppState) => state.addAskQuestion
  );
  const submitQuestionValue = useSelector(
    (state: AppState) => state.answerQuestion
  );

  const getQuestionListBody = {
    caseId: caseid,
    doctorId: String(DoctorID),
  } as QuestionLisyBody;

  const submitAnsBody = {
    caseId: caseid,
    answerText: questionDescription,
    type: "doctor",
    questionId: questionObj?.id,
  } as SubmitQuestionBody;

  const askquestionBody = {
    caseId: caseid,
    doctorId: String(DoctorID),
    questionText: questionDescription,
    questionType: "DoctorToPatient",
  } as AskQuestionBody;

  // Question Submit API
  const questionSubmit = () => {
    if (questionObj?.id !== undefined) {
      dispatch(submitQuestionApi(submitAnsBody));
    } else {
      dispatch(askQuestionApi(askquestionBody));
    }
  };

  const closeDialog = () => {
    closeDialogStateValue(false);
    questionObjStateValue({});
    setQuestionDescription("");
    dispatch(clearAskQuestionAPIRes());
    dispatch(clearQuestionAPIRes());
    setAskMsg("");
    setSubmitMsg("");
  };

  useEffect(() => {
    if (askQuestionValue?.successMsg !== "") {
      setMsgType("success");
      setAskMsg(askQuestionValue?.successMsg);
      dispatch(questionListApi(getQuestionListBody));
      closeDialog();
    }
    if (askQuestionValue?.errorMsg !== "") {
      setMsgType("error");
      setAskMsg(askQuestionValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askQuestionValue]);

  useEffect(() => {
    if (submitQuestionValue?.successMsg !== "") {
      setSubmitMsgType("success");
      setSubmitMsg(submitQuestionValue?.successMsg);
      dispatch(questionListApi(getQuestionListBody));
      closeDialog();
    }
    if (submitQuestionValue?.errorMsg !== "") {
      setSubmitMsgType("error");
      setSubmitMsg(submitQuestionValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitQuestionValue]);

  // Question Modal
  const questionModalBody = (
    <Dialog fullWidth open={dialogStateValue} onClose={closeDialog}>
      {askQuestionValue?.loading ||
        (submitQuestionValue?.loading && <MWLoader />)}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h6">
              {questionObj.disabled
                ? `${t("docpatientintpage.viewans", { ns: ["home"] })}`
                : questionObj.id !== undefined
                ? `${t("docpatientintpage.addAns", { ns: ["home"] })}`
                : `${t("appointmentpage.askquestion", { ns: ["home"] })}`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item>
            <Divider />
          </Grid>
          {questionObj?.id !== undefined ? (
            <>
              <Grid item>
                <Typography>
                  Question:{" "}
                  <b>
                    {questionObj?.id !== undefined
                      ? questionObj?.questionText
                      : ""}
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                {" "}
                <Divider />
              </Grid>
            </>
          ) : null}

          <Grid item>
            <TextField
              label={
                questionObj?.id !== undefined
                  ? `${t("docpatientintpage.ans", { ns: ["home"] })}`
                  : `${t("appointmentpage.ques", { ns: ["home"] })}`
              }
              type="text"
              value={
                questionObj?.disabled
                  ? questionObj?.answerText
                  : questionDescription
              }
              onChange={(e) => setQuestionDescription(e.target.value)}
              placeholder={
                questionObj?.id !== undefined
                  ? `${t("docpatientintpage.enterans", { ns: ["home"] })}`
                  : `${t("appointmentpage.enterQues", { ns: ["home"] })}`
              }
              id="questionDescriptionID"
              fullWidth
              size="small"
              minRows={4}
              multiline
              disabled={questionObj?.disabled}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
        {askMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={msgType}
              onClose={() => {
                dispatch(clearAskQuestionAPIRes());
                setAskMsg("");
              }}
            >
              {askMsg}
            </Alert>
          </Box>
        ) : null}
        {submitMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={submitMsgType}
              onClose={() => {
                dispatch(clearQuestionAPIRes());
                setSubmitMsg("");
              }}
            >
              {submitMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        {questionObj?.disabled ? (
          <Button onClick={closeDialog} variant="contained">
            {`${t("appointmentpage.close", { ns: ["home"] })}`}
          </Button>
        ) : (
          <Button
            onClick={questionSubmit}
            variant="contained"
            disabled={askQuestionValue?.loading || submitQuestionValue?.loading}
          >
            {`${t("Workschedulepage.submit", { ns: ["home"] })}`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  return <>{questionModalBody}</>;
}
