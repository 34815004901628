import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { AddDoctorSignatureDetailsActionTypes } from "./ActionTypes";
import { AddSignatureRes, AddSignatureBody } from "./Model";
import {
  addDoctorSignatureDetailsAPIResClearAction,
  addDoctorSignatureDetailsErrorAction,
  addDoctorSignatureDetailsLoadingAction,
  addDoctorSignatureDetailsSuccessAction,
  addDoctorSignatureDetailsAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddSignatureRes;
export const addSignatureDetails = (payload: AddSignatureBody) => {
  return function (dispatch: Dispatch<AddDoctorSignatureDetailsActionTypes>) {
    dispatch(addDoctorSignatureDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/api/sign/add_signature`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addDoctorSignatureDetailsLoadingAction(false));
        dispatch(
          addDoctorSignatureDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. signature details has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDoctorSignatureDetailsLoadingAction(false));
        dispatch(
          addDoctorSignatureDetailsErrorAction(
            {} as AddSignatureRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDoctorSignatureDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDoctorSignatureDetailsActionTypes>) {
    dispatch(
      addDoctorSignatureDetailsAPIMsgAction(
        apiRes as AddSignatureRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAddDoctorSignatureDetailsAPIRes = () => {
  return function (dispatch: Dispatch<AddDoctorSignatureDetailsActionTypes>) {
    dispatch(
      addDoctorSignatureDetailsAPIResClearAction(
        {} as AddSignatureRes,
        "",
        "",
        0
      )
    );
  };
};
