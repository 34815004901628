import {
  allActiveMeetingListDetails,
  allActiveMeetingListActionTypes,
  ALL_ACTIVE_MEETING_LIST_FAIL,
  ALL_ACTIVE_MEETING_LIST_LOADING,
  ALL_ACTIVE_MEETING_LIST_SUCCESS,
} from "./ActionTypes";
import { AllActiveMeetingListRes } from "./Model";

const initialStateGetPosts: allActiveMeetingListDetails = {
  loading: false,
  allActiveMeetingListRes: {} as AllActiveMeetingListRes,
  errRes: "",
};
export const allActiveMeetingListReducer = (
  state = initialStateGetPosts,
  action: allActiveMeetingListActionTypes
): allActiveMeetingListDetails => {
  switch (action.type) {
    case ALL_ACTIVE_MEETING_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ALL_ACTIVE_MEETING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allActiveMeetingListRes: action.payload,
        errRes: "",
      };
    case ALL_ACTIVE_MEETING_LIST_FAIL:
      return {
        ...state,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
