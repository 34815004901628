import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorSignatureDetailsActionTypes } from "./ActionTypes";
import { DoctorSignatureRes, GetDoctorSignatureBody } from "./Model";
import {
  getDoctorSignatureDetailsAPIResClearAction,
  getDoctorSignatureDetailsErrorAction,
  getDoctorSignatureDetailsLoadingAction,
  getDoctorSignatureDetailsSuccessAction,
  getDoctorSignatureDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as DoctorSignatureRes;
export const getSignatureDetails = (payload: GetDoctorSignatureBody) => {
  return function (dispatch: Dispatch<GetDoctorSignatureDetailsActionTypes>) {
    dispatch(getDoctorSignatureDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/api/sign/get_signature`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorSignatureDetailsLoadingAction(false));
        dispatch(
          getDoctorSignatureDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. signature details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorSignatureDetailsLoadingAction(false));
        dispatch(
          getDoctorSignatureDetailsErrorAction(
            {} as DoctorSignatureRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorSignatureDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorSignatureDetailsActionTypes>) {
    dispatch(
      getDoctorSignatureDetailsAPIMsgAction(apiRes as DoctorSignatureRes, "", 0)
    );
  };
};

export const clearGetDoctorSignatureDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorSignatureDetailsActionTypes>) {
    dispatch(
      getDoctorSignatureDetailsAPIResClearAction(
        {} as DoctorSignatureRes,
        "",
        0
      )
    );
  };
};
