import React from "react";
import Errorimage from "../components/image/not-found-page.png";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { t } from "i18next";

function NotFound() {
  return (
    <Container
      maxWidth="xl"
      sx={{ height: "100vh" }}
      title={t("common.pageNotFound", { ns: ["home"] })}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ height: "50vh" }}
          >
            <Grid item xs={8}>
              <Box>
                <Typography variant="h4">
                  {t("common.someWentWrong", { ns: ["home"] })}
                </Typography>
                <p>
                  <br />
                  {t("common.notFoundText", { ns: ["home"] })}
                  <br />
                  <br />
                </p>
                <Box>
                  <Button variant="contained" href="/signin">
                    {t("common.getBackHome", { ns: ["home"] })}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box mt={10}>
            <img
              width="520vh"
              height="500vh"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={Errorimage}
              alt=""
            />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}

export default NotFound;
