import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorAgreementActionTypes } from "./ActionTypes";
import { GetAgreementRes } from "./Model";
import {
  getDoctorAgreementAPIResClearAction,
  getDoctorAgreementErrorAction,
  getDoctorAgreementLoadingAction,
  getDoctorAgreementSuccessAction,
  getDoctorAgreementAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetAgreementRes;
export const getAgreement = () => {
  return function (dispatch: Dispatch<GetDoctorAgreementActionTypes>) {
    dispatch(getDoctorAgreementLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/agreement/list_agreementtemplate`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorAgreementLoadingAction(false));
        dispatch(
          getDoctorAgreementSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. agreement list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorAgreementLoadingAction(false));
        dispatch(
          getDoctorAgreementErrorAction(
            {} as GetAgreementRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorAgreementAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorAgreementActionTypes>) {
    dispatch(getDoctorAgreementAPIMsgAction(apiRes as GetAgreementRes, "", 0));
  };
};

export const clearGetDoctorEducationListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorAgreementActionTypes>) {
    dispatch(getDoctorAgreementAPIResClearAction({} as GetAgreementRes, "", 0));
  };
};
