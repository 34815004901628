import {
  AskQuestionActionTypes,
    ASK_QUESTION_FAIL,
    ASK_QUESTION_LOADING,
    ASK_QUESTION_SUCCESS,
    AskQuestionState,
    ASK_QUESTION_API_MSG,
    ASK_QUESTION_API_RES,
  } from "./ActionTypes";
  import { AskQuestionResponse } from "./Model";
  
  const initialStateEditPosts: AskQuestionState = {
    loading: false,
    askQuestionResponse: {} as AskQuestionResponse,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const askQuestionReducer = (
    state = initialStateEditPosts,
    action: AskQuestionActionTypes
  ): AskQuestionState => {
    switch (action.type) {
      case ASK_QUESTION_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case ASK_QUESTION_SUCCESS:
        return {
          ...state,
          askQuestionResponse: action.payload,
          successMsg: action.successMsg,
        };
      case ASK_QUESTION_FAIL:
        return {
          ...state,
          askQuestionResponse: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case ASK_QUESTION_API_MSG:
        return {
          ...state,
          askQuestionResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case ASK_QUESTION_API_RES:
        return {
          ...state,
          askQuestionResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };