import { DeleteWorkScheduleRes } from "./Model";

export const DELETE_WORK_SCHEDULE_LOADING =
  "DELETE_WORK_SCHEDULE_LOADING";
export const DELETE_WORK_SCHEDULE_SUCCESS =
  "DELETE_WORK_SCHEDULE_SUCCESS";
export const DELETE_WORK_SCHEDULE_FAIL =
  "DELETE_WORK_SCHEDULE_FAIL";
export const DELETE_WORK_SCHEDULE_DATA =
  "DELETE_WORK_SCHEDULE_DATA";

export interface DeleteWorkScheduleDetails {
  deleteWorkScheduleRes: DeleteWorkScheduleRes;
  loading: boolean;
  errRes: string;
}
export interface DeleteWorkScheduleLoading {
  type: typeof DELETE_WORK_SCHEDULE_LOADING;
}
export interface DeleteWorkScheduleSuccess {
  type: typeof DELETE_WORK_SCHEDULE_SUCCESS;
  payload: DeleteWorkScheduleRes;
  errRes: string;
}
export interface DeleteWorkScheduleFail {
  type: typeof DELETE_WORK_SCHEDULE_FAIL;
  payload: DeleteWorkScheduleRes;
  errRes: string;
}
interface DeleteWorkScheduleAction {
  type: typeof DELETE_WORK_SCHEDULE_DATA;
  payload: DeleteWorkScheduleRes;
  errRes: string;
}

export type DeleteWorkScheduleActionTypes =
  | DeleteWorkScheduleAction
  | DeleteWorkScheduleLoading
  | DeleteWorkScheduleSuccess
  | DeleteWorkScheduleFail;
