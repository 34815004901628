import { GetDoctorLicenseTypeListRes } from "./Model";
export const GET_DOCTOR_LICENSE_TYPE_LIST_LOADING =
  "GET_DOCTOR_LICENSE_TYPE_LIST_LOADING";
export const GET_DOCTOR_LICENSE_TYPE_LIST_SUCCESS =
  "GET_DOCTOR_LICENSE_TYPE_LIST_SUCCESS";
export const GET_DOCTOR_LICENSE_TYPE_LIST_FAIL =
  "GET_DOCTOR_LICENSE_TYPE_LIST_FAIL";
export const GET_DOCTOR_LICENSE_TYPE_LIST_API_MSG =
  "GET_DOCTOR_LICENSE_TYPE_LIST_API_MSG";
export const GET_DOCTOR_LICENSE_TYPE_LIST_API_RES =
  "GET_DOCTOR_LICENSE_TYPE_LIST_API_RES";

export interface GetDoctorLicenseTypeListState {
  getDoctorLicenseTypeListRes: GetDoctorLicenseTypeListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLicenseTypeListLoading {
  type: typeof GET_DOCTOR_LICENSE_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorLicenseTypeListSuccess {
  type: typeof GET_DOCTOR_LICENSE_TYPE_LIST_SUCCESS;
  payload: GetDoctorLicenseTypeListRes;
  successMsg: string;
}
export interface GetDoctorLicenseTypeListFail {
  type: typeof GET_DOCTOR_LICENSE_TYPE_LIST_FAIL;
  payload: GetDoctorLicenseTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLicenseTypeListAPIMsg {
  type: typeof GET_DOCTOR_LICENSE_TYPE_LIST_API_MSG;
  payload: GetDoctorLicenseTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLicenseTypeListAPIRes {
  type: typeof GET_DOCTOR_LICENSE_TYPE_LIST_API_RES;
  payload: GetDoctorLicenseTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorLicenseTypeListActionTypes =
  | GetDoctorLicenseTypeListLoading
  | GetDoctorLicenseTypeListSuccess
  | GetDoctorLicenseTypeListFail
  | GetDoctorLicenseTypeListAPIMsg
  | GetDoctorLicenseTypeListAPIRes;
