import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDrIdProofTypeListActionTypes } from "./ActionTypes";
import { GetDoctorIdTypeListRes } from "./Model";
import {
  getDrIdProofTypeListAPIResClearAction,
  getDrIdProofTypeListErrorAction,
  getDrIdProofTypeListLoadingAction,
  getDrIdProofTypeListSuccessAction,
  getDrIdProofTypeListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetDoctorIdTypeListRes;
export const getDrIdProofTypeList = () => {
  return function (dispatch: Dispatch<GetDrIdProofTypeListActionTypes>) {
    dispatch(getDrIdProofTypeListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL + `/doctorapp/api/doctoridproof/doctor_govtidtype_dropdown`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDrIdProofTypeListLoadingAction(false));
        dispatch(
          getDrIdProofTypeListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. identity proof type list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDrIdProofTypeListLoadingAction(false));
        dispatch(
          getDrIdProofTypeListErrorAction(
            {} as GetDoctorIdTypeListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDrIdProofTypeListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDrIdProofTypeListActionTypes>) {
    dispatch(
      getDrIdProofTypeListAPIMsgAction(
        apiRes as GetDoctorIdTypeListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDrIdProofTypeListAPIRes = () => {
  return function (dispatch: Dispatch<GetDrIdProofTypeListActionTypes>) {
    dispatch(
      getDrIdProofTypeListAPIResClearAction(
        {} as GetDoctorIdTypeListRes,
        "",
        "",
        0
      )
    );
  };
};
