import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { GetPatientActionTypes } from "./ActionTypes";
import { GetPersonalDetailsRes } from "./Model";
import {
  getPatientAPIResClearAction,
  getPatientErrorAction,
  getPatientLoadingAction,
  getPatientSuccessAction,
  getPatientUpdateAPIMsgAction,
} from "./Action";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import { headers } from "components/Utility";

let apiRes = {} as GetPersonalDetailsRes;
export const getPersonalDetailsApi = () => {
  return function (dispatch: Dispatch<GetPatientActionTypes>) {
    dispatch(getPatientLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(apiURL.apiURL + `/doctorapp/api/onboarding/get_personal_details`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientLoadingAction(false));
        dispatch(
          getPatientSuccessAction(
            res.data !== undefined ? res.data : ({} as GetPersonalDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "Get patient details successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getPatientLoadingAction(false));
        dispatch(
          getPatientErrorAction(
            {} as GetPersonalDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetPatientDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientActionTypes>) {
    dispatch(
      getPatientUpdateAPIMsgAction(apiRes as GetPersonalDetailsRes, "", "", 0)
    );
  };
};

export const clearGetPatientDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientActionTypes>) {
    dispatch(
      getPatientAPIResClearAction({} as GetPersonalDetailsRes, "", "", 0)
    );
  };
};
