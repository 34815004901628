import { combineReducers } from "redux";
import { apiAuthTokenReducer } from "../doctorLoginAuthToken/Reducer";
import { doctorAccountDetailsReducer } from "redux/AccountDetails/DoctorAccDetails/Reducer";
import { getSignatureDetailsReducer } from "redux/AccountDetails/DoctorSignature/GetDoctorSignature/Reducer";
import { emailPhoneOTPGenReducer } from "redux/AccountDetails/EmailPhoneGenerateOTP/Reducer";
import { getAppointmentReducer } from "./Reducer";
import {
  addCCReducer,
  deleteDocumentReducer,
  getCCReducer,
  getQuestionReducer,
} from "../FilePreparation/Reducer";
import { forgotPasswordReducer } from "redux/forgotPassword/Reducer";
import { emailOTPGenReducer } from "redux/emailOtpGeneration/Reducer";
import { emailOTPLoginReducer } from "redux/emailOtpAuth/Reducer";
import { emailOTPSignupReducer } from "redux/emailOtpSignup/Reducer";
import { submitQueryReducer } from "redux/needHelp/Reducer";
import { resetPasswordReducer } from "redux/resetPassword/Reducer";
import { getGenderListReducer } from "../DemographicsDetails/GenderList/Reducer";
import { getPersonalDetailsReducer } from "redux/GetPersonalDetails/Reducer";
import { editPersonalDetailsReducer } from "redux/EditPersonalDetails/Reducer";
import { getEducationListReducer } from "redux/UserProfile/EducationDetails/EducationList/Reducer";
import { getEducationSummaryReducer } from "redux/UserProfile/EducationDetails/GetEducationSummary/Reducer";
import { updateEducationSummaryReducer } from "redux/UserProfile/EducationDetails/EditEducationSummary/Reducer";
import { getDoctorEducationDetailsReducer } from "redux/GetEducationList/Reducer";
import { getDoctorDegreeListReducer } from "redux/DegreeListDropdown/Reducer";
import { addDoctorEducationDetailsReducer } from "redux/AddEducationDetails/Reducer";
import { editEducationDetailsReducer } from "redux/EditEducationDetails/Reducer";
import { drAppointmentDecisionReducer } from "../AddDoctorTeam/doctorAcceptAppointment/Reducer";
import { createScheduleMeetingReducer } from "../CreateMeeting/createScheduleMeeting/Reducer";
import { createInstantMeetingReducer } from "../CreateMeeting/createInstantmeeting/Reducer";
import { paymentStatusReducer } from "redux/PaymentStatus/Reducer";
import {
  createOrScheduleReducer,
  deleteMeetingReducer,
} from "../DoctorPatientInteraction/Reducer";
import { getProfessionalSummaryReducer } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/GetProfessionalSummary/Reducer";
import { editProfessionalSummaryReducer } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/EditProfessionalSummary/Reducer";
import { getSpecialtyListReducer } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/GetSpecialtyList/Reducer";
import { getWorkExperienceListReducer } from "redux/UserProfile/ProfessionalDetails/WorkExperience/GetWorkExperienceList/Reducer";
import { addDrWorkExperienceReducer } from "redux/UserProfile/ProfessionalDetails/WorkExperience/AddWorkExperience/Reducer";
import { editDrWorkExperienceReducer } from "redux/UserProfile/ProfessionalDetails/WorkExperience/EditWorkExperience/Reducer";
import { logOutReducer } from "../logOut/Reducer";
import { getLicenseListReducer } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/GetLicenseList/Reducer";
import { getDrLicenseTypeListReducer } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/DoctorLicenseTypeDropdown/Reducer";
import { addDrLicenseReducer } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/AddProfessionalLicenseDetails/Reducer";
import { editDrLicenseReducer } from "redux/UserProfile/ProfessionalDetails/ProfessionalLicense/EditProfessionalLicenseDetails/Reducer";
import { getMembershipListReducer } from "redux/UserProfile/ProfessionalDetails/Membership/GetProfessionalMembershipList/Reducer";
import { addDrProfessionalMembershipReducer } from "redux/UserProfile/ProfessionalDetails/Membership/AddProfessionalMembership/Reducer";
import { editDrProfessionalMembershipReducer } from "redux/UserProfile/ProfessionalDetails/Membership/EditProfessionalMembership/Reducer";
import { paymentLinkReducer } from "redux/AddDoctorTeam/GetPaymentLink/Reducer";
import { getAwardTypeListReducer } from "redux/UserProfile/Award/AwardTypeList/Reducer";
import { getAwardListReducer } from "redux/UserProfile/Award/GetAwardList/Reducer";
import { addDrAwardReducer } from "redux/UserProfile/Award/AddAward/Reducer";
import { editDrAwardReducer } from "redux/UserProfile/Award/EditAward/Reducer";
import { getCoverLetterReducer } from "redux/UserProfile/CoverLetter/GetCoverLetter/Reducer";
import { updateDoctorCoverLetterReducer } from "redux/UserProfile/CoverLetter/EditCoverLetter/Reducer";
import { getLegalSummaryReducer } from "redux/UserProfile/LegalDetails/LegalSummary/GetLegalSummay/Reducer";
import { updateDrLegalSummaryReducer } from "redux/UserProfile/LegalDetails/LegalSummary/EditLegalSummary/Reducer";
import { getLegalDetailsReducer } from "redux/UserProfile/LegalDetails/LegalDetails/GetLegalDetailsByDoctor/Reducer";
import { getLegalTypeListReducer } from "redux/UserProfile/LegalDetails/LegalDetails/LegalTypeList/Reducer";
import { addDrLegalDetailsReducer } from "redux/UserProfile/LegalDetails/LegalDetails/AddLegalDetailsApi/Reducer";
import { editDrLegalDetailsReducer } from "redux/UserProfile/LegalDetails/LegalDetails/EditLegalDetailsApi/Reducer";
import { getInsuranceListReducer } from "redux/UserProfile/Insurance/GetInsuranceByDoctor/Reducer";
import { addDrInsuranceReducer } from "redux/UserProfile/Insurance/AddDoctorInsurance/Reducer";
import { editDrInsuranceReducer } from "redux/UserProfile/Insurance/EditDoctorInsurance/Reducer";
import { getDrIdProofListReducer } from "redux/UserProfile/IdentityDetails/GetIdDetailsByDoctor/Reducer";
import { getDrIdProofTypeListReducer } from "redux/UserProfile/IdentityDetails/GovtIdProofDropdownList /Reducer";
import { addDrIdProofReducer } from "redux/UserProfile/IdentityDetails/AddDoctorIdProof/Reducer";
import { editDrIdProofReducer } from "redux/UserProfile/IdentityDetails/EditDoctorIdProof/Reducer";
import { getAgreementReducer } from "redux/UserProfile/Agreement/AgreementList/Reducer";
import { getAgreementTypeListReducer } from "redux/UserProfile/Agreement/AgreementTypeList/Reducer";
import { generateAgreementReducer } from "redux/UserProfile/Agreement/GenerateAgreement/Reducer";
import { signAgreementReducer } from "redux/UserProfile/Agreement/SignAgrementApi/Reducer";
import { addSignatureDetailsReducer } from "redux/AccountDetails/DoctorSignature/AddSignatureApi/Reducer";
import { imageUploadReducer } from "redux/ImageUpload/Reducer";
import { deleteSelectedDoctorReducer } from "redux/AddDoctorTeam/DeleteSelectedDoctor/Reducer";
import { doctorPersonalDetailsByIdReducer } from "redux/DoctorPersonalDetailsById/Reducer";
import { allActiveMeetingListReducer } from "redux/AllActivemeetingList/Reducer";
import { getTelehealthListReducer } from "redux/TelehealthDetailsList/Reducer";
import { setDrTeleHealthCredentialsReducer } from "redux/TelehealthCredentialSet/Reducer";
import { addEmailPhoneReducer } from "redux/AddEmailPhone/Reducer";
import { getDetailsForCreatePrescriptionReducer } from "redux/FinalOpinion/PrescriptionCreation/GetCreatingPrescriptionDetails/Reducer";
import { generatePrescriptionReducer } from "redux/FinalOpinion/PrescriptionCreation/GeneratePrescriptionApi/Reducer";
import { saveAsDraftPrescriptionReducer } from "../FinalOpinion/PrescriptionCreation/SavePrescriptionApi/Reducer";
import { getDetailsForEditPrescriptionReducer } from "redux/FinalOpinion/PrescriptionCreation/GetEditingPrescriptionDetails/Reducer";
import { joiningFeeReducer } from "redux/JoiningFeeApi/Reducer";
import { GetSectionTemplateListReducer } from "redux/SectionTemplateList/Reducer";
import { getRelationshipListReducer } from "redux/DemographicsDetails/RelationShipList/Reducer";
import { getBloodGroupListReducer } from "redux/DemographicsDetails/BloodGroupList/Reducer";
import { getMaritalListListReducer } from "redux/DemographicsDetails/MaritalStatusList/Reducer";
import { getSexualOrientationListReducer } from "redux/DemographicsDetails/SexualOrientationList/Reducer";
import { updateDemographicsDetailsByPatientIdReducer } from "redux/DemographicsDetails/UpdateDemographicsDetails/Reducer";
import { addDemographicsReducer } from "redux/DemographicsDetails/AddDemographicsDetails/Reducer";
import { WorkScheduleListReducer } from "redux/GetWorkScheduleList/Reducer";
import { getWeekdayListReducer } from "redux/weekdayList/Reducer";
import { deleteWorkScheduleReducer } from "redux/DeleteWorkSchedule/Reducer";
import { addWorkScheduleReducer } from "redux/AddWorkSchedule/Reducer";
import { jwtAuthTokenReducer } from "redux/jwtDoctorLogIn/Reducer";
import { getDoctorLoginDetailsReducer } from "redux/jwtDoctorLogIn/doctorLoginDetails/Reducer";

import { getDoctorSummaryListReducer } from "redux/FilePreparation/doctorSummary/getSummaryList/Reducer";
import { addDoctorSummaryListReducer } from "redux/FilePreparation/doctorSummary/addSummaryList/Reducer";
import { deleteDoctorSummaryListReducer } from "redux/FilePreparation/doctorSummary/deleteSummaryList/Reducer";
import { getDoctorCaseListReducer } from "redux/GetDoctorCaseList/Reducer";
import { addPatientDataReducer } from "redux/FilePreparation/AddPatientDataList/Reducer";
import { deletePatientDataByIdReducer } from "redux/FilePreparation/DeletePatientDataById/Reducer";
import { getPatientDataListReducer } from "redux/FilePreparation/GetPatientDataList/Reducer";
import { getQuestionnaireListReducer } from "redux/FilePreparation/Questionnaire/Reducer";
import { deletePatientCaseReducer } from "redux/FilePreparation/deletePatientCase/Reducer";
import { getDemographicsDetailsByPtIdReducer } from "redux/DemographicsDetails/GetPatientDemographicsDetails/Reducer";
import { getLanguageListReducer } from "redux/DemographicsDetails/LanguageList/Reducer";
import { questionListReducer } from "redux/PatientDoctorInteraction/QuestionList/Reducer";
import { submitQuestionReducer } from "redux/PatientDoctorInteraction/SubmitAnswer/Reducer";
import { askQuestionReducer } from "redux/PatientDoctorInteraction/AskQuestion/Reducer";
import { doctorListForCaseReducer } from "redux/AddDoctorTeam/DoctorListByPatientCase/Reducer";
import { getFinalOpinionListReducer } from "../FinalOpinion/FinalOpinionTable/GetFinalOpinionList/Reducer";
import { prescriptionListReducer } from "redux/FinalOpinion/PrescriptionTable/GetPrescriptionList/Reducer";
import { meetingListReducer } from "redux/PatientDoctorInteraction/MeetingList/Reducer";
import { getTaskListReducer } from "redux/Task/Reducer";
import { sectionTemplateListReducer } from "redux/PreviewSectionByStepId/SectionTemplateList/Reducer";
import { stepPreviewListReducer } from "redux/PreviewSectionByStepId/SectionsByStepIdForPreview/Reducer";

import { getPatientImportConfigListReducer } from "../FilePreparation/ImportConfig/GetPatientImportConfigList/Reducer";
import { addPatientImportConfigDataReducer } from "../FilePreparation/ImportConfig/AddPatientImportConfig/Reducer";
import { editPatientImportConfigDataReducer } from "../FilePreparation/ImportConfig/EditPatientImportConfig/Reducer";
import { authorizeDoctorForZoomTeleHealthReducer } from "redux/AuthorizeDoctorForZoomTeleHealth/Reducer";
import { appLoaderReducer } from "redux/appLoader/Reducer";

const Reducer = combineReducers({
  apiAuthTokens: apiAuthTokenReducer,
  caseValue: getDoctorCaseListReducer,
  getTaskListRes: getTaskListReducer,
  appointmentValue: getAppointmentReducer,
  doctorAccDetails: doctorAccountDetailsReducer,
  getSignatureDetailsRes: getSignatureDetailsReducer,
  emailPhoneOTPGenRes: emailPhoneOTPGenReducer,
  ccAddVlaue: addCCReducer,
  ccValue: getCCReducer,
  deleteDocumentValue: deleteDocumentReducer,
  questionValue: getQuestionReducer,
  forgotPassword: forgotPasswordReducer,
  emailOtpGeneration: emailOTPGenReducer,
  emailOTPLoginRes: emailOTPLoginReducer,
  emailOTPSignUpRes: emailOTPSignupReducer,
  submitQuery: submitQueryReducer,
  resetPassword: resetPasswordReducer,
  getPatientLanguageList: getLanguageListReducer,
  getPersonalDetails: getPersonalDetailsReducer,
  editPersonalDetails: editPersonalDetailsReducer,
  getPatientImportConfigList: getPatientImportConfigListReducer,
  addPatientImportConfigDataRes: addPatientImportConfigDataReducer,
  editPatientImportConfigDataRes: editPatientImportConfigDataReducer,
  drAppointmentDecisionRes: drAppointmentDecisionReducer,
  createScheduleMeeting: createScheduleMeetingReducer,
  getEducationList: getEducationListReducer,
  getEducationSummary: getEducationSummaryReducer,
  editEducationSummary: updateEducationSummaryReducer,
  getDoctorEducationDetailsRes: getDoctorEducationDetailsReducer,
  getDoctorDegreeListRes: getDoctorDegreeListReducer,
  addDoctorEducationDetailsRes: addDoctorEducationDetailsReducer,
  editEducationDetails: editEducationDetailsReducer,
  createInstantMeeting: createInstantMeetingReducer,
  doctorListForcasevalue: doctorListForCaseReducer,
  meetingListValue: meetingListReducer,
  createOrScheduleMeetingValue: createOrScheduleReducer,
  deletetelehealthMeetingValue: deleteMeetingReducer,
  questionListValueUnderCase: questionListReducer,
  getProfessionalSummaryValue: getProfessionalSummaryReducer,
  editProfessionalSummaryRes: editProfessionalSummaryReducer,
  specialtyListRes: getSpecialtyListReducer,
  getWorkExperienceListRes: getWorkExperienceListReducer,
  addDrWorkExperienceRes: addDrWorkExperienceReducer,
  editWorkExperienceRes: editDrWorkExperienceReducer,
  logOutRes: logOutReducer,
  addAskQuestion: askQuestionReducer,
  answerQuestion: submitQuestionReducer,
  getLicenseListRes: getLicenseListReducer,
  getDrLicenseTypeListRes: getDrLicenseTypeListReducer,
  addDrLicenseRes: addDrLicenseReducer,
  editDrLicenseRes: editDrLicenseReducer,
  getMembershipListRes: getMembershipListReducer,
  addDrProfessionalMembershipReducer: addDrProfessionalMembershipReducer,
  editDrProfessionalMembershipRes: editDrProfessionalMembershipReducer,
  getAwardTypeListRes: getAwardTypeListReducer,
  getAwardListRes: getAwardListReducer,
  addDrAwardRes: addDrAwardReducer,
  editDrAwardRes: editDrAwardReducer,
  getCoverLetterRes: getCoverLetterReducer,
  updateDoctorCoverLetterRes: updateDoctorCoverLetterReducer,
  getLegalSummaryRes: getLegalSummaryReducer,
  updateDrLegalSummaryRes: updateDrLegalSummaryReducer,
  getLegalDetailsRes: getLegalDetailsReducer,
  getLegalTypeListRes: getLegalTypeListReducer,
  addDrLegalDetailsRes: addDrLegalDetailsReducer,
  editDrLegalDetailsRes: editDrLegalDetailsReducer,
  DoctorpaymentLink: paymentLinkReducer,
  getInsuranceListRes: getInsuranceListReducer,
  addDrInsuranceRes: addDrInsuranceReducer,
  editDrInsuranceRes: editDrInsuranceReducer,
  getDrIdProofListRes: getDrIdProofListReducer,
  getDrIdProofTypeListRes: getDrIdProofTypeListReducer,
  addDrIdProofRes: addDrIdProofReducer,
  editDrIdProofRes: editDrIdProofReducer,
  deleteSelectedDoctor: deleteSelectedDoctorReducer,
  doctorPersonalDetailsById: doctorPersonalDetailsByIdReducer,
  allActiveMeetingList: allActiveMeetingListReducer,
  getAgreementRes: getAgreementReducer,
  getAgreementTypeListRes: getAgreementTypeListReducer,
  generateAgreementRes: generateAgreementReducer,
  signAgreementRes: signAgreementReducer,
  addSignatureDetailsRes: addSignatureDetailsReducer,
  imageUploading: imageUploadReducer,
  getTelehealthList: getTelehealthListReducer,
  setTelehealthCredentialsRes: setDrTeleHealthCredentialsReducer,
  addEmailPhoneRes: addEmailPhoneReducer,
  getDetailsForCreatePrescriptionRes: getDetailsForCreatePrescriptionReducer,
  generatePrescriptionValue: generatePrescriptionReducer,
  savePrescriptionValue: saveAsDraftPrescriptionReducer,
  prescriptionListRes: prescriptionListReducer,
  getDetailsForEditPrescriptionRes: getDetailsForEditPrescriptionReducer,
  joiningPaymentFee: joiningFeeReducer,
  paymentStatus: paymentStatusReducer,
  previewSectionByStepId: sectionTemplateListReducer,
  getSectionTemplateList: GetSectionTemplateListReducer,
  getDemographicsDetailsByPtId: getDemographicsDetailsByPtIdReducer,
  getPatientRelationshipList: getRelationshipListReducer,
  getPatientGenderList: getGenderListReducer,
  getPatientBloodGroupList: getBloodGroupListReducer,
  getPatientMaritalList: getMaritalListListReducer,
  getPatientSexualOrientation: getSexualOrientationListReducer,
  updateDemographicsDetails: updateDemographicsDetailsByPatientIdReducer,
  addDemographics: addDemographicsReducer,
  workScheduleList: WorkScheduleListReducer,
  getWeekdayList: getWeekdayListReducer,
  deleteWorkSchedule: deleteWorkScheduleReducer,
  addWorkSchedule: addWorkScheduleReducer,
  apiAuthToken: jwtAuthTokenReducer,
  getDoctorLoginDetailsRes: getDoctorLoginDetailsReducer,

  // FP ..
  getDoctorSummaryList: getDoctorSummaryListReducer,
  addDoctorSummaryList: addDoctorSummaryListReducer,
  deleteDoctorSummaryList: deleteDoctorSummaryListReducer,
  addPatientDataListRes: addPatientDataReducer,
  deletePatientDataByIdRes: deletePatientDataByIdReducer,
  patientDataListRes: getPatientDataListReducer,
  patientQuestionnaireListRes: getQuestionnaireListReducer,
  deleteCaseValue: deletePatientCaseReducer,
  getFinalOpinionListResValue: getFinalOpinionListReducer,
  authorizeDoctorForZoomTeleHealthRes: authorizeDoctorForZoomTeleHealthReducer,
  // summary..
  // getDoctorSummaryList: getDoctorSummaryListReducer,
  // addDoctorSummaryList: addDoctorSummaryListReducer,
  // deleteDoctorSummaryList: deleteDoctorSummaryListReducer,

  stepPreviewList: stepPreviewListReducer,
  appLoaderRes: appLoaderReducer,
});

export default Reducer;
