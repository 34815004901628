import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { AddDrProfessionalMembershipActionTypes } from "./ActionTypes";
import {
  AddProfessionalMembershipBody,
  AddProfessionalMembershipRes,
} from "./Model";
import {
  addDrProfessionalMembershipAPIResClearAction,
  addDrProfessionalMembershipErrorAction,
  addDrProfessionalMembershipLoadingAction,
  addDrProfessionalMembershipSuccessAction,
  addDrProfessionalMembershipAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddProfessionalMembershipRes;
export const addDrProfessionalMembership = (
  payload: AddProfessionalMembershipBody
) => {
  return function (dispatch: Dispatch<AddDrProfessionalMembershipActionTypes>) {
    dispatch(addDrProfessionalMembershipLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/doctormembership/add_doctor_membership`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDrProfessionalMembershipLoadingAction(false));
        dispatch(
          addDrProfessionalMembershipSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. professional membership details has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDrProfessionalMembershipLoadingAction(false));
        dispatch(
          addDrProfessionalMembershipErrorAction(
            {} as AddProfessionalMembershipRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDrProfessionalMembershipAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDrProfessionalMembershipActionTypes>) {
    dispatch(
      addDrProfessionalMembershipAPIMsgAction(
        apiRes as AddProfessionalMembershipRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAddDrProfessionalMembershipAPIRes = () => {
  return function (dispatch: Dispatch<AddDrProfessionalMembershipActionTypes>) {
    dispatch(
      addDrProfessionalMembershipAPIResClearAction(
        {} as AddProfessionalMembershipRes,
        "",
        "",
        0
      )
    );
  };
};
