import React, { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Card,
  CardHeader,
  Box,
  TextField,
  Autocomplete,
  CardContent,
  CardActions,
  Button,
  Grid,
  Stack,
  AlertColor,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { InputLabel } from "@mui/material";
import { t } from "i18next";
import { resetPasswordAPI } from "redux/resetPassword/API";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordBody } from "redux/resetPassword/Model";
import { AppState } from "redux/store/Store";
import { getGenderList } from "redux/GenderList/API";
import { getLanguageList } from "redux/LanguageList/API";
import { getPersonalDetailsApi } from "redux/GetPersonalDetails/API";
import {
  clearEditPersonalAPIRes,
  editPersonalDetailsApi,
} from "redux/EditPersonalDetails/API";
import { EditPersonalDetailsModel } from "redux/EditPersonalDetails/Model";
import MWLoader from "components/MWLoader";
import MWUploader from "components/MWUploader";

const useOutlinedInputStyles = makeStyles<void, "notchedOutline" | "focused">()(
  (_theme: any, _params: any, classes) => ({
    root: {
      [`& .${classes.notchedOutline}`]: {
        borderColor: "#DE3618",
        outlineWidth: 0,
      },
      [`&:hover .${classes.notchedOutline}`]: {
        borderColor: "#DE3618",
        outlineWidth: 0,
      },
      [`&.${classes.focused} .${classes.notchedOutline}`]: {
        borderColor: "#DE3618",
        outlineWidth: 0,
      },
      height: "38px",
      background: "white",
    },
    input: {
      "&::placeholder": {
        color: "#DE3618",
      },
      color: "#00000", // if you also want to change the color of the input, this is the prop you'd use
    },
    focused: {},
    notchedOutline: {},
  })
);

export interface Props {
  personalDetailsLoading: boolean | any;
}

export default function DoctorPersonalDetails({
  personalDetailsLoading,
}: Props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#DE3618",
      },
      secondary: {
        main: "#330101",
      },
      text: {
        primary: "#000000",
        secondary: "#DE3618",
      },
    },
  });

  const { classes: outlinedInputClasses } = useOutlinedInputStyles();
  const dispatch = useDispatch();
  // const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  // const loadingMarkup = isLoading ? <MWLoader /> : null;
  // const [imageId, setImageId] = useState("");
  // const [imageInfo, setImageInfo] = useState("");

  const [errorsupportnew, setErrorNewpassword] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [errorsupportold, setErrorOldpassword] = useState("");
  interface oldPasswordState {
    oldPassword: string;
    showPassword: boolean;
  }

  const [oldPassword, setOldPassword] = React.useState<oldPasswordState>({
    oldPassword: "",
    showPassword: false,
  });

  const handleOldPasswordChange =
    (prop: keyof oldPasswordState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOldPassword({ ...oldPassword, [prop]: event.target.value });
    };

  const handleOldPasswordClickShowPassword = () => {
    setOldPassword({ ...oldPassword, showPassword: !oldPassword.showPassword });
  };
  const handleOldMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // New Password Field
  interface NewPasswordState {
    newPassword: string;
    showNewPassword: boolean;
  }

  const [newPassword, setNewPassword] = React.useState<NewPasswordState>({
    newPassword: "",
    showNewPassword: false,
  });

  const handleNewPasswordChange =
    (prop: keyof NewPasswordState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword({ ...newPassword, [prop]: event.target.value });
    };

  const handleNewPasswordClickShowPassword = () => {
    setNewPassword({
      ...newPassword,
      showNewPassword: !newPassword.showNewPassword,
    });
  };

  const handleNewPasswordMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // const handleMessageChangeNew = useCallback((value) => setNewPassword(value), [],);

  const closeSuccess = () => {
    setForgotPasswordSuccess("");
  };
  const closeForgotPasswordError = () => {};

  const successForgotPasswordFiv = forgotPasswordSuccess ? (
    <Alert severity="success" onClose={closeSuccess}>
      {forgotPasswordSuccess}
    </Alert>
  ) : (
    ""
  );
  const errorForgotPasswordFiv = forgotPasswordError ? (
    <Alert severity="error" onClose={closeForgotPasswordError}>
      {forgotPasswordError}
    </Alert>
  ) : (
    ""
  );

  const errorSupportold = errorsupportold ? (
    <Typography>{errorsupportold}</Typography>
  ) : (
    ""
  );

  const errorSupportnew = errorsupportnew ? (
    <Typography>{errorsupportnew}</Typography>
  ) : (
    ""
  );

  const resetPassword = () => {
    setModalActive((modalActive) => !modalActive);
    setOldPassword({
      oldPassword: "",
      showPassword: false,
    });
    setNewPassword({
      newPassword: "",
      showNewPassword: false,
    });
    setForgotPasswordSuccess("");
    setForgotPasswordError("");
    setErrorOldpassword("");
    setErrorNewpassword("");
  };

  const token = function () {
    return localStorage.getItem("access");
  };

  let userToken: string = token()!;
  if (userToken == null) {
  }
  const resetbody = {
    old_password: oldPassword.oldPassword,
    new_password: newPassword.newPassword,
  } as ResetPasswordBody;

  const resetgetPasswordApicall = () => {
    if (oldPassword.oldPassword === "") {
      setErrorOldpassword("Please enter old password");
    } else if (newPassword.newPassword === "") {
      setErrorNewpassword("Please enter new password");
    } else {
      setErrorOldpassword("");
      setErrorNewpassword("");
      dispatch(resetPasswordAPI(resetbody));
    }
  };

  const resetPasswordValue = useSelector(
    (state: AppState) => state.resetPassword
  );
  // useEffect(() => {
  //   resetPasswordValue?.loading ? setIsLoading(true) : setIsLoading(false);
  // }, [resetPasswordValue]);
  useEffect(() => {
    if (resetPasswordValue?.resetPasswordRes.message !== undefined) {
      setForgotPasswordSuccess(resetPasswordValue?.resetPasswordRes.message);
      setForgotPasswordError("");
    }
    if (resetPasswordValue?.errorMsg !== "") {
      setForgotPasswordError(resetPasswordValue.errorMsg);
      setForgotPasswordSuccess("");
    }
  }, [resetPasswordValue]);

  const [userfirstName, setFirstName] = useState("");
  const handleuserFirstNameChange = useCallback(
    (value: any) => setFirstName(value),
    []
  );

  const [userlastName, setLastName] = useState("");
  const handleuserLastNameChange = useCallback(
    (value: any) => setLastName(value),
    []
  );

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [delImg, setDelImg] = useState(false);
  console.log("delImg", delImg);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  console.log("checkImgUpload", checkImgUpload);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    // console.log("passedVal", passedVal);
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  const [userEmail, setEmail] = useState("");
  const handleUserEmailChange = useCallback(
    (value: any) => setEmail(value),
    []
  );
  const [genderList, setGenderValues] = useState([] as any[]);
  const [selectedGen, setSelectedGen] = useState<number | null>(null);
  const [fMSName, setFMSName] = useState("");
  const handleFMSChange = useCallback((value: any) => setFMSName(value), []);

  const [language, setLanguage] = useState([] as any[]);
  const [languageSelected, setLanguageSelected] = useState([] as any);
  const filterItem = (dropdownPatientName: any, e: any) => {
    setLanguageSelected(dropdownPatientName);
  };

  const [address, setAddress] = useState("");
  const handleAddressChange = useCallback(
    (value: any) => setAddress(value),
    []
  );

  const [city, setCity] = useState("");
  const handleCityChange = useCallback((value: any) => setCity(value), []);

  const [state, setState] = useState("");
  const handleStateChange = useCallback((value: any) => setState(value), []);

  const NUMERIC_REGEX = /^(0|[1-9][0-9]*)$/;
  const [pinCode, setPinCode] = useState("");

  const filterGender = (dropdownGenderName: any, e: any) => {
    setSelectedGen(dropdownGenderName.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getGenderList());
    dispatch(getLanguageList());
    dispatch(getPersonalDetailsApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Call Gender List store
  const getDoctorGenderListRes = useSelector(
    (state: AppState) => state.getPatientGenderList
  );
  // Get Gender List
  useEffect(() => {
    personalDetailsLoading(getDoctorGenderListRes?.loading);
    if (getDoctorGenderListRes?.getGenderListRes?.genderList !== undefined) {
      setGenderValues(getDoctorGenderListRes?.getGenderListRes?.genderList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorGenderListRes]);

  // Call Language List store
  const getDoctorLanguageListRes = useSelector(
    (state: AppState) => state.getPatientLanguageList
  );
  // Get language List
  useEffect(() => {
    personalDetailsLoading(getDoctorLanguageListRes?.loading);
    if (
      getDoctorLanguageListRes?.getLanguageListRes?.languageList !== undefined
    ) {
      setLanguage(getDoctorLanguageListRes?.getLanguageListRes?.languageList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorLanguageListRes]);
  // Get Personal details store
  const getPersonalDetails = useSelector(
    (state: AppState) => state.getPersonalDetails
  );
  // Get personal details
  useEffect(() => {
    personalDetailsLoading(getPersonalDetails?.loading);
    if (
      getPersonalDetails?.getPersonalDetailsRes?.personalDetails !== undefined
    ) {
      setFirstName(
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.firstname
      );
      setLastName(
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.lastname
      );
      setEmail(
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.email
      );
      setSelectedGen(
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.gender ===
          null
          ? null
          : getPersonalDetails?.getPersonalDetailsRes?.personalDetails.gender
              .value
      );

      setAddress(
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.address
      );
      setFMSName(
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.guardianName
      );
      setState(
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.state
      );
      setCity(getPersonalDetails?.getPersonalDetailsRes?.personalDetails.city);
      const a =
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.pincode;
      setPinCode(a !== null ? a.toString() : "");
      let languagevalue =
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails
          .languageProficiency;
      setLanguageSelected(languagevalue);

      if (
        getPersonalDetails?.getPersonalDetailsRes?.personalDetails.doctorImage
      ) {
        setImageInfo(
          getPersonalDetails?.getPersonalDetailsRes?.personalDetails.doctorImage
            .document
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPersonalDetails]);

  const body = {
    firstname: userfirstName,
    lastname: userlastName,
    email: userEmail,
    guardianName: fMSName,
    gender: selectedGen,
    doctorImageId: imageId,
    address: address,
    city: city,
    state: state,
    pincode: pinCode,
    languageId: languageSelected.map((item: any) => item.value),
  } as EditPersonalDetailsModel;

  const editDoctorSubmit = () => {
    dispatch(editPersonalDetailsApi(body));
  };

  // Get Personal details store
  const editPersonalDetails = useSelector(
    (state: AppState) => state.editPersonalDetails
  );

  const [editDrPersonalDetailsMsg, setEditDrPersonalDetailsMsg] = useState("");
  const [editDrPersonalDetailsMsgType, setEditDrPersonalDetailsMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    personalDetailsLoading(editPersonalDetails?.loading);
    if (editPersonalDetails?.successMsg !== "") {
      setEditDrPersonalDetailsMsgType("success");
      setEditDrPersonalDetailsMsg(editPersonalDetails?.successMsg);
      dispatch(getPersonalDetailsApi());
      setIsEdit((isEdit) => !isEdit);
      setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
    }
    if (editPersonalDetails?.errorMsg !== "") {
      setEditDrPersonalDetailsMsgType("error");
      setEditDrPersonalDetailsMsg(editPersonalDetails?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPersonalDetails]);

  // Reset Password Modal
  const modalMarkup = (
    <Dialog fullWidth open={modalActive}>
      {resetPasswordValue?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{t("accountdetail.resetpassword", { ns: ["home"] })}</Grid>
          <Grid item>
            <IconButton onClick={resetPassword}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography>
            {t("accountdetail.oldpassword", { ns: ["home"] })!}
          </Typography>
          <Box pt={1}>
            <ThemeProvider theme={theme}>
              <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
              <OutlinedInput
                id="oldpassword"
                placeholder={t("accountdetail.enteroldpass", { ns: ["home"] })!}
                type={
                  oldPassword.showPassword
                    ? `${t("home.text", { ns: ["home"] })!}`
                    : `${t("password", { ns: ["home"] })!}`
                }
                value={oldPassword.oldPassword}
                onChange={handleOldPasswordChange("oldPassword")}
                fullWidth
                classes={outlinedInputClasses}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleOldPasswordClickShowPassword}
                      onMouseDown={handleOldMouseDownPassword}
                      edge="end"
                    >
                      {oldPassword.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </ThemeProvider>
          </Box>
          <Typography style={{ color: "#d50000" }}>
            {errorSupportold}
          </Typography>
        </div>
        <div>
          <Typography>
            {t("accountdetail.newpassword", { ns: ["home"] })!}
          </Typography>
          <Box pt={1}>
            <ThemeProvider theme={theme}>
              <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
              <OutlinedInput
                id="newpassword"
                placeholder="Enter your password"
                type={
                  newPassword.showNewPassword
                    ? `${t("home.text", { ns: ["home"] })!}`
                    : `${t("password", { ns: ["home"] })!}`
                }
                value={newPassword.newPassword}
                onChange={handleNewPasswordChange("newPassword")}
                fullWidth
                classes={outlinedInputClasses}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleNewPasswordClickShowPassword}
                      onMouseDown={handleNewPasswordMouseDownPassword}
                      edge="end"
                    >
                      {newPassword.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </ThemeProvider>
          </Box>
          <Typography style={{ color: "#d50000" }}>
            {errorSupportnew}
          </Typography>
        </div>
        <Box py={2}>
          <div>{successForgotPasswordFiv}</div>
          <div>{errorForgotPasswordFiv}</div>
        </Box>
      </DialogContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button variant="contained" onClick={resetgetPasswordApicall}>{`${t(
              "common.reset",
              { ns: ["home"] }
            )}`}</Button>
          </Grid>
        </Grid>
      </CardActions>
    </Dialog>
  );
  const actualPageMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("userprofilepage.docdetail", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={resetPassword}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                {t("accountdetail.resetpassword", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        <React.Fragment>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <TextField
                  fullWidth
                  label={t("common.firstname", { ns: ["home"] })}
                  value={userfirstName}
                  onChange={(e) => handleuserFirstNameChange(e.target.value)}
                  placeholder={t("common.enterfirstname", { ns: ["home"] })!}
                  disabled={isEdit}
                  size="small"
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  fullWidth
                  label={t("common.lastname", { ns: ["home"] })}
                  value={userlastName}
                  onChange={(e) => handleuserLastNameChange(e.target.value)}
                  placeholder={t("common.enterlastname", { ns: ["home"] })!}
                  disabled={isEdit}
                  size="small"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MWUploader
                documentType="doctor-image"
                criteria={t("common.imageSizeHelperText", { ns: ["home"] })}
                updatedDataState={updateDataStateFromChild}
                title={t("userprofilepage.docimg", { ns: ["home"] })}
                visibility={isEdit}
                imageUrl={imageInfo}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
              <Typography>
                {t("userprofilepage.imgsize", { ns: ["home"] })}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label={t("common.emailorphno", { ns: ["home"] })}
                type="email"
                value={userEmail}
                onChange={(e) => handleUserEmailChange(e.target.value)}
                placeholder={t("userprofilepage.modemail", { ns: ["home"] })!}
                disabled={isEdit}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                value={
                  genderList.find(
                    (element: any) => element.value === selectedGen
                  ) || null
                }
                id="gender-states"
                options={genderList}
                onChange={(event: any, newValue: any) => {
                  filterGender(newValue, event);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Gender"
                    label={t("userprofilepage.gender", { ns: ["home"] })}
                  />
                )}
                disabled={isEdit}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label={t("userprofilepage.ForMorsname", { ns: ["home"] })}
                value={fMSName}
                onChange={(e) => handleFMSChange(e.target.value)}
                placeholder={
                  t("userprofilepage.enterForMorsname", { ns: ["home"] })!
                }
                disabled={isEdit}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label={t("userprofilepage.address", { ns: ["home"] })}
                value={address}
                onChange={(e) => handleAddressChange(e.target.value)}
                placeholder={t("userprofilepage.enteradd", { ns: ["home"] })!}
                disabled={isEdit}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Autocomplete
                value={languageSelected}
                multiple
                id="tags-outlined"
                options={language}
                onChange={(event: any, newValue: any) => {
                  filterItem(newValue, event);
                }}
                getOptionLabel={(option) => option.label}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField {...params} label={"Preffered Language"} />
                )}
                disableClearable
                disabled={isEdit}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label={t("userprofilepage.city", { ns: ["home"] })}
                value={city}
                onChange={(e) => handleCityChange(e.target.value)}
                placeholder={t("userprofilepage.entercity", { ns: ["home"] })!}
                disabled={isEdit}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label={t("userprofilepage.state", { ns: ["home"] })}
                value={state}
                onChange={(e) => handleStateChange(e.target.value)}
                placeholder={t("userprofilepage.enterstate", { ns: ["home"] })!}
                disabled={isEdit}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <TextField
                fullWidth
                label={t("userprofilepage.pin", { ns: ["home"] })}
                value={pinCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const value = event.target.value;
                  if (value !== "" && !NUMERIC_REGEX.test(value)) {
                    return;
                  }
                  setPinCode(event.target.value);
                }}
                placeholder={t("userprofilepage.enterpin", { ns: ["home"] })!}
                disabled={isEdit}
                size="small"
                inputProps={{ maxLength: 5, minLength: 5 }}
                required
                error={pinCode !== "" && pinCode.length !== 5 ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {editDrPersonalDetailsMsg !== "" ? (
                <Box my={2}>
                  <Alert
                    severity={editDrPersonalDetailsMsgType}
                    onClose={() => {
                      dispatch(clearEditPersonalAPIRes());
                      setEditDrPersonalDetailsMsg("");
                    }}
                  >
                    {editDrPersonalDetailsMsg}
                  </Alert>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </React.Fragment>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                setIsEdit((isEdit) => !isEdit);
                setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
              }}
              sx={{ textTransform: "none" }}
            >
              {isEdit
                ? `${t("userprofilepage.edit", { ns: ["home"] })}`
                : `${t("accountdetail.cancel", { ns: ["home"] })}`}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={isEnableSubmit}
              onClick={() => {
                editDoctorSubmit();
              }}
              sx={{ textTransform: "none" }}
            >
              {`${t("userprofilepage.subdetail", { ns: ["home"] })}`}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );

  return (
    <Box>
      {actualPageMarkup}
      {modalMarkup}
    </Box>
  );
}
