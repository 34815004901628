import {
    SUBMIT_QUESTION_FAIL,
    SUBMIT_QUESTION_LOADING,
    SUBMIT_QUESTION_SUCCESS,
    SUBMIT_QUESTION_API_MSG,
    SUBMIT_QUESTION_API_RES,
    SubmitQuestionActionTypes,
  } from "./ActionTypes";
  import { SubmitQuestionResponse } from "./Model";
  
  export const submitQuestionLoadingAction = (
    loading: boolean
  ): SubmitQuestionActionTypes => {
    return {
      type: SUBMIT_QUESTION_LOADING,
      loading: loading,
    };
  };
  
  export const submitQuestionSuccessAction = (
    submitQuestionResponse: SubmitQuestionResponse,
    successMsg: string
  ): SubmitQuestionActionTypes => {
    return {
      type: SUBMIT_QUESTION_SUCCESS,
      payload: submitQuestionResponse,
      successMsg: successMsg,
    };
  };
  
  export const submitQuestionErrorAction = (
    submitQuestionResponse: SubmitQuestionResponse,
    errMsg: string,
    status: number
  ): SubmitQuestionActionTypes => {
    return {
      type: SUBMIT_QUESTION_FAIL,
      payload: submitQuestionResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const submitQuestionAPIMsgAction = (
    submitQuestionResponse: SubmitQuestionResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): SubmitQuestionActionTypes => {
    return {
      type: SUBMIT_QUESTION_API_MSG,
      payload: submitQuestionResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const submitQuestionAPIResClearAction = (
    submitQuestionResponse: SubmitQuestionResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): SubmitQuestionActionTypes => {
    return {
      type: SUBMIT_QUESTION_API_RES,
      payload: submitQuestionResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };