import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import DoctorPersonalDetails from "./DoctorOnboarding/DoctorPersonalDetails";
import DoctorProfessionalDetails from "./DoctorOnboarding/DoctorProfessionalDetails";
import DoctorEducationalDetails from "./DoctorOnboarding/DoctorEducationalDetails";
import DoctorCoverLetter from "./DoctorOnboarding/CoverLetter";
import Awards from "./DoctorOnboarding/Award";
import LegalDetails from "./DoctorOnboarding/LegalDetails";
import JoiningFee from "./DoctorOnboarding/JoiningFee";
import Agreement from "./DoctorOnboarding/Agreement";
import InsuranceDetails from "./DoctorOnboarding/Insurance";
import Identity from "./DoctorOnboarding/IdentityDetails";
import { t } from "i18next";
import MWPageTitle from "components/MWPageTitle";
import PageLayout from "components/MWPageLayout";
import { useDispatch } from "react-redux";
import { clearImageUploadAPIRes } from "redux/ImageUpload/API";
import { clearUpdateDrLegalSummaryAPIRes } from "redux/UserProfile/LegalDetails/LegalSummary/EditLegalSummary/API";
import { clearUpdateDoctorEducationSUmmaryAPIRes } from "redux/UserProfile/EducationDetails/EditEducationSummary/API";
import { clearEditDoctorProfessionalSummaryAPIRes } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/EditProfessionalSummary/API";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function UserProfile() {
  const dispatch = useDispatch();
  const [selectedTab, setSelected] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
    localStorage.setItem("selctedprofiletab", String(newValue));
    dispatch(clearImageUploadAPIRes());
    dispatch(clearUpdateDrLegalSummaryAPIRes());
    dispatch(clearUpdateDoctorEducationSUmmaryAPIRes());
    dispatch(clearEditDoctorProfessionalSummaryAPIRes());
  };
  const [pageTitle, setPageTitle] = useState("");
  const onboardingStatus = localStorage.getItem("onboardingrequestStatus");

  const defaultSelectedTab = localStorage.getItem("selctedprofiletab");

  useEffect(() => {
    defaultSelectedTab !== null
      ? setSelected(Number(defaultSelectedTab))
      : setSelected(0);
  }, [defaultSelectedTab]);

  useEffect(() => {
    if (onboardingStatus === "incomplete") {
      setPageTitle(
        `${t("userprofilepage.doctorOnboarding", { ns: ["home"] })}`
      );
    } else {
      setPageTitle(`${t("userprofilepage.docdetail", { ns: ["home"] })}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);
  const updatePersonalDetailsLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateEducationDetailsLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateDrProfessionalLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateDrAwardLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateCoverLetterLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateLegalDetailsLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateJoiningDetailsLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateInsuranceDetailsLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateIdentityDetailsLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };
  const updateAgreementDetailsLoadingValueFromChild = (passedVal: boolean) => {
    setLoading(passedVal);
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  return (
    <PageLayout>
      <MWPageTitle title={pageTitle} />
      <Box sx={{ maxWidth: { xs: 320, sm: 480, lg: 1366, xl: 3840 } }}>
        <Box>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            scrollButtons
            allowScrollButtonsMobile
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#d33316",
              },
            }}
            sx={{
              "& button:hover": { background: "" },
              "& button.Mui-selected": {
                background: "#fffff",
                color: "#c8682c",
              },
            }}
          >
            <Tab
              label={t("userprofilepage.personaldetail", {
                ns: ["home"],
              })}
            ></Tab>
            <Tab label={t("userprofilepage.edudetails", { ns: ["home"] })} />
            <Tab
              label={t("userprofilepage.profdetails", { ns: ["home"] })}
            ></Tab>
            <Tab label={t("userprofilepage.awards", { ns: ["home"] })} />
            <Tab label={t("userprofilepage.cover", { ns: ["home"] })}></Tab>
            <Tab label={t("userprofilepage.legaldetail", { ns: ["home"] })} />
            <Tab label={t("userprofilepage.joinfee", { ns: ["home"] })}></Tab>
            <Tab
              label={t("userprofilepage.insurancedetail", {
                ns: ["home"],
              })}
            />
            <Tab label={t("userprofilepage.iddetail", { ns: ["home"] })}></Tab>
            <Tab label={t("userprofilepage.agreement", { ns: ["home"] })} />
          </Tabs>
        </Box>
        <Box sx={{ padding: 2 }}>
          {selectedTab === 0 && (
            <DoctorPersonalDetails
              personalDetailsLoading={
                updatePersonalDetailsLoadingValueFromChild
              }
            />
          )}
          {selectedTab === 1 && (
            <DoctorEducationalDetails
              educationDetailsLoading={
                updateEducationDetailsLoadingValueFromChild
              }
            />
          )}
          {selectedTab === 2 && (
            <DoctorProfessionalDetails
              drProfessionalDetailsLoading={
                updateDrProfessionalLoadingValueFromChild
              }
            />
          )}
          {selectedTab === 3 && (
            <Awards drAwardLoading={updateDrAwardLoadingValueFromChild} />
          )}
          {selectedTab === 4 && (
            <DoctorCoverLetter
              coverLetterLoading={updateCoverLetterLoadingValueFromChild}
            />
          )}
          {selectedTab === 5 && (
            <LegalDetails
              legalDetailsLoading={updateLegalDetailsLoadingValueFromChild}
            />
          )}
          {selectedTab === 6 && (
            <JoiningFee
              joiningDetailsLoading={updateJoiningDetailsLoadingValueFromChild}
            />
          )}
          {selectedTab === 7 && (
            <InsuranceDetails
              insuranceDetailsLoading={
                updateInsuranceDetailsLoadingValueFromChild
              }
            />
          )}
          {selectedTab === 8 && (
            <Identity
              identityDetailsLoading={
                updateIdentityDetailsLoadingValueFromChild
              }
            />
          )}
          {selectedTab === 9 && (
            <Agreement
              agreementLoading={updateAgreementDetailsLoadingValueFromChild}
            />
          )}
        </Box>
      </Box>
    </PageLayout>
  );
}
