import {
  UpdateDoctorEducationSummaryActionTypes,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_FAIL,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_LOADING,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_SUCCESS,
  UpdateDoctorEducationSummaryState,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_API_MSG,
  UPDATE_DOCTOR_EDUCATION_SUMMARY_API_RES,
} from "./ActionTypes";
import { UpdateEducationSummaryRes } from "./Model";

const initialStateUpdatePosts: UpdateDoctorEducationSummaryState = {
  loading: false,
  updateDoctorEducationSummaryRes: {} as UpdateEducationSummaryRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const updateEducationSummaryReducer = (
  state = initialStateUpdatePosts,
  action: UpdateDoctorEducationSummaryActionTypes
): UpdateDoctorEducationSummaryState => {
  switch (action.type) {
    case UPDATE_DOCTOR_EDUCATION_SUMMARY_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case UPDATE_DOCTOR_EDUCATION_SUMMARY_SUCCESS:
      return {
        ...state,
        updateDoctorEducationSummaryRes: action.payload,
        successMsg: action.successMsg,
      };
    case UPDATE_DOCTOR_EDUCATION_SUMMARY_FAIL:
      return {
        ...state,
        updateDoctorEducationSummaryRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_DOCTOR_EDUCATION_SUMMARY_API_MSG:
      return {
        ...state,
        updateDoctorEducationSummaryRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_DOCTOR_EDUCATION_SUMMARY_API_RES:
      return {
        ...state,
        updateDoctorEducationSummaryRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
