import {
  DemographicsDetailsByPtIdDetails,
  GetDemographicsDetailsByPtIdActionTypes,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_MSG,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_RES,
} from "./ActionTypes";
import { GetDemographicsDetailsByPtIdRes } from "./Model";

const initialStateGetPosts: DemographicsDetailsByPtIdDetails = {
  loading: false,
  getDemographicsDetailsByPtIdRes: {} as GetDemographicsDetailsByPtIdRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getDemographicsDetailsByPtIdReducer = (
  state = initialStateGetPosts,
  action: GetDemographicsDetailsByPtIdActionTypes
): DemographicsDetailsByPtIdDetails => {
  switch (action.type) {
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS:
      return {
        ...state,
        getDemographicsDetailsByPtIdRes: action.payload,
        errorMsg: "",
      };
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL:
      return {
        ...state,
        getDemographicsDetailsByPtIdRes: action.payload,
        errorMsg: action.errorMsg,
      };
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_MSG:
      return {
        ...state,
        getDemographicsDetailsByPtIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_RES:
      return {
        ...state,
        getDemographicsDetailsByPtIdRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
