import {
  GET_DOCTOR_AGREEMENT_LIST_FAIL,
  GET_DOCTOR_AGREEMENT_LIST_LOADING,
  GET_DOCTOR_AGREEMENT_LIST_SUCCESS,
  GET_DOCTOR_AGREEMENT_LIST_API_MSG,
  GET_DOCTOR_AGREEMENT_LIST_API_RES,
  GetDoctorAgreementActionTypes,
} from "./ActionTypes";
import { GetAgreementRes } from "./Model";

export const getDoctorAgreementLoadingAction = (
  loading: boolean
): GetDoctorAgreementActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorAgreementSuccessAction = (
  getDoctorAgreementResponse: GetAgreementRes,
  successMsg: string
): GetDoctorAgreementActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_LIST_SUCCESS,
    payload: getDoctorAgreementResponse,
    successMsg: successMsg,
  };
};

export const getDoctorAgreementErrorAction = (
  getDoctorAgreementResponse: GetAgreementRes,
  errMsg: string,
  status: number
): GetDoctorAgreementActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_LIST_FAIL,
    payload: getDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorAgreementAPIMsgAction = (
  getDoctorAgreementResponse: GetAgreementRes,
  errMsg: string,
  status: number
): GetDoctorAgreementActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_LIST_API_MSG,
    payload: getDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorAgreementAPIResClearAction = (
  getDoctorAgreementResponse: GetAgreementRes,
  errMsg: string,
  status: number
): GetDoctorAgreementActionTypes => {
  return {
    type: GET_DOCTOR_AGREEMENT_LIST_API_RES,
    payload: getDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};
