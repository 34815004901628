import {
  ADD_DR_ID_PROOF_FAIL,
  ADD_DR_ID_PROOF_LOADING,
  ADD_DR_ID_PROOF_SUCCESS,
  ADD_DR_ID_PROOF_API_MSG,
  ADD_DR_ID_PROOF_API_RES,
  AddDrIdProofActionTypes,
} from "./ActionTypes";
import { AddDrIdProofRes } from "./Model";

export const addDrIdProofLoadingAction = (
  loading: boolean
): AddDrIdProofActionTypes => {
  return {
    type: ADD_DR_ID_PROOF_LOADING,
    loading: loading,
  };
};

export const addDrIdProofSuccessAction = (
  addDrIdProofResponse: AddDrIdProofRes,
  successMsg: string
): AddDrIdProofActionTypes => {
  return {
    type: ADD_DR_ID_PROOF_SUCCESS,
    payload: addDrIdProofResponse,
    successMsg: successMsg,
  };
};

export const addDrIdProofErrorAction = (
  addDrIdProofResponse: AddDrIdProofRes,
  errMsg: string,
  status: number
): AddDrIdProofActionTypes => {
  return {
    type: ADD_DR_ID_PROOF_FAIL,
    payload: addDrIdProofResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDrIdProofAPIMsgAction = (
  addDrIdProofResponse: AddDrIdProofRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrIdProofActionTypes => {
  return {
    type: ADD_DR_ID_PROOF_API_MSG,
    payload: addDrIdProofResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDrIdProofAPIResClearAction = (
  addDrIdProofResponse: AddDrIdProofRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrIdProofActionTypes => {
  return {
    type: ADD_DR_ID_PROOF_API_RES,
    payload: addDrIdProofResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
