import {
  EmailOTPLoginActionTypes,
  EMAIL_OTP_LOGIN_FAIL,
  EMAIL_OTP_LOGIN_LOADING,
  EMAIL_OTP_LOGIN_SUCCESS,
  EmailOTPLoginState,
  EMAIL_OTP_LOGIN_UPDATE_API_MSG,
  EMAIL_OTP_LOGIN_UPDATE_API_RES,
} from "./ActionTypes";
import { EmailOtpLoginRes } from "./Model";

const initialStateGetPosts: EmailOTPLoginState = {
  loading: false,
  emailOTPLoginRes: {} as EmailOtpLoginRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const emailOTPLoginReducer = (
  state = initialStateGetPosts,
  action: EmailOTPLoginActionTypes
): EmailOTPLoginState => {
  switch (action.type) {
    case EMAIL_OTP_LOGIN_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EMAIL_OTP_LOGIN_SUCCESS:
      return {
        ...state,
        emailOTPLoginRes: action.payload,
        successMsg: action.successMsg,
      };
    case EMAIL_OTP_LOGIN_FAIL:
      return {
        ...state,
        emailOTPLoginRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EMAIL_OTP_LOGIN_UPDATE_API_MSG:
      return {
        ...state,
        emailOTPLoginRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EMAIL_OTP_LOGIN_UPDATE_API_RES:
      return {
        ...state,
        emailOTPLoginRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
