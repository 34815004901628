import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { AddDrLegalDetailsActionTypes } from "./ActionTypes";
import { AddLegalDetailsBody, AddLegalDetailsRes } from "./Model";
import {
  addDrLegalDetailsAPIResClearAction,
  addDrLegalDetailsErrorAction,
  addDrLegalDetailsLoadingAction,
  addDrLegalDetailsSuccessAction,
  addDrLegalDetailsAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddLegalDetailsRes;
export const addDrLegalDetails = (payload: AddLegalDetailsBody) => {
  return function (dispatch: Dispatch<AddDrLegalDetailsActionTypes>) {
    dispatch(addDrLegalDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/add_legal_details`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDrLegalDetailsLoadingAction(false));
        dispatch(
          addDrLegalDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal details has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDrLegalDetailsLoadingAction(false));
        dispatch(
          addDrLegalDetailsErrorAction(
            {} as AddLegalDetailsRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDrLegalDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDrLegalDetailsActionTypes>) {
    dispatch(
      addDrLegalDetailsAPIMsgAction(apiRes as AddLegalDetailsRes, "", "", 0)
    );
  };
};

export const clearAddDrLegalDetailsAPIRes = () => {
  return function (dispatch: Dispatch<AddDrLegalDetailsActionTypes>) {
    dispatch(
      addDrLegalDetailsAPIResClearAction({} as AddLegalDetailsRes, "", "", 0)
    );
  };
};
