import {
  GET_DETAILS_FOR_CREATE_PRESCRIPTION_FAIL,
  GET_DETAILS_FOR_CREATE_PRESCRIPTION_LOADING,
  GET_DETAILS_FOR_CREATE_PRESCRIPTION_SUCCESS,
  GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_MSG,
  GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_RES,
  GetDetailsForCreatePrescriptionActionTypes,
} from "./ActionTypes";
import { GetDetailsForCreatePrescriptionRes } from "./Model";

export const getDetailsForCreatePrescriptionLoadingAction = (
  loading: boolean
): GetDetailsForCreatePrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_CREATE_PRESCRIPTION_LOADING,
    loading: loading,
  };
};

export const getDetailsForCreatePrescriptionSuccessAction = (
  getDetailsForCreatePrescriptionResponse: GetDetailsForCreatePrescriptionRes,
  successMsg: string
): GetDetailsForCreatePrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_CREATE_PRESCRIPTION_SUCCESS,
    payload: getDetailsForCreatePrescriptionResponse,
    successMsg: successMsg,
  };
};

export const getDetailsForCreatePrescriptionErrorAction = (
  getDetailsForCreatePrescriptionResponse: GetDetailsForCreatePrescriptionRes,
  errMsg: string,
  status: number
): GetDetailsForCreatePrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_CREATE_PRESCRIPTION_FAIL,
    payload: getDetailsForCreatePrescriptionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDetailsForCreatePrescriptionUpdateAPIMsgAction = (
  getDetailsForCreatePrescriptionResponse: GetDetailsForCreatePrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDetailsForCreatePrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_MSG,
    payload: getDetailsForCreatePrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDetailsForCreatePrescriptionUpdateAPIResClearAction = (
  getDetailsForCreatePrescriptionResponse: GetDetailsForCreatePrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDetailsForCreatePrescriptionActionTypes => {
  return {
    type: GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_RES,
    payload: getDetailsForCreatePrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
