import { GetPrescriptionListRes } from "./Model";
export const GET_PRESCRIPTION_LIST_LOADING = "GET_PRESCRIPTION_LIST_LOADING";
export const GET_PRESCRIPTION_LIST_SUCCESS = "GET_PRESCRIPTION_LIST_SUCCESS";
export const GET_PRESCRIPTION_LIST_FAIL = "GET_PRESCRIPTION_LIST_FAIL";
export const GET_PRESCRIPTION_LIST_API_MSG = "GET_PRESCRIPTION_LIST_API_MSG";
export const GET_PRESCRIPTION_LIST_API_RES = "GET_PRESCRIPTION_LIST_API_RES";

export interface GetPrescriptionListState {
  getPrescriptionListRes: GetPrescriptionListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPrescriptionListLoading {
  type: typeof GET_PRESCRIPTION_LIST_LOADING;
  loading: boolean;
}
export interface GetPrescriptionListSuccess {
  type: typeof GET_PRESCRIPTION_LIST_SUCCESS;
  payload: GetPrescriptionListRes;
  successMsg: string;
}
export interface GetPrescriptionListFail {
  type: typeof GET_PRESCRIPTION_LIST_FAIL;
  payload: GetPrescriptionListRes;
  errorMsg: string;
  status: number;
}
export interface GetPrescriptionListAPIMsg {
  type: typeof GET_PRESCRIPTION_LIST_API_MSG;
  payload: GetPrescriptionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetPrescriptionListAPIRes {
  type: typeof GET_PRESCRIPTION_LIST_API_RES;
  payload: GetPrescriptionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetPrescriptionListActionTypes =
  | GetPrescriptionListLoading
  | GetPrescriptionListSuccess
  | GetPrescriptionListFail
  | GetPrescriptionListAPIMsg
  | GetPrescriptionListAPIRes;
