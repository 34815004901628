import {
  AuthorizeDoctorForZoomTeleHealthActionTypes,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_FAIL,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_LOADING,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_SUCCESS,
  AuthorizeDoctorForZoomTeleHealthState,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_MSG,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_RES,
} from "./ActionTypes";
import { AuthorizeDoctorForZoomTeleHealthRes } from "./Model";

const initialStateEditPosts: AuthorizeDoctorForZoomTeleHealthState = {
  loading: false,
  doctorListForPatientCaseRes: {} as AuthorizeDoctorForZoomTeleHealthRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const authorizeDoctorForZoomTeleHealthReducer = (
  state = initialStateEditPosts,
  action: AuthorizeDoctorForZoomTeleHealthActionTypes,
): AuthorizeDoctorForZoomTeleHealthState => {
  switch (action.type) {
    case AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_SUCCESS:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        successMsg: action.successMsg,
      };
    case AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_FAIL:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_MSG:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_RES:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
