import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../../URL";
import { AddSummaryListActionTypes } from "./ActionTypes";
import { addSummaryListSubmitBody, AddSummaryListRes } from "./Model";
import {
  addSummaryListAPIResClearAction,
  addSummaryListErrorAction,
  addSummaryListLoadingAction,
  addSummaryListSuccessAction,
  addSummaryListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../authentication/Interceptors";
import { headers } from "../../../../components/Utility";

let apiRes = {} as AddSummaryListRes;
export const filePeparationDoctorAddSummarySubmit = (
  payload: addSummaryListSubmitBody
) => {
  return function (dispatch: Dispatch<AddSummaryListActionTypes>) {
    dispatch(addSummaryListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          `/patientapp/api/patientMedicalData/add_patientSectionSummaryByDoctor`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addSummaryListLoadingAction(false));
        dispatch(
          addSummaryListSuccessAction(
            res.data !== undefined ? res.data : ({} as AddSummaryListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Doctor Summary Added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addSummaryListLoadingAction(false));
        dispatch(
          addSummaryListErrorAction(
            {} as AddSummaryListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddSummaryListAPIResMsg = () => {
  return function (dispatch: Dispatch<AddSummaryListActionTypes>) {
    dispatch(
      addSummaryListUpdateAPIMsgAction(apiRes as AddSummaryListRes, "", "", 0)
    );
  };
};

export const clearAddSummaryListAPIRes = () => {
  return function (dispatch: Dispatch<AddSummaryListActionTypes>) {
    dispatch(
      addSummaryListAPIResClearAction({} as AddSummaryListRes, "", "", 0)
    );
  };
};
