import React, { useState, useEffect } from "react";
import {} from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import PaymentIcon from "@mui/icons-material/Payment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { joiningFeeApi } from "redux/JoiningFeeApi/API";
import { AppState } from "redux/store/Store";
import { paymentStatusApi } from "redux/PaymentStatus/API";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import MWExceptionList from "components/MWExceptionList";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { Paymentlistowner } from "redux/JoiningFeeApi/Model";
import dayjs from "dayjs";
export interface Props {
  joiningDetailsLoading: boolean | any;
}
export default function JoiningFee({ joiningDetailsLoading }: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [, setConsultationType] = useState("");
  const [, setDoctorTeamType] = useState("");
  const [, setResponseTimeType] = useState("");
  const [, setToastSuccessMessage] = useState("");
  const [, setToastErrorMessage] = useState("");

  //data table start
  const joiningFeeTableColumns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("payment.paystat", { ns: ["home"] })}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            dispatch(paymentStatusApi({ paymentId: params.row.paymentid }));
          }}
        ></Button>
      ),
    },
    {
      field: "createDate",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })}`,
      width: 250,
    },
    {
      field: "description",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })}`,
      width: 250,
    },
    {
      field: "amount",
      headerName: `${t("payment.amt", { ns: ["home"] })}`,
      width: 200,
    },
    {
      field: "paymentLink",
      headerName: `${t("payment.pay", { ns: ["home"] })}`,
      width: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<PaymentIcon />}
          variant="outlined"
          onClick={() => {
            window.open(params.row.paymentLink);
          }}
        >
          Pay Now
        </Button>
      ),
    },
    {
      field: "paymentstatus",
      headerName: `${t("payment.checkstat", { ns: ["home"] })}`,
      width: 200,
    },
  ];

  const paymentStatusValue = useSelector(
    (state: AppState) => state.paymentStatus
  );

  const joinfeebody = {
    caseId: caseid,
  };
  const paymentListforOwnerValue = useSelector(
    (state: AppState) => state.joiningPaymentFee
  );

  const [joiningFeeValue, setJoiningFeeValue] = React.useState([] as any[]);
  const getPaymentList = () => {
    dispatch(joiningFeeApi(joinfeebody));
  };
  useEffect(() => {
    // getPaymentList();
    dispatch(joiningFeeApi(joinfeebody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    joiningDetailsLoading(paymentListforOwnerValue?.loading);
    if (paymentListforOwnerValue !== undefined) {
      let consultationValue =
        paymentListforOwnerValue.joiningFee.consultationType === ""
          ? "Not Selected"
          : paymentListforOwnerValue.joiningFee.consultationType;
      let responseTimeValue =
        paymentListforOwnerValue.joiningFee.responseTimeType === ""
          ? "Not Selected"
          : paymentListforOwnerValue.joiningFee.responseTimeType;
      let doctorSelect =
        paymentListforOwnerValue.joiningFee.doctorTeamType === ""
          ? "Not Selected"
          : paymentListforOwnerValue.joiningFee.doctorTeamType;
      setConsultationType(consultationValue);
      setResponseTimeType(responseTimeValue);
      setDoctorTeamType(doctorSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentListforOwnerValue]);

  useEffect(() => {
    if (paymentListforOwnerValue?.joiningFee?.paymentList !== undefined) {
      let allAppointmentListTableValues =
        paymentListforOwnerValue?.joiningFee?.paymentList.map(
          (element: any) => ({
            paymentid: element.id,
            paymentLink: element.paymentLink,
            description: element.note,
            amount: element.amount,
            createDate: dayjs(element.create_date).format("ddd, MMMM D, YYYY"),
            paymentstatus: element.status,
          })
        );
      setJoiningFeeValue(allAppointmentListTableValues);
    } else setJoiningFeeValue([] as Paymentlistowner[]);
  }, [paymentListforOwnerValue]);

  useEffect(() => {
    if (paymentStatusValue?.paymentStatusRes?.message !== undefined) {
      if (paymentStatusValue?.paymentStatusRes?.message !== "") {
        setToastSuccessMessage(paymentStatusValue?.paymentStatusRes?.message);
        dispatch(joiningFeeApi(joinfeebody));
      }
    }
    if (paymentStatusValue?.errRes !== "") {
      setToastErrorMessage(paymentStatusValue?.errRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusValue]);

  const pagebody = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">
                {t("payment.paylist", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={getPaymentList}
                    sx={{ textTransform: "none" }}
                  >
                    {t("payment.reloadpaylist", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent>
        {joiningFeeValue && joiningFeeValue.length ? (
          <DataGrid
            rows={joiningFeeValue}
            columns={joiningFeeTableColumns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return <Box>{pagebody}</Box>;
}
