import { GetWorkExperienceListRes } from "./Model";
export const GET_DOCTOR_WORK_EXPERIENCE_LIST_LOADING =
  "GET_DOCTOR_WORK_EXPERIENCE_LIST_LOADING";
export const GET_DOCTOR_WORK_EXPERIENCE_LIST_SUCCESS =
  "GET_DOCTOR_WORK_EXPERIENCE_LIST_SUCCESS";
export const GET_DOCTOR_WORK_EXPERIENCE_LIST_FAIL =
  "GET_DOCTOR_WORK_EXPERIENCE_LIST_FAIL";
export const GET_DOCTOR_WORK_EXPERIENCE_LIST_API_MSG =
  "GET_DOCTOR_WORK_EXPERIENCE_LIST_API_MSG";
export const GET_DOCTOR_WORK_EXPERIENCE_LIST_API_RES =
  "GET_DOCTOR_WORK_EXPERIENCE_LIST_API_RES";

export interface GetDoctorWorkExperienceListState {
  getDoctorWorkExperienceListRes: GetWorkExperienceListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorWorkExperienceListLoading {
  type: typeof GET_DOCTOR_WORK_EXPERIENCE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorWorkExperienceListSuccess {
  type: typeof GET_DOCTOR_WORK_EXPERIENCE_LIST_SUCCESS;
  payload: GetWorkExperienceListRes;
  successMsg: string;
}
export interface GetDoctorWorkExperienceListFail {
  type: typeof GET_DOCTOR_WORK_EXPERIENCE_LIST_FAIL;
  payload: GetWorkExperienceListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorWorkExperienceListAPIMsg {
  type: typeof GET_DOCTOR_WORK_EXPERIENCE_LIST_API_MSG;
  payload: GetWorkExperienceListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorWorkExperienceListAPIRes {
  type: typeof GET_DOCTOR_WORK_EXPERIENCE_LIST_API_RES;
  payload: GetWorkExperienceListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorWorkExperienceListActionTypes =
  | GetDoctorWorkExperienceListLoading
  | GetDoctorWorkExperienceListSuccess
  | GetDoctorWorkExperienceListFail
  | GetDoctorWorkExperienceListAPIMsg
  | GetDoctorWorkExperienceListAPIRes;
