import {
  GET_DOCTOR_LICENSE_LIST_FAIL,
  GET_DOCTOR_LICENSE_LIST_LOADING,
  GET_DOCTOR_LICENSE_LIST_SUCCESS,
  GET_DOCTOR_LICENSE_LIST_API_MSG,
  GET_DOCTOR_LICENSE_LIST_API_RES,
  GetDoctorLicenseListActionTypes,
} from "./ActionTypes";
import { GetLicenseListRes } from "./Model";

export const getDoctorLicenseListLoadingAction = (
  loading: boolean
): GetDoctorLicenseListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorLicenseListSuccessAction = (
  getDoctorLicenseListResponse: GetLicenseListRes,
  successMsg: string
): GetDoctorLicenseListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_LIST_SUCCESS,
    payload: getDoctorLicenseListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorLicenseListErrorAction = (
  getDoctorLicenseListResponse: GetLicenseListRes,
  errMsg: string,
  status: number
): GetDoctorLicenseListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_LIST_FAIL,
    payload: getDoctorLicenseListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorLicenseListAPIMsgAction = (
  getDoctorLicenseListResponse: GetLicenseListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorLicenseListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_LIST_API_MSG,
    payload: getDoctorLicenseListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorLicenseListAPIResClearAction = (
  getDoctorLicenseListResponse: GetLicenseListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorLicenseListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_LIST_API_RES,
    payload: getDoctorLicenseListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
