import { DoctorSignatureRes } from "./Model";
export const GET_DOCTOR_SIGNATURE_DETAILS_LOADING =
  "GET_DOCTOR_SIGNATURE_DETAILS_LOADING";
export const GET_DOCTOR_SIGNATURE_DETAILS_SUCCESS =
  "GET_DOCTOR_SIGNATURE_DETAILS_SUCCESS";
export const GET_DOCTOR_SIGNATURE_DETAILS_FAIL =
  "GET_DOCTOR_SIGNATURE_DETAILS_FAIL";
export const GET_DOCTOR_SIGNATURE_DETAILS_API_MSG =
  "GET_DOCTOR_SIGNATURE_DETAILS_API_MSG";
export const GET_DOCTOR_SIGNATURE_DETAILS_API_RES =
  "GET_DOCTOR_SIGNATURE_DETAILS_API_RES";

export interface GetDoctorSignatureDetailsState {
  getDoctorSignatureDetailsRes: DoctorSignatureRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorSignatureDetailsLoading {
  type: typeof GET_DOCTOR_SIGNATURE_DETAILS_LOADING;
  loading: boolean;
}
export interface GetDoctorSignatureDetailsSuccess {
  type: typeof GET_DOCTOR_SIGNATURE_DETAILS_SUCCESS;
  payload: DoctorSignatureRes;
  successMsg: string;
}
export interface GetDoctorSignatureDetailsFail {
  type: typeof GET_DOCTOR_SIGNATURE_DETAILS_FAIL;
  payload: DoctorSignatureRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorSignatureDetailsAPIMsg {
  type: typeof GET_DOCTOR_SIGNATURE_DETAILS_API_MSG;
  payload: DoctorSignatureRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorSignatureDetailsAPIRes {
  type: typeof GET_DOCTOR_SIGNATURE_DETAILS_API_RES;
  payload: DoctorSignatureRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorSignatureDetailsActionTypes =
  | GetDoctorSignatureDetailsLoading
  | GetDoctorSignatureDetailsSuccess
  | GetDoctorSignatureDetailsFail
  | GetDoctorSignatureDetailsAPIMsg
  | GetDoctorSignatureDetailsAPIRes;
