import { GetDetailsForCreatePrescriptionRes } from "./Model";
export const GET_DETAILS_FOR_CREATE_PRESCRIPTION_LOADING =
  "GET_DETAILS_FOR_CREATE_PRESCRIPTION_LOADING";
export const GET_DETAILS_FOR_CREATE_PRESCRIPTION_SUCCESS =
  "GET_DETAILS_FOR_CREATE_PRESCRIPTION_SUCCESS";
export const GET_DETAILS_FOR_CREATE_PRESCRIPTION_FAIL =
  "GET_DETAILS_FOR_CREATE_PRESCRIPTION_FAIL";
export const GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_MSG =
  "GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_MSG";
export const GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_RES =
  "GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_RES";

export interface GetDetailsForCreatePrescriptionState {
  getDetailsForCreatePrescriptionRes: GetDetailsForCreatePrescriptionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDetailsForCreatePrescriptionLoading {
  type: typeof GET_DETAILS_FOR_CREATE_PRESCRIPTION_LOADING;
  loading: boolean;
}
export interface GetDetailsForCreatePrescriptionSuccess {
  type: typeof GET_DETAILS_FOR_CREATE_PRESCRIPTION_SUCCESS;
  payload: GetDetailsForCreatePrescriptionRes;
  successMsg: string;
}
export interface GetDetailsForCreatePrescriptionFail {
  type: typeof GET_DETAILS_FOR_CREATE_PRESCRIPTION_FAIL;
  payload: GetDetailsForCreatePrescriptionRes;
  errorMsg: string;
  status: number;
}
export interface GetDetailsForCreatePrescriptionUpdateAPIMsg {
  type: typeof GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_MSG;
  payload: GetDetailsForCreatePrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDetailsForCreatePrescriptionUpdateAPIRes {
  type: typeof GET_DETAILS_FOR_CREATE_PRESCRIPTION_API_RES;
  payload: GetDetailsForCreatePrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDetailsForCreatePrescriptionActionTypes =
  | GetDetailsForCreatePrescriptionLoading
  | GetDetailsForCreatePrescriptionSuccess
  | GetDetailsForCreatePrescriptionFail
  | GetDetailsForCreatePrescriptionUpdateAPIMsg
  | GetDetailsForCreatePrescriptionUpdateAPIRes;
