import {
  EDIT_DR_LEGAL_DETAILS_FAIL,
  EDIT_DR_LEGAL_DETAILS_LOADING,
  EDIT_DR_LEGAL_DETAILS_SUCCESS,
  EDIT_DR_LEGAL_DETAILS_API_MSG,
  EDIT_DR_LEGAL_DETAILS_API_RES,
  EditDrLegalDetailsActionTypes,
} from "./ActionTypes";
import { EditLegalDetailsRes } from "./Model";

export const editDrLegalDetailsLoadingAction = (
  loading: boolean
): EditDrLegalDetailsActionTypes => {
  return {
    type: EDIT_DR_LEGAL_DETAILS_LOADING,
    loading: loading,
  };
};

export const editDrLegalDetailsSuccessAction = (
  editDrLegalDetailsResponse: EditLegalDetailsRes,
  successMsg: string
): EditDrLegalDetailsActionTypes => {
  return {
    type: EDIT_DR_LEGAL_DETAILS_SUCCESS,
    payload: editDrLegalDetailsResponse,
    successMsg: successMsg,
  };
};

export const editDrLegalDetailsErrorAction = (
  editDrLegalDetailsResponse: EditLegalDetailsRes,
  errMsg: string,
  status: number
): EditDrLegalDetailsActionTypes => {
  return {
    type: EDIT_DR_LEGAL_DETAILS_FAIL,
    payload: editDrLegalDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDrLegalDetailsAPIMsgAction = (
  editDrLegalDetailsResponse: EditLegalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrLegalDetailsActionTypes => {
  return {
    type: EDIT_DR_LEGAL_DETAILS_API_MSG,
    payload: editDrLegalDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDrLegalDetailsAPIResClearAction = (
  editDrLegalDetailsResponse: EditLegalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrLegalDetailsActionTypes => {
  return {
    type: EDIT_DR_LEGAL_DETAILS_API_RES,
    payload: editDrLegalDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
