import React from "react";
import Box from "@mui/material/Box";
import ImportedDataList from "./ImportedDataList";
import ImportConfig from "./ImportConfig";
import { Stack, Tab, Tabs } from "@mui/material";
import { t } from "i18next";
import MWPageTitle from "../../../components/MWPageTitle";

interface Props {
  label?: string;
  type?: string;
}

export default function ImportedData({ label, type }: Props) {
  const [selectedTab, setSelected] = React.useState(0);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      <MWPageTitle
        title={t(`filePreparationTab.${label}`, {
          ns: ["home"],
        })}
      />
      <Box sx={{ maxWidth: { xs: 320, sm: 480, lg: 1366, xl: 3840 } }}>
        <Box>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            scrollButtons
            allowScrollButtonsMobile
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#0088cc",
              },
            }}
            sx={{
              "& button:hover": { background: "" },
              "& button.Mui-selected": {
                background: "#fffff",
                color: "#2e73b9",
              },
            }}
          >
            <Tab
              label={t("filePreparationTab.imptdData", {
                ns: ["home"],
              })}
            ></Tab>
            <Tab label={t("impDataPage.impConfig", { ns: ["home"] })} />
          </Tabs>
        </Box>
        <Box sx={{ padding: 2 }}>
          {selectedTab === 0 && <ImportedDataList label={label} type={type} />}
          {selectedTab === 1 && <ImportConfig label={label} type={type} />}
        </Box>
      </Box>
    </Stack>
  );
}
