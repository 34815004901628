import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import {
  submitQueryActionTypes,
  SUBMIT_QUERY_FAIL,
  SUBMIT_QUERY_LOADING,
  SUBMIT_QUERY_SUCCESS,
} from "./ActionTypes";
import { SubmitQueryModel, SubmitQueryValue } from "./Model";
import { headers } from "components/Utility";

export const submitQueryApi = (body: SubmitQueryModel) => {
  return function (dispatch: Dispatch<submitQueryActionTypes>) {
    dispatch({
      type: SUBMIT_QUERY_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(apiURL.apiURL + `/doctorapp/api/doctorhelp/submit_query`, body, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: SUBMIT_QUERY_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_QUERY_FAIL,
          payload: {} as SubmitQueryValue,
          errRes: error.response.data.message || error.response.data.error,
        });
      });
  };
};
