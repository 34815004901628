import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorEducationListActionTypes } from "./ActionTypes";
import { GetEducationListRes } from "./Model";
import {
  getDoctorEducationListAPIResClearAction,
  getDoctorEducationListErrorAction,
  getDoctorEducationListLoadingAction,
  getDoctorEducationListSuccessAction,
  getDoctorEducationListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetEducationListRes;
export const getEducationList = () => {
  return function (dispatch: Dispatch<GetDoctorEducationListActionTypes>) {
    dispatch(getDoctorEducationListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/education_list_dropdown`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorEducationListLoadingAction(false));
        dispatch(
          getDoctorEducationListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Education list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorEducationListLoadingAction(false));
        dispatch(
          getDoctorEducationListErrorAction(
            {} as GetEducationListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorEducationListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorEducationListActionTypes>) {
    dispatch(
      getDoctorEducationListAPIMsgAction(apiRes as GetEducationListRes, "", 0)
    );
  };
};

export const clearGetDoctorEducationListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorEducationListActionTypes>) {
    dispatch(
      getDoctorEducationListAPIResClearAction({} as GetEducationListRes, "", 0)
    );
  };
};
