import {
  ADD_DOCTOR_EDUCATION_DETAILS_FAIL,
  ADD_DOCTOR_EDUCATION_DETAILS_LOADING,
  ADD_DOCTOR_EDUCATION_DETAILS_SUCCESS,
  ADD_DOCTOR_EDUCATION_DETAILS_API_MSG,
  ADD_DOCTOR_EDUCATION_DETAILS_API_RES,
  AddDoctorEducationDetailsActionTypes,
} from "./ActionTypes";
import { AddEducationDetailsRes } from "./Model";

export const addDoctorEducationDetailsLoadingAction = (
  loading: boolean
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_EDUCATION_DETAILS_LOADING,
    loading: loading,
  };
};

export const addDoctorEducationDetailsSuccessAction = (
  addDoctorEducationDetailsResponse: AddEducationDetailsRes,
  successMsg: string
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_EDUCATION_DETAILS_SUCCESS,
    payload: addDoctorEducationDetailsResponse,
    successMsg: successMsg,
  };
};

export const addDoctorEducationDetailsErrorAction = (
  addDoctorEducationDetailsResponse: AddEducationDetailsRes,
  errMsg: string,
  status: number
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_EDUCATION_DETAILS_FAIL,
    payload: addDoctorEducationDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDoctorEducationDetailsAPIMsgAction = (
  addDoctorEducationDetailsResponse: AddEducationDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_EDUCATION_DETAILS_API_MSG,
    payload: addDoctorEducationDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDoctorEducationDetailsAPIResClearAction = (
  addDoctorEducationDetailsResponse: AddEducationDetailsRes,
  successMsg: string,
  errorMsg: string,
  status: number
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: ADD_DOCTOR_EDUCATION_DETAILS_API_RES,
    payload: addDoctorEducationDetailsResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
