import {
    DeleteWorkScheduleDetails,
    DeleteWorkScheduleActionTypes,
    DELETE_WORK_SCHEDULE_FAIL,
    DELETE_WORK_SCHEDULE_LOADING,
    DELETE_WORK_SCHEDULE_SUCCESS,
  } from "./ActionTypes";
  import { DeleteWorkScheduleRes } from "./Model";
  
  const initialStateGetPosts: DeleteWorkScheduleDetails = {
    loading: false,
    deleteWorkScheduleRes: {} as DeleteWorkScheduleRes,
    errRes: "",
  };
  export const deleteWorkScheduleReducer = (
    state = initialStateGetPosts,
    action: DeleteWorkScheduleActionTypes
  ): DeleteWorkScheduleDetails => {
    switch (action.type) {
      case DELETE_WORK_SCHEDULE_SUCCESS:
        return {
          ...state,
          loading: false,
          deleteWorkScheduleRes: action.payload,
          errRes: "",
        };
      case DELETE_WORK_SCHEDULE_LOADING:
        return {
          ...state,
          loading: true,
          deleteWorkScheduleRes: {} as DeleteWorkScheduleRes,
        };
      case DELETE_WORK_SCHEDULE_FAIL:
        return {
          ...state,
          deleteWorkScheduleRes: {} as DeleteWorkScheduleRes,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  