import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { UpdateDoctorCoverLetterActionTypes } from "./ActionTypes";
import { EditCoverLetterBody, EditCoverLetterRes } from "./Model";
import {
  updateDoctorCoverLetterAPIResClearAction,
  updateDoctorCoverLetterErrorAction,
  updateDoctorCoverLetterLoadingAction,
  updateDoctorCoverLetterSuccessAction,
  updateDoctorCoverLetterAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EditCoverLetterRes;
export const updateDoctorCoverLetter = (payload: EditCoverLetterBody) => {
  return function (dispatch: Dispatch<UpdateDoctorCoverLetterActionTypes>) {
    dispatch(updateDoctorCoverLetterLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/edit_cover_letter`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updateDoctorCoverLetterLoadingAction(false));
        dispatch(
          updateDoctorCoverLetterSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. cover letter has been updated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(updateDoctorCoverLetterLoadingAction(false));
        dispatch(
          updateDoctorCoverLetterErrorAction(
            {} as EditCoverLetterRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateUpdateDoctorCoverLetterAPIResMsg = () => {
  return function (dispatch: Dispatch<UpdateDoctorCoverLetterActionTypes>) {
    dispatch(
      updateDoctorCoverLetterAPIMsgAction(apiRes as EditCoverLetterRes, "", "", 0)
    );
  };
};

export const clearUpdateDoctorCoverLetterAPIRes = () => {
  return function (dispatch: Dispatch<UpdateDoctorCoverLetterActionTypes>) {
    dispatch(
      updateDoctorCoverLetterAPIResClearAction(
        {} as EditCoverLetterRes,
        "",
        "",
        0
      )
    );
  };
};
