import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetPrescriptionListActionTypes } from "./ActionTypes";
import { GetPrescriptionListBody, GetPrescriptionListRes } from "./Model";
import {
  getPrescriptionListAPIResClearAction,
  getPrescriptionListErrorAction,
  getPrescriptionListLoadingAction,
  getPrescriptionListSuccessAction,
  getPrescriptionListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetPrescriptionListRes;
export const prescriptionListApi = (payload: GetPrescriptionListBody) => {
  return function (dispatch: Dispatch<GetPrescriptionListActionTypes>) {
    dispatch(getPrescriptionListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/patientapp/api/prescriptionTemplate/list_prescription`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPrescriptionListLoadingAction(false));
        dispatch(
          getPrescriptionListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : ""
          )
        );
      })
      .catch((error) => {
        dispatch(getPrescriptionListLoadingAction(false));
        dispatch(
          getPrescriptionListErrorAction(
            {} as GetPrescriptionListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateFinalOpinionDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPrescriptionListActionTypes>) {
    dispatch(
      getPrescriptionListAPIMsgAction(apiRes as GetPrescriptionListRes, "", "", 0)
    );
  };
};

export const clearEFinalOpinionDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetPrescriptionListActionTypes>) {
    dispatch(
      getPrescriptionListAPIResClearAction({} as GetPrescriptionListRes, "", "", 0)
    );
  };
};
