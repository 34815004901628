import {
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_FAIL,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_LOADING,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_RES,
  EditDoctorProfessionalSummaryActionTypes,
} from "./ActionTypes";
import { EditProfessionalSummaryRes } from "./Model";

export const editDoctorProfessionalSummaryLoadingAction = (
  loading: boolean
): EditDoctorProfessionalSummaryActionTypes => {
  return {
    type: EDIT_DOCTOR_PROFESSIONAL_SUMMARY_LOADING,
    loading: loading,
  };
};

export const editDoctorProfessionalSummarySuccessAction = (
  editDoctorProfessionalSummaryResponse: EditProfessionalSummaryRes,
  successMsg: string
): EditDoctorProfessionalSummaryActionTypes => {
  return {
    type: EDIT_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS,
    payload: editDoctorProfessionalSummaryResponse,
    successMsg: successMsg,
  };
};

export const editDoctorProfessionalSummaryErrorAction = (
  editDoctorProfessionalSummaryResponse: EditProfessionalSummaryRes,
  errMsg: string,
  status: number
): EditDoctorProfessionalSummaryActionTypes => {
  return {
    type: EDIT_DOCTOR_PROFESSIONAL_SUMMARY_FAIL,
    payload: editDoctorProfessionalSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDoctorProfessionalSummaryAPIMsgAction = (
  editDoctorProfessionalSummaryResponse: EditProfessionalSummaryRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDoctorProfessionalSummaryActionTypes => {
  return {
    type: EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG,
    payload: editDoctorProfessionalSummaryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDoctorProfessionalSummaryAPIResClearAction = (
  editDoctorProfessionalSummaryResponse: EditProfessionalSummaryRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDoctorProfessionalSummaryActionTypes => {
  return {
    type: EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_RES,
    payload: editDoctorProfessionalSummaryResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
