import {
  GetDoctorEducationSummaryActionTypes,
  GET_DOCTOR_EDUCATION_SUMMARY_FAIL,
  GET_DOCTOR_EDUCATION_SUMMARY_LOADING,
  GET_DOCTOR_EDUCATION_SUMMARY_SUCCESS,
  GetDoctorEducationSummaryState,
  GET_DOCTOR_EDUCATION_SUMMARY_API_MSG,
  GET_DOCTOR_EDUCATION_SUMMARY_API_RES,
} from "./ActionTypes";
import { GetEducationSummaryRes } from "./Model";

const initialStateUpdatePosts: GetDoctorEducationSummaryState = {
  loading: false,
  getDoctorEducationSummaryRes: {} as GetEducationSummaryRes,
  errorMsg: "",
  status: 0,
};
export const getEducationSummaryReducer = (
  state = initialStateUpdatePosts,
  action: GetDoctorEducationSummaryActionTypes
): GetDoctorEducationSummaryState => {
  switch (action.type) {
    case GET_DOCTOR_EDUCATION_SUMMARY_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_EDUCATION_SUMMARY_SUCCESS:
      return {
        ...state,
        getDoctorEducationSummaryRes: action.payload,
      };
    case GET_DOCTOR_EDUCATION_SUMMARY_FAIL:
      return {
        ...state,
        getDoctorEducationSummaryRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_EDUCATION_SUMMARY_API_MSG:
      return {
        ...state,
        getDoctorEducationSummaryRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_EDUCATION_SUMMARY_API_RES:
      return {
        ...state,
        getDoctorEducationSummaryRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
