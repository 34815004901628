import { GetDetailsForEditPrescriptionRes } from "./Model";
export const GET_DETAILS_FOR_EDIT_PRESCRIPTION_LOADING =
  "GET_DETAILS_FOR_EDIT_PRESCRIPTION_LOADING";
export const GET_DETAILS_FOR_EDIT_PRESCRIPTION_SUCCESS =
  "GET_DETAILS_FOR_EDIT_PRESCRIPTION_SUCCESS";
export const GET_DETAILS_FOR_EDIT_PRESCRIPTION_FAIL =
  "GET_DETAILS_FOR_EDIT_PRESCRIPTION_FAIL";
export const GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_MSG =
  "GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_MSG";
export const GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_RES =
  "GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_RES";

export interface GetDetailsForEditPrescriptionState {
  getDetailsForEditPrescriptionRes: GetDetailsForEditPrescriptionRes;
  
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDetailsForEditPrescriptionLoading {
  type: typeof GET_DETAILS_FOR_EDIT_PRESCRIPTION_LOADING;
  loading: boolean;
}
export interface GetDetailsForEditPrescriptionSuccess {
  type: typeof GET_DETAILS_FOR_EDIT_PRESCRIPTION_SUCCESS;
  payload: GetDetailsForEditPrescriptionRes;
  successMsg: string;
}
export interface GetDetailsForEditPrescriptionFail {
  type: typeof GET_DETAILS_FOR_EDIT_PRESCRIPTION_FAIL;
  payload: GetDetailsForEditPrescriptionRes;
  errorMsg: string;
  status: number;
}
export interface GetDetailsForEditPrescriptionAPIMsg {
  type: typeof GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_MSG;
  payload: GetDetailsForEditPrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDetailsForEditPrescriptionAPIRes {
  type: typeof GET_DETAILS_FOR_EDIT_PRESCRIPTION_API_RES;
  payload: GetDetailsForEditPrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDetailsForEditPrescriptionActionTypes =
  | GetDetailsForEditPrescriptionLoading
  | GetDetailsForEditPrescriptionSuccess
  | GetDetailsForEditPrescriptionFail
  | GetDetailsForEditPrescriptionAPIMsg
  | GetDetailsForEditPrescriptionAPIRes;
