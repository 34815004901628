import { EditDrIdProofRes } from "./Model";
export const EDIT_DR_ID_PROOF_LOADING = "EDIT_DR_ID_PROOF_LOADING";
export const EDIT_DR_ID_PROOF_SUCCESS = "EDIT_DR_ID_PROOF_SUCCESS";
export const EDIT_DR_ID_PROOF_FAIL = "EDIT_DR_ID_PROOF_FAIL";
export const EDIT_DR_ID_PROOF_API_MSG = "EDIT_DR_ID_PROOF_API_MSG";
export const EDIT_DR_ID_PROOF_API_RES = "EDIT_DR_ID_PROOF_API_RES";

export interface EditDrIdProofState {
  editDrIdProofRes: EditDrIdProofRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrIdProofLoading {
  type: typeof EDIT_DR_ID_PROOF_LOADING;
  loading: boolean;
}
export interface EditDrIdProofSuccess {
  type: typeof EDIT_DR_ID_PROOF_SUCCESS;
  payload: EditDrIdProofRes;
  successMsg: string;
}
export interface EditDrIdProofFail {
  type: typeof EDIT_DR_ID_PROOF_FAIL;
  payload: EditDrIdProofRes;
  errorMsg: string;
  status: number;
}
export interface EditDrIdProofAPIMsg {
  type: typeof EDIT_DR_ID_PROOF_API_MSG;
  payload: EditDrIdProofRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrIdProofAPIRes {
  type: typeof EDIT_DR_ID_PROOF_API_RES;
  payload: EditDrIdProofRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDrIdProofActionTypes =
  | EditDrIdProofLoading
  | EditDrIdProofSuccess
  | EditDrIdProofFail
  | EditDrIdProofAPIMsg
  | EditDrIdProofAPIRes;
