import { StepPreviewResponse } from "./Model";
export const STEP_PREVIEW_LIST_LOADING = "STEP_PREVIEW_LIST_LOADING";
export const STEP_PREVIEW_LIST_SUCCESS = "STEP_PREVIEW_LIST_SUCCESS";
export const STEP_PREVIEW_LIST_FAIL = "STEP_PREVIEW_LIST_FAIL";
export const STEP_PREVIEW_LIST_API_MSG = "STEP_PREVIEW_LIST_API_MSG";
export const STEP_PREVIEW_LIST_API_RES = "STEP_PREVIEW_LIST_API_RES";

export interface StepPreviewListState {
  stepPreviewListResponse: StepPreviewResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface StepPreviewListLoading {
  type: typeof STEP_PREVIEW_LIST_LOADING;
  loading: boolean;
}
export interface StepPreviewListSuccess {
  type: typeof STEP_PREVIEW_LIST_SUCCESS;
  payload: StepPreviewResponse;
  successMsg: string;
}
export interface StepPreviewListFail {
  type: typeof STEP_PREVIEW_LIST_FAIL;
  payload: StepPreviewResponse;
  errorMsg: string;
  status: number;
}
export interface StepPreviewListAPIMsg {
  type: typeof STEP_PREVIEW_LIST_API_MSG;
  payload: StepPreviewResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface StepPreviewListAPIRes {
  type: typeof STEP_PREVIEW_LIST_API_RES;
  payload: StepPreviewResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type StepPreviewListActionTypes =
  | StepPreviewListLoading
  | StepPreviewListSuccess
  | StepPreviewListFail
  | StepPreviewListAPIMsg
  | StepPreviewListAPIRes;
