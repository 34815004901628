import { GetTelehealthgListRes } from "./Model";
export const TELEHEALTH_DETAIL_LIST_LOADING =
  "TELEHEALTH_DETAIL_LIST_LOADING";
export const TELEHEALTH_DETAIL_LIST_SUCCESS =
  "TELEHEALTH_DETAIL_SUCCESS";
export const TELEHEALTH_DETAIL_LIST_FAIL = "TELEHEALTH_DETAIL_LIST_FAIL";
export const TELEHEALTH_DETAIL_LIST_LIST = "TELEHEALTH_DETAIL_LIST_LIST";

export interface getTelehealthListDetails {
  getTelehealthListRes: GetTelehealthgListRes;
  loading: boolean;
  errRes: string;
}
export interface getTelehealthListLoading {
  type: typeof TELEHEALTH_DETAIL_LIST_LOADING;
  // loading: boolean;
}
export interface getTelehealthListSuccess {
  type: typeof TELEHEALTH_DETAIL_LIST_SUCCESS;
  payload: GetTelehealthgListRes;
  errRes: string;
}
export interface getTelehealthListFail {
  type: typeof TELEHEALTH_DETAIL_LIST_FAIL;
  errRes: string;
}

interface getTelehealthListAction {
  type: typeof TELEHEALTH_DETAIL_LIST_LIST;
  payload: GetTelehealthgListRes;
  errRes: string;
}

export type getTelehealthListActionTypes =
  | getTelehealthListAction
  | getTelehealthListLoading
  | getTelehealthListSuccess
  | getTelehealthListFail;
