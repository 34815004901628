import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { withStyles } from "tss-react/mui";
import { ThemeProvider, StepButton, Paper, Box } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PaymentIcon from "@mui/icons-material/Payment";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import CallIcon from "@mui/icons-material/Call";
import StepConnector from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material";
import { Grid, Button } from "@mui/material";
import AppoinmentBooking from "./AppoinmentBooking";
import AddlHealthRecord from "./FilePreparation/AddHealthRecord";
import AddDoctorTeam from "./AddDoctorTeam";
import DoctorPatientInteraction from "./DoctorPatientInteraction";
// import { getPatientCaseList } from "redux/effects/ApiCall";
import { AppState } from "redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import { CaseList } from "redux/interfaces/Model";
import * as _ from "lodash";
import mwTheme from "mwTheme";
import { t } from "i18next";
import MWLoader from "components/MWLoader";
import { getDoctorCaseListApi } from "redux/GetDoctorCaseList/API";
import MWPageTitle from "components/MWPageTitle";
import PageLayout from "components/MWPageLayout";
import FinalOpinion from "./FinalOpinion";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

declare module "@mui/material" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
const ColorlibConnector = withStyles(
  StepConnector,
  (_theme, _params, classes) => ({
    alternativeLabel: {
      top: 22,
    },
    completed: {
      [`& .${classes.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(222, 54, 24) 0%,rgb(222, 95, 73) 50%,rgb(255, 183, 170) 100%)",
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: "#ccc",
      borderRadius: 1,
    },
  })
);
const useColorlibStepIconStyles = makeStyles()({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg,rgb(222, 54, 24) 0%, rgb(255, 183, 170) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg,rgb(222, 54, 24) 60%,rgb(255, 183, 170) 100%)",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const { classes, cx } = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <SettingsIcon />,
    2: <GroupIcon />,
    3: <ContactPhoneIcon />,
    4: <FileCopyIcon />,
    5: <GroupAddIcon />,
    6: <PostAddIcon />,
    7: <PaymentIcon />,
    8: <EmojiPeopleIcon />,
    9: <CallIcon />,
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      width: "100%",
      //backgroundColor: '#ccc',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AppoinmentDetails() {
  const history = useHistory();
  let query = useQuery();
  const tabId = query.get("tab");
  const { caseid } = useParams() as {
    caseid: string;
  };
  const loadingMarkup = <MWLoader />;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  // Call Store
  const taskReduxValue = useSelector((state: AppState) => state.caseValue);

  const taskDetails: CaseList = _.filter(
    taskReduxValue.getDoctorCaseListRes.patientCaseList,
    ["caseId", Number(caseid)]
  )[0];

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  function getSteps() {
    return [
      `${t("appointmentpage.interaction", { ns: ["home"] })!}`,
      `${t("appointmentpage.fileprep", { ns: ["home"] })!}`,
      `${t("appointmentpage.adddoc", { ns: ["home"] })!}`,
      `${t("appointmentpage.appbook", { ns: ["home"] })!}`,
      `${t("appointmentpage.finalopinion", { ns: ["home"] })!}`,
    ];
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep + 1 === 1) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=chiefComplaint"
        }`
      );
    } else if (activeStep + 1 === 2) {
      history.push(`${window.location.pathname + "?&tab=patientTeam"}`);
    } else if (activeStep + 1 === 3) {
      history.push(`${window.location.pathname + "?&tab=bookAppointments"}`);
    } else if (activeStep + 1 === 4) {
      history.push(`${window.location.pathname + "?&tab=secondopinion"}`);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep - 1 === 0) {
      history.push(
        `${window.location.pathname + "?&tab=doctorpatientinteraction"}`
      );
    } else if (activeStep - 1 === 1) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=chiefComplaint"
        }`
      );
    } else if (activeStep - 1 === 2) {
      history.push(`${window.location.pathname + "?&tab=patientTeam"}`);
    } else if (activeStep - 1 === 3) {
      history.push(`${window.location.pathname + "?&tab=bookAppointments"}`);
    }
  };
  const handleStep = (step: number) => () => {
    setActiveStep(step);
    if (step === 0) {
      history.push(
        `${window.location.pathname + "?&tab=doctorpatientinteraction"}`
      );
    } else if (step === 1) {
      history.push(
        `${
          window.location.pathname + "?&tab=filePreparation&fp=chiefComplaint"
        }`
      );
    } else if (step === 2) {
      history.push(`${window.location.pathname + "?&tab=patientTeam"}`);
    } else if (step === 3) {
      history.push(`${window.location.pathname + "?&tab=bookAppointments"}`);
    } else if (step === 4) {
      history.push(`${window.location.pathname + "?&tab=secondopinion"}`);
    }
  };

  useEffect(() => {
    if (tabId === "doctorpatientinteraction") {
      setActiveStep(0);
    } else if (tabId === "filePreparation") {
      setActiveStep(1);
    } else if (tabId === "patientTeam") {
      setActiveStep(2);
    } else if (tabId === "bookAppointments") {
      setActiveStep(3);
    } else if (tabId === "secondopinion") {
      setActiveStep(4);
    } else {
      setActiveStep(0);
    }
    // eslint-disable-next-line
  }, [tabId]);

  const [filePreparationLoader, setFilePreparationLoader] = useState(false);
  // Get Loading value from File Preparation step section
  const filePreparationLoaderUpdatedState = (passedVal: boolean) => {
    setFilePreparationLoader(passedVal);
  };

  // const [completed] = React.useState(new Set<number>());
  const getStepContent = (stepper: number) => {
    switch (stepper) {
      case 0:
        return <DoctorPatientInteraction />;
      case 1:
        return (
          <AddlHealthRecord
            filePreparationLoader={filePreparationLoaderUpdatedState}
          />
        );
      case 2:
        return <AddDoctorTeam />;
      case 3:
        return <AppoinmentBooking />;
      case 4:
        return <FinalOpinion />;
      default:
        return "Unknown step";
    }
  };

  useEffect(() => {
    dispatch(getDoctorCaseListApi());
  }, [dispatch]);
  useEffect(() => {
    dispatch(toggleAppBarLoading(taskReduxValue?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskReduxValue]);

  const pageBody = (
    <Box>
      {filePreparationLoader ? loadingMarkup : null}
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: "#FBEAE5",
          position: "sticky",
          top: "74px",
          zIndex: 2,
        }}
      >
        <Stepper
          alternativeLabel
          nonLinear
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepButton onClick={handleStep(index)}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Paper>
      <Box>
        {activeStep === steps.length ? "" : <>{getStepContent(activeStep)}</>}
      </Box>
      {activeStep === steps.length ? (
        ""
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.button}
          >
            {t("appointmentpage.back", { ns: ["home"] })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
          >
            {activeStep === steps.length - 1
              ? `${t("appointmentpage.close", { ns: ["home"] })!}`
              : `${t("appointmentpage.next", { ns: ["home"] })!}`}
          </Button>
        </Grid>
      )}
    </Box>
  );

  return (
    <React.Fragment>
      <PageLayout>
        <MWPageTitle
          backButton={true}
          title={`${
            taskDetails === undefined
              ? ""
              : taskDetails.patientName + "'s Details"
          }`}
          enableSubtitle={true}
          subtitle={`${
            taskDetails === undefined ? "" : taskDetails.whyWant2ndOpinion
          }`}
        />
        <ThemeProvider theme={mwTheme}>{pageBody}</ThemeProvider>
      </PageLayout>
    </React.Fragment>
  );
}
