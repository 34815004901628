import {
  UPDATE_DOCTOR_COVER_LETTER_FAIL,
  UPDATE_DOCTOR_COVER_LETTER_LOADING,
  UPDATE_DOCTOR_COVER_LETTER_SUCCESS,
  UPDATE_DOCTOR_COVER_LETTER_API_MSG,
  UPDATE_DOCTOR_COVER_LETTER_API_RES,
  UpdateDoctorCoverLetterActionTypes,
} from "./ActionTypes";
import { EditCoverLetterRes } from "./Model";

export const updateDoctorCoverLetterLoadingAction = (
  loading: boolean
): UpdateDoctorCoverLetterActionTypes => {
  return {
    type: UPDATE_DOCTOR_COVER_LETTER_LOADING,
    loading: loading,
  };
};

export const updateDoctorCoverLetterSuccessAction = (
  updateDoctorCoverLetterResponse: EditCoverLetterRes,
  successMsg: string
): UpdateDoctorCoverLetterActionTypes => {
  return {
    type: UPDATE_DOCTOR_COVER_LETTER_SUCCESS,
    payload: updateDoctorCoverLetterResponse,
    successMsg: successMsg,
  };
};

export const updateDoctorCoverLetterErrorAction = (
  updateDoctorCoverLetterResponse: EditCoverLetterRes,
  errMsg: string,
  status: number
): UpdateDoctorCoverLetterActionTypes => {
  return {
    type: UPDATE_DOCTOR_COVER_LETTER_FAIL,
    payload: updateDoctorCoverLetterResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updateDoctorCoverLetterAPIMsgAction = (
  updateDoctorCoverLetterResponse: EditCoverLetterRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDoctorCoverLetterActionTypes => {
  return {
    type: UPDATE_DOCTOR_COVER_LETTER_API_MSG,
    payload: updateDoctorCoverLetterResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const updateDoctorCoverLetterAPIResClearAction = (
  updateDoctorCoverLetterResponse: EditCoverLetterRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDoctorCoverLetterActionTypes => {
  return {
    type: UPDATE_DOCTOR_COVER_LETTER_API_RES,
    payload: updateDoctorCoverLetterResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
