import { EditDrWorkExperienceRes } from "./Model";
export const EDIT_DR_WORK_EXPERIENCE_LOADING =
  "EDIT_DR_WORK_EXPERIENCE_LOADING";
export const EDIT_DR_WORK_EXPERIENCE_SUCCESS =
  "EDIT_DR_WORK_EXPERIENCE_SUCCESS";
export const EDIT_DR_WORK_EXPERIENCE_FAIL = "EDIT_DR_WORK_EXPERIENCE_FAIL";
export const EDIT_DR_WORK_EXPERIENCE_API_MSG =
  "EDIT_DR_WORK_EXPERIENCE_API_MSG";
export const EDIT_DR_WORK_EXPERIENCE_API_RES =
  "EDIT_DR_WORK_EXPERIENCE_API_RES";

export interface EditDrWorkExperienceState {
  editDrWorkExperienceRes: EditDrWorkExperienceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrWorkExperienceLoading {
  type: typeof EDIT_DR_WORK_EXPERIENCE_LOADING;
  loading: boolean;
}
export interface EditDrWorkExperienceSuccess {
  type: typeof EDIT_DR_WORK_EXPERIENCE_SUCCESS;
  payload: EditDrWorkExperienceRes;
  successMsg: string;
}
export interface EditDrWorkExperienceFail {
  type: typeof EDIT_DR_WORK_EXPERIENCE_FAIL;
  payload: EditDrWorkExperienceRes;
  errorMsg: string;
  status: number;
}
export interface EditDrWorkExperienceAPIMsg {
  type: typeof EDIT_DR_WORK_EXPERIENCE_API_MSG;
  payload: EditDrWorkExperienceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrWorkExperienceAPIRes {
  type: typeof EDIT_DR_WORK_EXPERIENCE_API_RES;
  payload: EditDrWorkExperienceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDrWorkExperienceActionTypes =
  | EditDrWorkExperienceLoading
  | EditDrWorkExperienceSuccess
  | EditDrWorkExperienceFail
  | EditDrWorkExperienceAPIMsg
  | EditDrWorkExperienceAPIRes;
