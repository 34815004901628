import { GetGenderListRes } from "./Model";

export const GET_GENDER_LIST_LOADING =
  "GET_GENDER_LIST_LOADING";
export const GET_GENDER_LIST_SUCCESS =
  "GET_GENDER_LIST_SUCCESS";
export const GET_GENDER_LIST_FAIL =
  "GET_GENDER_LIST_FAIL";
export const GET_GENDER_LIST_DATA =
  "GET_GENDER_LIST_DATA";

export interface GenderListDetails {
  getGenderListRes: GetGenderListRes;
  loading: boolean;
  errRes: string;
}
export interface GetGenderListActionLoading {
  type: typeof GET_GENDER_LIST_LOADING;
}
export interface GetGenderListActionSuccess {
  type: typeof GET_GENDER_LIST_SUCCESS;
  payload: GetGenderListRes;
  errRes: string;
}
export interface GetGenderListActionFail {
  type: typeof GET_GENDER_LIST_FAIL;
  payload: GetGenderListRes;
  errRes: string;
}
interface GetGenderListActionAction {
  type: typeof GET_GENDER_LIST_DATA;
  payload: GetGenderListRes;
  errRes: string;
}

export type GetGenderListActionTypes =
  | GetGenderListActionAction
  | GetGenderListActionLoading
  | GetGenderListActionSuccess
  | GetGenderListActionFail;
