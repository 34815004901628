import { GetEducationListRes } from "./Model";
export const GET_DOCTOR_EDUCATION_LIST_LOADING =
  "GET_DOCTOR_EDUCATION_LIST_LOADING";
export const GET_DOCTOR_EDUCATION_LIST_SUCCESS =
  "GET_DOCTOR_EDUCATION_LIST_SUCCESS";
export const GET_DOCTOR_EDUCATION_LIST_FAIL = "GET_DOCTOR_EDUCATION_LIST_FAIL";
export const GET_DOCTOR_EDUCATION_LIST_API_MSG =
  "GET_DOCTOR_EDUCATION_LIST_API_MSG";
export const GET_DOCTOR_EDUCATION_LIST_API_RES =
  "GET_DOCTOR_EDUCATION_LIST_API_RES";

export interface GetDoctorEducationListState {
  getDoctorEducationListRes: GetEducationListRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationListLoading {
  type: typeof GET_DOCTOR_EDUCATION_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorEducationListSuccess {
  type: typeof GET_DOCTOR_EDUCATION_LIST_SUCCESS;
  payload: GetEducationListRes;
  successMsg: string;
}
export interface GetDoctorEducationListFail {
  type: typeof GET_DOCTOR_EDUCATION_LIST_FAIL;
  payload: GetEducationListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationListAPIMsg {
  type: typeof GET_DOCTOR_EDUCATION_LIST_API_MSG;
  payload: GetEducationListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationListAPIRes {
  type: typeof GET_DOCTOR_EDUCATION_LIST_API_RES;
  payload: GetEducationListRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorEducationListActionTypes =
  | GetDoctorEducationListLoading
  | GetDoctorEducationListSuccess
  | GetDoctorEducationListFail
  | GetDoctorEducationListAPIMsg
  | GetDoctorEducationListAPIRes;
