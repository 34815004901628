import {
  DeletePatientCaseActionTypes,
  DELETE_PATIENT_CASE_FAIL,
  DELETE_PATIENT_CASE_LOADING,
  DELETE_PATIENT_CASE_SUCCESS,
  DeletePatientCaseState,
  DELETE_PATIENT_CASE_UPDATE_API_MSG,
  DELETE_PATIENT_CASE_UPDATE_API_RES,
} from "./ActionTypes";
import { DeletePatientCaseRes } from "./Model";

const initialStateGetPosts: DeletePatientCaseState = {
  loading: false,
  deletePatientCaseRes: {} as DeletePatientCaseRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const deletePatientCaseReducer = (
  state = initialStateGetPosts,
  action: DeletePatientCaseActionTypes
): DeletePatientCaseState => {
  switch (action.type) {
    case DELETE_PATIENT_CASE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DELETE_PATIENT_CASE_SUCCESS:
      return {
        ...state,
        deletePatientCaseRes: action.payload,
      };
    case DELETE_PATIENT_CASE_FAIL:
      return {
        ...state,
        loading: false,
        deletePatientCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_PATIENT_CASE_UPDATE_API_MSG:
      return {
        ...state,
        loading: false,
        deletePatientCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DELETE_PATIENT_CASE_UPDATE_API_RES:
      return {
        ...state,
        loading: false,
        deletePatientCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
