import { AddDrInsuranceRes } from "./Model";
export const ADD_DR_INSURANCE_LOADING = "ADD_DR_INSURANCE_LOADING";
export const ADD_DR_INSURANCE_SUCCESS = "ADD_DR_INSURANCE_SUCCESS";
export const ADD_DR_INSURANCE_FAIL = "ADD_DR_INSURANCE_FAIL";
export const ADD_DR_INSURANCE_API_MSG = "ADD_DR_INSURANCE_API_MSG";
export const ADD_DR_INSURANCE_API_RES = "ADD_DR_INSURANCE_API_RES";

export interface AddDrInsuranceState {
  addDrInsuranceRes: AddDrInsuranceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrInsuranceLoading {
  type: typeof ADD_DR_INSURANCE_LOADING;
  loading: boolean;
}
export interface AddDrInsuranceSuccess {
  type: typeof ADD_DR_INSURANCE_SUCCESS;
  payload: AddDrInsuranceRes;
  successMsg: string;
}
export interface AddDrInsuranceFail {
  type: typeof ADD_DR_INSURANCE_FAIL;
  payload: AddDrInsuranceRes;
  errorMsg: string;
  status: number;
}
export interface AddDrInsuranceAPIMsg {
  type: typeof ADD_DR_INSURANCE_API_MSG;
  payload: AddDrInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrInsuranceAPIRes {
  type: typeof ADD_DR_INSURANCE_API_RES;
  payload: AddDrInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrInsuranceActionTypes =
  | AddDrInsuranceLoading
  | AddDrInsuranceSuccess
  | AddDrInsuranceFail
  | AddDrInsuranceAPIMsg
  | AddDrInsuranceAPIRes;
