import { AddSignatureRes } from "./Model";
export const ADD_DOCTOR_SIGNATURE_DETAILS_LOADING =
  "ADD_DOCTOR_SIGNATURE_DETAILS_LOADING";
export const ADD_DOCTOR_SIGNATURE_DETAILS_SUCCESS =
  "ADD_DOCTOR_SIGNATURE_DETAILS_SUCCESS";
export const ADD_DOCTOR_SIGNATURE_DETAILS_FAIL =
  "ADD_DOCTOR_SIGNATURE_DETAILS_FAIL";
export const ADD_DOCTOR_SIGNATURE_DETAILS_API_MSG =
  "ADD_DOCTOR_SIGNATURE_DETAILS_API_MSG";
export const ADD_DOCTOR_SIGNATURE_DETAILS_API_RES =
  "ADD_DOCTOR_SIGNATURE_DETAILS_API_RES";

export interface AddDoctorSignatureDetailsState {
  addDoctorSignatureDetailsRes: AddSignatureRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorSignatureDetailsLoading {
  type: typeof ADD_DOCTOR_SIGNATURE_DETAILS_LOADING;
  loading: boolean;
}
export interface AddDoctorSignatureDetailsSuccess {
  type: typeof ADD_DOCTOR_SIGNATURE_DETAILS_SUCCESS;
  payload: AddSignatureRes;
  successMsg: string;
}
export interface AddDoctorSignatureDetailsFail {
  type: typeof ADD_DOCTOR_SIGNATURE_DETAILS_FAIL;
  payload: AddSignatureRes;
  errorMsg: string;
  status: number;
}
export interface AddDoctorSignatureDetailsAPIMsg {
  type: typeof ADD_DOCTOR_SIGNATURE_DETAILS_API_MSG;
  payload: AddSignatureRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorSignatureDetailsAPIRes {
  type: typeof ADD_DOCTOR_SIGNATURE_DETAILS_API_RES;
  payload: AddSignatureRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDoctorSignatureDetailsActionTypes =
  | AddDoctorSignatureDetailsLoading
  | AddDoctorSignatureDetailsSuccess
  | AddDoctorSignatureDetailsFail
  | AddDoctorSignatureDetailsAPIMsg
  | AddDoctorSignatureDetailsAPIRes;
