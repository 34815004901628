import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { UpdateDoctorEducationSummaryActionTypes } from "./ActionTypes";
import { UpdateEducationSummaryBody, UpdateEducationSummaryRes } from "./Model";
import {
  updateDoctorEducationSummaryAPIResClearAction,
  updateDoctorEducationSummaryErrorAction,
  updateDoctorEducationSummaryLoadingAction,
  updateDoctorEducationSummarySuccessAction,
  updateDoctorEducationSummaryAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as UpdateEducationSummaryRes;
export const updateEducationSummary = (payload: UpdateEducationSummaryBody) => {
  return function (
    dispatch: Dispatch<UpdateDoctorEducationSummaryActionTypes>
  ) {
    dispatch(updateDoctorEducationSummaryLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/edit_education_summary`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(updateDoctorEducationSummaryLoadingAction(false));
        dispatch(
          updateDoctorEducationSummarySuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Education summary has been updated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(updateDoctorEducationSummaryLoadingAction(false));
        dispatch(
          updateDoctorEducationSummaryErrorAction(
            {} as UpdateEducationSummaryRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDoctorEducationSummaryAPIResMsg = () => {
  return function (
    dispatch: Dispatch<UpdateDoctorEducationSummaryActionTypes>
  ) {
    dispatch(
      updateDoctorEducationSummaryAPIMsgAction(
        apiRes as UpdateEducationSummaryRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearUpdateDoctorEducationSUmmaryAPIRes = () => {
  return function (
    dispatch: Dispatch<UpdateDoctorEducationSummaryActionTypes>
  ) {
    dispatch(
      updateDoctorEducationSummaryAPIResClearAction(
        {} as UpdateEducationSummaryRes,
        "",
        "",
        0
      )
    );
  };
};
