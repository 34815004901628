import React from "react";
import PageLayout from "components/MWPageLayout";
import MWPageTitle from "components/MWPageTitle";
import Task from "./Task";
import { t } from "i18next";
export default function Dashboard() {
  return (
    <PageLayout>
      <MWPageTitle
        title={t("docdashboardpage.docdashboard", { ns: ["home"] })!}
      />
      <Task />
    </PageLayout>
  );
}
