import {
  EMAIL_OTP_SIGNUP_FAIL,
  EMAIL_OTP_SIGNUP_LOADING,
  EMAIL_OTP_SIGNUP_SUCCESS,
  EMAIL_OTP_SIGNUP_UPDATE_API_MSG,
  EMAIL_OTP_SIGNUP_UPDATE_API_RES,
  EmailOTPSignupActionTypes,
} from "./ActionTypes";
import { EmailOTPSignUpRes } from "./Model";

export const emailOTPGenLoadingAction = (
  loading: boolean
): EmailOTPSignupActionTypes => {
  return {
    type: EMAIL_OTP_SIGNUP_LOADING,
    loading: loading,
  };
};

export const emailOTPGenSuccessAction = (
  emailOTPGenResponse: EmailOTPSignUpRes,
  successMsg: string
): EmailOTPSignupActionTypes => {
  return {
    type: EMAIL_OTP_SIGNUP_SUCCESS,
    payload: emailOTPGenResponse,
    successMsg: successMsg,
  };
};

export const emailOTPGenErrorAction = (
  emailOTPGenResponse: EmailOTPSignUpRes,
  errMsg: string,
  status: number
): EmailOTPSignupActionTypes => {
  return {
    type: EMAIL_OTP_SIGNUP_FAIL,
    payload: emailOTPGenResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const emailOTPGenUpdateAPIMsgAction = (
  emailOTPGenResponse: EmailOTPSignUpRes,
  successMsg: string,
  errMsg: string,
  status: number
): EmailOTPSignupActionTypes => {
  return {
    type: EMAIL_OTP_SIGNUP_UPDATE_API_MSG,
    payload: emailOTPGenResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const emailOTPGenAPIResClearAction = (
  emailOTPGenResponse: EmailOTPSignUpRes,
  successMsg: string,
  errMsg: string,
  status: number
): EmailOTPSignupActionTypes => {
  return {
    type: EMAIL_OTP_SIGNUP_UPDATE_API_RES,
    payload: emailOTPGenResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
