import {
  GetDoctorLegalDetailsActionTypes,
  GET_DOCTOR_LEGAL_DETAILS_FAIL,
  GET_DOCTOR_LEGAL_DETAILS_LOADING,
  GET_DOCTOR_LEGAL_DETAILS_SUCCESS,
  GetDoctorLegalDetailsState,
  GET_DOCTOR_LEGAL_DETAILS_API_MSG,
  GET_DOCTOR_LEGAL_DETAILS_API_RES,
} from "./ActionTypes";
import { GetLegalDetailsByDoctorRes } from "./Model";

const initialStateUpdatePosts: GetDoctorLegalDetailsState = {
  loading: false,
  getDoctorLegalDetailsRes: {} as GetLegalDetailsByDoctorRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getLegalDetailsReducer = (
  state = initialStateUpdatePosts,
  action: GetDoctorLegalDetailsActionTypes
): GetDoctorLegalDetailsState => {
  switch (action.type) {
    case GET_DOCTOR_LEGAL_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_LEGAL_DETAILS_SUCCESS:
      return {
        ...state,
        getDoctorLegalDetailsRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DOCTOR_LEGAL_DETAILS_FAIL:
      return {
        ...state,
        getDoctorLegalDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LEGAL_DETAILS_API_MSG:
      return {
        ...state,
        getDoctorLegalDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_LEGAL_DETAILS_API_RES:
      return {
        ...state,
        getDoctorLegalDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
