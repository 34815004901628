import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../URL";
import { GetDoctorDetailsActionTypes } from "./ActionTypes";
import { GetDoctorDetailsRes, LoginBody } from "./Model";
import {
  getDoctorDetailsAPIResClearAction,
  getDoctorDetailsErrorAction,
  getDoctorDetailsLoadingAction,
  getDoctorDetailsSuccessAction,
  getDoctorDetailsAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";

let apiRes = {} as GetDoctorDetailsRes;
export const getDoctorLoginDetails = (payload: LoginBody) => {
  let APIHeader = headers;
  APIHeader.Authorization = `Bearer ${payload.token}`;
  return function (dispatch: Dispatch<GetDoctorDetailsActionTypes>) {
    dispatch(getDoctorDetailsLoadingAction(true));
    axios
      .get(Url.apiURL + `/doctorapp/api/doctor/doctorLoginDetails`, {
        headers: APIHeader,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorDetailsLoadingAction(false));
        dispatch(
          getDoctorDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Login successful"
          )
        );
        localStorage.setItem("loginDetails", JSON.stringify(res.data!)!);
      })
      .catch((error) => {
        dispatch(getDoctorDetailsLoadingAction(false));
        dispatch(
          getDoctorDetailsErrorAction(
            {} as GetDoctorDetailsRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
        localStorage.clear();
      });
  };
};

export const getDoctorDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorDetailsActionTypes>) {
    dispatch(
      getDoctorDetailsAPIMsgAction(apiRes as GetDoctorDetailsRes, "", 0)
    );
  };
};

export const clearGetDoctorDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorDetailsActionTypes>) {
    dispatch(
      getDoctorDetailsAPIResClearAction({} as GetDoctorDetailsRes, "", 0)
    );
  };
};
