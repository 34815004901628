import { GetProfessionalMembershipRes } from "./Model";
export const GET_DOCTOR_MEMBERSHIP_LIST_LOADING =
  "GET_DOCTOR_MEMBERSHIP_LIST_LOADING";
export const GET_DOCTOR_MEMBERSHIP_LIST_SUCCESS =
  "GET_DOCTOR_MEMBERSHIP_LIST_SUCCESS";
export const GET_DOCTOR_MEMBERSHIP_LIST_FAIL =
  "GET_DOCTOR_MEMBERSHIP_LIST_FAIL";
export const GET_DOCTOR_MEMBERSHIP_LIST_API_MSG =
  "GET_DOCTOR_MEMBERSHIP_LIST_API_MSG";
export const GET_DOCTOR_MEMBERSHIP_LIST_API_RES =
  "GET_DOCTOR_MEMBERSHIP_LIST_API_RES";

export interface GetDoctorMembershipListState {
  getDoctorMembershipListRes: GetProfessionalMembershipRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorMembershipListLoading {
  type: typeof GET_DOCTOR_MEMBERSHIP_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorMembershipListSuccess {
  type: typeof GET_DOCTOR_MEMBERSHIP_LIST_SUCCESS;
  payload: GetProfessionalMembershipRes;
  successMsg: string;
}
export interface GetDoctorMembershipListFail {
  type: typeof GET_DOCTOR_MEMBERSHIP_LIST_FAIL;
  payload: GetProfessionalMembershipRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorMembershipListAPIMsg {
  type: typeof GET_DOCTOR_MEMBERSHIP_LIST_API_MSG;
  payload: GetProfessionalMembershipRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorMembershipListAPIRes {
  type: typeof GET_DOCTOR_MEMBERSHIP_LIST_API_RES;
  payload: GetProfessionalMembershipRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDoctorMembershipListActionTypes =
  | GetDoctorMembershipListLoading
  | GetDoctorMembershipListSuccess
  | GetDoctorMembershipListFail
  | GetDoctorMembershipListAPIMsg
  | GetDoctorMembershipListAPIRes;
