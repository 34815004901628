import {
    SECTION_TEMPLATE_LIST_FAIL,
    SECTION_TEMPLATE_LIST_LOADING,
    SECTION_TEMPLATE_LIST_SUCCESS,
    SECTION_TEMPLATE_LIST_API_MSG,
    SECTION_TEMPLATE_LIST_API_RES,
    SectionTemplateListActionTypes,
  } from "./ActionTypes";
  import { SectionTemplateListResponse } from "./Model";
  
  export const sectionTemplateListLoadingAction = (
    loading: boolean
  ): SectionTemplateListActionTypes => {
    return {
      type: SECTION_TEMPLATE_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const sectionTemplateListSuccessAction = (
    sectionTemplateListResponse: SectionTemplateListResponse,
    successMsg: string
  ): SectionTemplateListActionTypes => {
    return {
      type: SECTION_TEMPLATE_LIST_SUCCESS,
      payload: sectionTemplateListResponse,
      successMsg: successMsg,
    };
  };
  
  export const sectionTemplateListErrorAction = (
    sectionTemplateListResponse: SectionTemplateListResponse,
    errMsg: string,
    status: number
  ): SectionTemplateListActionTypes => {
    return {
      type: SECTION_TEMPLATE_LIST_FAIL,
      payload: sectionTemplateListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const sectionTemplateListAPIMsgAction = (
    sectionTemplateListResponse: SectionTemplateListResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): SectionTemplateListActionTypes => {
    return {
      type: SECTION_TEMPLATE_LIST_API_MSG,
      payload: sectionTemplateListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const sectionTemplateListAPIResClearAction = (
    sectionTemplateListResponse: SectionTemplateListResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): SectionTemplateListActionTypes => {
    return {
      type: SECTION_TEMPLATE_LIST_API_RES,
      payload: sectionTemplateListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };