import { GetLegalSummaryRes } from "./Model";
export const GET_DOCTOR_LEGAL_SUMMARY_LOADING =
  "GET_DOCTOR_LEGAL_SUMMARY_LOADING";
export const GET_DOCTOR_LEGAL_SUMMARY_SUCCESS =
  "GET_DOCTOR_LEGAL_SUMMARY_SUCCESS";
export const GET_DOCTOR_LEGAL_SUMMARY_FAIL = "GET_DOCTOR_LEGAL_SUMMARY_FAIL";
export const GET_DOCTOR_LEGAL_SUMMARY_API_MSG =
  "GET_DOCTOR_LEGAL_SUMMARY_API_MSG";
export const GET_DOCTOR_LEGAL_SUMMARY_API_RES =
  "GET_DOCTOR_LEGAL_SUMMARY_API_RES";

export interface GetDoctorLegalSummaryState {
  getDoctorLegalSummaryRes: GetLegalSummaryRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalSummaryLoading {
  type: typeof GET_DOCTOR_LEGAL_SUMMARY_LOADING;
  loading: boolean;
}
export interface GetDoctorLegalSummarySuccess {
  type: typeof GET_DOCTOR_LEGAL_SUMMARY_SUCCESS;
  payload: GetLegalSummaryRes;
  successMsg: string;
}
export interface GetDoctorLegalSummaryFail {
  type: typeof GET_DOCTOR_LEGAL_SUMMARY_FAIL;
  payload: GetLegalSummaryRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalSummaryAPIMsg {
  type: typeof GET_DOCTOR_LEGAL_SUMMARY_API_MSG;
  payload: GetLegalSummaryRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalSummaryAPIRes {
  type: typeof GET_DOCTOR_LEGAL_SUMMARY_API_RES;
  payload: GetLegalSummaryRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorLegalSummaryActionTypes =
  | GetDoctorLegalSummaryLoading
  | GetDoctorLegalSummarySuccess
  | GetDoctorLegalSummaryFail
  | GetDoctorLegalSummaryAPIMsg
  | GetDoctorLegalSummaryAPIRes;
