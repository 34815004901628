import {
  ADD_EMAIL_PHONE_FAIL,
  ADD_EMAIL_PHONE_LOADING,
  ADD_EMAIL_PHONE_SUCCESS,
  ADD_EMAIL_PHONE_UPDATE_API_MSG,
  ADD_EMAIL_PHONE_UPDATE_API_RES,
  AddEmailPhoneTypes,
} from "./ActionTypes";
import { AddEmailPhoneRes } from "./Model";

export const addEmailPhoneLoadingAction = (loading: boolean): AddEmailPhoneTypes => {
  return {
    type: ADD_EMAIL_PHONE_LOADING,
    loading: loading,
  };
};

export const addEmailPhoneSuccessAction = (
  addEmailPhoneResponse: AddEmailPhoneRes,
  successMsg: string
): AddEmailPhoneTypes => {
  return {
    type: ADD_EMAIL_PHONE_SUCCESS,
    payload: addEmailPhoneResponse,
    successMsg: successMsg,
  };
};

export const addEmailPhoneErrorAction = (
  addEmailPhoneResponse: AddEmailPhoneRes,
  errMsg: string,
  status: number
): AddEmailPhoneTypes => {
  return {
    type: ADD_EMAIL_PHONE_FAIL,
    payload: addEmailPhoneResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addEmailPhoneUpdateAPIMsgAction = (
  addEmailPhoneResponse: AddEmailPhoneRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddEmailPhoneTypes => {
  return {
    type: ADD_EMAIL_PHONE_UPDATE_API_MSG,
    payload: addEmailPhoneResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addEmailPhoneAPIResClearAction = (
  addEmailPhoneResponse: AddEmailPhoneRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddEmailPhoneTypes => {
  return {
    type: ADD_EMAIL_PHONE_UPDATE_API_RES,
    payload: addEmailPhoneResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
