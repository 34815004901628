import * as React from "react";
import { useHistory } from "react-router-dom";
import { Chip, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

type Props = {
  backButton?: boolean | any;
  title?: string | any;
  enableSubtitle?: boolean | any;
  subtitle?: string | any;
  enableCount?: boolean | any;
  count?: string | any;
  reload?: boolean | any;
  reloadAction?: boolean | any;
};

export default function MWPageTitle({
  backButton,
  title,
  enableSubtitle,
  subtitle,
  enableCount,
  count,
  reload,
  reloadAction,
}: Props) {
  const history = useHistory();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
    >
      {backButton && (
        <Grid item>
          <IconButton aria-label="fingerprint" onClick={() => history.goBack()}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item sx={{ maxWidth: "600px" }}>
        {title && (
          <Typography variant="h5" fontWeight="bold">
            {title !== "" ? title : null}
          </Typography>
        )}
        {enableSubtitle && (
          // <Typography variant="subtitle1" sx={{ color: "#DE3618" }}>
          <Typography variant="subtitle1" sx={{ color: "#1E90FF" }}>
            {subtitle !== "" ? subtitle : null}
          </Typography>
        )}
      </Grid>
      {enableCount && count >= 0 && (
        <Grid item>
          <Chip
            label={count !== undefined ? String(count) : "0"}
            color="primary"
            size="small"
          />
        </Grid>
      )}
      {reload && (
        <Grid item>
          <IconButton onClick={() => reloadAction(true)}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
