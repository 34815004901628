import {
  EDIT_DR_PROFESSIONAL_MEMBERSHIP_FAIL,
  EDIT_DR_PROFESSIONAL_MEMBERSHIP_LOADING,
  EDIT_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS,
  EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_MSG,
  EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_RES,
  EditDrProfessionalMembershipActionTypes,
} from "./ActionTypes";
import { EditProfessionalMembershipRes } from "./Model";

export const editDrProfessionalMembershipLoadingAction = (
  loading: boolean
): EditDrProfessionalMembershipActionTypes => {
  return {
    type: EDIT_DR_PROFESSIONAL_MEMBERSHIP_LOADING,
    loading: loading,
  };
};

export const editDrProfessionalMembershipSuccessAction = (
  editDrProfessionalMembershipResponse: EditProfessionalMembershipRes,
  successMsg: string
): EditDrProfessionalMembershipActionTypes => {
  return {
    type: EDIT_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS,
    payload: editDrProfessionalMembershipResponse,
    successMsg: successMsg,
  };
};

export const editDrProfessionalMembershipErrorAction = (
  editDrProfessionalMembershipResponse: EditProfessionalMembershipRes,
  errMsg: string,
  status: number
): EditDrProfessionalMembershipActionTypes => {
  return {
    type: EDIT_DR_PROFESSIONAL_MEMBERSHIP_FAIL,
    payload: editDrProfessionalMembershipResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDrProfessionalMembershipAPIMsgAction = (
  editDrProfessionalMembershipResponse: EditProfessionalMembershipRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrProfessionalMembershipActionTypes => {
  return {
    type: EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_MSG,
    payload: editDrProfessionalMembershipResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDrProfessionalMembershipAPIResClearAction = (
  editDrProfessionalMembershipResponse: EditProfessionalMembershipRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrProfessionalMembershipActionTypes => {
  return {
    type: EDIT_DR_PROFESSIONAL_MEMBERSHIP_API_RES,
    payload: editDrProfessionalMembershipResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
