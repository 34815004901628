import {
  JWT_LOGIN_FAIL,
  JWT_LOGIN_LOADING,
  JWT_LOGIN_SUCCESS,
  JWT_LOGIN_UPDATE_API_MSG,
  JWT_LOGIN_UPDATE_API_RES,
  JwtTokenActionTypes,
} from "./ActionTypes";
import { LoginRes } from "./Model";

export const loginLoadingAction = (
  loading: boolean
): JwtTokenActionTypes => {
  return {
    type: JWT_LOGIN_LOADING,
    loading: loading,
  };
};

export const loginSuccessAction = (
  loginResponse: LoginRes,
  successMsg: string
): JwtTokenActionTypes => {
  return {
    type: JWT_LOGIN_SUCCESS,
    payload: loginResponse,
    successMsg: successMsg,
  };
};

export const loginErrorAction = (
  loginResponse: LoginRes,
  errMsg: string,
  status: number
): JwtTokenActionTypes => {
  return {
    type: JWT_LOGIN_FAIL,
    payload: loginResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const loginUpdateAPIMsgAction = (
  loginResponse: LoginRes,
  successMsg: string,
  errMsg: string,
  status: number
): JwtTokenActionTypes => {
  return {
    type: JWT_LOGIN_UPDATE_API_MSG,
    payload: loginResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const loginAPIResClearAction = (
  loginResponse: LoginRes,
  successMsg: string,
  errMsg: string,
  status: number
): JwtTokenActionTypes => {
  return {
    type: JWT_LOGIN_UPDATE_API_RES,
    payload: loginResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
