import {
  AddDoctorEducationDetailsActionTypes,
  EDIT_DOCTOR_EDUCATION_DETAILS_FAIL,
  EDIT_DOCTOR_EDUCATION_DETAILS_LOADING,
  EDIT_DOCTOR_EDUCATION_DETAILS_SUCCESS,
  AddDoctorEducationDetailsState,
  EDIT_DOCTOR_EDUCATION_DETAILS_API_MSG,
  EDIT_DOCTOR_EDUCATION_DETAILS_API_RES,
} from "./ActionTypes";
import { EditEducationDetailsRes } from "./Model";

const initialStateUpdatePosts: AddDoctorEducationDetailsState = {
  loading: false,
  editDoctorEducationDetailsRes: {} as EditEducationDetailsRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editEducationDetailsReducer = (
  state = initialStateUpdatePosts,
  action: AddDoctorEducationDetailsActionTypes
): AddDoctorEducationDetailsState => {
  switch (action.type) {
    case EDIT_DOCTOR_EDUCATION_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_DOCTOR_EDUCATION_DETAILS_SUCCESS:
      return {
        ...state,
        editDoctorEducationDetailsRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_DOCTOR_EDUCATION_DETAILS_FAIL:
      return {
        ...state,
        editDoctorEducationDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DOCTOR_EDUCATION_DETAILS_API_MSG:
      return {
        ...state,
        editDoctorEducationDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DOCTOR_EDUCATION_DETAILS_API_RES:
      return {
        ...state,
        editDoctorEducationDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
