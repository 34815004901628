import {
  SET_TELE_HEALTH_CREDENTIALS_FAIL,
  SET_TELE_HEALTH_CREDENTIALS_LOADING,
  SET_TELE_HEALTH_CREDENTIALS_SUCCESS,
  SET_TELE_HEALTH_CREDENTIALS_API_MSG,
  SET_TELE_HEALTH_CREDENTIALS_API_RES,
  DrAppointmentDecisionActionTypes,
} from "./ActionTypes";
import { SetTeleHealthCredentialRes } from "./Model";

export const setDrTeleHealthCredentialsLoadingAction = (
  loading: boolean
): DrAppointmentDecisionActionTypes => {
  return {
    type: SET_TELE_HEALTH_CREDENTIALS_LOADING,
    loading: loading,
  };
};

export const setDrTeleHealthCredentialsSuccessAction = (
  setDrTeleHealthCredentialsRes: SetTeleHealthCredentialRes,
  successMsg: string
): DrAppointmentDecisionActionTypes => {
  return {
    type: SET_TELE_HEALTH_CREDENTIALS_SUCCESS,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
  };
};

export const setDrTeleHealthCredentialsErrorAction = (
  setDrTeleHealthCredentialsRes: SetTeleHealthCredentialRes,
  errMsg: string,
  status: number
): DrAppointmentDecisionActionTypes => {
  return {
    type: SET_TELE_HEALTH_CREDENTIALS_FAIL,
    payload: setDrTeleHealthCredentialsRes,
    errorMsg: errMsg,
    status: status,
  };
};
export const setDrTeleHealthCredentialsAPIMsgAction = (
  setDrTeleHealthCredentialsRes: SetTeleHealthCredentialRes,
  successMsg: string,
  errMsg: string,
  status: number
): DrAppointmentDecisionActionTypes => {
  return {
    type: SET_TELE_HEALTH_CREDENTIALS_API_MSG,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const setDrTeleHealthCredentialsAPIResClearAction = (
  setDrTeleHealthCredentialsRes: SetTeleHealthCredentialRes,
  successMsg: string,
  errMsg: string,
  status: number
): DrAppointmentDecisionActionTypes => {
  return {
    type: SET_TELE_HEALTH_CREDENTIALS_API_RES,
    payload: setDrTeleHealthCredentialsRes,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
