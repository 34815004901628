import {
  EDIT_DR_WORK_LICENSE_FAIL,
  EDIT_DR_WORK_LICENSE_LOADING,
  EDIT_DR_WORK_LICENSE_SUCCESS,
  EDIT_DR_WORK_LICENSE_API_MSG,
  EDIT_DR_WORK_LICENSE_API_RES,
  EditDrLicenseActionTypes,
} from "./ActionTypes";
import { EditProfessionalLicenseRes } from "./Model";

export const editDrLicenseLoadingAction = (
  loading: boolean
): EditDrLicenseActionTypes => {
  return {
    type: EDIT_DR_WORK_LICENSE_LOADING,
    loading: loading,
  };
};

export const editDrLicenseSuccessAction = (
  editDrLicenseResponse: EditProfessionalLicenseRes,
  successMsg: string
): EditDrLicenseActionTypes => {
  return {
    type: EDIT_DR_WORK_LICENSE_SUCCESS,
    payload: editDrLicenseResponse,
    successMsg: successMsg,
  };
};

export const editDrLicenseErrorAction = (
  editDrLicenseResponse: EditProfessionalLicenseRes,
  errMsg: string,
  status: number
): EditDrLicenseActionTypes => {
  return {
    type: EDIT_DR_WORK_LICENSE_FAIL,
    payload: editDrLicenseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDrLicenseAPIMsgAction = (
  editDrLicenseResponse: EditProfessionalLicenseRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrLicenseActionTypes => {
  return {
    type: EDIT_DR_WORK_LICENSE_API_MSG,
    payload: editDrLicenseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDrLicenseAPIResClearAction = (
  editDrLicenseResponse: EditProfessionalLicenseRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrLicenseActionTypes => {
  return {
    type: EDIT_DR_WORK_LICENSE_API_RES,
    payload: editDrLicenseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
