import { DoctorListForCaseRes } from "./Model";
export const DOCTOR_LIST_FOR_CASE_LOADING =
  "DOCTOR_LIST_FOR_CASE_LOADING";
export const DOCTOR_LIST_FOR_CASE_SUCCESS =
  "DOCTOR_LIST_FOR_CASE_SUCCESS";
export const DOCTOR_LIST_FOR_CASE_FAIL = "DOCTOR_LIST_FOR_CASE_FAIL";
export const DOCTOR_LIST_FOR_CASE_API_MSG =
  "DOCTOR_LIST_FOR_CASE_API_MSG";
export const DOCTOR_LIST_FOR_CASE_API_RES =
  "DOCTOR_LIST_FOR_CASE_API_RES";

export interface DoctorListForPatientCaseState {
  doctorListForPatientCaseRes: DoctorListForCaseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorListForPatientCaseLoading {
  type: typeof DOCTOR_LIST_FOR_CASE_LOADING;
  loading: boolean;
}
export interface DoctorListForPatientCaseSuccess {
  type: typeof DOCTOR_LIST_FOR_CASE_SUCCESS;
  payload: DoctorListForCaseRes;
  successMsg: string;
}
export interface DoctorListForPatientCaseFail {
  type: typeof DOCTOR_LIST_FOR_CASE_FAIL;
  payload: DoctorListForCaseRes;
  errorMsg: string;
  status: number;
}
export interface DoctorListForPatientCaseAPIMsg {
  type: typeof DOCTOR_LIST_FOR_CASE_API_MSG;
  payload: DoctorListForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorListForPatientCaseAPIRes {
  type: typeof DOCTOR_LIST_FOR_CASE_API_RES;
  payload: DoctorListForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DoctorListForCaseActionTypes =
  | DoctorListForPatientCaseLoading
  | DoctorListForPatientCaseSuccess
  | DoctorListForPatientCaseFail
  | DoctorListForPatientCaseAPIMsg
  | DoctorListForPatientCaseAPIRes;
