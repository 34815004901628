import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { AddDoctorEducationDetailsActionTypes } from "./ActionTypes";
import { AddEducationDetailsRes, AddDoctorEducationDetailsBody } from "./Model";
import {
  addDoctorEducationDetailsAPIResClearAction,
  addDoctorEducationDetailsErrorAction,
  addDoctorEducationDetailsLoadingAction,
  addDoctorEducationDetailsSuccessAction,
  addDoctorEducationDetailsAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddEducationDetailsRes;
export const addDoctorEducationDetails = (
  payload: AddDoctorEducationDetailsBody
) => {
  return function (dispatch: Dispatch<AddDoctorEducationDetailsActionTypes>) {
    dispatch(addDoctorEducationDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/add_education_detail`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDoctorEducationDetailsLoadingAction(false));
        dispatch(
          addDoctorEducationDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor education details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDoctorEducationDetailsLoadingAction(false));
        dispatch(
          addDoctorEducationDetailsErrorAction(
            {} as AddEducationDetailsRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const addDoctorEducationDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDoctorEducationDetailsActionTypes>) {
    dispatch(
      addDoctorEducationDetailsAPIMsgAction(
        apiRes as AddEducationDetailsRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAddDoctorEducationDetailsAPIRes = () => {
  return function (dispatch: Dispatch<AddDoctorEducationDetailsActionTypes>) {
    dispatch(
      addDoctorEducationDetailsAPIResClearAction(
        {} as AddEducationDetailsRes,
        "",
        "",
        0
      )
    );
  };
};
