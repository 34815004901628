import { ForgotPasswordRes } from "./Model";
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_UPDATE_API_MSG = "FORGOT_PASSWORD_UPDATE_API_MSG";
export const FORGOT_PASSWORD_UPDATE_API_RES = "FORGOT_PASSWORD_UPDATE_API_RES";

export interface ForgetPasswordState {
  forgotPasswordRes: ForgotPasswordRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ForgetPasswordLoading {
  type: typeof FORGOT_PASSWORD_LOADING;
  loading: boolean;
}
export interface ForgetPasswordSuccess {
  type: typeof FORGOT_PASSWORD_SUCCESS;
  payload: ForgotPasswordRes;
  successMsg: string;
}
export interface ForgetPasswordFail {
  type: typeof FORGOT_PASSWORD_FAIL;
  payload: ForgotPasswordRes;
  errorMsg: string;
  status: number;
}
export interface ForgetPasswordUpdateAPIMsg {
  type: typeof FORGOT_PASSWORD_UPDATE_API_MSG;
  payload: ForgotPasswordRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ForgetPasswordUpdateAPIRes {
  type: typeof FORGOT_PASSWORD_UPDATE_API_RES;
  payload: ForgotPasswordRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ForgetPasswordActionTypes =
  | ForgetPasswordLoading
  | ForgetPasswordSuccess
  | ForgetPasswordFail
  | ForgetPasswordUpdateAPIMsg
  | ForgetPasswordUpdateAPIRes;
