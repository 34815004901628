import {
  AddDrLicenseActionTypes,
  ADD_DR_WORK_LICENSE_FAIL,
  ADD_DR_WORK_LICENSE_LOADING,
  ADD_DR_WORK_LICENSE_SUCCESS,
  AddDrLicenseState,
  ADD_DR_WORK_LICENSE_API_MSG,
  ADD_DR_WORK_LICENSE_API_RES,
} from "./ActionTypes";
import { AddDrProfessionalLicenseRes } from "./Model";

const initialStateEditPosts: AddDrLicenseState = {
  loading: false,
  addDrLicenseRes: {} as AddDrProfessionalLicenseRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDrLicenseReducer = (
  state = initialStateEditPosts,
  action: AddDrLicenseActionTypes
): AddDrLicenseState => {
  switch (action.type) {
    case ADD_DR_WORK_LICENSE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DR_WORK_LICENSE_SUCCESS:
      return {
        ...state,
        addDrLicenseRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DR_WORK_LICENSE_FAIL:
      return {
        ...state,
        addDrLicenseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_WORK_LICENSE_API_MSG:
      return {
        ...state,
        addDrLicenseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_WORK_LICENSE_API_RES:
      return {
        ...state,
        addDrLicenseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
