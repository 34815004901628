import {
  GeneratePrescriptionActionTypes,
  GENERATE_PRESCRIPTION_FAIL,
  GENERATE_PRESCRIPTION_LOADING,
  GENERATE_PRESCRIPTION_SUCCESS,
  GeneratePrescriptionState,
  GENERATE_PRESCRIPTION_API_MSG,
  GENERATE_PRESCRIPTION_API_RES,
} from "./ActionTypes";
import { GeneratePrescriptionRes } from "./Model";

const initialStateEditPosts: GeneratePrescriptionState = {
  loading: false,
  generatePrescriptionRes: {} as GeneratePrescriptionRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const generatePrescriptionReducer = (
  state = initialStateEditPosts,
  action: GeneratePrescriptionActionTypes
): GeneratePrescriptionState => {
  switch (action.type) {
    case GENERATE_PRESCRIPTION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GENERATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        generatePrescriptionRes: action.payload,
        successMsg: action.successMsg,
      };
    case GENERATE_PRESCRIPTION_FAIL:
      return {
        ...state,
        generatePrescriptionRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GENERATE_PRESCRIPTION_API_MSG:
      return {
        ...state,
        generatePrescriptionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GENERATE_PRESCRIPTION_API_RES:
      return {
        ...state,
        generatePrescriptionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
