import {
  GET_DOCTOR_AWARD_LIST_FAIL,
  GET_DOCTOR_AWARD_LIST_LOADING,
  GET_DOCTOR_AWARD_LIST_SUCCESS,
  GET_DOCTOR_AWARD_LIST_API_MSG,
  GET_DOCTOR_AWARD_LIST_API_RES,
  GetDoctorAwardListActionTypes,
} from "./ActionTypes";
import { GetAwardListRes } from "./Model";

export const getDoctorAwardListLoadingAction = (
  loading: boolean
): GetDoctorAwardListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorAwardListSuccessAction = (
  getDoctorAwardListResponse: GetAwardListRes,
  successMsg: string
): GetDoctorAwardListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_LIST_SUCCESS,
    payload: getDoctorAwardListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorAwardListErrorAction = (
  getDoctorAwardListResponse: GetAwardListRes,
  errMsg: string,
  status: number
): GetDoctorAwardListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_LIST_FAIL,
    payload: getDoctorAwardListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorAwardListAPIMsgAction = (
  getDoctorAwardListResponse: GetAwardListRes,
  errMsg: string,
  status: number
): GetDoctorAwardListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_LIST_API_MSG,
    payload: getDoctorAwardListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorAwardListAPIResClearAction = (
  getDoctorAwardListResponse: GetAwardListRes,
  errMsg: string,
  status: number
): GetDoctorAwardListActionTypes => {
  return {
    type: GET_DOCTOR_AWARD_LIST_API_RES,
    payload: getDoctorAwardListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
