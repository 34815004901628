import { GetEducationSummaryRes } from "./Model";
export const GET_DOCTOR_EDUCATION_SUMMARY_LOADING =
  "GET_DOCTOR_EDUCATION_SUMMARY_LOADING";
export const GET_DOCTOR_EDUCATION_SUMMARY_SUCCESS =
  "GET_DOCTOR_EDUCATION_SUMMARY_SUCCESS";
export const GET_DOCTOR_EDUCATION_SUMMARY_FAIL =
  "GET_DOCTOR_EDUCATION_SUMMARY_FAIL";
export const GET_DOCTOR_EDUCATION_SUMMARY_API_MSG =
  "GET_DOCTOR_EDUCATION_SUMMARY_API_MSG";
export const GET_DOCTOR_EDUCATION_SUMMARY_API_RES =
  "GET_DOCTOR_EDUCATION_SUMMARY_API_RES";

export interface GetDoctorEducationSummaryState {
  getDoctorEducationSummaryRes: GetEducationSummaryRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationSummaryLoading {
  type: typeof GET_DOCTOR_EDUCATION_SUMMARY_LOADING;
  loading: boolean;
}
export interface GetDoctorEducationSummarySuccess {
  type: typeof GET_DOCTOR_EDUCATION_SUMMARY_SUCCESS;
  payload: GetEducationSummaryRes;
  successMsg: string;
}
export interface GetDoctorEducationSummaryFail {
  type: typeof GET_DOCTOR_EDUCATION_SUMMARY_FAIL;
  payload: GetEducationSummaryRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationSummaryAPIMsg {
  type: typeof GET_DOCTOR_EDUCATION_SUMMARY_API_MSG;
  payload: GetEducationSummaryRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationSummaryAPIRes {
  type: typeof GET_DOCTOR_EDUCATION_SUMMARY_API_RES;
  payload: GetEducationSummaryRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorEducationSummaryActionTypes =
  | GetDoctorEducationSummaryLoading
  | GetDoctorEducationSummarySuccess
  | GetDoctorEducationSummaryFail
  | GetDoctorEducationSummaryAPIMsg
  | GetDoctorEducationSummaryAPIRes;
