import { SectionTemplateListResponse } from "./Model";
export const SECTION_TEMPLATE_LIST_LOADING = "SECTION_TEMPLATE_LIST_LOADING";
export const SECTION_TEMPLATE_LIST_SUCCESS = "SECTION_TEMPLATE_LIST_SUCCESS";
export const SECTION_TEMPLATE_LIST_FAIL = "SECTION_TEMPLATE_LIST_FAIL";
export const SECTION_TEMPLATE_LIST_API_MSG = "SECTION_TEMPLATE_LIST_API_MSG";
export const SECTION_TEMPLATE_LIST_API_RES = "SECTION_TEMPLATE_LIST_API_RES";

export interface SectionTemplateListState {
  sectionTemplateListResponse: SectionTemplateListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SectionTemplateListLoading {
  type: typeof SECTION_TEMPLATE_LIST_LOADING;
  loading: boolean;
}
export interface SectionTemplateListSuccess {
  type: typeof SECTION_TEMPLATE_LIST_SUCCESS;
  payload: SectionTemplateListResponse;
  successMsg: string;
}
export interface SectionTemplateListFail {
  type: typeof SECTION_TEMPLATE_LIST_FAIL;
  payload: SectionTemplateListResponse;
  errorMsg: string;
  status: number;
}
export interface SectionTemplateListAPIMsg {
  type: typeof SECTION_TEMPLATE_LIST_API_MSG;
  payload: SectionTemplateListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SectionTemplateListAPIRes {
  type: typeof SECTION_TEMPLATE_LIST_API_RES;
  payload: SectionTemplateListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SectionTemplateListActionTypes =
  | SectionTemplateListLoading
  | SectionTemplateListSuccess
  | SectionTemplateListFail
  | SectionTemplateListAPIMsg
  | SectionTemplateListAPIRes;
