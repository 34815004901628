import {
    ASK_QUESTION_FAIL,
    ASK_QUESTION_LOADING,
    ASK_QUESTION_SUCCESS,
    ASK_QUESTION_API_MSG,
    ASK_QUESTION_API_RES,
    AskQuestionActionTypes,
  } from "./ActionTypes";
  import { AskQuestionResponse } from "./Model";
  
  export const askQuestionLoadingAction = (
    loading: boolean
  ): AskQuestionActionTypes => {
    return {
      type: ASK_QUESTION_LOADING,
      loading: loading,
    };
  };
  
  export const askQuestionSuccessAction = (
    askQuestionResponse: AskQuestionResponse,
    successMsg: string
  ): AskQuestionActionTypes => {
    return {
      type: ASK_QUESTION_SUCCESS,
      payload: askQuestionResponse,
      successMsg: successMsg,
    };
  };
  
  export const askQuestionErrorAction = (
    askQuestionResponse: AskQuestionResponse,
    errMsg: string,
    status: number
  ): AskQuestionActionTypes => {
    return {
      type: ASK_QUESTION_FAIL,
      payload: askQuestionResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const askQuestionAPIMsgAction = (
    askQuestionResponse: AskQuestionResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): AskQuestionActionTypes => {
    return {
      type: ASK_QUESTION_API_MSG,
      payload: askQuestionResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const askQuestionAPIResClearAction = (
    askQuestionResponse: AskQuestionResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): AskQuestionActionTypes => {
    return {
      type: ASK_QUESTION_API_RES,
      payload: askQuestionResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };