import {
  GET_PATIENT_DATA_LIST_FAIL,
  GET_PATIENT_DATA_LIST_LOADING,
  GET_PATIENT_DATA_LIST_SUCCESS,
  GET_PATIENT_DATA_LIST_UPDATE_API_MSG,
  GET_PATIENT_DATA_LIST_UPDATE_API_RES,
  GetPatientDataListActionTypes,
} from "./ActionTypes";
import { GetPatientDataListRes } from "./Model";

export const getPatientDataListLoadingAction = (
  loading: boolean
): GetPatientDataListActionTypes => {
  return {
    type: GET_PATIENT_DATA_LIST_LOADING,
    loading: loading,
  };
};

export const getPatientDataListSuccessAction = (
  getPatientDataListResponse: GetPatientDataListRes,
  successMsg: string
): GetPatientDataListActionTypes => {
  return {
    type: GET_PATIENT_DATA_LIST_SUCCESS,
    payload: getPatientDataListResponse,
    successMsg: successMsg,
  };
};

export const getPatientDataListErrorAction = (
  getPatientDataListResponse: GetPatientDataListRes,
  errMsg: string,
  status: number
): GetPatientDataListActionTypes => {
  return {
    type: GET_PATIENT_DATA_LIST_FAIL,
    payload: getPatientDataListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientDataListUpdateAPIMsgAction = (
  getPatientDataListResponse: GetPatientDataListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientDataListActionTypes => {
  return {
    type: GET_PATIENT_DATA_LIST_UPDATE_API_MSG,
    payload: getPatientDataListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientDataListAPIResClearAction = (
  getPatientDataListResponse: GetPatientDataListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientDataListActionTypes => {
  return {
    type: GET_PATIENT_DATA_LIST_UPDATE_API_RES,
    payload: getPatientDataListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
