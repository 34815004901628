import {
  JwtTokenActionTypes,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  ResetPasswordState,
  RESET_PASSWORD_UPDATE_API_MSG,
  RESET_PASSWORD_UPDATE_API_RES,
} from "./ActionTypes";
import { ResetPasswordRes } from "./Model";

const initialStateGetPosts: ResetPasswordState = {
  loading: false,
  resetPasswordRes: {} as ResetPasswordRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const resetPasswordReducer = (
  state = initialStateGetPosts,
  action: JwtTokenActionTypes
): ResetPasswordState => {
  switch (action.type) {
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordRes: action.payload,
        successMsg: action.successMsg,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case RESET_PASSWORD_UPDATE_API_MSG:
      return {
        ...state,
        resetPasswordRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case RESET_PASSWORD_UPDATE_API_RES:
      return {
        ...state,
        resetPasswordRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
