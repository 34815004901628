import { GetQuestionnaireListRes } from "./Model";
export const GET_QUESTIONNAIRE_LIST_LOADING = "GET_QUESTIONNAIRE_LIST_LOADING";
export const GET_QUESTIONNAIRE_LIST_SUCCESS = "GET_QUESTIONNAIRE_LIST_SUCCESS";
export const GET_QUESTIONNAIRE_LIST_FAIL = "GET_QUESTIONNAIRE_LIST_FAIL";
export const GET_QUESTIONNAIRE_LIST_UPDATE_API_MSG =
  "GET_QUESTIONNAIRE_LIST_UPDATE_API_MSG";
export const GET_QUESTIONNAIRE_LIST_UPDATE_API_RES =
  "GET_QUESTIONNAIRE_LIST_UPDATE_API_RES";

export interface GetQuestionnaireListState {
  getQuestionnaireListRes: GetQuestionnaireListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetQuestionnaireListLoading {
  type: typeof GET_QUESTIONNAIRE_LIST_LOADING;
  loading: boolean;
}
export interface GetQuestionnaireListSuccess {
  type: typeof GET_QUESTIONNAIRE_LIST_SUCCESS;
  payload: GetQuestionnaireListRes;
  successMsg: string;
}
export interface GetQuestionnaireListFail {
  type: typeof GET_QUESTIONNAIRE_LIST_FAIL;
  payload: GetQuestionnaireListRes;
  errorMsg: string;
  status: number;
}
export interface GetQuestionnaireListUpdateAPIMsg {
  type: typeof GET_QUESTIONNAIRE_LIST_UPDATE_API_MSG;
  payload: GetQuestionnaireListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetQuestionnaireListUpdateAPIRes {
  type: typeof GET_QUESTIONNAIRE_LIST_UPDATE_API_RES;
  payload: GetQuestionnaireListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetQuestionnaireListActionTypes =
  | GetQuestionnaireListLoading
  | GetQuestionnaireListSuccess
  | GetQuestionnaireListFail
  | GetQuestionnaireListUpdateAPIMsg
  | GetQuestionnaireListUpdateAPIRes;
