import {
  ADD_PATIENT_IMPORT_CONFIG_FAIL,
  ADD_PATIENT_IMPORT_CONFIG_LOADING,
  ADD_PATIENT_IMPORT_CONFIG_SUCCESS,
  ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_MSG,
  ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_RES,
  AddPatientImportConfigDataActionTypes,
} from "./ActionTypes";
import { AddPatientImportConfigDataRes } from "./Model";

export const addAddPatientImportConfigDataLoadingAction = (
  loading: boolean
): AddPatientImportConfigDataActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_LOADING,
    loading: loading,
  };
};

export const addAddPatientImportConfigDataSuccessAction = (
  addAddPatientImportConfigDataResponse: AddPatientImportConfigDataRes,
  successMsg: string
): AddPatientImportConfigDataActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_SUCCESS,
    payload: addAddPatientImportConfigDataResponse,
    successMsg: successMsg,
  };
};

export const addAddPatientImportConfigDataErrorAction = (
  addAddPatientImportConfigDataResponse: AddPatientImportConfigDataRes,
  errMsg: string,
  status: number
): AddPatientImportConfigDataActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_FAIL,
    payload: addAddPatientImportConfigDataResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addAddPatientImportConfigDataUpdateAPIMsgAction = (
  addAddPatientImportConfigDataResponse: AddPatientImportConfigDataRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientImportConfigDataActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_MSG,
    payload: addAddPatientImportConfigDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addAddPatientImportConfigDataAPIResClearAction = (
  addAddPatientImportConfigDataResponse: AddPatientImportConfigDataRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientImportConfigDataActionTypes => {
  return {
    type: ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_RES,
    payload: addAddPatientImportConfigDataResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
