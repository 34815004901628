import {
  DoctorListForCaseActionTypes,
  DOCTOR_LIST_FOR_CASE_FAIL,
  DOCTOR_LIST_FOR_CASE_LOADING,
  DOCTOR_LIST_FOR_CASE_SUCCESS,
  DoctorListForPatientCaseState,
  DOCTOR_LIST_FOR_CASE_API_MSG,
  DOCTOR_LIST_FOR_CASE_API_RES,
} from "./ActionTypes";
import { DoctorListForCaseRes } from "./Model";

const initialStateEditPosts: DoctorListForPatientCaseState = {
  loading: false,
  doctorListForPatientCaseRes: {} as DoctorListForCaseRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const doctorListForCaseReducer = (
  state = initialStateEditPosts,
  action: DoctorListForCaseActionTypes
): DoctorListForPatientCaseState => {
  switch (action.type) {
    case DOCTOR_LIST_FOR_CASE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case DOCTOR_LIST_FOR_CASE_SUCCESS:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        successMsg: action.successMsg,
      };
    case DOCTOR_LIST_FOR_CASE_FAIL:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DOCTOR_LIST_FOR_CASE_API_MSG:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case DOCTOR_LIST_FOR_CASE_API_RES:
      return {
        ...state,
        doctorListForPatientCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
