import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../URL";
import { MeetingListActionTypes } from "./ActionTypes";
import { MeetingListResponse, MeetingListBody } from "./Model";
import {
  meetingListAPIResClearAction,
  meetingListErrorAction,
  meetingListLoadingAction,
  meetingListSuccessAction,
  meetingListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as MeetingListResponse;
export const meetingListApi = (payload: MeetingListBody) => {
  return function (dispatch: Dispatch<MeetingListActionTypes>) {
    dispatch(meetingListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/telehealthapp/api/telehealth/getMeetingList`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(meetingListLoadingAction(false));
        dispatch(
          meetingListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Question list fetch successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(meetingListLoadingAction(false));
        dispatch(
          meetingListErrorAction(
            {} as MeetingListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const meetingListAPIResMsg = () => {
  return function (dispatch: Dispatch<MeetingListActionTypes>) {
    dispatch(
      meetingListAPIMsgAction(apiRes as MeetingListResponse, "", "", 0)
    );
  };
};

export const clearMeetingListAPIRes = () => {
  return function (dispatch: Dispatch<MeetingListActionTypes>) {
    dispatch(
      meetingListAPIResClearAction({} as MeetingListResponse, "", "", 0)
    );
  };
};