import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type Props = {
  icon?: boolean | any;
  title?: string | any;
  showSubtitle?: boolean | any;
  subtitle?: boolean | any;
  showReason?: boolean | any;
  reason?: string | any;
  showPatient?: boolean | any;
  patient?: string | any;
  caseId?: number | any;
  patientId?: number | any;
  showNavigation?: boolean | any;
};

export default function MWResourceList({
  icon,
  title,
  showSubtitle,
  subtitle,
  showReason,
  reason,
  showPatient,
  patient,
  showNavigation,
}: Props) {
  return (
    <Paper>
      <List disablePadding dense>
        <ListItem>
          <ListItemButton>
            <ListItemIcon>
              {icon ? (
                <VisibilityOutlinedIcon />
              ) : (
                <Avatar
                  src="/broken-image.jpg"
                  style={{ height: "25px", width: "25px" }}
                />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography fontWeight="bold" sx={{ color: "#330101" }}>
                  {title !== undefined ? (title !== "" ? title : null) : null}
                </Typography>
              }
              secondary={
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ textDecoration: "none" }}
                  >
                    {showSubtitle
                      ? subtitle !== undefined
                        ? subtitle !== ""
                          ? subtitle
                          : null
                        : null
                      : null}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    {showReason ? (
                      <Grid item>
                        <Chip
                          size="small"
                          color="primary"
                          label={
                            reason !== undefined
                              ? reason !== ""
                                ? reason
                                : null
                              : null
                          }
                        />
                      </Grid>
                    ) : null}
                    {showPatient ? (
                      <Grid item>
                        <Chip
                          size="small"
                          color="primary"
                          label={
                            patient !== undefined
                              ? patient !== ""
                                ? patient
                                : null
                              : null
                          }
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              }
            />
            {
              <IconButton edge="end" aria-label="delete" disabled>
                {showNavigation ? <ChevronRightIcon /> : null}
              </IconButton>
            }
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
    </Paper>
  );
}
