import { DeleteSelectedDoctorValue } from "./Model";
export const DELETE_SELECTED_DOCTOR_LOADING =
  "DELETE_SELECTED_DOCTOR_LOADING";
export const DELETE_SELECTED_DOCTOR_SUCCESS =
  "DELETE_SELECTED_DOCTOR_SUCCESS";
export const DELETE_SELECTED_DOCTOR_FAIL = "DELETE_SELECTED_DOCTOR_FAIL";
export const DELETE_SELECTED_DOCTOR_LIST = "DELETE_SELECTED_DOCTOR_LIST";

export interface DeleteSelectedDoctorDetails {
  deleteSelectedDoctorRes: DeleteSelectedDoctorValue;
  loading: boolean;
  errRes: string;
}
export interface DeleteSelectedDoctorLoading {
  type: typeof DELETE_SELECTED_DOCTOR_LOADING;
}
export interface DeleteSelectedDoctorSuccess {
  type: typeof DELETE_SELECTED_DOCTOR_SUCCESS;
  payload: DeleteSelectedDoctorValue;
  errRes: string;
}
export interface DeleteSelectedDoctorFail {
  type: typeof DELETE_SELECTED_DOCTOR_FAIL;
  errRes: string;
}

interface DeleteSelectedDoctorAction {
  type: typeof DELETE_SELECTED_DOCTOR_LIST;
  payload: DeleteSelectedDoctorValue;
  errRes: string;
}

export type deleteSelectedDoctorActionTypes =
  | DeleteSelectedDoctorAction
  | DeleteSelectedDoctorLoading
  | DeleteSelectedDoctorSuccess
  | DeleteSelectedDoctorFail;
