import { EditProfessionalLicenseRes } from "./Model";
export const EDIT_DR_WORK_LICENSE_LOADING = "EDIT_DR_WORK_LICENSE_LOADING";
export const EDIT_DR_WORK_LICENSE_SUCCESS = "EDIT_DR_WORK_LICENSE_SUCCESS";
export const EDIT_DR_WORK_LICENSE_FAIL = "EDIT_DR_WORK_LICENSE_FAIL";
export const EDIT_DR_WORK_LICENSE_API_MSG = "EDIT_DR_WORK_LICENSE_API_MSG";
export const EDIT_DR_WORK_LICENSE_API_RES = "EDIT_DR_WORK_LICENSE_API_RES";

export interface EditDrLicenseState {
  editDrLicenseRes: EditProfessionalLicenseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrLicenseLoading {
  type: typeof EDIT_DR_WORK_LICENSE_LOADING;
  loading: boolean;
}
export interface EditDrLicenseSuccess {
  type: typeof EDIT_DR_WORK_LICENSE_SUCCESS;
  payload: EditProfessionalLicenseRes;
  successMsg: string;
}
export interface EditDrLicenseFail {
  type: typeof EDIT_DR_WORK_LICENSE_FAIL;
  payload: EditProfessionalLicenseRes;
  errorMsg: string;
  status: number;
}
export interface EditDrLicenseAPIMsg {
  type: typeof EDIT_DR_WORK_LICENSE_API_MSG;
  payload: EditProfessionalLicenseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrLicenseAPIRes {
  type: typeof EDIT_DR_WORK_LICENSE_API_RES;
  payload: EditProfessionalLicenseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDrLicenseActionTypes =
  | EditDrLicenseLoading
  | EditDrLicenseSuccess
  | EditDrLicenseFail
  | EditDrLicenseAPIMsg
  | EditDrLicenseAPIRes;
