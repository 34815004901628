import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import {
  allActiveMeetingListActionTypes,
  ALL_ACTIVE_MEETING_LIST_FAIL,
  ALL_ACTIVE_MEETING_LIST_LOADING,
  ALL_ACTIVE_MEETING_LIST_SUCCESS,
} from "./ActionTypes";
import {
  allActiveMeetingListDetails,
  AllActiveMeetingListValue,
} from "./Model";
import { headers } from "components/Utility";

export const allActiveMeetingListApi = (body: allActiveMeetingListDetails) => {
  return function (dispatch: Dispatch<allActiveMeetingListActionTypes>) {
    dispatch({
      type: ALL_ACTIVE_MEETING_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +
          `/doctorapp/api/doctortelehealth/all_active_meeting_list`,
        body,
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch({
          type: ALL_ACTIVE_MEETING_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: ALL_ACTIVE_MEETING_LIST_FAIL,
          payload: {} as AllActiveMeetingListValue,
          errRes: error.response.data.message,
        });
      });
  };
};
