import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorLegalTypeListActionTypes } from "./ActionTypes";
import { GetLegalTypeListRes } from "./Model";
import {
  getDoctorLegalTypeListAPIResClearAction,
  getDoctorLegalTypeListErrorAction,
  getDoctorLegalTypeListLoadingAction,
  getDoctorLegalTypeListSuccessAction,
  getDoctorLegalTypeListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetLegalTypeListRes;
export const getLegalTypeList = () => {
  return function (dispatch: Dispatch<GetDoctorLegalTypeListActionTypes>) {
    dispatch(getDoctorLegalTypeListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/legal_type_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorLegalTypeListLoadingAction(false));
        dispatch(
          getDoctorLegalTypeListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal type list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorLegalTypeListLoadingAction(false));
        dispatch(
          getDoctorLegalTypeListErrorAction(
            {} as GetLegalTypeListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorLegalTypeListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorLegalTypeListActionTypes>) {
    dispatch(
      getDoctorLegalTypeListAPIMsgAction(apiRes as GetLegalTypeListRes, "", 0)
    );
  };
};

export const clearGetDoctorLegalTypeListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorLegalTypeListActionTypes>) {
    dispatch(
      getDoctorLegalTypeListAPIResClearAction({} as GetLegalTypeListRes, "", 0)
    );
  };
};
