import { AddWorkScheduleRes } from "./Model";

export const ADD_WORK_SCHEDULE_LOADING =
  "ADD_WORK_SCHEDULE_LOADING";
export const ADD_WORK_SCHEDULE_SUCCESS =
  "ADD_WORK_SCHEDULE_SUCCESS";
export const ADD_WORK_SCHEDULE_FAIL =
  "ADD_WORK_SCHEDULE_FAIL";
export const ADD_WORK_SCHEDULE_DATA =
  "ADD_WORK_SCHEDULE_DATA";

export interface AddWorkScheduleDetails {
  addWorkScheduleRes: AddWorkScheduleRes;
  loading: boolean;
  errRes: string;
}
export interface AddWorkScheduleLoading {
  type: typeof ADD_WORK_SCHEDULE_LOADING;
}
export interface AddWorkScheduleSuccess {
  type: typeof ADD_WORK_SCHEDULE_SUCCESS;
  payload: AddWorkScheduleRes;
  errRes: string;
}
export interface AddWorkScheduleFail {
  type: typeof ADD_WORK_SCHEDULE_FAIL;
  payload: AddWorkScheduleRes;
  errRes: string;
}
interface AddWorkScheduleAction {
  type: typeof ADD_WORK_SCHEDULE_DATA;
  payload: AddWorkScheduleRes;
  errRes: string;
}

export type AddWorkScheduleActionTypes =
  | AddWorkScheduleAction
  | AddWorkScheduleLoading
  | AddWorkScheduleSuccess
  | AddWorkScheduleFail;
