import { apiAuthTokenValue } from "./Model";

// Patient Meeting Loading State
export const API_AUTH_TOKEN_LOADING =
  "API_AUTH_TOKEN_LOADING";
export const API_AUTH_TOKEN_SUCCESS =
  "API_AUTH_TOKEN_SUCCESS";
export const API_AUTH_TOKEN_FAIL = "API_AUTH_TOKEN_FAIL";
export const EMAIL_OTP_AUTH_LIST = "EMAIL_OTP_AUTH_LIST";

export interface apiAuthTokenDetails {
  apiAuthTokenRes: apiAuthTokenValue;
  loading: boolean;
  errRes: string;
}
export interface apiAuthTokenLoading {
  type: typeof API_AUTH_TOKEN_LOADING;
}
export interface apiAuthTokenSuccess {
  type: typeof API_AUTH_TOKEN_SUCCESS;
  payload: apiAuthTokenValue;
  errRes: string;
}
export interface apiAuthTokenFail {
  type: typeof API_AUTH_TOKEN_FAIL;
  errRes: string;
}

interface apiAuthTokenAction {
  type: typeof EMAIL_OTP_AUTH_LIST;
  payload: apiAuthTokenValue;
  errRes: string;
}

export type apiAuthTokenActionTypes =
  | apiAuthTokenAction
  | apiAuthTokenLoading
  | apiAuthTokenSuccess
  | apiAuthTokenFail;
