import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDetailsForEditPrescriptionActionTypes } from "./ActionTypes";
import {
  GetDetailsForEditPrescriptionBody,
  GetDetailsForEditPrescriptionRes,
} from "./Model";
import {
  getDetailsForEditPrescriptionAPIResClearAction,
  getDetailsForEditPrescriptionErrorAction,
  getDetailsForEditPrescriptionLoadingAction,
  getDetailsForEditPrescriptionSuccessAction,
  getDetailsForEditPrescriptionAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetDetailsForEditPrescriptionRes;
export const getDetailsForEditPrescription = (
  payload: GetDetailsForEditPrescriptionBody
) => {
  return function (
    dispatch: Dispatch<GetDetailsForEditPrescriptionActionTypes>
  ) {
    dispatch(getDetailsForEditPrescriptionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL +
          `/patientapp/api/prescriptiongenerate/get_details_for_editing_prescription`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDetailsForEditPrescriptionLoadingAction(false));
        dispatch(
          getDetailsForEditPrescriptionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Prescription has been fetched successfully for edit"
          )
        );
      })
      .catch((error) => {
        dispatch(getDetailsForEditPrescriptionLoadingAction(false));
        dispatch(
          getDetailsForEditPrescriptionErrorAction(
            {} as GetDetailsForEditPrescriptionRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetDetailsForEditPrescriptionAPIResMsg = () => {
  return function (
    dispatch: Dispatch<GetDetailsForEditPrescriptionActionTypes>
  ) {
    dispatch(
      getDetailsForEditPrescriptionAPIMsgAction(
        apiRes as GetDetailsForEditPrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDetailsForEditPrescriptionAPIRes = () => {
  return function (
    dispatch: Dispatch<GetDetailsForEditPrescriptionActionTypes>
  ) {
    dispatch(
      getDetailsForEditPrescriptionAPIResClearAction(
        {} as GetDetailsForEditPrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};
