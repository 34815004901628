import {
  GET_DOCTOR_COVER_LETTER_FAIL,
  GET_DOCTOR_COVER_LETTER_LOADING,
  GET_DOCTOR_COVER_LETTER_SUCCESS,
  GET_DOCTOR_COVER_LETTER_API_MSG,
  GET_DOCTOR_COVER_LETTER_API_RES,
  GetDoctorCoverLetterActionTypes,
} from "./ActionTypes";
import { GetCoverLetterRes } from "./Model";

export const getDoctorCoverLetterLoadingAction = (
  loading: boolean
): GetDoctorCoverLetterActionTypes => {
  return {
    type: GET_DOCTOR_COVER_LETTER_LOADING,
    loading: loading,
  };
};

export const getDoctorCoverLetterSuccessAction = (
  getDoctorCoverLetterResponse: GetCoverLetterRes,
  successMsg: string
): GetDoctorCoverLetterActionTypes => {
  return {
    type: GET_DOCTOR_COVER_LETTER_SUCCESS,
    payload: getDoctorCoverLetterResponse,
    successMsg: successMsg,
  };
};

export const getDoctorCoverLetterErrorAction = (
  getDoctorCoverLetterResponse: GetCoverLetterRes,
  errMsg: string,
  status: number
): GetDoctorCoverLetterActionTypes => {
  return {
    type: GET_DOCTOR_COVER_LETTER_FAIL,
    payload: getDoctorCoverLetterResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorCoverLetterAPIMsgAction = (
  getDoctorCoverLetterResponse: GetCoverLetterRes,
  errMsg: string,
  status: number
): GetDoctorCoverLetterActionTypes => {
  return {
    type: GET_DOCTOR_COVER_LETTER_API_MSG,
    payload: getDoctorCoverLetterResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorCoverLetterAPIResClearAction = (
  getDoctorCoverLetterResponse: GetCoverLetterRes,
  errMsg: string,
  status: number
): GetDoctorCoverLetterActionTypes => {
  return {
    type: GET_DOCTOR_COVER_LETTER_API_RES,
    payload: getDoctorCoverLetterResponse,
    errorMsg: errMsg,
    status: status,
  };
};
