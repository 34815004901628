import {
  JwtTokenActionTypes,
  JWT_LOGIN_FAIL,
  JWT_LOGIN_LOADING,
  JWT_LOGIN_SUCCESS,
  LoginState,
  JWT_LOGIN_UPDATE_API_MSG,
  JWT_LOGIN_UPDATE_API_RES,
} from "./ActionTypes";
import { LoginRes } from "./Model";

const initialStateGetPosts: LoginState = {
  loading: false,
  loginRes: {} as LoginRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const jwtAuthTokenReducer = (
  state = initialStateGetPosts,
  action: JwtTokenActionTypes
): LoginState => {
  switch (action.type) {
    case JWT_LOGIN_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case JWT_LOGIN_SUCCESS:
      return {
        ...state,
        loginRes: action.payload,
        successMsg: action.successMsg,
      };
    case JWT_LOGIN_FAIL:
      return {
        ...state,
        loginRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case JWT_LOGIN_UPDATE_API_MSG:
      return {
        ...state,
        loginRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case JWT_LOGIN_UPDATE_API_RES:
      return {
        ...state,
        loginRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
