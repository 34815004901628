import {
  SignDoctorAgreementActionTypes,
  SIGN_DOCTOR_AGREEMENT_FAIL,
  SIGN_DOCTOR_AGREEMENT_LOADING,
  SIGN_DOCTOR_AGREEMENT_SUCCESS,
  SignDoctorAgreementState,
  SIGN_DOCTOR_AGREEMENT_API_MSG,
  SIGN_DOCTOR_AGREEMENT_API_RES,
} from "./ActionTypes";
import { SignAgreementRes } from "./Model";

const initialStateUpdatePosts: SignDoctorAgreementState = {
  loading: false,
  signDoctorAgreementRes: {} as SignAgreementRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const signAgreementReducer = (
  state = initialStateUpdatePosts,
  action: SignDoctorAgreementActionTypes
): SignDoctorAgreementState => {
  switch (action.type) {
    case SIGN_DOCTOR_AGREEMENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SIGN_DOCTOR_AGREEMENT_SUCCESS:
      return {
        ...state,
        signDoctorAgreementRes: action.payload,
        successMsg: action.successMsg,
      };
    case SIGN_DOCTOR_AGREEMENT_FAIL:
      return {
        ...state,
        signDoctorAgreementRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SIGN_DOCTOR_AGREEMENT_API_MSG:
      return {
        ...state,
        signDoctorAgreementRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SIGN_DOCTOR_AGREEMENT_API_RES:
      return {
        ...state,
        signDoctorAgreementRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
