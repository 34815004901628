import {
  EDIT_DR_ID_PROOF_FAIL,
  EDIT_DR_ID_PROOF_LOADING,
  EDIT_DR_ID_PROOF_SUCCESS,
  EDIT_DR_ID_PROOF_API_MSG,
  EDIT_DR_ID_PROOF_API_RES,
  EditDrIdProofActionTypes,
} from "./ActionTypes";
import { EditDrIdProofRes } from "./Model";

export const editDrIdProofLoadingAction = (
  loading: boolean
): EditDrIdProofActionTypes => {
  return {
    type: EDIT_DR_ID_PROOF_LOADING,
    loading: loading,
  };
};

export const editDrIdProofSuccessAction = (
  editDrIdProofResponse: EditDrIdProofRes,
  successMsg: string
): EditDrIdProofActionTypes => {
  return {
    type: EDIT_DR_ID_PROOF_SUCCESS,
    payload: editDrIdProofResponse,
    successMsg: successMsg,
  };
};

export const editDrIdProofErrorAction = (
  editDrIdProofResponse: EditDrIdProofRes,
  errMsg: string,
  status: number
): EditDrIdProofActionTypes => {
  return {
    type: EDIT_DR_ID_PROOF_FAIL,
    payload: editDrIdProofResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDrIdProofAPIMsgAction = (
  editDrIdProofResponse: EditDrIdProofRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrIdProofActionTypes => {
  return {
    type: EDIT_DR_ID_PROOF_API_MSG,
    payload: editDrIdProofResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDrIdProofAPIResClearAction = (
  editDrIdProofResponse: EditDrIdProofRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrIdProofActionTypes => {
  return {
    type: EDIT_DR_ID_PROOF_API_RES,
    payload: editDrIdProofResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
