import { SaveAsDraftPrescriptionRes } from "./Model";
export const SAVE_AS_DRAFT_PRESCRIPTION_LOADING =
  "SAVE_AS_DRAFT_PRESCRIPTION_LOADING";
export const SAVE_AS_DRAFT_PRESCRIPTION_SUCCESS =
  "SAVE_AS_DRAFT_PRESCRIPTION_SUCCESS";
export const SAVE_AS_DRAFT_PRESCRIPTION_FAIL =
  "SAVE_AS_DRAFT_PRESCRIPTION_FAIL";
export const SAVE_AS_DRAFT_PRESCRIPTION_API_MSG =
  "SAVE_AS_DRAFT_PRESCRIPTION_API_MSG";
export const SAVE_AS_DRAFT_PRESCRIPTION_API_RES =
  "SAVE_AS_DRAFT_PRESCRIPTION_API_RES";

export interface SaveAsDraftPrescriptionState {
  saveAsDraftPrescriptionRes: SaveAsDraftPrescriptionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SaveAsDraftPrescriptionLoading {
  type: typeof SAVE_AS_DRAFT_PRESCRIPTION_LOADING;
  loading: boolean;
}
export interface SaveAsDraftPrescriptionSuccess {
  type: typeof SAVE_AS_DRAFT_PRESCRIPTION_SUCCESS;
  payload: SaveAsDraftPrescriptionRes;
  successMsg: string;
}
export interface SaveAsDraftPrescriptionFail {
  type: typeof SAVE_AS_DRAFT_PRESCRIPTION_FAIL;
  payload: SaveAsDraftPrescriptionRes;
  errorMsg: string;
  status: number;
}
export interface SaveAsDraftPrescriptionAPIMsg {
  type: typeof SAVE_AS_DRAFT_PRESCRIPTION_API_MSG;
  payload: SaveAsDraftPrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SaveAsDraftPrescriptionAPIRes {
  type: typeof SAVE_AS_DRAFT_PRESCRIPTION_API_RES;
  payload: SaveAsDraftPrescriptionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SaveAsDraftPrescriptionActionTypes =
  | SaveAsDraftPrescriptionLoading
  | SaveAsDraftPrescriptionSuccess
  | SaveAsDraftPrescriptionFail
  | SaveAsDraftPrescriptionAPIMsg
  | SaveAsDraftPrescriptionAPIRes;
