import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import { PatientDetailList } from "../../../../redux/FilePreparation/GetPatientDataList/Model";
import MWExceptionList from "../../../../components/MWExceptionList";
import ViewDocument from "../ViewDocument";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeleteSummaryData from "../DeleteSummaryData";
import AddSummaryData from "../AddSummaryData";
import { FilePreparationSectionSummaryList } from "redux/FilePreparation/doctorSummary/getSummaryList/Model";
import { t } from "i18next";

interface Props {
  label?: string;
  type?: string;
}

export default function SummaryList({ label, type }: Props) {
  const doctorSummaryListRes = useSelector(
    (state: AppState) => state.getDoctorSummaryList
  );
  const [patientDataList, setPatientDataList] = useState(
    [] as PatientDetailList[]
  );
  const [sectionTye, setSectionType] = useState("");
  useEffect(() => {
    type !== undefined
      ? type !== ""
        ? setSectionType(type)
        : setSectionType("")
      : setSectionType("");
  }, [type]);
  useEffect(() => {
    if (
      doctorSummaryListRes?.getSummaryListResponse
        ?.filePreparationSectionSummaryList !== undefined
    ) {
      if (
        doctorSummaryListRes?.getSummaryListResponse
          ?.filePreparationSectionSummaryList.length > 0 &&
        doctorSummaryListRes?.getSummaryListResponse
          ?.filePreparationSectionSummaryList[0]?.secondOpinionUiSection
          ?.name === type
      ) {
        let questionItem =
          doctorSummaryListRes?.getSummaryListResponse?.filePreparationSectionSummaryList.map(
            (item: FilePreparationSectionSummaryList) => {
              return {
                id: item.id,
                summaryText:
                  item?.summaryText !== undefined ? item.summaryText : "",
                viewItem: item.id,
              };
            }
          );
        setPatientDataList(questionItem);
      }
    }
    // eslint-disable-next-line
  }, [doctorSummaryListRes]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogUpdateState = (passedVal: boolean) => {
    setDialogOpen(passedVal);
  };
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const addEditDialogUpdateState = (passedVal: boolean) => {
    setAddEditDialogOpen(passedVal);
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const deleteDialogUpdateState = (passedVal: boolean) => {
    setDeleteDialogOpen(passedVal);
  };

  const [docName] = useState("");
  const [itemId, setItemId] = useState(0);
  const [selectedItem, setSelectedItem] = useState({} as any);
  const selectedItemUpdateState = (passedVal: any) => {
    setSelectedItem(passedVal);
  };
  const columns: GridColDef[] = [
    {
      field: "viewItem",
      headerName: `${t("common.view", { ns: ["home"] })}`,
      minWidth: 60,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setAddEditDialogOpen(true);
            setSelectedItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          <VisibilityIcon />
        </Button>
      ),
    },
    {
      field: "summaryText",
      headerName: `${t("filePrepPage.sum", { ns: ["home"] })}`,
      flex: 1,
    },
    {
      field: "id",
      headerName: `${t("appointmentpage.del", { ns: ["home"] })}`,
      minWidth: 50,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setDeleteDialogOpen(true);
            setItemId(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography variant="h5">
                  {t("appointmentpage.sumlist", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setAddEditDialogOpen(true)}
                  sx={{ textTransform: "none" }}
                >
                  {t("appointmentpage.addsum", { ns: ["home"] })}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {patientDataList.length > 0 ? (
            <DataGrid
              rows={patientDataList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {dialogOpen ? (
        <ViewDocument
          dialogEnable={dialogOpen}
          dialogUpdateState={dialogUpdateState}
          docName={docName}
        />
      ) : null}
      {addEditDialogOpen ? (
        <AddSummaryData
          dialogEnable={addEditDialogOpen}
          dialogUpdateState={addEditDialogUpdateState}
          docTypeName={label}
          itemId={itemId}
          type={sectionTye}
          selectedItem={selectedItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {deleteDialogOpen ? (
        <DeleteSummaryData
          dialogEnable={deleteDialogOpen}
          dialogUpdateState={deleteDialogUpdateState}
          docTypeName={label}
          itemId={itemId}
          type={sectionTye}
        />
      ) : null}
    </Box>
  );
}
