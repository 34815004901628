import {
  GET_DOCTOR_EDUCATION_SUMMARY_FAIL,
  GET_DOCTOR_EDUCATION_SUMMARY_LOADING,
  GET_DOCTOR_EDUCATION_SUMMARY_SUCCESS,
  GET_DOCTOR_EDUCATION_SUMMARY_API_MSG,
  GET_DOCTOR_EDUCATION_SUMMARY_API_RES,
  GetDoctorEducationSummaryActionTypes,
} from "./ActionTypes";
import { GetEducationSummaryRes } from "./Model";

export const getDoctorEducationSummaryLoadingAction = (
  loading: boolean
): GetDoctorEducationSummaryActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_SUMMARY_LOADING,
    loading: loading,
  };
};

export const getDoctorEducationSummarySuccessAction = (
  getDoctorEducationSummaryResponse: GetEducationSummaryRes,
  successMsg: string
): GetDoctorEducationSummaryActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_SUMMARY_SUCCESS,
    payload: getDoctorEducationSummaryResponse,
    successMsg: successMsg,
  };
};

export const getDoctorEducationSummaryErrorAction = (
  getDoctorEducationSummaryResponse: GetEducationSummaryRes,
  errMsg: string,
  status: number
): GetDoctorEducationSummaryActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_SUMMARY_FAIL,
    payload: getDoctorEducationSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorEducationSummaryAPIMsgAction = (
  getDoctorEducationSummaryResponse: GetEducationSummaryRes,
  errMsg: string,
  status: number
): GetDoctorEducationSummaryActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_SUMMARY_API_MSG,
    payload: getDoctorEducationSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorEducationSummaryAPIResClearAction = (
  getDoctorEducationSummaryResponse: GetEducationSummaryRes,
  errMsg: string,
  status: number
): GetDoctorEducationSummaryActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_SUMMARY_API_RES,
    payload: getDoctorEducationSummaryResponse,
    errorMsg: errMsg,
    status: status,
  };
};
