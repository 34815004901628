import { WorkScheduleListRes } from "./Model";

export const WORK_SCHEDULE_LIST_LOADING =
  "WORK_SCHEDULE_LIST_LOADING";
export const WORK_SCHEDULE_LIST_SUCCESS =
  "WORK_SCHEDULE_LIST_SUCCESS";
export const WORK_SCHEDULE_LIST_FAIL =
  "WORK_SCHEDULE_LIST_FAIL";
export const WORK_SCHEDULE_LIST_DATA =
  "WORK_SCHEDULE_LIST_DATA";

export interface WorkScheduleListDetails {
  WorkScheduleListRes: WorkScheduleListRes;
  loading: boolean;
  errRes: string;
}
export interface WorkScheduleListDetailsLoading {
  type: typeof WORK_SCHEDULE_LIST_LOADING;
}
export interface WorkScheduleListDetailsSuccess {
  type: typeof WORK_SCHEDULE_LIST_SUCCESS;
  payload: WorkScheduleListRes;
  errRes: string;
}
export interface WorkScheduleListDetailsFail {
  type: typeof WORK_SCHEDULE_LIST_FAIL;
  payload: WorkScheduleListRes;
  errRes: string;
}
interface WorkScheduleListDetailsAction {
  type: typeof WORK_SCHEDULE_LIST_DATA;
  payload: WorkScheduleListRes;
  errRes: string;
}

export type WorkScheduleListActionTypes =
  | WorkScheduleListDetailsAction
  | WorkScheduleListDetailsLoading
  | WorkScheduleListDetailsSuccess
  | WorkScheduleListDetailsFail;
