import {
  GET_DOCTOR_LICENSE_TYPE_LIST_FAIL,
  GET_DOCTOR_LICENSE_TYPE_LIST_LOADING,
  GET_DOCTOR_LICENSE_TYPE_LIST_SUCCESS,
  GET_DOCTOR_LICENSE_TYPE_LIST_API_MSG,
  GET_DOCTOR_LICENSE_TYPE_LIST_API_RES,
  GetDoctorLicenseTypeListActionTypes,
} from "./ActionTypes";
import { GetDoctorLicenseTypeListRes } from "./Model";

export const getDoctorLicenseTypeListLoadingAction = (
  loading: boolean
): GetDoctorLicenseTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_TYPE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorLicenseTypeListSuccessAction = (
  getDoctorLicenseTypeListResponse: GetDoctorLicenseTypeListRes,
  successMsg: string
): GetDoctorLicenseTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_TYPE_LIST_SUCCESS,
    payload: getDoctorLicenseTypeListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorLicenseTypeListErrorAction = (
  getDoctorLicenseTypeListResponse: GetDoctorLicenseTypeListRes,
  errMsg: string,
  status: number
): GetDoctorLicenseTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_TYPE_LIST_FAIL,
    payload: getDoctorLicenseTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorLicenseTypeListAPIMsgAction = (
  getDoctorLicenseTypeListResponse: GetDoctorLicenseTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorLicenseTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_TYPE_LIST_API_MSG,
    payload: getDoctorLicenseTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorLicenseTypeListAPIResClearAction = (
  getDoctorLicenseTypeListResponse: GetDoctorLicenseTypeListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorLicenseTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LICENSE_TYPE_LIST_API_RES,
    payload: getDoctorLicenseTypeListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
