import { AuthorizeDoctorForZoomTeleHealthRes } from "./Model";
export const AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_LOADING = "AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_LOADING";
export const AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_SUCCESS = "AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_SUCCESS";
export const AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_FAIL = "AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_FAIL";
export const AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_MSG = "AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_MSG";
export const AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_RES = "AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_RES";

export interface AuthorizeDoctorForZoomTeleHealthState {
  doctorListForPatientCaseRes: AuthorizeDoctorForZoomTeleHealthRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AuthorizeDoctorForZoomTeleHealthLoading {
  type: typeof AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_LOADING;
  loading: boolean;
}
export interface AuthorizeDoctorForZoomTeleHealthSuccess {
  type: typeof AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_SUCCESS;
  payload: AuthorizeDoctorForZoomTeleHealthRes;
  successMsg: string;
}
export interface AuthorizeDoctorForZoomTeleHealthFail {
  type: typeof AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_FAIL;
  payload: AuthorizeDoctorForZoomTeleHealthRes;
  errorMsg: string;
  status: number;
}
export interface AuthorizeDoctorForZoomTeleHealthAPIMsg {
  type: typeof AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_MSG;
  payload: AuthorizeDoctorForZoomTeleHealthRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AuthorizeDoctorForZoomTeleHealthAPIRes {
  type: typeof AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_RES;
  payload: AuthorizeDoctorForZoomTeleHealthRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AuthorizeDoctorForZoomTeleHealthActionTypes =
  | AuthorizeDoctorForZoomTeleHealthLoading
  | AuthorizeDoctorForZoomTeleHealthSuccess
  | AuthorizeDoctorForZoomTeleHealthFail
  | AuthorizeDoctorForZoomTeleHealthAPIMsg
  | AuthorizeDoctorForZoomTeleHealthAPIRes;
