import {
  GENERATE_PRESCRIPTION_FAIL,
  GENERATE_PRESCRIPTION_LOADING,
  GENERATE_PRESCRIPTION_SUCCESS,
  GENERATE_PRESCRIPTION_API_MSG,
  GENERATE_PRESCRIPTION_API_RES,
  GeneratePrescriptionActionTypes,
} from "./ActionTypes";
import { GeneratePrescriptionRes } from "./Model";

export const generatePrescriptionLoadingAction = (
  loading: boolean
): GeneratePrescriptionActionTypes => {
  return {
    type: GENERATE_PRESCRIPTION_LOADING,
    loading: loading,
  };
};

export const generatePrescriptionSuccessAction = (
  generatePrescriptionResponse: GeneratePrescriptionRes,
  successMsg: string
): GeneratePrescriptionActionTypes => {
  return {
    type: GENERATE_PRESCRIPTION_SUCCESS,
    payload: generatePrescriptionResponse,
    successMsg: successMsg,
  };
};

export const generatePrescriptionErrorAction = (
  generatePrescriptionResponse: GeneratePrescriptionRes,
  errMsg: string,
  status: number
): GeneratePrescriptionActionTypes => {
  return {
    type: GENERATE_PRESCRIPTION_FAIL,
    payload: generatePrescriptionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const generatePrescriptionAPIMsgAction = (
  generatePrescriptionResponse: GeneratePrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): GeneratePrescriptionActionTypes => {
  return {
    type: GENERATE_PRESCRIPTION_API_MSG,
    payload: generatePrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const generatePrescriptionAPIResClearAction = (
  generatePrescriptionResponse: GeneratePrescriptionRes,
  successMsg: string,
  errMsg: string,
  status: number
): GeneratePrescriptionActionTypes => {
  return {
    type: GENERATE_PRESCRIPTION_API_RES,
    payload: generatePrescriptionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
