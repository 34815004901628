import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  AlertColor,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import dayjs from "dayjs";
import { t } from "i18next";
import MWExceptionList from "components/MWExceptionList";
import PageLayout from "components/MWPageLayout";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import Url from "../../URL";
import {
  authorizeDoctorForZoomTeleHealth,
  clearAuthorizeDoctorForZoomTeleHealthAPIRes,
} from "redux/AuthorizeDoctorForZoomTeleHealth/API";
import { AuthorizeDoctorForZoomTeleHealthBody } from "redux/AuthorizeDoctorForZoomTeleHealth/Model";
import { getTelehealthListApi } from "redux/TelehealthDetailsList/API";
import {
  GetTelehealthgListValue,
  getTelehealthListDetails,
} from "redux/TelehealthDetailsList/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function TelehealthCredentials() {
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const code = query.get("code");

  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;

  useEffect(() => {
    console.log("zoom-oAuth-code", code);
    if (code !== null) {
      dispatch(
        authorizeDoctorForZoomTeleHealth({
          authorizationCode: code,
          doctorId: userDetails?.doctorId,
        } as AuthorizeDoctorForZoomTeleHealthBody)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCredentialBody = {
    doctorId: String(DoctorID),
  } as getTelehealthListDetails;

  const doctorGetTelehealthMeetingListValue = useSelector(
    (state: AppState) => state.getTelehealthList
  );

  const getTelehealthList = () => {
    dispatch(getTelehealthListApi(getCredentialBody));
  };

  useEffect(() => {
    dispatch(getTelehealthListApi(getCredentialBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectToZoom = () => {
    window.open(
      `${Url.zoomRedirectAuthBaseURL}?client_id=${Url.zoomClientId}&response_type=${Url.zoomResponseType}&redirect_uri=${Url.zoomRedirectURI}`,
      "_self"
    );
  };

  const authorizeDoctorForZoomTeleHealthRes = useSelector(
    (state: AppState) => state.authorizeDoctorForZoomTeleHealthRes
  );
  const [
    authorizeDoctorForZoomTeleHealthMsg,
    setAuthorizeDoctorForZoomTeleHealthMsg,
  ] = useState("");
  const [
    authorizeDoctorForZoomTeleHealthMsgType,
    setAuthorizeDoctorForZoomTeleHealthMsgType,
  ] = useState<AlertColor>("success");
  useEffect(() => {
    if (authorizeDoctorForZoomTeleHealthRes?.successMsg !== "") {
      setAuthorizeDoctorForZoomTeleHealthMsgType("success");
      setAuthorizeDoctorForZoomTeleHealthMsg(
        authorizeDoctorForZoomTeleHealthRes?.successMsg
      );
      dispatch(
        getTelehealthListApi({
          doctorId: String(DoctorID),
        } as getTelehealthListDetails)
      );
    }
    if (authorizeDoctorForZoomTeleHealthRes?.errorMsg !== "") {
      setAuthorizeDoctorForZoomTeleHealthMsgType("error");
      setAuthorizeDoctorForZoomTeleHealthMsg(
        authorizeDoctorForZoomTeleHealthRes?.errorMsg
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizeDoctorForZoomTeleHealthRes]);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("telehealthpage.meetingName", { ns: ["home"] })!}`,
      minWidth: 130,
      flex: 1,
    },
    {
      field: "doctorName",
      headerName: `${t("appointmentpage.doctorName", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "teleHealthAppName",
      headerName: `${t("telehealthpage.telehealthAppName", { ns: ["home"] })!}`,
      minWidth: 230,
    },
    {
      field: "modifiedDate",
      headerName: `${t("appointmentpage.createdDate", { ns: ["home"] })!}`,
      minWidth: 150,
    },
  ];
  const [meetingList, setMeetingList] = useState([] as any[]);
  useEffect(() => {
    if (
      doctorGetTelehealthMeetingListValue?.getTelehealthListRes
        ?.telehealthData !== undefined
    ) {
      let meetingItem =
        doctorGetTelehealthMeetingListValue?.getTelehealthListRes?.telehealthData.map(
          (item: GetTelehealthgListValue, index) => {
            return {
              id: index,
              name:
                item?.displayName !== undefined
                  ? item.displayName
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              doctorName:
                item?.doctor !== undefined
                  ? item?.doctor
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              teleHealthAppName:
                item?.teleHealthApp !== undefined
                  ? item?.teleHealthApp
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              modifiedDate:
                item?.modifiedDate !== undefined
                  ? item?.modifiedDate !== null
                    ? dayjs(item?.modifiedDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
            }; //as ModifiedPatientDetailList;
          }
        );
      setMeetingList(meetingItem);
    } else setMeetingList([] as any[]);
  }, [doctorGetTelehealthMeetingListValue]);

  useEffect(() => {
    dispatch(
      toggleAppBarLoading(
        doctorGetTelehealthMeetingListValue?.loading ||
          authorizeDoctorForZoomTeleHealthRes?.loading
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doctorGetTelehealthMeetingListValue,
    authorizeDoctorForZoomTeleHealthRes,
  ]);

  return (
    <PageLayout>
      {authorizeDoctorForZoomTeleHealthMsg !== "" ? (
        <Alert
          severity={authorizeDoctorForZoomTeleHealthMsgType}
          onClose={() => {
            dispatch(clearAuthorizeDoctorForZoomTeleHealthAPIRes());
            setAuthorizeDoctorForZoomTeleHealthMsg("");
            history.push("/telehealth");
          }}
        >
          {authorizeDoctorForZoomTeleHealthMsg}
        </Alert>
      ) : null}
      {/* telehelth creentials card */}
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h6">
                  {t("telehealthpage.telecred", { ns: ["home"] })}
                </Typography>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              {t("telehealthpage.integrateZoom", { ns: ["home"] })}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => connectToZoom()}
                sx={{ textTransform: "none" }}
              >
                {t("telehealthpage.authorize", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Telehealth credentials table */}
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography variant="h6">
                  {t("telehealthpage.teledetail", { ns: ["home"] })!}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      onClick={getTelehealthList}
                      sx={{ textTransform: "none" }}
                    >
                      {t("common.reload", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        ></CardHeader>
        <CardContent>
          {meetingList.length ? (
            <DataGrid
              rows={meetingList}
              columns={columns}
              pageSize={10}
              loading={doctorGetTelehealthMeetingListValue?.loading}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </PageLayout>
  );
}
