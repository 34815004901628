import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GeneratePrescriptionActionTypes } from "./ActionTypes";
import { GeneratePrescriptionBody, GeneratePrescriptionRes } from "./Model";
import {
  generatePrescriptionAPIResClearAction,
  generatePrescriptionErrorAction,
  generatePrescriptionLoadingAction,
  generatePrescriptionSuccessAction,
  generatePrescriptionAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GeneratePrescriptionRes;
export const generatePrescription = (payload: GeneratePrescriptionBody) => {
  return function (dispatch: Dispatch<GeneratePrescriptionActionTypes>) {
    dispatch(generatePrescriptionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/generate-prescription/`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(generatePrescriptionLoadingAction(false));
        dispatch(
          generatePrescriptionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Prescription has been generated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(generatePrescriptionLoadingAction(false));
        dispatch(
          generatePrescriptionErrorAction(
            {} as GeneratePrescriptionRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGeneratePrescriptionAPIResMsg = () => {
  return function (dispatch: Dispatch<GeneratePrescriptionActionTypes>) {
    dispatch(
      generatePrescriptionAPIMsgAction(
        apiRes as GeneratePrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGeneratePrescriptionAPIRes = () => {
  return function (dispatch: Dispatch<GeneratePrescriptionActionTypes>) {
    dispatch(
      generatePrescriptionAPIResClearAction(
        {} as GeneratePrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};
