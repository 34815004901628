import { doctorPersonalDetailsByIdValue } from "./Model";
export const PERSONAL_DETAILS_BY_ID_LOADING =
  "PERSONAL_DETAILS_BY_ID_LOADING";
export const PERSONAL_DETAILS_BY_ID_SUCCESS =
  "DOCTOR_ACCEPT_APPOINMENT_SUCCESS";
export const PERSONAL_DETAILS_BY_ID_FAIL = "PERSONAL_DETAILS_BY_ID_FAIL";
export const PERSONAL_DETAILS_BY_ID_LIST = "PERSONAL_DETAILS_BY_ID_LIST";

export interface doctorPersonalDetailsByIdDetails {
  doctorPersonalDetailsByIdRes: doctorPersonalDetailsByIdValue;
  loading: boolean;
  errRes: string;
}
export interface doctorPersonalDetailsByIdLoading {
  type: typeof PERSONAL_DETAILS_BY_ID_LOADING;
}
export interface doctorPersonalDetailsByIdSuccess {
  type: typeof PERSONAL_DETAILS_BY_ID_SUCCESS;
  payload: doctorPersonalDetailsByIdValue;
  errRes: string;
}
export interface doctorPersonalDetailsByIdFail {
  type: typeof PERSONAL_DETAILS_BY_ID_FAIL;
  errRes: string;
}

interface doctorPersonalDetailsByIdAction {
  type: typeof PERSONAL_DETAILS_BY_ID_LIST;
  payload: doctorPersonalDetailsByIdValue;
  errRes: string;
}

export type doctorPersonalDetailsByIdActionTypes =
  | doctorPersonalDetailsByIdAction
  | doctorPersonalDetailsByIdLoading
  | doctorPersonalDetailsByIdSuccess
  | doctorPersonalDetailsByIdFail;
