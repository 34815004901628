import { GetSexualOrientationListRes } from "./Model";

export const GET_SEXUAL_ORIENTATION_LIST_LOADING =
  "GET_SEXUAL_ORIENTATION_LIST_LOADING";
export const GET_SEXUAL_ORIENTATION_LIST_SUCCESS =
  "GET_SEXUAL_ORIENTATION_LIST_SUCCESS";
export const GET_SEXUAL_ORIENTATION_LIST_FAIL =
  "GET_SEXUAL_ORIENTATION_LIST_FAIL";
export const GET_SEXUAL_ORIENTATION_LIST_DATA =
  "GET_SEXUAL_ORIENTATION_LIST_DATA";

export interface SexualOrientationListDetails {
  getSexualOrientationListRes: GetSexualOrientationListRes;
  loading: boolean;
  errRes: string;
}
export interface GetSexualOrientationListActionLoading {
  type: typeof GET_SEXUAL_ORIENTATION_LIST_LOADING;
}
export interface GetSexualOrientationListActionSuccess {
  type: typeof GET_SEXUAL_ORIENTATION_LIST_SUCCESS;
  payload: GetSexualOrientationListRes;
  errRes: string;
}
export interface GetSexualOrientationListActionFail {
  type: typeof GET_SEXUAL_ORIENTATION_LIST_FAIL;
  payload: GetSexualOrientationListRes;
  errRes: string;
}
interface GetSexualOrientationListActionAction {
  type: typeof GET_SEXUAL_ORIENTATION_LIST_DATA;
  payload: GetSexualOrientationListRes;
  errRes: string;
}

export type GetSexualOrientationListActionTypes =
  | GetSexualOrientationListActionAction
  | GetSexualOrientationListActionLoading
  | GetSexualOrientationListActionSuccess
  | GetSexualOrientationListActionFail;
