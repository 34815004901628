import { AddDemographicsDetailsRes } from "./Model";

export const ADD_PATIENT_DEMOGRAPHICS_LOADING =
  "ADD_PATIENT_DEMOGRAPHICS_LOADING";
export const ADD_PATIENT_DEMOGRAPHICS_SUCCESS =
  "ADD_PATIENT_DEMOGRAPHICS_SUCCESS";
export const ADD_PATIENT_DEMOGRAPHICS_FAIL = "ADD_PATIENT_DEMOGRAPHICS_FAIL";
export const ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG =
  "ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG";
export const ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_RES =
  "ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_RES";

export interface AddPatientDemographicsDetailsState {
  addPatientDemographicsRes: AddDemographicsDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDemographicsDetailsLoading {
  type: typeof ADD_PATIENT_DEMOGRAPHICS_LOADING;
  loading: boolean;
}
export interface AddPatientDemographicsDetailsSuccess {
  type: typeof ADD_PATIENT_DEMOGRAPHICS_SUCCESS;
  payload: AddDemographicsDetailsRes;
  successMsg: string;
}
export interface AddPatientDemographicsDetailsFail {
  type: typeof ADD_PATIENT_DEMOGRAPHICS_FAIL;
  payload: AddDemographicsDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddPatientDemographicsDetailsUpdateAPIMsg {
  type: typeof ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG;
  payload: AddDemographicsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDemographicsDetailsUpdateAPIRes {
  type: typeof ADD_PATIENT_DEMOGRAPHICS_UPDATE_API_RES;
  payload: AddDemographicsDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDemographicsDetailsActionTypes =
  | AddPatientDemographicsDetailsLoading
  | AddPatientDemographicsDetailsSuccess
  | AddPatientDemographicsDetailsFail
  | AddPatientDemographicsDetailsUpdateAPIMsg
  | AddPatientDemographicsDetailsUpdateAPIRes;
