import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { JwtTokenActionTypes } from "./ActionTypes";
import { ResetPasswordBody, ResetPasswordRes } from "./Model";
import {
  resetPasswordAPIResClearAction,
  resetPasswordErrorAction,
  resetPasswordLoadingAction,
  resetPasswordSuccessAction,
  resetPasswordUpdateAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as ResetPasswordRes;
export const resetPasswordAPI = (payload: ResetPasswordBody) => {
  return function (dispatch: Dispatch<JwtTokenActionTypes>) {
    dispatch(resetPasswordLoadingAction(true));
    setupInterceptorsTo(axios)
    .put(Url.apiURL + `/profileapp/reset-password/`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(resetPasswordLoadingAction(false));
        dispatch(
          resetPasswordSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Password reset successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(resetPasswordLoadingAction(false));
        dispatch(
          resetPasswordErrorAction(
            {} as ResetPasswordRes,
            error.response !== undefined
              ? error.response.data !== undefined
                ? error.response.data.message !== undefined
                  ? error.response.data.message
                  : error.response.data.detail !== undefined
                  ? error.response.data.detail
                  : error.response.data.non_field_errors[0] !== undefined
                  ? error.response.data.non_field_errors[0]
                  : "Something went wrong"
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateResetPasswordAPIResMsg = () => {
  return function (dispatch: Dispatch<JwtTokenActionTypes>) {
    dispatch(
      resetPasswordUpdateAPIMsgAction(apiRes as ResetPasswordRes, "", "", 0)
    );
  };
};

export const clearResetPasswordAPIRes = () => {
  return function (dispatch: Dispatch<JwtTokenActionTypes>) {
    dispatch(resetPasswordAPIResClearAction({} as ResetPasswordRes, "", "", 0));
  };
};
