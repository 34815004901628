import { GetEducationDetailsRes } from "./Model";
export const GET_DOCTOR_EDUCATION_DETAILS_LOADING =
  "GET_DOCTOR_EDUCATION_DETAILS_LOADING";
export const GET_DOCTOR_EDUCATION_DETAILS_SUCCESS =
  "GET_DOCTOR_EDUCATION_DETAILS_SUCCESS";
export const GET_DOCTOR_EDUCATION_DETAILS_FAIL =
  "GET_DOCTOR_EDUCATION_DETAILS_FAIL";
export const GET_DOCTOR_EDUCATION_DETAILS_API_MSG =
  "GET_DOCTOR_EDUCATION_DETAILS_API_MSG";
export const GET_DOCTOR_EDUCATION_DETAILS_API_RES =
  "GET_DOCTOR_EDUCATION_DETAILS_API_RES";

export interface GetDoctorEducationDetailsState {
  getDoctorEducationDetailsRes: GetEducationDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationDetailsLoading {
  type: typeof GET_DOCTOR_EDUCATION_DETAILS_LOADING;
  loading: boolean;
}
export interface GetDoctorEducationDetailsSuccess {
  type: typeof GET_DOCTOR_EDUCATION_DETAILS_SUCCESS;
  payload: GetEducationDetailsRes;
  successMsg: string;
}
export interface GetDoctorEducationDetailsFail {
  type: typeof GET_DOCTOR_EDUCATION_DETAILS_FAIL;
  payload: GetEducationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationDetailsAPIMsg {
  type: typeof GET_DOCTOR_EDUCATION_DETAILS_API_MSG;
  payload: GetEducationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorEducationDetailsAPIRes {
  type: typeof GET_DOCTOR_EDUCATION_DETAILS_API_RES;
  payload: GetEducationDetailsRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorDetailsActionTypes =
  | GetDoctorEducationDetailsLoading
  | GetDoctorEducationDetailsSuccess
  | GetDoctorEducationDetailsFail
  | GetDoctorEducationDetailsAPIMsg
  | GetDoctorEducationDetailsAPIRes;
