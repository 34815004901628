import React, { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";

interface PageLayoutProps {
  children?: ReactNode;
}
const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
        py={2}
      >
        {children}
      </Stack>
    </Box>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

export default PageLayout;
