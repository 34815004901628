import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import DoctorAppointment from "./DoctorAppointment";
import { t } from "i18next";
import PageLayout from "components/PageLayout";
import MWPageTitle from "components/MWPageTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { Container } from "@mui/system";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 1 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Appointments() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };
  const allAppointmentValues = useSelector(
    (state: AppState) => state.appointmentValue
  );
  useEffect(() => {
    dispatch(toggleAppBarLoading(allAppointmentValues?.loading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAppointmentValues]);
  const pageMarkUp = (
    <Box>
      <Tabs value={selected} onChange={handleTabChange}>
        <Tab
          label={t("appointmentpage.docappoinment", { ns: ["home"] })!}
          {...a11yProps(0)}
        />
      </Tabs>
      <TabPanel value={selected} index={0}>
        {<DoctorAppointment />}
      </TabPanel>
    </Box>
  );

  return (
    <Container maxWidth="xl">
      <PageLayout>
        <MWPageTitle title={t("appointmentpage.details", { ns: ["home"] })!} />
        {pageMarkUp}
      </PageLayout>
    </Container>
  );
}
