import {
  EDIT_DR_INSURANCE_FAIL,
  EDIT_DR_INSURANCE_LOADING,
  EDIT_DR_INSURANCE_SUCCESS,
  EDIT_DR_INSURANCE_API_MSG,
  EDIT_DR_INSURANCE_API_RES,
  EditDrInsuranceActionTypes,
} from "./ActionTypes";
import { EditDrInsuranceRes } from "./Model";

export const editDrInsuranceLoadingAction = (
  loading: boolean
): EditDrInsuranceActionTypes => {
  return {
    type: EDIT_DR_INSURANCE_LOADING,
    loading: loading,
  };
};

export const editDrInsuranceSuccessAction = (
  editDrInsuranceResponse: EditDrInsuranceRes,
  successMsg: string
): EditDrInsuranceActionTypes => {
  return {
    type: EDIT_DR_INSURANCE_SUCCESS,
    payload: editDrInsuranceResponse,
    successMsg: successMsg,
  };
};

export const editDrInsuranceErrorAction = (
  editDrInsuranceResponse: EditDrInsuranceRes,
  errMsg: string,
  status: number
): EditDrInsuranceActionTypes => {
  return {
    type: EDIT_DR_INSURANCE_FAIL,
    payload: editDrInsuranceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDrInsuranceAPIMsgAction = (
  editDrInsuranceResponse: EditDrInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrInsuranceActionTypes => {
  return {
    type: EDIT_DR_INSURANCE_API_MSG,
    payload: editDrInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDrInsuranceAPIResClearAction = (
  editDrInsuranceResponse: EditDrInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditDrInsuranceActionTypes => {
  return {
    type: EDIT_DR_INSURANCE_API_RES,
    payload: editDrInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
