import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorAgreementTypeListActionTypes } from "./ActionTypes";
import { GetAgreementTypeListRes } from "./Model";
import {
  getDoctorAgreementTypeListAPIResClearAction,
  getDoctorAgreementTypeListErrorAction,
  getDoctorAgreementTypeListLoadingAction,
  getDoctorAgreementTypeListSuccessAction,
  getDoctorAgreementTypeListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetAgreementTypeListRes;
export const getAgreementTypeList = () => {
  return function (dispatch: Dispatch<GetDoctorAgreementTypeListActionTypes>) {
    dispatch(getDoctorAgreementTypeListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/agreement`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorAgreementTypeListLoadingAction(false));
        dispatch(
          getDoctorAgreementTypeListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. agreement type list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorAgreementTypeListLoadingAction(false));
        dispatch(
          getDoctorAgreementTypeListErrorAction(
            {} as GetAgreementTypeListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorAgreementTypeListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorAgreementTypeListActionTypes>) {
    dispatch(
      getDoctorAgreementTypeListAPIMsgAction(
        apiRes as GetAgreementTypeListRes,
        "",
        0
      )
    );
  };
};

export const clearGetDoctorEducationListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorAgreementTypeListActionTypes>) {
    dispatch(
      getDoctorAgreementTypeListAPIResClearAction(
        {} as GetAgreementTypeListRes,
        "",
        0
      )
    );
  };
};
