import {
  EditDrWorkExperienceActionTypes,
  EDIT_DR_WORK_EXPERIENCE_FAIL,
  EDIT_DR_WORK_EXPERIENCE_LOADING,
  EDIT_DR_WORK_EXPERIENCE_SUCCESS,
  EditDrWorkExperienceState,
  EDIT_DR_WORK_EXPERIENCE_API_MSG,
  EDIT_DR_WORK_EXPERIENCE_API_RES,
} from "./ActionTypes";
import { EditDrWorkExperienceRes } from "./Model";

const initialState: EditDrWorkExperienceState = {
  loading: false,
  editDrWorkExperienceRes: {} as EditDrWorkExperienceRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editDrWorkExperienceReducer = (
  state = initialState,
  action: EditDrWorkExperienceActionTypes
): EditDrWorkExperienceState => {
  switch (action.type) {
    case EDIT_DR_WORK_EXPERIENCE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_DR_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        editDrWorkExperienceRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_DR_WORK_EXPERIENCE_FAIL:
      return {
        ...state,
        editDrWorkExperienceRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_WORK_EXPERIENCE_API_MSG:
      return {
        ...state,
        editDrWorkExperienceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_WORK_EXPERIENCE_API_RES:
      return {
        ...state,
        editDrWorkExperienceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
