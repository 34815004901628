import {
  CreateOrScheduleMeetingStateType,
  CreateOrScheduleMeetingTypes,
  CREATE_OR_SCHEDULE_FAIL,
  CREATE_OR_SCHEDULE_LOADING,
  CREATE_OR_SCHEDULE_SUCCESS,
  DeleteMeetingStateType,
  DeleteMeetingTypes,
  DELETE_MEETING_FAIL,
  DELETE_MEETING_LOADING,
  DELETE_MEETING_SUCCESS,
  MeetingListStateType,
  MeetingListTypes,
  MEETING_LIST_FAIL,
  MEETING_LIST_LOADING,
  MEETING_LIST_SUCCESS,
} from "./ActionTypes";
import { DeactivateMeeting } from "./Model";

// get meeting list
const initialStateMeetingList: MeetingListStateType = {
  loading: false,
  meetingList: [],
  message: Object.assign({}),
  status: Object.assign({}),
  errRes: "",
};

export const meetingListReducer = (
  state = initialStateMeetingList,
  action: MeetingListTypes
): MeetingListStateType => {
  switch (action.type) {
    case MEETING_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        meetingList: action.meetingList,
        message: action.message,
        status: action.status,
        errRes: action.errRes,
      };
    case MEETING_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MEETING_LIST_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
// create or schedule meeting
const initialStateCreateOrScheduleMeeting: CreateOrScheduleMeetingStateType = {
  loading: false,
  message: Object.assign({}),
  status: Object.assign({}),
  errRes: "",
};

export const createOrScheduleReducer = (
  state = initialStateCreateOrScheduleMeeting,
  action: CreateOrScheduleMeetingTypes
): CreateOrScheduleMeetingStateType => {
  switch (action.type) {
    case CREATE_OR_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
        errRes: action.errRes,
      };
    case CREATE_OR_SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_OR_SCHEDULE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
// create or schedule meeting
const initialStateDeleteMeeting: DeleteMeetingStateType = {
  loading: false,
  payload: {} as DeactivateMeeting,
  errMsg: "",
};

export const deleteMeetingReducer = (
  state = initialStateDeleteMeeting,
  action: DeleteMeetingTypes
): DeleteMeetingStateType => {
  switch (action.type) {
    case DELETE_MEETING_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: action.payload,
        errMsg: action.errMsg,
      };
    case DELETE_MEETING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MEETING_FAIL:
      return {
        ...state,
        loading: false,
        payload: {} as DeactivateMeeting,
        errMsg: action.errMsg,
      };
    default:
      return state;
  }
};
