import {
  ScheduleMeetingActionTypes,
  SET_SCHEDULE_MEETING_FAIL,
  SET_SCHEDULE_MEETING_LOADING,
  SET_SCHEDULE_MEETING_SUCCESS,
  ScheduleMeetingState,
  SET_SCHEDULE_MEETING_API_MSG,
  SET_SCHEDULE_MEETING_API_RES,
} from "./ActionTypes";
import { CreateScheduleMeetingValue } from "./Model";

const initialStateEditPosts: ScheduleMeetingState = {
  loading: false,
  setScheduleMeetingRes: {} as CreateScheduleMeetingValue,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const createScheduleMeetingReducer = (
  state = initialStateEditPosts,
  action: ScheduleMeetingActionTypes
): ScheduleMeetingState => {
  switch (action.type) {
    case SET_SCHEDULE_MEETING_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_SCHEDULE_MEETING_SUCCESS:
      return {
        ...state,
        setScheduleMeetingRes: action.payload,
        successMsg: action.successMsg,
      };
    case SET_SCHEDULE_MEETING_FAIL:
      return {
        ...state,
        setScheduleMeetingRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SET_SCHEDULE_MEETING_API_MSG:
      return {
        ...state,
        setScheduleMeetingRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SET_SCHEDULE_MEETING_API_RES:
      return {
        ...state,
        setScheduleMeetingRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
