import { GetCoverLetterRes } from "./Model";
export const GET_DOCTOR_COVER_LETTER_LOADING =
  "GET_DOCTOR_COVER_LETTER_LOADING";
export const GET_DOCTOR_COVER_LETTER_SUCCESS =
  "GET_DOCTOR_COVER_LETTER_SUCCESS";
export const GET_DOCTOR_COVER_LETTER_FAIL = "GET_DOCTOR_COVER_LETTER_FAIL";
export const GET_DOCTOR_COVER_LETTER_API_MSG =
  "GET_DOCTOR_COVER_LETTER_API_MSG";
export const GET_DOCTOR_COVER_LETTER_API_RES =
  "GET_DOCTOR_COVER_LETTER_API_RES";

export interface GetDoctorCoverLetterState {
  getDoctorCoverLetterRes: GetCoverLetterRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorCoverLetterLoading {
  type: typeof GET_DOCTOR_COVER_LETTER_LOADING;
  loading: boolean;
}
export interface GetDoctorCoverLetterSuccess {
  type: typeof GET_DOCTOR_COVER_LETTER_SUCCESS;
  payload: GetCoverLetterRes;
  successMsg: string;
}
export interface GetDoctorCoverLetterFail {
  type: typeof GET_DOCTOR_COVER_LETTER_FAIL;
  payload: GetCoverLetterRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorCoverLetterAPIMsg {
  type: typeof GET_DOCTOR_COVER_LETTER_API_MSG;
  payload: GetCoverLetterRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorCoverLetterAPIRes {
  type: typeof GET_DOCTOR_COVER_LETTER_API_RES;
  payload: GetCoverLetterRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorCoverLetterActionTypes =
  | GetDoctorCoverLetterLoading
  | GetDoctorCoverLetterSuccess
  | GetDoctorCoverLetterFail
  | GetDoctorCoverLetterAPIMsg
  | GetDoctorCoverLetterAPIRes;
