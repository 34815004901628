import {
  UPDATE_PATIENT_DEMOGRAPHICS_FAIL,
  UPDATE_PATIENT_DEMOGRAPHICS_LOADING,
  UPDATE_PATIENT_DEMOGRAPHICS_SUCCESS,
  UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG,
  UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_RES,
  UpdateDemographicsDetailsActionTypes,
} from "./ActionTypes";
import { UpdateDemographicsDetailsByPatientIdRes } from "./Model";

export const updatePatientDemographicsDetailsLoadingAction = (
  loading: boolean
): UpdateDemographicsDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DEMOGRAPHICS_LOADING,
    loading: loading,
  };
};

export const updatePatientDemographicsDetailsSuccessAction = (
  updatePatientDemographicsDetailsResponse: UpdateDemographicsDetailsByPatientIdRes,
  successMsg: string
): UpdateDemographicsDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DEMOGRAPHICS_SUCCESS,
    payload: updatePatientDemographicsDetailsResponse,
    successMsg: successMsg,
  };
};

export const updatePatientDemographicsDetailsErrorAction = (
  updatePatientDemographicsDetailsResponse: UpdateDemographicsDetailsByPatientIdRes,
  errMsg: string,
  status: number
): UpdateDemographicsDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DEMOGRAPHICS_FAIL,
    payload: updatePatientDemographicsDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const updatePatientDemographicsDetailsUpdateAPIMsgAction = (
  updatePatientDemographicsDetailsResponse: UpdateDemographicsDetailsByPatientIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDemographicsDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_MSG,
    payload: updatePatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const updatePatientDemographicsDetailsAPIResClearAction = (
  updatePatientDemographicsDetailsResponse: UpdateDemographicsDetailsByPatientIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): UpdateDemographicsDetailsActionTypes => {
  return {
    type: UPDATE_PATIENT_DEMOGRAPHICS_UPDATE_API_RES,
    payload: updatePatientDemographicsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
