import {
  GET_PRESCRIPTION_LIST_FAIL,
  GET_PRESCRIPTION_LIST_LOADING,
  GET_PRESCRIPTION_LIST_SUCCESS,
  GET_PRESCRIPTION_LIST_API_MSG,
  GET_PRESCRIPTION_LIST_API_RES,
  GetPrescriptionListActionTypes,
} from "./ActionTypes";
import { GetPrescriptionListRes } from "./Model";

export const getPrescriptionListLoadingAction = (
  loading: boolean
): GetPrescriptionListActionTypes => {
  return {
    type: GET_PRESCRIPTION_LIST_LOADING,
    loading: loading,
  };
};

export const getPrescriptionListSuccessAction = (
  getPrescriptionListResponse: GetPrescriptionListRes,
  successMsg: string
): GetPrescriptionListActionTypes => {
  return {
    type: GET_PRESCRIPTION_LIST_SUCCESS,
    payload: getPrescriptionListResponse,
    successMsg: successMsg,
  };
};

export const getPrescriptionListErrorAction = (
  getPrescriptionListResponse: GetPrescriptionListRes,
  errMsg: string,
  status: number
): GetPrescriptionListActionTypes => {
  return {
    type: GET_PRESCRIPTION_LIST_FAIL,
    payload: getPrescriptionListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPrescriptionListAPIMsgAction = (
  getPrescriptionListResponse: GetPrescriptionListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPrescriptionListActionTypes => {
  return {
    type: GET_PRESCRIPTION_LIST_API_MSG,
    payload: getPrescriptionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPrescriptionListAPIResClearAction = (
  getPrescriptionListResponse: GetPrescriptionListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPrescriptionListActionTypes => {
  return {
    type: GET_PRESCRIPTION_LIST_API_RES,
    payload: getPrescriptionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
