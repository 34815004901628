import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { EditDrAwardActionTypes } from "./ActionTypes";
import { EditAwardBody, EditAwardRes } from "./Model";
import {
  editDrAwardAPIResClearAction,
  editDrAwardErrorAction,
  editDrAwardLoadingAction,
  editDrAwardSuccessAction,
  editDrAwardAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EditAwardRes;
export const editDrAward = (payload: EditAwardBody) => {
  return function (dispatch: Dispatch<EditDrAwardActionTypes>) {
    dispatch(editDrAwardLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/api/onboarding/edit_awards`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(editDrAwardLoadingAction(false));
        dispatch(
          editDrAwardSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. award has been updated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editDrAwardLoadingAction(false));
        dispatch(
          editDrAwardErrorAction(
            {} as EditAwardRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDrAwardAPIResMsg = () => {
  return function (dispatch: Dispatch<EditDrAwardActionTypes>) {
    dispatch(editDrAwardAPIMsgAction(apiRes as EditAwardRes, "", "", 0));
  };
};

export const clearEditDrAwardAPIRes = () => {
  return function (dispatch: Dispatch<EditDrAwardActionTypes>) {
    dispatch(editDrAwardAPIResClearAction({} as EditAwardRes, "", "", 0));
  };
};
