import { AddEducationDetailsRes } from "./Model";
export const ADD_DOCTOR_EDUCATION_DETAILS_LOADING =
  "ADD_DOCTOR_EDUCATION_DETAILS_LOADING";
export const ADD_DOCTOR_EDUCATION_DETAILS_SUCCESS =
  "ADD_DOCTOR_EDUCATION_DETAILS_SUCCESS";
export const ADD_DOCTOR_EDUCATION_DETAILS_FAIL =
  "ADD_DOCTOR_EDUCATION_DETAILS_FAIL";
export const ADD_DOCTOR_EDUCATION_DETAILS_API_MSG =
  "ADD_DOCTOR_EDUCATION_DETAILS_API_MSG";
export const ADD_DOCTOR_EDUCATION_DETAILS_API_RES =
  "ADD_DOCTOR_EDUCATION_DETAILS_API_RES";

export interface AddDoctorEducationDetailsState {
  addDoctorEducationDetailsRes: AddEducationDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorEducationDetailsLoading {
  type: typeof ADD_DOCTOR_EDUCATION_DETAILS_LOADING;
  loading: boolean;
}
export interface AddDoctorEducationDetailsSuccess {
  type: typeof ADD_DOCTOR_EDUCATION_DETAILS_SUCCESS;
  payload: AddEducationDetailsRes;
  successMsg: string;
}
export interface AddDoctorEducationDetailsFail {
  type: typeof ADD_DOCTOR_EDUCATION_DETAILS_FAIL;
  payload: AddEducationDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddDoctorEducationDetailsAPIMsg {
  type: typeof ADD_DOCTOR_EDUCATION_DETAILS_API_MSG;
  payload: AddEducationDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDoctorEducationDetailsAPIRes {
  type: typeof ADD_DOCTOR_EDUCATION_DETAILS_API_RES;
  payload: AddEducationDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDoctorEducationDetailsActionTypes =
  | AddDoctorEducationDetailsLoading
  | AddDoctorEducationDetailsSuccess
  | AddDoctorEducationDetailsFail
  | AddDoctorEducationDetailsAPIMsg
  | AddDoctorEducationDetailsAPIRes;
