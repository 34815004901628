import {
    SectionTemplateListActionTypes,
    SECTION_TEMPLATE_LIST_FAIL,
    SECTION_TEMPLATE_LIST_LOADING,
    SECTION_TEMPLATE_LIST_SUCCESS,
    SectionTemplateListState,
    SECTION_TEMPLATE_LIST_API_MSG,
    SECTION_TEMPLATE_LIST_API_RES,
  } from "./ActionTypes";
  import { SectionTemplateListResponse } from "./Model";
  
  const initialStateEditPosts: SectionTemplateListState = {
    loading: false,
    sectionTemplateListResponse: {} as SectionTemplateListResponse,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const sectionTemplateListReducer = (
    state = initialStateEditPosts,
    action: SectionTemplateListActionTypes
  ): SectionTemplateListState => {
    switch (action.type) {
      case SECTION_TEMPLATE_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case SECTION_TEMPLATE_LIST_SUCCESS:
        return {
          ...state,
          sectionTemplateListResponse: action.payload,
          successMsg: action.successMsg,
        };
      case SECTION_TEMPLATE_LIST_FAIL:
        return {
          ...state,
          sectionTemplateListResponse: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case SECTION_TEMPLATE_LIST_API_MSG:
        return {
          ...state,
          sectionTemplateListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case SECTION_TEMPLATE_LIST_API_RES:
        return {
          ...state,
          sectionTemplateListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };