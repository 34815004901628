import {
  SIGN_DOCTOR_AGREEMENT_FAIL,
  SIGN_DOCTOR_AGREEMENT_LOADING,
  SIGN_DOCTOR_AGREEMENT_SUCCESS,
  SIGN_DOCTOR_AGREEMENT_API_MSG,
  SIGN_DOCTOR_AGREEMENT_API_RES,
  SignDoctorAgreementActionTypes,
} from "./ActionTypes";
import { SignAgreementRes } from "./Model";

export const signDoctorAgreementLoadingAction = (
  loading: boolean
): SignDoctorAgreementActionTypes => {
  return {
    type: SIGN_DOCTOR_AGREEMENT_LOADING,
    loading: loading,
  };
};

export const signDoctorAgreementSuccessAction = (
  signDoctorAgreementResponse: SignAgreementRes,
  successMsg: string
): SignDoctorAgreementActionTypes => {
  return {
    type: SIGN_DOCTOR_AGREEMENT_SUCCESS,
    payload: signDoctorAgreementResponse,
    successMsg: successMsg,
  };
};

export const signDoctorAgreementErrorAction = (
  signDoctorAgreementResponse: SignAgreementRes,
  errMsg: string,
  status: number
): SignDoctorAgreementActionTypes => {
  return {
    type: SIGN_DOCTOR_AGREEMENT_FAIL,
    payload: signDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const signDoctorAgreementAPIMsgAction = (
  signDoctorAgreementResponse: SignAgreementRes,
  errMsg: string,
  status: number
): SignDoctorAgreementActionTypes => {
  return {
    type: SIGN_DOCTOR_AGREEMENT_API_MSG,
    payload: signDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const signDoctorAgreementAPIResClearAction = (
  signDoctorAgreementResponse: SignAgreementRes,
  errMsg: string,
  status: number
): SignDoctorAgreementActionTypes => {
  return {
    type: SIGN_DOCTOR_AGREEMENT_API_RES,
    payload: signDoctorAgreementResponse,
    errorMsg: errMsg,
    status: status,
  };
};
