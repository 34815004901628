import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorLegalDetailsActionTypes } from "./ActionTypes";
import { GetLegalDetailsByDoctorRes } from "./Model";
import {
  getDoctorLegalDetailsAPIResClearAction,
  getDoctorLegalDetailsErrorAction,
  getDoctorLegalDetailsLoadingAction,
  getDoctorLegalDetailsSuccessAction,
  getDoctorLegalDetailsAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetLegalDetailsByDoctorRes;
export const getLegalDetails = () => {
  return function (dispatch: Dispatch<GetDoctorLegalDetailsActionTypes>) {
    dispatch(getDoctorLegalDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL + `/doctorapp/api/onboarding/get_legal_details_by_doctor`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorLegalDetailsLoadingAction(false));
        dispatch(
          getDoctorLegalDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorLegalDetailsLoadingAction(false));
        dispatch(
          getDoctorLegalDetailsErrorAction(
            {} as GetLegalDetailsByDoctorRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorLegalDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorLegalDetailsActionTypes>) {
    dispatch(
      getDoctorLegalDetailsAPIMsgAction(
        apiRes as GetLegalDetailsByDoctorRes,
        "",
        0
      )
    );
  };
};

export const clearGetDoctorLegalDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorLegalDetailsActionTypes>) {
    dispatch(
      getDoctorLegalDetailsAPIResClearAction(
        {} as GetLegalDetailsByDoctorRes,
        "",
        0
      )
    );
  };
};
