import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { EditDrIdProofActionTypes } from "./ActionTypes";
import { EditDrIdProofBody, EditDrIdProofRes } from "./Model";
import {
  editDrIdProofAPIResClearAction,
  editDrIdProofErrorAction,
  editDrIdProofLoadingAction,
  editDrIdProofSuccessAction,
  editDrIdProofAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EditDrIdProofRes;
export const editDrIdProof = (payload: EditDrIdProofBody) => {
  return function (dispatch: Dispatch<EditDrIdProofActionTypes>) {
    dispatch(editDrIdProofLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/doctoridproof/edit_doctor_idproof`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editDrIdProofLoadingAction(false));
        dispatch(
          editDrIdProofSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. id proof details has been updated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editDrIdProofLoadingAction(false));
        dispatch(
          editDrIdProofErrorAction(
            {} as EditDrIdProofRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDrIdProofAPIResMsg = () => {
  return function (dispatch: Dispatch<EditDrIdProofActionTypes>) {
    dispatch(editDrIdProofAPIMsgAction(apiRes as EditDrIdProofRes, "", "", 0));
  };
};

export const clearEditDrIdProofAPIRes = () => {
  return function (dispatch: Dispatch<EditDrIdProofActionTypes>) {
    dispatch(editDrIdProofAPIResClearAction({} as EditDrIdProofRes, "", "", 0));
  };
};
