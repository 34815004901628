import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { SaveAsDraftPrescriptionActionTypes } from "./ActionTypes";
import { GeneratePrescriptionBody } from "../GeneratePrescriptionApi/Model";
import { SaveAsDraftPrescriptionRes } from "./Model";
import {
  saveAsDraftPrescriptionAPIResClearAction,
  saveAsDraftPrescriptionErrorAction,
  saveAsDraftPrescriptionLoadingAction,
  saveAsDraftPrescriptionSuccessAction,
  saveAsDraftPrescriptionAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as SaveAsDraftPrescriptionRes;
export const saveAsDraftPrescription = (payload: GeneratePrescriptionBody) => {
  return function (dispatch: Dispatch<SaveAsDraftPrescriptionActionTypes>) {
    dispatch(saveAsDraftPrescriptionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/patientapp/api/save-prescription/`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(saveAsDraftPrescriptionLoadingAction(false));
        dispatch(
          saveAsDraftPrescriptionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Prescription has been saved successfully as draft"
          )
        );
      })
      .catch((error) => {
        dispatch(saveAsDraftPrescriptionLoadingAction(false));
        dispatch(
          saveAsDraftPrescriptionErrorAction(
            {} as SaveAsDraftPrescriptionRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateSaveAsDraftPrescriptionAPIResMsg = () => {
  return function (dispatch: Dispatch<SaveAsDraftPrescriptionActionTypes>) {
    dispatch(
      saveAsDraftPrescriptionAPIMsgAction(
        apiRes as SaveAsDraftPrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearSaveAsDraftPrescriptionAPIRes = () => {
  return function (dispatch: Dispatch<SaveAsDraftPrescriptionActionTypes>) {
    dispatch(
      saveAsDraftPrescriptionAPIResClearAction(
        {} as SaveAsDraftPrescriptionRes,
        "",
        "",
        0
      )
    );
  };
};
