import {
  DR_ACC_DETAILS_FAIL,
  DR_ACC_DETAILS_LOADING,
  DR_ACC_DETAILS_SUCCESS,
  DR_ACC_DETAILS_UPDATE_API_MSG,
  DR_ACC_DETAILS_UPDATE_API_RES,
  DoctorAccountDetailsTypes,
} from "./ActionTypes";
import { DoctorAccDetailsRes } from "./Model";

export const doctorAccountDetailsLoadingAction = (
  loading: boolean
): DoctorAccountDetailsTypes => {
  return {
    type: DR_ACC_DETAILS_LOADING,
    loading: loading,
  };
};

export const doctorAccountDetailsSuccessAction = (
  doctorAccountDetailsResponse: DoctorAccDetailsRes,
  successMsg: string
): DoctorAccountDetailsTypes => {
  return {
    type: DR_ACC_DETAILS_SUCCESS,
    payload: doctorAccountDetailsResponse,
    successMsg: successMsg,
  };
};

export const doctorAccountDetailsErrorAction = (
  doctorAccountDetailsResponse: DoctorAccDetailsRes,
  errMsg: string,
  status: number
): DoctorAccountDetailsTypes => {
  return {
    type: DR_ACC_DETAILS_FAIL,
    payload: doctorAccountDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const doctorAccountDetailsUpdateAPIMsgAction = (
  doctorAccountDetailsResponse: DoctorAccDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorAccountDetailsTypes => {
  return {
    type: DR_ACC_DETAILS_UPDATE_API_MSG,
    payload: doctorAccountDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const doctorAccountDetailsAPIResClearAction = (
  doctorAccountDetailsResponse: DoctorAccDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorAccountDetailsTypes => {
  return {
    type: DR_ACC_DETAILS_UPDATE_API_RES,
    payload: doctorAccountDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
