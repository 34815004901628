import { AddPatientImportConfigDataRes } from "./Model";

export const ADD_PATIENT_IMPORT_CONFIG_LOADING =
  "ADD_PATIENT_IMPORT_CONFIG_LOADING";
export const ADD_PATIENT_IMPORT_CONFIG_SUCCESS =
  "ADD_PATIENT_IMPORT_CONFIG_SUCCESS";
export const ADD_PATIENT_IMPORT_CONFIG_FAIL = "ADD_PATIENT_IMPORT_CONFIG_FAIL";
export const ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_MSG =
  "ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_MSG";
export const ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_RES =
  "ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_RES";

export interface AddPatientImportConfigDataState {
  addAddPatientImportConfigDataRes: AddPatientImportConfigDataRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientImportConfigDataLoading {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_LOADING;
  loading: boolean;
}
export interface AddPatientImportConfigDataSuccess {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_SUCCESS;
  payload: AddPatientImportConfigDataRes;
  successMsg: string;
}
export interface AddPatientImportConfigDataFail {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_FAIL;
  payload: AddPatientImportConfigDataRes;
  errorMsg: string;
  status: number;
}
export interface AddPatientImportConfigDataUpdateAPIMsg {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_MSG;
  payload: AddPatientImportConfigDataRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientImportConfigDataUpdateAPIRes {
  type: typeof ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_RES;
  payload: AddPatientImportConfigDataRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddPatientImportConfigDataActionTypes =
  | AddPatientImportConfigDataLoading
  | AddPatientImportConfigDataSuccess
  | AddPatientImportConfigDataFail
  | AddPatientImportConfigDataUpdateAPIMsg
  | AddPatientImportConfigDataUpdateAPIRes;
