import {
  GenerateDoctorAgreementActionTypes,
  GENERATE_DOCTOR_AGREEMENT_FAIL,
  GENERATE_DOCTOR_AGREEMENT_LOADING,
  GENERATE_DOCTOR_AGREEMENT_SUCCESS,
  GenerateDoctorAgreementState,
  GENERATE_DOCTOR_AGREEMENT_API_MSG,
  GENERATE_DOCTOR_AGREEMENT_API_RES,
} from "./ActionTypes";
import { GenerateAgreementRes } from "./Model";

const initialStateUpdatePosts: GenerateDoctorAgreementState = {
  loading: false,
  generateDoctorAgreementRes: {} as GenerateAgreementRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const generateAgreementReducer = (
  state = initialStateUpdatePosts,
  action: GenerateDoctorAgreementActionTypes
): GenerateDoctorAgreementState => {
  switch (action.type) {
    case GENERATE_DOCTOR_AGREEMENT_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GENERATE_DOCTOR_AGREEMENT_SUCCESS:
      return {
        ...state,
        generateDoctorAgreementRes: action.payload,
        successMsg: action.successMsg,
      };
    case GENERATE_DOCTOR_AGREEMENT_FAIL:
      return {
        ...state,
        generateDoctorAgreementRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GENERATE_DOCTOR_AGREEMENT_API_MSG:
      return {
        ...state,
        generateDoctorAgreementRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GENERATE_DOCTOR_AGREEMENT_API_RES:
      return {
        ...state,
        generateDoctorAgreementRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
