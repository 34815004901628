import {
  GET_DOCTOR_SIGNATURE_DETAILS_FAIL,
  GET_DOCTOR_SIGNATURE_DETAILS_LOADING,
  GET_DOCTOR_SIGNATURE_DETAILS_SUCCESS,
  GET_DOCTOR_SIGNATURE_DETAILS_API_MSG,
  GET_DOCTOR_SIGNATURE_DETAILS_API_RES,
  GetDoctorSignatureDetailsActionTypes,
} from "./ActionTypes";
import { DoctorSignatureRes } from "./Model";

export const getDoctorSignatureDetailsLoadingAction = (
  loading: boolean
): GetDoctorSignatureDetailsActionTypes => {
  return {
    type: GET_DOCTOR_SIGNATURE_DETAILS_LOADING,
    loading: loading,
  };
};

export const getDoctorSignatureDetailsSuccessAction = (
  getDoctorSignatureDetailsResponse: DoctorSignatureRes,
  successMsg: string
): GetDoctorSignatureDetailsActionTypes => {
  return {
    type: GET_DOCTOR_SIGNATURE_DETAILS_SUCCESS,
    payload: getDoctorSignatureDetailsResponse,
    successMsg: successMsg,
  };
};

export const getDoctorSignatureDetailsErrorAction = (
  getDoctorSignatureDetailsResponse: DoctorSignatureRes,
  errMsg: string,
  status: number
): GetDoctorSignatureDetailsActionTypes => {
  return {
    type: GET_DOCTOR_SIGNATURE_DETAILS_FAIL,
    payload: getDoctorSignatureDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorSignatureDetailsAPIMsgAction = (
  getDoctorSignatureDetailsResponse: DoctorSignatureRes,
  errMsg: string,
  status: number
): GetDoctorSignatureDetailsActionTypes => {
  return {
    type: GET_DOCTOR_SIGNATURE_DETAILS_API_MSG,
    payload: getDoctorSignatureDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorSignatureDetailsAPIResClearAction = (
  getDoctorSignatureDetailsResponse: DoctorSignatureRes,
  errMsg: string,
  status: number
): GetDoctorSignatureDetailsActionTypes => {
  return {
    type: GET_DOCTOR_SIGNATURE_DETAILS_API_RES,
    payload: getDoctorSignatureDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
