import { CreateInstantMeetingValue } from "./Model";
export const SET_CREATE_MEETING_LOADING = "SET_CREATE_MEETING_LOADING";
export const SET_CREATE_MEETING_SUCCESS = "SET_CREATE_MEETING_SUCCESS";
export const SET_CREATE_MEETING_FAIL = "SET_CREATE_MEETING_FAIL";
export const SET_CREATE_MEETING_API_MSG = "SET_CREATE_MEETING_API_MSG";
export const SET_CREATE_MEETING_API_RES = "SET_CREATE_MEETING_API_RES";

export interface CreateMeetingState {
  setCreateMeetingRes: CreateInstantMeetingValue;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CreateMeetingLoading {
  type: typeof SET_CREATE_MEETING_LOADING;
  loading: boolean;
}
export interface CreateMeetingSuccess {
  type: typeof SET_CREATE_MEETING_SUCCESS;
  payload: CreateInstantMeetingValue;
  successMsg: string;
}
export interface CreateMeetingFail {
  type: typeof SET_CREATE_MEETING_FAIL;
  payload: CreateInstantMeetingValue;
  errorMsg: string;
  status: number;
}
export interface CreateMeetingAPIMsg {
  type: typeof SET_CREATE_MEETING_API_MSG;
  payload: CreateInstantMeetingValue;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CreateMeetingAPIRes {
  type: typeof SET_CREATE_MEETING_API_RES;
  payload: CreateInstantMeetingValue;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type CreateMeetingActionTypes =
  | CreateMeetingLoading
  | CreateMeetingSuccess
  | CreateMeetingFail
  | CreateMeetingAPIMsg
  | CreateMeetingAPIRes;
