import { GenerateAgreementRes } from "./Model";
export const GENERATE_DOCTOR_AGREEMENT_LOADING =
  "GENERATE_DOCTOR_AGREEMENT_LOADING";
export const GENERATE_DOCTOR_AGREEMENT_SUCCESS =
  "GENERATE_DOCTOR_AGREEMENT_SUCCESS";
export const GENERATE_DOCTOR_AGREEMENT_FAIL = "GENERATE_DOCTOR_AGREEMENT_FAIL";
export const GENERATE_DOCTOR_AGREEMENT_API_MSG =
  "GENERATE_DOCTOR_AGREEMENT_API_MSG";
export const GENERATE_DOCTOR_AGREEMENT_API_RES =
  "GENERATE_DOCTOR_AGREEMENT_API_RES";

export interface GenerateDoctorAgreementState {
  generateDoctorAgreementRes: GenerateAgreementRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GenerateDoctorAgreementLoading {
  type: typeof GENERATE_DOCTOR_AGREEMENT_LOADING;
  loading: boolean;
}
export interface GenerateDoctorAgreementSuccess {
  type: typeof GENERATE_DOCTOR_AGREEMENT_SUCCESS;
  payload: GenerateAgreementRes;
  successMsg: string;
}
export interface GenerateDoctorAgreementFail {
  type: typeof GENERATE_DOCTOR_AGREEMENT_FAIL;
  payload: GenerateAgreementRes;
  errorMsg: string;
  status: number;
}
export interface GenerateDoctorAgreementAPIMsg {
  type: typeof GENERATE_DOCTOR_AGREEMENT_API_MSG;
  payload: GenerateAgreementRes;
  errorMsg: string;
  status: number;
}
export interface GenerateDoctorAgreementAPIRes {
  type: typeof GENERATE_DOCTOR_AGREEMENT_API_RES;
  payload: GenerateAgreementRes;
  errorMsg: string;
  status: number;
}

export type GenerateDoctorAgreementActionTypes =
  | GenerateDoctorAgreementLoading
  | GenerateDoctorAgreementSuccess
  | GenerateDoctorAgreementFail
  | GenerateDoctorAgreementAPIMsg
  | GenerateDoctorAgreementAPIRes;
