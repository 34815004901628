import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../URL";
import { DoctorListForCaseActionTypes } from "./ActionTypes";
import { DoctorListForCaseRes } from "./Model";
import {
  DoctorListForCaseAPIResClearAction,
  DoctorListForCaseErrorAction,
  DoctorListForCaseLoadingAction,
  DoctorListForCaseSuccessAction,
  DoctorListForCaseAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as DoctorListForCaseRes;
export const getDoctorListForCase = (caseId: string) => {
  return function (dispatch: Dispatch<DoctorListForCaseActionTypes>) {
    dispatch(DoctorListForCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/patientapp/api/patientMedicalData/doctorList_for_patientCase`,
        { "caseId": caseId },
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(DoctorListForCaseLoadingAction(false));
        dispatch(
          DoctorListForCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : ""
          )
        );
      })
      .catch((error) => {
        dispatch(DoctorListForCaseLoadingAction(false));
        dispatch(
          DoctorListForCaseErrorAction(
            {} as DoctorListForCaseRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDoctorListForCaseAPIResMsg = () => {
  return function (dispatch: Dispatch<DoctorListForCaseActionTypes>) {
    dispatch(
      DoctorListForCaseAPIMsgAction(
        apiRes as DoctorListForCaseRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDoctorListForCaseAPIRes = () => {
  return function (dispatch: Dispatch<DoctorListForCaseActionTypes>) {
    dispatch(
      DoctorListForCaseAPIResClearAction(
        {} as DoctorListForCaseRes,
        "",
        "",
        0
      )
    );
  };
};
