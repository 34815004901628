import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Divider,
  AlertColor,
  Alert,
} from "@mui/material";
import { t } from "i18next";
import { AppState } from "redux/store/Store";
import { ForgotPasswordBody } from "redux/forgotPassword/Model";
import {
  clearForgotPasswordLoginAPIRes,
  forgotPasswordAPI,
} from "redux/forgotPassword/API";
import MWLoader from "components/MWLoader";

interface Props {
  activeDialog?: boolean | any;
  forgotPasswordUpdateState?: boolean | any;
}

export default function ForgetPassword({
  activeDialog,
  forgotPasswordUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const [dialogActive, setDialogActive] = useState(false);
  useEffect(() => {
    setDialogActive(activeDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDialog]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [forgotPasswordAPIMsg, setForgotPasswordAPIMsg] = useState("");
  const [forgotPasswordAPIMsgType, setForgotPasswordAPIMsgType] =
    useState<AlertColor>("success");
  const [loading, setLoading] = useState(false);
  const forgotPasswordRes = useSelector(
    (state: AppState) => state.forgotPassword
  );
  const initiateForgetPassword = () => {
    if (email === "") {
      setEmailError(`${t("common.erroremail", { ns: ["home"] })}`);
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email) ===
      false
    ) {
      setEmailError(`${t("common.validemail", { ns: ["home"] })}`);
    } else {
      dispatch(forgotPasswordAPI({ email: email } as ForgotPasswordBody));
    }
  };
  const closeDialog = () => {
    forgotPasswordUpdateState(false);
    setEmailError("");
    setEmailError("");
  };
  useEffect(() => {
    setLoading(forgotPasswordRes?.loading);
    if (forgotPasswordRes?.successMsg !== undefined) {
      setForgotPasswordAPIMsgType("success");
      setForgotPasswordAPIMsg(forgotPasswordRes?.successMsg);
    }
    if (forgotPasswordRes?.errorMsg !== "") {
      setForgotPasswordAPIMsgType("error");
      setForgotPasswordAPIMsg(forgotPasswordRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordRes]);
  return (
    <Dialog open={dialogActive} onClose={closeDialog}>
      {loading ? <MWLoader /> : null}
      <DialogTitle>
        <Typography fontWeight="bold">{t("loginpage.forgotpassword", { ns: ["home"] })}</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
        {t("loginpage.passResetLink", { ns: ["home"] })}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          size="small"
          fullWidth
          label={t("common.emailorphno", { ns: ["home"] })}
          value={email}
          id="supportemail"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(event.target.value);
            setEmailError("");
          }}
          helperText={
            emailError === ""
              ? `${t("loginpage.provEmailOrPhn", { ns: ["home"] })}`
              : emailError
          }
          error={emailError === "" ? false : true}
        />
        {forgotPasswordAPIMsg !== "" ? (
          <Box>
            <Alert
              severity={forgotPasswordAPIMsgType}
              onClose={() => {
                dispatch(clearForgotPasswordLoginAPIRes());
              }}
            >
              {forgotPasswordAPIMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={closeDialog} sx={{ textTransform: "none" }}>
        {t("accountdetail.cancel", { ns: ["home"] })}
        </Button>
        <Button
          variant="contained"
          onClick={initiateForgetPassword}
          sx={{ textTransform: "none" }}
          disabled={forgotPasswordRes?.loading}
        >
             {t("accountdetail.send", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
