import {
  EditDrAwardActionTypes,
  EDIT_DR_AWARD_FAIL,
  EDIT_DR_AWARD_LOADING,
  EDIT_DR_AWARD_SUCCESS,
  EditDrAwardState,
  EDIT_DR_AWARD_API_MSG,
  EDIT_DR_AWARD_API_RES,
} from "./ActionTypes";
import { EditAwardRes } from "./Model";

const initialStateEditPosts: EditDrAwardState = {
  loading: false,
  editDrAwardRes: {} as EditAwardRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editDrAwardReducer = (
  state = initialStateEditPosts,
  action: EditDrAwardActionTypes
): EditDrAwardState => {
  switch (action.type) {
    case EDIT_DR_AWARD_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_DR_AWARD_SUCCESS:
      return {
        ...state,
        editDrAwardRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_DR_AWARD_FAIL:
      return {
        ...state,
        editDrAwardRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_AWARD_API_MSG:
      return {
        ...state,
        editDrAwardRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_AWARD_API_RES:
      return {
        ...state,
        editDrAwardRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
