import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../URL";
import { setupInterceptorsTo } from "../../authentication/Interceptors";
import { AddDemographicsDetailsActionTypes } from "./ActionTypes";
import { AddDemographicsDetailsRes, AddDemographicsDetailsReq } from "./Model";
import {
  addPatientDemographicsDetailsAPIResClearAction,
  addPatientDemographicsDetailsErrorAction,
  addPatientDemographicsDetailsLoadingAction,
  addPatientDemographicsDetailsSuccessAction,
  addPatientDemographicsDetailsUpdateAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";

let apiRes = {} as AddDemographicsDetailsRes;
export const addDemographicsDetails = (payload: AddDemographicsDetailsReq) => {
  return function (dispatch: Dispatch<AddDemographicsDetailsActionTypes>) {
    dispatch(addPatientDemographicsDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL + `/patientapp/api/patient/add_patient`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes =
          res.data !== undefined ? res.data : ({} as AddDemographicsDetailsRes);
        dispatch(addPatientDemographicsDetailsLoadingAction(false));
        dispatch(
          addPatientDemographicsDetailsSuccessAction(
            res.data !== undefined
              ? res.data
              : ({} as AddDemographicsDetailsRes),
            res.data.message !== undefined
              ? res.data.message
              : "New patient has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addPatientDemographicsDetailsLoadingAction(false));
        dispatch(
          addPatientDemographicsDetailsErrorAction(
            {} as AddDemographicsDetailsRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateAddDemographicsDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDemographicsDetailsActionTypes>) {
    dispatch(
      addPatientDemographicsDetailsUpdateAPIMsgAction(
        apiRes as AddDemographicsDetailsRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAddDemographicsDetailsAPIRes = () => {
  return function (dispatch: Dispatch<AddDemographicsDetailsActionTypes>) {
    dispatch(
      addPatientDemographicsDetailsAPIResClearAction(
        {} as AddDemographicsDetailsRes,
        "",
        "",
        0
      )
    );
  };
};
