import {
  AddDrWorkExperienceActionTypes,
  ADD_DR_WORK_EXPERIENCE_FAIL,
  ADD_DR_WORK_EXPERIENCE_LOADING,
  ADD_DR_WORK_EXPERIENCE_SUCCESS,
  AddDrWorkExperienceState,
  ADD_DR_WORK_EXPERIENCE_API_MSG,
  ADD_DR_WORK_EXPERIENCE_API_RES,
} from "./ActionTypes";
import { AddWorkExperienceRes } from "./Model";

const initialStateEditPosts: AddDrWorkExperienceState = {
  loading: false,
  addDrWorkExperienceRes: {} as AddWorkExperienceRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addDrWorkExperienceReducer = (
  state = initialStateEditPosts,
  action: AddDrWorkExperienceActionTypes
): AddDrWorkExperienceState => {
  switch (action.type) {
    case ADD_DR_WORK_EXPERIENCE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DR_WORK_EXPERIENCE_SUCCESS:
      return {
        ...state,
        addDrWorkExperienceRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DR_WORK_EXPERIENCE_FAIL:
      return {
        ...state,
        addDrWorkExperienceRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_WORK_EXPERIENCE_API_MSG:
      return {
        ...state,
        addDrWorkExperienceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DR_WORK_EXPERIENCE_API_RES:
      return {
        ...state,
        addDrWorkExperienceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
