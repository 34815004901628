import { EditDrInsuranceRes } from "./Model";
export const EDIT_DR_INSURANCE_LOADING = "EDIT_DR_INSURANCE_LOADING";
export const EDIT_DR_INSURANCE_SUCCESS = "EDIT_DR_INSURANCE_SUCCESS";
export const EDIT_DR_INSURANCE_FAIL = "EDIT_DR_INSURANCE_FAIL";
export const EDIT_DR_INSURANCE_API_MSG = "EDIT_DR_INSURANCE_API_MSG";
export const EDIT_DR_INSURANCE_API_RES = "EDIT_DR_INSURANCE_API_RES";

export interface EditDrInsuranceState {
  editDrInsuranceRes: EditDrInsuranceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrInsuranceLoading {
  type: typeof EDIT_DR_INSURANCE_LOADING;
  loading: boolean;
}
export interface EditDrInsuranceSuccess {
  type: typeof EDIT_DR_INSURANCE_SUCCESS;
  payload: EditDrInsuranceRes;
  successMsg: string;
}
export interface EditDrInsuranceFail {
  type: typeof EDIT_DR_INSURANCE_FAIL;
  payload: EditDrInsuranceRes;
  errorMsg: string;
  status: number;
}
export interface EditDrInsuranceAPIMsg {
  type: typeof EDIT_DR_INSURANCE_API_MSG;
  payload: EditDrInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrInsuranceAPIRes {
  type: typeof EDIT_DR_INSURANCE_API_RES;
  payload: EditDrInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDrInsuranceActionTypes =
  | EditDrInsuranceLoading
  | EditDrInsuranceSuccess
  | EditDrInsuranceFail
  | EditDrInsuranceAPIMsg
  | EditDrInsuranceAPIRes;
