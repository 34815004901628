import {
    STEP_PREVIEW_LIST_FAIL,
    STEP_PREVIEW_LIST_LOADING,
    STEP_PREVIEW_LIST_SUCCESS,
    STEP_PREVIEW_LIST_API_MSG,
    STEP_PREVIEW_LIST_API_RES,
    StepPreviewListActionTypes,
  } from "./ActionTypes";
  import { StepPreviewResponse } from "./Model";
  
  export const stepPreviewListLoadingAction = (
    loading: boolean
  ): StepPreviewListActionTypes => {
    return {
      type: STEP_PREVIEW_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const stepPreviewListSuccessAction = (
    stepPreviewListResponse: StepPreviewResponse,
    successMsg: string
  ): StepPreviewListActionTypes => {
    return {
      type: STEP_PREVIEW_LIST_SUCCESS,
      payload: stepPreviewListResponse,
      successMsg: successMsg,
    };
  };
  
  export const stepPreviewListErrorAction = (
    stepPreviewListResponse: StepPreviewResponse,
    errMsg: string,
    status: number
  ): StepPreviewListActionTypes => {
    return {
      type: STEP_PREVIEW_LIST_FAIL,
      payload: stepPreviewListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const stepPreviewListAPIMsgAction = (
    stepPreviewListResponse: StepPreviewResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): StepPreviewListActionTypes => {
    return {
      type: STEP_PREVIEW_LIST_API_MSG,
      payload: stepPreviewListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const stepPreviewListAPIResClearAction = (
    stepPreviewListResponse: StepPreviewResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): StepPreviewListActionTypes => {
    return {
      type: STEP_PREVIEW_LIST_API_RES,
      payload: stepPreviewListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };