import { SetTeleHealthCredentialRes } from "./Model";
export const SET_TELE_HEALTH_CREDENTIALS_LOADING =
  "SET_TELE_HEALTH_CREDENTIALS_LOADING";
export const SET_TELE_HEALTH_CREDENTIALS_SUCCESS =
  "SET_TELE_HEALTH_CREDENTIALS_SUCCESS";
export const SET_TELE_HEALTH_CREDENTIALS_FAIL =
  "SET_TELE_HEALTH_CREDENTIALS_FAIL";
export const SET_TELE_HEALTH_CREDENTIALS_API_MSG =
  "SET_TELE_HEALTH_CREDENTIALS_API_MSG";
export const SET_TELE_HEALTH_CREDENTIALS_API_RES =
  "SET_TELE_HEALTH_CREDENTIALS_API_RES";

export interface DrAppointmentDecisionState {
  setDrTeleHealthCredentialsRes: SetTeleHealthCredentialRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DrAppointmentDecisionLoading {
  type: typeof SET_TELE_HEALTH_CREDENTIALS_LOADING;
  loading: boolean;
}
export interface DrAppointmentDecisionSuccess {
  type: typeof SET_TELE_HEALTH_CREDENTIALS_SUCCESS;
  payload: SetTeleHealthCredentialRes;
  successMsg: string;
}
export interface DrAppointmentDecisionFail {
  type: typeof SET_TELE_HEALTH_CREDENTIALS_FAIL;
  payload: SetTeleHealthCredentialRes;
  errorMsg: string;
  status: number;
}
export interface DrAppointmentDecisionAPIMsg {
  type: typeof SET_TELE_HEALTH_CREDENTIALS_API_MSG;
  payload: SetTeleHealthCredentialRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DrAppointmentDecisionAPIRes {
  type: typeof SET_TELE_HEALTH_CREDENTIALS_API_RES;
  payload: SetTeleHealthCredentialRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DrAppointmentDecisionActionTypes =
  | DrAppointmentDecisionLoading
  | DrAppointmentDecisionSuccess
  | DrAppointmentDecisionFail
  | DrAppointmentDecisionAPIMsg
  | DrAppointmentDecisionAPIRes;
