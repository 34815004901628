import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorMembershipListActionTypes } from "./ActionTypes";
import { GetProfessionalMembershipRes } from "./Model";
import {
  getDoctorMembershipListAPIResClearAction,
  getDoctorMembershipListErrorAction,
  getDoctorMembershipListLoadingAction,
  getDoctorMembershipListSuccessAction,
  getDoctorMembershipListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetProfessionalMembershipRes;
export const getMembershipList = () => {
  return function (dispatch: Dispatch<GetDoctorMembershipListActionTypes>) {
    dispatch(getDoctorMembershipListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL +
          `/doctorapp/api/doctormembership/get_member_details_by_doctor`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorMembershipListLoadingAction(false));
        dispatch(
          getDoctorMembershipListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. Work membership has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorMembershipListLoadingAction(false));
        dispatch(
          getDoctorMembershipListErrorAction(
            {} as GetProfessionalMembershipRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetDoctorMembershipListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorMembershipListActionTypes>) {
    dispatch(
      getDoctorMembershipListAPIMsgAction(apiRes as GetProfessionalMembershipRes, "", "", 0)
    );
  };
};

export const clearGetDoctorProfessionalListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorMembershipListActionTypes>) {
    dispatch(
      getDoctorMembershipListAPIResClearAction({} as GetProfessionalMembershipRes, "", "", 0)
    );
  };
};
