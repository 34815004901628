import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "components/PageLayout";
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import MWPageTitle from "components/MWPageTitle";
import { AppState } from "redux/store/Store";
import { sectionTemplateListApi } from "redux/PreviewSectionByStepId/SectionTemplateList/API";
import PreviewContent from "./PreviewContent";
import { t } from "i18next";
import { SecondOpinionUiTemplate } from "redux/PreviewSectionByStepId/SectionTemplateList/Model";

export default function Preview() {
  const dispatch = useDispatch();
  const sectionTemplateListValue = useSelector((state: AppState) => state.previewSectionByStepId);

  const [templateList, setTemplateList] = useState([] as SecondOpinionUiTemplate[]);
  useEffect(() => {
    if (
      sectionTemplateListValue?.sectionTemplateListResponse?.SecondOpinionUiTemplate !== undefined
    ) {
      if (
        sectionTemplateListValue?.sectionTemplateListResponse?.SecondOpinionUiTemplate.length > 0
      ) {
        setTemplateList(
          sectionTemplateListValue?.sectionTemplateListResponse?.SecondOpinionUiTemplate,
        );
      } else setTemplateList([] as SecondOpinionUiTemplate[]);
    } else setTemplateList([] as SecondOpinionUiTemplate[]);
  }, [sectionTemplateListValue]);

  const [selectedTemplate, setSelectedTemplate] = useState<SecondOpinionUiTemplate | null>(null);
  const selectedTemplateChange = (_event: any, passedVal: SecondOpinionUiTemplate | any) => {
    setSelectedTemplate(passedVal);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(sectionTemplateListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const viewPreviewModalMarkup = (
    <Card>
      <CardHeader
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Autocomplete
                value={selectedTemplate}
                id="highest-states"
                options={templateList}
                onChange={(event: any, newValue: any) => {
                  selectedTemplateChange(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t("previewPage.selTemp", { ns: ["home"] })!}
                    fullWidth
                  />
                )}
                size="small"
                getOptionLabel={(option: any) => option?.label! || ""}
                isOptionEqualToValue={(option: any, value) => option.value === value.value}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <PreviewContent templateId={selectedTemplate} />
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="xl">
      <PageLayout>
        <MWPageTitle
          backButton={true}
          title={t("previewPage.prevSec", { ns: ["home"] })}
          subtitle={""}
        />
        {viewPreviewModalMarkup}
      </PageLayout>
    </Container>
  );
}
