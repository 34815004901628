import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  AlertColor,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import MWExceptionList from "components/MWExceptionList";
import { t } from "i18next";
import {
  CreateScheduleMeetingApi,
  clearScheduleMeetingAPIRes,
} from "redux/CreateMeeting/createScheduleMeeting/API";
import {
  CreateInstantMeetingApi,
  clearCreateMeetingAPIRes,
} from "redux/CreateMeeting/createInstantmeeting/API";
import { useDispatch, useSelector } from "react-redux";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { meetingListApi } from "redux/PatientDoctorInteraction/MeetingList/API";
import { MeetingListBody } from "redux/PatientDoctorInteraction/MeetingList/Model";
import { AppState } from "redux/store/Store";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import ComputerIcon from "@mui/icons-material/Computer";
import { ModifiedMeetingList } from "./Model";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import { CreateScheduleMeetingBody } from "redux/CreateMeeting/createScheduleMeeting/Model";
import { CreateInstantMeetingBody } from "redux/CreateMeeting/createInstantmeeting/Model";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function ExistingMeetingList() {
  const dispatch = useDispatch();
  const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);
  const [isCreateMeeting, setIsCreateMeeting] = useState(false);
  const [meetingList, setMeetingList] = useState([] as any[]);

  const [apiMsgType, setAPIMsgType] = useState<AlertColor>("success");
  const [scheduleMeetingErrMsg, setScheduleMeetingErrMsg] = useState("");

  const [crateMeetingErrMsg, setCrateMeetingErrMsg] = useState("");

  const createScheduleMeeting = useSelector(
    (state: AppState) => state.createScheduleMeeting
  );
  const createInstantMeeting = useSelector(
    (state: AppState) => state.createInstantMeeting
  );

  const meetingListApiValue = useSelector(
    (state: AppState) => state.meetingListValue
  );
  const meetingListTableColumns: GridColDef[] = [
    {
      field: "startMeetingUrl",
      headerName: `Start Meeting`,
      width: 200,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<MeetingRoomIcon />}
          variant="outlined"
          onClick={() => {}}
        >
          Start Meeting
        </Button>
      ),
    },
    {
      field: "id",
      headerName: `Meeting Id`,
      width: 100,
    },
    {
      field: "displayName",
      headerName: `${t("appointmentpage.appoinmentname", { ns: ["home"] })!}`,
      width: 200,
    },
    {
      field: "appointmentDate",
      headerName: `Appointment Date & Time`,
      width: 200,
    },
    {
      field: "dateCreated",
      headerName: `${t("appointmentpage.createdate", { ns: ["home"] })}`,
      width: 200,
    },
    {
      field: "joinMeetingUrl",
      headerName: `Join Meeting`,
      width: 220,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ComputerIcon />}
          variant="outlined"
          onClick={() => {}}
        >
          Join Meeting
        </Button>
      ),
    },
    {
      field: "isActive",
      headerName: `Deactivate Meeting`,
      width: 300,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<NoMeetingRoomIcon />}
          variant="outlined"
          onClick={() => {}}
        >
          Deactivate Meeting
        </Button>
      ),
    },
  ];

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  let newDate = dayjs(`${selectedDate}`);

  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;

  const { caseid } = useParams() as {
    caseid: string;
  };

  // Schedule Meeting Body
  const scheduleMeetingBody = {
    doctorId: String(DoctorID)!,
    caseId: String(caseid),
    starttime: String(newDate),
  } as CreateScheduleMeetingBody;

  const meetingListBody = {
    caseId: caseid,
  } as MeetingListBody;

  // Schedule Meeting API
  const scheduleMeeting = () => {
    setCrateMeetingErrMsg("");
    setScheduleMeetingErrMsg("");
    setIsScheduleMeeting(true);
    dispatch(CreateScheduleMeetingApi(scheduleMeetingBody));
  };

  const closeScheduleMeetingMsg = () => {
    setScheduleMeetingErrMsg("");
    dispatch(clearScheduleMeetingAPIRes());
  };

  const closeCrateMeetingMsg = () => {
    setCrateMeetingErrMsg("");
    dispatch(clearCreateMeetingAPIRes());
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(createScheduleMeeting?.loading));
    if (createScheduleMeeting?.successMsg !== undefined) {
      if (createScheduleMeeting?.successMsg !== "") {
        setAPIMsgType("success");
        setScheduleMeetingErrMsg(createScheduleMeeting?.successMsg);
        dispatch(meetingListApi(meetingListBody));
      }
    }
    if (createScheduleMeeting?.errorMsg !== "") {
      setAPIMsgType("error");
      setScheduleMeetingErrMsg(createScheduleMeeting?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createScheduleMeeting]);

  // Create Meeting Body
  const createMeetingBody = {
    doctorId: String(DoctorID)!,
    caseId: String(caseid),
    starttime: String(newDate),
  } as CreateInstantMeetingBody;

  // Create Meeting API
  const createMeeting = () => {
    setCrateMeetingErrMsg("");
    setScheduleMeetingErrMsg("");
    setIsCreateMeeting(true);
    dispatch(CreateInstantMeetingApi(createMeetingBody));
    dispatch(meetingListApi(meetingListBody));
  };

  useEffect(() => {
    dispatch(toggleAppBarLoading(createInstantMeeting?.loading));
    if (createInstantMeeting?.successMsg !== undefined) {
      if (createInstantMeeting?.successMsg !== "") {
        setAPIMsgType("success");
        setCrateMeetingErrMsg(createInstantMeeting?.successMsg);
        dispatch(meetingListApi(meetingListBody));
      }
    }
    if (createInstantMeeting?.errorMsg !== "") {
      setAPIMsgType("error");
      setCrateMeetingErrMsg(createInstantMeeting?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInstantMeeting]);

  useEffect(() => {
    dispatch(meetingListApi(meetingListBody));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(toggleAppBarLoading(meetingListApiValue?.loading));
    if (meetingListApiValue?.meetingListResponse?.meetingList !== undefined) {
      let allMeetingListTableValues =
        meetingListApiValue?.meetingListResponse?.meetingList.map(
          (element: ModifiedMeetingList) => ({
            startMeetingUrl:
              element.startMeetingUrl !== "" ? element.startMeetingUrl : "",
            id: element.id,
            displayName: element.displayName !== "" ? element.displayName : "",
            appointmentDate:
              element.appointmentDate !== ""
                ? dayjs(element.appointmentDate).format("MM-DD-YYYY")
                : "",
            dateCreated:
              element.dateCreated !== ""
                ? dayjs(element.dateCreated).format("MM-DD-YYYY")
                : "",
            joinMeetingUrl:
              element.joinMeetingUrl !== "" ? element.joinMeetingUrl : "",
            isActive: element.isActive === true ? element.isActive : false,
            teleHealthAppointmentId: "",
            teleHealthAppointmentPwd: "",
            teleHealthApp: 0,
          })
        );
      setMeetingList(allMeetingListTableValues);
    } else setMeetingList([] as ModifiedMeetingList[]);
    // eslint-disable-next-line
  }, [meetingListApiValue]);

  const appointmentTableBody = (
    <div>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                {t("appointmentpage.existmeetdetail", { ns: ["home"] })!}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDateTimePicker
                        inputFormat="DD/MM/YYYY HH:mm"
                        disablePast
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date)}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                            size="small"
                            variant="outlined"
                            sx={{
                              svg: { color: "#DE3618" },
                              input: { color: "#DE3618" },
                              label: { color: "#DE3618" },
                            }}
                          />
                        )}
                        InputAdornmentProps={{ position: "end" }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={isScheduleMeeting}
                      onClick={scheduleMeeting}
                    >
                      {t("appointmentpage.schmeet", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      disabled={isCreateMeeting}
                      onClick={createMeeting}
                    >
                      {t("appointmentpage.createmeet", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => dispatch(meetingListApi(meetingListBody))}
                    >
                      {t("telehealthpage.reloadmeetlist", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            {scheduleMeetingErrMsg !== "" ? (
              <Alert
                severity={apiMsgType}
                variant="outlined"
                onClose={closeScheduleMeetingMsg}
              >
                {scheduleMeetingErrMsg}
              </Alert>
            ) : null}
            {crateMeetingErrMsg !== "" ? (
              <Alert
                severity={apiMsgType}
                variant="outlined"
                onClose={closeCrateMeetingMsg}
              >
                {crateMeetingErrMsg}
              </Alert>
            ) : null}
            {meetingListApiValue?.meetingListResponse?.meetingList !==
              undefined &&
            meetingListApiValue?.meetingListResponse?.meetingList.length > 0 ? (
              <DataGrid
                rows={meetingList}
                columns={meetingListTableColumns}
                hideFooter
                hideFooterPagination
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                getRowId={(row) => row?.id}
              />
            ) : (
              <MWExceptionList />
            )}
          </Stack>
        </CardContent>
      </Card>
      <br />
    </div>
  );

  return <>{appointmentTableBody}</>;
}
