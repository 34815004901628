import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader,
  Grid,
} from "@mui/material";
import { getWorkExperienceList } from "redux/UserProfile/ProfessionalDetails/WorkExperience/GetWorkExperienceList/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { getSpecialtyList } from "redux/UserProfile/ProfessionalDetails/ProfessionalSummary/GetSpecialtyList/API";
import { t } from "i18next";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import UpdateWorkExperience from "./UpdateWorkExperience";
import { ModifiedWorkExperienceList } from "./Model";
import DocumentViewer from "components/DocumentViewer";
import MWExceptionList from "components/MWExceptionList";
export interface Props {
  professionalWorkExperienceLoading: boolean | any;
}
export default function DoctorWorkExperience({
  professionalWorkExperienceLoading,
}: Props) {
  const [editItem, setEditItem] = useState({} as ModifiedWorkExperienceList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const workExpTableColumns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })!}`,
      width: 110,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<ArticleIcon />}
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setViewEducationDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "name",
      headerName: `${t("userprofilepage.instname", { ns: ["home"] })!}`,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "specialist",
      headerName: `${t("userprofilepage.specialist", { ns: ["home"] })!}`,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: `${t("userprofilepage.startdate", { ns: ["home"] })!}`,
      width: 100,
    },
    {
      field: "endDate",
      headerName: `${t("appointmentpage.enddate", { ns: ["home"] })!}`,
      width: 100,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })!}`,
      minWidth: 130,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setAddEducationDetailsDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("userprofilepage.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  // Get professional list
  const [workExperienceList, setWorkExperienceList] = useState<any[]>([]);

  const [, setsubmitDegreeListValues] = useState<any[]>([]);
  const getSpecialityListValueRes = useSelector(
    (state: AppState) => state.specialtyListRes
  );

  useEffect(() => {
    professionalWorkExperienceLoading(getSpecialityListValueRes?.loading);
    if (
      getSpecialityListValueRes?.specialtyListRes?.doctorSpecialtyList !==
      undefined
    ) {
      setsubmitDegreeListValues(
        getSpecialityListValueRes?.specialtyListRes?.doctorSpecialtyList
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSpecialityListValueRes]);

  // Call work experience list store
  const getWorkExperienceValueRes = useSelector(
    (state: AppState) => state.getWorkExperienceListRes
  );

  useEffect(() => {
    professionalWorkExperienceLoading(getWorkExperienceValueRes?.loading);
    if (
      getWorkExperienceValueRes?.getDoctorWorkExperienceListRes
        ?.workExperienceList !== undefined
    ) {
      let response =
        getWorkExperienceValueRes?.getDoctorWorkExperienceListRes?.workExperienceList.map(
          (item) => ({
            id: item?.id,
            supportingDocument:
              item?.supportingDocuments[0]?.userFile?.document,
            name: item?.name,
            specialist: item?.doctorSpecialty?.displayValue,
            specialistVal: item?.doctorSpecialty?.id,
            startDate:
              item?.fromDate !== null
                ? dayjs(item?.fromDate).format("DD-MM-YYYY")
                : "Not Set",
            endDate:
              item?.toDate !== null
                ? dayjs(item?.toDate).format("DD-MM-YYYY")
                : "Not Set",
            category: item?.isAIIMS ? "AIIMS" : "NON AIIMS",
          })
        );

      setWorkExperienceList(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWorkExperienceValueRes]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSpecialtyList());
    dispatch(getWorkExperienceList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addEducationDetailsDialog, setAddEducationDetailsDialog] =
    useState(false);
  const updateAddEducationDetailsDialogValFromChild = (passedVal: boolean) => {
    setAddEducationDetailsDialog(passedVal);
  };

  const [viewEducationDocumentDialog, setViewEducationDocumentDialog] =
    useState(false);
  const updateViewEducationDocumentDialogValFromChild = (
    passedVal: boolean
  ) => {
    setViewEducationDocumentDialog(passedVal);
  };

  const actualPageMarkup = (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("userprofilepage.workexp", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setAddEducationDetailsDialog(true)}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                >
                  {t("userprofilepage.addworkexp", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {workExperienceList.length > 0 ? (
            <DataGrid
              rows={workExperienceList}
              columns={workExpTableColumns}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );
  return (
    <Box>
      {actualPageMarkup}
      {addEducationDetailsDialog ? (
        <UpdateWorkExperience
          dialogEnable={addEducationDetailsDialog}
          dialogUpdateState={updateAddEducationDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewEducationDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewEducationDocumentDialog}
          dialogUpdateState={updateViewEducationDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
