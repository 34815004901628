import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetSpecialtyListActionTypes } from "./ActionTypes";
import { GetSpecialtyListRes } from "./Model";
import {
  getGetSpecialtyListAPIResClearAction,
  getGetSpecialtyListErrorAction,
  getGetSpecialtyListLoadingAction,
  getGetSpecialtyListSuccessAction,
  getGetSpecialtyListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetSpecialtyListRes;
export const getSpecialtyList = () => {
  return function (dispatch: Dispatch<GetSpecialtyListActionTypes>) {
    dispatch(getGetSpecialtyListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/speciality_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getGetSpecialtyListLoadingAction(false));
        dispatch(
          getGetSpecialtyListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Specialty list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getGetSpecialtyListLoadingAction(false));
        dispatch(
          getGetSpecialtyListErrorAction(
            {} as GetSpecialtyListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getSpecialtyListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetSpecialtyListActionTypes>) {
    dispatch(
      getGetSpecialtyListAPIMsgAction(apiRes as GetSpecialtyListRes, "", 0)
    );
  };
};

export const clearSpecialtyListAPIRes = () => {
  return function (dispatch: Dispatch<GetSpecialtyListActionTypes>) {
    dispatch(
      getGetSpecialtyListAPIResClearAction({} as GetSpecialtyListRes, "", 0)
    );
  };
};
