import {
  ADD_DR_LEGAL_DETAILS_FAIL,
  ADD_DR_LEGAL_DETAILS_LOADING,
  ADD_DR_LEGAL_DETAILS_SUCCESS,
  ADD_DR_LEGAL_DETAILS_API_MSG,
  ADD_DR_LEGAL_DETAILS_API_RES,
  AddDrLegalDetailsActionTypes,
} from "./ActionTypes";
import { AddLegalDetailsRes } from "./Model";

export const addDrLegalDetailsLoadingAction = (
  loading: boolean
): AddDrLegalDetailsActionTypes => {
  return {
    type: ADD_DR_LEGAL_DETAILS_LOADING,
    loading: loading,
  };
};

export const addDrLegalDetailsSuccessAction = (
  addDrLegalDetailsResponse: AddLegalDetailsRes,
  successMsg: string
): AddDrLegalDetailsActionTypes => {
  return {
    type: ADD_DR_LEGAL_DETAILS_SUCCESS,
    payload: addDrLegalDetailsResponse,
    successMsg: successMsg,
  };
};

export const addDrLegalDetailsErrorAction = (
  addDrLegalDetailsResponse: AddLegalDetailsRes,
  errMsg: string,
  status: number
): AddDrLegalDetailsActionTypes => {
  return {
    type: ADD_DR_LEGAL_DETAILS_FAIL,
    payload: addDrLegalDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDrLegalDetailsAPIMsgAction = (
  addDrLegalDetailsResponse: AddLegalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrLegalDetailsActionTypes => {
  return {
    type: ADD_DR_LEGAL_DETAILS_API_MSG,
    payload: addDrLegalDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDrLegalDetailsAPIResClearAction = (
  addDrLegalDetailsResponse: AddLegalDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrLegalDetailsActionTypes => {
  return {
    type: ADD_DR_LEGAL_DETAILS_API_RES,
    payload: addDrLegalDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
