import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { AddDrAwardActionTypes } from "./ActionTypes";
import { AddAwardBody, AddAwardRes } from "./Model";
import {
  addDrAwardAPIResClearAction,
  addDrAwardErrorAction,
  addDrAwardLoadingAction,
  addDrAwardSuccessAction,
  addDrAwardAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddAwardRes;
export const addDrAward = (payload: AddAwardBody) => {
  return function (dispatch: Dispatch<AddDrAwardActionTypes>) {
    dispatch(addDrAwardLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/api/onboarding/add_awards`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(addDrAwardLoadingAction(false));
        dispatch(
          addDrAwardSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. award has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDrAwardLoadingAction(false));
        dispatch(
          addDrAwardErrorAction(
            {} as AddAwardRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDrAwardAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDrAwardActionTypes>) {
    dispatch(addDrAwardAPIMsgAction(apiRes as AddAwardRes, "", "", 0));
  };
};

export const clearAddDrAwardAPIRes = () => {
  return function (dispatch: Dispatch<AddDrAwardActionTypes>) {
    dispatch(addDrAwardAPIResClearAction({} as AddAwardRes, "", "", 0));
  };
};
