import { GetDemographicsDetailsByPtIdRes } from "./Model";

export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING";
export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS";
export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL";
export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_DATA =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_DATA";

export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_MSG =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_MSG";
export const GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_RES =
  "GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_RES";

export interface DemographicsDetailsByPtIdDetails {
  getDemographicsDetailsByPtIdRes: GetDemographicsDetailsByPtIdRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDemographicsDetailsByPtIdActionLoading {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING;
  loading: boolean;
}
export interface GetDemographicsDetailsByPtIdActionSuccess {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS;
  payload: GetDemographicsDetailsByPtIdRes;
  successMsg: string;
}
export interface GetDemographicsDetailsByPtIdActionFail {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL;
  payload: GetDemographicsDetailsByPtIdRes;
  errorMsg: string;
  status: number;
}

export interface UpdatePatientDemographicsDetailsUpdateAPIMsg {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_MSG;
  payload: GetDemographicsDetailsByPtIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdatePatientDemographicsDetailsUpdateAPIRes {
  type: typeof GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_RES;
  payload: GetDemographicsDetailsByPtIdRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDemographicsDetailsByPtIdActionTypes =
  | GetDemographicsDetailsByPtIdActionLoading
  | GetDemographicsDetailsByPtIdActionSuccess
  | GetDemographicsDetailsByPtIdActionFail
  | UpdatePatientDemographicsDetailsUpdateAPIMsg
  | UpdatePatientDemographicsDetailsUpdateAPIRes;
