import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { AppState } from "redux/store/Store";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import MWLoader from "components/MWLoader";
import {
  clearDeletePatientDataByIdAPIRes,
  deletePatientDataById,
} from "redux/FilePreparation/DeletePatientDataById/API";
import { DeletePatientDataByIdBody } from "redux/FilePreparation/DeletePatientDataById/Model";
import { getFinalOpinionDetails } from "../../../redux/FinalOpinion/FinalOpinionTable/GetFinalOpinionList/API";
import { GetFinalOpinionListBody } from "../../../redux/FinalOpinion/FinalOpinionTable/GetFinalOpinionList/Model";

type Props = {
  itemId?: string | any;
  patientDetailsID?: string | any;
  deleteDialogEnable?: boolean | any;
  deleteDialogUpdateState?: boolean | any;
};

export default function DeleteFinalOpinion({
  itemId,
  deleteDialogEnable,
  deleteDialogUpdateState,
  patientDetailsID,
}: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [dialogState, setDialogState] = useState(false);
  useEffect(() => {
    setDialogState(deleteDialogEnable);
  }, [deleteDialogEnable, dialogState]);

  const handleDialogClose = () => {
    setDialogState(false);
    deleteDialogUpdateState(false);
    dispatch(clearDeletePatientDataByIdAPIRes());
  };

  const getDoctorFinalOpinionDetailsByCaseId = {
    caseId: caseid,
    secondOpinionUiSection: "finalOpinion",
  } as GetFinalOpinionListBody;

  const deletePatientDataByIdRes = useSelector(
    (state: AppState) => state.deletePatientDataByIdRes
  );

  const [deletePatientDataMsgType, setDeletePatientDataMsgType] =
    useState<AlertColor>("success");
  const [deletePatientDataErrorMsg, setDeletePatientDataErrorMsg] =
    useState("");
  useEffect(() => {
    if (deletePatientDataByIdRes?.successMsg !== "") {
      dispatch(getFinalOpinionDetails(getDoctorFinalOpinionDetailsByCaseId));
      handleDialogClose();
    } else if (deletePatientDataByIdRes?.errorMsg !== "") {
      setDeletePatientDataErrorMsg(deletePatientDataByIdRes?.errorMsg);
      setDeletePatientDataMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePatientDataByIdRes]);

  const deletePrescriptionBody = {
    caseId: String(itemId),
    patientDetailId: Number(patientDetailsID),
  } as DeletePatientDataByIdBody;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={dialogState}
      disableEscapeKeyDown
      PaperProps={{
        style: {
          minHeight: "20vh",
          minWidth: "40vw",
          overflow: "hidden",
        },
      }}
    >
      {deletePatientDataByIdRes?.loading ? <MWLoader /> : null}
      <DialogTitle id="customized-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{t("filePrepPage.deletePres", { ns: ["home"] })}</Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleDialogClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          {t("filePrepPage.deletePrescription", { ns: ["home"] })}
        </Typography>
      </DialogContent>
      {deletePatientDataErrorMsg !== "" ? (
        <Box my={2}>
          <Alert severity={deletePatientDataMsgType} onClose={() => {}}>
            {deletePatientDataErrorMsg}
          </Alert>
        </Box>
      ) : null}
      <DialogActions>
        <Button
          autoFocus
          variant="outlined"
          color="primary"
          onClick={handleDialogClose}
        >
          {t("filePrepPage.no", { ns: ["home"] })}
        </Button>
        <Button
          autoFocus
          variant="contained"
          color="primary"
          disabled={deletePatientDataByIdRes?.loading}
          onClick={() =>
            dispatch(deletePatientDataById(deletePrescriptionBody))
          }
        >
          {t("filePrepPage.yes", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
