import {
  GET_DOCTOR_INSURANCE_LIST_FAIL,
  GET_DOCTOR_INSURANCE_LIST_LOADING,
  GET_DOCTOR_INSURANCE_LIST_SUCCESS,
  GET_DOCTOR_INSURANCE_LIST_API_MSG,
  GET_DOCTOR_INSURANCE_LIST_API_RES,
  GetDoctorInsuranceListActionTypes,
} from "./ActionTypes";
import { GetInsuranceListRes } from "./Model";

export const getDoctorInsuranceListLoadingAction = (
  loading: boolean
): GetDoctorInsuranceListActionTypes => {
  return {
    type: GET_DOCTOR_INSURANCE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorInsuranceListSuccessAction = (
  getDoctorInsuranceListResponse: GetInsuranceListRes,
  successMsg: string
): GetDoctorInsuranceListActionTypes => {
  return {
    type: GET_DOCTOR_INSURANCE_LIST_SUCCESS,
    payload: getDoctorInsuranceListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorInsuranceListErrorAction = (
  getDoctorInsuranceListResponse: GetInsuranceListRes,
  errMsg: string,
  status: number
): GetDoctorInsuranceListActionTypes => {
  return {
    type: GET_DOCTOR_INSURANCE_LIST_FAIL,
    payload: getDoctorInsuranceListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorInsuranceListAPIMsgAction = (
  getDoctorInsuranceListResponse: GetInsuranceListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorInsuranceListActionTypes => {
  return {
    type: GET_DOCTOR_INSURANCE_LIST_API_MSG,
    payload: getDoctorInsuranceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorInsuranceListAPIResClearAction = (
  getDoctorInsuranceListResponse: GetInsuranceListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorInsuranceListActionTypes => {
  return {
    type: GET_DOCTOR_INSURANCE_LIST_API_RES,
    payload: getDoctorInsuranceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
