import { QuestionListResponse } from "./Model";
export const QUESTION_LIST_LOADING = "QUESTION_LIST_LOADING";
export const QUESTION_LIST_SUCCESS = "QUESTION_LIST_SUCCESS";
export const QUESTION_LIST_FAIL = "QUESTION_LIST_FAIL";
export const QUESTION_LIST_API_MSG = "QUESTION_LIST_API_MSG";
export const QUESTION_LIST_API_RES = "QUESTION_LIST_API_RES";

export interface QuestionListState {
  questionListResponse: QuestionListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrLegalDetailsLoading {
  type: typeof QUESTION_LIST_LOADING;
  loading: boolean;
}
export interface AddDrLegalDetailsSuccess {
  type: typeof QUESTION_LIST_SUCCESS;
  payload: QuestionListResponse;
  successMsg: string;
}
export interface AddDrLegalDetailsFail {
  type: typeof QUESTION_LIST_FAIL;
  payload: QuestionListResponse;
  errorMsg: string;
  status: number;
}
export interface AddDrLegalDetailsAPIMsg {
  type: typeof QUESTION_LIST_API_MSG;
  payload: QuestionListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrLegalDetailsAPIRes {
  type: typeof QUESTION_LIST_API_RES;
  payload: QuestionListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrLegalDetailsActionTypes =
  | AddDrLegalDetailsLoading
  | AddDrLegalDetailsSuccess
  | AddDrLegalDetailsFail
  | AddDrLegalDetailsAPIMsg
  | AddDrLegalDetailsAPIRes;
