import { GetRelationshipListRes } from "./Model";

export const GET_RELATIONSHIP_LIST_LOADING =
  "GET_RELATIONSHIP_LIST_LOADING";
export const GET_RELATIONSHIP_LIST_SUCCESS =
  "GET_RELATIONSHIP_LIST_SUCCESS";
export const GET_RELATIONSHIP_LIST_FAIL =
  "GET_RELATIONSHIP_LIST_FAIL";
export const GET_RELATIONSHIP_LIST_DATA =
  "GET_RELATIONSHIP_LIST_DATA";

export interface RelationshipListDetails {
  getRelationshipListRes: GetRelationshipListRes;
  loading: boolean;
  errRes: string;
}
export interface GetRelationshipListActionLoading {
  type: typeof GET_RELATIONSHIP_LIST_LOADING;
}
export interface GetRelationshipListActionSuccess {
  type: typeof GET_RELATIONSHIP_LIST_SUCCESS;
  payload: GetRelationshipListRes;
  errRes: string;
}
export interface GetRelationshipListActionFail {
  type: typeof GET_RELATIONSHIP_LIST_FAIL;
  payload: GetRelationshipListRes;
  errRes: string;
}
interface GetRelationshipListActionAction {
  type: typeof GET_RELATIONSHIP_LIST_DATA;
  payload: GetRelationshipListRes;
  errRes: string;
}

export type GetRelationshipListActionTypes =
  | GetRelationshipListActionAction
  | GetRelationshipListActionLoading
  | GetRelationshipListActionSuccess
  | GetRelationshipListActionFail;
