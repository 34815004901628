import {
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_UPDATE_API_MSG,
  RESET_PASSWORD_UPDATE_API_RES,
  JwtTokenActionTypes,
} from "./ActionTypes";
import { ResetPasswordRes } from "./Model";

export const resetPasswordLoadingAction = (
  loading: boolean
): JwtTokenActionTypes => {
  return {
    type: RESET_PASSWORD_LOADING,
    loading: loading,
  };
};

export const resetPasswordSuccessAction = (
  resetPasswordResponse: ResetPasswordRes,
  successMsg: string
): JwtTokenActionTypes => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: resetPasswordResponse,
    successMsg: successMsg,
  };
};

export const resetPasswordErrorAction = (
  resetPasswordResponse: ResetPasswordRes,
  errMsg: string,
  status: number
): JwtTokenActionTypes => {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: resetPasswordResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const resetPasswordUpdateAPIMsgAction = (
  resetPasswordResponse: ResetPasswordRes,
  successMsg: string,
  errMsg: string,
  status: number
): JwtTokenActionTypes => {
  return {
    type: RESET_PASSWORD_UPDATE_API_MSG,
    payload: resetPasswordResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const resetPasswordAPIResClearAction = (
  resetPasswordResponse: ResetPasswordRes,
  successMsg: string,
  errMsg: string,
  status: number
): JwtTokenActionTypes => {
  return {
    type: RESET_PASSWORD_UPDATE_API_RES,
    payload: resetPasswordResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
