import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { getCoverLetter } from "redux/UserProfile/CoverLetter/GetCoverLetter/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { EditCoverLetterBody } from "redux/UserProfile/CoverLetter/EditCoverLetter/Model";
import {
  updateDoctorCoverLetter,
  updateUpdateDoctorCoverLetterAPIResMsg,
} from "redux/UserProfile/CoverLetter/EditCoverLetter/API";
export interface Props {
  coverLetterLoading: boolean | any;
}
export default function DoctorCoverLetter({ coverLetterLoading }: Props) {
  const dispatch = useDispatch();
  const [coverLetter, setCoverLetter] = useState("");
  const coverLetterMaxLength = 1000;
  const [isEdit, setIsEdit] = useState(true);
  const [isEnableSubmit, setEnableSubmit] = useState(true);

  const updateDoctorCoverLetterBody = {
    coverLetter: coverLetter,
  } as EditCoverLetterBody;

  const updateDoctorCoverLetterRes = useSelector(
    (state: AppState) => state.updateDoctorCoverLetterRes
  );

  const [updateDoctorCoverLetterMsg, setUpdateDoctorCoverLetterMsg] =
    useState("");
  const [updateDoctorCoverLetterMsgType, setUpdateDoctorCoverLetterMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    coverLetterLoading(updateDoctorCoverLetterRes?.loading);
    if (updateDoctorCoverLetterRes?.successMsg !== "") {
      setUpdateDoctorCoverLetterMsgType("success");
      setUpdateDoctorCoverLetterMsg(updateDoctorCoverLetterRes?.successMsg);
      dispatch(getCoverLetter());
    }
    if (updateDoctorCoverLetterRes?.errorMsg !== "") {
      setUpdateDoctorCoverLetterMsgType("error");
      setUpdateDoctorCoverLetterMsg(updateDoctorCoverLetterRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDoctorCoverLetterRes]);

  useEffect(() => {
    dispatch(getCoverLetter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call get cover letter response from server
  const getCoverListRes = useSelector(
    (state: AppState) => state.getCoverLetterRes
  );
  useEffect(() => {
    coverLetterLoading(getCoverListRes?.loading);
    if (getCoverListRes?.getDoctorCoverLetterRes?.coverLetter !== undefined) {
      if (getCoverListRes?.getDoctorCoverLetterRes?.coverLetter !== null) {
        setCoverLetter(getCoverListRes?.getDoctorCoverLetterRes?.coverLetter);
      } else setCoverLetter("");
    } else setCoverLetter("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCoverListRes]);

  return (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("userprofilepage.coverdesc", { ns: ["home"] })!}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit
                  ? `${t("userprofilepage.edit", { ns: ["home"] })}`
                  : `${t("accountdetail.cancel", { ns: ["home"] })}`}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              {t("userprofilepage.entertext", { ns: ["home"] })}
            </Typography>
            <TextField
              label=""
              placeholder={t("userprofilepage.textletter", { ns: ["home"] })!}
              value={coverLetter}
              onChange={(e: any) => {
                if (coverLetter.length <= coverLetterMaxLength) {
                  setCoverLetter(e.target.value);
                }
              }}
              disabled={isEnableSubmit || updateDoctorCoverLetterRes?.loading}
              fullWidth
              multiline
              minRows={4}
              maxRows={10}
              inputProps={{ maxLength: 1000, minLength: 0 }}
            />
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Box>
                {coverLetter.length}/{coverLetterMaxLength}
              </Box>
            </Grid>
          </Grid>
          {updateDoctorCoverLetterMsg !== "" ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box>
                <Alert
                  severity={updateDoctorCoverLetterMsgType}
                  onClose={() => {
                    dispatch(updateUpdateDoctorCoverLetterAPIResMsg());
                    setUpdateDoctorCoverLetterMsg("");
                  }}
                >
                  {updateDoctorCoverLetterMsg}
                </Alert>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() =>
                dispatch(updateDoctorCoverLetter(updateDoctorCoverLetterBody))
              }
              disabled={isEnableSubmit || updateDoctorCoverLetterRes?.loading}
              sx={{ textTransform: "none" }}
            >
              {t("userprofilepage.subdetail", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
