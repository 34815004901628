import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../../URL";
import { DeleteSummaryListActionTypes } from "./ActionTypes";
import {  DeleteSummaryListSubmitBody,  DeleteSummaryListRes } from "./Model";
import {
 deleteSummaryListAPIResClearAction,
 deleteSummaryListErrorAction,
 deleteSummaryListLoadingAction,
 deleteSummaryListSuccessAction,
 deleteSummaryListUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../../../authentication/Interceptors";
import { headers } from "../../../../components/Utility"


let apiRes = {} as  DeleteSummaryListRes;
export const deleteSummaryList = (payload:  DeleteSummaryListSubmitBody) => {
  return function (dispatch: Dispatch<DeleteSummaryListActionTypes>) {
    dispatch( deleteSummaryListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL +  `/patientapp/api/patientMedicalData/delete_patientSectionSummary`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch( deleteSummaryListLoadingAction(false));
        dispatch(
         deleteSummaryListSuccessAction(
            res.data !== undefined ? res.data : ({} as  DeleteSummaryListRes),
            res.data.message !== undefined
              ? res.data.message
              : "Doctor Summary Added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch( deleteSummaryListLoadingAction(false));
        dispatch(
         deleteSummaryListErrorAction(
            {} as  DeleteSummaryListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDeleteSummaryListAPIResMsg = () => {
  return function (dispatch: Dispatch<DeleteSummaryListActionTypes>) {
    dispatch(
     deleteSummaryListUpdateAPIMsgAction(
        apiRes as  DeleteSummaryListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDeleteSummaryListAPIRes = () => {
  return function (dispatch: Dispatch<DeleteSummaryListActionTypes>) {
    dispatch(
     deleteSummaryListAPIResClearAction(
        {} as  DeleteSummaryListRes,
        "",
        "",
        0
      )
    );
  };
};
