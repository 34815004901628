import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import MWLoader from "components/MWLoader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { JwtTokenAuthBody } from "redux/jwtDoctorLogIn/Model";
import { clearLoginAPIRes, jwtTokenApiCall } from "redux/jwtDoctorLogIn/API";
import { LoginBody } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import {
  clearGetDoctorDetailsAPIRes,
  getDoctorLoginDetails,
} from "redux/jwtDoctorLogIn/doctorLoginDetails/API";
import ForgetPassword from "./ForgetPassword";

export default function Loginwithpasswordcomponent() {
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const errorEmail = emailErrorMsg ? (
    <Typography>{emailErrorMsg}</Typography>
  ) : (
    ""
  );
  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPassword] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const errorPassword = passwordErrorMsg ? (
    <Typography>{passwordErrorMsg}</Typography>
  ) : (
    ""
  );

  const [apiMsgType, setAPIMsgType] = useState<AlertColor>("success");
  const [error, setError] = useState("");

  const [forgotPassDialogActive, setForgotPassDialogActive] = useState(false);
  const updateForgotPassDialogActive = (passedVal: boolean) => {
    setForgotPassDialogActive(passedVal);
  };

  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const loadingMarkup = isLoading ? <MWLoader /> : null;

  const body = {
    username: email.toLowerCase(),
    password: password.password,
  } as JwtTokenAuthBody;

  //handle button click of login form Tab-1
  const handleLogin = () => {
    if (email === "" && password.password === "") {
      setEmailErrorMsg(`${t("common.erroremail", { ns: ["home"] })}`);
      setPasswordErrorMsg(`${t("home.enterpassword", { ns: ["home"] })}`);
      return;
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setEmailErrorMsg(`${t("common.validemail", { ns: ["home"] })}`);
    } else if (email === "" && password.password !== "") {
      setEmailErrorMsg(`${t("common.erroremail", { ns: ["home"] })}`);
      setPasswordErrorMsg("");
      return;
    } else if (email !== "" && password.password === "") {
      setEmailErrorMsg("");
      setPasswordErrorMsg(`${t("home.enterpassword", { ns: ["home"] })}`);
      return;
    } else {
      setPasswordErrorMsg("");
      setEmailErrorMsg("");
      dispatch(clearLoginAPIRes());
      dispatch(clearGetDoctorDetailsAPIRes());
      setError("");
      dispatch(jwtTokenApiCall(body));
    }
  };
  // Call JWT API store
  const apiAuthToken = useSelector((state: AppState) => state.apiAuthToken);
  useEffect(() => {
    setIsLoading(apiAuthToken?.loading);
    if (apiAuthToken?.loginRes?.access !== undefined) {
      dispatch(
        getDoctorLoginDetails({
          token: apiAuthToken?.loginRes?.access,
        } as LoginBody)
      );
    }
    if (apiAuthToken?.errorMsg !== "") {
      setAPIMsgType("error");
      setError(apiAuthToken?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiAuthToken]);
  // Call Doctor Details API store
  const getDoctorLoginDetailsValue = useSelector(
    (state: AppState) => state.getDoctorLoginDetailsRes
  );
  useEffect(() => {
    setIsLoading(getDoctorLoginDetailsValue?.loading);
    if (
      getDoctorLoginDetailsValue?.getDoctorLoginDetailsRes?.user !== undefined
    ) {
      window.location.reload();
    }
    if (getDoctorLoginDetailsValue?.errorMsg !== "") {
      setAPIMsgType("error");
      setError(getDoctorLoginDetailsValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorLoginDetailsValue]);

  const actualPageMarkup = (
    <Box onKeyDown={keyDownEvent}>
      {loadingMarkup}
      <TextField
        fullWidth
        sx={{ my: 2 }}
        value={email}
        error={errorEmail === "" ? false : true}
        label={t("common.emailorphno", { ns: ["home"] })}
        placeholder={t("loginpage.enterregemail", { ns: ["home"] })!}
        id="emailFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
          setEmailErrorMsg("");
        }}
        helperText={
          errorEmail === "" ? (
            <Box>{t("common.emailHelperText", { ns: ["home"] })!}</Box>
          ) : (
            errorEmail
          )
        }
        InputLabelProps={{ shrink: true }}
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password" shrink>
          {t("home.enterpassword", { ns: ["home"] })!}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          notched
          error={errorPassword === "" ? false : true}
          placeholder={t("home.enterpassword", { ns: ["home"] })!}
          label={t("home.enterpassword", { ns: ["home"] })!}
          type={password.showPassword ? "text" : "Password"}
          value={password.password}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {password.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            xs={12}
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                {errorPassword === "" ? (
                  <Box>{t("common.passwordHelperText", { ns: ["home"] })!}</Box>
                ) : (
                  errorPassword
                )}
              </Box>
              <Button
                color="primary"
                onClick={() => setForgotPassDialogActive(true)}
                disabled={
                  apiAuthToken?.loading || getDoctorLoginDetailsValue?.loading
                }
                sx={{ textTransform: "none" }}
              >
                {t("common.forgotpassword", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        </FormHelperText>
      </FormControl>
      <Button
        sx={{ my: 2, textTransform: "none" }}
        size="large"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleLogin}
      >
        {t("common.login", { ns: ["home"] })!}
      </Button>
      {error !== "" ? (
        <Box>
          <Alert
            severity={apiMsgType}
            onClose={() => {
              dispatch(clearLoginAPIRes());
              dispatch(clearGetDoctorDetailsAPIRes());
              setError("");
            }}
          >
            {error}
          </Alert>
        </Box>
      ) : null}
    </Box>
  );
  return (
    <Box>
      {actualPageMarkup}
      {forgotPassDialogActive ? (
        <ForgetPassword
          activeDialog={forgotPassDialogActive}
          forgotPasswordUpdateState={updateForgotPassDialogActive}
        />
      ) : null}
    </Box>
  );
}
