import {
  GET_DOCTOR_MEMBERSHIP_LIST_FAIL,
  GET_DOCTOR_MEMBERSHIP_LIST_LOADING,
  GET_DOCTOR_MEMBERSHIP_LIST_SUCCESS,
  GET_DOCTOR_MEMBERSHIP_LIST_API_MSG,
  GET_DOCTOR_MEMBERSHIP_LIST_API_RES,
  GetDoctorMembershipListActionTypes,
} from "./ActionTypes";
import { GetProfessionalMembershipRes } from "./Model";

export const getDoctorMembershipListLoadingAction = (
  loading: boolean
): GetDoctorMembershipListActionTypes => {
  return {
    type: GET_DOCTOR_MEMBERSHIP_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorMembershipListSuccessAction = (
  getDoctorMembershipListResponse: GetProfessionalMembershipRes,
  successMsg: string
): GetDoctorMembershipListActionTypes => {
  return {
    type: GET_DOCTOR_MEMBERSHIP_LIST_SUCCESS,
    payload: getDoctorMembershipListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorMembershipListErrorAction = (
  getDoctorMembershipListResponse: GetProfessionalMembershipRes,
  errMsg: string,
  status: number
): GetDoctorMembershipListActionTypes => {
  return {
    type: GET_DOCTOR_MEMBERSHIP_LIST_FAIL,
    payload: getDoctorMembershipListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorMembershipListAPIMsgAction = (
  getDoctorMembershipListResponse: GetProfessionalMembershipRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorMembershipListActionTypes => {
  return {
    type: GET_DOCTOR_MEMBERSHIP_LIST_API_MSG,
    payload: getDoctorMembershipListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorMembershipListAPIResClearAction = (
  getDoctorMembershipListResponse: GetProfessionalMembershipRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorMembershipListActionTypes => {
  return {
    type: GET_DOCTOR_MEMBERSHIP_LIST_API_RES,
    payload: getDoctorMembershipListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
