import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "../routes/PublicRoute";
import { PrivateRoute } from "../routes/PrivateRoute";
import Login from "../pages/UserOnboarding/Login";
import SignUp from "../pages/UserOnboarding/SignUp";
import Experience from "../pages/UserOnboarding/Experience";
import Dashboard from "../pages/Dashboard";
import PatientCaseList from "../pages/PatientCaseList";
import NotFound from "../pages/NotFound";
import CodeOfConduct from "../pages/CodeOfConduct";
import AppoinmentDetails from "../pages/AppoinmentDetails";
import Appointments from "../pages/Appointments";
import TaskDetails from "../pages/Task/TaskDetails";
import Task from "../pages/Task";
import DoctorPatientInteraction from "../pages/DoctorPatientInteraction";
import AppoinmentBooking from "../pages/AppoinmentBooking";
import AddDoctorTeam from "../pages/AddDoctorTeam";
import UserProfile from "../pages/UserProfile";
import NeedHelp from "../pages/NeedHelp";
import DoctorTeleHealth from "../pages/Telehealth/DoctorTeleHealth";
import Payment from "../pages/Payment";
import DoctorCalendar from "../pages/DoctorCalendar";
import DoctorAppointment from "../pages/DoctorAppointment";
import AccountDetails from "../pages/UserOnboarding/AccountDetails";
import Preview from "pages/FilePreparation/Preview";

function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/signin" component={Login} />
      <PublicRoute exact path="/signup" component={SignUp} />
      <PublicRoute exact path="/experience" component={Experience} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/appointments" component={Appointments} />
      <PrivateRoute path="/tasks" component={PatientCaseList} />
      <PrivateRoute path="/userprofile" component={UserProfile} />
      <PrivateRoute path="/needhelp" component={NeedHelp} />
      <PrivateRoute path="/codeofconduct" component={CodeOfConduct} />
      <PrivateRoute
        path="/appointmentdetails/:patientid?/:caseid?/:index?"
        component={AppoinmentDetails}
      />
      <PrivateRoute path="/patientcaselist" component={PatientCaseList} />
      <PrivateRoute path="/task" component={Task} />
      <PrivateRoute
        path="/taskdetails/:patientId?/:caseId?/:index?"
        component={TaskDetails}
      />
      <PrivateRoute
        path="/doctorpatientinteraction/:caseId?"
        component={DoctorPatientInteraction}
      />
      <PrivateRoute path="/telehealth" component={DoctorTeleHealth} />
      <PrivateRoute
        path="/appoinmentbooking/:caseId?"
        component={AppoinmentBooking}
      />
      <PrivateRoute path="/adddoctorteam/:caseId?" component={AddDoctorTeam} />
      <PrivateRoute path="/payment" component={Payment} />
      <PrivateRoute path="/calendar" component={DoctorCalendar} />
      <PrivateRoute path="/doctorappointment" component={DoctorAppointment} />
      <PrivateRoute path="/accountdetails" component={AccountDetails} />
      <PrivateRoute path="/preview/:patientid?/:caseid?" component={Preview} />

      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
