import { DoctorAccDetailsRes } from "./Model";
export const DR_ACC_DETAILS_LOADING = "DR_ACC_DETAILS_LOADING";
export const DR_ACC_DETAILS_SUCCESS = "DR_ACC_DETAILS_SUCCESS";
export const DR_ACC_DETAILS_FAIL = "DR_ACC_DETAILS_FAIL";
export const DR_ACC_DETAILS_UPDATE_API_MSG = "DR_ACC_DETAILS_UPDATE_API_MSG";
export const DR_ACC_DETAILS_UPDATE_API_RES = "DR_ACC_DETAILS_UPDATE_API_RES";

export interface DoctorAccountDetailsState {
  doctorAccountDetailsRes: DoctorAccDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorAccountDetailsLoading {
  type: typeof DR_ACC_DETAILS_LOADING;
  loading: boolean;
}
export interface DoctorAccountDetailsSuccess {
  type: typeof DR_ACC_DETAILS_SUCCESS;
  payload: DoctorAccDetailsRes;
  successMsg: string;
}
export interface DoctorAccountDetailsFail {
  type: typeof DR_ACC_DETAILS_FAIL;
  payload: DoctorAccDetailsRes;
  errorMsg: string;
  status: number;
}
export interface DoctorAccountDetailsUpdateAPIMsg {
  type: typeof DR_ACC_DETAILS_UPDATE_API_MSG;
  payload: DoctorAccDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorAccountDetailsUpdateAPIRes {
  type: typeof DR_ACC_DETAILS_UPDATE_API_RES;
  payload: DoctorAccDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DoctorAccountDetailsTypes =
  | DoctorAccountDetailsLoading
  | DoctorAccountDetailsSuccess
  | DoctorAccountDetailsFail
  | DoctorAccountDetailsUpdateAPIMsg
  | DoctorAccountDetailsUpdateAPIRes;
