import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "components/PageLayout";
import { useParams } from "react-router-dom";
import {
  SecondOpinionUiSection,
  StepPreviewBody,
} from "redux/PreviewSectionByStepId/SectionsByStepIdForPreview/Model";
import { stepPreviewListApi } from "redux/PreviewSectionByStepId/SectionsByStepIdForPreview/API";
import { AppState } from "redux/store/Store";
import { Box, Grid, Paper, Typography } from "@mui/material";
import DemographicDetails from "pages/FilePreparation/DemographicDetails";
import FileData from "pages/FilePreparation/FIleData";
import MWExceptionList from "components/MWExceptionList";
import { SecondOpinionUiTemplate } from "redux/PreviewSectionByStepId/SectionTemplateList/Model";

interface Props {
  templateId: SecondOpinionUiTemplate | null | any;
}

export default function PreviewContent({ templateId }: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };

  const stepPreviewValue = useSelector((state: AppState) => state.stepPreviewList);

  const stepPreviewBody = {
    stepId: 3,
    caseId: caseid,
  } as StepPreviewBody;

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(stepPreviewListApi(stepPreviewBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    templateId !== null
      ? dispatch(
          stepPreviewListApi(Object.assign(stepPreviewBody, { templateId: templateId.value })),
        )
      : dispatch(stepPreviewListApi(stepPreviewBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  const previewContentMarkup = (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
      p={2}
    >
      <Grid item xs={12} sm={12} md={9} xl={9} lg={9}>
        {stepPreviewValue?.stepPreviewListResponse?.SecondOpinionUiSection !== undefined &&
        stepPreviewValue?.stepPreviewListResponse?.SecondOpinionUiSection.length > 0 ? (
          stepPreviewValue?.stepPreviewListResponse?.SecondOpinionUiSection.map(
            (item: SecondOpinionUiSection, index: number) => (
              <Box sx={{ paddingTop: 1 }} key={index}>
                {item.name.includes("demographics") ? (
                  <DemographicDetails />
                ) : (
                  <FileData type={item.name} label={item.displayValue} />
                )}
              </Box>
            ),
          )
        ) : (
          <MWExceptionList />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} xl={3} lg={3}>
        <Paper
          variant="outlined"
          sx={{
            backgroundColor: "#FBEAE5",
          }}
        >
          {stepPreviewValue?.stepPreviewListResponse?.SecondOpinionUiSection !== undefined &&
          stepPreviewValue?.stepPreviewListResponse?.SecondOpinionUiSection.length > 0
            ? stepPreviewValue?.stepPreviewListResponse?.SecondOpinionUiSection.map(
                (item: SecondOpinionUiSection, index: number) => (
                  <Typography sx={{ padding: 1 }} key={index}>
                    {item.displayValue}
                  </Typography>
                ),
              )
            : null}
        </Paper>
      </Grid>
    </Grid>
  );

  return <PageLayout>{previewContentMarkup}</PageLayout>;
}
