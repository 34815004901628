import {
  AddDoctorSignatureDetailsActionTypes,
  ADD_DOCTOR_SIGNATURE_DETAILS_FAIL,
  ADD_DOCTOR_SIGNATURE_DETAILS_LOADING,
  ADD_DOCTOR_SIGNATURE_DETAILS_SUCCESS,
  AddDoctorSignatureDetailsState,
  ADD_DOCTOR_SIGNATURE_DETAILS_API_MSG,
  ADD_DOCTOR_SIGNATURE_DETAILS_API_RES,
} from "./ActionTypes";
import { AddSignatureRes } from "./Model";

const initialStateUpdatePosts: AddDoctorSignatureDetailsState = {
  loading: false,
  addDoctorSignatureDetailsRes: {} as AddSignatureRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addSignatureDetailsReducer = (
  state = initialStateUpdatePosts,
  action: AddDoctorSignatureDetailsActionTypes
): AddDoctorSignatureDetailsState => {
  switch (action.type) {
    case ADD_DOCTOR_SIGNATURE_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_DOCTOR_SIGNATURE_DETAILS_SUCCESS:
      return {
        ...state,
        addDoctorSignatureDetailsRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_DOCTOR_SIGNATURE_DETAILS_FAIL:
      return {
        ...state,
        addDoctorSignatureDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DOCTOR_SIGNATURE_DETAILS_API_MSG:
      return {
        ...state,
        addDoctorSignatureDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_DOCTOR_SIGNATURE_DETAILS_API_RES:
      return {
        ...state,
        addDoctorSignatureDetailsRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
