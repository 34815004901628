import {
  GET_DOCTOR_DEGREE_LIST_FAIL,
  GET_DOCTOR_DEGREE_LIST_LOADING,
  GET_DOCTOR_DEGREE_LIST_SUCCESS,
  GET_DOCTOR_DEGREE_LIST_API_MSG,
  GET_DOCTOR_DEGREE_LIST_API_RES,
  GetDoctorDegreeListActionTypes,
} from "./ActionTypes";
import { GetDegreeListRes } from "./Model";

export const getDoctorDegreeListLoadingAction = (
  loading: boolean
): GetDoctorDegreeListActionTypes => {
  return {
    type: GET_DOCTOR_DEGREE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorDegreeListSuccessAction = (
  getDoctorDegreeListResponse: GetDegreeListRes,
  successMsg: string
): GetDoctorDegreeListActionTypes => {
  return {
    type: GET_DOCTOR_DEGREE_LIST_SUCCESS,
    payload: getDoctorDegreeListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorDegreeListErrorAction = (
  getDoctorDegreeListResponse: GetDegreeListRes,
  errMsg: string,
  status: number
): GetDoctorDegreeListActionTypes => {
  return {
    type: GET_DOCTOR_DEGREE_LIST_FAIL,
    payload: getDoctorDegreeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorDegreeListAPIMsgAction = (
  getDoctorDegreeListResponse: GetDegreeListRes,
  errMsg: string,
  status: number
): GetDoctorDegreeListActionTypes => {
  return {
    type: GET_DOCTOR_DEGREE_LIST_API_MSG,
    payload: getDoctorDegreeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorDegreeListAPIResClearAction = (
  getDoctorDegreeListResponse: GetDegreeListRes,
  errMsg: string,
  status: number
): GetDoctorDegreeListActionTypes => {
  return {
    type: GET_DOCTOR_DEGREE_LIST_API_RES,
    payload: getDoctorDegreeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
