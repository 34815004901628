import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { AddDrInsuranceActionTypes } from "./ActionTypes";
import { AddDrInsuranceBody, AddDrInsuranceRes } from "./Model";
import {
  addDrInsuranceAPIResClearAction,
  addDrInsuranceErrorAction,
  addDrInsuranceLoadingAction,
  addDrInsuranceSuccessAction,
  addDrInsuranceAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddDrInsuranceRes;
export const addDrInsurance = (payload: AddDrInsuranceBody) => {
  return function (dispatch: Dispatch<AddDrInsuranceActionTypes>) {
    dispatch(addDrInsuranceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/doctorinsurance/add_doctor_insurance`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDrInsuranceLoadingAction(false));
        dispatch(
          addDrInsuranceSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal details has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDrInsuranceLoadingAction(false));
        dispatch(
          addDrInsuranceErrorAction(
            {} as AddDrInsuranceRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDrInsuranceAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDrInsuranceActionTypes>) {
    dispatch(
      addDrInsuranceAPIMsgAction(apiRes as AddDrInsuranceRes, "", "", 0)
    );
  };
};

export const clearAddDrInsuranceAPIRes = () => {
  return function (dispatch: Dispatch<AddDrInsuranceActionTypes>) {
    dispatch(
      addDrInsuranceAPIResClearAction({} as AddDrInsuranceRes, "", "", 0)
    );
  };
};
