import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { EditDrLegalDetailsActionTypes } from "./ActionTypes";
import { EditLegalDetailsBody, EditLegalDetailsRes } from "./Model";
import {
  editDrLegalDetailsAPIResClearAction,
  editDrLegalDetailsErrorAction,
  editDrLegalDetailsLoadingAction,
  editDrLegalDetailsSuccessAction,
  editDrLegalDetailsAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EditLegalDetailsRes;
export const editDrLegalDetails = (payload: EditLegalDetailsBody) => {
  return function (dispatch: Dispatch<EditDrLegalDetailsActionTypes>) {
    dispatch(editDrLegalDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/onboarding/edit_doctor_legalDetails`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editDrLegalDetailsLoadingAction(false));
        dispatch(
          editDrLegalDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. legal details has been edited successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editDrLegalDetailsLoadingAction(false));
        dispatch(
          editDrLegalDetailsErrorAction(
            {} as EditLegalDetailsRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDrLegalDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<EditDrLegalDetailsActionTypes>) {
    dispatch(
      editDrLegalDetailsAPIMsgAction(apiRes as EditLegalDetailsRes, "", "", 0)
    );
  };
};

export const clearEditDrLegalDetailsAPIRes = () => {
  return function (dispatch: Dispatch<EditDrLegalDetailsActionTypes>) {
    dispatch(
      editDrLegalDetailsAPIResClearAction({} as EditLegalDetailsRes, "", "", 0)
    );
  };
};
