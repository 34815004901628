import {
  DoctorCaseList,
  GetDoctorCaseListActionTypes,
  GET_DOCTOR_CASE_LIST_FAIL,
  GET_DOCTOR_CASE_LIST_LOADING,
  GET_DOCTOR_CASE_LIST_SUCCESS,
} from "./ActionTypes";
import { GetDoctorCaseListRes } from "./Model";

const initialStateGetPosts: DoctorCaseList = {
  loading: false,
  getDoctorCaseListRes: {} as GetDoctorCaseListRes,
  errRes: "",
};
export const getDoctorCaseListReducer = (
  state = initialStateGetPosts,
  action: GetDoctorCaseListActionTypes
): DoctorCaseList => {
  switch (action.type) {
    case GET_DOCTOR_CASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getDoctorCaseListRes: action.payload,
        errRes: "",
      };
    case GET_DOCTOR_CASE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getDoctorCaseListRes: {} as GetDoctorCaseListRes,
      };
    case GET_DOCTOR_CASE_LIST_FAIL:
      return {
        ...state,
        getDoctorCaseListRes: {} as GetDoctorCaseListRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
