import {
  SubmitQueryDetails,
  submitQueryActionTypes,
  SUBMIT_QUERY_FAIL,
  SUBMIT_QUERY_LOADING,
  SUBMIT_QUERY_SUCCESS,
} from "./ActionTypes";
import { SubmitQueryValue } from "./Model";

const initialStateGetPosts: SubmitQueryDetails = {
  loading: false,
  SUBMIT_QUERY: {} as SubmitQueryValue,
  errRes: "",
};
export const submitQueryReducer = (
  state = initialStateGetPosts,
  action: submitQueryActionTypes
): SubmitQueryDetails => {
  switch (action.type) {
    case SUBMIT_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        SUBMIT_QUERY: action.payload,
        errRes: "",
      };
    case SUBMIT_QUERY_LOADING:
      return {
        ...state,
        loading: true,
        SUBMIT_QUERY: {} as SubmitQueryValue,
      };
    case SUBMIT_QUERY_FAIL:
      return {
        ...state,
        SUBMIT_QUERY: {} as SubmitQueryValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
