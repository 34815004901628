import {
  GetCaseStateType,
  CaseGetActionTypes,
  CASE_LIST_FAIL,
  CASE_LIST_SUCCESS,
  CASE_LIST_LOADING,
  GetAppointmentStateType,
  AppointmentGetActionTypes,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_LOADING,
  GET_APPOINTMENT_FAIL
} from '../types/ActionTypes';

const initialStateGetCase: GetCaseStateType = { loading: false, caseValue: [] };

// Initial Appointment State
const initialStateGetAppointment: GetAppointmentStateType = { loading: false, appointmentValue: [] };

export const getCaseReducer = (state = initialStateGetCase, action: CaseGetActionTypes): GetCaseStateType => {
  switch (action.type) {
    case CASE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        caseValue: action.payload
      };
    case CASE_LIST_LOADING:
      return {
        ...state,
        loading: true,
        caseValue: []
      };
    case CASE_LIST_FAIL:
      return {
        ...state,
        caseValue: [],
        loading: false
      };
    default:
      return state;
    // case GET_CASE_LIST:
    //   return {
    //     ...state,
    //     caseValue: action.payload
    //   };
    // default:
    //   return state;
  }
};

// Get Doctor Appointment List Reducer
export const getAppointmentReducer = (state = initialStateGetAppointment, action: AppointmentGetActionTypes): GetAppointmentStateType => {
  switch (action.type) {
    case GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointmentValue: action.payload
      };
    case GET_APPOINTMENT_LOADING:
      return {
        ...state,
        loading: true,
        appointmentValue: []
      };
    case GET_APPOINTMENT_FAIL:
      return {
        ...state,
        appointmentValue: [],
        loading: false
      };
    default:
      return state;
  }
};
