import { useState } from "react";
import AppBars from "./AppBar";
import AppDrawer from "./Drawer";
import { Box, CssBaseline } from "@mui/material";

function AppFrame() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const updateDrawerState = (passedVal: boolean) => {
    setIsDrawerOpen(passedVal);
  };
  const updateDrawerStateFromAppBar = (passedVal: boolean) => {
    setIsDrawerOpen(passedVal);
  };
  const updateHoverState = (passedVal: boolean) => {
    setIsDrawerOpen(passedVal);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBars
        drawerState={isDrawerOpen}
        updateDrawerStateFromAppBar={updateDrawerStateFromAppBar}
        updateHoverState={updateHoverState}
      />
      <AppDrawer
        drawerState={isDrawerOpen}
        updateDrawerState={updateDrawerState}
      />
    </Box>
  );
}

export default AppFrame;
