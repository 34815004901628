import { SubmitQueryValue } from "./Model";

// Patient Meeting Loading State
export const SUBMIT_QUERY_LOADING =
  "SUBMIT_QUERY_LOADING";
export const SUBMIT_QUERY_SUCCESS =
  "SUBMIT_QUERY_SUCCESS";
export const SUBMIT_QUERY_FAIL = "SUBMIT_QUERY_FAIL";
export const SUBMIT_QUERY = "SUBMIT_QUERY";

export interface SubmitQueryDetails {
  SUBMIT_QUERY: SubmitQueryValue;
  loading: boolean;
  errRes: string;
}
export interface SubmitQueryLoading {
  type: typeof SUBMIT_QUERY_LOADING;
}
export interface SubmitQuerySuccess {
  type: typeof SUBMIT_QUERY_SUCCESS;
  payload: SubmitQueryValue;
  errRes: string;
}
export interface SubmitQueryFail {
  type: typeof SUBMIT_QUERY_FAIL;
  errRes: string;
}

interface SubmitQueryAction {
  type: typeof SUBMIT_QUERY;
  payload: SubmitQueryValue;
  errRes: string;
}

export type submitQueryActionTypes =
  | SubmitQueryAction
  | SubmitQueryLoading
  | SubmitQuerySuccess
  | SubmitQueryFail;
