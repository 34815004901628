import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorLicenseListActionTypes } from "./ActionTypes";
import { GetLicenseListRes } from "./Model";
import {
  getDoctorLicenseListAPIResClearAction,
  getDoctorLicenseListErrorAction,
  getDoctorLicenseListLoadingAction,
  getDoctorLicenseListSuccessAction,
  getDoctorLicenseListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetLicenseListRes;
export const getLicenseList = () => {
  return function (dispatch: Dispatch<GetDoctorLicenseListActionTypes>) {
    dispatch(getDoctorLicenseListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/doctorlicense/get_license_details_by_doctor`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorLicenseListLoadingAction(false));
        dispatch(
          getDoctorLicenseListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. Work license has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorLicenseListLoadingAction(false));
        dispatch(
          getDoctorLicenseListErrorAction(
            {} as GetLicenseListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetDoctorLicenseListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorLicenseListActionTypes>) {
    dispatch(
      getDoctorLicenseListAPIMsgAction(
        apiRes as GetLicenseListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDoctorProfessionalListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorLicenseListActionTypes>) {
    dispatch(
      getDoctorLicenseListAPIResClearAction(
        {} as GetLicenseListRes,
        "",
        "",
        0
      )
    );
  };
};
