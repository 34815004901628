import { AddEmailPhoneRes } from "./Model";
export const ADD_EMAIL_PHONE_LOADING = "ADD_EMAIL_PHONE_LOADING";
export const ADD_EMAIL_PHONE_SUCCESS = "ADD_EMAIL_PHONE_SUCCESS";
export const ADD_EMAIL_PHONE_FAIL = "ADD_EMAIL_PHONE_FAIL";
export const ADD_EMAIL_PHONE_UPDATE_API_MSG = "ADD_EMAIL_PHONE_UPDATE_API_MSG";
export const ADD_EMAIL_PHONE_UPDATE_API_RES = "ADD_EMAIL_PHONE_UPDATE_API_RES";

export interface AddEmailPhoneState {
  addEmailPhoneRes: AddEmailPhoneRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddEmailPhoneLoading {
  type: typeof ADD_EMAIL_PHONE_LOADING;
  loading: boolean;
}
export interface AddEmailPhoneSuccess {
  type: typeof ADD_EMAIL_PHONE_SUCCESS;
  payload: AddEmailPhoneRes;
  successMsg: string;
}
export interface AddEmailPhoneFail {
  type: typeof ADD_EMAIL_PHONE_FAIL;
  payload: AddEmailPhoneRes;
  errorMsg: string;
  status: number;
}
export interface AddEmailPhoneUpdateAPIMsg {
  type: typeof ADD_EMAIL_PHONE_UPDATE_API_MSG;
  payload: AddEmailPhoneRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddEmailPhoneUpdateAPIRes {
  type: typeof ADD_EMAIL_PHONE_UPDATE_API_RES;
  payload: AddEmailPhoneRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddEmailPhoneTypes =
  | AddEmailPhoneLoading
  | AddEmailPhoneSuccess
  | AddEmailPhoneFail
  | AddEmailPhoneUpdateAPIMsg
  | AddEmailPhoneUpdateAPIRes;
