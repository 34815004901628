import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { allActiveMeetingListApi } from "redux/AllActivemeetingList/API";
import { allActiveMeetingListDetails } from "redux/AllActivemeetingList/Model";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "components/MWExceptionList";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import dayjs from "dayjs";
import { AllActiveMeetingListValue } from "../../redux/AllActivemeetingList/Model";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { toggleAppBarLoading } from "redux/appLoader/Actions";
export default function DoctorTeleHealth() {
  const history = useHistory();
  const dispatch = useDispatch();
  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;

  function gotoCaseDetails(patientId: number, caseId: number) {
    history.push(`/appointmentdetails/${patientId}/${caseId}`);
  }

  // Meeting List Body
  const meetingListBody = {
    doctorId: String(DoctorID),
  } as allActiveMeetingListDetails;
  const columns: GridColDef[] = [
    {
      field: "meetingSequence",
      headerName: `${t("common.view", { ns: ["home"] })}`,
      minWidth: 80,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            gotoCaseDetails(params.row.patientId, params.row.caseId);
          }}
          sx={{ textTransform: "none" }}
        >
          <VisibilityIcon />
        </Button>
      ),
    },
    {
      field: "id",
      headerName: `${t("appointmentpage.meetingId", { ns: ["home"] })!}`,
      align: "center",
    },
    {
      field: "description",
      headerName: `${t("appointmentpage.appoinmentname", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "appointmentDate",
      headerName: `${t("appointmentpage.appointmentDate", { ns: ["home"] })!}`,
      minWidth: 130,
    },
    {
      field: "dateCreated",
      headerName: `${t("appointmentpage.createdDate", { ns: ["home"] })!}`,
      minWidth: 120,
    },
  ];
  // Meeting List GET API
  const doctorAllActiveMeetingListValue = useSelector(
    (state: AppState) => state.allActiveMeetingList
  );

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [meetingList, setMeetingList] = useState([] as any[]);
  useEffect(() => {
    dispatch(toggleAppBarLoading(doctorAllActiveMeetingListValue?.loading));
    if (
      doctorAllActiveMeetingListValue?.allActiveMeetingListRes?.meetingList !==
      undefined
    ) {
      let meetingItem =
        doctorAllActiveMeetingListValue?.allActiveMeetingListRes?.meetingList.map(
          (item: AllActiveMeetingListValue, index) => {
            return {
              meetingSequence: index,
              id: item.id,
              patientId: item?.patientId,
              caseId: item?.caseId,
              description:
                item?.displayName !== undefined
                  ? item.displayName
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              appointmentDate:
                item?.appointmentDate !== undefined
                  ? item?.appointmentDate !== null
                    ? dayjs(item?.appointmentDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              dateCreated:
                item?.dateCreated !== undefined
                  ? item?.dateCreated !== null
                    ? dayjs(item?.dateCreated).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
            }; //as ModifiedPatientDetailList;
          }
        );
      setMeetingList(meetingItem);
    } else setMeetingList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorAllActiveMeetingListValue]);

  useEffect(() => {
    dispatch(allActiveMeetingListApi(meetingListBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item>
              <Typography variant="h6">
                {t("telehealthpage.meetlist", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={() =>
                      dispatch(allActiveMeetingListApi(meetingListBody))
                    }
                    sx={{ textTransform: "none" }}
                  >
                    {t("telehealthpage.reloadmeetlist", { ns: ["home"] })!}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {meetingList.length > 0 ? (
          <DataGrid
            rows={meetingList}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
            pagination
            // pageSize={10}
            // hideFooter
            // hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );
}
