import { GetMaritalListListRes } from "./Model";

export const GET_MARITAL_STATUS_LIST_LOADING =
  "GET_MARITAL_STATUS_LIST_LOADING";
export const GET_MARITAL_STATUS_LIST_SUCCESS =
  "GET_MARITAL_STATUS_LIST_SUCCESS";
export const GET_MARITAL_STATUS_LIST_FAIL =
  "GET_MARITAL_STATUS_LIST_FAIL";
export const GET_MARITAL_STATUS_LIST_DATA =
  "GET_MARITAL_STATUS_LIST_DATA";

export interface MaritalListListDetails {
  getMaritalListListRes: GetMaritalListListRes;
  loading: boolean;
  errRes: string;
}
export interface GetMaritalListListActionLoading {
  type: typeof GET_MARITAL_STATUS_LIST_LOADING;
}
export interface GetMaritalListListActionSuccess {
  type: typeof GET_MARITAL_STATUS_LIST_SUCCESS;
  payload: GetMaritalListListRes;
  errRes: string;
}
export interface GetMaritalListListActionFail {
  type: typeof GET_MARITAL_STATUS_LIST_FAIL;
  payload: GetMaritalListListRes;
  errRes: string;
}
interface GetMaritalListListActionAction {
  type: typeof GET_MARITAL_STATUS_LIST_DATA;
  payload: GetMaritalListListRes;
  errRes: string;
}

export type GetMaritalListListActionTypes =
  | GetMaritalListListActionAction
  | GetMaritalListListActionLoading
  | GetMaritalListListActionSuccess
  | GetMaritalListListActionFail;
