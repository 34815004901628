// import axios from "axios";
// import { Dispatch } from "redux";
// import apiURL from "../../ApiUrl";
// import { setupInterceptorsTo } from "../authentication/Interceptors";
// import {
//   emailOtpSignupActionTypes,
//   EMAIL_OTP_SIGNUP_FAIL,
//   EMAIL_OTP_SIGNUP_LOADING,
//   EMAIL_OTP_SIGNUP_SUCCESS,
// } from "./ActionTypes";
// import { EmailOtpSignUpBody, emailOtpSignupValue } from "./Model";

// export const emailOtpSignUp = (
//   body: EmailOtpSignUpBody
// ) => {
//   return function (dispatch: Dispatch<emailOtpSignupActionTypes>) {
//     dispatch({
//       type: EMAIL_OTP_SIGNUP_LOADING,
//     });

//     setupInterceptorsTo(axios)
//       .post(
//         apiURL.apiURL +
//           `/doctorapp/email-otp-auth-signup/`,
//         body
//       )
//       .then((res) => {
//         // dispatch(emailOtpSignupAction(res.data));
//         dispatch({
//           type: EMAIL_OTP_SIGNUP_SUCCESS,
//           payload: res.data,
//           errRes: "",
//         });
//       })
//       .catch((error) => {
//         dispatch({
//           type: EMAIL_OTP_SIGNUP_FAIL,
//           payload: {} as emailOtpSignupValue,
//           errRes: error.response.data.message,
//         });
//       });
//   };
// };

import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../URL";
import { EmailOTPSignupActionTypes } from "./ActionTypes";
import { EmailOtpSignUpBody, EmailOTPSignUpRes } from "./Model";
import {
  emailOTPGenAPIResClearAction,
  emailOTPGenErrorAction,
  emailOTPGenLoadingAction,
  emailOTPGenSuccessAction,
  emailOTPGenUpdateAPIMsgAction,
} from "./Action";
import { ApiHeader } from "../../components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as EmailOTPSignUpRes;
export const emailOtpSignUp = (payload: EmailOtpSignUpBody) => {
  return function (dispatch: Dispatch<EmailOTPSignupActionTypes>) {
    dispatch(emailOTPGenLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/email-otp-auth-signup/`, payload, {
        headers: ApiHeader,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(emailOTPGenLoadingAction(false));
        dispatch(
          emailOTPGenSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "OTP generated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(emailOTPGenLoadingAction(false));
        dispatch(
          emailOTPGenErrorAction(
            {} as EmailOTPSignUpRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEmailOTPSignUpAPIResMsg = () => {
  return function (dispatch: Dispatch<EmailOTPSignupActionTypes>) {
    dispatch(
      emailOTPGenUpdateAPIMsgAction(apiRes as EmailOTPSignUpRes, "", "", 0)
    );
  };
};

export const clearEmailOTPSignUpAPIRes = () => {
  return function (dispatch: Dispatch<EmailOTPSignupActionTypes>) {
    dispatch(emailOTPGenAPIResClearAction({} as EmailOTPSignUpRes, "", "", 0));
  };
};
