import {
  IMAGE_UPLOAD_API_MSG,
  IMAGE_UPLOAD_API_RES,
  IMAGE_UPLOAD_FAIL,
  IMAGE_UPLOAD_LOADING,
  IMAGE_UPLOAD_PROGRESS,
  IMAGE_UPLOAD_SUCCESS,
  ImageUploadActionTypes,
} from "./ActionTypes";
import { ImageUploadResponse } from "./Model";

export const imageUploadLoadingAction = (
  loading: boolean
): ImageUploadActionTypes => {
  return {
    type: IMAGE_UPLOAD_LOADING,
    loading: loading,
  };
};

export const imageUploadProgress = (progress: number): any => {
  return {
    type: IMAGE_UPLOAD_PROGRESS,
    progress: progress,
  };
};

export const imageUploadSuccessAction = (
  imageUploadResponse: ImageUploadResponse,
  successMsg: string,
  status: string
): ImageUploadActionTypes => {
  return {
    type: IMAGE_UPLOAD_SUCCESS,
    payload: imageUploadResponse,
    successMsg: successMsg,
    status: status,
  };
};

export const imageUploadErrorAction = (
  imageUploadResponse: ImageUploadResponse,
  errMsg: string,
  status: string
): ImageUploadActionTypes => {
  return {
    type: IMAGE_UPLOAD_FAIL,
    payload: imageUploadResponse,
    errMsg: errMsg,
    status: status,
  };
};

export const imageUploadUpdateAPIMsgAction = (
  imageUploadResponse: ImageUploadResponse,
  successMsg: string,
  errMsg: string,
  status: string
): ImageUploadActionTypes => {
  return {
    type: IMAGE_UPLOAD_API_MSG,
    payload: imageUploadResponse,
    successMsg: successMsg,
    errMsg: errMsg,
    status: status,
  };
};

export const imageUploadAPIResClearAction = (
  imageUploadResponse: ImageUploadResponse,
  successMsg: string,
  errMsg: string,
  status: string
): ImageUploadActionTypes => {
  return {
    type: IMAGE_UPLOAD_API_RES,
    payload: imageUploadResponse,
    successMsg: successMsg,
    errMsg: errMsg,
    status: status,
  };
};
