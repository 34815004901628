import {
  EDIT_DOCTOR_EDUCATION_DETAILS_FAIL,
  EDIT_DOCTOR_EDUCATION_DETAILS_LOADING,
  EDIT_DOCTOR_EDUCATION_DETAILS_SUCCESS,
  EDIT_DOCTOR_EDUCATION_DETAILS_API_MSG,
  EDIT_DOCTOR_EDUCATION_DETAILS_API_RES,
  AddDoctorEducationDetailsActionTypes,
} from "./ActionTypes";
import { EditEducationDetailsRes } from "./Model";

export const editDoctorEducationDetailsLoadingAction = (
  loading: boolean
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: EDIT_DOCTOR_EDUCATION_DETAILS_LOADING,
    loading: loading,
  };
};

export const editDoctorEducationDetailsSuccessAction = (
  editDoctorEducationDetailsResponse: EditEducationDetailsRes,
  successMsg: string
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: EDIT_DOCTOR_EDUCATION_DETAILS_SUCCESS,
    payload: editDoctorEducationDetailsResponse,
    successMsg: successMsg,
  };
};

export const editDoctorEducationDetailsErrorAction = (
  editDoctorEducationDetailsResponse: EditEducationDetailsRes,
  errMsg: string,
  status: number
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: EDIT_DOCTOR_EDUCATION_DETAILS_FAIL,
    payload: editDoctorEducationDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editDoctorEducationDetailsAPIMsgAction = (
  editDoctorEducationDetailsResponse: EditEducationDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: EDIT_DOCTOR_EDUCATION_DETAILS_API_MSG,
    payload: editDoctorEducationDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editDoctorEducationDetailsAPIResClearAction = (
  editDoctorEducationDetailsResponse: EditEducationDetailsRes,
  successMsg: string,
  errorMsg: string,
  status: number
): AddDoctorEducationDetailsActionTypes => {
  return {
    type: EDIT_DOCTOR_EDUCATION_DETAILS_API_RES,
    payload: editDoctorEducationDetailsResponse,
    successMsg: successMsg,
    errorMsg: errorMsg,
    status: status,
  };
};
