import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../URL";
import { AskQuestionActionTypes } from "./ActionTypes";
import { AskQuestionBody, AskQuestionResponse } from "./Model";
import {
  askQuestionAPIResClearAction,
  askQuestionErrorAction,
  askQuestionLoadingAction,
  askQuestionSuccessAction,
  askQuestionAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AskQuestionResponse;
export const askQuestionApi = (payload: AskQuestionBody) => {
  return function (dispatch: Dispatch<AskQuestionActionTypes>) {
    dispatch(askQuestionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/questionandanswerapp/api/question/ask_Question`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(askQuestionLoadingAction(false));
        dispatch(
          askQuestionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Question submitted successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(askQuestionLoadingAction(false));
        dispatch(
          askQuestionErrorAction(
            {} as AskQuestionResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const askQuestionAPIResMsg = () => {
  return function (dispatch: Dispatch<AskQuestionActionTypes>) {
    dispatch(
      askQuestionAPIMsgAction(apiRes as AskQuestionResponse, "", "", 0)
    );
  };
};

export const clearAskQuestionAPIRes = () => {
  return function (dispatch: Dispatch<AskQuestionActionTypes>) {
    dispatch(
      askQuestionAPIResClearAction({} as AskQuestionResponse, "", "", 0)
    );
  };
};