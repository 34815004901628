import {
  PaymentListStateType,
  PaymentListActionTypes,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_LOADING,
  PAYMENT_LIST_SUCCESS,
} from "./ActionTypes";
import { getPaymentLinkRes } from "./Model";

const initialStateGetPosts: PaymentListStateType = {
  loading: false,
  getPaymentLinkRes: {} as getPaymentLinkRes,
  errRes: "",
};
export const paymentLinkReducer = (
  state = initialStateGetPosts,
  action: PaymentListActionTypes
): PaymentListStateType => {
  switch (action.type) {
    case PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getPaymentLinkRes: action.payload,
        errRes: "",
      };
    case PAYMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        getPaymentLinkRes: {} as getPaymentLinkRes,
      };
    case PAYMENT_LIST_FAIL:
      return {
        ...state,
        getPaymentLinkRes: {} as getPaymentLinkRes,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
