import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { GetDoctorWorkExperienceListActionTypes } from "./ActionTypes";
import { GetWorkExperienceListRes } from "./Model";
import {
  getDoctorWorkExperienceListAPIResClearAction,
  getDoctorWorkExperienceListErrorAction,
  getDoctorWorkExperienceListLoadingAction,
  getDoctorWorkExperienceListSuccessAction,
  getDoctorWorkExperienceListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetWorkExperienceListRes;
export const getWorkExperienceList = () => {
  return function (dispatch: Dispatch<GetDoctorWorkExperienceListActionTypes>) {
    dispatch(getDoctorWorkExperienceListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/doctorapp/api/onboarding/work_experience_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorWorkExperienceListLoadingAction(false));
        dispatch(
          getDoctorWorkExperienceListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. Work experience has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorWorkExperienceListLoadingAction(false));
        dispatch(
          getDoctorWorkExperienceListErrorAction(
            {} as GetWorkExperienceListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetDoctorWorkExperienceListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorWorkExperienceListActionTypes>) {
    dispatch(
      getDoctorWorkExperienceListAPIMsgAction(
        apiRes as GetWorkExperienceListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDoctorProfessionalListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorWorkExperienceListActionTypes>) {
    dispatch(
      getDoctorWorkExperienceListAPIResClearAction(
        {} as GetWorkExperienceListRes,
        "",
        "",
        0
      )
    );
  };
};
