import {
  AddChiefComplaintTypes, CC_ADD_LIST_FAIL, CC_ADD_LIST_LOADING, CC_ADD_LIST_SUCCESS,
  CC_LIST_FAIL, CC_LIST_LOADING, CC_LIST_SUCCESS, ChiefComplaintGetActionTypes, DeleteCaseStateType, DeleteCaseTypes, DeleteDocumentTypes,
  DELETE_CASE_FAIL,
  DELETE_CASE_LOADING,
  DELETE_CASE_SUCCESS,
  DELETE_DOCUMENT_LIST_FAIL, DELETE_DOCUMENT_LIST_LOADING, DELETE_DOCUMENT_LIST_SUCCESS,
  GetChiefComplaintStateType, GetQuestionStateType, GetStepStateType, QuestionGetActionTypes, QUESTION_LIST_FAIL,
  QUESTION_LIST_LOADING, QUESTION_LIST_SUCCESS, SetAddChiefComplaintStateType, SetDeleteDocumentStateType, StepsGetActionTypes, STEPS_LIST_FAIL, STEPS_LIST_LOADING, STEPS_LIST_SUCCESS
} from "./ActionTypes";

const initialStateGetQuestion: GetQuestionStateType = { loading: false, questionValue: [] };
const initialStateGetCC: GetChiefComplaintStateType = { loading: false, ccValue: [] };
const initialStateGetCCAdd: SetAddChiefComplaintStateType = { loading: false, addCC: [], response: Object.assign({}) };
const initialStateGetDeleteDocument: SetDeleteDocumentStateType = { loading: false, deleteDoc: [], response: Object.assign({}),  errRes: "" };
const initialStateGetStep: GetStepStateType = { loading: false, stepValue: [] };
const initialStateDeleteCase: DeleteCaseStateType = { loading: false, message: Object.assign({}), status: Object.assign({}) };

export const getQuestionReducer = (state = initialStateGetQuestion, action: QuestionGetActionTypes): GetQuestionStateType => {
  switch (action.type) {
    case QUESTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        questionValue: action.payload
      };
    case QUESTION_LIST_LOADING:
      return {
        ...state,
        loading: true,
        questionValue: []
      };
    case QUESTION_LIST_FAIL:
      return {
        ...state,
        questionValue: [],
        loading: false
      };
    default:
      return state;
  }
};

export const getCCReducer = (state = initialStateGetCC, action: ChiefComplaintGetActionTypes): GetChiefComplaintStateType => {
  switch (action.type) {
    case CC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ccValue: action.payload
      };
    case CC_LIST_LOADING:
      return {
        ...state,
        loading: true,
        ccValue: []
      };
    case CC_LIST_FAIL:
      return {
        ...state,
        ccValue: [],
        loading: false
      };
    default:
      return state;
  }
};

export const addCCReducer = (state = initialStateGetCCAdd, action: AddChiefComplaintTypes): SetAddChiefComplaintStateType => {
  switch (action.type) {
    case CC_ADD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CC_ADD_LIST_LOADING:
      return {
        ...state,
        loading: true,
        addCC: []
      };
    case CC_ADD_LIST_FAIL:
      return {
        ...state,
        addCC: [],
        loading: false
      };
    default:
      return state;
  }
};

export const deleteDocumentReducer = (state = initialStateGetDeleteDocument, action: DeleteDocumentTypes): SetDeleteDocumentStateType => {
  switch (action.type) {
    case DELETE_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        errRes: "",
      };
    case DELETE_DOCUMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        deleteDoc: []
      };
    case DELETE_DOCUMENT_LIST_FAIL:
      return {
        ...state,
        deleteDoc: [],
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};

export const getSteps = (state = initialStateGetStep, action: StepsGetActionTypes): GetStepStateType => {
  switch (action.type) {
    case STEPS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stepValue: action.payload
      };
    case STEPS_LIST_LOADING:
      return {
        ...state,
        loading: true,
        stepValue: []
      };
    case STEPS_LIST_FAIL:
      return {
        ...state,
        stepValue: [],
        loading: false
      };
    default:
      return state;
  }
};

export const deleteCaseReducer = (state = initialStateDeleteCase, action: DeleteCaseTypes): DeleteCaseStateType => {
  switch (action.type) {
    case DELETE_CASE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status
      };
    case DELETE_CASE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CASE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status
      };
    default:
      return state;
  }
};