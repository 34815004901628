import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentIcon from "@mui/icons-material/Payment";

export const DrawerList = [
  {
    name: `dashboard`,
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    name: `appointments`,
    path: "/appointments",
    icon: <BookOnlineIcon />,
  },
  {
    name: `patient`,
    path: "/patientcaselist",
    icon: <PeopleAltIcon />,
  },
  {
    name: "telehealth",
    path: "/telehealth",
    icon: <PermPhoneMsgIcon />,
  },
  {
    name: "payment",
    path: "/payment",
    icon: <PaymentIcon />,
  },
  {
    name: "needhelp",
    path: "/needhelp",
    icon: <HelpCenterIcon />,
  },
  {
    name: "userprofile",
    path: "/userprofile",
    icon: <AccountCircleIcon />,
  },
  {
    name: "logout",
    path: "/dashboard",
    icon: <LogoutIcon />,
  },
];
export const NavigationList = [
  {
    name: `userprofile`,
    path: "/userprofile",
  },
  {
    name: `codeofconduct`,
    path: "/codeofconduct",
  },
  {
    name: `accdetails`,
    path: "/accountdetails",
  },
];
