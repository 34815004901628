import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { SignDoctorAgreementActionTypes } from "./ActionTypes";
import { SignAgreementBody, SignAgreementRes } from "./Model";
import {
  signDoctorAgreementAPIResClearAction,
  signDoctorAgreementErrorAction,
  signDoctorAgreementLoadingAction,
  signDoctorAgreementSuccessAction,
  signDoctorAgreementAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as SignAgreementRes;
export const signAgreementList = (body: SignAgreementBody) => {
  return function (dispatch: Dispatch<SignDoctorAgreementActionTypes>) {
    dispatch(signDoctorAgreementLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.apiURL + `/doctorapp/api/sign/sign_agreement`, body, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(signDoctorAgreementLoadingAction(false));
        dispatch(
          signDoctorAgreementSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. agreement has been signd successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(signDoctorAgreementLoadingAction(false));
        dispatch(
          signDoctorAgreementErrorAction(
            {} as SignAgreementRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateSignDoctorAgreementAPIResMsg = () => {
  return function (dispatch: Dispatch<SignDoctorAgreementActionTypes>) {
    dispatch(
      signDoctorAgreementAPIMsgAction(apiRes as SignAgreementRes, "", 0)
    );
  };
};

export const clearSignDoctorAgreementListAPIRes = () => {
  return function (dispatch: Dispatch<SignDoctorAgreementActionTypes>) {
    dispatch(
      signDoctorAgreementAPIResClearAction(
        {} as SignAgreementRes,
        "",
        0
      )
    );
  };
};
