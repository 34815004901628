import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../URL";
import { DrAppointmentDecisionActionTypes } from "./ActionTypes";
import {
  DoctorAppointmentDecisionBody,
  DoctorAppointmentDecisionRes,
} from "./Model";
import {
  drAppointmentDecisionAPIResClearAction,
  drAppointmentDecisionErrorAction,
  drAppointmentDecisionLoadingAction,
  drAppointmentDecisionSuccessAction,
  drAppointmentDecisionAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as DoctorAppointmentDecisionRes;
export const drAppointmentDecision = (
  payload: DoctorAppointmentDecisionBody
) => {
  return function (dispatch: Dispatch<DrAppointmentDecisionActionTypes>) {
    dispatch(drAppointmentDecisionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL +
          `/appointmentapp/api/appointment/doctor_accept_appointment`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(drAppointmentDecisionLoadingAction(false));
        dispatch(
          drAppointmentDecisionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : payload?.requestStatus === 1
              ? "Dr. has been accepted the appointment"
              : "Dr. has been rejected the appointment"
          )
        );
      })
      .catch((error) => {
        dispatch(drAppointmentDecisionLoadingAction(false));
        dispatch(
          drAppointmentDecisionErrorAction(
            {} as DoctorAppointmentDecisionRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDrAppointmentDecisionAPIResMsg = () => {
  return function (dispatch: Dispatch<DrAppointmentDecisionActionTypes>) {
    dispatch(
      drAppointmentDecisionAPIMsgAction(
        apiRes as DoctorAppointmentDecisionRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDrAppointmentDecisionAPIRes = () => {
  return function (dispatch: Dispatch<DrAppointmentDecisionActionTypes>) {
    dispatch(
      drAppointmentDecisionAPIResClearAction(
        {} as DoctorAppointmentDecisionRes,
        "",
        "",
        0
      )
    );
  };
};
