import React from "react";
import { Stack } from "@mui/material";
import LegalSummary from "./LegalSummary";
import LegalDetailsList from "./LegalDetails";
export interface Props {
  legalDetailsLoading: boolean | any;
}

export default function LegalDetails({ legalDetailsLoading }: Props) {
  const updateLegalSummaryLoadingValueFromChild = (passedVal: boolean) => {
    legalDetailsLoading(passedVal);
  };
  const updateLegalDetailsListLoadingValueFromChild = (passedVal: boolean) => {
    legalDetailsLoading(passedVal);
  };
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      <LegalSummary
        legalSummaryLoading={updateLegalSummaryLoadingValueFromChild}
      />
      <LegalDetailsList legalDetailsListLoading={updateLegalDetailsListLoadingValueFromChild} />
    </Stack>
  );
}
