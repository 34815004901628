import {
    MeetingListActionTypes,
    MEETING_LIST_FAIL,
    MEETING_LIST_LOADING,
    MEETING_LIST_SUCCESS,
    MeetingListState,
    MEETING_LIST_API_MSG,
    MEETING_LIST_API_RES,
  } from "./ActionTypes";
  import { MeetingListResponse } from "./Model";
  
  const initialStateEditPosts: MeetingListState = {
    loading: false,
    meetingListResponse: {} as MeetingListResponse,
    successMsg: "",
    errorMsg: "",
    status: 0,
  };
  export const meetingListReducer = (
    state = initialStateEditPosts,
    action: MeetingListActionTypes
  ): MeetingListState => {
    switch (action.type) {
      case MEETING_LIST_LOADING:
        return {
          ...state,
          loading: action.loading,
        };
      case MEETING_LIST_SUCCESS:
        return {
          ...state,
          meetingListResponse: action.payload,
          successMsg: action.successMsg,
        };
      case MEETING_LIST_FAIL:
        return {
          ...state,
          meetingListResponse: action.payload,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case MEETING_LIST_API_MSG:
        return {
          ...state,
          meetingListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      case MEETING_LIST_API_RES:
        return {
          ...state,
          meetingListResponse: action.payload,
          successMsg: action.successMsg,
          errorMsg: action.errorMsg,
          status: action.status,
        };
      default:
        return state;
    }
  };