import { GetSectionTemplateListRes } from "./Model";

export const GET_SECTION_TEMPLATE_LIST_LOADING =
  "GET_SECTION_TEMPLATE_LIST_LOADING";
export const GET_SECTION_TEMPLATE_LIST_SUCCESS =
  "GET_SECTION_TEMPLATE_LIST_SUCCESS";
export const GET_SECTION_TEMPLATE_LIST_FAIL =
  "GET_SECTION_TEMPLATE_LIST_FAIL";
export const GET_SECTION_TEMPLATE_LIST_DATA =
  "GET_SECTION_TEMPLATE_LIST_DATA";

export interface GetSectionTemplateDetails {
  GetSectionTemplateListRes: GetSectionTemplateListRes;
  loading: boolean;
  errRes: string;
}
export interface GetSectionTemplateLoading {
  type: typeof GET_SECTION_TEMPLATE_LIST_LOADING;
}
export interface GetSectionTemplateSuccess {
  type: typeof GET_SECTION_TEMPLATE_LIST_SUCCESS;
  payload: GetSectionTemplateListRes;
  errRes: string;
}
export interface GetSectionTemplateFail {
  type: typeof GET_SECTION_TEMPLATE_LIST_FAIL;
  payload: GetSectionTemplateListRes;
  errRes: string;
}
interface GetSectionTemplateAction {
  type: typeof GET_SECTION_TEMPLATE_LIST_DATA;
  payload: GetSectionTemplateListRes;
  errRes: string;
}

export type GetSectionTemplateListActionTypes =
  | GetSectionTemplateAction
  | GetSectionTemplateLoading
  | GetSectionTemplateSuccess
  | GetSectionTemplateFail;
