import {
    getTelehealthListDetails,
    getTelehealthListActionTypes,
    TELEHEALTH_DETAIL_LIST_FAIL,
    TELEHEALTH_DETAIL_LIST_LOADING,
    TELEHEALTH_DETAIL_LIST_SUCCESS,
  } from "./ActionTypes";
  import { GetTelehealthgListRes } from "./Model";
  
  const initialStateGetPosts: getTelehealthListDetails = {
    loading: false,
    getTelehealthListRes: {} as GetTelehealthgListRes,
    errRes: "",
  };
  export const getTelehealthListReducer = (
    state = initialStateGetPosts,
    action: getTelehealthListActionTypes
  ): getTelehealthListDetails => {
    switch (action.type) {
      case TELEHEALTH_DETAIL_LIST_LOADING:
        return {
          ...state,
          loading: true,
        };
      case TELEHEALTH_DETAIL_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          getTelehealthListRes: action.payload,
          errRes: "",
        };
      case TELEHEALTH_DETAIL_LIST_FAIL:
        return {
          ...state,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  