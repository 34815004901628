import { EditProfessionalSummaryRes } from "./Model";
export const EDIT_DOCTOR_PROFESSIONAL_SUMMARY_LOADING =
  "EDIT_DOCTOR_PROFESSIONAL_SUMMARY_LOADING";
export const EDIT_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS =
  "EDIT_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS";
export const EDIT_DOCTOR_PROFESSIONAL_SUMMARY_FAIL =
  "EDIT_DOCTOR_PROFESSIONAL_SUMMARY_FAIL";
export const EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG =
  "EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG";
export const EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_RES =
  "EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_RES";

export interface EditDoctorProfessionalSummaryState {
  editDoctorProfessionalSummaryRes: EditProfessionalSummaryRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDoctorProfessionalSummaryLoading {
  type: typeof EDIT_DOCTOR_PROFESSIONAL_SUMMARY_LOADING;
  loading: boolean;
}
export interface EditDoctorProfessionalSummarySuccess {
  type: typeof EDIT_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS;
  payload: EditProfessionalSummaryRes;
  successMsg: string;
}
export interface EditDoctorProfessionalSummaryFail {
  type: typeof EDIT_DOCTOR_PROFESSIONAL_SUMMARY_FAIL;
  payload: EditProfessionalSummaryRes;
  errorMsg: string;
  status: number;
}
export interface EditDoctorProfessionalSummaryAPIMsg {
  type: typeof EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG;
  payload: EditProfessionalSummaryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDoctorProfessionalSummaryAPIRes {
  type: typeof EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_RES;
  payload: EditProfessionalSummaryRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDoctorProfessionalSummaryActionTypes =
  | EditDoctorProfessionalSummaryLoading
  | EditDoctorProfessionalSummarySuccess
  | EditDoctorProfessionalSummaryFail
  | EditDoctorProfessionalSummaryAPIMsg
  | EditDoctorProfessionalSummaryAPIRes;
