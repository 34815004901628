import { GetTaskListRes } from "./Model";
export const GET_DOCTOR_TASK_LIST_LOADING =
  "GET_DOCTOR_TASK_LIST_LOADING";
export const GET_DOCTOR_TASK_LIST_SUCCESS =
  "GET_DOCTOR_TASK_LIST_SUCCESS";
export const GET_DOCTOR_TASK_LIST_FAIL = "GET_DOCTOR_TASK_LIST_FAIL";
export const GET_DOCTOR_TASK_LIST_API_MSG =
  "GET_DOCTOR_TASK_LIST_API_MSG";
export const GET_DOCTOR_TASK_LIST_API_RES =
  "GET_DOCTOR_TASK_LIST_API_RES";

export interface GetDoctorTaskListState {
  getDoctorTaskListRes: GetTaskListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTaskListLoading {
  type: typeof GET_DOCTOR_TASK_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorTaskListSuccess {
  type: typeof GET_DOCTOR_TASK_LIST_SUCCESS;
  payload: GetTaskListRes;
  successMsg: string;
}
export interface GetDoctorTaskListFail {
  type: typeof GET_DOCTOR_TASK_LIST_FAIL;
  payload: GetTaskListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTaskListAPIMsg {
  type: typeof GET_DOCTOR_TASK_LIST_API_MSG;
  payload: GetTaskListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorTaskListAPIRes {
  type: typeof GET_DOCTOR_TASK_LIST_API_RES;
  payload: GetTaskListRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorTaskListActionTypes =
  | GetDoctorTaskListLoading
  | GetDoctorTaskListSuccess
  | GetDoctorTaskListFail
  | GetDoctorTaskListAPIMsg
  | GetDoctorTaskListAPIRes;
