import { JoiningFeeRes } from "./Model";
export const JOINING_FEE_LOADING = "JOINING_FEE_LOADING";
export const JOINING_FEE_SUCCESS = "JOINING_FEE_SUCCESS";
export const JOINING_FEE_FAIL = "JOINING_FEE_FAIL";
export const JOINING_FEE_DATA = "JOINING_FEE_DATA";
export const JOINING_FEE_UPDATE_API_MSG =
  "JOINING_FEE_UPDATE_API_MSG";
export const JOINING_FEE_UPDATE_API_RES =
  "JOINING_FEE_UPDATE_API_RES";

export interface JoiningFeeState {
  joiningFee: JoiningFeeRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface JoiningFeeLoading {
  type: typeof JOINING_FEE_LOADING;
  loading: boolean;
}
export interface JoiningFeeSuccess {
  type: typeof JOINING_FEE_SUCCESS;
  payload: JoiningFeeRes;
  successMsg: string;
}
export interface JoiningFeeFail {
  type: typeof JOINING_FEE_FAIL;
  payload: JoiningFeeRes;
  errorMsg: string;
  status: number;
}
export interface JoiningFeeUpdateAPIMsg {
  type: typeof JOINING_FEE_UPDATE_API_MSG;
  payload: JoiningFeeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface JoiningFeeUpdateAPIRes {
  type: typeof JOINING_FEE_UPDATE_API_RES;
  payload: JoiningFeeRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type JoiningFeeActionTypes =
  | JoiningFeeLoading
  | JoiningFeeSuccess
  | JoiningFeeFail
  | JoiningFeeUpdateAPIMsg
  | JoiningFeeUpdateAPIRes;

