import {  DeleteSummaryListRes } from "./Model";
export const  DELETE_SUMMARYLIST_SUBMIT_LIST_LOADING = " DELETE_SUMMARYLIST_SUBMIT_LIST_LOADING";
export const  DELETE_SUMMARYLIST_SUBMIT_LIST_SUCCESS = " DELETE_SUMMARYLIST_SUBMIT_LIST_SUCCESS";
export const  DELETE_SUMMARYLIST_SUBMIT_LIST_FAIL = " DELETE_SUMMARYLIST_SUBMIT_LIST_FAIL";
export const  DELETE_SUMMARYLIST_SUBMIT_LIST_DATA = " DELETE_SUMMARYLIST_SUBMIT_LIST_DATA";
export const  DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG =
  " DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG";
export const  DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES =
  " DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES";

export interface  DeleteSummaryListState {
deleteSummaryListResponse:  DeleteSummaryListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  DeleteSummaryListLoading {
  type: typeof  DELETE_SUMMARYLIST_SUBMIT_LIST_LOADING;
  loading: boolean;
}
export interface  DeleteSummaryListSuccess {
  type: typeof  DELETE_SUMMARYLIST_SUBMIT_LIST_SUCCESS;
  payload:  DeleteSummaryListRes;
  successMsg: string;
}
export interface  DeleteSummaryListFail {
  type: typeof  DELETE_SUMMARYLIST_SUBMIT_LIST_FAIL;
  payload:  DeleteSummaryListRes;
  errorMsg: string;
  status: number;
}
export interface  DeleteSummaryListUpdateAPIMsg {
  type: typeof  DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG;
  payload:  DeleteSummaryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface  DeleteSummaryListUpdateAPIRes {
  type: typeof  DELETE_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES;
  payload:  DeleteSummaryListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type  DeleteSummaryListActionTypes =
  |  DeleteSummaryListLoading
  |  DeleteSummaryListSuccess
  |  DeleteSummaryListFail
  |  DeleteSummaryListUpdateAPIMsg
  |  DeleteSummaryListUpdateAPIRes;
