import { EmailOtpLoginRes } from "./Model";
export const EMAIL_OTP_LOGIN_LOADING = "EMAIL_OTP_LOGIN_LOADING";
export const EMAIL_OTP_LOGIN_SUCCESS = "EMAIL_OTP_LOGIN_SUCCESS";
export const EMAIL_OTP_LOGIN_FAIL = "EMAIL_OTP_LOGIN_FAIL";
export const EMAIL_OTP_LOGIN_UPDATE_API_MSG = "EMAIL_OTP_LOGIN_UPDATE_API_MSG";
export const EMAIL_OTP_LOGIN_UPDATE_API_RES = "EMAIL_OTP_LOGIN_UPDATE_API_RES";

export interface EmailOTPLoginState {
  emailOTPLoginRes: EmailOtpLoginRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EmailOTPLoginLoading {
  type: typeof EMAIL_OTP_LOGIN_LOADING;
  loading: boolean;
}
export interface EmailOTPLoginSuccess {
  type: typeof EMAIL_OTP_LOGIN_SUCCESS;
  payload: EmailOtpLoginRes;
  successMsg: string;
}
export interface EmailOTPLoginFail {
  type: typeof EMAIL_OTP_LOGIN_FAIL;
  payload: EmailOtpLoginRes;
  errorMsg: string;
  status: number;
}
export interface EmailOTPLoginUpdateAPIMsg {
  type: typeof EMAIL_OTP_LOGIN_UPDATE_API_MSG;
  payload: EmailOtpLoginRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EmailOTPLoginUpdateAPIRes {
  type: typeof EMAIL_OTP_LOGIN_UPDATE_API_RES;
  payload: EmailOtpLoginRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EmailOTPLoginActionTypes =
  | EmailOTPLoginLoading
  | EmailOTPLoginSuccess
  | EmailOTPLoginFail
  | EmailOTPLoginUpdateAPIMsg
  | EmailOTPLoginUpdateAPIRes;
