import { AddPatientDataRes } from "./Model";
export const ADD_PATIENT_DATA_LOADING = "ADD_PATIENT_DATA_LOADING";
export const ADD_PATIENT_DATA_SUCCESS = "ADD_PATIENT_DATA_SUCCESS";
export const ADD_PATIENT_DATA_FAIL = "ADD_PATIENT_DATA_FAIL";
export const ADD_PATIENT_DATA_API_MSG = "ADD_PATIENT_DATA_API_MSG";
export const ADD_PATIENT_DATA_API_RES = "ADD_PATIENT_DATA_API_RES";

export interface AddPatientDataState {
  addPatientDataRes: AddPatientDataRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDataLoading {
  type: typeof ADD_PATIENT_DATA_LOADING;
  loading: boolean;
}
export interface AddPatientDataSuccess {
  type: typeof ADD_PATIENT_DATA_SUCCESS;
  payload: AddPatientDataRes;
  successMsg: string;
}
export interface AddPatientDataFail {
  type: typeof ADD_PATIENT_DATA_FAIL;
  payload: AddPatientDataRes;
  errorMsg: string;
  status: number;
}
export interface AddPatientDataUpdateAPIMsg {
  type: typeof ADD_PATIENT_DATA_API_MSG;
  payload: AddPatientDataRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientDataUpdateAPIRes {
  type: typeof ADD_PATIENT_DATA_API_RES;
  payload: AddPatientDataRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddPatientDataActionTypes =
  | AddPatientDataLoading
  | AddPatientDataSuccess
  | AddPatientDataFail
  | AddPatientDataUpdateAPIMsg
  | AddPatientDataUpdateAPIRes;
