import { GetLegalTypeListRes } from "./Model";
export const GET_DOCTOR_LEGAL_TYPE_LIST_LOADING =
  "GET_DOCTOR_LEGAL_TYPE_LIST_LOADING";
export const GET_DOCTOR_LEGAL_TYPE_LIST_SUCCESS =
  "GET_DOCTOR_LEGAL_TYPE_LIST_SUCCESS";
export const GET_DOCTOR_LEGAL_TYPE_LIST_FAIL =
  "GET_DOCTOR_LEGAL_TYPE_LIST_FAIL";
export const GET_DOCTOR_LEGAL_TYPE_LIST_API_MSG =
  "GET_DOCTOR_LEGAL_TYPE_LIST_API_MSG";
export const GET_DOCTOR_LEGAL_TYPE_LIST_API_RES =
  "GET_DOCTOR_LEGAL_TYPE_LIST_API_RES";

export interface GetDoctorLegalTypeListState {
  getDoctorLegalTypeListRes: GetLegalTypeListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalTypeListLoading {
  type: typeof GET_DOCTOR_LEGAL_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface GetDoctorLegalTypeListSuccess {
  type: typeof GET_DOCTOR_LEGAL_TYPE_LIST_SUCCESS;
  payload: GetLegalTypeListRes;
  successMsg: string;
}
export interface GetDoctorLegalTypeListFail {
  type: typeof GET_DOCTOR_LEGAL_TYPE_LIST_FAIL;
  payload: GetLegalTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalTypeListAPIMsg {
  type: typeof GET_DOCTOR_LEGAL_TYPE_LIST_API_MSG;
  payload: GetLegalTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLegalTypeListAPIRes {
  type: typeof GET_DOCTOR_LEGAL_TYPE_LIST_API_RES;
  payload: GetLegalTypeListRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorLegalTypeListActionTypes =
  | GetDoctorLegalTypeListLoading
  | GetDoctorLegalTypeListSuccess
  | GetDoctorLegalTypeListFail
  | GetDoctorLegalTypeListAPIMsg
  | GetDoctorLegalTypeListAPIRes;
