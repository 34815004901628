import React, { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import { InsuranceList } from "redux/UserProfile/Insurance/GetInsuranceByDoctor/Model";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import MWExceptionList from "components/MWExceptionList";
import dayjs from "dayjs";
import { getInsuranceList } from "redux/UserProfile/Insurance/GetInsuranceByDoctor/API";
import { ModifiedInsuranceList } from "./Model";
import DocumentViewer from "components/DocumentViewer";
import UpdateInsurance from "./UpdateInsurance";

export interface Props {
  insuranceDetailsLoading: boolean | any;
}

export default function InsuranceDetails({ insuranceDetailsLoading }: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInsuranceList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInsuranceListRes = useSelector(
    (state: AppState) => state.getInsuranceListRes
  );
  const [insuranceList, setInsuranceList] = React.useState(
    [] as ModifiedInsuranceList[]
  );
  useEffect(() => {
    insuranceDetailsLoading(getInsuranceListRes?.loading);
    if (
      getInsuranceListRes?.getDoctorInsuranceListRes?.insuranceList !==
      undefined
    ) {
      if (
        getInsuranceListRes?.getDoctorInsuranceListRes?.insuranceList.length > 0
      ) {
        let InsuranceListItem =
          getInsuranceListRes?.getDoctorInsuranceListRes?.insuranceList.map(
            (element: InsuranceList) =>
              ({
                id: element.id,
                supportingDocument:
                  element?.supportingDocuments !== undefined
                    ? element?.supportingDocuments !== null
                      ? element?.supportingDocuments[0]?.document
                      : ""
                    : "",
                tableDescription:
                  element?.description !== undefined
                    ? element?.description !== ""
                      ? element?.description.substring(0, 30)
                      : ""
                    : "",
                description:
                  element?.description !== undefined
                    ? element?.description !== ""
                      ? element?.description
                      : ""
                    : "",
                insuranceNumber:
                  element?.insuranceNumber !== undefined
                    ? element?.insuranceNumber !== ""
                      ? element?.insuranceNumber
                      : ""
                    : "",
                amount:
                  element?.amount !== undefined
                    ? element?.amount !== null
                      ? element?.amount
                      : 0
                    : 0,
                fromDate:
                  element?.fromDate !== undefined
                    ? element?.fromDate !== null
                      ? dayjs(element.fromDate).format("DD-MM-YYYY")
                      : null
                    : null,
                toDate:
                  element?.toDate !== undefined
                    ? element?.toDate !== null
                      ? dayjs(element.toDate).format("DD-MM-YYYY")
                      : null
                    : null,
              } as ModifiedInsuranceList)
          );
        setInsuranceList(InsuranceListItem);
      } else setInsuranceList([] as ModifiedInsuranceList[]);
    } else setInsuranceList([] as ModifiedInsuranceList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInsuranceListRes]);

  const insuranceTableColumn: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("appointmentpage.doc", { ns: ["home"] })}`,
      minWidth: 100,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DescriptionIcon />}
          variant="outlined"
          onClick={() => {
            setViewInsuranceDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "tableDescription",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })}`,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "insuranceNumber",
      headerName: `${t("userprofilepage.insno", { ns: ["home"] })}`,
      minWidth: 250,
    },
    {
      field: "amount",
      headerName: `${t("userprofilepage.inscovamt", { ns: ["home"] })}`,
      minWidth: 200,
    },
    {
      field: "fromDate",
      headerName: `${t("userprofilepage.fromdate", { ns: ["home"] })}`,
      minWidth: 100,
    },
    {
      field: "toDate",
      headerName: `${t("userprofilepage.validdate", { ns: ["home"] })}`,
      minWidth: 100,
    },
    {
      field: "id",
      headerName: `${t("userprofilepage.edit", { ns: ["home"] })}`,
      minWidth: 110,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setEditItem(params.row);
            setUpdateInsuranceDetailsDialog(true);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("userprofilepage.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  const [editItem, setEditItem] = useState({} as ModifiedInsuranceList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const [updateInsuranceDetailsDialog, setUpdateInsuranceDetailsDialog] =
    useState(false);
  const updateInsuranceDetailsDialogValFromChild = (passedVal: boolean) => {
    setUpdateInsuranceDetailsDialog(passedVal);
  };

  const [viewInsuranceDocumentDialog, setViewInsuranceDocumentDialog] =
    useState(false);
  const updateViewInsuranceDocumentDialogValFromChild = (
    passedVal: boolean
  ) => {
    setViewInsuranceDocumentDialog(passedVal);
  };
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Typography variant="h6">
                  {t("userprofilepage.insurancedetail", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => setUpdateInsuranceDetailsDialog(true)}
                      sx={{ textTransform: "none" }}
                    >
                      {
                        t("userprofilepage.addinsurancedetail", {
                          ns: ["home"],
                        })!
                      }
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {insuranceList && insuranceList.length ? (
            <DataGrid
              rows={insuranceList}
              columns={insuranceTableColumn}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {updateInsuranceDetailsDialog ? (
        <UpdateInsurance
          dialogEnable={updateInsuranceDetailsDialog}
          dialogUpdateState={updateInsuranceDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewInsuranceDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewInsuranceDocumentDialog}
          dialogUpdateState={updateViewInsuranceDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
