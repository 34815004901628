import {
  UpdateDoctorCoverLetterActionTypes,
  UPDATE_DOCTOR_COVER_LETTER_FAIL,
  UPDATE_DOCTOR_COVER_LETTER_LOADING,
  UPDATE_DOCTOR_COVER_LETTER_SUCCESS,
  UpdateDoctorCoverLetterState,
  UPDATE_DOCTOR_COVER_LETTER_API_MSG,
  UPDATE_DOCTOR_COVER_LETTER_API_RES,
} from "./ActionTypes";
import { EditCoverLetterRes } from "./Model";

const initialStateEditPosts: UpdateDoctorCoverLetterState = {
  loading: false,
  updateDoctorCoverLetterRes: {} as EditCoverLetterRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const updateDoctorCoverLetterReducer = (
  state = initialStateEditPosts,
  action: UpdateDoctorCoverLetterActionTypes
): UpdateDoctorCoverLetterState => {
  switch (action.type) {
    case UPDATE_DOCTOR_COVER_LETTER_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case UPDATE_DOCTOR_COVER_LETTER_SUCCESS:
      return {
        ...state,
        updateDoctorCoverLetterRes: action.payload,
        successMsg: action.successMsg,
      };
    case UPDATE_DOCTOR_COVER_LETTER_FAIL:
      return {
        ...state,
        updateDoctorCoverLetterRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_DOCTOR_COVER_LETTER_API_MSG:
      return {
        ...state,
        updateDoctorCoverLetterRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case UPDATE_DOCTOR_COVER_LETTER_API_RES:
      return {
        ...state,
        updateDoctorCoverLetterRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
