import {
    MEETING_LIST_FAIL,
    MEETING_LIST_LOADING,
    MEETING_LIST_SUCCESS,
    MEETING_LIST_API_MSG,
    MEETING_LIST_API_RES,
    MeetingListActionTypes,
  } from "./ActionTypes";
  import { MeetingListResponse } from "./Model";
  
  export const meetingListLoadingAction = (
    loading: boolean
  ): MeetingListActionTypes => {
    return {
      type: MEETING_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const meetingListSuccessAction = (
    meetingListResponse: MeetingListResponse,
    successMsg: string
  ): MeetingListActionTypes => {
    return {
      type: MEETING_LIST_SUCCESS,
      payload: meetingListResponse,
      successMsg: successMsg,
    };
  };
  
  export const meetingListErrorAction = (
    meetingListResponse: MeetingListResponse,
    errMsg: string,
    status: number
  ): MeetingListActionTypes => {
    return {
      type: MEETING_LIST_FAIL,
      payload: meetingListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const meetingListAPIMsgAction = (
    meetingListResponse: MeetingListResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): MeetingListActionTypes => {
    return {
      type: MEETING_LIST_API_MSG,
      payload: meetingListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const meetingListAPIResClearAction = (
    meetingListResponse: MeetingListResponse,
    successMsg: string,
    errMsg: string,
    status: number
  ): MeetingListActionTypes => {
    return {
      type: MEETING_LIST_API_RES,
      payload: meetingListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };