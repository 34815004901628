import {
    GET_SUMMARYLIST_SUBMIT_LIST_FAIL,
    GET_SUMMARYLIST_SUBMIT_LIST_LOADING,
    GET_SUMMARYLIST_SUBMIT_LIST_SUCCESS,
    GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG,
    GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES,
    GetSummaryListActionTypes,
  } from "./ActionTypes";
  import { GetSummaryListRes } from "./Model";
  
  export const getSummaryListLoadingAction = (
    loading: boolean
  ): GetSummaryListActionTypes => {
    return {
      type: GET_SUMMARYLIST_SUBMIT_LIST_LOADING,
      loading: loading,
    };
  };
  
  export const getSummaryListSuccessAction = (
    getSummaryListResponse: GetSummaryListRes,
    successMsg: string
  ): GetSummaryListActionTypes => {
    return {
      type: GET_SUMMARYLIST_SUBMIT_LIST_SUCCESS,
      payload: getSummaryListResponse,
      successMsg: successMsg,
    };
  };
  
  export const getSummaryListErrorAction = (
    getSummaryListResponse: GetSummaryListRes,
    errMsg: string,
    status: number
  ): GetSummaryListActionTypes => {
    return {
      type: GET_SUMMARYLIST_SUBMIT_LIST_FAIL,
      payload: getSummaryListResponse,
      errorMsg: errMsg,
      status: status,
    };
  };
  export const getSummaryListUpdateAPIMsgAction = (
    getSummaryListResponse: GetSummaryListRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): GetSummaryListActionTypes => {
    return {
      type: GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_MSG,
      payload: getSummaryListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  
  export const getSummaryListAPIResClearAction = (
    getSummaryListResponse: GetSummaryListRes,
    successMsg: string,
    errMsg: string,
    status: number
  ): GetSummaryListActionTypes => {
    return {
      type: GET_SUMMARYLIST_SUBMIT_LIST_UPDATE_API_RES,
      payload: getSummaryListResponse,
      successMsg: successMsg,
      errorMsg: errMsg,
      status: status,
    };
  };
  