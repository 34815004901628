import { emailOtpGenRes } from "./Model";
export const EMAIL_OTP_GEN_LOADING = "EMAIL_OTP_GEN_LOADING";
export const EMAIL_OTP_GEN_SUCCESS = "EMAIL_OTP_GEN_SUCCESS";
export const EMAIL_OTP_GEN_FAIL = "EMAIL_OTP_GEN_FAIL";
export const EMAIL_OTP_GEN_UPDATE_API_MSG = "EMAIL_OTP_GEN_UPDATE_API_MSG";
export const EMAIL_OTP_GEN_UPDATE_API_RES = "EMAIL_OTP_GEN_UPDATE_API_RES";

export interface AddScheduleState {
  emailOTPGenRes: emailOtpGenRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddScheduleLoading {
  type: typeof EMAIL_OTP_GEN_LOADING;
  loading: boolean;
}
export interface AddScheduleSuccess {
  type: typeof EMAIL_OTP_GEN_SUCCESS;
  payload: emailOtpGenRes;
  successMsg: string;
}
export interface AddScheduleFail {
  type: typeof EMAIL_OTP_GEN_FAIL;
  payload: emailOtpGenRes;
  errorMsg: string;
  status: number;
}
export interface AddScheduleUpdateAPIMsg {
  type: typeof EMAIL_OTP_GEN_UPDATE_API_MSG;
  payload: emailOtpGenRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddScheduleUpdateAPIRes {
  type: typeof EMAIL_OTP_GEN_UPDATE_API_RES;
  payload: emailOtpGenRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddScheduleActionTypes =
  | AddScheduleLoading
  | AddScheduleSuccess
  | AddScheduleFail
  | AddScheduleUpdateAPIMsg
  | AddScheduleUpdateAPIRes;
