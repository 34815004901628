import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorInsuranceListActionTypes } from "./ActionTypes";
import { GetInsuranceListRes } from "./Model";
import {
  getDoctorInsuranceListAPIResClearAction,
  getDoctorInsuranceListErrorAction,
  getDoctorInsuranceListLoadingAction,
  getDoctorInsuranceListSuccessAction,
  getDoctorInsuranceListAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetInsuranceListRes;
export const getInsuranceList = () => {
  return function (dispatch: Dispatch<GetDoctorInsuranceListActionTypes>) {
    dispatch(getDoctorInsuranceListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL +
          `/doctorapp/api/doctorinsurance/get_insurance_details_by_doctor`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorInsuranceListLoadingAction(false));
        dispatch(
          getDoctorInsuranceListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. insurance list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorInsuranceListLoadingAction(false));
        dispatch(
          getDoctorInsuranceListErrorAction(
            {} as GetInsuranceListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorInsuranceListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorInsuranceListActionTypes>) {
    dispatch(
      getDoctorInsuranceListAPIMsgAction(
        apiRes as GetInsuranceListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDoctorInsuranceListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorInsuranceListActionTypes>) {
    dispatch(
      getDoctorInsuranceListAPIResClearAction(
        {} as GetInsuranceListRes,
        "",
        "",
        0
      )
    );
  };
};
