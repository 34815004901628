import { DeactivateMeeting, MeetingList } from "./Model";

// Doctor Dropdown List
export const DOCTOR_LIST_LOADING = "DOCTOR_LIST_LOADING";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";
export const DOCTOR_LIST_TYPE = "DOCTOR_LIST_TYPE";

// Meeting List
export const MEETING_LIST_LOADING = "MEETING_LIST_LOADING";
export const MEETING_LIST_SUCCESS = "MEETING_LIST_SUCCESS";
export const MEETING_LIST_FAIL = "MEETING_LIST_FAIL";
export const MEETING_LIST_TYPE = "MEETING_LIST_TYPE";

export interface MeetingListStateType {
  loading: boolean;
  meetingList: MeetingList[];
  message: string;
  status: number;
  errRes: string;
}
export interface MeetingListLoading {
  type: typeof MEETING_LIST_LOADING;
}
export interface MeetingListSuccess {
  type: typeof MEETING_LIST_SUCCESS;
  meetingList: MeetingList[];
  message: string;
  status: number;
  errRes: string;
}
export interface MeetingListFail {
  type: typeof MEETING_LIST_FAIL;
  message: string;
  status: number;
  errRes: string;
}

interface MeetingListType {
  type: typeof MEETING_LIST_TYPE;
  meetingList: MeetingList[];
  message: string;
  status: number;
  errRes: string;
}

export type MeetingListTypes =
  | MeetingListType
  | MeetingListLoading
  | MeetingListSuccess
  | MeetingListFail;
// Create or Schedule Meeting
export const CREATE_OR_SCHEDULE_LOADING = "CREATE_OR_SCHEDULE_LOADING";
export const CREATE_OR_SCHEDULE_SUCCESS = "CREATE_OR_SCHEDULE_SUCCESS";
export const CREATE_OR_SCHEDULE_FAIL = "CREATE_OR_SCHEDULE_FAIL";
export const CREATE_OR_SCHEDULE_TYPE = "CREATE_OR_SCHEDULE_TYPE";

export interface CreateOrScheduleMeetingStateType {
  loading: boolean;
  message: string;
  status: number;
  errRes: string;
}
export interface CreateOrScheduleMeetingLoading {
  type: typeof CREATE_OR_SCHEDULE_LOADING;
}
export interface CreateOrScheduleMeetingSuccess {
  type: typeof CREATE_OR_SCHEDULE_SUCCESS;
  message: string;
  status: number;
  errRes: string;
}
export interface CreateOrScheduleMeetingFail {
  type: typeof CREATE_OR_SCHEDULE_FAIL;
  message: string;
  status: number;
  errRes: string;
}

interface CreateOrScheduleMeetingType {
  type: typeof CREATE_OR_SCHEDULE_TYPE;
  message: string;
  status: number;
  errRes: string;
}

export type CreateOrScheduleMeetingTypes =
  | CreateOrScheduleMeetingType
  | CreateOrScheduleMeetingLoading
  | CreateOrScheduleMeetingSuccess
  | CreateOrScheduleMeetingFail;
// Delete Meeting
export const DELETE_MEETING_LOADING = "DELETE_MEETING_LOADING";
export const DELETE_MEETING_SUCCESS = "DELETE_MEETING_SUCCESS";
export const DELETE_MEETING_FAIL = "DELETE_MEETING_FAIL";
export const DELETE_MEETING_TYPE = "DELETE_MEETING_TYPE";

export interface DeleteMeetingStateType {
  loading: boolean;
  payload: DeactivateMeeting;
  errMsg: string;
}
export interface DeleteMeetingLoading {
  type: typeof DELETE_MEETING_LOADING;
}
export interface DeleteMeetingSuccess {
  type: typeof DELETE_MEETING_SUCCESS;
  payload: DeactivateMeeting;
  errMsg: string;
}
export interface DeleteMeetingFail {
  type: typeof DELETE_MEETING_FAIL;
  payload: DeactivateMeeting;
  errMsg: string;
}

interface DeleteMeetingType {
  type: typeof DELETE_MEETING_TYPE;
  payload: DeactivateMeeting;
  errMsg: string;
}

export type DeleteMeetingTypes =
  | DeleteMeetingType
  | DeleteMeetingLoading
  | DeleteMeetingSuccess
  | DeleteMeetingFail;
