import { EditAwardRes } from "./Model";
export const EDIT_DR_AWARD_LOADING = "EDIT_DR_AWARD_LOADING";
export const EDIT_DR_AWARD_SUCCESS = "EDIT_DR_AWARD_SUCCESS";
export const EDIT_DR_AWARD_FAIL = "EDIT_DR_AWARD_FAIL";
export const EDIT_DR_AWARD_API_MSG = "EDIT_DR_AWARD_API_MSG";
export const EDIT_DR_AWARD_API_RES = "EDIT_DR_AWARD_API_RES";

export interface EditDrAwardState {
  editDrAwardRes: EditAwardRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrAwardLoading {
  type: typeof EDIT_DR_AWARD_LOADING;
  loading: boolean;
}
export interface EditDrAwardSuccess {
  type: typeof EDIT_DR_AWARD_SUCCESS;
  payload: EditAwardRes;
  successMsg: string;
}
export interface EditDrAwardFail {
  type: typeof EDIT_DR_AWARD_FAIL;
  payload: EditAwardRes;
  errorMsg: string;
  status: number;
}
export interface EditDrAwardAPIMsg {
  type: typeof EDIT_DR_AWARD_API_MSG;
  payload: EditAwardRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditDrAwardAPIRes {
  type: typeof EDIT_DR_AWARD_API_RES;
  payload: EditAwardRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditDrAwardActionTypes =
  | EditDrAwardLoading
  | EditDrAwardSuccess
  | EditDrAwardFail
  | EditDrAwardAPIMsg
  | EditDrAwardAPIRes;
