import { EditCoverLetterRes } from "./Model";
export const UPDATE_DOCTOR_COVER_LETTER_LOADING =
  "UPDATE_DOCTOR_COVER_LETTER_LOADING";
export const UPDATE_DOCTOR_COVER_LETTER_SUCCESS =
  "UPDATE_DOCTOR_COVER_LETTER_SUCCESS";
export const UPDATE_DOCTOR_COVER_LETTER_FAIL =
  "UPDATE_DOCTOR_COVER_LETTER_FAIL";
export const UPDATE_DOCTOR_COVER_LETTER_API_MSG =
  "UPDATE_DOCTOR_COVER_LETTER_API_MSG";
export const UPDATE_DOCTOR_COVER_LETTER_API_RES =
  "UPDATE_DOCTOR_COVER_LETTER_API_RES";

export interface UpdateDoctorCoverLetterState {
  updateDoctorCoverLetterRes: EditCoverLetterRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorCoverLetterLoading {
  type: typeof UPDATE_DOCTOR_COVER_LETTER_LOADING;
  loading: boolean;
}
export interface UpdateDoctorCoverLetterSuccess {
  type: typeof UPDATE_DOCTOR_COVER_LETTER_SUCCESS;
  payload: EditCoverLetterRes;
  successMsg: string;
}
export interface UpdateDoctorCoverLetterFail {
  type: typeof UPDATE_DOCTOR_COVER_LETTER_FAIL;
  payload: EditCoverLetterRes;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorCoverLetterAPIMsg {
  type: typeof UPDATE_DOCTOR_COVER_LETTER_API_MSG;
  payload: EditCoverLetterRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface UpdateDoctorCoverLetterAPIRes {
  type: typeof UPDATE_DOCTOR_COVER_LETTER_API_RES;
  payload: EditCoverLetterRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type UpdateDoctorCoverLetterActionTypes =
  | UpdateDoctorCoverLetterLoading
  | UpdateDoctorCoverLetterSuccess
  | UpdateDoctorCoverLetterFail
  | UpdateDoctorCoverLetterAPIMsg
  | UpdateDoctorCoverLetterAPIRes;
