import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from "@mui/material";
import { t } from "i18next";
import MWExceptionList from "components/MWExceptionList";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { QuestionLisyBody } from "redux/PatientDoctorInteraction/QuestionList/Model";
import { questionListApi } from "redux/PatientDoctorInteraction/QuestionList/API";
import { ModifiedQuestionList } from "./Model";
import QuestionDialog from "./QuestionDialog";
import { GetDoctorDetailsRes } from "redux/jwtDoctorLogIn/doctorLoginDetails/Model";
import RefreshIcon from "@mui/icons-material/Refresh";
import { toggleAppBarLoading } from "redux/appLoader/Actions";

export default function QuestionAnswer() {
  const dispatch = useDispatch();

  let userDetails = JSON.parse(
    localStorage.getItem("loginDetails")!
  ) as GetDoctorDetailsRes;

  const DoctorID = userDetails !== undefined ? userDetails?.doctorId : null;
  const { caseid } = useParams() as {
    caseid: string;
  };

  const questionListValue = useSelector(
    (state: AppState) => state.questionListValueUnderCase
  );

  const [questionList, setQuestionList] = useState(
    [] as ModifiedQuestionList[]
  );
  const [dialogStateValue, setDialogStateValue] = useState(false);
  const [questionObj, setQuestionObj] = useState({} as ModifiedQuestionList);

  const dialogState = () => {
    setDialogStateValue((dialogStateValue) => !dialogStateValue);
  };

  const closeDialogStateValueFromChild = async (dialogCloseState: any) => {
    setDialogStateValue(dialogCloseState);
  };

  const questionObjStateValueFromChild = async (objState: any) => {
    setQuestionObj(objState);
  };

  const questionAnswertableColumns: GridColDef[] = [
    {
      field: "questionText",
      headerName: `${t("appointmentpage.desc", { ns: ["home"] })}`,
      width: 150,
    },
    {
      field: "askedBy",
      headerName: `${t("appointmentpage.askby", { ns: ["home"] })}`,
      width: 250,
    },
    {
      field: "answerText",
      headerName: `${t("docpatientintpage.ans", { ns: ["home"] })}`,
      width: 200,
    },
    {
      field: "answeredBy",
      headerName: `${t("appointmentpage.ansBy", { ns: ["home"] })}`,
      width: 200,
    },
    {
      field: "questionStatus",
      headerName: `${t("appointmentpage.status", { ns: ["home"] })}`,
      width: 200,
    },
    {
      field: "submitAnswer",
      headerName: `${t("appointmentpage.ansSub", { ns: ["home"] })}`,
      width: 200,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          disabled={params.row.status === "answered" ? true : false}
          onClick={() => {
            setDialogStateValue((dialogStateValue) => !dialogStateValue);
            setQuestionObj(Object.assign(params?.row, { disabled: false }));
          }}
        >
          {t("docpatientintpage.ans", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "fullAnswer",
      headerName: `${t("appointmentpage.fullAns", { ns: ["home"] })}`,
      width: 300,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<VisibilityIcon />}
          variant="outlined"
          onClick={() => {
            setDialogStateValue((dialogStateValue) => !dialogStateValue);
            setQuestionObj(Object.assign(params?.row, { disabled: true }));
          }}
        >
          {t("docpatientintpage.viewans", { ns: ["home"] })}
        </Button>
      ),
    },
  ];

  const getQuestionBody = {
    caseId: caseid,
    doctorId: String(DoctorID),
  } as QuestionLisyBody;

  useEffect(() => {
    dispatch(questionListApi(getQuestionBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(toggleAppBarLoading(questionListValue?.loading));
    if (questionListValue?.questionListResponse?.questionList !== undefined) {
      let allQuestionListTableValues =
        questionListValue?.questionListResponse?.questionList.map(
          (element: ModifiedQuestionList) => ({
            id: element.id,
            questionText: element.hasOwnProperty("questionText")
              ? element.questionText !== ""
                ? element.questionText
                : " "
              : "",
            askedBy: element.hasOwnProperty("askedBy")
              ? element.askedBy !== ""
                ? element.askedBy
                : " "
              : "",
            answerText: element.hasOwnProperty("answerText")
              ? element.answerText !== ""
                ? element.answerText
                : " "
              : "",
            answeredBy: element.hasOwnProperty("answeredBy")
              ? element.answeredBy !== ""
                ? element.answeredBy
                : " "
              : "",
            questionStatus: element.hasOwnProperty("questionStatus")
              ? element.questionStatus !== ""
                ? element.questionStatus
                : " "
              : "",
          })
        );
      setQuestionList(allQuestionListTableValues);
    } else setQuestionList([] as ModifiedQuestionList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionListValue]);

  // Question Table
  const questionTableBody = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>{t("appointmentpage.Q&A", { ns: ["home"] })}</Grid>
                <Grid item>
                  <IconButton
                    onClick={() => dispatch(questionListApi(getQuestionBody))}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={dialogState}>
                {t("appointmentpage.askquestion", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {questionListValue.questionListResponse.questionList !== undefined &&
        questionListValue.questionListResponse.questionList.length > 0 ? (
          <DataGrid
            rows={questionList}
            columns={questionAnswertableColumns}
            hideFooter
            hideFooterPagination
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            autoHeight
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 250 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            getRowId={(row) => row?.id}
          />
        ) : (
          <MWExceptionList />
        )}
      </CardContent>
    </Card>
  );

  return (
    <>
      {questionTableBody}
      <QuestionDialog
        dialogStateValue={dialogStateValue}
        closeDialogStateValue={closeDialogStateValueFromChild}
        questionObj={questionObj}
        questionObjStateValue={questionObjStateValueFromChild}
      />
    </>
  );
}
