import {
  GetDoctorAwardTypeListActionTypes,
  GET_DOCTOR_AWARD_TYPE_LIST_FAIL,
  GET_DOCTOR_AWARD_TYPE_LIST_LOADING,
  GET_DOCTOR_AWARD_TYPE_LIST_SUCCESS,
  GetDoctorAwardTypeListState,
  GET_DOCTOR_AWARD_TYPE_LIST_API_MSG,
  GET_DOCTOR_AWARD_TYPE_LIST_API_RES,
} from "./ActionTypes";
import { GetAwardTypeListRes } from "./Model";

const initialStateUpdatePosts: GetDoctorAwardTypeListState = {
  loading: false,
  getDoctorAwardTypeListRes: {} as GetAwardTypeListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getAwardTypeListReducer = (
  state = initialStateUpdatePosts,
  action: GetDoctorAwardTypeListActionTypes
): GetDoctorAwardTypeListState => {
  switch (action.type) {
    case GET_DOCTOR_AWARD_TYPE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_DOCTOR_AWARD_TYPE_LIST_SUCCESS:
      return {
        ...state,
        getDoctorAwardTypeListRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_DOCTOR_AWARD_TYPE_LIST_FAIL:
      return {
        ...state,
        getDoctorAwardTypeListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_AWARD_TYPE_LIST_API_MSG:
      return {
        ...state,
        getDoctorAwardTypeListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_DOCTOR_AWARD_TYPE_LIST_API_RES:
      return {
        ...state,
        getDoctorAwardTypeListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
