import {
  EditDoctorProfessionalSummaryActionTypes,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_FAIL,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_LOADING,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS,
  EditDoctorProfessionalSummaryState,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG,
  EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_RES,
} from "./ActionTypes";
import { EditProfessionalSummaryRes } from "./Model";

const initialStateEditPosts: EditDoctorProfessionalSummaryState = {
  loading: false,
  editDoctorProfessionalSummaryRes: {} as EditProfessionalSummaryRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editProfessionalSummaryReducer = (
  state = initialStateEditPosts,
  action: EditDoctorProfessionalSummaryActionTypes
): EditDoctorProfessionalSummaryState => {
  switch (action.type) {
    case EDIT_DOCTOR_PROFESSIONAL_SUMMARY_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_DOCTOR_PROFESSIONAL_SUMMARY_SUCCESS:
      return {
        ...state,
        editDoctorProfessionalSummaryRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_DOCTOR_PROFESSIONAL_SUMMARY_FAIL:
      return {
        ...state,
        editDoctorProfessionalSummaryRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_MSG:
      return {
        ...state,
        editDoctorProfessionalSummaryRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DOCTOR_PROFESSIONAL_SUMMARY_API_RES:
      return {
        ...state,
        editDoctorProfessionalSummaryRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
