import {
  GET_DOCTOR_ID_PROOF_LIST_FAIL,
  GET_DOCTOR_ID_PROOF_LIST_LOADING,
  GET_DOCTOR_ID_PROOF_LIST_SUCCESS,
  GET_DOCTOR_ID_PROOF_LIST_API_MSG,
  GET_DOCTOR_ID_PROOF_LIST_API_RES,
  GetDrIdProofListActionTypes,
} from "./ActionTypes";
import { GetDrIdProofListRes } from "./Model";

export const getDrIdProofListLoadingAction = (
  loading: boolean
): GetDrIdProofListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_LIST_LOADING,
    loading: loading,
  };
};

export const getDrIdProofListSuccessAction = (
  getDrIdProofListResponse: GetDrIdProofListRes,
  successMsg: string
): GetDrIdProofListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_LIST_SUCCESS,
    payload: getDrIdProofListResponse,
    successMsg: successMsg,
  };
};

export const getDrIdProofListErrorAction = (
  getDrIdProofListResponse: GetDrIdProofListRes,
  errMsg: string,
  status: number
): GetDrIdProofListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_LIST_FAIL,
    payload: getDrIdProofListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDrIdProofListAPIMsgAction = (
  getDrIdProofListResponse: GetDrIdProofListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDrIdProofListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_LIST_API_MSG,
    payload: getDrIdProofListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDrIdProofListAPIResClearAction = (
  getDrIdProofListResponse: GetDrIdProofListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDrIdProofListActionTypes => {
  return {
    type: GET_DOCTOR_ID_PROOF_LIST_API_RES,
    payload: getDrIdProofListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
