import { GetDoctorDetailsRes } from "./Model";
export const GET_DOCTOR_LOGIN_DETAILS_LOADING =
  "GET_DOCTOR_LOGIN_DETAILS_LOADING";
export const GET_DOCTOR_LOGIN_DETAILS_SUCCESS =
  "GET_DOCTOR_LOGIN_DETAILS_SUCCESS";
export const GET_DOCTOR_LOGIN_DETAILS_FAIL = "GET_DOCTOR_LOGIN_DETAILS_FAIL";
export const GET_DOCTOR_LOGIN_DETAILS_API_MSG =
  "GET_DOCTOR_LOGIN_DETAILS_API_MSG";
export const GET_DOCTOR_LOGIN_DETAILS_API_RES =
  "GET_DOCTOR_LOGIN_DETAILS_API_RES";

export interface GetDoctorLoginDetailsState {
  getDoctorLoginDetailsRes: GetDoctorDetailsRes;
  loading: boolean;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLoginDetailsLoading {
  type: typeof GET_DOCTOR_LOGIN_DETAILS_LOADING;
  loading: boolean;
}
export interface GetDoctorLoginDetailsSuccess {
  type: typeof GET_DOCTOR_LOGIN_DETAILS_SUCCESS;
  payload: GetDoctorDetailsRes;
  successMsg: string;
}
export interface GetDoctorLoginDetailsFail {
  type: typeof GET_DOCTOR_LOGIN_DETAILS_FAIL;
  payload: GetDoctorDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLoginDetailsAPIMsg {
  type: typeof GET_DOCTOR_LOGIN_DETAILS_API_MSG;
  payload: GetDoctorDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetDoctorLoginDetailsAPIRes {
  type: typeof GET_DOCTOR_LOGIN_DETAILS_API_RES;
  payload: GetDoctorDetailsRes;
  errorMsg: string;
  status: number;
}

export type GetDoctorDetailsActionTypes =
  | GetDoctorLoginDetailsLoading
  | GetDoctorLoginDetailsSuccess
  | GetDoctorLoginDetailsFail
  | GetDoctorLoginDetailsAPIMsg
  | GetDoctorLoginDetailsAPIRes;
