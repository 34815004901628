import {
  EditDrIdProofActionTypes,
  EDIT_DR_ID_PROOF_FAIL,
  EDIT_DR_ID_PROOF_LOADING,
  EDIT_DR_ID_PROOF_SUCCESS,
  EditDrIdProofState,
  EDIT_DR_ID_PROOF_API_MSG,
  EDIT_DR_ID_PROOF_API_RES,
} from "./ActionTypes";
import { EditDrIdProofRes } from "./Model";

const initialStateEditPosts: EditDrIdProofState = {
  loading: false,
  editDrIdProofRes: {} as EditDrIdProofRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editDrIdProofReducer = (
  state = initialStateEditPosts,
  action: EditDrIdProofActionTypes
): EditDrIdProofState => {
  switch (action.type) {
    case EDIT_DR_ID_PROOF_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_DR_ID_PROOF_SUCCESS:
      return {
        ...state,
        editDrIdProofRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_DR_ID_PROOF_FAIL:
      return {
        ...state,
        editDrIdProofRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_ID_PROOF_API_MSG:
      return {
        ...state,
        editDrIdProofRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_DR_ID_PROOF_API_RES:
      return {
        ...state,
        editDrIdProofRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
