import { AddProfessionalMembershipRes } from "./Model";
export const ADD_DR_PROFESSIONAL_MEMBERSHIP_LOADING =
  "ADD_DR_PROFESSIONAL_MEMBERSHIP_LOADING";
export const ADD_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS =
  "ADD_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS";
export const ADD_DR_PROFESSIONAL_MEMBERSHIP_FAIL =
  "ADD_DR_PROFESSIONAL_MEMBERSHIP_FAIL";
export const ADD_DR_PROFESSIONAL_MEMBERSHIP_API_MSG =
  "ADD_DR_PROFESSIONAL_MEMBERSHIP_API_MSG";
export const ADD_DR_PROFESSIONAL_MEMBERSHIP_API_RES =
  "ADD_DR_PROFESSIONAL_MEMBERSHIP_API_RES";

export interface AddDrProfessionalMembershipState {
  addDrProfessionalMembershipRes: AddProfessionalMembershipRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrProfessionalMembershipLoading {
  type: typeof ADD_DR_PROFESSIONAL_MEMBERSHIP_LOADING;
  loading: boolean;
}
export interface AddDrProfessionalMembershipSuccess {
  type: typeof ADD_DR_PROFESSIONAL_MEMBERSHIP_SUCCESS;
  payload: AddProfessionalMembershipRes;
  successMsg: string;
}
export interface AddDrProfessionalMembershipFail {
  type: typeof ADD_DR_PROFESSIONAL_MEMBERSHIP_FAIL;
  payload: AddProfessionalMembershipRes;
  errorMsg: string;
  status: number;
}
export interface AddDrProfessionalMembershipAPIMsg {
  type: typeof ADD_DR_PROFESSIONAL_MEMBERSHIP_API_MSG;
  payload: AddProfessionalMembershipRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrProfessionalMembershipAPIRes {
  type: typeof ADD_DR_PROFESSIONAL_MEMBERSHIP_API_RES;
  payload: AddProfessionalMembershipRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrProfessionalMembershipActionTypes =
  | AddDrProfessionalMembershipLoading
  | AddDrProfessionalMembershipSuccess
  | AddDrProfessionalMembershipFail
  | AddDrProfessionalMembershipAPIMsg
  | AddDrProfessionalMembershipAPIRes;
