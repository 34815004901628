import {
  AddPatientImportConfigDataActionTypes,
  ADD_PATIENT_IMPORT_CONFIG_FAIL,
  ADD_PATIENT_IMPORT_CONFIG_LOADING,
  ADD_PATIENT_IMPORT_CONFIG_SUCCESS,
  AddPatientImportConfigDataState,
  ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_MSG,
  ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_RES,
} from "./ActionTypes";
import { AddPatientImportConfigDataRes } from "./Model";

const initialStateGetPosts: AddPatientImportConfigDataState = {
  loading: false,
  addAddPatientImportConfigDataRes: {} as AddPatientImportConfigDataRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const addPatientImportConfigDataReducer = (
  state = initialStateGetPosts,
  action: AddPatientImportConfigDataActionTypes
): AddPatientImportConfigDataState => {
  switch (action.type) {
    case ADD_PATIENT_IMPORT_CONFIG_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ADD_PATIENT_IMPORT_CONFIG_SUCCESS:
      return {
        ...state,
        addAddPatientImportConfigDataRes: action.payload,
        successMsg: action.successMsg,
      };
    case ADD_PATIENT_IMPORT_CONFIG_FAIL:
      return {
        ...state,
        addAddPatientImportConfigDataRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_MSG:
      return {
        ...state,
        addAddPatientImportConfigDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ADD_PATIENT_IMPORT_CONFIG_UPDATE_API_RES:
      return {
        ...state,
        addAddPatientImportConfigDataRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
