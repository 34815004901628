import { GetDoctorIdTypeListRes } from "./Model";
export const GET_DOCTOR_ID_PROOF_TYPE_LIST_LOADING =
  "GET_DOCTOR_ID_PROOF_TYPE_LIST_LOADING";
export const GET_DOCTOR_ID_PROOF_TYPE_LIST_SUCCESS =
  "GET_DOCTOR_ID_PROOF_TYPE_LIST_SUCCESS";
export const GET_DOCTOR_ID_PROOF_TYPE_LIST_FAIL =
  "GET_DOCTOR_ID_PROOF_TYPE_LIST_FAIL";
export const GET_DOCTOR_ID_PROOF_TYPE_LIST_API_MSG =
  "GET_DOCTOR_ID_PROOF_TYPE_LIST_API_MSG";
export const GET_DOCTOR_ID_PROOF_TYPE_LIST_API_RES =
  "GET_DOCTOR_ID_PROOF_TYPE_LIST_API_RES";

export interface GetDrIdProofTypeListState {
  getDrIdProofTypeListRes: GetDoctorIdTypeListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDrIdProofTypeListLoading {
  type: typeof GET_DOCTOR_ID_PROOF_TYPE_LIST_LOADING;
  loading: boolean;
}
export interface GetDrIdProofTypeListSuccess {
  type: typeof GET_DOCTOR_ID_PROOF_TYPE_LIST_SUCCESS;
  payload: GetDoctorIdTypeListRes;
  successMsg: string;
}
export interface GetDrIdProofTypeListFail {
  type: typeof GET_DOCTOR_ID_PROOF_TYPE_LIST_FAIL;
  payload: GetDoctorIdTypeListRes;
  errorMsg: string;
  status: number;
}
export interface GetDrIdProofTypeListAPIMsg {
  type: typeof GET_DOCTOR_ID_PROOF_TYPE_LIST_API_MSG;
  payload: GetDoctorIdTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetDrIdProofTypeListAPIRes {
  type: typeof GET_DOCTOR_ID_PROOF_TYPE_LIST_API_RES;
  payload: GetDoctorIdTypeListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetDrIdProofTypeListActionTypes =
  | GetDrIdProofTypeListLoading
  | GetDrIdProofTypeListSuccess
  | GetDrIdProofTypeListFail
  | GetDrIdProofTypeListAPIMsg
  | GetDrIdProofTypeListAPIRes;
