import {
  ADD_DR_WORK_LICENSE_FAIL,
  ADD_DR_WORK_LICENSE_LOADING,
  ADD_DR_WORK_LICENSE_SUCCESS,
  ADD_DR_WORK_LICENSE_API_MSG,
  ADD_DR_WORK_LICENSE_API_RES,
  AddDrLicenseActionTypes,
} from "./ActionTypes";
import { AddDrProfessionalLicenseRes } from "./Model";

export const addDrLicenseLoadingAction = (
  loading: boolean
): AddDrLicenseActionTypes => {
  return {
    type: ADD_DR_WORK_LICENSE_LOADING,
    loading: loading,
  };
};

export const addDrLicenseSuccessAction = (
  addDrLicenseResponse: AddDrProfessionalLicenseRes,
  successMsg: string
): AddDrLicenseActionTypes => {
  return {
    type: ADD_DR_WORK_LICENSE_SUCCESS,
    payload: addDrLicenseResponse,
    successMsg: successMsg,
  };
};

export const addDrLicenseErrorAction = (
  addDrLicenseResponse: AddDrProfessionalLicenseRes,
  errMsg: string,
  status: number
): AddDrLicenseActionTypes => {
  return {
    type: ADD_DR_WORK_LICENSE_FAIL,
    payload: addDrLicenseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addDrLicenseAPIMsgAction = (
  addDrLicenseResponse: AddDrProfessionalLicenseRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrLicenseActionTypes => {
  return {
    type: ADD_DR_WORK_LICENSE_API_MSG,
    payload: addDrLicenseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addDrLicenseAPIResClearAction = (
  addDrLicenseResponse: AddDrProfessionalLicenseRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddDrLicenseActionTypes => {
  return {
    type: ADD_DR_WORK_LICENSE_API_RES,
    payload: addDrLicenseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
