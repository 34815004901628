import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../URL";
import { DoctorAccountDetailsTypes } from "./ActionTypes";
import { DoctorAccDetailsRes } from "./Model";
import {
  doctorAccountDetailsAPIResClearAction,
  doctorAccountDetailsErrorAction,
  doctorAccountDetailsLoadingAction,
  doctorAccountDetailsSuccessAction,
  doctorAccountDetailsUpdateAPIMsgAction,
} from "./Actions";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as DoctorAccDetailsRes;
export const doctorAccountDetailsAPI = () => {
  return function (dispatch: Dispatch<DoctorAccountDetailsTypes>) {
    dispatch(doctorAccountDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.apiURL + `/profileapp/api/userDetails/get_details`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(doctorAccountDetailsLoadingAction(false));
        dispatch(
          doctorAccountDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Password reset successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(doctorAccountDetailsLoadingAction(false));
        dispatch(
          doctorAccountDetailsErrorAction(
            {} as DoctorAccDetailsRes,
            error.response !== undefined
              ? error.response.data !== undefined
                ? error.response.data.message !== undefined
                  ? error.response.data.message
                  : error.response.data.detail !== undefined
                  ? error.response.data.detail
                  : error.response.data.non_field_errors[0] !== undefined
                  ? error.response.data.non_field_errors[0]
                  : "Something went wrong"
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateDoctorAccountDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<DoctorAccountDetailsTypes>) {
    dispatch(
      doctorAccountDetailsUpdateAPIMsgAction(
        apiRes as DoctorAccDetailsRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDoctorAccountDetailsAPIRes = () => {
  return function (dispatch: Dispatch<DoctorAccountDetailsTypes>) {
    dispatch(
      doctorAccountDetailsAPIResClearAction(
        {} as DoctorAccDetailsRes,
        "",
        "",
        0
      )
    );
  };
};
