import {
  JoiningFeeActionTypes,
  JOINING_FEE_FAIL,
  JOINING_FEE_LOADING,
  JOINING_FEE_SUCCESS,
  JoiningFeeState,
  JOINING_FEE_UPDATE_API_MSG,
  JOINING_FEE_UPDATE_API_RES,
} from "./ActionTypes";
import { JoiningFeeRes } from "./Model";

const initialStateGetPosts: JoiningFeeState = {
  loading: false,
  joiningFee: {} as JoiningFeeRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const joiningFeeReducer = (
  state = initialStateGetPosts,
  action: JoiningFeeActionTypes
): JoiningFeeState => {
  switch (action.type) {
    case JOINING_FEE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case JOINING_FEE_SUCCESS:
      return {
        ...state,
        joiningFee: action.payload,
        successMsg: action.successMsg,
      };
    case JOINING_FEE_FAIL:
      return {
        ...state,
        joiningFee: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case JOINING_FEE_UPDATE_API_MSG:
      return {
        ...state,
        joiningFee: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case JOINING_FEE_UPDATE_API_RES:
      return {
        ...state,
        joiningFee: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
