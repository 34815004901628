import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store/Store";
import { getLegalSummary } from "redux/UserProfile/LegalDetails/LegalSummary/GetLegalSummay/API";
import { UpdateDrLegalSummaryBody } from "redux/UserProfile/LegalDetails/LegalSummary/EditLegalSummary/Model";
import {
  clearUpdateDrLegalSummaryAPIRes,
  updateDrLegalSummary,
} from "redux/UserProfile/LegalDetails/LegalSummary/EditLegalSummary/API";
export interface Props {
  legalSummaryLoading: boolean | any;
}
export default function LegalSummary({ legalSummaryLoading }: Props) {
  const dispatch = useDispatch();
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [isEdit, setIsEdit] = useState(true);

  const [noticeReceive, setNoticeReceive] = useState("0");

  const [pendingCase, setPendingCase] = useState("0");

  const [deathCount, setDeathCount] = useState("");

  const editDoctorLegalSummaryBody = {
    noOfNoticeReceived: noticeReceive,
    noOfCasesPending: pendingCase,
    noOfDeathDueToNegligence: deathCount,
  } as UpdateDrLegalSummaryBody;

  const professionSummarySubmit = () => {
    dispatch(updateDrLegalSummary(editDoctorLegalSummaryBody));
  };

  useEffect(() => {
    dispatch(getLegalSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call Professional summary store
  const getLegalSummaryRes = useSelector(
    (state: AppState) => state.getLegalSummaryRes
  );
  // Get Professional summary
  useEffect(() => {
    legalSummaryLoading(getLegalSummaryRes?.loading);
    getLegalSummaryRes?.getDoctorLegalSummaryRes?.legalSummaryDetails
      ?.noOfNoticeReceived !== undefined
      ? setNoticeReceive(
          String(
            getLegalSummaryRes?.getDoctorLegalSummaryRes?.legalSummaryDetails
              ?.noOfNoticeReceived
          )
        )
      : setNoticeReceive("0");
    getLegalSummaryRes?.getDoctorLegalSummaryRes?.legalSummaryDetails
      ?.noOfCasesPending !== undefined
      ? setPendingCase(
          String(
            getLegalSummaryRes?.getDoctorLegalSummaryRes?.legalSummaryDetails
              ?.noOfCasesPending
          )
        )
      : setPendingCase("0");
    getLegalSummaryRes?.getDoctorLegalSummaryRes?.legalSummaryDetails
      ?.noOfDeathDueToNegligence !== undefined
      ? setDeathCount(
          String(
            getLegalSummaryRes?.getDoctorLegalSummaryRes?.legalSummaryDetails
              ?.noOfDeathDueToNegligence
          )
        )
      : setDeathCount("0");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLegalSummaryRes]);

  const [updateLegalSummaryMessage, setUpdateLegalSummaryMessage] =
    useState("");
  const [updateLegalSummaryType, setUpdateLegalSummaryType] =
    useState<AlertColor>("success");

  // Call update legal summary store
  const updateDrLegalSummaryRes = useSelector(
    (state: AppState) => state.updateDrLegalSummaryRes
  );
  useEffect(() => {
    legalSummaryLoading(updateDrLegalSummaryRes?.loading);
    if (updateDrLegalSummaryRes?.successMsg !== "") {
      setUpdateLegalSummaryType("success");
      setUpdateLegalSummaryMessage(updateDrLegalSummaryRes?.successMsg);
      dispatch(getLegalSummary());
      setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
    }
    if (updateDrLegalSummaryRes?.errorMsg !== "") {
      setUpdateLegalSummaryType("error");
      setUpdateLegalSummaryMessage(updateDrLegalSummaryRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDrLegalSummaryRes]);

  return (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("userprofilepage.legaldetailsum", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
                variant="text"
                sx={{ textTransform: "none" }}
              >
                {isEdit
                  ? `${t("userprofilepage.edit", { ns: ["home"] })}`
                  : `${t("accountdetail.cancel", { ns: ["home"] })}`}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              label={t("userprofilepage.noofnotice", { ns: ["home"] })}
              placeholder={
                t("userprofilepage.enternoofnotice", { ns: ["home"] })!
              }
              value={noticeReceive}
              onChange={(event: any) => setNoticeReceive(event.target.value)}
              disabled={isEdit}
              fullWidth
              type="number"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              label={t("userprofilepage.pendingcaseno", { ns: ["home"] })}
              placeholder={
                t("userprofilepage.enterpendingcaseno", { ns: ["home"] })!
              }
              value={pendingCase}
              onChange={(event: any) => setPendingCase(event.target.value)}
              disabled={isEdit}
              type="number"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              label={t("userprofilepage.deathcase", { ns: ["home"] })}
              placeholder={
                t("userprofilepage.enterdeathcase", { ns: ["home"] })!
              }
              value={deathCount}
              onChange={(event: any) => setDeathCount(event.target.value)}
              disabled={isEdit}
              type="number"
              fullWidth
              size="small"
            />
          </Grid>
          {updateLegalSummaryMessage !== "" ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                severity={updateLegalSummaryType}
                onClose={() => {
                  dispatch(clearUpdateDrLegalSummaryAPIRes());
                  setUpdateLegalSummaryMessage("");
                }}
              >
                {updateLegalSummaryMessage}
              </Alert>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="contained"
              disabled={
                isEnableSubmit ||
                getLegalSummaryRes?.loading ||
                updateDrLegalSummaryRes?.loading
              }
              onClick={() => {
                professionSummarySubmit();
              }}
              sx={{ textTransform: "none" }}
            >
              {`${t("userprofilepage.subdetail", { ns: ["home"] })}`}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
