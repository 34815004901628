import {
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_FAIL,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_LOADING,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_SUCCESS,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_MSG,
  AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_RES,
  AuthorizeDoctorForZoomTeleHealthActionTypes,
} from "./ActionTypes";
import { AuthorizeDoctorForZoomTeleHealthRes } from "./Model";

export const authorizeDoctorForZoomTeleHealthLoadingAction = (
  loading: boolean,
): AuthorizeDoctorForZoomTeleHealthActionTypes => {
  return {
    type: AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_LOADING,
    loading: loading,
  };
};

export const authorizeDoctorForZoomTeleHealthSuccessAction = (
  doctorListForPatientCaseResponse: AuthorizeDoctorForZoomTeleHealthRes,
  successMsg: string,
): AuthorizeDoctorForZoomTeleHealthActionTypes => {
  return {
    type: AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_SUCCESS,
    payload: doctorListForPatientCaseResponse,
    successMsg: successMsg,
  };
};

export const authorizeDoctorForZoomTeleHealthErrorAction = (
  doctorListForPatientCaseResponse: AuthorizeDoctorForZoomTeleHealthRes,
  errMsg: string,
  status: number,
): AuthorizeDoctorForZoomTeleHealthActionTypes => {
  return {
    type: AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_FAIL,
    payload: doctorListForPatientCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const authorizeDoctorForZoomTeleHealthAPIMsgAction = (
  doctorListForPatientCaseResponse: AuthorizeDoctorForZoomTeleHealthRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AuthorizeDoctorForZoomTeleHealthActionTypes => {
  return {
    type: AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_MSG,
    payload: doctorListForPatientCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const authorizeDoctorForZoomTeleHealthAPIResClearAction = (
  doctorListForPatientCaseResponse: AuthorizeDoctorForZoomTeleHealthRes,
  successMsg: string,
  errMsg: string,
  status: number,
): AuthorizeDoctorForZoomTeleHealthActionTypes => {
  return {
    type: AUTHORIZE_DR_FOR_ZOOM_TELEHEALTH_API_RES,
    payload: doctorListForPatientCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
