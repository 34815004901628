import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../URL";
import { GetDoctorAwardListActionTypes } from "./ActionTypes";
import { GetAwardListRes } from "./Model";
import {
  getDoctorAwardListAPIResClearAction,
  getDoctorAwardListErrorAction,
  getDoctorAwardListLoadingAction,
  getDoctorAwardListSuccessAction,
  getDoctorAwardListAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as GetAwardListRes;
export const getAwardList = () => {
  return function (dispatch: Dispatch<GetDoctorAwardListActionTypes>) {
    dispatch(getDoctorAwardListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.apiURL + `/doctorapp/api/onboarding/get_awards_list_by_doctor`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorAwardListLoadingAction(false));
        dispatch(
          getDoctorAwardListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. award list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorAwardListLoadingAction(false));
        dispatch(
          getDoctorAwardListErrorAction(
            {} as GetAwardListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getDoctorAwardListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorAwardListActionTypes>) {
    dispatch(getDoctorAwardListAPIMsgAction(apiRes as GetAwardListRes, "", 0));
  };
};

export const clearGetDoctorAwardListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorAwardListActionTypes>) {
    dispatch(getDoctorAwardListAPIResClearAction({} as GetAwardListRes, "", 0));
  };
};
