import { LogOutResponse } from "./Model";

// Patient Meeting Loading State
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_DATA = "LOGOUT_DATA";

export interface answerQuestionDetails {
  logoutRes: LogOutResponse;
  loading: boolean;
  errRes: string;
}
export interface LogOutActionLoading {
  type: typeof LOGOUT_LOADING;
}
export interface LogOutActionSuccess {
  type: typeof LOGOUT_SUCCESS;
  payload: LogOutResponse;
  errRes: string;
}
export interface LogOutActionFail {
  type: typeof LOGOUT_FAIL;
  payload: LogOutResponse;
  errRes: string;
}
interface LogOutActionAction {
  type: typeof LOGOUT_DATA;
  payload: LogOutResponse;
  errRes: string;
}

export type LogOutActionTypes =
  | LogOutActionAction
  | LogOutActionLoading
  | LogOutActionSuccess
  | LogOutActionFail;
