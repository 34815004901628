import {
  Alert,
  AlertColor,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { DegreeList } from "redux/UserProfile/EducationDetails/EducationList/Model";
import { useDispatch, useSelector } from "react-redux";
import { getEducationList } from "redux/UserProfile/EducationDetails/EducationList/API";
import { AppState } from "redux/store/Store";
import { DegreeListDropDownMenu } from "./Model";
import { getEducationSummary } from "redux/UserProfile/EducationDetails/GetEducationSummary/API";
import { UpdateEducationSummaryBody } from "redux/UserProfile/EducationDetails/EditEducationSummary/Model";
import {
  clearUpdateDoctorEducationSUmmaryAPIRes,
  updateEducationSummary,
} from "redux/UserProfile/EducationDetails/EditEducationSummary/API";

export interface Props {
  educationSummaryLoading: boolean | any;
}

export default function EducationalSummary({ educationSummaryLoading }: Props) {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(true);
  const [isEnableSubmit, setEnableSubmit] = useState(true);

  const [degreeList, setDegreeList] = useState([] as DegreeListDropDownMenu[]);
  const [highestQualification, setHighestQualification] =
    useState<DegreeList | null>(null);
  const highestQualificationChange = (
    _event: any,
    passedVal: DegreeListDropDownMenu | any
  ) => {
    setHighestQualification(passedVal);
  };

  const [secHighestQualification, setSecHighestQualification] =
    useState<DegreeList | null>(null);
  const secHighestQualificationChange = (
    _event: any,
    passedVal: DegreeListDropDownMenu | any
  ) => {
    setSecHighestQualification(passedVal);
  };

  const [degreeQualification, setDegreeQualification] =
    useState<DegreeList | null>(null);
  const degreeQualificationChange = (
    _event: any,
    passedVal: DegreeListDropDownMenu | any
  ) => {
    setDegreeQualification(passedVal);
  };

  useEffect(() => {
    dispatch(getEducationList());
    dispatch(getEducationSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call Education List store
  const getEducationListRes = useSelector(
    (state: AppState) => state.getEducationList
  );
  useEffect(() => {
    educationSummaryLoading(getEducationListRes?.loading);
    getEducationListRes?.getDoctorEducationListRes?.degreeList !== undefined
      ? setDegreeList(
          getEducationListRes?.getDoctorEducationListRes?.degreeList
        )
      : setDegreeList([] as DegreeListDropDownMenu[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEducationListRes]);

  // Call Education summary store
  const getEducationSummaryRes = useSelector(
    (state: AppState) => state.getEducationSummary
  );
  // Get Education summary
  useEffect(() => {
    educationSummaryLoading(getEducationSummaryRes?.loading);
    if (
      getEducationSummaryRes?.getDoctorEducationSummaryRes.educationSummary !==
      undefined
    ) {
      if (
        getEducationSummaryRes?.getDoctorEducationSummaryRes?.educationSummary
          ?.highestQualification?.value !== undefined
      ) {
        setHighestQualification(
          degreeList.find(
            (o) =>
              o.value ===
              getEducationSummaryRes?.getDoctorEducationSummaryRes
                ?.educationSummary?.highestQualification?.value
          )!
        );
      }
      if (
        getEducationSummaryRes?.getDoctorEducationSummaryRes.educationSummary
          ?.secondHighestQualification?.value !== undefined
      ) {
        setSecHighestQualification(
          degreeList.find(
            (o) =>
              o.value ===
              getEducationSummaryRes?.getDoctorEducationSummaryRes
                ?.educationSummary?.secondHighestQualification?.value
          )!
        );
      }
      if (
        getEducationSummaryRes?.getDoctorEducationSummaryRes.educationSummary
          .mbbsDegree?.value !== undefined
      ) {
        setDegreeQualification(
          degreeList.find(
            (o) =>
              o.value ===
              getEducationSummaryRes?.getDoctorEducationSummaryRes
                ?.educationSummary?.mbbsDegree?.value
          )!
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEducationSummaryRes, degreeList]);

  const educationSummaryBody = {
    highestQualification: highestQualification?.value,
    secondHighestQualification: secHighestQualification?.value,
    mbbsDegree: degreeQualification?.value,
  } as UpdateEducationSummaryBody;

  // Add Education Summary
  const educationSummarySubmit = () => {
    dispatch(updateEducationSummary(educationSummaryBody));
  };

  const [updateEducationSummaryMessage, setUpdateEducationSummaryMessage] =
    useState("");
  const [updateEducationSummaryType, setUpdateEducationSummaryType] =
    useState<AlertColor>("success");

  // Call Education Summary store
  const editEducationSummaryResponse = useSelector(
    (state: AppState) => state.editEducationSummary
  );
  useEffect(() => {
    educationSummaryLoading(editEducationSummaryResponse?.loading);
    if (
      editEducationSummaryResponse?.updateDoctorEducationSummaryRes?.message !==
      undefined
    ) {
      setUpdateEducationSummaryType("success");
      setUpdateEducationSummaryMessage(
        editEducationSummaryResponse?.updateDoctorEducationSummaryRes?.message
      );
      dispatch(getEducationSummary());
      setIsEdit((isEdit) => !isEdit);
      setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
    }
    if (editEducationSummaryResponse?.errorMsg !== "") {
      setUpdateEducationSummaryType("error");
      setUpdateEducationSummaryMessage(editEducationSummaryResponse?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEducationSummaryResponse]);

  return (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="subtitle1" fontWeight="bold">
                {t("userprofilepage.edusum", { ns: ["home"] })}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit
                  ? `${t("userprofilepage.edit", { ns: ["home"] })}`
                  : `${t("accountdetail.cancel", { ns: ["home"] })}`}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              {t("userprofilepage.qualification1", { ns: ["home"] })}
            </Typography>
            <Autocomplete
              value={highestQualification}
              id="education-states"
              options={degreeList}
              onChange={(event: any, newValue: any) => {
                highestQualificationChange(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    t("userprofilepage.selHighQual", { ns: ["home"] })!
                  }
                />
              )}
              disabled={isEdit}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              {t("userprofilepage.qualification2", { ns: ["home"] })}
            </Typography>
            <Autocomplete
              value={secHighestQualification}
              id="highest-states"
              options={degreeList}
              onChange={(event: any, newValue: any) => {
                secHighestQualificationChange(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    t("userprofilepage.sel2ndHighQual", { ns: ["home"] })!
                  }
                />
              )}
              disabled={isEdit}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="body1" style={{ marginBottom: "5px" }}>
              {t("userprofilepage.qualification3", { ns: ["home"] })}
            </Typography>
            <Autocomplete
              value={degreeQualification}
              id="degree-states"
              options={degreeList}
              onChange={(event: any, newValue: any) => {
                degreeQualificationChange(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    t("userprofilepage.selDegree", { ns: ["home"] })!
                  }
                />
              )}
              disabled={isEdit}
              size="small"
            />
          </Grid>
          {updateEducationSummaryMessage !== "" ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                severity={updateEducationSummaryType}
                onClose={() => {
                  dispatch(clearUpdateDoctorEducationSUmmaryAPIRes());
                  setUpdateEducationSummaryMessage("");
                }}
              >
                {updateEducationSummaryMessage}
              </Alert>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{ textTransform: "none" }}
              onClick={() => educationSummarySubmit()}
              disabled={isEnableSubmit || editEducationSummaryResponse?.loading}
            >
              {t("userprofilepage.subdetail", { ns: ["home"] })}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
