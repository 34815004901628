import {
  GET_DOCTOR_TASK_LIST_FAIL,
  GET_DOCTOR_TASK_LIST_LOADING,
  GET_DOCTOR_TASK_LIST_SUCCESS,
  GET_DOCTOR_TASK_LIST_API_MSG,
  GET_DOCTOR_TASK_LIST_API_RES,
  GetDoctorTaskListActionTypes,
} from "./ActionTypes";
import { GetTaskListRes } from "./Model";

export const getDoctorTaskListLoadingAction = (
  loading: boolean
): GetDoctorTaskListActionTypes => {
  return {
    type: GET_DOCTOR_TASK_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorTaskListSuccessAction = (
  getDoctorTaskListResponse: GetTaskListRes,
  successMsg: string
): GetDoctorTaskListActionTypes => {
  return {
    type: GET_DOCTOR_TASK_LIST_SUCCESS,
    payload: getDoctorTaskListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorTaskListErrorAction = (
  getDoctorTaskListResponse: GetTaskListRes,
  errMsg: string,
  status: number
): GetDoctorTaskListActionTypes => {
  return {
    type: GET_DOCTOR_TASK_LIST_FAIL,
    payload: getDoctorTaskListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorTaskListAPIMsgAction = (
  getDoctorTaskListResponse: GetTaskListRes,
  errMsg: string,
  status: number
): GetDoctorTaskListActionTypes => {
  return {
    type: GET_DOCTOR_TASK_LIST_API_MSG,
    payload: getDoctorTaskListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorTaskListAPIResClearAction = (
  getDoctorTaskListResponse: GetTaskListRes,
  errMsg: string,
  status: number
): GetDoctorTaskListActionTypes => {
  return {
    type: GET_DOCTOR_TASK_LIST_API_RES,
    payload: getDoctorTaskListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
