import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../../URL";
import { setupInterceptorsTo } from "../../authentication/Interceptors";
import {
  PaymentListActionTypes,
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_LOADING,
  PAYMENT_LIST_SUCCESS,
} from "./ActionTypes";
import { getPaymentLinkRes } from "./Model";
import { headers } from "components/Utility";

export const getPaymentLinkApi = (body: object) => {
  return function (dispatch: Dispatch<PaymentListActionTypes>) {
    dispatch({
      type: PAYMENT_LIST_LOADING,
    });
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL + `/paymentapp/api/payment/get_payment_link`,
        body,
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch({
          type: PAYMENT_LIST_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_LIST_FAIL,
          payload: {} as getPaymentLinkRes,
          errRes: error.response.data.message,
        });
      });
  };
};
