import {
  GET_DOCTOR_LEGAL_TYPE_LIST_FAIL,
  GET_DOCTOR_LEGAL_TYPE_LIST_LOADING,
  GET_DOCTOR_LEGAL_TYPE_LIST_SUCCESS,
  GET_DOCTOR_LEGAL_TYPE_LIST_API_MSG,
  GET_DOCTOR_LEGAL_TYPE_LIST_API_RES,
  GetDoctorLegalTypeListActionTypes,
} from "./ActionTypes";
import { GetLegalTypeListRes } from "./Model";

export const getDoctorLegalTypeListLoadingAction = (
  loading: boolean
): GetDoctorLegalTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_TYPE_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorLegalTypeListSuccessAction = (
  getDoctorLegalTypeListResponse: GetLegalTypeListRes,
  successMsg: string
): GetDoctorLegalTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_TYPE_LIST_SUCCESS,
    payload: getDoctorLegalTypeListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorLegalTypeListErrorAction = (
  getDoctorLegalTypeListResponse: GetLegalTypeListRes,
  errMsg: string,
  status: number
): GetDoctorLegalTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_TYPE_LIST_FAIL,
    payload: getDoctorLegalTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorLegalTypeListAPIMsgAction = (
  getDoctorLegalTypeListResponse: GetLegalTypeListRes,
  errMsg: string,
  status: number
): GetDoctorLegalTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_TYPE_LIST_API_MSG,
    payload: getDoctorLegalTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorLegalTypeListAPIResClearAction = (
  getDoctorLegalTypeListResponse: GetLegalTypeListRes,
  errMsg: string,
  status: number
): GetDoctorLegalTypeListActionTypes => {
  return {
    type: GET_DOCTOR_LEGAL_TYPE_LIST_API_RES,
    payload: getDoctorLegalTypeListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
