import axios from "axios";
import { Dispatch } from "redux";
import apiURL from "../../URL";
import { JoiningFeeActionTypes } from "./ActionTypes";
import { JoiningFeeBody, JoiningFeeRes } from "./Model";
import {
  joiningFeeAPIResClearAction,
  joiningFeeErrorAction,
  joiningFeeLoadingAction,
  joiningFeeSuccessAction,
  joiningFeeUpdateAPIMsgAction,
} from "./Actions";
import { setupInterceptorsTo } from "../authentication/Interceptors";
import { headers } from "components/Utility";

let apiRes = {} as JoiningFeeRes;
export const joiningFeeApi = (payload: JoiningFeeBody) => {
  return function (dispatch: Dispatch<JoiningFeeActionTypes>) {
    dispatch(joiningFeeLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        apiURL.apiURL + `/paymentapp/api/payment/get_payment_list_for_owner`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(joiningFeeLoadingAction(false));
        dispatch(
          joiningFeeSuccessAction(
            res.data !== undefined ? res.data : ({} as JoiningFeeRes),
            res.data.message !== undefined
              ? res.data.message
              : "Joining Fee list fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(joiningFeeLoadingAction(false));
        dispatch(
          joiningFeeErrorAction(
            {} as JoiningFeeRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.message
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateJoiningFeeAPIResMsg = () => {
  return function (dispatch: Dispatch<JoiningFeeActionTypes>) {
    dispatch(joiningFeeUpdateAPIMsgAction(apiRes as JoiningFeeRes, "", "", 0));
  };
};

export const clearJoiningFeeAPIRes = () => {
  return function (dispatch: Dispatch<JoiningFeeActionTypes>) {
    dispatch(joiningFeeAPIResClearAction({} as JoiningFeeRes, "", "", 0));
  };
};
