import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../../URL";
import { AddDrLicenseActionTypes } from "./ActionTypes";
import { AddDrProfessionalLicenseBody, AddDrProfessionalLicenseRes } from "./Model";
import {
  addDrLicenseAPIResClearAction,
  addDrLicenseErrorAction,
  addDrLicenseLoadingAction,
  addDrLicenseSuccessAction,
  addDrLicenseAPIMsgAction,
} from "./Action";
import { headers } from "components/Utility";
import { setupInterceptorsTo } from "redux/authentication/Interceptors";

let apiRes = {} as AddDrProfessionalLicenseRes;
export const addDrLicense = (payload: AddDrProfessionalLicenseBody) => {
  return function (dispatch: Dispatch<AddDrLicenseActionTypes>) {
    dispatch(addDrLicenseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.apiURL + `/doctorapp/api/doctorlicense/add_doctor_license`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addDrLicenseLoadingAction(false));
        dispatch(
          addDrLicenseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Dr. license has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addDrLicenseLoadingAction(false));
        dispatch(
          addDrLicenseErrorAction(
            {} as AddDrProfessionalLicenseRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddDrLicenseAPIResMsg = () => {
  return function (dispatch: Dispatch<AddDrLicenseActionTypes>) {
    dispatch(
      addDrLicenseAPIMsgAction(apiRes as AddDrProfessionalLicenseRes, "", "", 0)
    );
  };
};

export const clearAddDrLicenseAPIRes = () => {
  return function (dispatch: Dispatch<AddDrLicenseActionTypes>) {
    dispatch(
      addDrLicenseAPIResClearAction(
        {} as AddDrProfessionalLicenseRes,
        "",
        "",
        0
      )
    );
  };
};
