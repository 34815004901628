import { AddLegalDetailsRes } from "./Model";
export const ADD_DR_LEGAL_DETAILS_LOADING = "ADD_DR_LEGAL_DETAILS_LOADING";
export const ADD_DR_LEGAL_DETAILS_SUCCESS = "ADD_DR_LEGAL_DETAILS_SUCCESS";
export const ADD_DR_LEGAL_DETAILS_FAIL = "ADD_DR_LEGAL_DETAILS_FAIL";
export const ADD_DR_LEGAL_DETAILS_API_MSG = "ADD_DR_LEGAL_DETAILS_API_MSG";
export const ADD_DR_LEGAL_DETAILS_API_RES = "ADD_DR_LEGAL_DETAILS_API_RES";

export interface AddDrLegalDetailsState {
  addDrLegalDetailsRes: AddLegalDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrLegalDetailsLoading {
  type: typeof ADD_DR_LEGAL_DETAILS_LOADING;
  loading: boolean;
}
export interface AddDrLegalDetailsSuccess {
  type: typeof ADD_DR_LEGAL_DETAILS_SUCCESS;
  payload: AddLegalDetailsRes;
  successMsg: string;
}
export interface AddDrLegalDetailsFail {
  type: typeof ADD_DR_LEGAL_DETAILS_FAIL;
  payload: AddLegalDetailsRes;
  errorMsg: string;
  status: number;
}
export interface AddDrLegalDetailsAPIMsg {
  type: typeof ADD_DR_LEGAL_DETAILS_API_MSG;
  payload: AddLegalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddDrLegalDetailsAPIRes {
  type: typeof ADD_DR_LEGAL_DETAILS_API_RES;
  payload: AddLegalDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddDrLegalDetailsActionTypes =
  | AddDrLegalDetailsLoading
  | AddDrLegalDetailsSuccess
  | AddDrLegalDetailsFail
  | AddDrLegalDetailsAPIMsg
  | AddDrLegalDetailsAPIRes;
