import {
    WorkScheduleListDetails,
    WorkScheduleListActionTypes,
    WORK_SCHEDULE_LIST_FAIL,
    WORK_SCHEDULE_LIST_LOADING,
    WORK_SCHEDULE_LIST_SUCCESS,
  } from "./ActionTypes";
  import { WorkScheduleListRes } from "./Model";
  
  const initialStateGetPosts: WorkScheduleListDetails = {
    loading: false,
    WorkScheduleListRes: {} as WorkScheduleListRes,
    errRes: "",
  };
  export const WorkScheduleListReducer = (
    state = initialStateGetPosts,
    action: WorkScheduleListActionTypes
  ): WorkScheduleListDetails => {
    switch (action.type) {
      case WORK_SCHEDULE_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          WorkScheduleListRes: action.payload,
          errRes: "",
        };
      case WORK_SCHEDULE_LIST_LOADING:
        return {
          ...state,
          loading: true,
          WorkScheduleListRes: {} as WorkScheduleListRes,
        };
      case WORK_SCHEDULE_LIST_FAIL:
        return {
          ...state,
          WorkScheduleListRes: {} as WorkScheduleListRes,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  