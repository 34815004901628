import {
    AddWorkScheduleDetails,
    AddWorkScheduleActionTypes,
    ADD_WORK_SCHEDULE_FAIL,
    ADD_WORK_SCHEDULE_LOADING,
    ADD_WORK_SCHEDULE_SUCCESS,
  } from "./ActionTypes";
  import { AddWorkScheduleRes } from "./Model";
  
  const initialStateGetPosts: AddWorkScheduleDetails = {
    loading: false,
    addWorkScheduleRes: {} as AddWorkScheduleRes,
    errRes: "",
  };
  export const addWorkScheduleReducer = (
    state = initialStateGetPosts,
    action: AddWorkScheduleActionTypes
  ): AddWorkScheduleDetails => {
    switch (action.type) {
      case ADD_WORK_SCHEDULE_SUCCESS:
        return {
          ...state,
          loading: false,
          addWorkScheduleRes: action.payload,
          errRes: "",
        };
      case ADD_WORK_SCHEDULE_LOADING:
        return {
          ...state,
          loading: true,
          addWorkScheduleRes: {} as AddWorkScheduleRes,
        };
      case ADD_WORK_SCHEDULE_FAIL:
        return {
          ...state,
          addWorkScheduleRes: {} as AddWorkScheduleRes,
          loading: false,
          errRes: action.errRes,
        };
      default:
        return state;
    }
  };
  