import {
  apiAuthTokenDetails,
  apiAuthTokenActionTypes,
  API_AUTH_TOKEN_FAIL,
  API_AUTH_TOKEN_LOADING,
  API_AUTH_TOKEN_SUCCESS,
} from "./ActionTypes";
import { apiAuthTokenValue } from "./Model";

const initialStateGetPosts: apiAuthTokenDetails = {
  loading: false,
  apiAuthTokenRes: {} as apiAuthTokenValue,
  errRes: "",
};
export const apiAuthTokenReducer = (
  state = initialStateGetPosts,
  action: apiAuthTokenActionTypes
): apiAuthTokenDetails => {
  switch (action.type) {
    case API_AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        apiAuthTokenRes: action.payload,
        errRes: "",
      };
    case API_AUTH_TOKEN_LOADING:
      return {
        ...state,
        loading: true,
        apiAuthTokenRes: {} as apiAuthTokenValue,
      };
    case API_AUTH_TOKEN_FAIL:
      return {
        ...state,
        apiAuthTokenRes: {} as apiAuthTokenValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
