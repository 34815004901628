import {
  GET_DOCTOR_EDUCATION_LIST_FAIL,
  GET_DOCTOR_EDUCATION_LIST_LOADING,
  GET_DOCTOR_EDUCATION_LIST_SUCCESS,
  GET_DOCTOR_EDUCATION_LIST_API_MSG,
  GET_DOCTOR_EDUCATION_LIST_API_RES,
  GetSpecialtyListActionTypes,
} from "./ActionTypes";
import { GetSpecialtyListRes } from "./Model";

export const getGetSpecialtyListLoadingAction = (
  loading: boolean
): GetSpecialtyListActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_LIST_LOADING,
    loading: loading,
  };
};

export const getGetSpecialtyListSuccessAction = (
  getGetSpecialtyListResponse: GetSpecialtyListRes,
  successMsg: string
): GetSpecialtyListActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_LIST_SUCCESS,
    payload: getGetSpecialtyListResponse,
    successMsg: successMsg,
  };
};

export const getGetSpecialtyListErrorAction = (
  getGetSpecialtyListResponse: GetSpecialtyListRes,
  errMsg: string,
  status: number
): GetSpecialtyListActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_LIST_FAIL,
    payload: getGetSpecialtyListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getGetSpecialtyListAPIMsgAction = (
  getGetSpecialtyListResponse: GetSpecialtyListRes,
  errMsg: string,
  status: number
): GetSpecialtyListActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_LIST_API_MSG,
    payload: getGetSpecialtyListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getGetSpecialtyListAPIResClearAction = (
  getGetSpecialtyListResponse: GetSpecialtyListRes,
  errMsg: string,
  status: number
): GetSpecialtyListActionTypes => {
  return {
    type: GET_DOCTOR_EDUCATION_LIST_API_RES,
    payload: getGetSpecialtyListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
